import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { CropImageContainer } from "../styled";
import {
  BlueWhiteBtn as OK,
  BlueOutlinedBtn as Cancel,
} from "../../../common/button";

export default class ClassCropperModal extends Component {
  static propTypes = {
    uploadedImageFile: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      src: null,
    };
    this.cropperRef = createRef();
  }

  componentDidMount() {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const dataURL = e.target.result;
      this.setState({ src: dataURL });
    };
    fileReader.readAsDataURL(this.props.uploadedImageFile);
  }

  handleSubmit = () => {
    if (!this.state.submitting) {
      let file = this.props.uploadedImageFile;
      if (this.cropperRef?.current?.cropper) {
        this.cropperRef?.current?.cropper
          .getCroppedCanvas()
          .toBlob(async (blob) => {
            //Pass the selected cut picture out
            this.props.onSubmit(blob);
            // Close the popup
            this.props.onClose();
          }, file.type);
      }
    }
  };

  render() {
    const { src } = this.state;
    const { onClose } = this.props;
    return (
      <CropImageContainer>
        <div className="class-cropper-modal">
          <div className="modal-panel">
            <div className="cropper-container-container">
              <div className="cropper-container">
                <Cropper
                  src={src}
                  className="cropper"
                  ref={this.cropperRef}
                  guides={true}
                  preview=".cropper-preview"
                  autoCropArea={1}
                />
              </div>
              <div className="preview-container">
                <div className="cropper-preview" />
              </div>
            </div>
            <div className="button-row">
              <OK title="SUBMIT" onClick={this.handleSubmit} />
              <Cancel title="CANCEL" onClick={onClose} margin="0 0 0 20px" />
            </div>
          </div>
        </div>
      </CropImageContainer>
    );
  }
}
