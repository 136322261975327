import React, { Component } from "react";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { actionCreators } from "../store";
import ClaimDetail from "../shared/claimDetail";
import { isValid } from "date-fns";
import { compareDates } from "../../../utils/dateTimeHelper";

let dateList = {
  claim_due_days: "",
  claim_due_term: "",
  cert_due_days: "",
  cert_due_term: "",
  payment_due_days: "",
  payment_due_term: "",
};

// DATE FIELDS WILL SHOW 'INVALID DATE FORMAT' ERROR MESSAGE
class Index extends Component {
  updatePageChangeStatus = (field, value) => {
    this.props.componentUpdated();
    this.props.handleChange(field, value);
  };

  handleDateChange = (field, event) => {
    const { proId, contractId, claimId, contractInfo, contractType } =
      this.props;
    this.props.componentUpdated();
    this.props.handleChange(
      field,
      event,
      proId,
      contractId,
      claimId,
      contractInfo.get("integration"),
      contractType,
    );
  };
  render() {
    const { claimInfo, payment, paymentsOption, accountConfig } = this.props;
    const claimWord = accountConfig?.getIn(["claim", "noun"]);
    if (payment && paymentsOption["pay_claim_options"]) {
      Object.keys(dateList).forEach((item) => {
        if (item.includes("term")) {
          let optionName = item.includes("claim")
            ? "pay_claim_options"
            : item.includes("cert")
              ? "pay_cert_options"
              : "payment_options";
          dateList[item] = paymentsOption[optionName][payment.get(item)];
        } else {
          dateList[item] = payment.get(item);
        }
      });
    }
    const debounceChange = debounce(
      (field, value) => this.updatePageChangeStatus(field, value),
      400,
    );
    const data = {
      summary: [
        {
          label: `${claimWord} Reference`,
          value: claimInfo.get("claim_ref") || "",
          action: (field, event) => debounceChange(field, event.target.value),
          autoFocus: true,
          name: "claim_ref",
          required: true,
          error: claimInfo.get("claim_ref") === "" ? "Required" : "",
        },
        {
          label: `Previous ${claimWord} Reference`,
          value: claimInfo.get("previous_claim_name"),
        },
      ],
      period: [
        {
          label: "From",
          value: claimInfo.get("claim_from") ? claimInfo.get("claim_from") : "",
          field: "date",
          name: "claim_from",
          action: this.handleDateChange,
        },
        {
          label: "To",
          value: claimInfo.get("claim_to") ? claimInfo.get("claim_to") : "",
          field: "date-required",
          name: "claim_to",
          action: this.handleDateChange,
          error:
            compareDates(
              claimInfo?.get("claim_from"),
              claimInfo?.get("claim_to"),
            ) === "greater"
              ? `Please enter a date later than ${claimInfo?.get("claim_from")}`
              : "",
        },
      ],
      due_date: [
        {
          label: `Due Date for ${claimWord}`,
          value: claimInfo.get("claim_due_date")
            ? claimInfo.get("claim_due_date")
            : "",
          field: "date",
          name: "claim_due_date",
          action: this.handleDateChange,
          component: [
            { className: "inputStyle_days", value: dateList.claim_due_days },
            { className: "inputStyle_term", value: dateList.claim_due_term },
          ],
        },
        {
          label: "Due Date for Certificate",
          value: claimInfo.get("cert_due_date")
            ? claimInfo.get("cert_due_date")
            : "",
          field: "date",
          name: "cert_due_date",
          action: (field, event) => this.updatePageChangeStatus(field, event),
          component: [
            { className: "inputStyle_days", value: dateList.cert_due_days },
            { className: "inputStyle_term", value: dateList.cert_due_term },
          ],
        },
        {
          label: "Due Date for Payment",
          value: claimInfo.get("pay_due_date")
            ? claimInfo.get("pay_due_date")
            : "",
          field: "date",
          name: "pay_due_date",
          action: (field, event) => this.updatePageChangeStatus(field, event),
          component: [
            { className: "inputStyle_days", value: dateList.payment_due_days },
            { className: "inputStyle_term", value: dateList.payment_due_term },
          ],
        },
      ],
      retention_date: [
        {
          label: "Practical Completion Date",
          field: "date",
          value: claimInfo.getIn(["read_retention", "pcd_date"]),
        },
        {
          label: "Defects Liability Period",
          field: "date",
          value: claimInfo.getIn(["read_retention", "dlp_date"]),
        },
      ],
    };
    return (
      <ClaimDetail
        header={claimWord}
        data={data}
        has_retention={claimInfo.get("has_retention")}
        accountConfig={accountConfig}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  handleChange(
    field,
    event,
    proId,
    contractId,
    claimId = "",
    integration = "",
    contractType,
  ) {
    if (field === "claim_ref") {
      dispatch(actionCreators.setClaimInfo(field, event));
    } else {
      if (isValid(event)) {
        dispatch(
          actionCreators.setClaimInfo(
            field,
            event,
            proId,
            contractId,
            claimId,
            integration,
            contractType,
          ),
        );
      }
    }
  },
});
export default connect(null, mapDispatchToProps)(Index);
