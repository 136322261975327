import styled from "styled-components";
import * as theme from "../../../../theme";

export const Table = styled.div`
  margin: 20px 0 20px 0;
  box-sizing: border-box;
  line-height: 17px;
  table {
    width: 100%;
    box-sizing: border-box;
    border-collapse: collapse;
    table-layout: fixed;
    th,
    td {
      height: 44px;
      padding: 8px 16px;
      border-left: solid 1px ${theme.palette.white};
      border-right: solid 1px ${theme.palette.white};
      &.align-right {
        text-align: right;
      }
    }
    thead {
      font-weight: bold;
      text-align: left;
      tr {
        height: 44px;
        th {
          background-color: ${theme.palette.gunmetal};
          color: ${theme.palette.white};
          &.fit-width {
            width: 190px;
          }
        }
      }
    }

    tbody {
      tr {
        height: 44px;
      }
      tr:nth-child(odd) {
        background-color: ${theme.palette.veryColdGrey};
      }
    }
    tfoot {
      font-weight: ${theme.font.mainFontWeight};
      text-align: right;
      .total-wrap {
        background-color: ${theme.palette.tealBlue};
        color: ${theme.palette.white};
      }
      .net-wrap {
        background-color: ${theme.palette.turquoiseBlue};
        color: ${theme.palette.white};
        border-top: 1px solid ${theme.palette.white};
      }
    }
  }
  .pagination {
    width: 100%;
    position: relative;
  }
`;

export const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 40px;
`;

export const CellSubtitle = styled.div`
  color: ${theme.palette.veryMediumGrey};
  font-size: 12px;
  margin-top: 4px;
`;

export const TableStyle = styled.div`
  margin-top: 40px;
  // chrome
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .inputWrapper {
      margin-right: -1px;
      margin-left: 0px;
    }
  }
  //firefox
  @-moz-document url-prefix() {
    .inputWrapper {
      margin-right: 0px;
      margin-left: -1px;
    }
  }

  thead {
    th {
      text-align: left !important;
    }
  }
  table {
    td {
      padding: 8px 16px;
    }
  }

  // Style for lineitem_options based on lump/material
  .grey-dash {
    display: inline-block;
    width: 10px;
    height: 1px;
    background-color: grey;
  }
  .table-caption {
    font-size: 20px;
    font-weight: bold;
  }
`;

export const RetentionWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px;
  width: 100%;

  height: auto;

  border: 1px solid ${theme.palette.lightWolfGrey};
  border-radius: 4px;

  line-height: 20px;
  font-weight: bold;
  margin-bottom: 24px;
  h2 {
    font-size: 20px;
  }
  &.content {
    flex-direction: column;
    align-items: flex-start;
    width: 428px;
    padding: 16px;
    height: 134px;
    margin-bottom: 0;
    .date {
      margin-top: 8px;
      font-weight: 400;
    }
  }
  .grid-wrap {
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 6px;
    margin-top: 16px;
    width: 100%;
    label {
      font-weight: 400;
      color: ${theme.palette.veryMediumGrey};
      font-size: 16px;
    }
    .num {
      font-size: 20px;
    }
    .has-margin {
      margin-bottom: 16px;
    }
  }
  &.schedule-wrap {
    font-weight: 400;
    align-items: flex-start;
    li {
      text-align: right;
    }
  }
`;
