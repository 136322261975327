import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import * as themeStyle from "../../theme";
export default function MaterialUIPickers(props) {
  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          height: "30px",
          padding: "6px 0 7px 16px",
          fontFamily: themeStyle.font.mainFont,
          fontSize: "16px",
        },
      },
      MuiIconButton: { root: { padding: "8px" } },
      MuiPickersModal: {
        dialogRoot: {
          height: "460px",
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: themeStyle.palette.tangerineOrange,
        },
      },
      MuiTypography: {
        colorPrimary: {
          color: themeStyle.palette.gunmetal,
        },
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: themeStyle.palette.tangerineOrange,
        },
      },
      MuiButton: {
        textPrimary: {
          color: themeStyle.palette.gunmetal,
        },
      },
    },
  });

  const handleDateChange = (date) => {
    props.handleChange(date);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justifyContent="flex-start">
          <KeyboardDatePicker
            style={{
              width: props.width ? props.width : "250px",
              height: "44px",
              border: `1px solid ${themeStyle.palette.wolfGrey}`,
              margin: "0",
              borderRadius: props.radius || "0px",
              backgroundColor: props.disable
                ? themeStyle.palette.greyWhite
                : "white",
            }}
            InputProps={{
              disableUnderline: true,
            }}
            placeholder="yyyy-mm-dd"
            format="yyyy-MM-dd"
            margin="normal"
            id={props.id}
            value={props.initialValue ? new Date(props.initialValue) : null}
            disabled={props.disable}
            onChange={handleDateChange}
            clearable={true}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}
