import API from "../../../server";
import * as constants from "./constants";
import { fromJS } from "immutable";
import {
  HIDE_ACTIVE,
  HIDE_EMPTY,
  SHOW_ARCHIVED,
  STATUS_ACTIVE,
  STATUS_ARCHIVE,
} from "../../../utils/constants";
import { caseInsensitiveIncludes } from "../../../utils/helper";
import { downloadFile } from "../../../utils/createDownloadLink";

export const handleFilters = (filters) => {
  return (dispatch) => {
    dispatch(setData("filterOption", filters));
    dispatch(setFilteredProjects());
  };
};

export const handleSearch = (searchString) => {
  return (dispatch) => {
    dispatch(setData("search", searchString));
    dispatch(setFilteredProjects());
  };
};

const setFilteredProjects = () => {
  return (dispatch, getState) => {
    let table = getState().getIn(["projects", "allProjects"]),
      searchString = getState().getIn(["projects", "search"]),
      filters = getState().getIn(["projects", "filterOption"]);

    // hide archived by default
    let filteredTable = table.filter((p) => {
      return ![STATUS_ARCHIVE].includes(p.get("status"));
    });

    // apply filters
    if (filters["Project Status"]) {
      // readable booleans
      const isArchiveVisible = filters["Project Status"][SHOW_ARCHIVED],
        isActiveHidden = filters["Project Status"][HIDE_ACTIVE],
        isEmptyHidden = filters["Project Status"][HIDE_EMPTY];

      // show archived
      if (isArchiveVisible) {
        filteredTable = table;
      }

      // hide active
      if (isActiveHidden) {
        // to show empty projects even if active projects are filtered out
        if (!isEmptyHidden) {
          filteredTable = filteredTable.filter((p) => {
            let numContracts = 0;
            numContracts += p.get("claim_contracts").get("contract_number");
            numContracts += p.get("cert_contracts").get("contract_number");

            return (
              numContracts < 1 || ![STATUS_ACTIVE].includes(p.get("status"))
            );
          });
        } else {
          filteredTable = filteredTable.filter((p) => {
            return ![STATUS_ACTIVE].includes(p.get("status"));
          });
        }
      }

      // hide empty
      if (isEmptyHidden) {
        filteredTable = filteredTable.filter((project) => {
          let numContracts = 0;
          numContracts += project.get("claim_contracts").get("contract_number");
          numContracts += project.get("cert_contracts").get("contract_number");

          return numContracts > 0 && project;
        });
      }
      // for filteredTable, take the project if searchString matches/is included in
      // project name, or any of its contract's names, numbers or other party name
      if (searchString) {
        filteredTable = filteredTable.filter((p) => {
          let tags = [
            ...p?.getIn(["cert_contracts", "contract_search_tags"]).toJS(),
            ...p?.getIn(["claim_contracts", "contract_search_tags"]).toJS(),
          ];
          const tagsIsMatch =
            tags.findIndex((tag) =>
              tag.toLowerCase().includes(searchString.toLowerCase()),
            ) > -1;

          return (
            tagsIsMatch ||
            ["name", "project_number"].some((field) =>
              caseInsensitiveIncludes(p.get(field), searchString),
            )
          );
        });
      }
    }
    dispatch(setData("filteredProjects", fromJS(filteredTable)));
  };
};

export const setData = (name, value) => ({
  type: constants.SET_DATA,
  name,
  value,
});

const setProjects = (projects) => ({
  //return all project list
  type: constants.SET_PROJECT_LIST,
  payload: projects,
});

export const readProjects = () => {
  return (dispatch) => {
    dispatch(setData("loading", true));
    API.read_projects()
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setProjects(res));
        dispatch(setFilteredProjects());
      });
  };
};
export const reset = () => ({
  type: constants.RESET,
});

export const downloadProjectsSummary = () => {
  return (dispatch) => {
    API.download_projects_csv_summary({ responseType: "blob" }).then((res) => {
      downloadFile(res);
    });
  };
};
