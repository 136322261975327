import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { isDirty } from "redux-form/immutable";

import { BgWrapper, HeaderTitle } from "../../../../../common/shared_style";

import Menu from "../../../../../common/breadcrumbs";
import Form from "./components/profileForm";
import Prompt from "../../../../../common/modal/component/prompt";

import { actionCreators } from "../../../store";

class Index extends PureComponent {
  render() {
    var link = [{ link: "/manage-account/profile", text: "Profile" }];
    return (
      <BgWrapper>
        <Prompt when={this.props.isFormDirty && !this.props.saved} />
        <Menu link={link} content="Edit Profile Details" />
        <HeaderTitle>Edit Profile Details</HeaderTitle>
        <Form
          onSubmit={(value) => {
            this.props.updateInfo(value);
          }}
          initialValues={this.props.profile}
        />
      </BgWrapper>
    );
  }
  componentDidMount() {
    this.props.getInitialInfo();
  }
  componentWillUnmount() {
    this.props.reset();
  }
}

const mapStateToProps = (state) => ({
  profile: state.getIn(["manageAccount", "profile"]),
  isFormDirty: isDirty("ProfileForm")(state),
  saved: state.getIn(["manageAccount", "saved"]),
});

const mapDispatchToProps = (dispatch) => ({
  getInitialInfo() {
    dispatch(actionCreators.getProfileInfo());
  },
  updateInfo(value) {
    dispatch(actionCreators.updateProfile(value, "profile"));
  },
  reset() {
    dispatch(actionCreators.reset());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
