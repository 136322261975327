import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { fromJS } from "immutable";

import { Container, ProjectBackground } from "../../project/styled";
import { ContractDetailWrapper } from "../styled";

import Menu from "../../../common/breadcrumbs";
import { CLAIM_ONLY, EDIT, PROJECT } from "../../../utils/constants";
import profile from "../../../utils/profile";
import { findTreeParentIds } from "../../../utils/helper";

import LineItem from "../components/lineItem";
import Provisional from "../components/provisional";
import Materials from "../components/materials";
import ContractDetails from "./components/contractDetails";
import NoteSection from "../components/noteSection";
import DocumentSection from "../components/document";
import RetentionSection from "../../project/components/retention";
import ClaimHistory from "./components/claimHistory";
import ProjectSummary from "../../project/components/projectSummary";
import { ReturnAction } from "../index";
import Navigator, { navTemplate } from "../../../common/navigator";
import RetentionOverview from "../components/retention/overview";
import { getDataByElementKey } from "../common/constants";

import { actionCreators } from "../store";

// active contract overview page
class Index extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      allowEditProject: true,
      isBaseExpanded: false,
      isVarExpanded: false,
      navExpanded: [],
      haveProvisionalItems: false,
      expandTreeKeys: [],
    };
  }

  updateHaveProvisionalItems = (status) => {
    this.setState({ haveProvisionalItems: status });
  };

  updateExpandTreeKeys = (keys, scrollToView = false, elementType) => {
    this.setState(
      () => ({
        expandTreeKeys: keys,
      }),
      () => {
        if (scrollToView) {
          const targetId = `${elementType}_name_${keys[0]}`;
          setTimeout(function () {
            let el = document.querySelector(`#${targetId}`);
            if (el) {
              el.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
              });
              el.click();
            } else {
              console.error("Element not found:", targetId);
            }
          }, 100);
        }
      },
    );
  };

  render() {
    const {
      projectInfo,
      contractInfo,
      claimSummary,
      orgType,
      readOnly,

      updateContract,
      uploadFiles,
      deleteAttachment,
      downloadAttachment,
      updateRetention,
      downloadSummary,
      updatePayHistory,
      downloadPaymentFile,
      changeState,
      updateAttachment,
      handlePendingCert,
      downloadRetentionPdf,
      config,
      accountConfig,
    } = this.props;
    const { proId, type, contractId } = this.props.match.params;
    const { allowEditProject } = this.state;
    const isLinked = contractInfo.get("integration") ? true : false;

    const setNavExpanded = (value, navItem) => {
      let updatedItems = [];
      if (value) {
        updatedItems = [...this.state.navExpanded, navItem];
      } else {
        updatedItems = this.state.navExpanded.filter(
          (item) => item !== navItem,
        );
      }
      this.setState({ navExpanded: updatedItems });
    };

    const link = [
      { link: "/projects", text: "Projects" },
      {
        link: `/project/view/${projectInfo.get("id")}`,
        text: projectInfo.get("name"),
      },
    ];
    var hasRetention =
      contractInfo && contractInfo.get("retention") ? true : false;
    let outstandingClaims = claimSummary.filter(
      (claim) => claim.get("claim_status") === "issue",
    ).size;
    // display project card in the claim contract page when account is claim only and no cert contracts of the project
    let showProjectSummary =
      orgType === CLAIM_ONLY &&
      type === "claim" &&
      projectInfo.get("cert_contracts")?.size === 0;

    let ContractOverviewNavigator = [
      ...(showProjectSummary ? ["nav-projectInfo"] : []),
      "nav-contractInfo",
      ...(claimSummary.size > 0 ? ["nav-history"] : []),
      "nav-baseContracts",
      "nav-variation",
      ...(this.state.haveProvisionalItems ? ["nav-provisional"] : []),
      ...(!isLinked ? ["nav-materials"] : []),
      ...(hasRetention ? ["nav-retention"] : []),
      "nav-note",
      "nav-document",
    ].map((id) => {
      let processed = navTemplate[id];
      if (
        typeof processed.label === "string" &&
        processed.label?.toLowerCase().includes("claim")
      ) {
        processed.label = processed.label.replace(
          /claim/gi,
          accountConfig?.getIn(["claim", "noun"]),
        );
      }
      return { id, ...processed };
    });

    return (
      <Container>
        <div className="page-breadcrumbs">
          <Menu
            link={link}
            content={contractInfo.get("name")}
            className="page-breadcrumbs-label"
          />
          <ReturnAction projectInfo={projectInfo} orgType={orgType} />
        </div>
        <ProjectBackground
          src={projectInfo.get("cover_url")}
          id="background-banner"
        />
        <Navigator
          data={ContractOverviewNavigator}
          anchor={{ element: "background-banner", direction: "top" }}
          isBaseExpanded={this.state.isBaseExpanded}
          isVarExpanded={this.state.isVarExpanded}
          navExpanded={this.state.navExpanded}
        />
        {showProjectSummary && (
          <ProjectSummary
            projectInfo={projectInfo}
            welcomeInfoSeen={true}
            changeState={changeState}
            allowEditProject={allowEditProject}
            hideMap={true}
          />
        )}
        <ContractDetailWrapper
          HasMarginTop={!showProjectSummary}
          id="nav-contractInfo"
        >
          <ContractDetails {...this.props} isLinked={isLinked} />
        </ContractDetailWrapper>
        {claimSummary.size > 0 && (
          <ClaimHistory
            {...this.props}
            contractId={contractId}
            proId={proId}
            type={contractInfo.get("contract_type")}
            downloadSummary={() =>
              downloadSummary(proId, contractId, contractInfo.get("name"))
            }
            update={(claimId, file, paidAmount) =>
              updatePayHistory(contractId, claimId, file, paidAmount)
            }
            download={(fileId, fileName) =>
              downloadPaymentFile(fileId, fileName)
            }
            handlePendingCert={(isNew, claimId, certId) =>
              handlePendingCert(
                proId,
                contractId,
                claimId,
                certId,
                isNew,
                contractInfo.get("contract_type"),
              )
            }
          />
        )}
        <LineItem
          {...this.props}
          itemsType={"baseContract"}
          hasExpand
          outstandingClaims={outstandingClaims}
          setExpanded={(value) => setNavExpanded(value, "nav-baseContracts")}
          readOnly={readOnly}
          isLinked={isLinked}
          expandTreeKeys={this.state.expandTreeKeys}
          setExpandKeys={this.updateExpandTreeKeys}
          hasRetention={hasRetention}
        />
        <LineItem
          {...this.props}
          itemsType={"variations"}
          hasExpand
          outstandingClaims={outstandingClaims}
          setExpanded={(value) => setNavExpanded(value, "nav-variation")}
          isLinked={isLinked}
          expandTreeKeys={this.state.expandTreeKeys}
          setExpandKeys={this.updateExpandTreeKeys}
          hasRetention={hasRetention}
        />
        <Provisional
          {...this.props}
          setNavExpanded={(value) => setNavExpanded(value, "nav-provisional")}
          hasExpand
          outstandingClaims={outstandingClaims}
          updateHaveProvisionalItems={this.updateHaveProvisionalItems}
        />
        {!isLinked && (
          <Materials
            {...this.props}
            hasExpand
            proId={proId}
            contractId={contractId}
            type={type}
            outstandingClaims={outstandingClaims}
            setNavExpanded={(value) => setNavExpanded(value, "nav-materials")}
            hasRetention={hasRetention}
          />
        )}
        {hasRetention && (
          <RetentionSection
            {...this.props}
            isLinked={isLinked}
            retention={contractInfo.get("retention") || fromJS({})}
            updateInfo={(type, info, scale, retentionAccounts) =>
              updateRetention(
                proId,
                contractId,
                type,
                info,
                scale,
                retentionAccounts,
              )
            }
            showLessRetention
            isContract
            render={() => (
              <RetentionOverview
                contractInfo={contractInfo}
                retentionReport={claimSummary.filter(
                  (cert) => cert?.get("cert_issued_date") !== null,
                )}
                retention={contractInfo.get("retention") || fromJS({})}
                downloadRetentionPdf={(isDownload) =>
                  downloadRetentionPdf(proId, contractId, isDownload)
                }
                accountConfig={this.props.accountConfig}
                setExpandKeys={this.updateExpandTreeKeys}
                config={config}
              />
            )}
          />
        )}
        <NoteSection
          side={type}
          info={contractInfo}
          handleSubmit={(update) => updateContract(proId, contractId, update)}
          readOnly={readOnly}
        />
        <DocumentSection
          {...this.props}
          uploadFiles={(files) => uploadFiles(proId, contractId, files)}
          handleDelete={(fileId) =>
            deleteAttachment(
              projectInfo.get("id"),
              contractInfo.get("id"),
              fileId,
            )
          }
          handleDownload={(fileId) =>
            downloadAttachment(
              projectInfo.get("id"),
              contractInfo.get("id"),
              fileId,
            )
          }
          updateAttachment={(updatedAttachment) =>
            updateAttachment(proId, contractId, updatedAttachment)
          }
        />
      </Container>
    );
  }
  reloadContract = () => {
    const { proId, contractId } = this.props.match.params;
    this.props.readContractDetails(proId, contractId);
  };
  scrollToTargetContainer = () => {
    // Access location and state from props
    const { location } = this.props;
    if (location.state && location.state.id && location.state.section) {
      const { id, section } = location.state;
      if (section === "lineitem") {
        let elementKey = id.split("#")[0];
        if (elementKey !== "childitem") {
          //variations/base_contract/material
          //not redirect to target sections for childitems
          let dataKey = getDataByElementKey(elementKey);
          let containerName =
            elementKey === "variations" ? "variation" : elementKey;
          let tree = this.props.contractInfo?.get(dataKey).toJS() || [];
          const parentIds = findTreeParentIds(tree, id.split("#")[1]) || [];
          parentIds.length > 0 &&
            this.updateExpandTreeKeys(parentIds, true, containerName);
        }
      } else {
        const targetSection = document.getElementById(id);
        if (targetSection) {
          setTimeout(function () {
            targetSection.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 100);
        }
      }
    }
  };
  componentDidMount() {
    const { proId } = this.props.match.params;
    profile
      .checkPermission(PROJECT, EDIT, proId)
      .then((res) => this.setState({ allowEditProject: res }));
    window.addEventListener("focus", this.reloadContract);
    this.scrollToTargetContainer();
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.reloadContract);
  }
}

const mapStateToProps = (state) => ({
  accountId: state.getIn(["headers", "profile", "payclaim_account_id"]),
  claimSummary: state.getIn(["contract", "claimSummary"]),
  payHistory: state.getIn(["contract", "payHistory"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
  config: state.getIn(["headers", "config"]),
});

const mapDispatchToProps = (dispatch) => ({
  changeContractStatus(proId, contractId, action) {
    dispatch(actionCreators.changeContractStatus(proId, contractId, action));
  },
  downloadSummary(proId, contractId, contractName) {
    dispatch(
      actionCreators.downloadClaimSummary(proId, contractId, contractName),
    );
  },
  updatePayHistory(contractId, claimId, file, paidAmount) {
    dispatch(
      actionCreators.updatePayHistory(contractId, claimId, file, paidAmount),
    );
  },
  downloadPaymentFile(fileId, fileName) {
    dispatch(actionCreators.downloadPaymentFile(fileId, fileName));
  },
  downloadFile(proId, contractId, fileId, fileName, isView) {
    dispatch(
      actionCreators.downloadItemFile(
        proId,
        contractId,
        fileId,
        fileName,
        isView,
      ),
    );
  },
  leaveContract(proId, contractId, fullContractType) {
    dispatch(actionCreators.leaveContract(proId, contractId, fullContractType));
  },
  changeState(proId, state) {
    dispatch(actionCreators.changeState(proId, state));
  },
  handlePendingCert(proId, contractId, claimId, certId, data, contractType) {
    dispatch(
      actionCreators.handlePendingCert(
        proId,
        contractId,
        claimId,
        certId,
        data,
        contractType,
      ),
    );
  },
  downloadRetentionPdf(proId, contractId, isDownload) {
    dispatch(
      actionCreators.downloadRetentionPdf(proId, contractId, isDownload),
    );
  },
  readContractDetails(proId, contractId) {
    dispatch(actionCreators.readContractDetails(proId, contractId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
