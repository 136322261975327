import React from "react";
import Select from "react-select";

import { palette } from "../../theme";
import downloadIcon from "../../statics/img/icon-download-medium-grey.svg";

const style = {
  container: (styles) => ({
    ...styles,
    "&:hover": {
      width: "25px",
      height: "24px",
      borderRadius: "50px",
      backgroundColor: palette.veryWarmLightGrey,
    },
    "&:active": {
      width: "25px",
      height: "24px",
      borderRadius: "50px",
      backgroundColor: palette.wolfGrey,
    },
  }),
  control: () => ({
    width: "24px",
    height: "24px",
    border: "none",
    fontSize: "16px",
    fontWeight: "bold",
    boxShadow: "none",
    backgroundColor: "none",
  }),
  valueContainer: (styles) => ({
    ...styles,
    background: `url(${downloadIcon}) no-repeat`,
    "&:hover": { cursor: "pointer" },
  }),
  menu: (styles) => ({
    ...styles,
    width: "236px",
    marginTop: "0px",
    left: "-212px",
  }),
  option: (styles) => ({
    ...styles,
    padding: "10px 16px",
    backgroundColor: "none",
    color: palette.gunmetal,
    "&:hover": { backgroundColor: palette.warmLightGrey, cursor: "pointer" },
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    display: "none",
    width: "0",
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 9999,
  }),
};

// Icon with a dropdown
export const IconDropdown = ({ options }) => (
  <Select
    styles={style}
    isSearchable={false}
    value={{ label: "" }}
    onChange={(e) => e.value()}
    options={options}
    menuPortalTarget={document.querySelector("body")}
  />
);
