import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Link from "../../../../../common/linkWithQuery";

import { BgWrapper, HeaderTitle } from "../../../../../common/shared_style";
import { PlanInfo, BillInfo, BottomWrapper } from "./style";

import {
  GreyOutlinedBtn as Button,
  BlueOutlinedBtn as BlueButton,
} from "../../../../../common/button";
import Menu from "../../../../../common/breadcrumbs";
import Loading from "../../../../../common/loading";

import { actionCreators } from "../../../store";

Math.easeInOutQuad = function (t, b, c, d) {
  //t = current time
  //b = start value
  //c = change in value
  //d = duration
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

class PlanPayment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      scrollPos: 0,
    };
    this.scrollLeft = this.scrollLeft.bind(this);
  }

  scrollLeft(element, change, duration) {
    var start = element.scrollLeft,
      currentTime = 0,
      increment = 20;
    this.setState({ scrollPos: element.scrollLeft + change });
    var animateScroll = function () {
      currentTime += increment;
      var val = Math.easeInOutQuad(currentTime, start, change, duration);
      element.scrollLeft = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  render() {
    const { planInfoList, planDetail, loadingPlanInfo, accountConfig } =
      this.props;

    const currency = accountConfig?.getIn(["currency", "title"]);

    const hasPlan = planDetail.get("status") === "active",
      currentPlan = hasPlan
        ? planDetail.getIn(["current_plan", "price_id"]) || ""
        : "",
      pk_info = planInfoList.get("stripe_pk_key") || "",
      list = planInfoList.get("pricing_plans") || [];

    let labelToShow = {
      title: hasPlan ? "Pricing" : "Choose a Plan",
      link: hasPlan
        ? [
            { link: "/manage-account/view", text: "Manage Account" },
            { link: "/manage-account/plan-payment", text: "Plan & Payment" },
          ]
        : [{ link: "/manage-account/view", text: "Manage Account" }],
    };
    var link = labelToShow.link;
    const noPermission = this.props.location.state.noPermission;
    const BillItem = ({ item }) => {
      return (
        <BillInfo
          className={hasPlan ? "" : "toChoose"}
          key={item.get("price_id")}
          selected={currentPlan === item.get("price_id")}
        >
          <div className="top">
            <div className="title">{item.get("product_name")}</div>
            <div className="price">
              <span className="priceA">
                {currency}
                {item.get("price_decimal")}
              </span>
              <span className="month">
                {item.get("price_currency")} / {item.get("price_interval")}
              </span>
              <span className="billed">
                Billed {item.get("price_interval").toLowerCase()}ly
              </span>
            </div>
            <div className="arrow" />
          </div>
          <div className="bottom">
            <div className="projectLabel">{item.get("product_des")}</div>

            {hasPlan ? (
              currentPlan === item.get("price_id") && (
                <Button
                  title="Current Tier"
                  padding={"10px 36px"}
                  disabled
                  style={{ border: "none" }}
                />
              )
            ) : (
              <BlueButton
                title="Select"
                padding={"10px 36px"}
                className="hoverButton"
                disabled={noPermission}
                onClick={() =>
                  this.props.createSession(item.get("price_id"), pk_info)
                }
              />
            )}
          </div>
        </BillInfo>
      );
    };
    return (
      <BgWrapper>
        <Menu link={link} content={labelToShow.title} />
        <HeaderTitle>{labelToShow.title}</HeaderTitle>
        {!loadingPlanInfo ? (
          <>
            <PlanInfo>
              <button
                className="left-button"
                disabled={this.state.scrollPos <= 0}
                onClick={() =>
                  this.scrollLeft(
                    document.getElementById("content"),
                    -392,
                    1000,
                  )
                }
              />
              <div id="content" className="center" ref={this.contentRef}>
                {list.map((item) => (
                  <BillItem item={item} key={item.get("price_id")} />
                ))}
              </div>
              <button
                className="left-button right"
                disabled={
                  list.size <= 3 ||
                  this.state.scrollPos >= 392 * Math.ceil(list.size / 3)
                }
                onClick={() =>
                  this.scrollLeft(document.getElementById("content"), 392, 1000)
                }
              />
            </PlanInfo>
            <BottomWrapper>
              <Link
                to={
                  hasPlan
                    ? "/manage-account/plan-payment"
                    : "/manage-account/view"
                }
              >
                <BlueButton title="Exit" padding="10px 43px" />
              </Link>
            </BottomWrapper>
          </>
        ) : (
          <Loading message="Loading All Plan Info ..." />
        )}
      </BgWrapper>
    );
  }

  componentDidMount() {
    this.props.getPlanInfo();
  }
  componentWillUnmount() {
    this.props.reset();
  }
}

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
  planInfoList: state.getIn(["manageAccount", "planInfo"]),
  pk_info: state.getIn(["manageAccount", "planInfo", "stripe_pk_key"]),
  planDetail: state.getIn(["manageAccount", "planDetail"]),
  loadingPlanInfo: state.getIn(["manageAccount", "loadingPlanInfo"]),
});

const mapDispatchToProps = (dispatch) => ({
  getPlanInfo() {
    dispatch(actionCreators.getPlanOverview());
  },
  createSession(priceId, pk_info) {
    dispatch(actionCreators.createSession(priceId, pk_info));
  },
  reset() {
    dispatch(actionCreators.reset());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanPayment);
