import styled from "styled-components";
import * as theme from "../../theme";
import DropDown from "../../statics/img/icon-dropdown-down-dark-grey.svg";
import userIcon from "../../statics/img/avatar.svg";
import noticeBellIcon from "../../statics/img/notice-bell-dark-grey.svg";
import noticeBoardIcon from "../../statics/img/notice-icon-dark-blue.svg";
import checked from "../../statics/img/yellow-circle-completed.svg";
import unchecked from "../../statics/img/icon-radio-off.svg";
import rightGoto from "../../statics/img/icon-chevron-right-medium-grey.svg";
import rightUnGoto from "../../statics/img/icon-chevron-right-light-grey.svg";
import supportIcon from "../../statics/img/icon-contact-support-grey.svg";
import supFormIcon from "../../statics/img/icon-mail-dark-grey.svg";
import supHubIcon from "../../statics/img/icon-support-hub-dark-grey.svg";
import noticeIcon from "../../statics/img/icon-information-light-grey.svg";
import logoutIcon from "../../statics/img/icon-logout.svg";
import avatarIcon from "../../statics/img/icon-avatar.svg";
import manageIcon from "../../statics/img/icon-toolbox.svg";

export const NavBarRectangle = styled.div`
  min-height: 78px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  background-color: ${theme.palette.white};
  border-top: 6px solid ${theme.palette.tangerineOrange};
  color: ${theme.palette.gunmetal};
  display: table;
  position: relative;
  z-index: 109;
`;

export const LogoPlaceholder = styled.div`
  .logo {
    margin: 11px 22px 0 0;
    float: left;
    height: 50px;
    width: 50px;
    display: block;
    cursor: pointer;
  }
`;

export const NavItem = styled.div`
  float: left;
  cursor: pointer;
  width: auto;
  height: 72px;
  margin: 0 22px;
  padding: 22px 0;
  font-size: 18px;
  font-weight: bold;
  box-sizing: border-box;
  color: ${theme.palette.gunmetal};
  &.focused {
    border-bottom: 6px solid ${theme.palette.gunmetal};
    transition: all 0.2s ease-out;
  }
  .badge {
    width: fit-content;
  }
`;

export const UserLoginWrapper = styled.div`
  float: right;
  height: 100%;
  .noticeBadge {
    float: right;
    margin: 22px 0;
  }
`;

export const UserDropdownList = styled.div`
  float: right;
  height: 100%;
  position: relative;
  .iconfont {
    width: 24px;
    height: 24px;
    background: url(${DropDown}) no-repeat;
    background-size: contain;
    cursor: pointer;
    margin: 22px 0;
    &.iconfontOpen {
      transform: rotate(180deg);
    }
  }
  .supportIcon {
    width: 24px;
    height: 24px;
    background: url(${supportIcon}) no-repeat;
    background-size: contain;
    display: block;
    cursor: pointer;
    margin: 22px 0 22px 10px;
  }
`;
export const UserIcon = styled.div`
  height: 100%;
  float: right;
  line-height: 95px;
  text-align: center;
  .iconfont {
    width: 24px;
    height: 24px;
    background: url(${userIcon}) no-repeat;
    background-size: contain;
    cursor: pointer;
    margin: 22px 0;
  }
`;
export const NoticeBell = styled.div`
  float: right;
  width: 24px;
  height: 24px;
  background: url(${noticeBellIcon}) no-repeat;
  cursor: pointer;
  background-size: contain;
`;
export const NoticeBoard = styled.div`
  float: right;
  width: 24px;
  height: 24px;
  background: url(${noticeBoardIcon}) no-repeat;
  cursor: pointer;
  background-size: contain;
  margin-left: 10px;
  position: relative;
`;

export const NoticeDropdown = styled.div`
  z-index: 1;
  position: absolute;
  top: 30px;
  right: 0px;
  height: auto;
  width: 432px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
  background-color: ${theme.palette.white};
  box-sizing: border-box;
  .container {
    width: 100%;
    padding: 30px;
    display: flex;
    justify-content: space-between;

    &.top {
      flex-direction: column;
      height: 162px;
      .title {
        font-size: 20px;
        font-weight: ${theme.font.mainFontWeight};
      }
      .content {
        margin: 30px 0 20px 0;
      }
      .progress {
        display: flex;
        justify-content: space-between;
        align-items: end;
        .percentBar {
          height: 12px;
          width: 328px;
          background-color: ${theme.palette.paleYellow};
          .loading {
            height: inherit;
            background-color: ${theme.palette.tangerineOrange};
          }
        }
      }
    }
    &.middle {
      height: 180px;
      flex-direction: column;
      .progressLine {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .step {
          width: 280px;
        }
        .img {
          height: 30px;
          width: 30px;
        }
        .checked {
          background: url(${checked}) no-repeat;
        }
        .unchecked {
          background: url(${unchecked}) no-repeat;
          background-size: contain;
        }
        .goto {
          background: url(${rightGoto}) no-repeat;
        }
        .ungoto {
          background: url(${rightUnGoto}) no-repeat;
        }
      }
    }

    &.bottom {
      padding: 24px 30px;
      .action {
        cursor: pointer;
        color: ${theme.palette.veryMediumGrey};
        font-weight: ${theme.font.mainFontWeight};
      }
    }
  }
`;

export const UserInfoWrapper = styled.div`
  height: 100%;
  float: right;
  margin-right: 8px;
  width: auto;
  max-width: 180px;
  display: flex;
  flex-direction: column;
  padding: 17px 0 0;
  box-sizing: border-box;
  text-align: right;

  .flagContainer {
    margin-right: 3px;
    position: relative;
    margin-right: 30px;
    height: 16px;

    .flag {
      height: 16px;
      position: absolute;
    }
  }
`;
export const UserInfoTop = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.2;
  font-weight: bold;
  &.accountInfo {
    line-height: 15px;
    font-size: 12px;
    font-weight: normal;
    color: ${theme.palette.midGrey};
  }
`;

export const UserDropdownItems = styled.div`
  z-index: 1;
  position: absolute;
  top: 50px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  width: 220px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.104);
  background-color: ${theme.palette.white};
  padding: 14px 20px 0 20px;
  box-sizing: border-box;
  transition:
    opacity 0.3s ease,
    transform 0.2s ease;
  visibility: ${(props) => props.visible};
  opacity: ${(props) => (props.visible === "hidden" ? 0 : 1)};

  &.account-dropdown {
    width: fit-content;
    padding: 12px 16px;
    display: grid;
    grid-auto-rows: max-content;
    grid-row-gap: 8px;

    .line {
      margin: 0;
    }
    .dropdownDetail {
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .switchAcc-title {
    height: 21px;
    font-size: 12px;
    color: ${theme.palette.veryMediumGrey};
  }

  .dropdownDetail {
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 14px;
    color: ${theme.palette.gunmetal};
    line-height: 20px;
    &.switch {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .label {
      line-height: 16px;
      &.secondary_label {
        margin-top: 14px;
        font-size: 12px;
        color: ${theme.palette.veryMediumGrey};
      }
    }
    .icon {
      width: 20px;
      height: 20px;
      margin-right: 12px;
      cursor: pointer;
      object-fit: contain;
      float: left;
      &.formIcon {
        background: url(${supFormIcon}) no-repeat;
      }
      &.hubIcon {
        background: url(${supHubIcon}) no-repeat;
      }
      &.hoverIcon {
        width: 16px;
        height: 16px;
        border: none;
        background: url(${noticeIcon}) no-repeat;
        background-size: contain;
        margin-left: 6px;
      }
    }
  }

  .line {
    height: 1px;
    width: 100%;
    margin-bottom: 14px;
    background-color: ${theme.palette.lightWolfGrey};
  }
`;

export const DropdownItem = styled.div`
  width: 208px;
  min-height: 48px;
  display: flex;
  align-items: center;

  &.log-out {
    color: ${theme.palette.watermelonRed};
  }
  &.selected {
    color: ${theme.palette.turquoiseBlue};
    font-weight: bold;
    .subtext {
      font-weight: normal;
    }
  }

  .icon {
    height: 20px;
    width: 20px;
    margin: 0 14px 0 2px;

    &.log-out {
      background: url(${logoutIcon}) no-repeat;
      background-position: center;
    }
    &.profile {
      background: url(${avatarIcon}) no-repeat;
      background-position: center;
    }
    &.manage-account {
      background: url(${manageIcon}) no-repeat;
      background-position: center;
    }
    &.flag {
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      .flag {
        height: 24px;
      }
    }
  }

  // stop link from turning purple
  a,
  a:hover,
  a:visited,
  a:active {
    color: inherit;
  }

  .text {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    word-wrap: break-word;

    .title {
      line-height: 20px;
      cursor: pointer;
      max-width: 156px;
    }

    .subtext {
      font-size: 12px;
      color: ${theme.palette.midGrey};
      line-height: 20px;
      max-width: 172px;
    }
  }
`;
