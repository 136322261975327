import { httpRequestExt } from "../base/httpRequestExt";

export const MAPBOX_URL = "https://api.mapbox.com";
export const MAPBOX_LOADING_ISSUE = "There is an issue loading the map.";

const forward_geocoding = (searchText, mapbox_token) =>
  httpRequestExt(
    "get",
    `${MAPBOX_URL}/geocoding/v5/mapbox.places/${searchText}.json?types=address&access_token=${mapbox_token}`,
  );
const search_timezone = (longitude, latitude, mapbox_token) =>
  httpRequestExt(
    "get",
    `${MAPBOX_URL}/v4/examples.4ze9z6tv/tilequery/${longitude},${latitude}.json?access_token=${mapbox_token}`,
  );

export default {
  forward_geocoding,
  search_timezone,
};
