import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { ReviewWrapper } from "../../styled";
import EmailSystem from "../../../../common/email_send_service";
import { actionCreators } from "../../store";
class ContactInfo extends PureComponent {
  render() {
    return (
      <ReviewWrapper className="email">
        <EmailSystem {...this.props} label="Claim" />
      </ReviewWrapper>
    );
  }
}
const mapStateToProps = (state) => ({
  isSendEmail: state.getIn(["makeaclaim", "isSendEmail"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});
const mapDispatchToProps = (dispatch) => {
  return {
    changeEmailSubject(name, value) {
      dispatch(actionCreators.setEmailSubject(name, value));
    },
    setIsSendEmail(event) {
      dispatch(actionCreators.setValue("isSendEmail", event.target.checked));
    },
    addContact(entityId, contactId, field) {
      dispatch(actionCreators.addContact_Email(entityId, contactId, field));
    },
    addNewContact(name, email, field) {
      let data = { contact_name: name, contact_email: email };
      dispatch(actionCreators.addEmail(data, field));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);
