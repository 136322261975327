import React, { Fragment, PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fromJS } from "immutable";

import {
  PayCertTableWrapper,
  ImgAction,
  MoreActionsContainer,
  TableWarning,
} from "./styled";

import { AddBtn } from "../button";
import Dialog from "../modal/component/certReasonDialog";
import TableComponent from "./basic_table";
import CommentList from "./comment_table";
import { NumericInput, NumericInputGroup } from "./numeric_input_comp";
import { removeTrailingZeros, checkIsParent } from "../../utils/helper";
import { convertToPercent, roundNearest } from "../../utils/numeralHelper";
import {
  convertToCurrencyUnit,
  currencySubunitToUnit,
} from "../../utils/currencyHelper";
import {
  dropDecimalColumns,
  dropDecimal,
  headerFormatter,
} from "../../utils/tableFormatHelper";
import profile from "../../utils/profile";
import {
  CONTRACT,
  EDIT,
  VIEW,
  LUMP,
  CERT_VARIATION_ITEM,
  CERT_VARIATION_CHILD_ITEM,
  CERT_ITEM_ACTION,
} from "../../utils/constants";
import API from "../../server";
import InputDialogue from "../lineitem/variationInputTable";
import {
  ProvisionalDescription,
  getExpandProvisionalParents,
} from "../lineitem/sharedComponent";

const modalActionOptions = {
  EDIT: "editing_item",
  ADD: "adding_item",
  ADD_CHILD: "adding_child_item",
};
class Table extends PureComponent {
  constructor() {
    super();
    this.state = {
      openDialog: false,
      input_type: modalActionOptions.ADD, //adding_item/editing_item/adding_child_item
      tableItem: {
        //store new to add lineitme
        label: "",
        description: "",
        quantity: 0,
        unit: "",
        rate: 0,
        detailed_description: "",
        variation_reference: "",
        submit_date: "",
        approved_date: "",
        exclude_retention: false,
      },
      isParent: false,
      editItem: {
        //store the edited item id
        id: "",
      },
      editItemFiles: [],
      expands: [],
      allowEditContract: true,
      allowViewContract: false,
      prevLineType: "rate",
      type: "",
    };
  }
  returnValue = (itemId, list, field) => {
    let percent = 0,
      value = 0,
      qty = 0;
    var obj = list.find((obj) => obj.get("id") === itemId);
    if (!obj) {
      return null;
    }

    percent = obj.get("payment_percent");
    value = obj.get("payment_value");
    qty = obj.get("cert_qty");
    if (qty === 0) {
      qty = value / obj?.get("item_rate");
    }

    if (field === "percent") {
      return removeTrailingZeros(percent);
    }
    if (field === "qty") {
      return qty;
    } else {
      return value / 100;
    }
  };
  saveLineItemType = (itemId, type) => {
    const { proId, contractId } = this.props.match.params;
    API.update_one_lineitem(proId, contractId, itemId, { cert_option: type });
  };
  getReason = (itemId, list) => {
    let obj = list.find((ele) => ele.get("id") === itemId);
    if (!obj) {
      return { reason: "", isRequiredReason: null };
    }
    return {
      reason: obj.get("payment_comment") || "",
      isRequiredReason: obj.get("required_reason"),
    };
  };

  handleCloseEdit = () => {
    //reset tableItem content
    return this.setState({
      tableItem: {
        label: "",
        description: "",
        quantity: 0,
        unit: "",
        rate: 0,
        detailed_description: "",
        variation_reference: "",
        submit_date: "",
        approved_date: "",
        exclude_retention: false,
      },
    });
  };

  handleSave = (id, lineType, info) => {
    let lineItem = { ...info, lineitem_options: lineType };
    // add new child or parent item, edit item
    if (this.state.input_type === modalActionOptions.ADD) {
      this.props.handleItem(CERT_ITEM_ACTION.ADD, "", lineItem);
      this.setState({ prevLineType: lineType });
    } else if (this.state.input_type === modalActionOptions.ADD_CHILD) {
      this.props.handleItem(CERT_ITEM_ACTION.ADD, id, lineItem);
    } else {
      this.props.handleItem(CERT_ITEM_ACTION.EDIT, id, lineItem);
    }

    return this.setState({ openDialog: false });
  };

  handleDeleteItem = (id) => {
    //delete or clean input item
    if (this.state.input_type === modalActionOptions.EDIT) {
      this.props.handleItem(CERT_ITEM_ACTION.DELETE, id);
      return this.setState({ openDialog: false });
    } else {
      this.handleCloseEdit();
    }
  };

  getModalTitle = (description) => {
    let title =
      this.state.input_type === modalActionOptions.ADD
        ? "Add Item"
        : this.state.input_type === modalActionOptions.ADD_CHILD
          ? "Add Child Item To " + description
          : "Edit Item - " + description;

    return title;
  };

  isTreeExpanded = (id) => {
    return this.state.expands.includes(id);
  };

  expandProvisionalParent = (line) => {
    let expanded = getExpandProvisionalParents(line, this.state.expands);
    this.setState({ expands: [...expanded] });
  };

  lineitemEditFiles = (id) => {
    const {
      editFileList,
      match: {
        params: { proId, contractId },
      },
    } = this.props;
    if (editFileList.size > 0) {
      this.setState({ editItemFiles: editFileList });
      return;
    }

    if (!id) {
      this.setState({ editItemFiles: fromJS([]) });
      return;
    }
    API.read_line_item_files(proId, contractId, id).then((res) => {
      this.setState({ editItemFiles: fromJS(res?.data?.data?.file) });
    });
  };

  render() {
    const {
      projectData,
      tableContent,
      table_title,
      certList,
      certTotal,
      totalValues,
      reasonOption,

      tableField, // variation/ base_contract/ contra same with the api parameter name

      inputCert,
      input_method,
      inputReason,
      isNewCert,
      updateComment,
      accountConfig,
      allowExcludeRetention,

      // cert variation attachments
      editFileList,
      uploadFiles,
      handleFilesChange,
      uploadAttachments,
      cancelAttachment,
      initialFileList,
      deleteAttachment,
      contractLinked,
    } = this.props;

    const showCurrency = !accountConfig?.get("enable_header_currency");
    const CertWord = accountConfig?.getIn(["cert", "value"]),
      CertNounWord = accountConfig?.getIn(["cert", "noun"]);
    const claimTerm = accountConfig.getIn(["claim"]);

    let truncateColumn = dropDecimalColumns(tableContent.toJS(), [
      "total",
      "claim_to_date_value",
      "cert_to_date_value",
      "last_cert_value",
      "current_claim",
    ]);
    const {
      input_type,
      openDialog,
      tableItem,
      editItem,
      expands,
      allowEditContract,
      allowViewContract,
      isParent,
    } = this.state;

    const columns_dev = [
      {
        title: "Ref",
        className: "align_left lineitem-ref",
        width: 53,
        render: (d) => d.label,
      },
      {
        title: "Description",
        className: "align_left",
        treeColumnsName: "childitems",
        width: 220,
        render: (d) => (
          <div>
            <ProvisionalDescription
              data={d}
              isUpdateExpanded={() => {
                return false;
              }}
              isTreeExpanded={this.isTreeExpanded}
              expandProvisionalParent={this.expandProvisionalParent}
            />
          </div>
        ),
      },
      {
        title: headerFormatter(
          "Total",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        render: (d) =>
          currencySubunitToUnit(
            truncateColumn["total"] ? roundNearest(d.total) : d.total,
            !accountConfig?.get("enable_header_currency"),
            false,
            showCurrency,
          ),
        width: 113,
      },
      {
        title: headerFormatter(
          `${claimTerm?.get("value")} to Date`,
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        className: "group_header grey",
        render: (d) => {
          return (
            <div className="group_value">
              <div className="percent_value">
                {convertToPercent(d.claimed_to_date_percent / 100)}
              </div>
              <div>
                {currencySubunitToUnit(
                  truncateColumn["claim_to_date_value"]
                    ? roundNearest(d.claimed_to_date_value)
                    : d.claimed_to_date_value,
                  !accountConfig?.get("enable_header_currency"),
                  truncateColumn["claim_to_date_value"],
                  showCurrency,
                )}
              </div>
            </div>
          );
        },
        width: 168,
      },
      {
        title: headerFormatter(
          CertWord + " to Date",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        render: (d) =>
          currencySubunitToUnit(
            d.cert_to_date_value,
            !accountConfig?.get("enable_header_currency"),
            truncateColumn["cert_to_date_value"],
            showCurrency,
          ),
        width: 113,
      },
      {
        title: headerFormatter(
          "Variance to Date",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        render: (d) =>
          currencySubunitToUnit(
            dropDecimal(d.claimed_to_date_value - d.cert_to_date_value)
              ? roundNearest(d.claimed_to_date_value - d.cert_to_date_value)
              : d.claimed_to_date_value - d.cert_to_date_value,
            !accountConfig?.get("enable_header_currency"),
            dropDecimal(d.claimed_to_date_value - d.cert_to_date_value),
            showCurrency,
          ),
        width: 113,
      },
      {
        title: headerFormatter(
          "Previous Net " + CertWord,
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        render: (d) =>
          currencySubunitToUnit(
            truncateColumn["last_cert_value"]
              ? roundNearest(d.last_cert_value)
              : d.last_cert_value,
            !accountConfig?.get("enable_header_currency"),
            truncateColumn["last_cert_value"],
            showCurrency,
          ),
        width: 113,
      },
      {
        className: " emptyColumn",
        render: (d) => "",
        width: 8,
      },
      {
        title: headerFormatter(
          `Current ${claimTerm?.get("noun")}`,
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        render: (d) =>
          currencySubunitToUnit(
            truncateColumn["current_claim"]
              ? roundNearest(d.current_claim)
              : d.current_claim,
            !accountConfig?.get("enable_header_currency"),
            truncateColumn["current_claim"],
            showCurrency,
          ),
        width: 113,
      },
      {
        title: headerFormatter(
          `Current ${CertNounWord} Value`,
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        className: "group_header group_input",
        width: 240,
        render: (d) => {
          return (
            <div className="certValue">
              {d.lineitem_options === LUMP || checkIsParent(d) ? (
                <NumericInput
                  isParent={checkIsParent(d)}
                  input_method={input_method}
                  lineItem={d}
                  onChange={(id, field, value, onLoad, fromPrctQty) =>
                    inputCert(id, field, value, onLoad, fromPrctQty)
                  }
                  displayValue={(id, field) =>
                    this.returnValue(id, certList, field)
                  }
                />
              ) : (
                <NumericInputGroup
                  isParent={checkIsParent(d)}
                  lineItem={d}
                  defaultOption={d?.cert_option}
                  onChange={(id, field, value, onLoad, fromPrctQty) =>
                    inputCert(id, field, value, onLoad, fromPrctQty)
                  }
                  displayValue={(id, field) =>
                    this.returnValue(id, certList, field)
                  }
                  saveLineItemType={(id, type) =>
                    this.saveLineItemType(id, type)
                  }
                />
              )}
            </div>
          );
        },
      },
      {
        title: "Reason",
        className: "reason",
        width: 142,
        render: (d) => {
          return (
            <div className="inlineDiv">
              <Dialog
                title={`Reason for Variance to Submitted ${claimTerm?.get(
                  "noun",
                )}`}
                width="758px"
                height="479px"
                initialValue={this.getReason(d.id, certList)}
                inputReason={(reason, e) => inputReason(d.id, reason)}
                reasonOption={reasonOption}
              />
              {tableField === "variation" &&
                (d.status === CERT_VARIATION_ITEM ||
                  d.status === CERT_VARIATION_CHILD_ITEM) && (
                  <div className="more_actions">
                    <ImgAction />
                    <MoreActionsContainer>
                      <div
                        className="editIcon"
                        onClick={(e) => {
                          this.setState({
                            openDialog: true,
                            input_type: modalActionOptions.EDIT,
                            isParent: checkIsParent(d),
                            tableItem: {
                              ...d,
                              rate: d.rate,
                              quantity: d.quantity * 100,
                            },
                            editItem: {
                              ...d,
                              rate: d.rate,
                              quantity: d.quantity * 100,
                            },
                          });
                          initialFileList(d.uploaded_files);
                        }}
                      />
                      <div
                        className="addIcon"
                        onClick={(e) =>
                          this.setState(
                            {
                              openDialog: true,
                              input_type: modalActionOptions.ADD_CHILD,
                              editItem: { ...d },
                              isParent: checkIsParent(d),
                            },
                            this.handleCloseEdit,
                          )
                        }
                      />
                    </MoreActionsContainer>
                  </div>
                )}
              {!isNewCert && (allowEditContract || allowViewContract) && (
                <CommentList
                  data={d}
                  expands={expands}
                  onTreeExpand={(key) => {
                    return this.setState({
                      expands: [...this.state.expands, key],
                    });
                  }}
                  updateComment={updateComment}
                  type={"cert"}
                  viewOnly={allowViewContract}
                  otherParty={projectData.get("payee_entity_name")}
                />
              )}
            </div>
          );
        },
      },
    ];

    const rowClassName = (d, i) => {
      if (i % 2 === 0) {
        return "table-even";
      } else {
        return "table-odd";
      }
    };
    const totalList = {
      total: currencySubunitToUnit(
        projectData.get(`total_${tableField}`),
        showCurrency,
      ),
      claim_to_date_total: currencySubunitToUnit(
        totalValues.get("claim_to_date_total"),
        showCurrency,
      ),
      cert_to_date_total: currencySubunitToUnit(
        totalValues.get("cert_to_date_total"),
        showCurrency,
      ),
      variance_to_date_total: currencySubunitToUnit(
        totalValues.get("claim_to_date_total") -
          totalValues.get("cert_to_date_total"),
        showCurrency,
      ),
      last_cert_total: currencySubunitToUnit(
        totalValues.get("last_cert_total"),
        showCurrency,
      ),
      current_claim_total: currencySubunitToUnit(
        totalValues.get("current_claim_total"),
        showCurrency,
      ),
      cert_total: convertToCurrencyUnit(certTotal, showCurrency),
    };

    return (
      <Fragment>
        <PayCertTableWrapper>
          <TableComponent
            onTreeExpand={(keys) => this.setState({ expands: keys })}
            treeExpandKeys={expands}
            tableContent={tableContent.toJS()}
            tableColumns={columns_dev}
            tableCaption={table_title}
            hasSort
            hasEnhancedTableHeader
            hasEnhancedTableFooter
            rowClassName={rowClassName}
          >
            <table className="header_table">
              <thead>
                <tr>
                  <td style={{ width: 276 }} />
                  <td className="header_label progress">PROGRESS TO DATE</td>
                  <td style={{ width: 122 }} />
                  <td className="header_label cert">
                    {accountConfig?.getIn(["cert", "noun"])}
                  </td>
                  <td />
                </tr>
              </thead>
            </table>
            <table>
              <tfoot>
                <tr>
                  <td className="white start" />
                  <td className="subtotal title">Sub Total</td>
                  <td className="value">{totalList.total}</td>
                  <td className="grey">{totalList.claim_to_date_total}</td>
                  <td className="value">{totalList.cert_to_date_total}</td>
                  <td className="value">{totalList.variance_to_date_total}</td>
                  <td className="value">{totalList.last_cert_total}</td>
                  <td className="white between" />
                  <td className="light_blue claim">
                    {totalList.current_claim_total}
                  </td>
                  <td className="light_blue value">{totalList.cert_total}</td>
                  <td className="white reason" />
                </tr>
              </tfoot>
            </table>
          </TableComponent>
        </PayCertTableWrapper>
        {tableField === "variation" && !contractLinked && (
          <AddBtn
            onClick={() =>
              this.setState(
                {
                  openDialog: true,
                  input_type: modalActionOptions.ADD,
                  isParent: false,
                },
                this.handleCloseEdit,
              )
            }
            disabled={openDialog}
            title="ADD ITEM"
            float="none"
            margin="20px 0 0"
          />
        )}
        <InputDialogue
          title={this.getModalTitle(editItem.description)}
          tableList={tableItem}
          field="dialog"
          modalOpen={openDialog}
          action={input_type}
          handleClose={(e) =>
            this.setState({ openDialog: false, editItem: { id: "" } })
          }
          handleSaveItem={(lineType, form) =>
            this.handleSave(editItem.id, lineType, form)
          }
          handleCleanItem={() => this.handleDeleteItem(editItem.id)}
          previousLineType={this.state.prevLineType}
          type={this.state.type}
          allowExcludeRetention={!isParent && allowExcludeRetention}
          // cert vari files
          files={
            this.getModalTitle(editItem.description).includes("Edit")
              ? editFileList && editFileList.size > 0
                ? editFileList
                : this.state.editItemFiles
              : uploadFiles
          }
          handleFilesChange={(files) => {
            handleFilesChange(files);
          }}
          uploadAttachments={uploadAttachments}
          cancelAttachment={cancelAttachment}
          deleteAttachment={deleteAttachment}
          initialFileList={initialFileList}
        />
        {certList.some((item) => item.get("required_reason")) && (
          <TableWarning>
            <div className="warningIcon" />
            <span>
              Reasons are required when changing cert values. <br />
              Please enter a reason for all the items indicated.
            </span>
          </TableWarning>
        )}
      </Fragment>
    );
  }
  componentDidMount() {
    const { proId, contractId } = this.props.match.params;
    profile.checkPermission(CONTRACT, EDIT, proId, contractId).then((res) => {
      this.setState({ allowEditContract: res });
    });
    profile.checkPermission(CONTRACT, VIEW, proId, contractId).then((res) => {
      this.setState({ allowViewContract: res });
    });
  }
  componentDidUpdate(_, prevState) {
    if (this.state.editItem && this.state.editItem !== prevState.editItem) {
      this.lineitemEditFiles(this.state.editItem?.id);
    }
  }
}

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default withRouter(connect(mapStateToProps, null)(Table));
