import { fromJS } from "immutable";
import * as constants from "./constants";

const defaultState = fromJS({
  toasts: {},
});

const addToast = (state, action) => {
  let dataUpdate = {
    ...state.get("toasts").toJS(),
    [action.id]: action.props,
  };
  localStorage.setItem("toasts", JSON.stringify(dataUpdate));

  if (action.type === constants.ADD_TOAST) {
    // if action type is ADD_TOAST, display toast normally
    return state.merge(fromJS({ toasts: dataUpdate }));
  } else {
    // do not add toast into state.toasts
    // toast will be displayed after a page refresh when it's retrieved from storage
    return state;
  }
};

const dismissToast = (state, action) => {
  let dataUpdate = { ...state.get("toasts").toJS() };
  delete dataUpdate[action?.id];

  if (Object.keys(dataUpdate).length > 0) {
    localStorage.setItem("toasts", JSON.stringify(dataUpdate));
  } else {
    localStorage.removeItem("toasts");
  }

  return state.merge(fromJS({ toasts: dataUpdate }));
};

const readStorage = (state) => {
  let dataUpdate = JSON.parse(localStorage.getItem("toasts")) || {
    ...state.get("toasts").toJS(),
  };
  return state.merge(fromJS({ toasts: dataUpdate }));
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.ADD_TOAST:
      return addToast(state, action);
    case constants.DISMISS_TOAST:
      return dismissToast(state, action);
    case constants.GET_STORAGE_TOAST:
      return readStorage(state);
    case constants.SET_STORAGE_TOAST:
      return addToast(state, action);
    default:
      return state;
  }
};
