export const tableDataParam = [
  {
    key: "baseitems",
    label: "Base Contract",
    elementKey: "base_contract",
  },
  { key: "variation_items", label: "Variations", elementKey: "variation" },
  { key: "materials", label: "Materials On/Off Site", elementKey: "material" },
];

export const getDataByElementKey = (elementKey) => {
  const entry = tableDataParam.find((entry) =>
    elementKey.includes(entry.elementKey),
  );
  return entry ? entry.key : "baseitems";
};

export const getElementKeyByLabel = (label) => {
  const entry = tableDataParam.find((entry) => label.includes(entry.label));
  return entry ? entry.elementKey : "base_contract";
};

export const getKeyByLabel = (label) => {
  const entry = tableDataParam.find((entry) => label.includes(entry.label));
  return entry ? entry.key : "baseitems";
};
