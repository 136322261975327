export function getMenuLink(
  projectInfo,
  contractId,
  contractName,
  contractType,
  claimId,
  claimTitle,
  onClick,
) {
  let contractPageType =
    contractType === "claim" || contractType === "self-cert" ? "claim" : "cert";
  let proId = projectInfo.get("id");

  let menuLinks = [
    { link: "/projects", text: "Projects" }, // projects page
    {
      link: `/project/view/${proId}`,
      text: projectInfo.get("name"),
    }, // project overview page
    {
      link: `/contract/${proId}/${contractPageType}/${contractId}`,
      text: contractName,
    }, // contract overview page
  ];
  if (claimId) {
    menuLinks.push({
      link: `/project/${proId}/contract/${contractId}/${contractType}/claim/${claimId}`,
      text: `${claimTitle ? claimTitle : "Make a Claim"}`,
      onClick: onClick,
    }); // draft claim page
  }

  return menuLinks;
}

export const ClaimContract = ["claim", "self-cert"];
