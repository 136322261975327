import React, { Component } from "react";
import Select from "react-select";
import DropdownIndicator from "common/dropdown/component/indicator";
import * as mainTheme from "../../theme";

const selectInputStyle = {
  control: (styles) => ({
    ...styles,
    width: "400px",
    height: "auto",
    borderRadius: "4px",
    border: `solid 1px ${mainTheme.palette.wolfGrey}`,
    fontSize: "14px",
    fontWeight: "bold",
    paddingLeft: "10px",
    boxShadow: "none",
    minHeight: 0,
    marginTop: "8px",
    "&:hover": {
      cursor: "pointer",
    },
  }),

  option: (provided, state) => ({
    ...provided,
    width: "400px",
    height: "44px",
    fontSize: "14px",
    paddingLeft: "10px",
    "&:hover": {
      backgroundColor: mainTheme.palette.warmLightGrey,
      cursor: "pointer",
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: "14px",
  }),
  menu: (styles) => ({
    ...styles,
    width: "400px",
    color: mainTheme.palette.gunmetal,
    fontFamily: mainTheme.font.mainFont,
  }),
  singleValue: (styles) => ({
    ...styles,
    lineHeight: "24px",
    color: mainTheme.palette.tangerineOrange,
    fontFamily: mainTheme.font.mainFont,
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: mainTheme.palette.paleYellow,
    borderRadius: " 14px",
    color: mainTheme.palette.tangerineOrange,
    padding: " 2px 14px",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: mainTheme.palette.tangerineOrange,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    "&:hover": {
      backgroundColor: mainTheme.palette.tangerineOrange,
      color: mainTheme.palette.paleYellow,
    },
  }),
};
export default class SelectCom extends Component {
  render() {
    return (
      <Select
        components={{ DropdownIndicator }}
        isMulti
        name="colors"
        options={this.props.options}
        value={this.props.value}
        onChange={(value) => {
          this.props.action(value);
        }}
        className="basic-multi-select"
        classNamePrefix="select"
        styles={selectInputStyle}
        closeMenuOnSelect={true}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: mainTheme.palette.veryWarmLightGrey,
            primary: mainTheme.palette.wolfGrey,
          },
        })}
        placeholder="Select accounts"
      />
    );
  }
}
