import styled from "styled-components";
import * as theme from "../../theme";

import Arrow from "../../statics/img/icon-dropdown-down-dark-grey.svg";
import cert from "statics/img/icon-cert-received.svg";
import claim from "statics/img/icon-claim-received.svg";
import custom from "statics/img/icon-custom.svg";

export const DropdownLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 16px;
  color: ${(props) =>
    props.buttonTheme === "primary"
      ? theme.palette.gunmetal
      : theme.palette.white};

  .label-img {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
`;

export const DropdownBadge = styled.span`
  background: ${theme.palette.rareGrey};
  margin-left: ${(props) => props.marginLeft || "15px"};
  font-size: 10px;
  display: inline-block;
  padding: 5px;
  border-radius: 4px;
  font-weight: bold;
  position: absolute;
`;
export const FlagContainer = styled.div`
  .flag-container {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    border: solid 1px ${theme.palette.wolfGrey};
    padding: 10px 15px;

    .flag {
      width: 26px;
      margin-right: 10px;
    }

    &.flag-container-small {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 8px 4px 16px;
      width: 104px;
      height: 32px;
      background: #f2f2f2;
      border-radius: 4px;
      border: none;
      .flag {
        width: 18px;
      }
    }

    .arrow {
      position: absolute;
      right: 8px;
      cursor: pointer;
      background: url(${Arrow}) no-repeat;
      width: 24px;
      height: 24px;

      &.closeArrow {
        transform: rotate(180deg);
      }
    }

    .dropdown {
      margin-top: 10px;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1;
      list-style: none;
      background-color: #fff;
      border: 1px solid #ccc;
      padding: 0;
      width: 100%;
    }

    .dropdown li {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      cursor: pointer;
    }

    .dropdown li:hover {
      background-color: #f0f0f0;
    }
  }
`;

export const OptionWrap = styled.div`
  display: flex;
  flex-direction: row;
  .img {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    flex-shrink: 0;
    &.cert {
      background: url(${cert}) no-repeat;
    }
    &.claim {
      background: url(${claim}) no-repeat;
    }
    &.custom {
      background: url(${custom}) no-repeat;
    }
  }
  .option-title {
    margin-bottom: 4px;
    font-weight: bold;
  }
  .previous-info {
    color: ${theme.palette.veryMediumGrey};
    margin-bottom: 4px;
  }
  .msg {
    font-size: 14px;
    line-height: 18px;
  }
`;
