import styled from "styled-components";
import * as theme from "../../theme";
import errorIcon from "../../statics/img/icon-information-red.svg";

export const BCTIBanner = styled.div`
  background: ${(props) =>
    props.isBCTIOn ? theme.palette.lightPastelBlue : "inherit"};
  padding: 15px;
`;

export const Error = styled.div`
  font-size: 12px;
  color: ${theme.palette.watermelonRed};
  display: flex;
  align-items: center;
  margin: -18px 0 24px 0;
  .error_img {
    background: url(${errorIcon});
    background-size: contain;
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`;

export const ModalContent = styled.div`
  max-width: 720px;
  height: auto;
  padding: 0 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  &.align-row {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .full-width {
    width: 100%;
  }
  .mini-width {
    width: 181px;
  }
  .medium-width {
    width: 284px;
  }
  .small-width {
    width: 304px;
  }
  .input-margin {
    margin: 32px 0 8px 0;
  }
  .input-small-margin {
    margin: 8px 0;
  }

  .address-container {
    margin-bottom: 18px;
  }

  input {
    width: inherit;
    outline: none;
    border-radius: 4px;
    color: ${theme.palette.gunmetal};
  }

  .flex-row {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .info-bar {
    padding: 20px;
    width: 100%;
    height: auto;
    border: 1px solid ${theme.palette.lightWolfGrey};
    border-radius: 4px;
    line-height: 20px;
    margin: 16px 0 0 0;
    position: relative;
  }

  .has_margin {
    margin-bottom: 24px;
  }
  h3 {
    font-size: 18px;
    line-height: 22px;
    font-weight: ${theme.font.mainFontWeight};
  }

  h2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: ${theme.font.mainFontWeight};
    &.hasMargin {
      margin-bottom: 8px;
    }
  }
  .has_padding {
    margin-top: 10px;
    padding-left: 32px;
  }
`;
