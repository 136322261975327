import styled from "styled-components";
import * as theme from "../../../../theme";
import checkIcon from "../../../../statics/img/icon-check-box-check-dark-grey.svg";
import uncheckIcon from "../../../../statics/img/icon-check-box-un-check-dark-grey.svg";
import helpIcon from "../../../../statics/img/icon-help-light-blue.svg";
import errorIcon from "../../../../statics/img/icon-information-red.svg";

export const HelpIconButton = styled.button.attrs({
  title: "You can check the NZBN here",
})`
  width: 16px;
  height: 16px;
  border: none;
  background: url(${helpIcon}) no-repeat;
  background-size: contain;
  margin-left: 4px;
  cursor: pointer;
`;

export const SearchPart = styled.div`
  margin: 20px 0;
`;
export const ProjectCheckBoxWrapper = styled.div`
  color: ${theme.palette.gunmetal};
  height: 40px;
  .checkBoxS {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    background: url(${uncheckIcon}) no-repeat;
    background-size: contain;
    display: block;
    cursor: pointer;
    float: left;
    margin: 0 12px 0 0;
    :checked {
      background: url(${checkIcon}) no-repeat;
    }
  }
  .message {
    float: left;
    font-size: 16px;
    font-weight: ${theme.font.normalFontWeight};
    line-height: 24px;
  }
`;
export const WrapperDetail = styled.div`
  margin: 56px 0;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  background-color: ${theme.palette.white};
  padding: 40px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
`;
export const WrapperPayerDetail = styled.div`
  height: max-content;
  width: 100%;
  background-color: ${theme.palette.white};
  box-sizing: border-box;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.edit-contact {
    margin-top: 0;
  }

  &.editS {
    height: max-content;
    border-radius: 4px;
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 56px;
  }

  .line {
    width: 1px;
    background-color: ${theme.palette.lightWolfGrey};
  }
  .addressPanel {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .spacer {
    height: 50px;
  }
`;

export const DetailsWrapper = styled.div`
  height: 100%;
  width: 492px;
  display: flex;
  flex-direction: column;
  .label {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: ${theme.font.mainFontWeight};
  }

  .company-details {
    display: grid;
    grid-template-rows: (4, auto);
    grid-row-gap: 16px;
  }

  .contact-details {
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .searchAddressClass {
    height: auto;
    margin-bottom: 0;
  }
`;

export const AddressPanel = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: 16px;
  margin-top: 32px;
  padding: 24px;
  border-radius: 4px;
  border: 1px solid ${theme.palette.rareGrey};

  &.view-only {
    grid-template-rows: repeat(3, auto);
  }

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .searchAddressClass {
    margin-top: 0;
  }
`;

export const SecondTitle = styled.div`
  margin-bottom: 26px;
  box-sizing: border-box;
  font-size: 24px;
  font-weight: ${theme.font.mainFontWeight};
  color: ${theme.palette.gunmetal};
`;

export const BottomWrapper = styled.div`
  height: 44px;
  width: 100%;
  margin-top: 56px;
`;

export const Input = styled.input`
::placeholder {
  color: ${theme.palette.wolfGrey};
}
:read-only{
  background-color: ${theme.palette.greyWhite};
}

width:${(props) => props.width || "446px"}
color: ${theme.palette.gunmetal};
height:44px;
font-size: 16px;
font-weight:${theme.font.normalFontWeight};
padding: 0 16px;
box-sizing: border-box;
background-color: ${theme.palette.white};
border: 1px solid ${theme.palette.wolfGrey};
font-family: ${theme.font.mainFont};
border-radius: 4px;
&.error {
  border: 1px solid ${theme.palette.watermelonRed};
}
`;
export const Label = styled.div`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: ${theme.font.mainFontWeight};
  display: flex;

  .required {
    color: ${theme.palette.watermelonRed};
  }
`;
export const HelperText = styled.div`
  margin-top: 2px;
  font-size: 12px;
  display: flex;
`;

export const Error = styled.div`
  font-size: 12px;
  color: ${theme.palette.watermelonRed};
  display: flex;
  align-items: center;
  margin: 4px 0;
  .error_img {
    background: url(${errorIcon});
    background-size: contain;
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`;
