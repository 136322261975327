import * as constants from "./constants";
import { actionCreators as actionCreatorsModal } from "../../../common/modal";
import { sortByLabel } from "../../../utils/helper";
import API from "../../../server";
import { STATUS_ARCHIVE } from "../../../utils/constants";

export const readAllInfo = (code) => {
  return (dispatch) => {
    API.read_invitation(code)
      .then((res) => res.data)
      .then((res) => res.data.cert)
      .then((res) => {
        dispatch(setAllInfo(res));
        dispatch(setData("loading", false));
      });
  };
};

export const setAllInfo = (data) => ({
  type: constants.RESET_ALL_DATA,
  data,
});
export const acceptInvitation = (code, projectId, contactId, accId) => {
  return (dispatch) => {
    API.accept_invitation(accId, projectId, contactId, code)
      .then((res) => res.data)
      .then((res) => {
        dispatch(
          actionCreatorsModal.showModal("confirm", {
            open: true,
            title: "Success!",
            message: "You have accepted the invitation successfully！",
            url: `/project/view/${projectId}?account_id=${accId}`,
          }),
        );
      });
  };
};

export const declineInvitation = (code) => {
  return (dispatch) => {
    API.decline_invitation(code)
      .then((res) => res.data)
      .then((res) => {
        dispatch(
          actionCreatorsModal.showModal("confirm", {
            open: true,
            title: "Success!",
            message: "You have declined the invitation successfully！",
            url: "/",
          }),
        );
      });
  };
};

export const createProject = (inviteId, data) => {
  return (dispatch) => {
    API.copy_project_info(inviteId, data.account)
      .then((res) => res.data)
      .then((res) => {
        dispatch(
          acceptInvitation(
            inviteId,
            res.data.contract_group_id,
            data.contact,
            data.account,
          ),
        );
      });
  };
};

export const setInfo = (field, data) => ({
  type: constants.RESET_ALL_PROJECTS,
  field,
  data,
});

export const setData = (field, data) => ({
  type: constants.SET_DATA,
  field,
  data,
});

export const setProjectOption = (accountId) => {
  return (dispatch) => {
    API.read_projects(accountId)
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        let options = [];
        res.map((ele) => {
          if (ele.status !== STATUS_ARCHIVE) {
            options.push({
              label: ele.name,
              value: ele.id,
              accountId: accountId,
              user_role: ele?.user_role,
            });
          }
          return null;
        });
        let projectList = options.sort(sortByLabel);
        dispatch(setData("projectList", projectList));
      });
  };
};

export const setContactOption = (accountId) => {
  return (dispatch) => {
    API.read_account_user("all", "active", accountId)
      .then((res) => res.data)
      .then((res) => res.data.users)
      .then((res) => {
        let option = res.map((ele) => ({ label: ele.name, value: ele.id }));
        dispatch(setData("contactList", option));
      });
  };
};

export const resetInfo = () => ({
  type: constants.RESET,
});
