import React from "react";
import { FilterContainer, FilterForm } from "../styled";
import Badge from "@material-ui/core/Badge";
import { AntSwitch } from "../../../common/switch";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

export default function Filter(props) {
  const { accountConfig } = props;
  const [expand, setExpand] = React.useState(false);
  const handleChange = (name) => (event) => {
    props.handleFilter(name, event.target.checked);
  };
  return (
    <ClickAwayListener onClickAway={() => setExpand(false)}>
      <FilterContainer>
        <span>Filters {props.hasFilter && "applied"}</span>
        <Badge
          color="secondary"
          overlap="rectangular"
          badgeContent=" "
          variant="dot"
          invisible={!props.hasFilter}
        >
          <div className="filterIcon" onClick={() => setExpand(!expand)} />
        </Badge>
        <FilterForm expand={expand}>
          <div className="title">Task Type</div>
          <div className="list_item">
            <input
              type="checkbox"
              id="claim"
              checked={props.filterOptions.claim}
              onChange={handleChange("claim")}
            />
            <label htmlFor="claim">
              {accountConfig?.getIn(["claim", "noun"]) + "s"}
            </label>
          </div>
          <div className="list_item">
            <input
              type="checkbox"
              id="cert"
              checked={props.filterOptions.cert}
              onChange={handleChange("cert")}
            />
            <label htmlFor="cert">Certificates</label>
          </div>
          <hr />
          <div className="title">Optional Task</div>
          <div className="list_item">
            <AntSwitch
              checked={props.filterOptions.showOptional}
              onChange={handleChange("showOptional")}
              name="showOptional"
              id="showOptional"
            />
            <label htmlFor="showOptional" style={{ marginLeft: 16 }}>
              Show optional tasks
            </label>
          </div>
        </FilterForm>
      </FilterContainer>
    </ClickAwayListener>
  );
}
