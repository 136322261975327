import React, { useState } from "react";
import { ClickAwayListener } from "@material-ui/core";

import { ListItem } from "../style";

import BootstrapTooltip from "../../../../../common/toolTip";
import UserActions from "./userAction";
import ScopeModal from "./userAccessScope";

const UserItem = (props) => {
  const { item } = props;
  const [edit, setEdit] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [explicitModal, setExplicitModal] = useState(false);

  const contractNum = item?.get("contractNum") || 0,
    projectNum = item?.get("projectNum") || 0;
  return (
    <ListItem className="user">
      <div className="icon" />
      <div className="align_middle">
        <BootstrapTooltip title={item.get("name")} placement="top">
          <div className="person_name">{item.get("name")}</div>
        </BootstrapTooltip>
      </div>
      <div className="align_middle">
        {item.get("role_id").replace(/^\S/, (s) => s.toUpperCase())}
      </div>

      <>
        {contractNum === 0 && projectNum === 0 ? (
          <div className="light_grey_text align_middle">No Explicit Access</div>
        ) : (
          <div className="light_border" onClick={() => setExplicitModal(true)}>
            {projectNum !== 0 && (
              <div>
                {projectNum} {projectNum > 1 ? "projects" : "project"}
              </div>
            )}
            {contractNum !== 0 && (
              <div>
                {contractNum} {contractNum > 1 ? "contracts" : "contract"}
              </div>
            )}
          </div>
        )}
      </>
      <ScopeModal
        isOpen={explicitModal}
        close={() => setExplicitModal(false)}
        data={item}
      />
      <div>
        <BootstrapTooltip title={item.get("email")} placement="top">
          <div>{item.get("email")}</div>
        </BootstrapTooltip>
        <div>{item.get("phone")}</div>
      </div>

      <div className="align_middle">
        {item.get("status") === "active" ? "Active" : "Inactive"}
      </div>
      <ClickAwayListener
        onClickAway={() => {
          setEdit(false);
          setEditItem({});
        }}
      >
        <div className="align_middle actionWrap">
          <div
            className="actionIcon"
            onClick={() => {
              setEdit(!edit);
              setEditItem(edit ? {} : item);
            }}
          />
          <UserActions show={edit} item={editItem} {...props} />
        </div>
      </ClickAwayListener>
    </ListItem>
  );
};
export default UserItem;
