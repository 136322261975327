import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  Container,
  ProjectDetailWrapper,
  AdditionalWrapper,
  LinkLabel,
  CloseIcon,
  Error,
  LinkedBanner,
  IconLink,
} from "../../project/styled";

import Input from "../../../common/form";
import Address from "./address";

const RequiredInput = (props) => {
  return (
    <div>
      <Input {...props} />
      {props.error && props.defaultValue === "" && (
        <Error className="error">
          <div className="error_img" />
          {props.error}
        </Error>
      )}
    </div>
  );
};

const ContractDetails = (props) => {
  const { contractInfo, config, updateInfo, readOnly, accountConfig } = props;
  const [openDes, setOpenDes] = useState(false);
  const [info, setInfo] = useState({});
  const [tradeOptions, setTradeOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [addressInfo, setAddressInfo] = useState({});
  const isLinked = contractInfo.get("integration") ? true : false;

  const handleChange = (e, field) => {
    let name = field || e.target.name,
      value = field ? e : e.target.value;
    if (field === "trade") {
      if (e === "Other") {
        setInfo({ ...info, tradeText: "Other", [name]: value });
      } else {
        setInfo({ ...info, tradeText: "", trade: e });
      }
      updateInfo({ trade: value });
    } else if (name === "tradeText") {
      updateInfo({ trade: value });
    } else if (name === "street_address") {
      setInfo({ ...info, [name]: value });
      if (value) {
        updateInfo({ [name]: value });
        setErrors({ street_address: "" });
      } else {
        setErrors({ street_address: "Required" });
      }
    } else {
      setInfo({ ...info, [name]: value });
      updateInfo({ [name]: value });
    }
  };

  // separate address field changes handling
  const handleAddressChange = (e, field) => {
    let name = field || e.target.name,
      value = field ? e.value : e.target.value;
    let newAddress = { ...addressInfo };
    newAddress[name] = value;
    setAddressInfo({ ...newAddress });
    updateInfo({ ...newAddress });
  };

  const handleSetAddress = (newAddress) => {
    // only apply address update if required field is included
    const applyUpdate = newAddress.street_address ? true : false;
    newAddress.postal_code = newAddress.postcode; // 'postal_code' is the term used
    if (applyUpdate) {
      setAddressInfo({ ...newAddress });
      updateInfo({ ...newAddress });
    }
  };

  const cancelAddDes = () => {
    setOpenDes(false);
    setInfo({ ...info, description: "" });
    updateInfo({ description: "" });
  };

  useEffect(() => {
    let options = [],
      tradeOption = "";
    if (config.payclaim) {
      config.payclaim.trade_options.map((ele) =>
        options.push({
          label: ele,
          value: ele,
        }),
      );
      if (!config.payclaim.trade_options.includes(contractInfo.get("trade"))) {
        tradeOption = "Other";
      }
    }
    setTradeOptions(options);
    setInfo({
      contract_number: contractInfo.get("contract_number"),
      description: contractInfo.get("description"),
      po_number: contractInfo.get("po_number"),
      payee_ref: contractInfo.get("payee_ref"),
      name: contractInfo.get("name"),
      tradeText: contractInfo.get("trade"),
      trade: tradeOption || contractInfo.get("trade"),
    });

    // address
    setAddressInfo({
      country: contractInfo.get("country"),
      state: contractInfo.get("state"),
      city: contractInfo.get("city"),
      suburb: contractInfo.get("suburb"),
      street_address: contractInfo.get("street_address"),
      postal_code: contractInfo.get("postal_code"),
    });

    if (contractInfo?.get("description") !== "") {
      setOpenDes(true);
    }
  }, [config, contractInfo]);

  return (
    <Container className={props.className} id="nav-contractInfo">
      <ProjectDetailWrapper className="grid" style={{ margin: "56px auto" }}>
        <div className="details">
          <h3>Contract Name</h3>
          <RequiredInput
            name="name"
            defaultValue={info.name}
            className="input-field_name no-margin"
            placeholder="Untitled Contract"
            onBlur={handleChange}
            error={"Name cannot be empty"}
            disabled={readOnly || isLinked}
            field={isLinked ? "link-field" : ""}
          />

          {openDes ? (
            <div className={isLinked ? "" : "des-wrapper no-margin"}>
              <Input
                name="description"
                defaultValue={contractInfo?.get("description")}
                className={isLinked ? "" : "input-field_description"}
                placeholder="Add description for the contract"
                onBlur={handleChange}
                disabled={readOnly || isLinked}
                field={isLinked ? "link-field" : ""}
                width={isLinked ? "875px" : ""}
              />
              {!(isLinked && contractInfo?.get("description")) && (
                <CloseIcon onClick={cancelAddDes} title="remove description" />
              )}
            </div>
          ) : (
            <LinkLabel className="no-margin" onClick={() => setOpenDes(true)}>
              Add Description (Optional)
            </LinkLabel>
          )}
        </div>
        {/* Address Section */}
        <Address
          className="top-margin"
          readOnly={readOnly || isLinked}
          setTargetAddress={handleSetAddress}
          handleChange={handleAddressChange}
          address={addressInfo}
          errors={errors}
          isSettings
          accountConfig={accountConfig}
          linked={isLinked}
        />
        {/* Contract Details */}
        <AdditionalWrapper className="top-margin">
          <div className="flex-row">
            <div>
              <Input
                label="Trade/Discipline"
                name="trade"
                value={info.trade}
                field="dropdown"
                placeholder="Select Trade/Discipline"
                className="input-field"
                onChange={(e) => handleChange(e.value, "trade")}
                options={tradeOptions}
                width={info?.trade !== "Other" ? "826px" : "400px"}
                disabled={readOnly}
              />
              <div className="append-meg">
                If base contract items are different, this will be updated to
                Mixed Discipline.
              </div>
            </div>
            {info?.trade === "Other" && (
              <Input
                name="tradeText"
                defaultValue={info.tradeText}
                placeholder="Type Trade/Discipline"
                className="input-field middle"
                onBlur={handleChange}
                disabled={readOnly}
              />
            )}
          </div>
          <div className="flex-row">
            <Input
              label="Contract Number"
              name="contract_number"
              defaultValue={contractInfo.get("contract_number")}
              className="input-field"
              placeholder="Enter number"
              onBlur={handleChange}
              disabled={readOnly || isLinked}
              field={isLinked ? "link-field" : ""}
            />
          </div>
          <div className="flex-row">
            <Input
              label="Payee Reference"
              name="payee_ref"
              defaultValue={contractInfo.get("payee_ref")}
              className="input-field middle"
              placeholder="Internal Reference"
              onBlur={handleChange}
              disabled={readOnly}
            />
            <Input
              label="Payer Reference (PO Number/Contract Reference)"
              name="payer_ref"
              defaultValue={contractInfo?.get("payer_ref")}
              placeholder="Enter number"
              className="input-field middle"
              onBlur={handleChange}
              disabled={readOnly}
            />
          </div>
        </AdditionalWrapper>
        {contractInfo.get("integration") && (
          <LinkedBanner className="contract settings">
            <IconLink />
            <div>
              This contract is linked from another service so some details are
              provided and cannot be edited.
            </div>
            {/* Hidden for now as webpage does not exist yet
            <div className="webpage-link">
              <a href="">Find out more</a>
            </div>
            */}
          </LinkedBanner>
        )}
      </ProjectDetailWrapper>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default connect(mapStateToProps, null)(ContractDetails);
