import React, { Component } from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../common/header/store";
import { Top } from "./styled";
import Select from "common/dropdown/dropdown_with_label";
import ListTable from "./components/list";
import { BgWrapper, HeaderTitle } from "common/shared_style";

function checkList(list) {
  var now = Date.now(),
    stime = new Date(now).getTime(),
    recentList = [],
    earlierList = [];
  if (list) {
    list.map((item) => {
      var etime = new Date(item.get("createdate")).getTime();
      var usedTime = stime - etime;
      var days = Math.floor(usedTime / (24 * 3600 * 1000));
      if (days >= 1) {
        return earlierList.push(item);
      } else {
        return recentList.push(item);
      }
    });
  }
  return { recentList, earlierList };
}

class index extends Component {
  constructor() {
    super();
    this.handleFilterType = this.handleFilterType.bind(this);
    this.state = { filterType: "all" };
  }
  handleFilterType(event) {
    if (event != null) {
      return this.setState({
        filterType: event.value,
      });
    } else {
      return;
    }
  }

  render() {
    const valueFromId = (opts, id) => opts.find((o) => o.value === id);
    const { filterType } = this.state;
    const { allNotice, deleteNotice, accountConfig } = this.props;
    const options = [
      { label: "All Notifications", value: "all" },
      { label: `${accountConfig?.getIn(["claim", "noun"])}`, value: "claim" },
      { label: `${accountConfig.getIn(["cert", "noun"])}s`, value: "cert" },
      { label: "Project Invitations", value: "invitation" },
      { label: "System Notifications", value: "system" },
    ];
    const obj = checkList(allNotice, filterType);

    return (
      <BgWrapper>
        <Top>
          <HeaderTitle style={{ float: "left" }}> Notifications </HeaderTitle>
          <div className="filter">
            <div className="filterText">Show:</div>
            <div style={{ float: "left" }}>
              <Select
                value={valueFromId(options, filterType)}
                onChange={this.handleFilterType}
                name="filterType"
                defaultValue="all"
                options={options}
                width="242px"
                controlWidth="176px"
                margin="10px 0 0 -66px"
              />
            </div>
          </div>
        </Top>
        <ListTable
          filterType={filterType}
          list={obj.recentList}
          deleteNotice={(id) => deleteNotice(id)}
          title="Recent"
          accountConfig={accountConfig}
        />
        <ListTable
          filterType={filterType}
          list={obj.earlierList}
          deleteNotice={(id) => deleteNotice(id)}
          title="Earlier"
          accountConfig={accountConfig}
        />
      </BgWrapper>
    );
  }
  componentDidMount() {
    this.props.getAllNotice();
  }
}

const mapStateToProps = (state) => ({
  allNotice: state.getIn(["headers", "allNotice"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getAllNotice() {
      dispatch(actionCreators.readAllNotice());
    },
    deleteNotice(noticeId) {
      dispatch(actionCreators.deleteNotice(noticeId));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(index);
