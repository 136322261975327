import React, { useState } from "react";
import Flags from "country-flag-icons/react/3x2";
import { DEFAULT_COUNTRY } from "utils/constants";
import { FlagContainer } from "./styled";

const DropdownCountry = (props) => {
  const {
    handleChange,
    countriesList,
    country,
    className,
    displayBriefName,
    disabled,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const selectedCountry = countriesList.find(
    (option) => option.value === country,
  );

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCountrySelect = (code) => {
    handleChange("country", code);
    toggleDropdown();
  };

  const renderFlag = (countryCode) => {
    const Flag = Flags[countryCode];
    return Flag ? <Flag className="flag" /> : null;
  };

  const FullNameCountry = () => (
    <FlagContainer className={className}>
      <div
        className={`flag-container`}
        onClick={() => {
          !disabled && toggleDropdown();
        }}
      >
        {renderFlag(country || DEFAULT_COUNTRY)}
        <span>{selectedCountry?.label}</span>
        {!disabled && (
          <span className={`${isOpen ? "arrow closeArrow" : "arrow"}`} />
        )}
        {isOpen && (
          <ul className="dropdown">
            {countriesList.map((country) => (
              <li
                key={country.value}
                onClick={() => handleCountrySelect(country.value)}
              >
                {renderFlag(country.value)}
                <span>{country.label}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </FlagContainer>
  );

  const BriefNameCountry = () => (
    <FlagContainer className={className}>
      <div
        className={"flag-container flag-container-small"}
        onClick={() => {
          !disabled && toggleDropdown();
        }}
      >
        {renderFlag(selectedCountry?.value || DEFAULT_COUNTRY)}
        <span>{selectedCountry?.value}</span>
        {!disabled && (
          <span className={`${isOpen ? "arrow closeArrow" : "arrow"}`} />
        )}
        {isOpen && (
          <ul className="dropdown">
            {countriesList.map((country) => (
              <li
                key={country.value}
                onClick={() => handleCountrySelect(country.value)}
              >
                {renderFlag(country.value)}
                <span>{country.value}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </FlagContainer>
  );

  return <>{!displayBriefName ? <FullNameCountry /> : <BriefNameCountry />}</>;
};

export default DropdownCountry;
