import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import * as theme from "../../../theme";
import {
  InvitationWrap,
  RequiredWrap,
  InvitationTable,
  NoticeWrap,
  CompanyImage,
} from "../styled";

import {
  BlueOutlinedBtn as ButtonSecondary,
  BlueWhiteBtn as Button,
  RedOutlinedBtn as ButtonRed,
} from "../../../common/button";
import Modal from "../../project/components/modal";
import SelfInvitationModal from "./selfClaimCert";
import Input from "../../../common/form";
import { actionCreators } from "../store";
import { capitalizeAddress } from "../../../utils/helper";

const InvitationStatus = {
  WAIT: "waiting",
  CANCEL: "cancel",
  APPROVE: "approved",
};

const InvitationKind = {
  INVITING: "inviting",
  INVITED: "invited",
  SELF_INVITED: "self-invited",
  DEFAULT: "",
};

function displayInviteStatus(status) {
  // transfer invitation status to status content
  switch (status) {
    case InvitationStatus.APPROVE:
      return (
        <center>
          <div className="img_accept" />
        </center>
      );
    case InvitationStatus.WAIT:
      return (
        <center style={{ color: theme.palette.veryMediumGrey }}>Pending</center>
      );
    case InvitationStatus.CANCEL:
      return (
        <center
          style={{ color: theme.palette.veryMediumGrey, fontStyle: "italic" }}
        >
          Declined
        </center>
      );
    default:
      return null;
  }
}
function checkName(type, claimer, certer) {
  // transfer invitation type name
  if (type === "cert") {
    return !certer ? "—" : certer;
  } else {
    return !claimer ? "—" : claimer;
  }
}

const InvitationModal = (props) => {
  // invitation third-party modal
  const { open, otherParty, submit, setOpen } = props;
  const [info, setInfo] = useState({ companyName: "", email: "" });
  useEffect(() => {
    setInfo({ companyName: "", email: "" });
  }, [open]);
  return (
    <Modal
      title={"Invite " + otherParty}
      open={open}
      close={() => setOpen(false)}
      submit={() => submit(info)}
      disabled={!(info.companyName && info.email)}
    >
      <>
        <div>
          Enter the email of the person you would like to invite to join this
          contract.
        </div>
        <Input
          label="Company Name"
          wrapperClassName="input-margin"
          value={info.companyName}
          onChange={(e) => setInfo({ ...info, companyName: e.target.value })}
        />
        <Input
          label="Email Address"
          wrapperClassName="input-margin"
          value={info.email}
          onChange={(e) => setInfo({ ...info, email: e.target.value })}
        />
      </>
    </Modal>
  );
};

const HistoryModal = (props) => {
  // invitation history table
  const {
    open,
    data,
    setOpen,
    deleteInvitation,
    cancelInvitation,
    readOnly,
    accountConfig,
  } = props;
  const claimerWord =
    accountConfig?.getIn(["claim", "noun"]) === "Claim"
      ? "Claimer"
      : accountConfig?.getIn(["claim", "name"]);
  return (
    <Modal
      title={"Invites"}
      open={open}
      close={() => setOpen(false)}
      hideActions
    >
      <InvitationTable>
        <table>
          <thead>
            <tr>
              <th />
              <th className="company">Company</th>
              <th className="type">Type</th>
              <th>Email</th>
              <th className="phone">Phone</th>
              <th className="statue">
                <center>Invitation Status</center>
              </th>
              {!readOnly && <th />}
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.get("id")}>
                <td>
                  <div className="img_company" />
                </td>
                <td>
                  {checkName(
                    item.get("type"),
                    item.get("claimer"),
                    item.get("certer"),
                  )}
                </td>
                <td>
                  {item.get("type") === "cert"
                    ? "Contract Certifier"
                    : `Contract ${claimerWord}`}
                </td>
                <td>{item.get("email")}</td>
                <td>{item.get("phone") === "" ? "—" : item.get("phone")}</td>
                <td>{displayInviteStatus(item.get("status"))}</td>
                {!readOnly && (
                  <td>
                    <div className="actions">
                      <div
                        className="img_actions"
                        onClick={() =>
                          item.get("status") === InvitationStatus.WAIT
                            ? cancelInvitation(item.get("id"))
                            : deleteInvitation(item.get("id"))
                        }
                      />
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </InvitationTable>
    </Modal>
  );
};

const InvitationBar = (props) => {
  const { proId, contractId, type } = props.match.params;

  const invitationType = type !== "claim" ? "Claimer" : "Certifier";

  const {
    invitationList,
    contractInfo,
    sendInvitation,
    deleteInvitation,
    cancelInvitation,
    readInvitationList,
    readOnly,
    accountConfig,
  } = props;
  const [open, setOpen] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [openSelfInvite, setOpenSelfInvite] = useState(false);
  const [invitationStatus, setInvitationStatus] = useState(
    InvitationKind.DEFAULT,
  );
  const [invitedInfo, setInvitedInfo] = useState({});

  useEffect(() => {
    readInvitationList(proId, contractId);
  }, [readInvitationList, proId, contractId]);
  useEffect(() => {
    let thirdAccount = "claim_account",
      contact = "payee";
    if (type === "claim") {
      thirdAccount = "cert_account";
      contact = "payer";
    }
    let contactInfo = !contractInfo.get(contact)
      ? {}
      : contractInfo.get(contact).toJS();
    let companyInfo = !contractInfo.getIn([contact + "_company", "entity_id"])
      ? {}
      : contractInfo.get(contact + "_company").toJS();

    if (
      contractInfo.get(thirdAccount) !== undefined &&
      contractInfo.get(thirdAccount) !== null
    ) {
      //if third party accept contract
      //set invitation status to invited and display invitee info
      setInvitationStatus(InvitationKind.INVITED);
      let entity = contractInfo.get(thirdAccount).toJS();
      let invitedInfo = Object.assign({}, contactInfo, {
        entity_name: entity.name,
        entity_phone: entity.phone,
        website: entity.website,
        account_image_url: entity.account_image_url,
      });
      setInvitedInfo(invitedInfo);
    } else {
      if (
        //if invitation list to check whether there is invitation send out
        //if has, set invitation status to inviting and display invitation history
        invitationList.size !== 0 &&
        invitationList?.last()?.get("status") === InvitationStatus.WAIT
      ) {
        setInvitationStatus(InvitationKind.INVITING);
      } else if (contactInfo.id && companyInfo.entity_id) {
        // if there is third-party account bind and has payee/payer contact
        // it will be self-claim/self-cert for now
        // just display contact info
        setInvitationStatus(InvitationKind.SELF_INVITED);
        setInvitedInfo(Object.assign({}, contactInfo, companyInfo));
      } else {
        // if no invitation/no self payer/payee, set default
        setInvitationStatus(InvitationKind.DEFAULT);
      }
    }
  }, [contractInfo, invitationList, type]);

  //hide or display invitation declined history
  const showDeclineRecord =
    invitationList.size !== 0 &&
    invitationList.every(
      (invite) => invite.get("status") === InvitationStatus.CANCEL,
    ) &&
    invitationStatus !== "self-invited";
  // local terms
  const claimerWord =
    accountConfig?.getIn(["claim", "noun"]) === "Claim"
      ? "Claimer"
      : accountConfig?.getIn(["claim", "name"]);

  // display the latest person who declined the invitation
  let declinePersonEmail = "";
  if (showDeclineRecord) {
    declinePersonEmail = invitationList
      .findLast((item) => item.get("status") === InvitationStatus.CANCEL)
      .get("email");
  }

  const InviteHistory = () => (
    // the link to show all invites
    <>
      <div className="all-link" onClick={() => setOpenHistory(true)}>
        See All Invites
      </div>
      {openHistory && (
        <HistoryModal
          open={openHistory}
          otherParty={invitationType}
          data={invitationList}
          setOpen={setOpenHistory}
          readOnly={readOnly}
          deleteInvitation={(id) =>
            deleteInvitation(proId, contractId, type, id)
          }
          cancelInvitation={(id) => cancelInvitation(proId, contractId, id)}
          accountConfig={accountConfig}
        />
      )}
    </>
  );
  return (
    <>
      {showDeclineRecord && (
        <NoticeWrap>
          <div>
            <div className="warning-icon" />
            <span>{declinePersonEmail} has declined your invite.</span>
          </div>
          <InviteHistory />
        </NoticeWrap>
      )}

      <InvitationModal
        open={open}
        setOpen={setOpen}
        otherParty={invitationType === "Claimer" ? claimerWord : invitationType}
        submit={(info) => {
          sendInvitation(proId, contractId, info, type);
          setOpen(false);
        }}
      />

      <SelfInvitationModal
        open={openSelfInvite}
        setOpen={setOpenSelfInvite}
        otherParty={invitationType}
        invitedInfo={invitationStatus === "self-invited" ? invitedInfo : null}
        {...props}
      />

      {!invitationStatus && (
        <InvitationWrap isClaim={type !== "claim"}>
          <div>
            <div className="person-icon" />
            <h1>
              Invite{" "}
              {invitationType === "Claimer" ? claimerWord : invitationType}
            </h1>
            <RequiredWrap>
              <label>Required</label>
              <div className="required-icon" />
            </RequiredWrap>
          </div>
          <div>
            This contract is in draft and ready for the other party. Once they
            accept the contract, their details will be be shown here. If you{" "}
            {type !== "claim"
              ? `use the self-${accountConfig
                  ?.getIn(["claim", "noun"])
                  ?.toLowerCase()} feature`
              : " are self-certifying"}
            , you will need to enter their details manually.
          </div>
          <div>
            {!readOnly && (
              <React.Fragment>
                <Button
                  title="invite"
                  margin={"0 0 0 16px"}
                  onClick={() => setOpen(true)}
                />
                <ButtonSecondary
                  title={
                    type !== "claim"
                      ? `Self-${accountConfig?.getIn(["claim", "noun"])}`
                      : "Self-Cert"
                  }
                  onClick={() => setOpenSelfInvite(true)}
                />
              </React.Fragment>
            )}
          </div>
        </InvitationWrap>
      )}

      {(invitationStatus === "self-invited" ||
        invitationStatus === "invited") && (
        <>
          <InvitationWrap className="full-info">
            <div>
              <div className="person-icon" />
              <h1>
                {invitationType === "Claimer" ? claimerWord : invitationType}
              </h1>
              {invitationStatus === "self-invited" && (
                <React.Fragment>
                  <div className="notice-type">
                    {type !== "claim"
                      ? `Self-${accountConfig?.getIn(["claim", "noun"])}`
                      : "Self-Certifying"}
                  </div>
                  {!readOnly && (
                    <ButtonSecondary
                      title="edit details"
                      onClick={() => setOpenSelfInvite(true)}
                    />
                  )}
                </React.Fragment>
              )}
            </div>
            <div>
              <div className="flex-wrap">
                <div className="pending-wrap approved">
                  {invitationStatus === "invited" && (
                    <div className="person-avatar" />
                  )}
                  <h2 className="has-margin">{invitedInfo.name}</h2>
                  <h3>{invitedInfo.email}</h3>
                  <h3>{invitedInfo.phone}</h3>
                </div>
                <div className="pending-wrap approved">
                  {invitationStatus === "invited" && (
                    <CompanyImage url={invitedInfo.account_image_url} />
                  )}
                  <h2 className="has-margin">{invitedInfo.entity_name}</h2>
                  {invitationStatus === "self-invited" ? (
                    <>
                      {capitalizeAddress(
                        invitedInfo.street_address,
                        invitedInfo.suburb,
                        invitedInfo.city,
                        invitedInfo.postal_code,
                        invitedInfo.state,
                        invitedInfo.country,
                      )}
                    </>
                  ) : (
                    <>
                      <h3>{invitedInfo.website}</h3>
                      <h3>{invitedInfo.entity_phone}</h3>
                    </>
                  )}
                </div>
              </div>

              {invitationStatus === "self-invited" && !readOnly && (
                <>
                  <div className="append-info">
                    Has the{" "}
                    {invitationType === "Claimer"
                      ? claimerWord
                      : invitationType}{" "}
                    joined PayLab? Invite them to the project.
                  </div>
                  <Button
                    title={`Invite ${
                      invitationType === "Claimer"
                        ? claimerWord
                        : invitationType
                    }`}
                    onClick={() => setOpen(true)}
                  />{" "}
                </>
              )}
            </div>
          </InvitationWrap>
        </>
      )}

      {invitationStatus === "inviting" && (
        <>
          <InvitationWrap className="full-info">
            <div>
              <div className="person-icon" />
              <h1>
                {invitationType === "Claimer" ? claimerWord : invitationType}
              </h1>
              <RequiredWrap>
                <label>Required</label>
                <div className="required-icon" />
              </RequiredWrap>
              <InviteHistory />
            </div>
            <>
              {invitationList.map((ele) => {
                if (ele.get("status") === InvitationStatus.WAIT) {
                  return (
                    <div key={ele.get("id")}>
                      <div className="pending-wrap" key={ele.get("id")}>
                        <div className="pending-notice">Pending Acceptance</div>
                        <h2 className="has-margin">{ele.get("email")}</h2>
                        <h3>
                          Invited{" "}
                          {invitationType === "Claimer"
                            ? claimerWord
                            : invitationType}{" "}
                          needs to accept contract.
                        </h3>
                      </div>
                      {!readOnly && (
                        <div>
                          <ButtonRed
                            title="cancel invite"
                            onClick={() => {
                              cancelInvitation(
                                proId,
                                contractId,
                                ele.get("id"),
                              );
                              deleteInvitation(
                                proId,
                                contractId,
                                type,
                                ele.get("id"),
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                }
                return null;
              })}
            </>
          </InvitationWrap>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  invitationList: state.getIn(["contract", "invitationList"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    readInvitationList(proId, contractId) {
      dispatch(actionCreators.readInvitationList(proId, contractId));
    },
    sendInvitation(proId, contractId, info, contractType) {
      dispatch(
        actionCreators.sendInvitation(proId, contractId, info, contractType),
      );
    },
    deleteInvitation(proId, contractId, type, invitationId, showWarning) {
      dispatch(
        actionCreators.deleteInvitation(
          proId,
          contractId,
          type,
          invitationId,
          showWarning,
        ),
      );
    },
    cancelInvitation(proId, contractId, invitationId) {
      dispatch(
        actionCreators.cancelInvitation(proId, contractId, invitationId),
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InvitationBar);
