import * as constants from "./constants";
import { fromJS } from "immutable";

const defaultState = fromJS({
  projectList: [],
  contactList: [],
  contractInfo: {},
  projectInfo: {},
  loading: true,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_ALL_DATA:
      return state
        .set("contractInfo", fromJS(action.data.contract))
        .set("projectInfo", fromJS(action.data.project));
    case constants.RESET_ALL_PROJECTS:
      return state.set(
        action.field,
        !action.data ? fromJS([]) : fromJS(action.data),
      );
    case constants.SET_DATA:
      return state.set(action.field, action.data);
    case constants.RESET:
      return defaultState;
    default:
      return state;
  }
};
