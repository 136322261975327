import { fromJS } from "immutable";
import * as constants from "./constants";
const defaultState = fromJS({
  entityList: [],
  selectedSortOption: "company.entity_name",
  selectedContactId: "",
  selectedEntityId: "",
  companyInfo: {},
  useExistingEntity: false,
  contactList: [],
  allContact: [],
  entityId: "",
  contactId: "",
  contactInfo: {},
  entityInfo: {},
  saved: false,
  is_dialog_open: false,
  csvFile: [],
  formValue: {},
  loading: true,
});

const returnContactInfo = (state, action) => {
  if (action.field === "entity") {
    return state.set("entityInfo", fromJS(action.contactList));
  } else {
    return state.set("contactInfo", fromJS(action.contactList));
  }
};
const setDetailContact = (state, action) => {
  if (action.field === "entity") {
    return state.set("companyInfo", fromJS(action.info));
  } else if (action.field === "contact") {
    return state.set("payerContactInfo", fromJS(action.info));
  } else if (action.field === "project") {
    return state.set("projectContactInfo", fromJS(action.info));
  }
};

const setSelectedInfo = (state, action) => {
  if (action.name === "selectedContactId") {
    if (action.id === "") {
      return state
        .set(action.name, action.id)
        .set("contactList", state.get("allContact"));
    } else {
      let selectedItem = [];
      var list = state.get("allContact");
      selectedItem = list.filter(
        (item) =>
          item.get("contact_id") === action.id ||
          item.get("entity_id") === action.id,
      );
      return state
        .set(action.name, action.id)
        .set("contactList", fromJS(selectedItem));
    }
  }
  return state.set(action.name, action.id);
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SET_ALL_ENTITY_OPTION:
      return state.set(
        "entityList",
        !action.payload ? fromJS([]) : fromJS(action.payload),
      );
    case constants.SELECTED_INFO:
      return setSelectedInfo(state, action);
    case constants.SET_DETAIL_CONTACT_INFO:
      return setDetailContact(state, action);
    case constants.RESET_STAGE:
      return defaultState;
    case constants.CHANGE_LIST_DATA:
      return state
        .set("contactList", fromJS(action.contactList))
        .set("allContact", fromJS(action.contactList))
        .set("selectedContactId", "");
    case constants.SET_CONTACT_INFO:
      return returnContactInfo(state, action);
    case constants.SET_DATA:
      return state.set(action.name, fromJS(action.data));
    default:
      return state;
  }
};
