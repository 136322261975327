import React, { Component } from "react";
import { ProjectWrapper, ProjectItems, TopWrapper } from "../styled";
import PageBarPart from "../../../common/pagination";
import GlobalSearchComponent from "./search_input";
import { OtherTable } from "./table_component";
import Select from "../../dashboard/components/select_frame";

function compareByOption(field) {
  let name = field.split("$")[0];
  let reverse = field.split("$")[1] === "up" ? 1 : -1;
  if (
    name === "total" ||
    name === "claimed" ||
    name === "remaining" ||
    name === "approved"
  ) {
    return function (a, b) {
      var result = a > b ? 1 : b > a ? -1 : 0;
      return result * reverse;
    };
  } else
    return function (a, b) {
      var result =
        a.toString().toLowerCase().trim() > b.toString().toLowerCase().trim()
          ? 1
          : b.toString().toLowerCase().trim() >
              a.toString().toLowerCase().trim()
            ? -1
            : 0;
      return result * reverse;
    };
}

function setProjectTotalInfo(table) {
  //calculate project total based on the table
  let projectList = table.toJS();
  let sumTotal = 0,
    sumApproved = 0,
    sumClaimed = 0,
    sumPaid = 0,
    sumRemaining = 0;
  let status = !projectList ? false : true;
  if (status) {
    projectList.map((item) => {
      sumTotal += item.total;
      sumClaimed += item.claimed;
      sumApproved += item.approved;
      sumRemaining += item.remaining;
      sumPaid += item.paid || 0;
      return { sumTotal, sumClaimed, sumApproved, sumPaid, sumRemaining };
    });
  }
  return {
    ProjectTotal: sumTotal,
    Claimed: sumClaimed,
    Approved: sumApproved,
    Paid: sumPaid,
    Remaining: sumRemaining,
  };
}
class ClaimProjectsList extends Component {
  constructor(props) {
    super(props);
    this.selProjectType = this.selProjectType.bind(this);
    this.handleSetDataNum = this.handleSetDataNum.bind(this);
    this.state = {
      currentPage: 1,
      sortField: "project_name$up",
      filteredData: props.tableContent,
      limit: 50,
      tableTotal: {
        ProjectTotal: 0,
        Claimed: 0,
        Approved: 0,
        Paid: 0,
        Remaining: 0,
      },
    };
  }

  handleSetData = (data) => {
    let total = setProjectTotalInfo(data);
    this.handlePageChange(1);
    this.setState({ filteredData: data, tableTotal: total });
  };

  handlePageChange = (page) => {
    this.setState({
      currentPage: page,
    });
  };

  sortOption(name) {
    return this.setState({
      sortField: name,
    });
  }
  selProjectType(e) {
    this.handlePageChange(1);
    this.props.selProjectType(e);
  }
  handleSetDataNum(data) {
    this.handlePageChange(1);
    return this.setState({
      limit: data,
    });
  }
  render() {
    const { tableContent, projectType, accountId, accountConfig } = this.props;
    const limit = this.state.limit,
      total = this.state.filteredData.size;
    const { currentPage, tableTotal } = this.state;
    let table = this.state.filteredData.sortBy((val, index, obj) => {
      return val.get(this.state.sortField.split("$")[0]);
    }, compareByOption(this.state.sortField));
    const valueFromId = (opts, id) => opts.find((o) => o.value === id);
    const options = [
      { label: "Current Contracts", value: "current" },
      { label: "Completed Contracts", value: "completed" },
      { label: "Archived Contracts", value: "archived" },
    ];

    return (
      <ProjectItems className="claims-received_step3">
        <ProjectWrapper>
          <TopWrapper className={projectType}>
            <Select
              value={valueFromId(options, projectType)}
              onChange={(e) => this.selProjectType(e)}
              name="projectType"
              className="selectFrame"
              defaultValue="current"
              options={options}
            />
            <GlobalSearchComponent
              data={tableContent}
              handleSetData={this.handleSetData}
              searchLabel="Search for a project or contractor"
            />
          </TopWrapper>
          <OtherTable
            table={table}
            limit={limit}
            currentPage={currentPage}
            tableTotal={tableTotal}
            sortOption={(field) => this.sortOption(field)}
            accountId={accountId}
            accountConfig={accountConfig}
          />
          <div style={{ height: "104px", marginRight: "10px" }}>
            <PageBarPart
              total={total}
              limit={limit}
              pageCount={5}
              currentPage={currentPage}
              handlePageChange={this.handlePageChange}
              handleSetData={this.handleSetDataNum}
              field="big"
            />
          </div>
        </ProjectWrapper>
      </ProjectItems>
    );
  }
  componentDidMount() {
    this.handleSetData(this.props.tableContent);
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.projectType !== nextProps.projectType) {
      this.handleSetData(nextProps.tableContent);
    }
    if (this.props.tableContent !== nextProps.tableContent) {
      this.handleSetData(nextProps.tableContent);
    }
    return true;
  }
}
export default ClaimProjectsList;
