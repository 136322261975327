import React, { useEffect, useRef, useState } from "react";
import mapBoxGl from "mapbox-gl";
import "./map.css";
import { capitalizeAddress } from "../../utils/helper";
import API from "../../server";
import { connect } from "react-redux";

const MapBox = ({ placeName, config }) => {
  const mapContainer = useRef(null);
  const [map, setMap] = useState(null);
  const [showMap, setShowMap] = useState(true);
  const hasMapService =
    config.mapbox_token !== null && config.mapbox_token !== "";

  const initializeMap = (result) => {
    let point = result.data.features[0],
      longitude = point.center[0],
      latitude = point.center[1];

    mapBoxGl.accessToken = config.mapbox_token;

    if (mapContainer.current) {
      const mapBox = new mapBoxGl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: [longitude, latitude],
        zoom: 15,
      });
      // Create a default Marker and add it to the map
      new mapBoxGl.Marker({ color: "red" })
        .setLngLat([longitude, latitude])
        .addTo(mapBox);

      const markerHeight = 40;
      const popupOffsets = { bottom: [0, -markerHeight] };

      //create popup to display address info
      new mapBoxGl.Popup({ offset: popupOffsets, closeOnClick: false })
        .setLngLat([longitude, latitude])
        .setHTML(
          `<div id="popup">${capitalizeAddress(...placeName.split(","))}</div>`,
        )
        .addTo(mapBox);

      setMap(mapBox);
    }
  };

  useEffect(() => {
    if (!placeName || !hasMapService || !config.mapbox_token) {
      setShowMap(false);
      return;
    }

    const removeMap = () => {
      setShowMap(false);
      if (map) {
        map.remove();
      }
      setMap(null);
    };
    API.forward_geocoding(placeName, config.mapbox_token)
      .then((res) => {
        if (res.status === 200 && res.data && res.data.features.length !== 0) {
          setShowMap(true);
          if (!map) {
            initializeMap(res);
          } else {
            map.remove();
            initializeMap(res);
          }
        } else {
          removeMap();
        }
      })
      .catch((error) => {
        removeMap();
      });
    // eslint-disable-next-line
  }, [placeName, hasMapService, config]);

  return (
    <>
      {showMap && hasMapService && <div ref={mapContainer} id="mapWrapper" />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    config: state.getIn(["headers", "config"]),
  };
};

const getTimezoneHelper = async (longitude, latitude, token) => {
  return API.search_timezone(longitude, latitude, token).then((point) => {
    if (point.data.features.length > 0) {
      let timezone = point.data.features[0].properties.TZID;
      return timezone;
    }
  });
};

export function getTimezone(placeName, token, callback, callbackFailure) {
  return API.forward_geocoding(placeName, token)
    .then(async (res) => {
      let point = res.status === 200 ? res.data.features[0] : null;
      if (point) {
        let longitude = point.center[0],
          latitude = point.center[1];

        try {
          let result = await getTimezoneHelper(longitude, latitude, token);
          callback(result);
        } catch {
          throw new Error("Error retrieving Mapbox timezone");
        }
      } else {
        throw new Error("Error retrieving Mapbox coordinates");
      }
    })
    .catch((error) => {
      console.error(error);
      if (callbackFailure) {
        callbackFailure();
      }
    });
}

export default connect(mapStateToProps, null)(MapBox);
