import React from "react";
import { Claim, Cert, TableWrapper } from "../styled";
import history from "../../../utils/history";
import { convertToCurrencyUnit } from "../../../utils/currencyHelper";

function formatName(name) {
  if (name === "issue") {
    name = "issued";
  }
  const FIELD = name.replace(/^\S/, (s) => s.toUpperCase());
  return FIELD;
}

function openProjects(item) {
  let url = "",
    id = item.get("contract_id"),
    claimid = item.get("claim_id"),
    certid = item.get("cert_id");

  if (item.get("type") === "claimed") {
    switch (item.get("claim_status")) {
      case "draft":
        url = `/makeclaim/${id}/${claimid}`;
        break;
      case "approved":
        url = `/makeclaim/review/${id}/${claimid}`;
        break;
      case "issue":
        url = `/review-claim/${id}/${claimid}`;
        break;
      case "certified":
        url = `/review-claim/${id}/${claimid}/approved`;
        break;
      default:
        url = "";
    }
  } else {
    switch (item.get("cert_status")) {
      case "draft":
        url = `/makecert/${id}/${claimid}/${certid}/contract`;
        break;
      case "approved":
        url = `/review-cert/${id}/${claimid}/${certid}/approved/contract`;
        break;
      default:
        url = "";
    }
  }
  return history.push(url);
}

function TableHeaderItem(props) {
  return (
    <th className={props.className}>
      {props.label}
      {props.sortField !== null && (
        <>
          <span
            className="imgSort up"
            onClick={() => props.sortOption(props.sortField + "$up")}
          />
          <span
            className="imgSort"
            onClick={() => props.sortOption(props.sortField + "$down")}
          />
        </>
      )}
    </th>
  );
}

export const Table = (props) => {
  const { table, limit, currentPage, tableTotal, sortOption } = props;
  const tableHeader = [
    { label: "Project Name", sortField: "contract_name", className: "client" },
    { label: "Project Number", sortField: "contract_number", className: "num" },
    { label: "Date", sortField: "Date", className: "" },
    { label: "Type", sortField: null, className: "" },
    { label: "Status", sortField: null, className: "" },
    { label: "Project Total", sortField: "total", className: "value" },
    { label: "Claimed", sortField: "claimed", className: "value" },
    { label: "Approved", sortField: "approved", className: "value" },
    { label: "Variance", sortField: "remaining", className: "value" },
  ];

  return (
    <TableWrapper>
      <table className="cc_step2">
        <thead>
          <tr>
            {tableHeader.map((item) => (
              <TableHeaderItem
                key={item.label}
                sortOption={sortOption}
                label={item.label}
                sortField={item.sortField}
                className={item.className}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {table.map((item, index) => {
            let page = currentPage - 1;
            if (index >= page * limit && index < (page + 1) * limit) {
              return (
                <tr
                  onClick={() => openProjects(item)}
                  key={
                    item.get("type") !== "cert"
                      ? item.get("claim_id")
                      : item.get("cert_id")
                  }
                >
                  <td className="alignToLeft">{item.get("contract_name")}</td>
                  <td className="alignToLeft">{item.get("contract_number")}</td>
                  <td>{item.get("Date").split("T")[0]}</td>
                  <td className="alignToLeft">
                    {item.get("type") === "claimed" ? (
                      <Claim>Claim</Claim>
                    ) : (
                      <Cert>Cert</Cert>
                    )}
                  </td>
                  <td className="alignToLeft">
                    {item.get("type") === "claimed"
                      ? formatName(item.get("claim_status"))
                      : formatName(item.get("cert_status"))}
                  </td>
                  <td>{convertToCurrencyUnit(item.get("total"))}</td>
                  <td>{convertToCurrencyUnit(item.get("claimed"))}</td>
                  <td>{convertToCurrencyUnit(item.get("approved"))}</td>
                  <td>{convertToCurrencyUnit(item.get("remaining"))}</td>
                </tr>
              );
            } else return "";
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="empty" colSpan="3" />
            <td colSpan="3">Projects Totals</td>
            <td>{convertToCurrencyUnit(tableTotal.Claimed)}</td>
            <td>{convertToCurrencyUnit(tableTotal.Approved)}</td>
            <td>{convertToCurrencyUnit(tableTotal.Remaining)}</td>
          </tr>
        </tfoot>
      </table>
    </TableWrapper>
  );
};
