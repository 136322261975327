import React, { Component } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, ProjectBackground, ProjectListWrapper } from "../styled";

import Menu from "../../../common/breadcrumbs";
import {
  BlueOutlinedBtn as Button,
  BlueWhiteBtn as ButtonDark,
  LightBlueWhiteBtn as ButtonSecondary,
  AddBtn as AddButton,
} from "../../../common/button";
import Link from "../../../common/linkWithQuery";
import {
  CLAIM_ONLY,
  CERT_ONLY,
  EDIT,
  PROJECT,
  CONTRACT,
} from "../../../utils/constants";
import Contracts, { ContractListView } from "../components/contract";
import ProjectSummary from "../components/projectSummary";
import profile from "../../../utils/profile";

import { actionCreators } from "../store";
import { actionCreators as actionCreatorsAccount } from "../../manageaccount/store";
import Skeleton from "../../../common/skeleton";
class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      history: useHistory,
      allowEditProject: true,
      allowEditContract: false,
    };
  }

  render() {
    const {
      accountId,
      projectInfo,
      loading,
      history,
      welcomeInfoSeen,
      orgType,
      hideWelcomeInfo,
      changeState,
      downloadProjectSummary,
      accountConfig,
    } = this.props;
    const { allowEditProject, allowEditContract } = this.state;

    var link = [{ link: "/projects", text: "Projects" }];
    let project = {
      id: projectInfo.get("id"),
      name: projectInfo.get("name"),
      coverImage: projectInfo.get("cover_url"),
    };

    if (loading) {
      return (
        <Container>
          <div className="page-breadcrumbs">
            <Menu
              link={link}
              content={project.name}
              className="page-breadcrumbs-label"
            />
            <Link to={"/projects"}>
              <Button title="Back To Projects" />
            </Link>
          </div>
          <div>
            <ProjectBackground />
            <Skeleton.ProjectDetailsCard />
            <div className="skeleton-components">
              <Skeleton.ComponentCard count={10} />
            </div>
          </div>
        </Container>
      );
    }

    var hasClaimContracts =
      projectInfo
        .get("claim_contracts")
        .filter((ele) => ele.get("status") === "current").size !== 0;
    var contractId = hasClaimContracts
      ? projectInfo.get("claim_contracts").toJS()[0]["id"]
      : "";
    var contractType = hasClaimContracts
      ? projectInfo.get("claim_contracts").toJS()[0]["contract_type"]
      : "";
    var claimPath = hasClaimContracts
      ? `/project/${project.id}/contract/${contractId}/${contractType}/claim?account_id=${accountId}`
      : "";

    var isActive =
      projectInfo && projectInfo.get("status") === "active" ? true : false;
    var claimContractNum = projectInfo.get("claim_contracts")?.size || 0,
      certContractNum = projectInfo.get("cert_contracts")?.size || 0;

    return (
      <Container>
        <div className="page-breadcrumbs">
          <Menu
            link={link}
            content={project.name}
            className="page-breadcrumbs-label"
          />
          <Link to={"/projects"}>
            <Button title="Back To Projects" />
          </Link>
        </div>
        <ProjectBackground src={project.coverImage} />
        <ProjectSummary
          projectInfo={projectInfo}
          welcomeInfoSeen={welcomeInfoSeen}
          hideWelcomeInfo={hideWelcomeInfo}
          changeState={changeState}
          allowEditProject={allowEditProject}
          downloadProjectSummary={downloadProjectSummary}
          accountConfig={accountConfig}
        />
        {/* show main contract section when account org is not cert only or has claim contracts */}
        {(orgType !== CERT_ONLY || claimContractNum > 0) && (
          <ProjectListWrapper>
            <div className="frame-title">
              <div>Main Contract</div>
              {hasClaimContracts && allowEditContract && (
                <ButtonSecondary
                  title={`${
                    accountConfig?.getIn(["claim", "noun"])?.toLowerCase() ===
                    "claim"
                      ? "make a claim"
                      : accountConfig?.getIn(["claim", "verb"])
                  }`}
                  onClick={() => history.push(claimPath)}
                />
              )}
            </div>
            {claimContractNum === 0 && isActive && (
              <div className="project-list-frame">
                <label>
                  Add your contract here to manage{" "}
                  {accountConfig?.getIn(["claim", "noun"])?.toLowerCase()}s...
                </label>
                <div className="placeholder">
                  Private (not visible to consultants)
                </div>
                {allowEditProject && (
                  <Link
                    to={`/contract/${project.id}/claim`}
                    className="hasAlign"
                  >
                    <ButtonDark title="Add Contract" className="has-margin" />
                  </Link>
                )}
              </div>
            )}
            {claimContractNum > 0 && (
              <ContractListView
                data={projectInfo.get("claim_contracts")}
                isClaimContract
              />
            )}
          </ProjectListWrapper>
        )}
        {/* show sub contracts section when account org is not claim only or has cert contracts */}
        {(orgType !== CLAIM_ONLY || certContractNum > 0) && (
          <ProjectListWrapper>
            <div className="frame-title">
              <div>Sub Contracts</div>
              {certContractNum > 0 && isActive && allowEditProject && (
                <Link to={`/contract/${project.id}/cert`}>
                  <AddButton title="add a new contract" />
                </Link>
              )}
            </div>
            {certContractNum === 0 ? (
              !isActive ? (
                <></>
              ) : (
                <div className="project-list-frame">
                  <label>Add your sub contract here for certifying...</label>
                  {allowEditProject && (
                    <Link
                      to={`/contract/${project.id}/cert`}
                      className="hasAlign"
                    >
                      <ButtonDark title="Add Contract" />
                    </Link>
                  )}
                </div>
              )
            ) : (
              <Contracts
                contracts={projectInfo.get("cert_contracts")}
                accountId={accountId}
                isNotCovered={projectInfo.get("is_not_covered")}
              />
            )}
          </ProjectListWrapper>
        )}
      </Container>
    );
  }

  componentDidMount() {
    const proId = this.props.match.params.projectId;
    this.props.readProject(proId);
    profile.checkPermission(PROJECT, EDIT, proId).then((res) => {
      this.setState({ allowEditProject: res });
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.projectInfo !== this.props.projectInfo) {
      const { projectInfo } = this.props;
      var projectId = projectInfo.get("id");
      var hasClaimContracts = false;
      if (projectInfo.get("claim_contracts")) {
        hasClaimContracts =
          projectInfo
            .get("claim_contracts")
            .filter((ele) => ele.get("status") === "current").size !== 0;
      }
      var contractId = hasClaimContracts
        ? projectInfo.get("claim_contracts").toJS()[0]["id"]
        : "";

      if (contractId) {
        profile
          .checkPermission(CONTRACT, EDIT, projectId, contractId)
          .then((res) => {
            this.setState({ allowEditContract: res });
          });
      }
    }
  }
  componentWillUnmount() {
    this.props.reset();
  }
}
const mapStateToProps = (state) => ({
  accountId: state.getIn(["headers", "profile", "payclaim_account_id"]),
  loading: state.getIn(["newContractGroup", "loading"]),
  projectInfo: state.getIn(["newContractGroup", "projectInfo"]),
  welcomeInfoSeen: state.getIn(["newContractGroup", "welcomeInfoSeen"]),
  orgType: state.getIn(["manageAccount", "orgType"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});

const mapDispatchToProps = (dispatch) => ({
  readProject(proId) {
    dispatch(actionCreators.readProject(proId));
    dispatch(actionCreatorsAccount.getAccountInfo());
  },
  hideWelcomeInfo(id) {
    dispatch(actionCreators.hideWelcome(id));
  },
  changeState(proId, state) {
    dispatch(actionCreators.changeState(proId, state));
  },
  reset() {
    dispatch(actionCreators.reset());
  },
  downloadProjectSummary(proId) {
    dispatch(actionCreators.downloadProjectSummary(proId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
