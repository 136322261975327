import React from "react";
import { Overdue, Due3, Due7, UnClaimed, Empty } from "../styled";
import BootstrapTooltip from "../../../common/toolTip";
import { palette } from "../../../theme";

export const NoticeBell = ({ due, dueDays, field, accountConfig }) => {
  let title = "";
  let type = field.includes("Claim")
    ? accountConfig?.getIn(["claim", "noun"])
    : field;
  const label = accountConfig?.getIn(["claim", "novalue"])
    ? accountConfig?.getIn(["claim", "novalue"])
    : "Unclaimed";

  switch (due) {
    case "over_due":
      return (
        <BootstrapTooltip
          placement="bottom"
          title={`${type} overdue`}
          color={palette.lightPastelPink}
          fontcolor={palette.watermelonRed}
        >
          <Overdue />
        </BootstrapTooltip>
      );
    case "due_in_3_days":
      title = `${type} due ${
        dueDays === 0
          ? "today"
          : `in ${dueDays || 3} day${dueDays !== 1 ? "s" : ""}`
      }`;
      return (
        <BootstrapTooltip
          placement="bottom"
          title={title}
          color={palette.paleYellow}
          fontcolor={palette.tangerineOrange}
        >
          <Due3 />
        </BootstrapTooltip>
      );
    case "due_in_7_days":
      title = `${type} due ${dueDays ? `in ${dueDays} days` : "in 7 days"}`;
      return (
        <BootstrapTooltip
          placement="bottom"
          title={title}
          color={palette.lightPastelBlue}
          fontcolor={palette.turquoiseBlue}
        >
          <Due7 />
        </BootstrapTooltip>
      );
    case "unclaimed_days":
      return (
        <BootstrapTooltip
          placement="bottom"
          title={`${label} for 30 Days`}
          color={palette.white}
          fontcolor={palette.gunmetal}
        >
          <UnClaimed />
        </BootstrapTooltip>
      );
    default:
      return <Empty />;
  }
};
