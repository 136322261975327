import React from "react";
import { LockInputWrap } from "./styled";
import Flags from "country-flag-icons/react/3x2";

const LockInput = (props) => {
  const Flag = Flags[props?.country];
  return (
    <LockInputWrap {...props}>
      {props?.country && props?.hasFlag && <Flag className="flag" />}
      <input defaultValue={props.value} disabled />
      <div className="lockIcon" />
    </LockInputWrap>
  );
};

export default LockInput;
