import * as theme from "../../theme";
import styled from "styled-components";
import noticeIcon from "../../statics/img/icon-information-light-grey.svg";
import closeIcon from "../../statics/img/icon-clear-medium-grey.svg";

export const HistoryTableStyle = styled.div`
  font-family: ${theme.font.mainFont};
  height: auto;
  padding: 40px;
  box-sizing: border-box;
  width: 724px;
  &.payment {
    width: 900px;
  }
  .title {
    font-size: 20px;
    color: ${theme.palette.gunmetal};
    font-family: ${theme.font.mainFont};
    font-weight: ${theme.font.mainFontWeight};
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .img_close {
      width: 24px;
      height: 24px;
      background: url(${closeIcon}) no-repeat;
      background-size: contain;
      cursor: pointer;
    }
  }
  .table {
    table-layout: fixed;
    height: auto;
    font-size: 14px;
    font-weight: 400;
    border-spacing: 1px;
    width: 100%;
    tbody {
      tr:nth-child(even) {
        background-color: ${theme.palette.warmLightGrey};
      }
      tr:nth-child(odd) {
        background-color: ${theme.palette.greyWhite};
      }
    }
  }
  .thead {
    height: 32px;
    background-color: ${theme.palette.veryMediumGrey};
    text-align: right;
    color: ${theme.palette.white};
    &.paymentHeader {
      background-color: ${theme.palette.white};
    }
  }

  .tdTitleItem {
    padding: 0 15px 0 0;
    font-size: 14px;
    font-weight: ${theme.font.mainFontWeight};
    box-sizing: border-box;
    position: relative;
    height: 32px;
    color: ${theme.palette.white};
    &.titleSum {
      text-align: center;
      height: 40px;
      background-color: ${theme.palette.veryMediumGrey};
    }
    &.claim {
      width: 136px;
      text-align: left;
      padding: 0 0 0 15px;
      height: 32px;
    }
    &.date {
      width: 110px;
      text-align: left;
      padding: 0 0 0 15px;
      height: 32px;
    }

    &.percent_qty {
      width: 152px;
      text-align: center;
      padding: 0;
      height: 32px;
      text-align: left;
      padding: 0 0 0 15px;
    }
    &.total {
      width: 136px;
      text-align: center;
      padding: 0;
      height: 32px;
    }
  }
  .tbody {
    color: ${theme.palette.gunmetal};
    text-align: right;
    box-sizing: border-box;
  }
  .tdItem {
    height: 32px;
    padding: 0 15px;

    &.desItem {
      text-align: left;
      padding: 4px 15px;
      box-sizing: border-box;
    }
    &.actions {
      text-align: center;
      padding: 4px 15px;
      box-sizing: border-box;
      white-space: nowrap;

      .percent_qty {
        display: grid;
        grid-template-columns: 50% 50%;
        text-align: right;
      }
    }
    &.total {
      text-align: right;
      padding: 4px 15px;
      box-sizing: border-box;
    }
  }
`;

export const NoticeImage = styled.div`
  height: 24px;
  width: 24px;
  background: url(${noticeIcon}) no-repeat;
  background-size: contain;
  &.notice_payment {
    margin: 10px 10px 10px 50px;
  }
  &.notice_lineitem {
    height: 20px;
    width: 20px;
    margin-top: 3px;
    float: left;
  }
`;

export const TimezoneTip = styled.div`
  width: 960px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;

  width: fit-content;
  height: 20px;

  background: ${theme.palette.rareGrey};
  border-radius: 2px;
  color: ${theme.palette.gunmetal};
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  margin-left: 8px;
  float: left;
`;
