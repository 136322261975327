import React, { Fragment, Component } from "react";
import { AvatarWrapper } from "../style";
import { connect } from "react-redux";
import { fromJS } from "immutable";

import ClassCropperModal from "../../../../common/modal/component/uploadImgDialog";
import { BlueWhiteBtn as Button } from "../../../../common/button";
import { actionCreators } from "../../../../common/modal";
import { actionCreators as manageAccActionCreators } from "../../store";

class accountImg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classModalVisible: false,
      classModalFile: null,
      classResultImgUrl: null,
    };
  }

  handleImageChange = (e) => {
    const file = e.target.files[0];
    // 2MB front end size limit, lower than back end file upload limit to prevent size issues
    // since cropped images sometimes have a larger file size than the original uploaded
    // because the quality factor (compression) can be less after cropping and re-encoding the image
    const SIZE_LIMIT = 2 * 1024 * 1024;
    if (file.type === "image/png" || file.type === "image/jpeg") {
      if (file.size < SIZE_LIMIT) {
        this.setState(
          {
            classModalFile: file, // First put the uploaded file in the state
          },
          () => {
            this.setState({
              classModalVisible: true, // Then pop up modal
            });
          },
        );
      } else {
        this.props.showWarning(
          "Error!",
          "The file is too big! Please choose another one.",
        );
      }
    } else {
      this.props.showWarning(
        "Invalid upload!",
        "Only png or jpg files allowed.",
      );
    }
  };

  handleGetResultImgUrl = (key) => (blob) => {
    const str = URL.createObjectURL(blob);
    this.setState({
      [key]: str,
    });
    this.toBase64(blob, (image) => this.setImage(image));
  };

  setImage(image) {
    this.props.updateImage(image);
  }

  toBase64 = (file, callback) => {
    var reader = new FileReader();
    reader.onloadend = function () {
      // image type
      let first = reader.result.indexOf("image/");
      let last = reader.result.indexOf(";");
      // base64 string
      let from = reader.result.indexOf("base64,");

      let image = {};
      image["image_type"] = reader.result.substring(first, last);
      // adds 7 so that 'base64,' is not included in the string
      image["image_64"] = reader.result.substring(from + 7);
      callback(image);
    };
    reader.readAsDataURL(file);
  };

  render() {
    const { image_type, image_64, hideForProfile } = this.props;
    const { classModalVisible, classModalFile, classResultImgUrl } = this.state;
    return (
      <AvatarWrapper>
        {!classResultImgUrl ? (
          image_64 !== "" && image_64 !== undefined ? (
            <div className="img-container">
              <img
                src={"data:" + image_type + ";base64," + image_64}
                alt=""
                style={{ maxWidth: "140px", maxHeight: "140px" }}
              />
            </div>
          ) : (
            <div className="profileImg" />
          )
        ) : (
          <Fragment>
            <div className="img-container">
              {classResultImgUrl && (
                <img
                  className="img"
                  src={classResultImgUrl}
                  alt="classResultImgUrl"
                />
              )}
            </div>
          </Fragment>
        )}
        {classModalVisible && (
          <ClassCropperModal
            uploadedImageFile={classModalFile}
            onClose={() => {
              this.setState({ classModalVisible: false });
            }}
            onSubmit={this.handleGetResultImgUrl("classResultImgUrl")}
          />
        )}
        <input
          type="file"
          accept="image/jpeg,image/jpg,image/png"
          ref={(ref) => (this.upload = ref)}
          onChange={this.handleImageChange}
          style={{ display: "none" }}
        />
        {!hideForProfile && (
          <Button
            title="CHANGE PHOTO"
            margin="20px 0 0 13px"
            float="left"
            onClick={() => this.upload.click()}
          />
        )}
      </AvatarWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showWarning(title, message) {
    dispatch(
      actionCreators.showModal("alert", {
        open: true,
        title: title,
        message: message,
      }),
    );
  },
  updateImage(file) {
    dispatch(
      manageAccActionCreators.updateImage("profile_image", fromJS(file)),
    );
  },
});

export default connect(null, mapDispatchToProps)(accountImg);
