import * as constants from "./constants";
import { fromJS } from "immutable";
import { addDepthLabel } from "../../../utils/lineitemHelper";

const defaultState = fromJS({
  variationstable: [],
  variationsTotal: 0,
  uploadFiles: [],
  loading: true,
  addItem: false,
  jumpnextprocess: false,
  editFileList: [],
  contractInfo: [],
});

const addInitialRowToTable = (state, action) => {
  let variationItems = action.variationsitem.toJS();
  variationItems.forEach((item, index) => {
    variationItems[index] = addDepthLabel(item, 1);
  });

  return state
    .set("loading", false)
    .set("variationstable", fromJS(variationItems))
    .set("variationsTotal", action.variationsitemTotal);
};
const removeFileFromList = (state, action) => {
  const fileList = state.get("uploadFiles");
  return state.set(
    "uploadFiles",
    fileList.filter((o) => o.name !== action.value),
  );
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.INITIAL_ITEM_LIST:
      return addInitialRowToTable(state, action);
    case constants.LOADING_STATUS_CHANGE:
      return state.set("loading", action.status);
    case constants.INITIAL_FILE_LIST:
      if (action.fileList !== undefined && action.fileList) {
        return state.set("editFileList", fromJS(action.fileList));
      } else {
        return state.merge({
          editFileList: fromJS([]),
          uploadFiles: fromJS([]),
        });
      }
    case constants.EMPTY_ITEM_LIST:
      return state.merge({
        variationstable: fromJS([]),
        variationsTotal: 0,
        loading: false,
      });
    case constants.RESET:
      return defaultState;
    case constants.CHANGE_FILE_LIST:
      return state.set(
        "uploadFiles",
        state.get("uploadFiles").concat(action.files),
      );
    case constants.CANCEL_ONE_FILE:
      return removeFileFromList(state, action);
    case constants.SET_VALUE:
      return state.set(action.name, action.value);
    default:
      return state;
  }
};
