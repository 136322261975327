import API from "../../../server";
import {
  UPLOAD_FILE,
  UPLOAD_PROGRESS,
  SUCCESS_UPLOAD,
  FAILURE_UPLOAD,
  RETRY_UPLOAD,
  REMOVE_FILE,
  CLEAR_FILE,
} from "./constants";

export const uploadFileWithProgress =
  (api, file, retryId = null) =>
  async (dispatch) => {
    const fileId = retryId || Math.floor(Math.random() * 1000000);

    dispatch(uploadFileRequest(file, fileId, file.name, file.size));

    let dataSend = new FormData();
    dataSend.append("files", file);
    let config = {
      mimeType: "multipart/form-data",
      onUploadProgress: (progress) => {
        const { loaded, total } = progress;
        const percentageProgress = Math.floor((loaded / total) * 100);
        dispatch(uploadFileProgress(fileId, percentageProgress));
      },
    };
    API[api.name](...api.config, dataSend, config)
      .then((res) => {
        dispatch(uploadFileSuccess(fileId, res.data.data.data));
      })
      .catch((error) => {
        dispatch(uploadFileFailure(fileId, error.message));
      });
  };

export const uploadFileRequest = (file, fileId, fileName, fileSize) => ({
  type: UPLOAD_FILE,
  payload: { file, fileId, fileName, fileSize },
});

export const uploadFileProgress = (fileId, progress) => ({
  type: UPLOAD_PROGRESS,
  payload: { fileId, progress },
});

export const uploadFileSuccess = (fileId, files) => ({
  type: SUCCESS_UPLOAD,
  payload: { fileId, files },
});

export const uploadFileFailure = (fileId, error) => ({
  type: FAILURE_UPLOAD,
  payload: { fileId, error },
});

export const uploadFileRetry = (fileId) => ({
  type: RETRY_UPLOAD,
  payload: { fileId },
});

export const deleteUploadingFile = (fileId) => ({
  type: REMOVE_FILE,
  payload: { fileId },
});

export const clearFiles = () => ({
  type: CLEAR_FILE,
});
