import API from "../../../server";
import * as constants from "./constants";

const setCountries = (result) => ({
  type: constants.SET_COUNTRIES,
  payload: result,
});

const setIpAddressDetails = (result) => ({
  type: constants.SET_PUBLIC_IP_ADDRESS_DETAILS,
  payload: result,
});

export const readCountries = () => {
  return (dispatch) => {
    API.read_countries()
      .then((res) => res.data)
      .then((result) => {
        // setting countries for dropdown
        let formattedCountries = result?.data?.countries.map(
          ({ name, iso }) => ({ label: name, value: iso }),
        );
        dispatch(setCountries(formattedCountries));
      });
  };
};

export const readAccountCountry = () => {
  return (dispatch) => {
    API.read_account_detail()
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((result) => {
        dispatch(setData("accountCountry", result.country));
      });
  };
};

export const setData = (name, data) => ({
  type: constants.SET_DATA,
  name,
  data,
});

export const getAccountConfig = () => {
  return (dispatch) => {
    API.read_account_config()
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        let state = res.state
          ? res.state.map((state) => ({
              label: state.title,
              value: state.title,
            }))
          : [];
        res.state = state;
        dispatch(setData("accountConfig", res));
      });
  };
};

export const readPublicIPAddressDetails = () => {
  return (dispatch) => {
    API.read_public_ip_details()
      .then((res) => res.data)
      .then((result) => {
        dispatch(setIpAddressDetails(result));
      });
  };
};
