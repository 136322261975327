import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { format, parse, getDay } from "date-fns";
import * as constants from "./constants";
import history from "../../../utils/history";
import { getOrgType } from "../../../utils/checkOrgType";
import API from "../../../server";
import { actionCreators as actionCreatorsModal } from "../../../common/modal";
import { actionCreators as actionCreatorsHeaders } from "../../../common/header/store";
import { getTimezone } from "../../../common/mapbox/map";

import { actionCreators as actionCreatorsConfig } from "../../../common/config/store";
import moment from "moment";
import {
  timezoneLabel,
  convertUTCToTime,
  convertTimeToUTC,
  filterTimezonesByCountry,
} from "../../../utils/dateTimeHelper";
import { loginSuccess } from "../../../common/header/store/actionCreators";

export const reset = () => ({
  type: constants.RESET,
});

export const setData = (name, data) => ({
  type: constants.SET_DATA,
  name,
  data,
});

export const updateAccountInfo = (value) => {
  return (dispatch, getState) => {
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    const config = getState().getIn(["headers", "config"]);
    const hasAddress =
      value.street_address ||
      value.suburb ||
      value.city ||
      value.postal_code ||
      value.state
        ? true
        : false;

    let address = hasAddress
      ? `${value.street_address},${value.suburb},${value.city},${value.postal_code},${value.state},${value.country}`
      : "";
    const saveAccountDetails = (value) => {
      if (value.phone) value.phone = formatPhoneNumberIntl(value.phone);
      API.update_account_detail(value)
        .then((res) => res.data)
        .then((res) => {
          dispatch(setData("saved", true));
          API.login_info()
            .then((res) => res.data)
            .then((res) => {
              dispatch(actionCreatorsHeaders.loginSuccess(res.data.profile));
              history.push("/manage-account/view?account_id=" + accountId);
              dispatch(actionCreatorsConfig.getAccountConfig());
            });
        });
    };

    // retrieve timezone if there's an address update
    getTimezone(
      address,
      config.mapbox_token,
      (timezone) => {
        value.time_zone = timezone;
        saveAccountDetails(value);
      },
      () => {
        saveAccountDetails(value);
      },
    );
  };
};

export const getAccountInfo = () => {
  return (dispatch, getState) => {
    const config = getState().getIn(["headers", "config"]);
    API.read_account_detail()
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("accountInfo", res));
        let orgType = getOrgType(config, res.role);
        dispatch(setData("orgType", orgType));
        dispatch(setData("formValue", res));
        dispatch(
          setData(
            "integration",
            !res.integrations?.length ? null : res.integrations,
          ),
        );
      });
  };
};

export const getEmailSummary = () => {
  return (dispatch, getState) => {
    API.read_email_notice_time()
      .then((res) => res.data)
      .then((res) => res.data.times)
      .then((res) => {
        const accountTimezone = getState().getIn([
          "manageAccount",
          "accountInfo",
          "time_zone",
        ]);

        const dailyDateTime = convertUTCToTime(
          res["daily"].start_at,
          accountTimezone,
          false,
        )[0];
        const weeklyDateTime = convertUTCToTime(
          res["weekly"].start_at,
          accountTimezone,
          false,
        )[0];

        let dayTimeFns = parse(
          dailyDateTime,
          "dd/MM/yyyy HH:mm:ss",
          new Date(),
        );
        let weekTimeFns = parse(
          weeklyDateTime,
          "dd/MM/yyyy HH:mm:ss",
          new Date(),
        );

        let emailSummary = {
          day_time: parseInt(format(dayTimeFns, "HH"), 10),
          week: getDay(weekTimeFns),
          week_time: parseInt(format(weekTimeFns, "HH"), 10),
          time_string: {
            day_time: format(dayTimeFns, "h:mm a"),
            week: format(weekTimeFns, "EEEE"),
            week_time: format(weekTimeFns, "h:mm a"),
          },
        };

        dispatch(setData("emailSummary", emailSummary));
      });
  };
};

function getSentTime(time, timeZone, day_offset = 1, week_day) {
  if (time === 12 || time === 24) {
    time = time - 12;
  }

  let now = moment();
  const nextDateTime = now.clone().set({
    hours: time,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  if (week_day) {
    nextDateTime.isoWeekday(week_day);
  }
  if (now.isSameOrAfter(nextDateTime)) {
    nextDateTime.add(day_offset, "days");
  }

  return convertTimeToUTC(nextDateTime, timeZone);
}

export const updateEmailSummary = (data) => {
  return (dispatch, getState) => {
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    const accountTimezone = getState().getIn([
      "manageAccount",
      "accountInfo",
      "time_zone",
    ]);
    const nextDayDate = getSentTime(data.get("day_time"), accountTimezone);
    const nextWeekDate = getSentTime(
      data.get("week_time"),
      accountTimezone,
      7,
      data.get("week"),
    );

    API.update_email_notice_time({
      daily: { start_at: nextDayDate },
      weekly: { start_at: nextWeekDate },
    })
      .then((res) => res.data)
      .then((res) => {
        history.push("/manage-account/view?account_id=" + accountId);
      });
  };
};

export const changeEmailPeriod = (data, name, value) => {
  let emailSummary = data.toJS();
  emailSummary[name] = value;
  return (dispatch) => {
    dispatch(setData("emailSummary", emailSummary));
  };
};

export const getAllUsers = () => {
  return (dispatch) => {
    API.read_account_user("all", "all")
      .then((res) => res.data.data)
      .then((res) => res.users)
      .then((res) => {
        let users = res.map((user) => ({
          ...user,
          contractNum: user.contracts ? user.contracts.length : 0,
          projectNum: user.projects ? user.projects.length : 0,
        }));
        dispatch(setData("allAccount", users || []));
      });
  };
};

export const inviteANewUser = (values) => {
  return (dispatch, getState) => {
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    let data = values.toJS();
    data.phone = data.phone && formatPhoneNumberIntl(data.phone);
    dispatch(setData("saving", true));
    API.invite_one_user(data)
      .then((res) => res.data)
      .then((res) => {
        dispatch(setData("saved", true));
        dispatch(setData("saving", false));
        dispatch(
          actionCreatorsModal.showModal("confirm", {
            open: true,
            title: "Success!",
            message: "Your have added this user successfully.",
            url: "/manage-account/users?account_id=" + accountId,
          }),
        );
      })
      .catch((error) => {
        dispatch(setData("saved", true));
        dispatch(setData("saving", false));
      });
  };
};

export const getProfileInfo = () => {
  return (dispatch) => {
    API.read_user_profile()
      .then((res) => res.data)
      .then((res) => res.data.result)
      .then((res) => {
        dispatch(setData("profile", res));
      });
  };
};

export const setEmailNotice = (name, value) => {
  return (dispatch, getState) => {
    let profile = getState().getIn(["manageAccount", "profile"]);
    let data = {
      workflow_required: profile.getIn([
        "email_preferences",
        "workflow_required",
      ]),
      workflow_optional: profile.getIn([
        "email_preferences",
        "workflow_optional",
      ]),
      has_due_alerts: profile.getIn(["email_preferences", "has_due_alerts"]),
      alert_summary: profile.getIn(["email_preferences", "alert_summary"]),
    };
    data[name] = value;
    API.set_user_email_preference(data)
      .then((res) => res.data)
      .then((res) => {
        dispatch(getProfileInfo());
      });
  };
};

export const updateProfile = (value, type) => {
  return (dispatch, getState) => {
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    let data = value.toJS();
    data.phone = data.phone && formatPhoneNumberIntl(data.phone);

    if (type === "user") {
      //update role
      API.update_user_role(data)
        .then((res) => res.data)
        .then((res) => {
          dispatch(setData("saved", true));
          history.push("/manage-account/users?account_id=" + accountId);
        });
    } else if (type === "profile") {
      data.role_id = data.role;
      //update profile
      API.update_user_profile(data)
        .then((res) => res.data)
        .then((res) => {
          dispatch(setData("saved", true));
          history.push("/manage-account/profile?account_id=" + accountId);
        });
    }
  };
};

export const deleteAccountUser = (userId, redirect = false) => {
  return (dispatch) => {
    if (userId) {
      redirect &&
        API.delete_account_user(userId).then((res) => {
          history.push("/login");
        });
      !redirect &&
        dispatch(
          actionCreatorsModal.showModal("delete", {
            open: true,
            uniqueTitle: "Deactivate User",
            uniqueMessage: (
              <div>
                <div> This user will be marked as inactive. </div>
                <div style={{ margin: "15px 0" }}>
                  The work they have done in PayLab will still be assigned to
                  them but they can no longer login or be added to new projects.
                </div>
                <div>Are you sure you want to deactivate this user?</div>
              </div>
            ),
            uniqueAction: "Deactivate",
            api: { name: "delete_account_user", config: [userId] },
            action: getAllUsers(),
          }),
        );
    }
  };
};

export const activateAccountUser = (userId) => {
  return (dispatch) => {
    if (userId) {
      dispatch(setData("sendingEmail", { id: userId, sending: true }));
      API.activate_user_account(userId)
        .then((res) => res.data)
        .then((res) => {
          if (res.data) {
            dispatch(
              actionCreatorsModal.showModal("confirm", {
                open: true,
                title: "Success!",
                message: res.data.message,
              }),
            );
          } else {
            dispatch(getAllUsers());
          }
          dispatch(setData("sendingEmail", { id: "", sending: false }));
        });
    }
  };
};

export const resendEmailToUser = (userId) => {
  return (dispatch) => {
    if (userId) {
      dispatch(setData("sendingEmail", { id: userId, sending: true }));
      API.resend_email_to_user(userId).then(
        (res) => {
          dispatch(setData("sendingEmail", { id: "", sending: false }));
          dispatch(
            actionCreatorsModal.showModal("confirm", {
              open: true,
              title: "Success!",
              message: "You have resent email to the user.",
            }),
          );
        },
        (error) => {
          dispatch(setData("sendingEmail", { id: "", sending: false }));
        },
      );
    }
  };
};

export const updateUser = (user, role, redirect = false) => {
  return (dispatch, getState) => {
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    let userInfo = user.toJS();
    let data = {
      ...userInfo,
      role_id: role,
    };
    dispatch(setData("requestingOwner", true));
    API.update_user_role(data)
      .then((res) => res.data)
      .then((res) => {
        if (redirect) {
          history.push(`/manage-account/profile?account_id=${accountId}`);
        } else {
          dispatch(getAllUsers());
        }
        dispatch(setData("requestingOwner", false));
      });
  };
};

export const getPlanConfig = (params) => {
  return (dispatch) => {
    API.read_plan_payment_config(params)
      .then((res) => res.data)
      .then((res) => res.data.config)
      .then((res) => {
        dispatch(setData("planInfo", res));
        dispatch(setData("loadingPlanInfo", false));
      });
  };
};

export const getPlanOverview = () => {
  //get pay & payment overall info
  return (dispatch) => {
    dispatch(setData("loadingPlanInfo", true));
    API.read_account_payment()
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("planDetail", res));
        //if there is an active subscription for the account
        //then send the price_id back to get all unarchived/active plans
        let params = new URLSearchParams(),
          price_id = res.current_plan.is_plan_active
            ? res.current_plan.price_id
            : null;
        price_id && params.append("price_id", price_id);
        dispatch(getPlanConfig(params));
        dispatch(setData("loadingPlanInfo", false));
      });
  };
};

export const getBillHistory = () => {
  //get pay & payment overall info
  return (dispatch) => {
    API.read_account_bill_history()
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        if (res) {
          dispatch(setData("billHistory", res));
        }
      });
  };
};

export const createSession = (priceId, pk_info) => {
  const stripePromise = loadStripe(pk_info);
  return (dispatch, getState) => {
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    API.create_checkout_session({
      priceId: priceId,
    })
      .then((res) => res.data)
      .then((res) => res.data)
      .then(async (res) => {
        const stripe = await stripePromise;
        const { error } = stripe.redirectToCheckout({
          sessionId: res.sessionId,
        });
        if (error) {
          dispatch(
            actionCreatorsModal.showModal("alert", {
              open: true,
              title: "Error!",
              message: error.message,
              url: "/manage-account/view?account_id=" + accountId,
            }),
          );
        }
      });
  };
};

export const saveCheckoutSession = (id) => {
  return (dispatch) => {
    API.checkout_payment_session(id)
      .then((res) => res.data)
      .then((res) => {
        dispatch(getBillHistory());
        dispatch(getPlanOverview());
      });
  };
};

export const getCustomerPortal = (id) => {
  return (dispatch) => {
    API.checkout_customer_portal(id)
      .then((res) => res.data)
      .then((res) => res.data.url)
      .then((res) => {
        //open url in the current tab instead of new tab
        window.open(res, "_self");
      });
  };
};

export const showNoticeBoard = (status, id) => {
  return (dispatch, getState) => {
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    if (status === "canceled") {
      dispatch(
        actionCreatorsModal.showModal("alert", {
          open: true,
          title: "Oops!",
          message:
            "You may have hit back, don’t worry you can choose plan again later.",
          url: "/manage-account/view?account_id=" + accountId,
        }),
      );
    } else {
      dispatch(
        actionCreatorsModal.showModal("confirm", {
          open: true,
          title: "Success!",
          message:
            "Your Plan & Payment details have been successfully updated.",
        }),
      );
    }
  };
};

export const updateImage = (name, data) => {
  return (dispatch) => {
    dispatch(actionCreatorsHeaders.setData(name, data));
  };
};

// for manual time zone updates through component
export const updateTimezone = (data) => {
  return (dispatch) => {
    API.update_account_detail({ time_zone: data }).then(() =>
      dispatch(getAccountInfo()),
    );
  };
};

export const getTimezoneOptions = (country) => {
  return (dispatch) => {
    API.read_timezones()
      .then((res) => res.data.data)
      .then((res) => {
        let filtered = filterTimezonesByCountry(country, res.timezones);
        let result = [];
        for (const i in filtered) {
          result.push({
            label: timezoneLabel(filtered[i]),
            value: filtered[i],
          });
        }

        dispatch(setData("timezoneOptions", result));
      });
  };
};

export const getLoginInfo = () => {
  return (dispatch) => {
    API.login_info()
      .then((res) => res.data)
      .then((res) => {
        dispatch(loginSuccess(res.data.profile));
      });
  };
};
