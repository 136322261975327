import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import { Table, ModalTitle } from "./styled";

import Modal from "../../../project/components/modal";
import PageBarPart from "../../../../common/pagination";
import { formatLargeCurrency } from "../../../../utils/currencyHelper";
import AccountDetail from "./accountRetentionDetail";
import { convertUTCToTime } from "../../../../utils/dateTimeHelper";
import { DownloadDropdown } from "common/dropdown/dropdown_with_icon";
import DownloadIcon from "../../../../statics/img/download-arrow.svg";

function RetentionReport(props) {
  const {
    open,
    handleOpen,
    retentionRecord,
    retentionAccounts,
    timezone,
    downloadRetentionPdf,
  } = props;
  // pagination stuff
  const [limit, setLimit] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState({
    withheldTotal: 0,
    releaseTotal: 0,
    netRelease: 0,
  });

  const handleSetLimit = (data) => {
    setCurrentPage(1);
    setLimit(data);
  };

  useEffect(() => {
    retentionRecord.map((item, index) => {
      if (index === 0) {
        setTotal({
          withheldTotal: item.get("cert_less_retention"),
          releaseTotal: item.get("cert_dlrr") + item.get("cert_pcrr"),
          netRelease: item.get("cert_retention"),
        });
      }
      return item;
    });
  }, [retentionRecord]);

  const getDiffWithPrevious = (data, index, size, ...fields) => {
    if (index >= size - 1) {
      return fields.reduce((acc, field) => acc + data.get(field), 0);
    }
    const currentData = fields.map((field) => data.get(field));
    const nextData = fields.map((field) =>
      retentionRecord.getIn([index + 1, field]),
    );
    const sumOfDifferences = currentData.reduce(
      (acc, value, i) => acc + (value - nextData[i]),
      0,
    );
    return sumOfDifferences;
  };

  return (
    <>
      <Modal
        title={
          <ModalTitle>
            <label>Retention Report</label>
            <DownloadDropdown
              labelImg={DownloadIcon}
              theme="primary"
              options={[
                {
                  label: "View file in new tab",
                  value: () => downloadRetentionPdf(false),
                },
                {
                  label: "Save file to your device",
                  value: () => downloadRetentionPdf(true),
                },
              ]}
            />
          </ModalTitle>
        }
        open={open}
        close={() => handleOpen(false)}
        hideActions
        className={"retention-report"}
      >
        <>
          {retentionAccounts.length !== 0 && (
            <AccountDetail accounts={retentionAccounts} viewOnly />
          )}
          <Table>
            <table>
              <thead>
                <tr>
                  <th>Certificate Ref.</th>
                  <th>Period (End)</th>
                  <th className="fit-width">Certificate Issued Date</th>
                  <th className="align-right">Retention Withheld</th>
                  <th className="align-right">Retention Released</th>
                </tr>
              </thead>
              <tbody>
                {retentionRecord.size !== 0 &&
                  retentionRecord.map((retention, index) => {
                    let page = currentPage - 1;
                    if (index >= page * limit && index < (page + 1) * limit) {
                      const withHeld = getDiffWithPrevious(
                        retention,
                        index,
                        retentionRecord.size,
                        "cert_less_retention",
                      );
                      const release = getDiffWithPrevious(
                        retention,
                        index,
                        retentionRecord.size,
                        "cert_dlrr",
                        "cert_pcrr",
                      );
                      return (
                        <tr key={retention.get("id")}>
                          <td> {retention.get("cert_name")}</td>
                          <td>
                            {
                              convertUTCToTime(
                                retention.get("period_ending"),
                                timezone,
                                false,
                                "dd/MM/yyyy",
                              )[0]
                            }
                          </td>
                          <td>
                            {
                              convertUTCToTime(
                                retention.get("cert_issued_date"),
                                timezone,
                                false,
                                "dd/MM/yyyy",
                              )[0]
                            }
                          </td>
                          <td className="align-right">
                            {formatLargeCurrency(withHeld)}
                          </td>
                          <td className="align-right">
                            {formatLargeCurrency(release)}
                          </td>
                        </tr>
                      );
                    } else {
                      return "";
                    }
                  })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={2} />
                  <td className="total-wrap">Total Retention</td>
                  <td className="total-wrap">
                    {formatLargeCurrency(total.withheldTotal)}
                  </td>
                  <td className="total-wrap">
                    {formatLargeCurrency(total.releaseTotal)}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} />
                  <td className="net-wrap">Net Retention</td>
                  <td colSpan={2} className="net-wrap">
                    {formatLargeCurrency(total.netRelease)}
                  </td>
                </tr>
              </tfoot>
            </table>

            <div className="pagination">
              <PageBarPart
                total={retentionRecord.size}
                limit={limit}
                pageCount={Math.ceil(retentionRecord.size / limit)}
                currentPage={currentPage}
                handlePageChange={setCurrentPage}
                handleSetData={handleSetLimit}
                field="fill"
              />
            </div>
          </Table>
        </>
      </Modal>
    </>
  );
}

RetentionReport.propTypes = {
  // retention table records in immutable
  retentionRecord: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Immutable.Iterable),
  ]).isRequired,
  // retention accounts info
  retentionAccounts: PropTypes.array.isRequired,
  //handle modal open/close
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  // contract timezone
  timezone: PropTypes.string.isRequired,
  // download retention pdf
  downloadRetentionPdf: PropTypes.func.isRequired,
};

export default RetentionReport;
