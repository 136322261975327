import React from "react";
import { parsePercent } from "../../../utils/numeralHelper";
import {
  STATUS_DRAFT,
  STATUS_COMPLETED,
  STATUS_ARCHIVE,
} from "../../../utils/constants";

const CompletionInfo = (props) => {
  const { info } = props;
  const isShowPercentage = (status) =>
    ![STATUS_DRAFT, STATUS_COMPLETED, STATUS_ARCHIVE].includes(status);
  const colouredStatus = (status) => {
    if (status === STATUS_DRAFT) {
      return <span className="draft">{status}</span>;
    } else if (status === STATUS_ARCHIVE) {
      return <span className="archived">{status}</span>;
    } else if (status === STATUS_COMPLETED) {
      return <span className="completed">Complete</span>;
    }
    return status;
  };

  return (
    <>
      {isShowPercentage(info.get("status")) && (
        <div className="complete-info">
          {parsePercent(
            info.get("cert_to_date_value"),
            info.get("total_contract_value"),
          )}
        </div>
      )}
      {!isShowPercentage(info.get("status")) && (
        <div className="complete-info no-background capitalize">
          {colouredStatus(info.get("status"))}
        </div>
      )}
    </>
  );
};

export default CompletionInfo;
