import React, { useState } from "react";
import { connect } from "react-redux";

import { Title } from "./styled";
import {
  InviteBtn as Button,
  BlueWhiteBtn as ImportButton,
  BlueOutlinedBtn as CancelButton,
} from "../button";
import ImportCSV from "../modal/component/modalBase";
import Content from "./csvUpload";
import { downloadFile } from "../../utils/createDownloadLink";

import { actionCreators } from "../modal";

import API from "../../server";

const UploadDialog = (props) => {
  const { resetInfo, showAlert, showMeg, type, proId, contractId, readOnly } =
    props;
  const [open, setOpen] = useState(false);
  const [fileList, setFileList] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setFileList([]);
  };

  const uploadFile = (file) => {
    const fileName = file[0].name + ".pdf";
    var dataSend = new FormData();
    dataSend.append("lineitem_template", file[0]);
    return API.upload_csv_file(proId, contractId, dataSend, {
      responseType: "blob",
      mimeType: "multipart/form-data",
      params: { type: type },
    }).then((res) => {
      setFileList(file);
      downloadFile(res, fileName);
    });
  };

  const importLineItem = () => {
    var dataSend = new FormData();
    dataSend.append("lineitem_template", fileList[0]);
    API.import_line_items(proId, contractId, dataSend, {
      mimeType: "multipart/form-data",
      params: { type: type },
    })
      .then((res) => res.data)
      .then(() => {
        showMeg();
        resetInfo();
        handleClose();
      });
  };

  return (
    <Title>
      {type !== "contra" && (
        <Button
          title="Import Excel File"
          type="upload"
          disabled={readOnly}
          onClick={() => setOpen(true)}
        />
      )}
      <ImportCSV
        title={"Import from Excel"}
        width={type === "base" ? "950px" : "1176px"}
        open={open}
        handleOpen={() => setOpen(true)}
        handleClose={handleClose}
      >
        <Content
          uploadFile={uploadFile}
          fileList={fileList}
          clearUpload={() => setFileList([])}
          alertBox={showAlert}
          type={type}
        />
        <div>
          <ImportButton
            autoFocus
            onClick={importLineItem}
            title="import"
            disabled={fileList.length === 0}
          />
          <CancelButton
            title="cancel"
            onClick={handleClose}
            margin={"0 12px 0 0"}
          />
        </div>
      </ImportCSV>
    </Title>
  );
};

const mapPropsToDispatch = (dispatch) => {
  return {
    showAlert(meg) {
      dispatch(
        actionCreators.showModal("alert", {
          open: true,
          title: "Error!",
          message: meg,
        }),
      );
    },
    showMeg() {
      dispatch(
        actionCreators.showModal("confirm", {
          open: true,
          title: "Success!",
          message: "You have imported an Excel file successfully！",
        }),
      );
    },
  };
};

export default connect(null, mapPropsToDispatch)(UploadDialog);
