import React from "react";

import { VarDetailSection, FileWrapper, FileDetail, ImgClose } from "./styled";
import { FormGroup } from "../form/styled";

import Input from "../form";
import { IconDropdown } from "../dropdown/icon_dropdown";
import Date from "../datePicker";
import { bytesToSize, checkIsParent } from "../../utils/helper";
import { ExcludeRetentionBox } from "../lineitem/sharedComponent";
import History from "./log_history";

export const BaseDetails = ({
  data,
  downloadFile,
  proId,
  contractId,
  readOnlyExpandKeys,
  handleClose,
  hasRetention,
  timeZone,
}) => {
  return (
    <VarDetailSection>
      {handleClose ? <ImgClose onClick={handleClose} /> : null}
      <FormGroup>
        <History
          data={data}
          proId={proId}
          contractId={contractId}
          timeZone={timeZone}
        />
        <div className="form_body">
          <Input field="child" label="Base Contract Attachments">
            <FileWrapper
              className={
                readOnlyExpandKeys ? "viewDetail expanded" : "viewDetail"
              }
            >
              {data.uploaded_files !== null ? (
                data.uploaded_files.map((item) => {
                  return (
                    <li key={item.id}>
                      <FileDetail>
                        <div className="fileImg attachment" />
                        <div className="fileName">{item.file_name}</div>
                        <div className="size">
                          ({bytesToSize(item.file_size)})
                        </div>
                        {/* if file is a pdf, use the icon dropdown */}
                        {item.file_name.includes("pdf") ? (
                          <div className="delFileImg noBackground">
                            <IconDropdown
                              options={[
                                {
                                  label: "View file in new tab",
                                  value: () =>
                                    downloadFile(
                                      proId,
                                      contractId,
                                      item.id,
                                      item.file_name,
                                      true,
                                    ),
                                },
                                {
                                  label: "Save file to your device",
                                  value: () =>
                                    downloadFile(
                                      proId,
                                      contractId,
                                      item.id,
                                      item.file_name,
                                      false,
                                    ),
                                },
                              ]}
                            />
                          </div>
                        ) : (
                          <div
                            className="delFileImg downloadFileImg"
                            value={item.file_name}
                            onClick={() =>
                              downloadFile(
                                proId,
                                contractId,
                                item.id,
                                item.file_name,
                              )
                            }
                          />
                        )}
                      </FileDetail>
                    </li>
                  );
                })
              ) : (
                <FileDetail>
                  <div className="fileName no-file">No files</div>
                </FileDetail>
              )}
            </FileWrapper>
            {!checkIsParent(data) && hasRetention && (
              <ExcludeRetentionBox
                className={"has-margin"}
                checked={data.exclude_retention}
                disabled={true}
              />
            )}
          </Input>
          <Input
            disabled
            label="Detailed Description"
            name="detailed_description"
            value={data.detailed_description || "No Description"}
            width={readOnlyExpandKeys ? "300px" : "600px"}
            field="textarea"
          />
        </div>
      </FormGroup>
    </VarDetailSection>
  );
};
export const VariationDetails = ({
  data,
  downloadFile,
  proId,
  contractId,
  readOnlyExpandKeys,
  handleClose,
  hasRetention,
  timeZone,
}) => {
  return (
    <VarDetailSection>
      {handleClose ? <ImgClose onClick={handleClose} /> : null}
      <FormGroup>
        <History
          data={data}
          proId={proId}
          contractId={contractId}
          timeZone={timeZone}
        />
        <div className="form_body">
          <Input label="Submit Date" field="child">
            <Date
              disable={true}
              initialValue={data.submit_date}
              id="submit_date"
              width={readOnlyExpandKeys ? "250px" : "350px"}
            />
          </Input>
          <Input label="Approved Date" field="child">
            <Date
              disable={true}
              initialValue={data.approved_date}
              id="approved_date"
              width={readOnlyExpandKeys ? "250px" : "350px"}
            />
          </Input>
          <Input
            disabled
            label="Variation Reference"
            name="variation_reference"
            value={data.variation_reference}
            width={readOnlyExpandKeys ? "250px" : "387px"}
          />
        </div>
        <div className="form_body" style={{ marginTop: "40px" }}>
          <Input field="child" label="Variation Attachments">
            <FileWrapper
              className={
                readOnlyExpandKeys ? "viewDetail expanded" : "viewDetail"
              }
            >
              {data.uploaded_files !== null ? (
                data.uploaded_files.map((item) => {
                  return (
                    <li key={item.id}>
                      <FileDetail>
                        <div className="fileImg attachment" />
                        <div className="fileName">{item.file_name}</div>
                        <div className="size">
                          ({bytesToSize(item.file_size)})
                        </div>
                        {item.file_name.includes("pdf") ? (
                          <div className="delFileImg noBackground">
                            <IconDropdown
                              options={[
                                {
                                  label: "View file in new tab",
                                  value: () =>
                                    downloadFile(
                                      proId,
                                      contractId,
                                      item.id,
                                      item.file_name,
                                      true,
                                    ),
                                },
                                {
                                  label: "Save file to your device",
                                  value: () =>
                                    downloadFile(
                                      proId,
                                      contractId,
                                      item.id,
                                      item.file_name,
                                      false,
                                    ),
                                },
                              ]}
                            />
                          </div>
                        ) : (
                          <div
                            className="delFileImg downloadFileImg"
                            value={item.file_name}
                            onClick={() =>
                              downloadFile(
                                proId,
                                contractId,
                                item.id,
                                item.file_name,
                              )
                            }
                          />
                        )}
                      </FileDetail>
                    </li>
                  );
                })
              ) : (
                <FileDetail>
                  <div className="fileName no-file">No files</div>
                </FileDetail>
              )}
            </FileWrapper>
            {!checkIsParent(data) && hasRetention && (
              <ExcludeRetentionBox
                className={"has-margin"}
                checked={data.exclude_retention}
                disabled={true}
              />
            )}
          </Input>
          <Input
            disabled
            label="Detailed Description"
            name="detailed_description"
            value={data.detailed_description}
            width={readOnlyExpandKeys ? "300px" : "600px"}
            field="textarea"
          />
        </div>
      </FormGroup>
    </VarDetailSection>
  );
};
