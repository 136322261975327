import Pagination from "react-paginating";
import React, { Component, Fragment } from "react";
import { PageBar, ImgDirection } from "./styled";
import leftIcon from "../../statics/img/icon-chevron-left-medium-grey.svg";
import rightIcon from "../../statics/img/icon-chevron-right-medium-grey.svg";
import firstIcon from "../../statics/img/icon-first-page-medium-grey.svg";
import lastIcon from "../../statics/img/icon-last-page-medium-grey.svg";
import InputSelect from "../dropdown/dropdown_with_label";
import * as theme from "../../theme";

function info(current, pageSize, total) {
  let from = (current - 1) * pageSize + 1;
  let to = current * pageSize;
  if (to > total) to = total;
  if (total === 0) from = 0;
  return (
    <>
      {from} - {to}&nbsp;&nbsp;of&nbsp;&nbsp;{total}
    </>
  ); // extra space for formatting
}

const SelectContainer = (props) => {
  const { handleChange, value, options } = props;
  return (
    <div className="select_div">
      <div className="sortTxt">Views:</div>
      <div style={{ float: "right" }}>
        <InputSelect
          onChange={handleChange}
          value={value}
          className="select_style"
          options={options}
          width="201px"
          controlWidth="140px"
          margin="10px 0 0 -60px"
        />
      </div>
    </div>
  );
};

class PageBarPart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectNum: 50,
    };
  }

  handleChange = (event) => {
    this.setState({ selectNum: event.value }, () => this.globalSearch());
  };

  globalSearch = () => {
    let { selectNum } = this.state;
    this.props.handleSetData(selectNum);
  };

  render() {
    const PageContainer = ({ pages, getPageItemProps }) => {
      return (
        <Fragment>
          {pages.map((page) => {
            let activePage = null;
            if (currentPage === page) {
              activePage = {
                backgroundColor: theme.palette.veryMediumGrey,
                color: theme.palette.white,
                borderRadius: "4px",
              };
            }
            return (
              <div
                className="btn"
                key={page}
                style={activePage}
                {...getPageItemProps({
                  pageValue: page,
                  onPageChange: handlePageChange,
                })}
              >
                {page}
              </div>
            );
          })}
        </Fragment>
      );
    };

    const { total, limit, pageCount, currentPage, handlePageChange, field } =
      this.props;
    const options = [
      { label: "10 / Per page", value: 10 },
      { label: "20 / Per page", value: 20 },
      { label: "30 / Per page", value: 30 },
      { label: "40 / Per page", value: 40 },
      { label: "50 / Per page", value: 50 },
    ];
    const valueFromId = (opts, id) => opts.find((o) => o.value === id);
    return (
      <PageBar className={field}>
        <Pagination
          total={total}
          limit={limit}
          pageCount={pageCount}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        >
          {({
            pages,
            currentPage,
            hasNextPage,
            hasPreviousPage,
            previousPage,
            nextPage,
            totalPages,
            getPageItemProps,
          }) => (
            <div className="buttonWrapper">
              {field && (
                <SelectContainer
                  handleChange={this.handleChange}
                  value={valueFromId(options, this.state.selectNum)}
                  options={options}
                />
              )}
              <div className="right">
                <div className="number_count">
                  {info(currentPage, limit, total)}
                </div>
                <ImgDirection
                  icon={firstIcon}
                  {...getPageItemProps({
                    pageValue: 1,
                    onPageChange: handlePageChange,
                  })}
                />

                {hasPreviousPage && (
                  <ImgDirection
                    icon={leftIcon}
                    {...getPageItemProps({
                      pageValue: previousPage,
                      onPageChange: handlePageChange,
                    })}
                  />
                )}
                {!field && (
                  <PageContainer
                    pages={pages}
                    currentPage={currentPage}
                    getPageItemProps={getPageItemProps}
                  />
                )}

                {hasNextPage && (
                  <ImgDirection
                    icon={rightIcon}
                    {...getPageItemProps({
                      pageValue: nextPage,
                      onPageChange: handlePageChange,
                    })}
                  />
                )}

                <ImgDirection
                  icon={lastIcon}
                  {...getPageItemProps({
                    pageValue: totalPages,
                    onPageChange: handlePageChange,
                  })}
                />
              </div>
            </div>
          )}
        </Pagination>
      </PageBar>
    );
  }
}

export default PageBarPart;
