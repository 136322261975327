import React, { useState, useEffect } from "react";
import Link from "../../../common/linkWithQuery";
import { Table as Tables } from "shineout";
import numeral from "numeral";
import * as theme from "../../../theme";
import {
  HIDE_ACTIVE,
  SHOW_ARCHIVED,
  STATUS_ACTIVE,
  STATUS_ARCHIVE,
} from "../../../utils/constants";

import {
  NumDisplay,
  SummaryContainer,
  ProjectSummaryTable,
  SearchInput,
  WorkflowStep,
  DayIndicator,
  ToolTipWrap,
} from "../styled";

import { currencySubunitToUnit } from "../../../utils/currencyHelper";
import PageBarPart from "../../../common/pagination";
import Tooltip from "../../../common/toolTip";
import Input from "../../../common/form";
import Filter from "../../../common/filter";

function formatPercent(num) {
  if (num < 0) {
    num = Math.abs(num);
  }
  return numeral(num).format("0.00%");
}

function formatName(userName) {
  if (!userName) {
    return "";
  }
  return userName
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), "");
}

function compareByField(name, isSortAsc) {
  return (a, b) =>
    isSortAsc ? a[name].localeCompare(b[name]) : b[name].localeCompare(a[name]);
}

const TooltipWhite = (props) => (
  <Tooltip
    {...props}
    placement="bottom"
    color={theme.palette.white}
    fontcolor={theme.palette.gunmetal}
    size={"14px"}
  >
    {props.children}
  </Tooltip>
);

const DisplayNumBar = ({ data, field }) => {
  let color = "tangerineOrange",
    valueArray = [],
    total = data.base_value + data.variation_value;

  if (field === "project") {
    //! todo process negative situation
    valueArray = [{ value: data.variation_value }, { value: data.base_value }];
    let percent =
      data.base_value === 0
        ? 1
        : Math.abs(data.variation_value / data.base_value);
    if (percent < 0.5) {
      color = "gunmetal";
    } else if (percent >= 0.8) {
      color = "watermelonRed";
    }
    const percentage = formatPercent(Math.min(percent, 1)),
      pieColor = theme["palette"][color],
      baseColor = theme.palette.wolfGrey;
    return (
      <NumDisplay className="pie_chart">
        <div>
          {valueArray.map((item, index) => (
            <div key={field + index}>
              {currencySubunitToUnit(item.value, true, true, true)}
            </div>
          ))}
        </div>
        <div
          className="num_pie"
          style={{
            background: `conic-gradient(${pieColor} 0 ${percentage}, ${baseColor} ${percentage} 100%)`,
          }}
        />
      </NumDisplay>
    );
  } else {
    let difference = Math.abs(data.claim_value - data.cert_value);
    valueArray = [
      {
        percent: data.claim_value / total,
        value: data.claim_value,
      },
      {
        percent: data.cert_value / total,
        value: data.cert_value,
      },
    ];

    if (difference < Math.abs(0.05 * data.claim_value)) {
      color = "brightGreen";
    } else if (difference >= Math.abs(0.1 * data.claim_value)) {
      color = "watermelonRed";
    }
    return (
      <NumDisplay color={color}>
        {valueArray.map((item, index) => (
          <div className="value_container" key={field + index}>
            <div>{currencySubunitToUnit(item.value, true, true, true)}</div>
            <div className="num_bar">
              <div
                className="complete"
                style={{ width: formatPercent(Math.min(item.percent, 1)) }}
              />
            </div>
          </div>
        ))}
      </NumDisplay>
    );
  }
};

const WorkflowStepPoint = ({ data }) => {
  const item = [];
  let showCertStar = true;
  let certNode = "step active";
  if (
    data.total_cert_issued.length === 0 &&
    data.total_cert_issued.length === data.total_claims_issued.length
  ) {
    showCertStar = false;
  }

  if (!data.contracts) {
    if (showCertStar) {
      if (data.workflow) {
        if (
          data.total_cert_issued.length < data.total_claims_issued.length &&
          !data.workflow.cert_id
        ) {
          certNode = "step";
        }
        item.push(<li key={1} className={certNode} />);
        for (let i = 2; i <= data.workflow.total_step + 1; i++) {
          item.push(
            <li
              key={i}
              className={
                "step" + (data.workflow.current_step + 1 > i ? " active" : " ")
              }
            />,
          );
        }
        item.push(
          <li
            key={data.workflow.total_step + 2}
            className={
              "cert" +
              (data.workflow.cert_status === "approved" ? " active" : "")
            }
          />,
        );
      } else {
        item.push(<li key={1} className={certNode} />);
        item.push(
          <li
            key={2}
            className={
              "cert" +
              (data.cert_to_date.cert_status === "approved" ? " active" : "")
            }
          />,
        );
      }
    }
  }
  return item;
};

const WorkflowBar = ({ data }) => {
  if (!data.workflow || !data.workflow.total_step) {
    return (
      <WorkflowStep>
        <div className="stepper-wrapper">
          <ul className="stepper-item">
            <WorkflowStepPoint data={data} />
          </ul>
        </div>
      </WorkflowStep>
    );
  } else {
    let ApproversList = !data.workflow.approvers
        ? []
        : data.workflow.approvers.filter((item) => !item.approved_date),
      requiredApprover = [],
      optionalApprover = [];
    ApproversList.map((item) => {
      if (item.required) {
        requiredApprover.push(item.user_name);
      } else {
        optionalApprover.push(item.user_name);
      }
      return item;
    });
    const Approvers = (
      <div>
        <div>{requiredApprover.join(", ")}</div>
        {optionalApprover.length !== 0 && (
          <>
            <br />
            <div>Optional: {optionalApprover.join(", ")}</div>
          </>
        )}
      </div>
    );
    return (
      <WorkflowStep>
        <div className="stepper-wrapper">
          <ul className="stepper-item">
            <WorkflowStepPoint data={data} />
          </ul>
        </div>
        {(requiredApprover.length > 0 || optionalApprover.length > 1) && (
          <TooltipWhite title={Approvers}>
            <div className="approver-wrapper">
              <div className="approver-name-container">
                {formatName(requiredApprover[0])}
              </div>
              {Approvers.length > 1 && <div className="more-approver" />}
            </div>
          </TooltipWhite>
        )}
      </WorkflowStep>
    );
  }
};

const DisplayDays = ({ data }) => {
  let days = data.days_to_respond,
    color = "",
    deg = 0;
  if (days) {
    if (days === "done") {
      color = "brightGreen";
      deg = 360;
    } else if (days <= 0) {
      color = "watermelonRed";
      deg = 360;
    } else if (days < 4) {
      color = "watermelonRed";
      deg = 90;
    } else if (days < 8) {
      color = "tangerineOrange";
      deg = 180;
    } else {
      color = "brightGreen";
      deg = 270;
    }
  }

  return (
    <DayIndicator days={days} color={color} deg={deg}>
      {days && (
        <>
          <div className="days-indicator">
            <div
              className={
                "progress-circle " +
                (days > 7 || days <= 0 || days === "done" ? "over50" : "")
              }
            >
              <div className="progress-circle-clipper">
                <div className="progress-circle-bar" />
                <div className="progress-circle-value-bar" />
              </div>
            </div>
          </div>
          <div>{days === "done" ? "Done" : `${days} days`}</div>
        </>
      )}
    </DayIndicator>
  );
};

const ValueIndicatorItem = ({ data }) => (
  <div className="list">
    <div className={"point " + data.className} />
    <div>{data.label}</div>
  </div>
);

const ProjectValueIndicator = (
  <ToolTipWrap>
    {[
      {
        className: "point_grey",
        label: "Variations are under 50% of base contract value",
      },
      {
        className: "point_yellow",
        label:
          "Variations equal to and over 50% but less than 80% of base contract value",
      },
      {
        className: "point_red",
        label: "Variations equal to and over 80% of base contract value",
      },
    ].map((item) => (
      <ValueIndicatorItem key={item.className} data={item} />
    ))}
  </ToolTipWrap>
);

const ClaimValueIndicator = (
  <ToolTipWrap>
    {[
      { className: "point_green", label: "Up to 5% difference" },
      { className: "point_yellow", label: "5% - 10% difference" },
      { className: "point_red", label: "Over 10% difference" },
    ].map((item) => (
      <ValueIndicatorItem key={item.className} data={item} />
    ))}
  </ToolTipWrap>
);

export default function Table(props) {
  const {
    singleProjects,
    groupTable,
    setExpandKeys,
    expandKeys,
    aggPeriod,
    changeGroupPeriod,
    accountConfig,
  } = props;
  const [searchInput, setSearchInput] = useState("");
  const [sortField] = useState("name");
  const [isSortAsc, setIsSortAsc] = useState(true);
  const [tableToDisplay, setTableToDisplay] = useState([]);
  const [summaryToDisplay, setSummaryToDisplay] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);

  // default filters
  const [filterOptions, setFilterOptions] = useState({
    "Project Status": [
      { label: "Hide Active", value: HIDE_ACTIVE },
      { label: "Show Archived", value: SHOW_ARCHIVED },
    ],
  });

  const columns = [
    {
      title: (
        <>
          Name
          <span
            className={
              `sort_icon ` +
              (sortField === "name" && isSortAsc ? `sort_down` : `sort_up`)
            }
            onClick={() => setIsSortAsc(!isSortAsc)}
          />
        </>
      ),
      render: (d) => {
        if (!d.contracts) {
          return (
            <Link
              to={`/contract/${d.project_id}/cert/view/${d.id}`}
              search={`?account_id=${d.account_id}`}
              className="link_url"
            >
              {d.claimer_name}
            </Link>
          );
        } else {
          return (
            <div className="groupName">
              <Link
                to={`/project/view/${d.project_id}`}
                search={`?account_id=${d.account_id}`}
                className="link_url"
              >
                {d.name}
              </Link>
              <div
                className={"pinIcon " + (d.is_pinned ? "" : "unActive")}
                onClick={() => handlePin(d)}
              />
            </div>
          );
        }
      },
      treeColumnsName: "contracts",
      treeIndent: 10,
      width: 280,
      className: "project group projectName",
    },
    {
      title: "Total Contract Value",
      render: (d) => currencySubunitToUnit(d.total_value, true, true, true),
      width: 120,
    },
    {
      title: (
        <div className="has_icon">
          Total Variations / <br /> Base Contract Value
          <TooltipWhite title={ProjectValueIndicator}>
            <div className="img_icon" />
          </TooltipWhite>
        </div>
      ),
      render: (d) => <DisplayNumBar data={d} field={"project"} />,
      width: 185,
    },
    {
      title: (
        <div className="has_icon">
          {accountConfig?.getIn(["claim", "value"])} to Date /<br />
          {accountConfig?.getIn(["cert", "value"])} to Date
          <TooltipWhite title={ClaimValueIndicator}>
            <div className="img_icon" />
          </TooltipWhite>
        </div>
      ),
      render: (d) => <DisplayNumBar data={d} field={"claim"} />,
      width: 185,
    },
    {
      title: `${accountConfig?.getIn(["claim", "noun"])}s Received`,
      className: "alignToCenter",
      render: (d) => d.total_claims_issued.length,
      width: 90,
    },
    {
      title: "Certificates Issued",
      className: "alignToCenter",
      render: (d) => {
        if (d.contracts) {
          return d.total_cert_issued.length;
        }
        let link =
          d.total_cert_issued.length === 1
            ? `/review-cert/cert/${d.project_id}/${d.id}/${d.total_cert_issued[0].claim_uuid}/${d.total_cert_issued[0].cert_uuid}/approved`
            : `/contract/${d.project_id}/cert/view/${d.id}`;
        return (
          <Link
            to={link}
            search={`?account_id=${d.account_id}`}
            className="link_url"
          >
            {d.total_cert_issued.length === 1 ? (
              <button className="view_button">View</button>
            ) : (
              d.total_cert_issued.length
            )}
          </Link>
        );
      },
      width: 90,
    },
    {
      title: "Workflow Status",
      className: "alignToCenter",
      render: (d) => <WorkflowBar data={d} />,
      width: 196,
    },
    {
      title: "Days to Respond",
      className: "alignToCenter",
      render: (d) => <DisplayDays data={d} />,
      width: 120,
    },
  ];
  const options = [
    { label: "Current Month", value: "current" },
    { label: "Previous Month", value: "last" },
  ];

  useEffect(() => {
    // shine out tables will only accept the data in this format
    let displayData = [],
      displaySummary = {};
    [displayData, displaySummary] = transformGroupData(
      groupTable.toJS(),
      searchInput,
      filterOptions,
    );
    displayData = handleSort(displayData, sortField, isSortAsc);
    setTableToDisplay(displayData);
    setSummaryToDisplay(displaySummary);
    // eslint-disable-next-line
  }, [groupTable, searchInput, isSortAsc, sortField, filterOptions]);

  const handlePin = (data) => {
    props.pinGroup(data.id, !data.is_pinned, props.aggPeriod);
  };

  const handleSetDataNum = (data) => {
    setCurrentPage(1);
    setPageLimit(data);
  };

  const handleSearch = (data, searchInput) => {
    let searched = Object.values(data).map((project, i) => {
      if (project["name"].toLowerCase().includes(searchInput.toLowerCase())) {
        return project;
      }
      // search contract claimer names if searchInput can't be found from project names
      let hasClaimerName = project;
      hasClaimerName["contracts"] = project["contracts"].filter((c) => {
        return c["claimer_name"]
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });

      // if claimer name exists...
      if (
        hasClaimerName["contracts"] &&
        hasClaimerName["contracts"].length > 0
      ) {
        setExpandKeys([project["project_id"]]);
        return hasClaimerName;
      }
      return null;
    });
    searched = searched.filter((p) => p);

    return searched;
  };

  const transformGroupData = (table, searchInput, filters) => {
    if (searchInput) {
      table = handleSearch(table, searchInput);
    }

    // get project filters;
    // selectStatus: 0 is active, 1 is archived.
    // selectStatus[0]==true means: active select.
    // active project is displayed initial.
    let selectStatus = [true, false];
    for (const [key, value] of Object.entries(filters["Project Status"])) {
      if (value) {
        if (key === HIDE_ACTIVE) {
          selectStatus[0] = false;
        }
        if (key === SHOW_ARCHIVED) {
          selectStatus[1] = true;
        }
      }
    }
    let cFilters = [];
    if (selectStatus[0]) {
      cFilters.push(STATUS_ACTIVE);
    }

    if (selectStatus[1]) {
      cFilters.push(STATUS_ARCHIVE);
    }

    let projectGroupTable = [],
      projectGroupValue = {
        claim_value: 0,
        cert_value: 0,
        claim_num: 0,
        cert_num: 0,
      };
    if (table) {
      Object.values(table).map((item) => {
        if (item.contracts && cFilters.includes(item.status)) {
          let data = {
            id: item.id,
            name: item.name,
            total_value: 0,
            base_value: 0,
            variation_value: 0,
            contracts: [],
            claim_value: 0,
            cert_value: 0,
            claimed_to_date_value: 0,
            cert_to_date_value: 0,
            total_claims_issued: [],
            total_cert_issued: [],
            days_to_respond: null,
            is_pinned: item.is_pinned,
            account_id: item.account_id,
            status: item.status,
          };
          Object.values(item.contracts).map((ele) => {
            ele.total_value = ele.base_value + ele.variation_value;
            ele.projects = null;
            ele.claim_value =
              ele.claim_to_date.claim_value.base +
              ele.claim_to_date.claim_value.variations +
              ele.claim_to_date.claim_value.materials;
            ele.cert_value =
              ele.cert_to_date.cert_value.base +
              ele.cert_to_date.cert_value.variations +
              ele.cert_to_date.cert_value.materials;

            ele.total_cert_issued = ele.total_cert_issued || [];
            ele.total_claims_issued = ele.total_claims_issued || [];
            ele.account_id = data.account_id;

            data.base_value += ele.base_value;
            data.variation_value += ele.variation_value;
            data.total_value += ele.total_value;
            data.total_claims_issued = data.total_claims_issued.concat(
              ele.total_claims_issued,
            );
            data.total_cert_issued = data.total_cert_issued.concat(
              ele.total_cert_issued,
            );
            data.claim_value += ele.claim_value;
            //set group claim up to date value
            data.claimed_to_date_value += ele.claimed_to_date_value;
            //set group cert up to date value
            data.cert_to_date_value += ele.cert_to_date_value;
            data.cert_value += ele.cert_value;
            data.project_id = item.id;
            projectGroupValue.claim_value += ele.claim_value;
            projectGroupValue.cert_value += ele.cert_value;
            switch (ele.claim_to_date.claim_status) {
              case "issue":
                if (
                  !data.days_to_respond ||
                  data.days_to_respond === "done" ||
                  ele.days_to_respond < data.days_to_respond
                ) {
                  data.days_to_respond = ele.days_to_respond;
                }
                break;
              case "certified":
                data.days_to_respond = "done";
                ele.days_to_respond = "done";
                break;
              default:
                ele.days_to_respond = null;
                break;
            }
            ele.project_id = item.id;
            data.contracts.push(ele);
            return ele;
          });
          projectGroupTable.push(data);
          projectGroupValue.claim_num += data.total_claims_issued.length;
          projectGroupValue.cert_num += data.total_cert_issued.length;
        }
        return null;
      });
      return [projectGroupTable, projectGroupValue];
    }
  };

  const handleSort = (data, field, isSortAsc) => {
    let sorted = data;
    // sort contracts via claimer names
    for (const [key, value] of Object.entries(sorted)) {
      sorted[key]["contracts"] = value["contracts"].sort(
        compareByField("claimer_name", isSortAsc),
      );
    }
    // sort project names
    sorted = sorted.sort(compareByField(field, isSortAsc));
    let pinned = sorted.filter((p) => p.is_pinned);
    let unPinned = sorted.filter((p) => !p.is_pinned);

    return [...pinned, ...unPinned];
  };

  return (
    <SummaryContainer className="group">
      <div className="sumType">
        <span>Project Summary</span>
        <div style={{ display: "flex" }}>
          <SearchInput>
            <div className="imgStyle" />
            <input
              name="searchInput"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder={`Search ${accountConfig?.getIn([
                "claim",
                "name",
              ])}/group name …`}
            />
          </SearchInput>
          <Input
            field="dropdown"
            value={aggPeriod}
            onChange={(e) => changeGroupPeriod(e.value)}
            name="aggPeriod"
            options={options}
            width={200}
          />
          <Filter
            filterOptions={filterOptions}
            handleFilter={setFilterOptions}
          />
        </div>
      </div>
      <ProjectSummaryTable>
        <div className="table_top-widget">
          {[
            {
              label: `${accountConfig?.getIn(["claim", "noun"])}s In`,
              value: summaryToDisplay.claim_value,
              subValue: summaryToDisplay.claim_num,
              className: "claim",
            },
            {
              label: "Certified Out",
              value: summaryToDisplay.cert_value,
              subValue: summaryToDisplay.cert_num,
              className: "cert",
            },
            {
              label: "Difference",
              value: summaryToDisplay.claim_value - summaryToDisplay.cert_value,
              subValue: null,
              className: "difference",
            },
          ].map((item) => (
            <div className={"widget-wrap " + item.className} key={item.label}>
              <label>
                {item.label}
                {item.subValue === null ? null : (
                  <span>( {item.subValue} No.)</span>
                )}
                {item.className === "difference" && item.value < 0 && (
                  <TooltipWhite
                    title={`There is more certified out than ${accountConfig?.getIn(
                      ["claim", "noun"],
                    )}s in.`}
                    placement="bottom"
                    color="white"
                    fontcolor="#425563"
                    size={"14px"}
                  >
                    <div className="hover_icon" />
                  </TooltipWhite>
                )}
              </label>
              <div className={"value " + (item.value < 0 ? "negative" : "")}>
                {currencySubunitToUnit(item.value, true, false, true)}
              </div>
            </div>
          ))}
        </div>
        <Tables
          keygen="id"
          data={tableToDisplay.filter(
            (item, index) =>
              index >= (currentPage - 1) * pageLimit &&
              index < currentPage * pageLimit,
          )}
          columns={columns}
          onTreeExpand={(keys) => setExpandKeys(keys)}
          treeExpandKeys={expandKeys.toJS()}
        >
          {tableToDisplay.length === 0 && (
            <table>
              <tbody>
                <tr>
                  <td colSpan={8}>
                    <div className="empty_notice-label">
                      No projects found. Please try different search terms.
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </Tables>
        <div className="table_bottom-bar">
          <div style={{ marginTop: 30 }}>
            {singleProjects.size !== 0 && (
              <span>
                There are {singleProjects.size} projects not in groups. Add them
                to groups to see here.
              </span>
            )}
          </div>

          <PageBarPart
            total={tableToDisplay.length}
            limit={pageLimit}
            pageCount={5}
            currentPage={currentPage}
            handlePageChange={setCurrentPage}
            handleSetData={handleSetDataNum}
            field="big"
          />
        </div>
      </ProjectSummaryTable>
    </SummaryContainer>
  );
}
