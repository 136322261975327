import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../../common/config/store";
import { ProjectInfoWrapper, Error } from "../styled";
import { BlueOutlinedBtn as Button } from "../../../common/button";
import Modal from "./modal";
import { capitalizeAddress } from "../../../utils/helper";
import Input from "../../../common/form";
import { COUNTRY_AU } from "../../../utils/constants";
import AddressSearch from "../../../common/mapbox/addressSearch";
import MapboxDisplay from "../../../common/mapbox/mapboxDisplay";
import { StateWarningModal } from "../../../common/config/stateConfigInput";

const RequiredInput = (props) => {
  return (
    <div className={"has_margin " + props.className}>
      <Input {...props} />
      {props.error && (
        <Error>
          <div className="error_img" />
          {props.error}
        </Error>
      )}
    </div>
  );
};

const ProjectItem = ({ item }) => {
  if (item.defaultValue) {
    return (
      <>
        <label>
          <b>{item.label}</b>
        </label>
        <label>{item.defaultValue}</label>
      </>
    );
  } else {
    return null;
  }
};

const DescriptionComponent = (props) => {
  const { projectInfo, updateInfo, accountConfig, getCountries } = props;
  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [valid, setValid] = React.useState(false);
  const [info, setInfo] = React.useState({});
  const [projectAddress, setProjectAddress] = React.useState({});
  const isLinked = projectInfo.get("integration") ? true : false;

  const ProjectInfoDisplay = [
    { label: "Project Name", defaultValue: projectInfo.get("name") },
    { label: "Description", defaultValue: projectInfo.get("description") },
    {
      label: "Project Address",
      defaultValue: capitalizeAddress(
        projectInfo.get("street_address"),
        projectInfo.get("suburb"),
        projectInfo.get("city"),
        projectInfo.get("postcode"),
        projectInfo.get("state"),
        projectInfo.get("country"),
      ),
    },
  ];

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
    open &&
      setInfo({
        name: projectInfo.get("name"),
        description: projectInfo.get("description"),
        street_address: projectInfo.get("street_address"),
        suburb: projectInfo.get("suburb"),
        city: projectInfo.get("city"),
        postcode: projectInfo.get("postcode"),
        state: projectInfo.get("state"),
        country: projectInfo.get("country"),
      });
  }, [projectInfo, open]);

  useEffect(() => {
    Object.keys(projectAddress).length !== 0 &&
      setInfo({ ...info, ...projectAddress });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectAddress]);

  const ProjectInfoEdit = [
    {
      label: "Project Name",
      name: "name",
      field: isLinked ? "link-field" : "text",
      defaultValue: projectInfo.get("name"),
      error: errors.name,
      className: "full-width",
      ...(isLinked && { value: projectInfo.get("name") }),
    },
    {
      label: "Description",
      name: "description",
      defaultValue: projectInfo.get("description"),
      field: isLinked ? "link-field" : "textarea",
      className: "full-width",
      placeholder: "Optional",
      ...(isLinked && { value: projectInfo.get("description") }),
    },
    {
      label: accountConfig.getIn(["address", "street"]),
      value: info.street_address,
      name: "street_address",
      field: isLinked ? "link-field" : "text",
      className: "full-width",
      error: errors.street_address,
    },
    {
      label: accountConfig.getIn(["address", "suburb"]),
      value: info.suburb,
      name: "suburb",
      field: isLinked ? "link-field" : "text",
      className: "medium-width",
      error: errors.suburb,
      ...(isLinked && { width: "424px" }),
    },
    {
      label: accountConfig.getIn(["address", "city"]),
      value: info.city,
      name: "city",
      field: isLinked ? "link-field" : "text",
      className: "medium-width",
      error: errors.city,
      ...(isLinked && { width: "424px" }),
    },
    {
      label: accountConfig.getIn(["address", "postcode"]),
      value: info.postcode,
      name: "postcode",
      field: isLinked ? "link-field" : "text",
      className: "small-width",
      error: errors.postcode,
      ...(isLinked && { width: "272px" }),
    },
    {
      label: accountConfig.getIn(["address", "state"]),
      value: info.state,
      name: "state",
      field: isLinked
        ? "link-field"
        : info.country === COUNTRY_AU
          ? "dropdown"
          : "text",
      className: "small-width",
      placeholder:
        info.country === COUNTRY_AU
          ? `Select ${accountConfig.getIn(["address", "state"])}`
          : accountConfig.getIn(["address", "state"]),
      error: errors.state,
      width: "272px",
      options: accountConfig.get("state")?.toJS(),
      missingRequired: !info.state && errors.state !== undefined,
    },
    {
      value: info.country,
      name: "country",
      field: "lock-country",
      hasInitialValue: true,
      className: "small-width",
      width: "272px",
      error: errors.country,
    },
  ];
  const handleChange = (e) => {
    let name = e.target.name,
      value = e.target.value,
      newInfo = {};
    newInfo = Object.assign({}, info, {
      [name]: value,
    });
    valid && onValidate(newInfo);
    return setInfo(newInfo);
  };

  const handleSelectionChange = (e, name) => {
    let value = e.value,
      newInfo = {};
    newInfo = Object.assign({}, info, {
      [name]: value,
    });
    valid && onValidate(newInfo);
    return setInfo(newInfo);
  };

  const onValidate = (values) => {
    const errors = {};
    const stringArr = ["name", "street_address", "country", "state"];
    stringArr.forEach((element) => {
      if (
        element === "state" &&
        values.country === COUNTRY_AU &&
        !values.state
      ) {
        errors[element] = "Required";
      }
      if (element !== "state" && !values[element]) {
        errors[element] = "Required";
      }
    });
    setErrors(errors);
    return errors;
  };

  const validate = () => {
    var valid = true,
      errors = {};
    errors = onValidate(info);
    valid &= Object.keys(errors).length === 0;
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValid(true);
    if (!validate()) return;
    setOpen(false);
    return updateInfo(info, "basic");
  };

  const handleClose = (value) => {
    setOpen(value);
    setErrors({});
  };

  const setTargetAddress = (address, insertDefaultCountry) => {
    if (!insertDefaultCountry) {
      valid && onValidate({ ...info, ...address });
      setProjectAddress(address);
    }
  };

  return (
    <>
      {/* display project description */}
      <ProjectInfoWrapper>
        <div className="title">Project Details</div>
        <Button title="Edit" onClick={() => handleClose(true)} />
        {ProjectInfoDisplay.map((ele) => (
          <ProjectItem item={ele} key={ele.label} />
        ))}
      </ProjectInfoWrapper>
      {/* edit project description */}
      <Modal
        title="Project Details"
        className={"align-row"}
        open={open}
        close={() => handleClose(false)}
        submit={(e) => handleSubmit(e)}
        disabled={Object.keys(errors).length !== 0}
      >
        <>
          {ProjectInfoEdit.map((ele, index) => {
            return (
              <Fragment key={ele.name}>
                {index === 2 && (
                  <>
                    <h3>Project Address</h3>
                    {!isLinked && (
                      <MapboxDisplay setAddress={setTargetAddress}>
                        <AddressSearch
                          hasRestrictCountry
                          setAddress={setTargetAddress}
                        />
                      </MapboxDisplay>
                    )}
                  </>
                )}
                <RequiredInput
                  {...ele}
                  onChange={(e) => {
                    if (ele.field === "dropdown") {
                      return handleSelectionChange(e, ele.name);
                    } else {
                      return handleChange(e);
                    }
                  }}
                />
              </Fragment>
            );
          })}
          <StateWarningModal
            country={info.country}
            state={info.state}
            showError={errors.state !== undefined}
          />
        </>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  countriesList: state.getIn(["config", "countriesList"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getCountries() {
      dispatch(actionCreators.readCountries());
    },
  };
};
export const Description = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DescriptionComponent);

const AdditionalDescriptionComponent = (props) => {
  const { projectInfo, updateInfo, accountConfig } = props;
  const [open, setOpen] = React.useState(false);
  const [info, setInfo] = React.useState({
    project_number: projectInfo.get("project_number"),
    gst: projectInfo.get("gst"),
    vault_url: projectInfo.get("vault_url"),
  });
  const isLinked = projectInfo.get("integration") ? true : false;
  const ProjectInfoDisplay = [
    {
      label: "Project Number",
      defaultValue: projectInfo.get("project_number"),
      name: "project_number",
      placeholder: "Enter number",
      field: isLinked ? "link-field" : "",
      ...(isLinked && {
        value: projectInfo.get("project_number"),
        width: "424px",
      }),
    },
    {
      label: accountConfig?.getIn(["gst_rate", "title"]),
      defaultValue: projectInfo.get("gst"),
      name: "gst",
      placeholder: `${accountConfig?.getIn(["gst_rate", "value"])}%`,
      field: "number",
      className: "input-field number",
      decimalScale: 0,
      onValueChange: (e) => handleChange(e.floatValue, "gst"),
      isAllowed: (values) => {
        const { formattedValue, floatValue } = values;
        return formattedValue === "" || floatValue <= 100;
      },
    },
    {
      label: "Vault Asset URL",
      defaultValue: projectInfo.get("vault_url"),
      name: "vault_url",
      placeholder: "Type or paste URL",
      field: "textarea",
    },
  ];
  const handleChange = (e, field) => {
    let name = field || e.target.name,
      value = field ? e : e.target.value;
    setInfo({
      ...info,
      [name]: value,
    });
  };
  return (
    <>
      {/* display project additional description */}
      <ProjectInfoWrapper>
        <div className="title">Additional Details</div>
        <Button title="Edit" onClick={() => setOpen(true)} />
        {ProjectInfoDisplay.map((ele) => (
          <ProjectItem item={ele} key={ele.name} />
        ))}
      </ProjectInfoWrapper>
      {/* edit project additional description modal */}
      <Modal
        title="Additional Details"
        open={open}
        close={() => setOpen(false)}
        submit={() => {
          updateInfo(info);
          setOpen(false);
        }}
      >
        <>
          {ProjectInfoDisplay.map((ele) => {
            return (
              <div key={ele.name} className={"has_margin"}>
                <Input onChange={handleChange} {...ele} />
              </div>
            );
          })}
        </>
      </Modal>
    </>
  );
};

export const AdditionalDescription = connect(
  mapStateToProps,
  null,
)(AdditionalDescriptionComponent);
