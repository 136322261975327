import React, { Fragment, useEffect, useState, useRef } from "react";
import Flags from "country-flag-icons/react/3x2";
import Link from "../../linkWithQuery";
import Badge from "@material-ui/core/Badge";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  UserLoginWrapper,
  UserDropdownList,
  UserIcon,
  UserInfoWrapper,
  UserInfoTop,
  UserDropdownItems,
  NoticeBell,
  DropdownItem,
  // NoticeBoard -- pl-568
} from "../style";
// import { TutorialNotice } from "./tutorialNoticeBoard";  -- pl-568
import Support from "./supportHub";
import Tooltip from "../../toolTip/index";

const renderFlag = (countryCode) => {
  const Flag = Flags[countryCode];
  return Flag ? <Flag className="flag" /> : null;
};

export function compareString(a, b) {
  var result =
    a.toString().toLowerCase().trim() > b.toString().toLowerCase().trim()
      ? 1
      : b.toString().toLowerCase().trim() > a.toString().toLowerCase().trim()
        ? -1
        : 0;
  return result;
}
export function UserInfoPart(props) {
  const {
    noticeNum,
    handleUserDropdown,
    openUserList,
    accountList,
    handleClickAway,
    accountIndex,
    changeAccount,
    profile,
    canAccessAccount,
    accountConfig,
    /*  -- pl-568
    setupProcess,
    setupExpand,
    free_trial,
    handleNotice,
    accountId, */
  } = props;
  const [overflow, setOverflow] = useState(false);
  const name = useRef(null);

  useEffect(() => {
    const node = name.current;
    const clientWidth = node.clientWidth;
    const scrollWidth = node.scrollWidth;
    if (clientWidth < scrollWidth) {
      setOverflow(true);
    } //check whether account name overflow the container
  }, []);

  /*  -- pl-568
  let processStatus = {}, processComSteps = 0;
  if (setupProcess.get("progress")) {
    processStatus = setupProcess.get("progress").toJS();
    processComSteps = setupProcess.get("completedSteps");
  }; */
  let accounts = accountList.sortBy((val) =>
    val.get("account_name").toString().toLowerCase(),
  );
  const checkHasSameCountry = (accounts) =>
    accounts.every((item) => item.country === accounts[0].country);
  let hasSameCountryAccounts = checkHasSameCountry(accounts.toJS());
  return (
    <div>
      <UserLoginWrapper>
        <Support {...props} />

        {/* {role !== "user" && processComSteps !== 3 && (
          <Badge
            badgeContent={3 - processComSteps}
            color="error"
            overlap="rectangular"
            className="noticeBadge"
          >
            <NoticeBoard onClick={() => handleNotice("")} />
            {setupExpand && (
              <TutorialNotice
                processStatus={processStatus}
                processComSteps={processComSteps}
                days={free_trial.get("days")}
                handleNotice={handleNotice}
                accountId={accountId}
              />
            )}
          </Badge>
        )}  -- pl-568 */}
        <Badge
          badgeContent={noticeNum}
          color="error"
          overlap="rectangular"
          className="noticeBadge dashboard_step2"
        >
          <Link to="/notice">
            <NoticeBell />
          </Link>
        </Badge>

        <ClickAwayListener onClickAway={handleClickAway}>
          <UserDropdownList>
            <div
              onClick={handleUserDropdown}
              className={openUserList ? "iconfont iconfontOpen" : "iconfont"}
            />
            <UserDropdownItems
              className="account-dropdown"
              visible={openUserList ? "visible" : "hidden"}
            >
              <DropdownItem>
                <div className="icon profile" />
                <div className="title" onClick={handleUserDropdown}>
                  <Link
                    to="/manage-account/profile"
                    style={{ textDecoration: "none" }}
                  >
                    Profile
                  </Link>
                </div>
              </DropdownItem>

              {canAccessAccount && (
                <DropdownItem>
                  <div className="icon manage-account" />
                  <div className="text">
                    <div className="title" onClick={handleUserDropdown}>
                      <Link
                        to="/manage-account/view"
                        style={{ textDecoration: "none" }}
                      >
                        Account
                      </Link>
                    </div>
                    <div className="subtext">{profile.get("accountName")}</div>
                  </div>
                </DropdownItem>
              )}
              <div className="line" />

              {accounts.size > 1 ? (
                <Fragment>
                  <div className="switchAcc-title">Switch Accounts</div>
                  {accounts.map((item) => {
                    return (
                      <DropdownItem
                        key={item.get("account_id")}
                        className={
                          item.get("account_id") === accountIndex
                            ? "selected"
                            : ""
                        }
                        onClick={() => changeAccount(item.get("account_id"))}
                      >
                        <div className="icon flag">
                          {!hasSameCountryAccounts && (
                            <div className="flag">
                              {renderFlag(item.get("country"))}
                            </div>
                          )}
                        </div>
                        <div className="text">
                          <div className="title">
                            {item.get("account_name")}
                          </div>
                          <div className="subtext">
                            {item.getIn(["currency", "code"])}
                          </div>
                        </div>
                      </DropdownItem>
                    );
                  })}
                  <div className="line" />
                </Fragment>
              ) : null}
              <DropdownItem
                className="log-out"
                onClick={() => {
                  handleUserDropdown();
                  localStorage.clear();
                  window.location = "/auth/logout";
                }}
              >
                <div className="icon log-out" />
                <div className="text">
                  <span className="title">Log Out</span>
                </div>
              </DropdownItem>
            </UserDropdownItems>
          </UserDropdownList>
        </ClickAwayListener>
        <UserIcon>
          <div onClick={handleUserDropdown} className="iconfont" />
        </UserIcon>
        <UserInfoWrapper>
          <UserInfoTop>{profile.get("userName")}</UserInfoTop>
          <Tooltip title={profile.get("accountName")} isHide={!overflow}>
            <UserInfoTop className="accountInfo" ref={name}>
              {!hasSameCountryAccounts && (
                <span className="flagContainer">
                  {renderFlag(accountConfig?.getIn(["country", "code"]))}
                </span>
              )}
              {profile.get("accountName")} (
              {accountConfig?.getIn(["currency", "code"])})
            </UserInfoTop>
          </Tooltip>
        </UserInfoWrapper>
      </UserLoginWrapper>
    </div>
  );
}
