import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Container, SectionWrapper } from "../basecontract/styled";

import { BlueOutlinedBtn as ButtonBlue } from "../../common/button";
import Table from "../../common/lineitem/materialTable";
import UploadDialog from "../../common/lineitem/csvUploadDialog";
import Menu from "../../common/breadcrumbs";
import Link from "../../common/linkWithQuery";

import { actionCreators } from "./store";
import { actionCreators as actionCreatorsContract } from "../contract/store";
import { actionCreators as actionCreatorsFile } from "../../common/upload_file_progress/store";

class Materials extends PureComponent {
  constructor() {
    super();
    this.state = {
      sortState: {
        sortField: "created_date",
        sortSep: "asc",
      },
    };
  }

  setSortState = (sortState) => {
    this.setState({ sortState });
  };

  render() {
    const {
      handleEditItem,
      materialstable,
      setEditFiles,
      handleDeleteItem,
      files,
      addNewRow,
      fileDelete,
      uploadFiles,
      contractInfo,
      projectInfo,
      readContract,
    } = this.props;
    const { proId, type, contractId } = this.props.match.params;
    const previousURL = `/contract/${proId}/${type}/${contractId}`;

    const link = [
      { link: "/projects", text: "Projects" },
      { link: `/project/view/${proId}`, text: projectInfo.get("name") },
      { link: previousURL, text: contractInfo.get("name") },
    ];
    const headers = "Material On/Off Site";

    let materialOnSiteItems = [];
    let materialOffSiteItems = [];

    if (materialstable.toJS()) {
      materialOnSiteItems = materialstable.filter((obj) => obj?.get("on_site"));
      materialOffSiteItems = materialstable.filter(
        (obj) => !obj?.get("on_site"),
      );
    }

    let showOnSite =
      (materialOnSiteItems.size === 0 && materialOffSiteItems.size === 0) ||
      materialOnSiteItems.size > 0;
    let showOffSite = materialOffSiteItems.size > 0;

    return (
      <Container>
        <div className="page-breadcrumbs">
          <Menu
            link={link}
            content={headers}
            className="page-breadcrumbs-label"
          />
          <Link to={previousURL}>
            <ButtonBlue title="Back To Contract" />
          </Link>
        </div>
        <h1>{headers}</h1>
        <p>Set up {headers} Items.</p>

        <SectionWrapper>
          {/* On Site Table */}
          {showOnSite && (
            <Table
              tableContent={materialOnSiteItems.toJS()}
              tableSubtitle="On Site"
              isTableSubtitleBold={true}
              tableCaption={headers}
              tableCaptionSubtitle="Add on site or off site material items."
              showAddButton={!showOffSite}
              hideTableToolbar={!showOnSite}
              deleteItem={(id) => handleDeleteItem(proId, contractId, id)}
              addRow={(lineType, newRow) =>
                addNewRow(
                  proId,
                  contractId,
                  newRow,
                  files,
                  materialstable,
                  lineType,
                )
              }
              handleSaveEdit={(list, id) =>
                handleEditItem(proId, contractId, id, list)
              }
              setEditFiles={(file) => setEditFiles(file)}
              handleUploadFile={(id, file) =>
                uploadFiles(proId, contractId, id, file)
              }
              handleFileDelete={(lineid, fileid) =>
                fileDelete(proId, contractId, lineid, fileid)
              }
              proId={proId}
              contractId={contractId}
              setSortState={this.setSortState}
              {...this.props}
            >
              <UploadDialog
                type="materials"
                proId={proId}
                contractId={contractId}
                resetInfo={() => readContract(proId, contractId)}
              />
            </Table>
          )}

          {/* Off Site Table */}
          {showOffSite && (
            <Table
              tableContent={materialOffSiteItems.toJS()}
              tableSubtitle="Off Site"
              isTableSubtitleBold={true}
              tableCaption={!showOnSite ? headers : false}
              tableCaptionSubtitle={
                !showOnSite ? "Add on site or off site material items." : ""
              }
              showAddButton={true}
              hideTableToolbar={showOnSite}
              deleteItem={(id) => handleDeleteItem(proId, contractId, id)}
              addRow={(lineType, newRow) =>
                addNewRow(
                  proId,
                  contractId,
                  newRow,
                  files,
                  materialstable,
                  lineType,
                )
              }
              handleSaveEdit={(list, id) =>
                handleEditItem(proId, contractId, id, list)
              }
              setEditFiles={(file) => setEditFiles(file)}
              handleUploadFile={(id, file) =>
                uploadFiles(proId, contractId, id, file)
              }
              handleFileDelete={(lineid, fileid) =>
                fileDelete(proId, contractId, lineid, fileid)
              }
              proId={proId}
              contractId={contractId}
              sortState={this.state.sortState}
              {...this.props}
            >
              <UploadDialog
                type="materials"
                proId={proId}
                contractId={contractId}
                resetInfo={() => readContract(proId, contractId)}
              />
            </Table>
          )}
        </SectionWrapper>
      </Container>
    );
  }
  componentDidMount() {
    const { proId, contractId } = this.props.match.params;
    this.props.readContract(proId, contractId);
  }
  componentWillUnmount() {
    this.props.resetStatus();
  }
}

const mapStateToProps = (state) => ({
  materialstable: state.getIn(["variation", "variationstable"]),
  files: state.getIn(["variation", "uploadFiles"]),
  editFileList: state.getIn(["variation", "editFileList"]),
  loading: state.getIn(["variation", "loading"]),
  projectInfo: state.getIn(["contract", "projectInfo"]),
  contractInfo: state.getIn(["variation", "contractInfo"]),
  currency: state.getIn(["config", "accountConfig", "currency", "title"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});
const mapDispatchToProps = (dispatch) => {
  return {
    resetStatus() {
      dispatch(actionCreators.reset());
      dispatch(actionCreatorsFile.clearFiles());
    },
    readContract(proId, contractId) {
      dispatch(actionCreatorsContract.readProject(proId));
      dispatch(actionCreators.getInitialMaterialsItems(proId, contractId));
    },
    handleDeleteItem(proId, contractId, itemId) {
      dispatch(actionCreators.deleteLineItem(proId, contractId, itemId));
    },
    handleEditItem(proId, contractId, id, value) {
      dispatch(actionCreators.updateLineItem(proId, contractId, id, value));
    },
    uploadFiles(proId, contractId, id, files) {
      dispatch(actionCreators.uploadFileToDB(proId, contractId, id, files));
    },
    fileDelete(proId, contractId, lineId, fileId) {
      dispatch(actionCreators.deleteFile(proId, contractId, lineId, fileId));
    },
    addNewRow(proId, contractId, newRow, files, table, lineType) {
      dispatch(
        actionCreators.createNewRow(
          proId,
          contractId,
          newRow,
          files,
          table,
          lineType,
        ),
      );
    },
    handleFilesChange(files) {
      dispatch(actionCreators.changeFilesList(files));
    },
    handleFileCancel(name) {
      dispatch(actionCreators.cancelOneFile(name));
    },
    setEditFiles(fileList) {
      dispatch(actionCreators.initialFileList(fileList));
    },
    downloadFile(proId, contractId, fileId, fileName, isView) {
      dispatch(
        actionCreatorsContract.downloadItemFile(
          proId,
          contractId,
          fileId,
          fileName,
          isView,
        ),
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Materials);
