import styled from "styled-components";
import * as theme from "../../../../../theme";
import leftIcon from "../../../../../statics/img/icon-chevron-left-medium-grey.svg";
import rightIcon from "../../../../../statics/img/icon-chevron-right-medium-grey.svg";

export const BottomWrapper = styled.div`
  width: 100%;
  margin: 56px 0;
  height: 40px;
`;

export const PlanWrapper = styled.div`
  height: auto;
  margin: 30px 149px 0 148px;
  width: 100%;
  color: ${theme.palette.gunmetal};
`;

export const PlanInfo = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 46px -50px 0 -50px;
  .center {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
  .left-button {
    width: 50px;
    height: 50px;
    background: url(${leftIcon}) no-repeat;
    background-size: contain;
    cursor: pointer;
    border: none;
    &.right {
      background: url(${rightIcon}) no-repeat;
      background-size: contain;
    }
    &:disabled {
      background: transparent;
      cursor: default;
    }
  }
`;

export const BillInfo = styled.div`
  margin: 10px 16px;
  height: 402px;
  min-width: 352px;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  font-weight: ${theme.font.mainFontWeight};
  display: inline-block;
  &.toChoose {
    &:hover {
      border: 1px solid ${theme.palette.tangerineOrange};
      .top {
        background-color: ${theme.palette.tangerineOrange};
        .arrow {
          border-color: ${theme.palette.tangerineOrange} transparent transparent;
        }
      }
    }
  }

  border: ${(props) =>
    props.selected
      ? `1px solid ${theme.palette.tangerineOrange}`
      : `1px solid ${theme.palette.wolfGrey}`};
  .top {
    position: relative;
    padding: 24px 0;
    text-align: center;
    background-color: ${(props) =>
      props.selected
        ? `${theme.palette.tangerineOrange}`
        : ` ${theme.palette.wolfGrey}`};
    color: ${theme.palette.white};
    .arrow {
      position: absolute;
      width: 0;
      height: 0;
      top: 95%;
      left: 43%;
      border-width: 20px;
      border-style: solid;
      border-color: ${(props) =>
        props.selected
          ? ` ${theme.palette.tangerineOrange} transparent transparent`
          : `${theme.palette.wolfGrey} transparent transparent`};
    }
  }
  .title {
    font-size: 24px;
  }
  .price {
    margin-top: 10px;
    flex-direction: column;
    display: flex;
    .priceA {
      font-size: 46px;
    }
    .month {
      margin: 10px 0;
      font-size: 14px;
    }
    .billed {
      font-size: 14px;
      color: ${theme.palette.gunmetal};
    }
  }
  .bottom {
    height: 224px;
    flex-direction: column;
    display: flex;
    padding: 40px 0;
    align-items: center;
    justify-content: flex-start;
  }
  .projectLabel {
    font-size: 14px;
    min-height: 14px;
    font-weight: ${theme.font.normalFontWeight};
    margin: 0 auto 30px auto;
    max-width: 240px;
    overflow-wrap: anywhere;
    white-space: normal;
  }
  .hoverButton {
    &:hover {
      background-color: ${theme.palette.gunmetal};
      color: ${theme.palette.white};
    }
    &:disabled {
      background-color: ${theme.palette.white};
      border: 1px solid ${theme.palette.wolfGrey};
      color: ${theme.palette.wolfGrey};
    }
  }
`;
