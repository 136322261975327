import React, { useEffect } from "react";
import { connect } from "react-redux";

const MapBoxDisplay = (props) => {
  const { accountConfig, children, setAddress, addSpacing } = props;

  const enable_mapbox = accountConfig?.getIn(["enable_mapbox"]);

  useEffect(() => {
    if (accountConfig?.getIn(["enable_mapbox"]) === false) {
      setAddress({ country: accountConfig.getIn(["country", "code"]) }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountConfig]);

  return enable_mapbox
    ? children
    : addSpacing && <div className="with-spacing"></div>;
};

MapBoxDisplay.defaultProps = {
  setAddress: () => {}, // Default empty function
  addSpacing: false, // Default is false so no spacing by default
};

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default connect(mapStateToProps, null)(MapBoxDisplay);
