import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { BgWrapper, HeaderTitle } from "../../common/shared_style";
import { actionCreators } from "./store";
import { actionCreators as actionCreatorHeader } from "../../common/header/store";
import ClaimProjectsList from "./components/table";
import CertSummary from "./components/cert_summary";
import Loading from "../../common/loading";
import { format } from "date-fns";
// import Tour from "../../common/tour"; -- pl-568
import FilterBar from "../../common/select_multi_accounts";
import SwitchGst from "../../common/switch/switch_include_gst";
class Projects extends PureComponent {
  applyFilter = () => {
    this.props.getAllProjects(this.props.projectType);
    this.props.getCertSummary(
      format(new Date(this.props.selDate.toString()), "yyyy-MM-dd"),
    );
  };

  render() {
    return (
      <>
        {/* {this.props.firstLogin && <Tour field="CLAIMS_RECEIVED" showSetupList={this.props.showSetupList}/>} -- pl-568*/}
        <BgWrapper className="big" style={{ position: "relative" }}>
          <FilterBar applyFilter={this.applyFilter} />
          <HeaderTitle>
            Contracts <SwitchGst apply={this.applyFilter} />
          </HeaderTitle>
          <CertSummary {...this.props} />
          {!this.props.loading ? (
            <ClaimProjectsList {...this.props} />
          ) : (
            <Loading message="Loading All Contracts ..." />
          )}
        </BgWrapper>
      </>
    );
  }
  componentDidMount() {
    this.props.getAllProjects("current");
    let result = format(new Date(), "yyyy-MM-dd");
    this.props.getCertSummary(result);
  }
  componentWillUnmount() {
    this.props.resetPageStatus();
  }
}

const mapStateToProps = (state) => ({
  accountId: state.getIn(["headers", "profile", "payclaim_account_id"]),
  tableContent: state.getIn(["claimsReceived", "table"]),
  firstLogin: state.getIn(["headers", "firstLogin"]),
  projectType: state.getIn(["claimsReceived", "projectType"]),
  loading: state.getIn(["claimsReceived", "loading"]),
  selDate: state.getIn(["claimsReceived", "selDate"]),
  certSummary: state.getIn(["claimsReceived", "certSummary"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});

const mapDispatch = (dispatch) => {
  return {
    getAllProjects(type) {
      dispatch(actionCreators.getAllProjectList(type));
    },
    handleChangeDate(event) {
      if (!event) {
      } else {
        if (event.toString() !== "Invalid Date") {
          let result = format(new Date(event.toString()), "yyyy-MM-dd");
          dispatch(actionCreators.setDate(event.toString()));
          dispatch(actionCreators.getSummary(result));
        }
      }
    },
    getCertSummary(result) {
      dispatch(actionCreators.getSummary(result));
    },
    resetPageStatus() {
      dispatch(actionCreators.resetPageStatus());
    },
    selProjectType(e) {
      dispatch(actionCreators.chooseProjectType(e.value));
    },
    showSetupList(triggerClose) {
      dispatch(actionCreatorHeader.getSetupProcess(triggerClose));
    },
  };
};

export default connect(mapStateToProps, mapDispatch)(Projects);
