import React from "react";

import { ModalContent } from "../styled";

import {
  GreenWhiteBtn as Button,
  BlueOutlinedBtn as CancelButton,
} from "../../../common/button";
import Modal from "../../../common/modal/component/modalBase";

export default (props) => {
  return (
    <Modal
      open={props.open}
      title={props.title}
      divider={false}
      width={"960px"}
      handleClose={props.close}
    >
      <ModalContent className={props.className}>{props.children}</ModalContent>
      {!props.hideActions && (
        <div>
          <Button
            title="save"
            onClick={props.submit}
            disabled={props.disabled}
          />
          <CancelButton
            title="cancel"
            margin={"0 16px 0 0"}
            onClick={props.close}
          />
        </div>
      )}
    </Modal>
  );
};
