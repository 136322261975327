import React, { PureComponent } from "react";
import { fromJS } from "immutable";
import {
  InfoSection,
  SummaryContainer,
  ListItem,
  TotalSection,
} from "../styled";
import Filter from "./filterTable";
import history from "../../../utils/history";
import { currencySubunitToUnit } from "../../../utils/currencyHelper";
import { NoticeBell } from "../../claimsReceived/components/noticeBell";
import { convertUTCToTime } from "../../../utils/dateTimeHelper";
import CertOptionModal from "../../contract/components/certOptionalModal";
import API from "../../../server";

import { checkIfHavePermission as makeACertCheckIfHavePermission } from "../../contract/view/components/claimHistoryTable";

function compareByOption(field, isSortAsc) {
  //sort function based on the table columns
  let name = field;
  let reverse = isSortAsc ? 1 : -1;
  if (name === "due_date" || name === "value") {
    return function (a, b) {
      var result = a > b ? 1 : b > a ? -1 : 0;
      return result * reverse;
    };
  } else
    return function (a, b) {
      var result =
        a.toString().toLowerCase().trim() > b.toString().toLowerCase().trim()
          ? 1
          : b.toString().toLowerCase().trim() >
              a.toString().toLowerCase().trim()
            ? -1
            : 0;
      return result * reverse;
    };
}

function toUpperCase(string) {
  return string.replace(/^\S/, (s) => s.toUpperCase());
}
export default class index extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      sortField: "due_date",
      isSortAsc: true,
      filterOptions: JSON.parse(
        localStorage.getItem("dashboard_filter_options"),
      ) || {
        claim: false,
        cert: false,
        showOptional: false,
      },
      openCertModal: false,
      editCert: fromJS({ claimId: "", certId: "", certName: "" }),
    };
  }

  getProjectId = (item) => {
    if (item.get("type") === "claim") {
      return item.get("fk_claim_contract_group") === null
        ? item.get("fk_cert_contract_group")
        : item.get("fk_claim_contract_group");
    }
    return item.get("fk_cert_contract_group") === null
      ? item.get("fk_claim_contract_group")
      : item.get("fk_cert_contract_group");
  };

  handleDraftCert = (isNew) => {
    const item = this.state.editCert;
    const { proId, contractId, claimId, certId, contractType } = {
      proId: this.getProjectId(item),
      contractId: item?.get("contract_id"),
      claimId: item?.get("claim_id"),
      certId: item?.get("cert_uuid"),
      contractType: item?.get("contract_view_type"),
    };

    this.props.handlePendingCert(
      proId,
      contractId,
      claimId,
      certId,
      contractType,
      isNew,
    );
    this.setState({ openCertModal: false });
  };

  redirect = (item, userProfile) => {
    const search = `?account_id=${item.get("account_id")}`;

    let type = item.get("type");
    if (type === "claim") {
      if (item.get("contract_view_type") !== "") {
        type = item.get("contract_view_type");
      }
      item.get("action") === "approve" &&
        history.push({
          pathname: `/project/${this.getProjectId(item)}/contract/${item.get(
            "contract_id",
          )}/${type}/claim/${item.get("claim_id")}`,
          search,
        });
      item.get("action") === "issue" &&
        history.push({
          pathname: `/project/${this.getProjectId(item)}/contract/${item.get(
            "contract_id",
          )}/${type}/claim/${item.get("claim_id")}/review`,
          search,
        });
    }
    if (item.get("type") === "cert") {
      if (item.get("contract_view_type") !== "") {
        type = item.get("contract_view_type");
      }

      const { proId, contractId, claimId, certId } = {
        proId: this.getProjectId(item),
        contractId: item?.get("contract_id"),
        claimId: item?.get("claim_id"),
        certId: item?.get("cert_uuid"),
      };

      const draftCertPath = `/project/${proId}/contract/${contractId}/${type}/claim/${claimId}/cert`;
      const approvedCertPath = `${draftCertPath}/review`;

      const handleCreateAction = () => {
        if (item.get("cert_status") === "pending") {
          return this.setState({
            openCertModal: true,
            editCert: item,
          });
        } else {
          history.push({ pathname: draftCertPath, search });
        }
      };

      const handleApproveAction = () => {
        if (certId && userProfile) {
          API.read_cert_workflow(proId, contractId, claimId, certId).then(
            (res) => {
              const data = res.data.data.data;

              if (data && Object.keys(data).length > 0) {
                const approverPermission = makeACertCheckIfHavePermission(
                  data,
                  userProfile,
                );

                history.push({
                  pathname: approverPermission
                    ? draftCertPath
                    : approvedCertPath,
                  search,
                });
              }
            },
          );
        }
      };

      const handleIssueAction = () => {
        history.push({ pathname: approvedCertPath, search });
      };

      switch (item.get("action")) {
        case "create":
          handleCreateAction();
          break;
        case "approve":
          handleApproveAction();
          break;
        default:
          handleIssueAction();
          break;
      }
    }
  };

  handleFilter = (name, value) => {
    let newState = { ...this.state.filterOptions, [name]: value };
    localStorage.setItem("dashboard_filter_options", JSON.stringify(newState));
    return this.setState({ filterOptions: newState });
  };

  handleSort = (name, value) => {
    return this.setState({
      sortField: name,
      isSortAsc: value,
    });
  };

  render() {
    const { taskList, accountConfig, userProfile } = this.props;
    const { show, sortField, isSortAsc, filterOptions } = this.state;
    const listHeader = [
      { label: "Task Name", sortField: "contract_name" },
      { label: "Date Due", sortField: "due_date" },
      { label: "Type", sortField: "type" },
      { label: "Value", sortField: "value" },
      { label: "Action" },
    ];

    let table = taskList.sortBy(
      (val) => {
        return val.get(sortField);
      },
      compareByOption(sortField, isSortAsc),
    );

    if (Object.values(filterOptions).some((item) => item === true)) {
      table = table.filter(
        (item) =>
          (filterOptions["showOptional"] ||
            (!filterOptions["showOptional"] && !item.get("is_optional"))) &&
          (item.get("type") === "claim"
            ? filterOptions["claim"]
            : filterOptions["cert"]),
      );
    }

    const ListData = ({ item, index }) => {
      let task_name,
        date,
        action,
        task_type = "",
        value = 0;
      if (item.get("type") === "claim") {
        task_name = (
          <span>
            My {accountConfig?.getIn(["claim", "noun"])} to{" "}
            {item.get("payer_company_name")} for{" "}
            <b>{item.get("contract_name")}</b>
          </span>
        );
      } else if (item.get("type") === "cert") {
        task_name = (
          <span>
            Certify {item.get("payee_company_name")} for{" "}
            <b>{item.get("contract_name")}</b>
          </span>
        );
        task_type = "certificate";
      }
      date = convertUTCToTime(
        item.get("due_date"),
        item.get("contract_timezone"),
        false,
        "dd MMM, yyyy",
      )[0];
      task_type = toUpperCase(task_type || item.get("type"));
      action = toUpperCase(item.get("action"));
      value = currencySubunitToUnit(item.get("value"));
      return (
        <ListItem key={index}>
          <div className="task_name_wrap">
            {item.get("is_optional") && (
              <div className="optional_label">Optional</div>
            )}
            <span>{task_name}</span>
          </div>
          <div className="label_date">
            <NoticeBell
              due={item.get("due_term")}
              dueDays={item.get("due_days")}
              field={toUpperCase(item.get("type"))}
              accountConfig={accountConfig}
            />
            {date}
          </div>
          <div>
            <div className={"button " + item.get("type")}>
              {task_type === "Claim"
                ? accountConfig?.getIn(["claim", "noun"])
                : task_type}
            </div>
          </div>
          <div className="label_date"> {value} </div>
          <div>
            <button
              className="button view"
              onClick={() => this.redirect(item, userProfile)}
            >
              {action}
            </button>
          </div>
        </ListItem>
      );
    };

    return (
      <SummaryContainer className="task">
        <div className="sumType">
          <span> Your Tasks</span>
          {taskList.size !== 0 && (
            <Filter
              handleFilter={this.handleFilter}
              hasFilter={Object.values(filterOptions).some(
                (item) => item === true,
              )}
              filterOptions={filterOptions}
              accountConfig={accountConfig}
            />
          )}
        </div>
        <InfoSection>
          {taskList.size === 0 ? (
            <div className="emptyNotice">
              You currently have no tasks assigned to you
            </div>
          ) : (
            <>
              {table.size === 0 && (
                <div className="emptyNotice">
                  You have no tasks with the current filters
                </div>
              )}
              {table.size !== 0 && (
                <TotalSection>
                  <ListItem>
                    {listHeader.map((item) => (
                      <div
                        className={
                          "list_header-label " +
                          (item.sortField === "due_date" ? "hasMargin" : "")
                        }
                        onClick={() =>
                          item.sortField &&
                          this.handleSort(item.sortField, !isSortAsc)
                        }
                        key={item.sortField || "label"}
                      >
                        {item.label}
                        {item.sortField && (
                          <>
                            <span
                              className={
                                "sort_icon sort_up " +
                                (item.sortField === sortField && isSortAsc
                                  ? "active"
                                  : "")
                              }
                              onClick={() =>
                                this.handleSort(item.sortField, true)
                              }
                            />
                            <span
                              className={
                                "sort_icon sort_down " +
                                (item.sortField === sortField && !isSortAsc
                                  ? "active"
                                  : "")
                              }
                              onClick={() =>
                                this.handleSort(item.sortField, false)
                              }
                            />
                          </>
                        )}
                      </div>
                    ))}
                  </ListItem>

                  {table.map((item, index) => {
                    if (show || index < 3) {
                      return <ListData item={item} key={index} />;
                    } else {
                      return null;
                    }
                  })}

                  {table.size > 3 && (
                    <ListItem className="last_one-without-line">
                      <div
                        className="show_hide_controller"
                        onClick={() => this.setState({ show: !show })}
                      >
                        <div className={show ? "img" : "img down"} />
                        <div className="label">
                          {!show
                            ? `Show all ${table.size} tasks`
                            : "Show fewer tasks"}
                        </div>
                      </div>
                    </ListItem>
                  )}
                </TotalSection>
              )}
            </>
          )}
        </InfoSection>
        <CertOptionModal
          open={this.state.openCertModal}
          closeDialog={() => this.setState({ openCertModal: false })}
          chooseOption={this.handleDraftCert}
          certName={this.state.editCert?.get("cert_name") || ""}
        />
      </SummaryContainer>
    );
  }
}
