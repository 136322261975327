import payment from "./api/payment";
import config from "./api/config";
import payCert from "./api/paycert";
import payClaim from "./api/payclaim";
import project from "./api/project";
import contract from "./api/contract";
import account from "./api/account";
import lineitem from "./api/lineitem";
import company from "./api/company";
import mapbox from "./api/mapbox";
import dashboard from "./api/dashboard";
import ipm from "./api/integration";

export default {
  ...payment,
  ...config,
  ...payCert,
  ...payClaim,
  ...project,
  ...contract,
  ...account,
  ...lineitem,
  ...company,
  ...mapbox,
  ...dashboard,
  ...ipm,
};
