import React, { useState } from "react";
import { connect } from "react-redux";
import { MoveProject } from "../../project/styled";
import Modal from "../../project/components/modal";
import { BlueOutlinedBtn, RedOutlinedBtn } from "../../../common/button";
import Search from "common/dropdown/select";
import { actionCreators } from "../store";
const Move = (props) => {
  const {
    allProjects,
    projectId,
    contractId,
    moveContract,
    isMain,
    deleteContract,
    selfContract,
    contractLinked,
  } = props;
  const [open, setOpen] = useState(false);
  const [project, setProject] = useState({});
  // filter projects list based on what kind of contract it is
  let moveList = isMain
    ? allProjects.filter(
        (project) => project.get("claim_contracts").get("contract_number") < 1,
      )
    : allProjects.filter((project) => project.get("id") !== projectId);

  return (
    <MoveProject style={{ textAlign: "center" }}>
      <div
        className={
          `has_margin` + (selfContract ? (!contractLinked ? ` grid` : ``) : ``)
        }
      >
        {!contractLinked && (
          <BlueOutlinedBtn
            title="Move to Different Project"
            onClick={() => setOpen(true)}
          />
        )}
        {selfContract && (
          <RedOutlinedBtn
            className="solid"
            title="Delete Contract"
            onClick={deleteContract}
          />
        )}
      </div>
      <div>&nbsp;</div>
      <Modal
        title="Move Project"
        open={open}
        close={() => setOpen(false)}
        submit={() => {
          moveContract(contractId, project.value, projectId);
          setOpen(false);
        }}
      >
        <>
          <p className="has_margin">
            This will move the contract from this project to another project.
          </p>
          <p className="has_margin">
            Select which project you want this contract to be a part of:
          </p>
          <Search
            name="selectedProject"
            onChange={(e) => setProject(e)}
            options={moveList.map((item) => {
              return { value: item.get("id"), label: item.get("name") };
            })}
            placeholder="Search for a project"
          />
        </>
      </Modal>
    </MoveProject>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveContract(contractId, projectId, currentProjectId) {
      dispatch(
        actionCreators.moveContract(contractId, projectId, currentProjectId),
      );
    },
  };
};
export default connect(null, mapDispatchToProps)(Move);
