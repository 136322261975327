import styled from "styled-components";
import * as theme from "../../theme";
import whiteIcon from "../../statics/img/icon-dropdown-white.svg";
import whiteSortIcon from "../../statics/img/icon-dropdown-up-white.svg";
import notificationIcon from "../../statics/img/icon-notification-red.svg";
import due7Icon from "../../statics/img/icon-notification-blue.svg";
import due3Icon from "../../statics/img/icon-notification-orange.svg";
import unclaimedIcon from "../../statics/img/icon-notification-dark-grey.svg";
import dateIcon from "../../statics/img/icon-calendar-medium-grey.svg";
import searchIcon from "../../statics/img/icon-search-dark-grey.svg";

export const TableWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 30px 40px 0;
  table {
    height: 100%;
    width: 100%;
    border-spacing: 1px 0;
    border-collapse: separate;
    table-layout: fixed;
    font-size: 16px;
    font-weight: ${theme.font.normalFontWeight};
    color: ${theme.palette.gunmetal};
    tbody {
      tr:nth-child(even) {
        background-color: ${theme.palette.veryColdGrey};
      }
      tr:nth-child(odd) {
        background-color: ${theme.palette.white};
      }
      tr {
        &:hover {
          cursor: pointer;
          background-color: ${theme.palette.lightGrey};
        }
      }
    }
  }
  thead {
    background-color: ${theme.palette.gunmetal};
    height: 44px;
    box-sizing: border-box;
  }
  th {
    text-align: left;
    padding: 0 15px;
    color: ${theme.palette.white};
    font-weight: ${theme.font.mainFontWeight};
    line-height: 24px;
    position: relative;
    box-sizing: border-box;
    height: 44px;
    &.client {
      width: 164px;
    }
    &.project {
      width: 311px;
    }
    &.total_value {
      width: 167px;
    }
    &.value {
      width: 153px;
    }
  }
  td {
    padding: 4px 15px;
    box-sizing: border-box;
    height: 44px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: right;
    &.alignToLeft {
      text-align: left;
    }
    &.empty {
      background-color: white !important;
    }
  }
  tfoot {
    background-color: ${theme.palette.tealBlue} !important;
    color: ${theme.palette.white};
    height: 44px;
    font-weight: ${theme.font.mainFontWeight};
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .imgSort {
    height: 24px;
    width: 24px;
    background: url(${whiteIcon}) no-repeat;
    background-size: contain;
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 5px;
    &.up {
      background: url(${whiteSortIcon}) no-repeat;
      background-size: contain;
      bottom: 15px;
    }
  }
`;

export const SummaryWrapper = styled.div`
  width: 100%;
  margin-bottom: 56px;
  height: 282px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .claim_summary {
    width: 65.5%;
    background-color: ${theme.palette.white};
    border-radius: 4px;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);

    .title_claim {
      display: flex;
      justify-content: space-between;
      font-size: 24px;
      font-weight: ${theme.font.mainFontWeight};
      width: 100%;
      padding: 23px 40px;
      box-sizing: border-box;
      border-bottom: 1px solid ${theme.palette.lightWolfGrey};
      line-height: 38px;
    }
    .title_date {
      height: 38px;
      width: 172px;
      background-color: ${theme.palette.greyWhite};
      border-radius: 4px;
      padding: 6px 10px 3px 16px;
      .img_date {
        float: right;
        background: url(${dateIcon}) no-repeat scroll;
        background-size: 24px;
        height: 24px;
        width: 24px;
        margin-top: 1px;
      }
    }
    .content_claim {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .content_project {
        padding: 62px 10px 62px 40px;
        box-sizing: border-box;
        height: 197px;
        .label {
          font-size: 16px;
          color: ${theme.palette.veryMediumGrey};
          font-weight: ${theme.font.mainFontWeight};
          height: 19px;
          margin-bottom: 20px;
        }
        .money {
          font-size: 28px;
          color: ${theme.palette.gunmetal};
          font-weight: ${theme.font.normalontWeight};
        }
        &.total {
          width: 278px;
          border-right: 1px solid ${theme.palette.lightWolfGrey};
        }
        &.total_claimed {
          width: 250px;
          border-right: 1px solid ${theme.palette.lightWolfGrey};
        }
        &.total_money {
          width: 360px;
        }
      }
    }
  }
  .claim_due {
    width: 31.47%;
    background-color: ${theme.palette.white};
    border-radius: 4px;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
    .title_due {
      font-size: 24px;
      font-weight: ${theme.font.mainFontWeight};
      height: 84px;
      width: 100%;
      padding: 30px 40px;
      box-sizing: border-box;
      border-bottom: 1px solid ${theme.palette.lightWolfGrey};
      .img_due {
        background: url(${notificationIcon}) no-repeat scroll;
        height: 24px;
        width: 24px;
        float: left;
        margin-right: 8px;
        &.unclaimed {
          background: url(${unclaimedIcon}) no-repeat scroll;
        }
        &.img_due_3 {
          background: url(${due3Icon}) no-repeat scroll;
        }
        &.img_due_7 {
          background: url(${due7Icon}) no-repeat scroll;
        }
      }
    }

    .content_due {
      height: 197px;
      width: 100%;
      padding: 30px 40px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .content_list {
        font-size: 16px;
        font-weight: ${theme.font.mainFontWeight};
        height: 39px;
        width: 100%;
        border-radius: 4px;
        padding: 10px 16px 10px 20px;
        box-sizing: border-box;
        .field_claim {
          float: left;
        }
        .money_claim {
          float: right;
        }
        &.overdue {
          background-color: ${theme.palette.lightPastelPink};
          color: ${theme.palette.watermelonRed};
        }
        &.in_due {
          background-color: ${theme.palette.lightPastelBlue};
          color: ${theme.palette.turquoiseBlue};
        }
        &.in_3_due {
          background-color: ${theme.palette.paleYellow};
          color: ${theme.palette.tangerineOrange};
        }
        &.unclaimed {
          background-color: ${theme.palette.veryWarmLightGrey};
          color: ${theme.palette.gunmetal};
        }
      }
    }
  }
`;
export const ProjectItems = styled.div`
  width: 100%;
  height: auto;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  background-color: ${theme.palette.white};
`;
export const ProjectWrapper = styled.div`
  height: 100%;
  display: block;
  width: 100%;
  margin-bottom: 24px;
  .selectFrame {
    float: left;
  }
`;

export const TopWrapper = styled.div`
  height: 104px;
  width: 100%;
  padding: 30px 40px;
  box-sizing: border-box;
  &.draft {
    background-color: ${theme.palette.veryLightBlue};
  }
  &.current {
    background-color: ${theme.palette.lightPastelGreen};
  }
  &.completed {
    background-color: ${theme.palette.lightPastelBlue};
  }
  &.archived {
    background-color: ${theme.palette.warmLightGrey};
  }
`;
export const Empty = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 10px;
  float: left;
`;
export const Overdue = styled(Empty)`
  background: url(${notificationIcon}) no-repeat;
`;
export const Due3 = styled(Empty)`
  background: url(${due3Icon}) no-repeat;
`;
export const Due7 = styled(Empty)`
  background: url(${due7Icon}) no-repeat;
`;
export const UnClaimed = styled(Empty)`
  background: url(${unclaimedIcon}) no-repeat;
`;
export const SearchContainer = styled.div`
  height: 44px;
  width: 320px;
  background-color: ${theme.palette.white};
  box-sizing: border-box;
  float: left;
  border-radius: 4px;
  padding: 10px 10px 10px 16px;
  border: 1px solid ${theme.palette.lightWolfGrey};

  input {
    ::placeholder {
      color: ${theme.palette.wolfGrey};
    }
    outline: none;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-family: ${theme.font.mainFont};
    font-weight: ${theme.font.normalFontWeight};
    float: right;
    height: 100%;
    width: 248px;
    color: ${theme.palette.gunmetal};
  }
  .img-search {
    height: 24px;
    width: 24px;
    background: url(${searchIcon}) no-repeat scroll;
    background-size: 24px;
    float: left;
    cursor: pointer;
  }
`;
