import styled from "styled-components";
import * as theme from "../../theme";
import { IconButton } from "../button";
import filterIcon from "../../statics/img/icon-filter-dark-grey.svg";

export const FilterContainer = styled.div`
  margin-left: 24px;
  float: left;
  height: 44px;
  width: 44px;
  background-color: ${theme.palette.white};
  border: 1px solid ${theme.palette.lightWolfGrey};
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
`;

export const FilterIcon = styled(IconButton)`
  background: url(${filterIcon}) no-repeat;
`;

export const FormStyle = styled.div`
  width: 184px;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  font-size: 14px;
  color: ${theme.palette.gunmetal};
  padding: 14px 20px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-weight: ${theme.font.normalFontWeight};
  justify-content: space-between;
  .title {
    font-weight: ${theme.font.mainFontWeight};
    margin-bottom: 5px;
  }

  .filter-container {
    margin-bottom: 10px;
    &.border {
      border-bottom: 1px solid ${theme.palette.lightWolfGrey};
    }
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
    font-family: ${theme.font.mainFont};
    text-transform: capitalize;
  }

  .reset {
    border-top: 1px solid ${theme.palette.lightWolfGrey};
    padding: 14px 0;

    cursor: pointer;
  }
`;
