import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Table, LineItemBtn, HoverDiv, ToolTip } from "../styled";

import PageBarPart from "../../../../common/pagination";
import {
  currencySubunitToUnit,
  formatLargeCurrency,
} from "../../../../utils/currencyHelper";
import { convertUTCToTime } from "../../../../utils/dateTimeHelper";
import {
  STATUS_APPROVED,
  STATUS_ISSUE,
  STATUS_CERTIFIED,
  STATUS_PENDING,
  CONTRACT_TYPE,
  STATUS_DRAFT,
} from "../../../../utils/constants";
import { MAX_NUMBER_CURRENCY_LIMIT } from "../../../../utils/numberConstants";
import CertOptionModal from "../../components/certOptionalModal";
import API from "../../../../server";

export const checkIfHavePermission = function (list, user) {
  if (list.size === 0) {
    return false;
  }

  const { current_step_index, steps } = list;
  const userEmail = user.get("email");

  if (steps?.length <= 2) {
    //no workflow for the claim/cert, redirect to make claim page
    return true;
  }

  if (current_step_index === 1) {
    //check whether some approvers approved in step 1
    //if no, always return to make a claim/cert (draft) page
    const hasApprovedUser = steps[1]?.approvals?.find(
      (user) => user.approved && user.required,
    );
    if (!hasApprovedUser) {
      return true;
    }
  }
  // for all other steps, check if the current user is in the current step
  const currentStep = steps?.find((step) => step.step === current_step_index);
  const currentUser = currentStep?.approvals?.find(
    (user) => user.user_email === userEmail,
  );
  if (currentUser) {
    return !currentUser.approved;
  } else {
    return false;
  }
};

function ClaimHistoryTable(props) {
  const {
    claimSummary,
    proId,
    contractId,
    accountId,
    contractInfo,
    type,
    readOnly,
    accountInfo,
    accountConfig,
    handlePendingCert,
    userProfile,
  } = props;
  const history = useHistory();

  const [claims, setClaims] = useState([]);
  const [sortDescending, setSortDescending] = useState(true);
  // pagination stuff
  const [limit, setLimit] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [timezone, setTimezone] = useState("");

  const [openCertModal, handleOpenCertModal] = useState(false);
  const [pendingCert, setPendingCert] = useState({
    id: "",
    cert_id: "",
    cert_name: "",
  });

  const setDisplayClaims = (claims) => {
    let displayClaims = [];
    claims.map((claim, i) => {
      if (i >= limit * (currentPage - 1) && i < limit * currentPage) {
        displayClaims.push(claim);
      }
      return claim;
    });
    setClaims(displayClaims);
  };

  useEffect(() => {
    setTimezone(
      contractInfo?.get("time_zone") || accountInfo?.get("time_zone"),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractInfo, accountInfo]);

  useEffect(() => {
    let filteredClaims = claimSummary.toJS();
    if (type === CONTRACT_TYPE.cert) {
      filteredClaims = filteredClaims.filter((claim) => {
        return [STATUS_ISSUE, STATUS_CERTIFIED].includes(claim.claim_status);
      });
    }
    if (sortDescending) {
      setDisplayClaims(filteredClaims);
    } else {
      setDisplayClaims(filteredClaims.reverse());
    }
    setPages(Math.ceil(filteredClaims.length / limit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimSummary, sortDescending, currentPage, limit, type]);

  useEffect(() => {
    if (props.location.state?.claimId) {
      handleOpenCertModal(true);
      const { certName, claimId, certId } = props.location.state;
      setPendingCert({ cert_name: certName, id: claimId, cert_id: certId });
    }
  }, [props.location]);

  const showCertValue = (claim) => {
    if (!claim.cert_status || claim.cert_status === STATUS_PENDING) {
      return false;
    }
    return (
      type === CONTRACT_TYPE.cert ||
      (type === CONTRACT_TYPE.claim &&
        claim.claim_status === STATUS_CERTIFIED) ||
      (type === CONTRACT_TYPE.selfCert &&
        (claim.claim_status === STATUS_ISSUE ||
          claim.claim_status === STATUS_CERTIFIED)) ||
      (type === CONTRACT_TYPE.selfClaim &&
        (claim.claim_status === STATUS_ISSUE ||
          claim.claim_status === STATUS_CERTIFIED))
    );
  };

  const calculateGross = (claim, contractInfo, isCert) => {
    var gross = contractInfo.get("total_contract_value");
    var grossItem = isCert
      ? claim.gross_certified_value
      : claim.gross_claimed_value;

    var grossPercent = 0;
    if (gross !== 0) {
      grossPercent = Math.round((grossItem / gross) * 100);
    }
    return (
      ((isCert && showCertValue(claim)) || !isCert) &&
      gross > 0 && (
        <React.Fragment>
          {grossItem > 0 && formatLargeCurrency(grossItem, true)}{" "}
          {grossPercent > 0 && <>({grossPercent}%)</>}
        </React.Fragment>
      )
    );
  };

  const claimAmount = (claim, previousClaim) => {
    if (!previousClaim)
      return (
        <HoverDiv>
          <div className="value">
            {currencySubunitToUnit(
              claim.claim_net_value,
              !accountConfig?.get("enable_header_currency"),
              false,
              false,
            )}
          </div>
        </HoverDiv>
      );
    let change = claim.claim_net_value - previousClaim.claim_net_value;
    let symbol = change >= 0 ? "+" : "";
    return (
      <HoverDiv>
        {Object.keys(previousClaim).length !== 0 && (
          <ToolTip className="tooltip value">
            <div className="grid">
              {claim.claim_net_value > MAX_NUMBER_CURRENCY_LIMIT && (
                <>
                  <p>Net Amount</p>
                  <p className="align-right">
                    {currencySubunitToUnit(claim.claim_net_value)}
                  </p>
                </>
              )}
              <p>Previous {accountConfig?.getIn(["claim", "noun"])}</p>
              <p className="align-right">
                {currencySubunitToUnit(previousClaim.claim_net_value)}
              </p>
              <p>
                Change from previous{" "}
                {accountConfig?.getIn(["claim", "noun"]).toLowerCase()}
              </p>
              <p className="align-right">
                {symbol}
                {currencySubunitToUnit(change)}
              </p>
            </div>
          </ToolTip>
        )}
        <div className="value">
          {formatLargeCurrency(
            claim.claim_net_value,
            Object.keys(previousClaim).length === 0,
          )}
        </div>
      </HoverDiv>
    );
  };

  const certAmount = (claim) => {
    return showCertValue(claim) ? (
      <HoverDiv>
        <ToolTip className="tooltip value">
          <div className="grid">
            {claim.claim_net_value > MAX_NUMBER_CURRENCY_LIMIT && (
              <>
                <p>Net amount</p>
                <p className="align-right">
                  {currencySubunitToUnit(claim.claim_net_value)}
                </p>
              </>
            )}
            <p>Retentions (Net)</p>
            <p className="align-right">
              {currencySubunitToUnit(claim.cert_retention)}
            </p>
            <p>Variance</p>
            <p className="align-right">
              {currencySubunitToUnit(claim.gross_variance)}
            </p>
          </div>
        </ToolTip>
        <div className="value">{formatLargeCurrency(claim.cert_net_value)}</div>
      </HoverDiv>
    ) : (
      <div />
    ); // so that the action button will remain in the same position when nothing is returned here
  };

  const claimAmountButton = (claim, type, readOnly) => {
    let date = "";
    switch (claim.claim_status) {
      case STATUS_CERTIFIED:
        date = claim?.claim_issued_date
          ? claim?.claim_issued_date
          : claim?.updated_at;
        break;
      default:
        date = claim.updated_at;
    }
    let localDate = convertUTCToTime(date, timezone, false, "d MMM yyyy")[0];

    let claimPopup = "Last Edited";
    if (claim.claim_status === STATUS_CERTIFIED) {
      claimPopup = "Date Certified";
    } else if (claim.claim_status === STATUS_ISSUE) {
      claimPopup = "Date Issued";
    } else if (claim.claim_status === STATUS_APPROVED) {
      claimPopup = "Date Approved";
    }

    const makeClaimPath = `/project/${proId}/contract/${contractId}/${type}/claim/${claim.id}?account_id=${accountId}`,
      reviewClaimPath = `/project/${proId}/contract/${contractId}/${type}/claim/${claim.id}/review?account_id=${accountId}`;

    const handleApproversClick = async () => {
      const data = await API.read_claim_workflow(
        proId,
        contractId,
        claim.id,
      ).then((res) => {
        const data = res.data.data.data;
        return data;
      });

      if (data && Object.keys(data).length > 0) {
        const approver_permission = checkIfHavePermission(data, userProfile);

        if (approver_permission) {
          history.push(makeClaimPath);
        } else {
          history.push(reviewClaimPath);
        }
      }
    };

    return (
      <HoverDiv>
        <ToolTip className="tooltip">
          <p className="bold">
            {claim.claim_name} (
            <span className="status">{claim.claim_status}</span>)
          </p>
          {localDate && (
            <p>
              {claimPopup}:{localDate}
            </p>
          )}
        </ToolTip>

        {claim.claim_status !== STATUS_DRAFT ? (
          <LineItemBtn
            className="button view"
            onClick={() => history.push(reviewClaimPath)}
          >
            View
          </LineItemBtn>
        ) : claim.claim_status === STATUS_DRAFT && !readOnly ? (
          <LineItemBtn className={claim.claim_status}>
            <div className="icon" onClick={handleApproversClick} />
          </LineItemBtn>
        ) : (
          claim.claim_status === STATUS_DRAFT &&
          readOnly && (
            <LineItemBtn
              className="button view"
              onClick={() => history.push(makeClaimPath)}
            >
              Draft
            </LineItemBtn>
          )
        )}
      </HoverDiv>
    );
  };

  const certAmountButton = (claim, type) => {
    const draftCertPath = `/project/${proId}/contract/${contractId}/${type}/claim/${claim.id}/cert?account_id=${accountId}`,
      approvedCertPath = `/project/${proId}/contract/${contractId}/${type}/claim/${claim.id}/cert/review?account_id=${accountId}`;

    let date = claim?.cert_issued_date
      ? claim?.cert_issued_date
      : claim?.cert_updated_date;
    if (date) {
      date = convertUTCToTime(date, timezone, false, "d MMM yyyy")[0];
    }

    const handleApproversClick = async () => {
      const data = await API.read_cert_workflow(
        proId,
        contractId,
        claim.id,
        claim.cert_id,
      ).then((res) => {
        const data = res.data.data.data;
        return data;
      });

      if (data && Object.keys(data).length > 0) {
        const approver_permission = checkIfHavePermission(data, userProfile);

        if (approver_permission) {
          history.push(draftCertPath);
        } else {
          history.push(approvedCertPath);
        }
      }
    };

    //if a claim has been issued but no certs yet
    if (
      type !== CONTRACT_TYPE.claim &&
      !readOnly &&
      claim.claim_status === STATUS_ISSUE &&
      (claim.cert_id === "" || claim.cert_status === STATUS_PENDING)
    ) {
      return (
        <LineItemBtn
          className="button cert"
          onClick={() => {
            if (claim.cert_status === STATUS_PENDING) {
              handleOpenCertModal(true);
              setPendingCert(claim);
            } else {
              history.push(draftCertPath);
            }
          }}
        >
          Cert
        </LineItemBtn>
      );
    }
    return (
      <>
        <HoverDiv>
          <ToolTip className="tooltip">
            <p className="bold">
              {claim.cert_name} (
              <span className="status">{claim.cert_status}</span>)
            </p>
            {date && (
              <p>
                {claim.cert_status === STATUS_DRAFT
                  ? "Last Edited:"
                  : "Date Approved:"}
                {date}
              </p>
            )}
          </ToolTip>

          {claim.cert_status === STATUS_DRAFT &&
          type !== CONTRACT_TYPE.claim &&
          !readOnly ? (
            <LineItemBtn className={claim.cert_status}>
              <div className="icon" onClick={handleApproversClick} />
            </LineItemBtn>
          ) : claim.cert_status === STATUS_DRAFT &&
            type !== CONTRACT_TYPE.claim ? (
            <LineItemBtn
              className="button view"
              onClick={() => history.push(draftCertPath)}
            >
              Draft
            </LineItemBtn>
          ) : (
            claim.cert_status === STATUS_APPROVED && (
              <LineItemBtn
                className="button view"
                onClick={() => history.push(approvedCertPath)}
              >
                View
              </LineItemBtn>
            )
          )}
        </HoverDiv>
      </>
    );
  };

  const handleSetLimit = (data) => {
    setCurrentPage(1);
    setLimit(data);
  };

  const handleDraftCert = (isNew) => {
    handlePendingCert(isNew, pendingCert.id, pendingCert.cert_id);
    handleOpenCertModal(false);
  };

  return (
    <Table>
      <div className="label-row">
        <div />
        <div className="claim">{accountConfig?.getIn(["claim", "noun"])}</div>
        <div className="certificate">CERTIFICATE</div>
      </div>
      <table>
        <thead>
          <tr>
            <th className="period-column">
              <div className="period">
                Period (End)
                <div
                  className={
                    "icon " + (sortDescending ? " descending" : " ascending")
                  }
                  onClick={() => setSortDescending(!sortDescending)}
                />
              </div>
            </th>
            <th className="separator" />
            <th className="percent">
              Gross Amount
              {accountConfig?.get("enable_header_currency") &&
                " (" + accountConfig?.getIn(["currency", "code"]) + ")"}{" "}
              (%)
            </th>
            <th className="value">
              Net Amount
              {accountConfig?.get("enable_header_currency") &&
                " (" + accountConfig?.getIn(["currency", "code"]) + ")"}
            </th>
            <th className="separator" />
            <th className="percent">
              Gross Amount{" "}
              {accountConfig?.get("enable_header_currency") &&
                " (" + accountConfig?.getIn(["currency", "code"]) + ")"}{" "}
              (%)
            </th>
            <th className="value">
              Net Amount
              {accountConfig?.get("enable_header_currency") &&
                " (" + accountConfig?.getIn(["currency", "code"]) + ")"}
            </th>
          </tr>
        </thead>
        <tbody>
          {claims.map((claim) => {
            let previousClaim = {};
            if (claim.default_index < claims.length - 1) {
              previousClaim = claims.filter((c) => {
                return c.default_index === claim.default_index + 1;
              });
              previousClaim = previousClaim[0];
            }
            return (
              <tr key={claim.id}>
                <td>
                  {
                    convertUTCToTime(
                      claim.period_ending,
                      timezone,
                      false,
                      "dd/MM/yyyy",
                    )[0]
                  }
                </td>
                <td className="separator" />
                <td>{calculateGross(claim, contractInfo, false)}</td>
                <td>
                  <div className="amount">
                    {claimAmount(claim, previousClaim)}
                    {claimAmountButton(claim, type, readOnly)}
                  </div>
                </td>
                <td className="separator" />
                <td>{calculateGross(claim, contractInfo, true)}</td>
                <td>
                  <div className="amount">
                    {certAmount(claim)} {certAmountButton(claim, type)}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="pagination">
        <PageBarPart
          total={claimSummary.size}
          limit={limit}
          pageCount={pages}
          currentPage={currentPage}
          handlePageChange={setCurrentPage}
          handleSetData={handleSetLimit}
          field="fill"
        />
      </div>
      <CertOptionModal
        open={openCertModal}
        closeDialog={() => handleOpenCertModal(false)}
        chooseOption={handleDraftCert}
        certName={pendingCert?.cert_name}
      />
    </Table>
  );
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.getIn(["manageAccount", "accountInfo"]),
    accountConfig: state.getIn(["config", "accountConfig"]),
    userProfile: state.getIn(["headers", "profile"]),
  };
};

export default withRouter(connect(mapStateToProps, null)(ClaimHistoryTable));
