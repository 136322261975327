import React, { PureComponent } from "react";
import { connect } from "react-redux";
import debounce from "lodash/debounce";

import { BgWrapper, HeaderTitle as Title } from "../../../common/shared_style";
import { ContentWrapper } from "./style";

import Loading from "../../../common/loading";
import Timezone from "./component/timezone";
import {
  AccountInfoPart,
  PlanInfoPart,
  AccountRole,
  UserInfoPart,
  EmailSetting,
} from "./component/accountInfo";

import { actionCreators } from "../store";

class Index extends PureComponent {
  render() {
    const {
      userList,
      role,
      planDetail,
      accountInfo,
      accountStatus,
      loadingAccountInfo,
      emailSummary,
      config,
      updateTimezone,
      accountConfig,
      env,
    } = this.props;
    return (
      <BgWrapper>
        <Title> Account Settings </Title>
        {!loadingAccountInfo && (
          <ContentWrapper>
            <AccountInfoPart {...this.props} />
            <PlanInfoPart
              info={planDetail}
              userList={userList}
              noPermission={role !== "owner"}
              accountType={accountStatus}
              accountConfig={accountConfig}
              env={env}
            />
            <AccountRole
              noPermission={role !== "owner" && role !== "admin"}
              info={accountInfo}
              config={config}
              accountConfig={accountConfig}
            />
            <Timezone
              {...this.props}
              updateTimezone={(data) => {
                updateTimezone(emailSummary, data);
              }}
            />
            <UserInfoPart list={userList} />
            <EmailSetting data={emailSummary} />
          </ContentWrapper>
        )}
        {loadingAccountInfo && (
          <Loading message="Loading All Account Info ..." />
        )}
      </BgWrapper>
    );
  }
  componentDidMount() {
    this.props.getAccountInfo();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.accountInfo !== prevProps.accountInfo &&
      this.props.accountInfo
    ) {
      this.props.getInitialInfo();
    }
  }
}

const mapStateToProps = (state) => ({
  userList: state.getIn(["manageAccount", "allAccount"]),
  accountInfo: state.getIn(["manageAccount", "accountInfo"]),
  loadingAccountInfo: state.getIn(["manageAccount", "loadingAccountInfo"]),
  accountStatus: state.getIn(["headers", "profile", "account_status"]),
  image_64: state.getIn(["headers", "profile_image", "image_64"]),
  image_type: state.getIn(["headers", "profile_image", "image_type"]),
  role: state.getIn(["headers", "accountRole"]),
  config: state.getIn(["headers", "config"]),
  planDetail: state.getIn(["manageAccount", "planDetail"]),
  emailSummary: state.getIn(["manageAccount", "emailSummary"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
  timezoneOptions: state.getIn(["manageAccount", "timezoneOptions"]),
  env: state.getIn(["headers", "config", "env"]),
});

const mapDispatchToProps = (dispatch) => ({
  getAccountInfo() {
    dispatch(actionCreators.getAccountInfo());
  },
  getInitialInfo() {
    dispatch(actionCreators.getAllUsers());
    dispatch(actionCreators.getPlanOverview());
    dispatch(actionCreators.getEmailSummary());
    dispatch(actionCreators.getLoginInfo());
  },
  updateTimezone(emailSummary, data) {
    dispatch(actionCreators.updateTimezone(data));
    dispatch(actionCreators.updateEmailSummary(emailSummary));
    const debounceUpdateEmailSummary = debounce(
      (emailSummaryData) => actionCreators.updateEmailSummary(emailSummaryData),
      500,
    );
    debounceUpdateEmailSummary(emailSummary);
  },
  getTimezoneOptions(country) {
    dispatch(actionCreators.getTimezoneOptions(country));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
