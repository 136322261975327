import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Link from "../../../../common/linkWithQuery";

import { BgWrapper, HeaderTitle } from "../../../../common/shared_style";

import Menu from "../../../../common/breadcrumbs";
import { BlueWhiteBtn as Button } from "../../../../common/button";
import Users from "./components/users";

import { actionCreators } from "../../store";

class ManageUsers extends PureComponent {
  render() {
    var link = [{ link: "/manage-account/view", text: "Manage Account" }];
    return (
      <BgWrapper>
        <Menu link={link} content="Manage Users" />
        <HeaderTitle style={{ float: "left" }}>Manage Users</HeaderTitle>
        <Link to="/manage-account/users/add">
          <Button title="ADD NEW USER" />
        </Link>
        <Users {...this.props} />
      </BgWrapper>
    );
  }

  componentDidMount() {
    this.props.getUserList();
  }
}

const mapStateToProps = (state) => ({
  allUsers: state.getIn(["manageAccount", "allAccount"]),
  sendingEmail: state.getIn(["manageAccount", "sendingEmail"]),
  profile: state.getIn(["headers", "profile"]),
  requestingOwner: state.getIn(["manageAccount", "requestingOwner"]),
});

const mapDispatchToProps = (dispatch) => ({
  getUserList() {
    dispatch(actionCreators.getAllUsers());
  },
  handleDeleteUser(user, redirect) {
    dispatch(actionCreators.deleteAccountUser(user?.get("id"), redirect));
  },
  handleActivateUser(user) {
    dispatch(actionCreators.activateAccountUser(user?.get("id")));
  },
  handleResendEmail(user) {
    dispatch(actionCreators.resendEmailToUser(user?.get("id")));
  },
  handleUpdateUser(user, data, redirect) {
    dispatch(actionCreators.updateUser(user, data, redirect));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
