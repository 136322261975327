import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { format } from "date-fns";

import { DeleteButton, HelperContainer } from "../../styled";

import { actionCreators } from "../../../../common/modal";
import { AddBtn } from "../../../../common/button";
import Input from "../../../../common/form";
import {
  calculateSubunitToUnit,
  currencySubunitToUnit,
} from "../../../../utils/currencyHelper";
import {
  DEFAULT_CURRENCY,
  RETENTION_OPTIONS,
  RETENTION_SCALE,
} from "../../../../utils/constants";
import Modal from "../modal";
import AccountDetail from "../../../contract/components/retention/accountRetentionDetail";
import { roundNumber } from "utils/numeralHelper";

import store from "../../../../store";

const PercentInput = (props) => {
  return (
    <Input
      field="number"
      decimalScale={2}
      max={100}
      className="input-percent-small-width"
      suffix={"%"}
      placeholder={"%"}
      {...props}
      isAllowed={(values) => {
        const { floatValue } = values;
        return !floatValue || (floatValue <= 100 && floatValue >= 0);
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

const DollarInput = connect(
  mapStateToProps,
  null,
)((props) => {
  const { accountConfig } = props;

  return (
    <Input
      field="number"
      decimalScale={2}
      className={`input-percent-small-width dollar ${props.appendstyle}`}
      thousandSeparator={","}
      decimalSeparator={"."}
      allowLeadingZeros={false}
      defaultValue={0.0}
      prefix={
        accountConfig?.getIn(["direction", "value"]) === "LTR"
          ? accountConfig?.getIn(["currency", "title"]) || DEFAULT_CURRENCY
          : ""
      }
      suffix={
        accountConfig?.getIn(["direction", "value"]) === "RTL"
          ? accountConfig?.getIn(["currency", "title"]) || DEFAULT_CURRENCY
          : ""
      }
      placeholder={
        accountConfig?.getIn(["currency", "title"]) || DEFAULT_CURRENCY
      }
      {...props}
      isAllowed={(values) => {
        const { floatValue } = values;
        return !floatValue || floatValue >= 0;
      }}
    />
  );
});

export default function Retention(props) {
  const [disableSave, setDisableSave] = useState(false);

  const [retentionInfo, setRetentionInfo] = useState({});
  const [retentionType, setRetentionType] = useState("");
  const [retentionScale, setRetentionScale] = useState([]);
  const [retentionAccounts, setRetentionAccounts] = useState([]);

  const {
    config,
    retention,
    contractInfo,
    hideHelper,
    currency,

    open,
    setEdited,
    updateInfo,
    setOpen,
    hideTag,
    isContract,
  } = props;

  const {
    firstPercent,
    firstAmount,
    pcdPercent,
    pcdDate,
    dlpType,
    dlpPercent,
    dlpDays,
    excessPercent,
    excessAmount,
    maximum,
    showPrefilledTag,
  } = retentionInfo;

  const validateForm = () => {
    const { pcdPercent, dlpPercent } = retentionInfo;
    if (
      retentionType !== RETENTION_OPTIONS.NO_RETENTION.value &&
      (pcdPercent < 0 || dlpPercent < 0 || pcdPercent + dlpPercent !== 100)
    ) {
      return false;
    }
    return true;
  };

  const handleSave = (showPrefilledTag) => {
    if (!validateForm()) {
      store.dispatch(
        actionCreators.showModal("alert", {
          open: true,
          title: "Error!",
          message:
            "Sum of Practical Completion and Defect Liability percent not equal to 100%",
        }),
      );
      return;
    }

    if (showPrefilledTag) hideTag();
    updateInfo(retentionType, retentionInfo, retentionScale, retentionAccounts);
    handleOpen(false);
  };

  useEffect(() => {
    setInitialValue(retention);
    // eslint-disable-next-line
  }, [retention, open]);

  useEffect(() => {
    setDisableSave(!validateForm());
    // eslint-disable-next-line
  }, [retentionInfo]);

  const setInitialValue = (retention) => {
    let type =
      retention?.get("retention_type") || RETENTION_OPTIONS.NO_RETENTION.value;
    let noRetention = type === RETENTION_OPTIONS.NO_RETENTION.value;
    let retentionAccount = [];
    let info = {
      firstPercent: noRetention ? "" : 0,
      firstAmount: noRetention ? "" : 0,
      excessPercent: noRetention ? "" : 0,
      excessAmount: noRetention ? "" : 0,
      maximum: noRetention ? "" : 0,
      pcdPercent: "",
      pcdDate: "",
      dlpType: "working_days_after_pcd",
      dlpPercent: "",
      dlpDays: "",
    };

    if (retention) {
      info = {
        firstPercent: retention.get("firstpercent"),
        firstAmount: calculateSubunitToUnit(retention.get("firstamount")),

        excessPercent: retention.get("excesspercent"),
        excessAmount: calculateSubunitToUnit(retention.get("excessamount")),
        maximum: calculateSubunitToUnit(retention.get("maximum")),

        pcdPercent: roundNumber(retention.get("pcd_percent")),
        pcdDate: retention.get("pcd_date"),
        dlpType: retention.get("release_dlp_type"),
        dlpPercent: roundNumber(retention.get("dlppercent")),
        dlpDays: retention.get("dlpdays"),
      };
      retentionAccount = retention?.get("retention_account")?.toJS()
        ? retention
            ?.get("retention_account")
            .toJS()
            .map((msg, index) => ({ id: index, msg }))
        : [];

      let scaleList = [];
      RETENTION_SCALE.map((item) => {
        if (
          retention.get(item + "nextpercent") &&
          retention.get(item + "nextamount")
        ) {
          let scale = {};
          scale["id"] = Date.now();
          scale["percentage"] = retention.get(item + "nextpercent") || "";
          scale["amount"] =
            calculateSubunitToUnit(retention.get(item + "nextamount")) || "";
          scaleList.push(scale);
        }
        return null;
      });
      setRetentionScale(scaleList);
    }
    setRetentionInfo(info);
    setRetentionType(type);
    setRetentionAccounts(retentionAccount);
  };

  const handleOpen = (value) => {
    if (!value) {
      setInitialValue(retention);
    }
    setOpen(value);
  };

  const handleChange = (name, value) => {
    setEdited(true);
    if (name === "pcdDate") {
      if (!value) {
        // set pcdDate to null if empty value
        return setRetentionInfo({ ...retentionInfo, [name]: null });
      } else {
        if (value.toString() !== "Invalid Date") {
          value = format(new Date(value), "yyyy-MM-dd");
        }
      }
    }
    if (name === "pcdPercent") {
      return setRetentionInfo({
        ...retentionInfo,
        [name]: value,
        dlpPercent: 100 - value,
      });
    }
    if (name === "dlpPercent") {
      return setRetentionInfo({
        ...retentionInfo,
        [name]: value,
        pcdPercent: 100 - value,
      });
    }
    return setRetentionInfo({
      ...retentionInfo,
      [name]: value,
    });
  };
  const handleRemoveValue = () => {
    return setRetentionInfo({
      ...retentionInfo,
      firstPercent: "",
      firstAmount: "",
    });
  };
  const handleScaleChange = (name, value) => {
    let id = name.split("_")[0],
      param = name.split("_")[1];
    let result = retentionScale.map((comp) => {
      if (comp.id === parseInt(id, 10)) {
        if (param === "percent") {
          comp.percentage = value;
        } else {
          comp.amount = value;
        }
      }
      return comp;
    });
    setRetentionScale(result);
  };

  const addScale = () => {
    setRetentionScale(
      retentionScale.concat({
        id: Date.now(),
        percentage: "",
        amount: "",
      }),
    );
  };

  const removeScale = (id) => {
    const list = [...retentionScale];
    var index = -1;
    for (var i = 0; i < retentionScale.length; i++) {
      if (list[i].id === id) {
        index = i;
      }
    }
    if (index !== -1) {
      list.splice(index, 1);
    }
    setRetentionScale(list);
  };

  const getCalculatedRetentionAmount = () =>
    currencySubunitToUnit(
      (firstPercent * contractInfo?.get("total_baseitems")) / 100,
    );

  let dlpOptions = [];
  if (config.size !== 0) {
    let dlpConfig = config.payclaim.payclaim_retention_dlp_option || {};
    dlpOptions = Object.keys(dlpConfig).map((ele) => ({
      value: ele,
      label: dlpConfig[ele],
    }));
  }

  return (
    <Modal
      title="Retentions"
      open={open}
      disabled={disableSave}
      close={() => handleOpen(false)}
      submit={() => handleSave(showPrefilledTag)}
    >
      <>
        <div className="has_margin">Set up your project retention info.</div>
        <h1>Retention Schedule</h1>
        <Input
          field="dropdown"
          wrapperClassName="input-margin"
          onChange={(e) => {
            setRetentionType(e.value);
            handleRemoveValue();
          }}
          value={retentionType}
          options={Object.values(RETENTION_OPTIONS)}
          width="424px"
          name="retention_type"
          usePortal={true}
        />

        {/* Percentage of Contract Value */}
        {retentionType === RETENTION_OPTIONS.CONTRACT.value && (
          <div className="form-container">
            <div className="input-label">Retention Amount</div>
            <div className="flex-row">
              <PercentInput
                name="firstPercent"
                value={firstPercent}
                onValueChange={(e) =>
                  handleChange("firstPercent", e.floatValue)
                }
              />
            </div>
            {!hideHelper && (
              <HelperContainer>
                Current base contract is at{" "}
                {currencySubunitToUnit(contractInfo?.get("total_baseitems"))}.
                Retention amount will be {getCalculatedRetentionAmount()}
              </HelperContainer>
            )}
          </div>
        )}

        {/* Percentage of fixed Value */}
        {retentionType === RETENTION_OPTIONS.FIXED.value && (
          <div className="form-container">
            <div className="input-label">Retention Amount</div>
            <div className="flex-row">
              <PercentInput
                value={firstPercent}
                onValueChange={(e) =>
                  handleChange("firstPercent", e.floatValue)
                }
              />
              <div className="content-label small_space">Of</div>
              <DollarInput
                appendstyle="big_size"
                value={firstAmount}
                onValueChange={(e) => handleChange("firstAmount", e.floatValue)}
                currency={currency}
              />
              <DollarInput
                disabled
                value={calculateSubunitToUnit(firstPercent * firstAmount)}
                currency={currency}
              />
            </div>
          </div>
        )}

        {/* Percentage of Scale */}
        {retentionType === RETENTION_OPTIONS.SCALE.value && (
          <div className="form-container">
            <div className="input-label">Retention Amount</div>
            <div className="flex-row">
              <PercentInput
                name="firstPercent"
                value={firstPercent}
                onValueChange={(e) =>
                  handleChange("firstPercent", e.floatValue)
                }
              />
              <div className="content-label">Of the first</div>
              <DollarInput
                name="firstAmount"
                value={firstAmount}
                onValueChange={(e) => handleChange("firstAmount", e.floatValue)}
                currency={currency}
              />
              <DollarInput
                disabled
                value={calculateSubunitToUnit(firstPercent * firstAmount)}
                currency={currency}
              />
            </div>

            {retentionScale.length !== 0
              ? retentionScale.map((comp, index) => {
                  return (
                    <div className="flex-row" key={index}>
                      <PercentInput
                        name={comp.id + "_percent"}
                        value={comp.percentage}
                        onValueChange={(e) =>
                          handleScaleChange(comp.id + "_percent", e.floatValue)
                        }
                      />
                      <div className="content-label">Of the next</div>
                      <DollarInput
                        name={comp.id + "_amount"}
                        value={comp.amount}
                        onValueChange={(e) =>
                          handleScaleChange(comp.id + "_amount", e.floatValue)
                        }
                        currency={currency}
                      />
                      <DollarInput
                        disabled
                        value={calculateSubunitToUnit(
                          comp.amount * comp.percentage,
                        )}
                        currency={currency}
                      />
                      <DeleteButton onClick={() => removeScale(comp.id)} />
                    </div>
                  );
                })
              : null}

            {retentionScale.length !== 3 ? (
              <AddBtn
                title="Add Scale"
                margin="20px 0 32px 0"
                onClick={() => addScale()}
              />
            ) : null}

            <div className="input-label">Optional</div>
            <div className="flex-row">
              <PercentInput
                value={excessPercent}
                onValueChange={(e) =>
                  handleChange("excessPercent", e.floatValue)
                }
              />
              <div className="content-label">In excess of</div>
              <DollarInput
                value={excessAmount}
                onValueChange={(e) =>
                  handleChange("excessAmount", e.floatValue)
                }
                currency={currency}
              />
            </div>
            <div className="flex-row">
              <div className="content-label has_big_space">
                With a maximum of
              </div>
              <DollarInput
                value={maximum}
                onValueChange={(e) => handleChange("maximum", e.floatValue)}
                currency={currency}
              />
            </div>
          </div>
        )}

        {/* retention release */}
        {retentionType !== RETENTION_OPTIONS.NO_RETENTION.value && (
          <div className="form-container">
            <h1 className="has_margin">Retention Release</h1>
            <div className="has_margin">
              <div className="input-label">Practical Completion</div>
              <div className="flex-row">
                <PercentInput
                  value={pcdPercent}
                  onValueChange={(e) =>
                    handleChange("pcdPercent", e.floatValue)
                  }
                />
                <Input
                  field="date"
                  name="pcd_date"
                  width="348px"
                  radius="4px"
                  handleChange={(e) => handleChange("pcdDate", e)}
                  initialValue={pcdDate}
                />
              </div>
            </div>

            <div className="has_margin">
              <div className="input-label">Defect Liability Period (DLP)</div>
              <div className="flex-row">
                <PercentInput
                  value={dlpPercent}
                  onValueChange={(e) =>
                    handleChange("dlpPercent", e.floatValue)
                  }
                />
                <Input
                  field="number"
                  decimalScale={0}
                  max={1000}
                  value={dlpDays}
                  className="input-small-width"
                  onChange={(e) => handleChange("dlpDays", e.target.value)}
                />
                <Input
                  field="dropdown"
                  width="272px"
                  options={dlpOptions}
                  value={dlpType}
                  onChange={(e) => handleChange("dlpType", e.value)}
                />
              </div>
            </div>

            {isContract && (
              <AccountDetail
                updateAccounts={(data) => setRetentionAccounts(data)}
                accounts={retentionAccounts}
              />
            )}
          </div>
        )}
      </>
    </Modal>
  );
}
