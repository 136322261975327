import React, { useState } from "react";
import { connect } from "react-redux";

import {
  ContractDetailWrapper,
  SettingWrapper,
  ClaimHistoryWrap,
} from "../../styled";
import {
  Img,
  BlueSecondaryButton as DownloadBtn,
} from "../../../../common/button/style";
import downloadIcon from "../../../../statics/img/download-arrow.svg";
import { BlueOutlinedBtn as Button } from "../../../../common/button";
import ClaimHistoryTable from "./claimHistoryTable";
import PaymentTable from "./paymentTable";

function ClaimHistory(props) {
  const { payHistory, readOnly, accountInfo, contractInfo, accountConfig } =
    props;
  const [openModal, setOpenModal] = useState(false);

  return (
    <ContractDetailWrapper className="setting-page" id="nav-history">
      <SettingWrapper>
        <div className="section-title">
          {accountConfig?.getIn(["claim", "noun"])}s History
        </div>
        <ClaimHistoryWrap>
          <div className="buttons">
            <DownloadBtn onClick={props.downloadSummary}>
              <Img
                name={downloadIcon}
                margin="0 10px 0 0"
                float="left"
                height="17px"
                width="14px"
              />
              {accountConfig?.getIn(["claim", "noun"])}s Summary
            </DownloadBtn>
            {props.type !== "cert" && (
              <Button
                className="payments-btn"
                title="payments"
                onClick={() => setOpenModal(true)}
              />
            )}
          </div>
          <ClaimHistoryTable {...props} />
        </ClaimHistoryWrap>
      </SettingWrapper>

      <PaymentTable
        open={openModal}
        handleClose={() => setOpenModal(false)}
        payments={payHistory}
        update={props.update}
        readOnly={readOnly}
        download={props.download}
        accountInfo={accountInfo}
        contractInfo={contractInfo}
      />
    </ContractDetailWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.getIn(["manageAccount", "accountInfo"]),
  };
};
export default connect(mapStateToProps, null)(ClaimHistory);
