import React, { Component } from "react";
import {
  TableWrapper,
  EnhancedTableToolbar,
  TableContainer,
  DropdownWrapper,
  TableSubtitle,
} from "./basic_table_style";
import { handleTableSorter, checkIsParent } from "../../utils/helper";
import { isString, toKebabCase } from "../../utils/stringHelper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Table as Tables } from "shineout";

function sortNodesAndChildren(nodes, sortLabel, sortSep) {
  nodes.sort(handleTableSorter(sortLabel, sortSep));
  nodes.forEach(function (node) {
    if (checkIsParent(node)) {
      sortNodesAndChildren(node.childitems, sortLabel, sortSep);
    }
  });
}

export const Sort = ({
  open,
  openDropdown,
  setSort,
  sortField,
  sortSep,
  sortOptions,
}) => {
  return (
    <DropdownWrapper active={open}>
      <div className="dropdown-title" onClick={openDropdown}>
        <label>Sort By:</label>
        <div>{sortOptions["options"][sortField]}</div>
        <div className="icon" />
      </div>

      {open && (
        <div className="dropdown-list">
          <ul>
            {Object.keys(sortOptions["options"]).map((item) => (
              <li key={item}>
                <input
                  type="radio"
                  id={item}
                  name="sortField"
                  value={item}
                  checked={sortField === item}
                  onChange={setSort}
                />
                <label htmlFor={item}>{sortOptions["options"][item]}</label>
              </li>
            ))}
          </ul>
          <hr />
          <ul>
            {Object.keys(sortOptions["sep"]).map((item) => (
              <li key={item}>
                <input
                  type="radio"
                  id={item}
                  name="sortSep"
                  value={item}
                  checked={sortSep === item}
                  onChange={setSort}
                />
                <label htmlFor={item}>{sortOptions["sep"][item]}</label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </DropdownWrapper>
  );
};

export default class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: [],
      openSortList: false,
      sortField: this.props.defaultSortOrder || "created_date",
      sortSep: "asc",
    };
    this.sortTable = this.sortTable.bind(this);
  }

  handleSort() {
    if (this.state.openSortList) {
      if (this.props?.setSortState) {
        this.props?.setSortState({
          sortField: this.state.sortField,
          sortSep: this.state.sortSep,
        });
      }
      this.sortTable();
      return this.setState({ openSortList: false });
    }
  }

  sortTable() {
    const { table, sortField, sortSep } = this.state;

    // deep copy table data before sorting
    let tempTable = JSON.parse(JSON.stringify(table));
    sortNodesAndChildren(tempTable, sortField, sortSep);
    return this.setState({ table: tempTable });
  }

  render() {
    // TODO - Investigate Later
    // Happening while removing the unused `children` prop - Super weird
    const {
      tableCaption,
      tableCaptionSubtitle,
      tableColumns,
      tableSubtitle,
      tableSubtitleClass,
      hideTableToolbar,

      hasEnhancedTableToolbar,
      hasEnhancedTableHeader,
      hasEnhancedTableFooter,
      hasTableSubtitle,
      isTableSubtitleBold,
      hasSort,
      hideTable,
      // eslint-disable-next-line no-unused-vars
      children,
      externalSortOptions,
      overwriteDefaultSortOptions,
      ...otherProps
    } = this.props;
    const { openSortList, sortField, sortSep, table } = this.state;

    let classNameTableCaption = tableCaption;
    if (!isString(tableCaption) && tableCaption?.props) {
      if (tableCaption?.props.children[1]?.props?.children) {
        classNameTableCaption =
          tableCaption?.props.children[1]?.props?.children;
      }
    }

    const defaultSortOptions = {
      options: overwriteDefaultSortOptions
        ? overwriteDefaultSortOptions
        : {
            created_date: "Date Created",
            label: "Reference",
            description: "Description",
            total: "Total Value",
          },
      sep: {
        asc: "Ascending",
        desc: "Descending",
      },
    };

    const enhancedCom = React.Children.toArray(this.props.children);
    return (
      <TableWrapper>
        {!hideTableToolbar && (
          <EnhancedTableToolbar>
            <div className="tableCaption">{tableCaption}</div>
            <div className="tableToolbar">
              {hasSort && (
                <ClickAwayListener onClickAway={() => this.handleSort()}>
                  <div>
                    <Sort
                      open={openSortList}
                      openDropdown={() =>
                        this.setState({ openSortList: !openSortList })
                      }
                      setSort={(e) =>
                        this.setState({ [e.target.name]: e.target.value })
                      }
                      sortField={sortField}
                      sortSep={sortSep}
                      sortOptions={
                        externalSortOptions
                          ? {
                              sep: { ...defaultSortOptions.sep },
                              options: {
                                ...defaultSortOptions.options,
                                ...externalSortOptions,
                              },
                            }
                          : defaultSortOptions
                      }
                    />
                  </div>
                </ClickAwayListener>
              )}
              {hasEnhancedTableToolbar && <>{enhancedCom[0]}</>}
            </div>
          </EnhancedTableToolbar>
        )}
        {tableCaptionSubtitle && (
          <div className="tableCaptionSubtitle">{tableCaptionSubtitle}</div>
        )}
        {hasTableSubtitle && (
          <TableSubtitle
            className={
              isTableSubtitleBold !== undefined
                ? "form_label " + (tableSubtitleClass ? tableSubtitleClass : "")
                : "" + (tableSubtitleClass ? tableSubtitleClass : "")
            }
          >
            {tableSubtitle}
          </TableSubtitle>
        )}
        {hideTable && <>{enhancedCom[1]}</>}
        {!hideTable && (
          <TableContainer
            className={toKebabCase(classNameTableCaption)}
            marginTop={"20"}
          >
            {hasEnhancedTableHeader && (
              <>{enhancedCom[hasEnhancedTableToolbar ? 1 : 0]}</>
            )}
            <Tables
              keygen="id"
              data={table}
              columns={tableColumns}
              {...otherProps}
            />
            {hasEnhancedTableFooter && (
              <>
                {
                  enhancedCom[
                    hasEnhancedTableHeader && hasEnhancedTableToolbar
                      ? 2
                      : hasEnhancedTableHeader || hasEnhancedTableToolbar
                        ? 1
                        : 0
                  ]
                }
              </>
            )}
          </TableContainer>
        )}
      </TableWrapper>
    );
  }
  shouldComponentUpdate(nextProps) {
    if (nextProps.tableContent !== this.props.tableContent) {
      this.setState(
        {
          table: nextProps.tableContent,
        },
        () => this.sortTable(),
      );
    }
    return true;
  }
  componentDidMount() {
    return this.setState({ table: this.props.tableContent }, () =>
      this.sortTable(),
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props?.sortState &&
      this.props?.sortState !== prevProps?.sortState
    ) {
      return this.setState(
        {
          sortField: this.props.sortState.sortField,
          sortSep: this.props.sortState.sortSep,
        },
        () => this.sortTable(),
      );
    }
  }
}
