import React, { useState, useEffect, Fragment } from "react";

import { SectionIcon } from "../../project/styled";
import {
  ContractDetailWrapper,
  SettingWrapper,
  NotesFieldStyle,
  NotesModalContent,
  NotesStyle,
} from "../styled";
import {
  BlueWhiteBtn as DarkBtn,
  BlueOutlinedBtn as Button,
} from "../../../common/button";

import Modal from "../../project/components/modal";
import Input from "../../../common/form";

const NotesField = (props) => (
  <NotesFieldStyle>
    <h3>{props.title}</h3>
    <Input
      className="notes-input"
      field="textarea"
      value={props.value}
      onChange={(e) => props.handleChange(e.target.value)}
    />
    <p className="description">{props.description}</p>
  </NotesFieldStyle>
);

export default function NoteSection(props) {
  const { info, side, readOnly } = props;
  const [openModal, setOpenModal] = useState(false);
  const [notesEmpty, setNotesEmpty] = useState(true);
  const [internalNotes, setInternalNotes] = useState("");
  const [externalNotes, setExternalNotes] = useState("");
  // used as a display and holder of note changes in the modal
  const [displayInternal, setDisplayInternal] = useState("");
  const [displayExternal, setDisplayExternal] = useState("");

  // set initial notes
  useEffect(() => {
    let internalNote = "";

    if (side === "claim") {
      internalNote = "claimer_internal_notes";
    } else if (side === "cert") {
      internalNote = "certifier_internal_notes";
    }
    // check if both internal and external notes from the server are empty
    if (internalNote !== "") {
      let notesEmpty = !info.get("external_notes") && !info.get(internalNote);
      setNotesEmpty(notesEmpty);
      if (!notesEmpty) {
        setInternalNotes(info.get(internalNote));
        setExternalNotes(info.get("external_notes"));

        setDisplayInternal(info.get(internalNote));
        setDisplayExternal(info.get("external_notes"));
      }
    }
  }, [info, side]);

  const handleSubmit = () => {
    props.handleSubmit({
      internal_notes: displayInternal,
      external_notes: displayExternal,
    });
    setOpenModal(false);
  };

  const handleCancel = () => {
    setOpenModal(false);
    setDisplayInternal(internalNotes);
    setDisplayExternal(externalNotes);
  };

  return (
    <ContractDetailWrapper className="setting-page" id="nav-note">
      <SettingWrapper>
        <div className="section-title">
          <div>
            <SectionIcon img="notes" />
            <span>Notes</span>
          </div>
          {!notesEmpty && !readOnly && (
            <Button title="edit" onClick={() => setOpenModal(true)} />
          )}
        </div>

        {/* check if there are internal and external notes */}
        {notesEmpty ? (
          <>
            <div className="center">
              Add notes for internal or external people to see.
            </div>
            {!readOnly && (
              <div>
                <DarkBtn title="Add Notes" onClick={() => setOpenModal(true)} />
              </div>
            )}
          </>
        ) : (
          <>
            <NotesStyle>
              <div className="internal">
                <h3>Internal Notes</h3>
                <p className="notes">
                  {internalNotes &&
                    internalNotes.split("\n").map((line, key) => (
                      <Fragment key={key}>
                        {line}
                        <br />
                      </Fragment>
                    ))}
                </p>
              </div>
              <div className="external">
                <h3>External Notes</h3>
                <p className="notes">
                  {externalNotes.split("\n").map((line, key) => (
                    <Fragment key={key}>
                      {line}
                      <br />
                    </Fragment>
                  ))}
                </p>
              </div>
            </NotesStyle>
          </>
        )}
      </SettingWrapper>
      {/* Notes input modal */}
      <Modal
        open={openModal}
        close={() => handleCancel()}
        submit={() => handleSubmit()}
        title="Notes"
      >
        <NotesModalContent>
          <NotesField
            title="Internal Notes"
            description="These notes will only be seen by contract members"
            value={displayInternal}
            handleChange={setDisplayInternal}
          />
          <NotesField
            title="External Notes"
            description="These notes will only be seen by third party and people outside of the projects (e.g in export pdf)"
            value={displayExternal}
            handleChange={setDisplayExternal}
          />
        </NotesModalContent>
      </Modal>
    </ContractDetailWrapper>
  );
}
