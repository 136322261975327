import React from "react";
import { Prompt } from "react-router-dom";
import Modal from "./confirmLeaveDialog";
import history from "../../../utils/history";

export class RouteLeavingGuard extends React.Component {
  state = {
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false,
  };

  showModal = (location) =>
    this.setState({
      modalVisible: true,
      lastLocation: location,
    });

  closeModal = (callback) =>
    this.setState(
      {
        modalVisible: false,
      },
      callback,
    );

  handleBlockedNavigation = (nextLocation) => {
    const { confirmedNavigation } = this.state;
    if (!confirmedNavigation) {
      this.showModal(nextLocation);
      return false;
    }
    return true;
  };

  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const { lastLocation } = this.state;
      if (lastLocation) {
        this.setState(
          {
            confirmedNavigation: true,
          },
          () => {
            history.push(lastLocation.pathname);
          },
        );
      }
    });
  render() {
    const { when } = this.props;
    const { modalVisible } = this.state;
    return (
      <>
        <Prompt
          when={when}
          message={(location) => this.handleBlockedNavigation(location)}
        />
        <Modal
          open={modalVisible}
          title="Notice!"
          type="warning"
          closeModal={() => this.closeModal()}
          leavePage={() => this.handleConfirmNavigationClick()}
        />
      </>
    );
  }
}
export default RouteLeavingGuard;
