import styled from "styled-components";
import * as theme from "../../../theme";
import searchIcon from "../../../statics/img/icon-search-dark-grey.svg";
import linkIcon from "../../../statics/img/icon-link.svg";

export const ToggleButtonStyle = styled.div`
  height: ${(props) => props.height || "40px"};
  display: flex;
  font-size: 16px;
  margin-bottom: 56px;
  .button {
    height: 100%;
    width: ${(props) => props.buttonWidth || "50%"};
    padding: 8px 24px;
    background-color: ${theme.palette.white};
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px ${theme.palette.gunmetal};
    font-weight: bold;
    cursor: pointer;

    &.left {
      border-radius: 4px 0 0 4px;
      border-right: 0;
    }
    &.right {
      border-radius: 0 4px 4px 0;
      border-left: 0;
    }

    &.active {
      background-color: ${theme.palette.gunmetal};
      color: ${theme.palette.white};
    }

    &.disabled {
      cursor: none;
      pointer-events: none
      background-color: ${theme.palette.white};
      color: ${theme.palette.lightGrey};
      border: solid 1px ${theme.palette.lightGrey};
    }

    &.disabled-active {
      background-color: ${theme.palette.lightGrey} !important;
      color: ${theme.palette.white} !important;
    }
  }
`;
export const LinkSelectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const SearchContainer = styled.div`
  height: 44px;
  width: 576px;
  background-color: ${theme.palette.white};
  box-sizing: border-box;
  float: left;
  border-radius: 4px;
  padding: 10px 10px 10px 16px;
  border: 1px solid ${theme.palette.lightWolfGrey};
  display: grid;
  grid-template-columns: 24px auto;
  grid-column-gap: 10px;

  input {
    ::placeholder {
      color: ${theme.palette.wolfGrey};
    }
    outline: none;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-family: ${theme.font.mainFont};
    font-weight: ${theme.font.normalFontWeight};
    height: 100%;
    color: ${theme.palette.gunmetal};
  }
  .img-search {
    height: 24px;
    width: 24px;
    background: url(${searchIcon}) no-repeat scroll;
    background-size: 24px;
    cursor: pointer;
  }
`;

export const SortContainer = styled.div`
  height: 44px;
  width: 278px;
  border: 1px solid ${theme.palette.wolfGrey};
  border-radius: 4px;
  margin-left: 24px;
  float: left;
  box-sizing: border-box;
  background-color: ${theme.palette.white};
  display: grid;
  grid-template-columns: 70px auto;
  align-items: center;
  .sort-label {
    margin-left: 16px;
    font-weight: ${theme.font.mainFontWeight};
  }
`;

export const ListWrap = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  float: left;
  width: 100%;
  label {
    text-transform: capitalize;
  }
  ul {
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid ${theme.palette.lightWolfGrey};
    margin: 4px 0 16px 0;
    max-height: 300px;
    height: auto;
    overflow: auto;
  }
  li {
    margin-bottom: 8px;
    list-style: none;
    width: 100%;
    min-height: 24px;
    background-color: ${theme.palette.white};
    padding: 4px 7px;
    &:hover {
      background-color: ${theme.palette.greyWhite};
      cursor: pointer;
    }
    &:last-child {
      margin: 0;
    }
    &.disabled {
      color: ${theme.palette.wolfGrey};
      cursor: not-allowed;
    }
    &.selected {
      background-color: ${theme.palette.veryWarmLightGrey};
    }
  }
`;

export const LinkedWrap = styled.div`
  margin: 56px 0;
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid ${theme.palette.gunmetal};
  .upper-wrap {
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
  }
  label {
    flex: 1 0 0;
    font-size: 20px;
    font-weight: 700;
  }
  .fixed-label {
    display: grid;
    align-items: center;
    width: 100%;
    grid-template-columns: 24px 1fr 95px;
    padding: 8px 16px;
    gap: 8px;
    border-radius: 4px;
    background: ${theme.palette.veryWarmLightGrey};
    .under-line-label {
      text-decoration-line: underline;
    }
  }
  .icon {
    width: 24px;
    height: 24px;
    background: url(${linkIcon}) no-repeat;
  }
`;

export const LinkedNotice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  .text {
    flex: 1 0 0;
    font-size: 16px;
    font-weight: 400;
  }
  .icon {
    width: 24px;
    height: 24px;
    background: url(${linkIcon}) no-repeat;
  }
`;
