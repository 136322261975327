import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import * as mainTheme from "../../theme";

export const AntSwitch = withStyles((theme) => ({
  root: {
    width: 37,
    height: 18,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: mainTheme.palette.white,
    "&$checked": {
      transform: "translateX(20px)",
      color: mainTheme.palette.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: mainTheme.palette.gunmetal,
        borderColor: mainTheme.palette.gunmetal,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${mainTheme.palette.wolfGrey}`,
    height: "16px",
    borderRadius: 18 / 2,
    opacity: 1,
    backgroundColor: mainTheme.palette.wolfGrey,
  },
  checked: {},
}))(Switch);
