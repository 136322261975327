import styled from "styled-components";
import * as theme from "../../../../../theme";
export const FormWrapper = styled.div`
  width: 526px;
  height: auto;
  margin: 56px 0;
`;
export const DetailBox = styled.div`
  height: auto;
  width: 100%;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  padding: 40px;
  box-sizing: border-box;
  .title {
    font-size: 24px;
    font-weight: ${theme.font.mainFontWeight};
    &.hasMargin {
      margin-top: 26px;
    }
  }
  .comment {
    margin: 40px 0;
    width: 100%;
    font-size: 16px;
    font-weight: ${theme.font.normalFontWeight};
  }
  .content {
    height: 376px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.profileWrapper {
      height: 332px;
    }
  }
  .SelectStyle {
    margin-top: 8px;
  }
  .label {
    font-size: 16px;
    font-weight: ${theme.font.mainFontWeight};
    margin-bottom: 8px;
  }
`;

export const Label = styled.label`
  font-size: 16px;
  font-weight: ${theme.font.mainFontWeight};
  height: 19px;
`;
export const Comment = styled.div`
  font-size: 14px;
  color: ${theme.palette.veryMediumGrey};
  line-height: 1.15;
`;
export const ErrorStyle = styled.div`
  color: ${theme.palette.watermelonRed};
`;
