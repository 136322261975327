import styled from "styled-components";
import toTopIcon from "../../statics/img/back-to-top.svg";

export const ToTop = styled.div`
  height: 70px;
  width: 70px;
  background: url(${toTopIcon}) no-repeat scroll;
  position: fixed;
  right: 0px;
  bottom: 20px;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
`;
