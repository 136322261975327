import React from "react";
import { Link, Redirect } from "react-router-dom";
import { useStore } from "react-redux";
import styled from "styled-components";

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export default function LinkWithQuery({ children, to, search, ...props }) {
  const store = useStore();
  const accountId = store
    .getState()
    .getIn(["headers", "profile", "payclaim_account_id"]);
  const defaultSearch = "?account_id=" + accountId;
  const pathname = typeof to === "string" ? to : to.pathname;
  return (
    <StyledLink
      to={{
        pathname: pathname,
        search: search || defaultSearch,
        ...to,
      }}
      {...props}
    >
      {children}
    </StyledLink>
  );
}

export function RedirectWithQuery({ children, to, search, ...props }) {
  const store = useStore();
  const accountId = store
    .getState()
    .getIn(["headers", "profile", "payclaim_account_id"]);
  const defaultSearch = "?account_id=" + accountId;

  return (
    <Redirect
      to={{
        pathname: to,
        search: search || defaultSearch,
      }}
      {...props}
    >
      {children}
    </Redirect>
  );
}
