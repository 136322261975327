import styled from "styled-components";
import * as theme from "../../../theme";
import editIcon from "../../../statics/img/icon-edit-pen-blue.svg";

export const DetailBox = styled.div`
  width: 100%;
  background-color: ${theme.palette.white};
  box-sizing: border-box;
  padding: 40px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 40px;
  .title {
    font-size: 16px;
    font-weight: ${theme.font.mainFontWeight};
    margin-bottom: 8px;
  }
  .sub-text {
    margin-top: 4px;
    font-size: 12px;
  }
`;

export const CompanyDetails = styled.div`
  display: grid;

  &.grid {
    grid-template: repeat(2, auto) / repeat(2, 420px);
    grid-column-gap: 40px;
    grid-row-gap: 40px;

    input {
      width: 100%;
    }
  }
  &.address {
    grid-template-rows: repeat(5, auto);
    grid-row-gap: 24px;
    border: 1px solid #c9c9c9;
    border-radius: 4px;
    padding: 24px;
    .searchAddressClass {
      margin: 0;
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }
  .country-field {
    display: flex;
  }

  input {
    border-radius: 4px;
    border-color: ${theme.palette.lightWolfGrey};
  }
`;

export const LinkElement = styled.div`
  .img {
    margin-right: 10px;
    height: 20px;
    width: 20px;
    background: url(${editIcon}) no-repeat;
    background-size: contain;
    display: block;
    float: left;
  }
  cursor: pointer;
  margin-top: 11px;
  color: ${theme.palette.turquoiseBlue};
  font-size: 14px;
  font-weight: ${theme.font.mainFontWeight};
  line-height: 24px;
  width: 130px;
  a {
    text-decoration: none;
    cursor: pointer;
    color: ${theme.palette.turquoiseBlue};
  }
`;
