import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";

import EditModal from "../modal";
import Input from "common/form";

import {
  PaymentTermStyle,
  DueDatesDialog,
  PaymentDueModalDescription,
} from "./styled";
import { DEFAULT_DUE_DATE_TERMS } from "utils/constants";

const Banner = ({ dueDates, example, error, accountConfig }) => {
  let invalidDueDate = "";
  let earlyDueDate = "";

  /* priority of error messages:
    1. claim earlier than cert
    2. claim earlier than payment
    3. cert earlier than payment */
  if (!dueDates?.get("claim_due_before_cert")) {
    invalidDueDate = accountConfig?.getIn(["claim", "noun"]);
    earlyDueDate = "Certificate";
  } else if (
    !dueDates?.get("claim_due_before_pay") &&
    dueDates?.get("claim_due_before_cert")
  ) {
    invalidDueDate = accountConfig?.getIn(["claim", "noun"]);
    earlyDueDate = "Payment";
  } else if (
    !dueDates?.get("cert_due_before_pay") &&
    (dueDates?.get("claim_due_before_cert") ||
      dueDates?.get("claim_due_before_pay"))
  ) {
    invalidDueDate = "Certificate";
    earlyDueDate = "Payment";
  }

  return (
    <DueDatesDialog className={error ? "error" : ""}>
      {error ? (
        <Fragment>
          <div className="error-icon" />
          {earlyDueDate} due date cannot be earlier than {invalidDueDate} due
          date.
        </Fragment>
      ) : (
        <Fragment>
          <span className="bold">Example: </span>
          <br />
          This month the {accountConfig?.getIn(["claim", "noun"])} will be due
          on {example.claimDate}. Certificate will be due on {example.certDate}{" "}
          and Payment will be due on {example.payDate}.
        </Fragment>
      )}
    </DueDatesDialog>
  );
};

const PaymentFields = (props) => {
  const { setDisableSave, dayValue, value, handleChange, options, placeMenu } =
    props;
  const [error, setError] = useState(false);
  // display values
  const [numInput, setNumInput] = useState(dayValue);
  const [option, setOption] = useState(value);

  useEffect(() => {
    if (option) {
      let input = numInput;
      // set to zero so error message won't show for last day option
      if (option?.includes("last_day")) {
        input = 0;
      }
      const err = input > 999;
      setError(err);
      setDisableSave(err);
      if (!err) {
        handleChange({ days: input, terms: option });
      }
    }
    // eslint-disable-next-line
  }, [numInput, option]);

  return (
    <PaymentTermStyle>
      <h3>{props.title}</h3>
      <div className="input-fields">
        {props.value === "the_last_day_of_following_month" ? (
          <div className="blocked-input" />
        ) : (
          <Input
            className={`days-input ${error ? "invalid" : ""}`}
            field="number"
            onValueChange={(e) => setNumInput(e.floatValue)}
            value={numInput}
            allowNegative={false}
          />
        )}
        <Input
          field="dropdown"
          className="dropdown-input"
          onChange={(e) => setOption(e.value)}
          options={options}
          value={option}
          width="370px"
          placeMenu={placeMenu}
        />
      </div>
      <div className="error">
        {error && <Fragment>Due date must be 999 or less</Fragment>}
      </div>
    </PaymentTermStyle>
  );
};

export default function PaymentDatesModal(props) {
  const {
    isProject,
    dueDates,
    initial,
    options,
    calculateExample,
    handleSubmit,
    open,
    accountConfig,
  } = props;

  const defaultDueDates = accountConfig?.get("default_due_dates");
  const [dropDownOptions, setDropDownOptions] = useState({});

  const [claimDue, setClaimDue] = useState(
    defaultDueDates?.get("claim_due_days").toJS() ??
      DEFAULT_DUE_DATE_TERMS.claim,
  );
  const [certDue, setCertDue] = useState(
    defaultDueDates?.get("cert_due_days").toJS() ?? DEFAULT_DUE_DATE_TERMS.cert,
  );
  const [payDue, setPayDue] = useState(
    defaultDueDates?.get("payment_due_days").toJS() ??
      DEFAULT_DUE_DATE_TERMS.pay,
  );
  const [example, setExample] = useState({});
  const [showError, setShowError] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    // check if the days values are not null, so it won't calculate if they are
    if (
      typeof claimDue.days === "number" &&
      typeof certDue.days === "number" &&
      typeof payDue.days === "number"
    ) {
      calculateExample({
        claim: claimDue,
        cert: certDue,
        pay: payDue,
      });
    }
    // eslint-disable-next-line
  }, [claimDue, certDue, payDue]);

  // set example dates and result of back end date validations
  useEffect(() => {
    const error = !(
      dueDates?.get("claim_due_before_cert") &&
      dueDates?.get("claim_due_before_pay") &&
      dueDates?.get("cert_due_before_pay")
    );
    setShowError(error);
    if (error) {
      setDisableSave(error);
    }

    if (!error) {
      let processedDates = {
        claimDate: dueDates?.get("claim_due_date")?.split("T")[0],
        certDate: dueDates?.get("cert_due_date")?.split("T")[0],
        payDate: dueDates?.get("pay_due_date")?.split("T")[0],
      };

      for (var [k, v] of Object.entries(processedDates)) {
        processedDates[k] = moment(v).format("dddd Do MMMM");
      }
      setExample(processedDates);
    }
  }, [dueDates]);

  useEffect(() => {
    if (Object.keys(initial).length > 0 && initial.every((v) => v !== null)) {
      setClaimDue({
        days: initial.get("claim_due_days"),
        terms: initial.get("claim_due_term"),
      });
      setCertDue({
        days: initial.get("cert_due_days"),
        terms: initial.get("cert_due_term"),
      });
      setPayDue({
        days: initial.get("payment_due_days"),
        terms: initial.get("payment_due_term"),
      });
    }
  }, [initial, open]);

  useEffect(() => {
    // setup the options for the dropdown fields
    let newOptions = {};
    for (const k in options) {
      newOptions[k] = turnToOptionsArray(options[k]);
    }
    setDropDownOptions(newOptions);
  }, [options, accountConfig]); // eslint-disable-line

  const turnToOptionsArray = (optionsObj) => {
    let optionArr = [];
    for (var i = 0; i < Object.keys(optionsObj).length; i++) {
      let label = Object.values(optionsObj)[i];
      if (label?.toLowerCase().includes("claim") && accountConfig) {
        label = label.replace(
          "claim",
          accountConfig?.getIn(["claim", "noun"])?.toLowerCase(),
        );
      }

      optionArr[i] = {
        label: label,
        value: Object.keys(optionsObj)[i],
      };
    }
    return optionArr;
  };

  return (
    <EditModal
      {...props}
      submit={() =>
        handleSubmit({
          claim: claimDue,
          cert: certDue,
          pay: payDue,
        })
      }
      disabled={disableSave}
    >
      {isProject && (
        <PaymentDueModalDescription>
          These will apply for all contracts that get added to this project.
          (Can be overriden per contract)
        </PaymentDueModalDescription>
      )}
      <PaymentFields
        title={`${accountConfig?.getIn(["claim", "noun"])} Due`}
        options={dropDownOptions["pay_claim_options"]}
        value={claimDue.terms}
        dayValue={claimDue.days}
        handleChange={setClaimDue}
        setDisableSave={setDisableSave}
        placeMenu={"bottom"}
      />
      <PaymentFields
        title={`${accountConfig?.getIn(["cert", "noun"])} Due`}
        options={dropDownOptions["pay_cert_options"]}
        value={certDue.terms}
        dayValue={certDue.days}
        handleChange={setCertDue}
        setDisableSave={setDisableSave}
        placeMenu={"bottom"}
      />
      <PaymentFields
        title="Payment Due"
        options={dropDownOptions["payment_options"]}
        value={payDue.terms}
        dayValue={payDue.days}
        handleChange={setPayDue}
        setDisableSave={setDisableSave}
        placeMenu={"top"}
      />
      <Banner
        dueDates={dueDates}
        example={example}
        error={showError}
        accountConfig={accountConfig}
      />
    </EditModal>
  );
}
