import {
  CALENDAR_DAYS_AFTER_CLAIM,
  CALENDAR_DAYS_FOLLOWING_MONTH,
  LAST_DAY_FOLLOWING_MONTH,
  OF_CLAIM_MONTH,
  WORKING_DAYS_AFTER_CLAIM,
  WORKING_DAYS_FOLLOWING_MONTH,
  WORKING_DAYS_OF_THE_FOLLOWING_MONTH,
  WORKING_DAYS_AFTER_THE_CLAIM_DATE,
  CALENDAR_DAYS_BEFORE_CLAIM_MONTH_END,
  WORKING_DAYS_BEFORE_CLAIM_MONTH_END,
} from "./constants";
const aMonth = 31;
function calculateDays(
  day,
  month,
  terms,
  claimMonth = {},
  priorMonthEnd = false,
) {
  let monthRes = month,
    dayRes = Math.max(day, 0);

  if (priorMonthEnd) {
    monthRes = 0;
    dayRes = Math.max(aMonth - day, 0);
  } else {
    // calculate dayOffset to the current month
    if (Object.keys(claimMonth).length > 0) {
      // terms based on claim date
      monthRes = claimMonth.month;
      dayRes = parseInt(claimMonth.dayOffset) + parseInt(day);
      if (monthRes >= 2) {
        dayRes = dayRes >= aMonth ? aMonth : dayRes % aMonth || aMonth;
      } else {
        if (dayRes > aMonth) {
          monthRes++;
          if (dayRes > aMonth * 2) {
            monthRes++;
          }
          if (dayRes > aMonth * 3) {
            dayRes = aMonth;
          } else {
            dayRes = dayRes % aMonth || aMonth;
          }
        }
      }
    } else if (terms === OF_CLAIM_MONTH) {
      if (dayRes > aMonth) {
        monthRes++;
        if (dayRes > aMonth * 2) {
          monthRes++;
        }
        if (dayRes > aMonth * 3) {
          dayRes = aMonth;
        } else {
          dayRes = dayRes % aMonth || aMonth;
        }
      }
    } else {
      // all other terms
      if (dayRes > aMonth) {
        monthRes++;
        if (dayRes > aMonth * 2) {
          dayRes = aMonth;
        } else {
          dayRes = dayRes % aMonth || aMonth;
        }
      }
    }
  }
  return {
    month: Math.min(monthRes, 2),
    day: parseInt(day < 0 ? 0 : day),
    dayOffset: parseInt(dayRes),
    terms: terms,
  };
}

function calculatePosition(terms, days, claimMonth) {
  switch (terms) {
    case CALENDAR_DAYS_FOLLOWING_MONTH:
    case WORKING_DAYS_OF_THE_FOLLOWING_MONTH:
    case WORKING_DAYS_FOLLOWING_MONTH: {
      return calculateDays(days, 1, terms);
    }
    case CALENDAR_DAYS_AFTER_CLAIM:
    case WORKING_DAYS_AFTER_CLAIM:
    case WORKING_DAYS_AFTER_THE_CLAIM_DATE: {
      return calculateDays(days, 0, terms, claimMonth);
    }
    case OF_CLAIM_MONTH: {
      return calculateDays(days, 0, terms);
    }
    case CALENDAR_DAYS_BEFORE_CLAIM_MONTH_END:
    case WORKING_DAYS_BEFORE_CLAIM_MONTH_END: {
      return calculateDays(days, 0, terms, {}, true);
    }
    case LAST_DAY_FOLLOWING_MONTH: {
      return { month: 1, day: aMonth, dayOffset: aMonth, terms: terms };
    }
    default: {
      return {
        month: 0,
        day: parseInt(days < 0 ? 0 : days),
        dayOffset: parseInt(days < 0 ? 0 : days),
        terms: terms,
      };
    }
  }
}

// calculates placement of each due date for the date schedule bar
export const calculateMonthPosition = (claimDue, certDue, payDue) => {
  let monthPosition = {
    claim: { month: 0, day: 0, dayOffset: 0 },
    cert: { month: 0, day: 0, dayOffset: 0 },
    pay: { month: 0, day: 0, dayOffset: 0 },
  };

  // to check if all the input date is valid
  const claimDueOk = claimDue && JSON.stringify(claimDue) !== "{}";
  const certDueOk = certDue && JSON.stringify(certDue) !== "{}";
  const payDueOk = payDue && JSON.stringify(payDue) !== "{}";

  if (claimDueOk && certDueOk && payDueOk) {
    // place claimDue in month
    monthPosition["claim"] = calculatePosition(claimDue.terms, claimDue.days);
    // place certDue in month
    monthPosition["cert"] = calculatePosition(
      certDue.terms,
      certDue.days,
      monthPosition["claim"],
    );
    // place payDue in month
    monthPosition["pay"] = calculatePosition(
      payDue.terms,
      payDue.days,
      monthPosition["claim"],
    );
  }
  return monthPosition;
};
