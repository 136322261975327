import * as constants from "./constants";
import { fromJS } from "immutable";
import { addDepthLabel } from "../../../utils/lineitemHelper";

const defaultState = fromJS({
  baseContractTable: [],
  addingItem: false,
  baseContractTotal: 0,
  loading: true,
  uploadFiles: [],
  editFileList: [],
  contractInfo: [],
});

const addInitialRowToTable = (state, action) => {
  let baseItems = action.baseItems.toJS();
  baseItems.forEach((line, index) => {
    baseItems[index] = addDepthLabel(line, 1);
  });

  return state
    .set("baseContractTable", fromJS(baseItems))
    .set("baseContractTotal", action.baseItemsTotal)
    .set("loading", false);
};
const removeFileFromList = (state, action) => {
  const fileList = state.get("uploadFiles");
  return state.set(
    "uploadFiles",
    fileList.filter((o) => o.name !== action.value),
  );
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.INITIAL_ITEM_LIST:
      return addInitialRowToTable(state, action);
    case constants.LOADING_STATUS_CHANGE:
      return state.set("loading", action.status);
    case constants.EMPTY_ITEM_LIST:
      return state.merge({
        baseContractTable: fromJS([]),
        baseContractTotal: 0,
        loading: false,
      });
    case constants.RESET:
      return defaultState;
    case constants.SET_VALUE:
      return state.set(action.name, action.value);
    case constants.CANCEL_ONE_FILE:
      return removeFileFromList(state, action);
    case constants.CHANGE_FILE_LIST:
      return state.set(
        "uploadFiles",
        state.get("uploadFiles").concat(action.files),
      );
    case constants.INITIAL_FILE_LIST:
      if (action.fileList !== undefined && action.fileList) {
        return state.set("editFileList", fromJS(action.fileList));
      } else {
        return state.merge({
          editFileList: fromJS([]),
          uploadFiles: fromJS([]),
        });
      }
    default:
      return state;
  }
};
