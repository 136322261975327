import { STATUS_ARCHIVE } from "../../../utils/constants";
import * as constants from "./constants";
import { fromJS } from "immutable";

const defaultState = fromJS({
  claimSummary: {},
  certSummary: {},
  retentionSummary: {},
  project: {},
  projectType: "current",
  projectList: [],
  claimsReceivedSummary: {},
  claimsReceivedType: 6,
  loading: true,
  loadingList: true,
  claimCertList: [],
  config: {},
  hasProject: false,
  taskList: [],
  systemNotice: [],
  showNotice: false,
  projectGroupTable: [],
  singleProjects: [],
  hasContracts: false,
  expandKeys: [],
  aggPeriod: "current",
});

const setInfo = (state, action) => {
  const project = action.project,
    retention = action.retention,
    claimsReceived = action.claimsReceived,
    claim = action.claim,
    cert = action.cert;

  // Ignore archived contracts
  let hasContracts = false;
  for (const [key] of Object.entries(action.groupTable)) {
    if (action.groupTable[key].status !== STATUS_ARCHIVE) {
      hasContracts = true;
    }
  }

  let status =
    project.draft_contracts.contract_nums +
      project.current_contracts.contract_nums +
      project.archived_contracts.contract_nums +
      project.completed_contracts.contract_nums ===
    0
      ? false
      : true;
  let taskList =
    action.taskList &&
    action.taskList.map((item) => {
      let action = item.task_type.split("_")[0],
        type = item.task_type.split("_")[1];
      if (action === "create") {
        item.value = type === "cert" ? item["claim_total"] : 0;
      } else {
        item.value = item[`${type}_total`];
      }
      item.due_date = item[`${type}_due_date`];
      item.type = type;
      item.action = action;
      return item;
    });
  return state.merge(
    fromJS({
      claimsReceivedSummary: claimsReceived,
      claimSummary: claim,
      certSummary: cert,
      retentionSummary: retention,
      loading: false,
      project: project,
      projectList: project.current_contracts || {},
      projectType: "current",
      hasProject: status,
      taskList: taskList || [],
      showNotice: action.systemNotice.length !== 0,
      systemNotice: action.systemNotice || [],
      hasContracts: hasContracts,
      projectGroupTable: hasContracts ? action.groupTable : [],
    }),
  );
};

const setGroupTable = (state, action) => {
  let projectGroupTable = action.payload;
  return state.merge(
    fromJS({
      projectGroupTable,
    }),
  );
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STATUS:
      return state.set("loading", true);
    case constants.SET_PROJECT_TYPE_OPTION:
      return state
        .set(
          "projectList",
          state.getIn(["project", `${action.value + "_contracts"}`]),
        )
        .set(action.name, action.value);
    case constants.SET_CLAIMS_RECEIVED_TYPE_OPTION:
      return state
        .set(action.name, action.value)
        .set("claimsReceivedSummary", fromJS(action.claimsReceived));
    case constants.SET_INITIAL_DASHBOARD:
      return setInfo(state, action);
    case constants.SET_DATA:
      return state.set(action.name, fromJS(action.value));
    case constants.SET_GROUP_TABLE:
      return setGroupTable(state, action);
    case constants.SET_AGG_TABLE_EXPAND_KEY:
      return state.set(action.name, fromJS(action.value));
    default:
      return state;
  }
};
