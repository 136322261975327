import React, { Component } from "react";
import { connect } from "react-redux";
import debounce from "lodash/debounce";

import {
  Container,
  ProjectBackground,
  ProjectDetailWrapper,
  EditImg,
  ProjectAvatar,
  PageTitle,
  LinkedBanner,
  IconLink,
} from "../styled";

import Menu from "../../../common/breadcrumbs";
import { BlueOutlinedBtn as Button } from "../../../common/button";
import Link from "../../../common/linkWithQuery";
import Loading from "../../../common/loading";
import profile from "../../../utils/profile";

import UploadImageModal from "../components/uploadImageModal";
import {
  Description,
  AdditionalDescription,
} from "../components/projectDescription";
import DateSection from "../components/dateSchedule";
import MemberSection from "../components/projectMember";
import WorkflowSection from "../components/workflow";
import TimezoneSection from "../components/timezone";
import SettingsSection from "../components/settingsSection";
import Navigator, { navTemplate } from "../../../common/navigator";

import RetentionSection from "../components/retention";
import { actionCreators } from "../store";
import { actionCreators as actionCreatorsModal } from "../../../common/modal";
import { fromJS } from "immutable";
import { CERT_ONLY, CLAIM_ONLY, PROJECT, EDIT } from "../../../utils/constants";

import { actionCreators as actionCreatorsAccount } from "../../manageaccount/store";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cropImgVisible: false,
      uploadImage: null,
      isCover: false,
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.calculateDates = debounce(
      (proId, dueDates) => this.props.calculateDueDate(proId, dueDates),
      750,
    );
  }
  handleImageChange = (e) => {
    const file = e.target.files[0];
    const SIZE_LIMIT = 2 * 1024 * 1024;
    if (file.type === "image/png" || file.type === "image/jpeg") {
      if (file.size < SIZE_LIMIT) {
        this.setState({ uploadImage: file }, () => {
          this.setState({ cropImgVisible: true });
        });
      } else {
        this.props.showWarning(
          "Error!",
          "The file is too big! Please choose another one.",
        );
      }
    } else {
      this.props.showWarning(
        "Invalid upload!",
        "Only png or jpg files allowed.",
      );
    }
  };

  render() {
    const {
      projectInfo,
      loading,
      updateImage,
      updateProject,
      updateRetention,
      updateProjectMember,
      allUsers,
      members,
      orgType,
      updatePaymentTerms,
      symbol,
      accountConfig,
    } = this.props;
    const { cropImgVisible, isCover, uploadImage } = this.state;
    const isLinked = projectInfo.get("integration") ? true : false;

    const link = [
      { link: "/projects", text: "Projects" },
      {
        link: `/project/view/${projectInfo.get("id")}`,
        text: projectInfo.get("name"),
      },
    ];

    const claimContractNum = projectInfo.get("claim_contracts")?.size || 0,
      certContractNum = projectInfo.get("cert_contracts")?.size || 0;
    // show claim workflow when account is not cert only or has claim contracts
    // show cert workflow when account is not claim only or has cert contracts
    const showClaimWorkflow = orgType !== CERT_ONLY || claimContractNum > 0,
      showCertWorkflow = orgType !== CLAIM_ONLY || certContractNum > 0;

    const EditImageContainer = ({ className, fileField }) => {
      let inputref;
      return (
        <>
          <input
            type="file"
            accept="image/jpeg,image/jpg,image/png"
            ref={(ref) => (inputref = ref)}
            onChange={(e) => {
              this.setState({ isCover: fileField.includes("cover") });
              this.handleImageChange(e);
            }}
            style={{ display: "none" }}
          />
          <EditImg onClick={() => inputref.click()} className={className} />
        </>
      );
    };

    const ProjectSettingsNavigator = [
      "nav-projectInfo",
      "nav-projectMember",
      "nav-dateSchedule",
      ...(showClaimWorkflow ? ["nav-claimWorkflow"] : []),
      ...(showCertWorkflow ? ["nav-certWorkflow"] : []),
      "nav-timezone",
      "nav-retention",
      "nav-setting",
    ].map((id) => {
      let processed = navTemplate[id];
      if (id.includes("claim") && accountConfig?.getIn(["claim", "noun"]))
        processed.label = processed.label.replace(
          "Claim",
          accountConfig?.getIn(["claim", "noun"]),
        );
      return { id, ...processed };
    });

    if (loading) {
      return <Loading />;
    }

    return (
      <Container>
        <div className="page-breadcrumbs">
          <Menu
            link={link}
            content={"Project Settings"}
            className="page-breadcrumbs-label"
          />
          <Link to={`/project/view/${projectInfo.get("id")}`}>
            <Button title="Back To Project" />
          </Link>
        </div>
        {cropImgVisible && (
          <UploadImageModal
            uploadedImageFile={uploadImage}
            onClose={() => this.setState({ cropImgVisible: false })}
            onSubmit={(blob) =>
              updateImage(
                projectInfo.get("id"),
                isCover ? "cover" : "avatar",
                blob,
              )
            }
          />
        )}
        <Navigator
          data={ProjectSettingsNavigator}
          anchor={{ element: "nav-projectInfo", direction: "right" }}
        />
        <ProjectDetailWrapper className="setting-page" id="nav-projectInfo">
          <ProjectBackground
            src={projectInfo.get("cover_url")}
            className="setting-page"
          >
            <EditImageContainer
              fileField="cover"
              key="cover"
              className={"bottom-position"}
            />
            <ProjectAvatar projectImage={projectInfo.get("avatar_url")}>
              <EditImageContainer
                fileField="project"
                key="project"
                className={"bottom-position"}
              />
            </ProjectAvatar>
          </ProjectBackground>
          <hr />
          <Description
            updateInfo={(data) => updateProject(projectInfo.get("id"), data)}
            projectInfo={projectInfo}
          />
          <hr />
          <AdditionalDescription
            updateInfo={(data) => updateProject(projectInfo.get("id"), data)}
            projectInfo={projectInfo}
          />
          {isLinked && (
            <LinkedBanner>
              <IconLink />
              <div>
                This project is linked from another service so some details are
                provided and cannot be edited.
              </div>
              {/* Hidden for now as webpage does not exist yet
              <div className="webpage-link">
                <a href="">Find out more</a>
              </div>
              */}
            </LinkedBanner>
          )}
        </ProjectDetailWrapper>
        <PageTitle>
          <h2>Project Settings</h2>
          <div>
            Setup these settings to be automatically applied for new contracts.
          </div>
        </PageTitle>
        <MemberSection
          updateMembers={(data, mainContact) =>
            updateProjectMember(projectInfo.get("id"), data, mainContact)
          }
          allUsers={allUsers.toJS()}
          members={members.toJS()}
          level={"project"}
        />
        <DateSection
          {...this.props}
          submitDueTerms={(dueTerms) =>
            updatePaymentTerms(projectInfo.get("id"), dueTerms)
          }
          payTerms={projectInfo.get("payment_terms")}
          calculateExample={(dueDates) =>
            this.calculateDates(projectInfo.get("id"), dueDates)
          }
        />
        {showClaimWorkflow && (
          <WorkflowSection
            workflowType="claim"
            projectId={projectInfo.get("id")}
            workflowInfo={projectInfo.get("claim_workflow_steps")}
          />
        )}
        {showCertWorkflow && (
          <WorkflowSection
            workflowType="cert"
            projectId={projectInfo.get("id")}
            workflowInfo={projectInfo.get("cert_workflow_steps")}
            isLinked={isLinked}
          />
        )}
        <TimezoneSection
          timezone={projectInfo.get("time_zone")}
          updateInfo={(data) =>
            updateProject(projectInfo.get("id"), { time_zone: data })
          }
          level="project"
          {...this.props}
        />
        <RetentionSection
          {...this.props}
          isLinked={isLinked}
          retention={projectInfo.get("retention") || fromJS({})}
          updateInfo={(type, info, scale) =>
            updateRetention(projectInfo.get("id"), type, info, scale)
          }
          hideHelper
          currency={symbol?.get("title")}
        />
        <SettingsSection
          {...this.props}
          submitUpdate={(update) =>
            updateProject(projectInfo.get("id"), { attach_method: update })
          }
        />
      </Container>
    );
  }

  componentDidMount() {
    const proId = this.props.match.params.projectId;
    profile
      .checkPermission(PROJECT, EDIT, proId)
      .then((res) => this.props.readProject(proId, res));
  }
}
const mapStateToProps = (state) => ({
  config: state.getIn(["headers", "config"]),
  profile: state.getIn(["headers", "profile"]),
  loading: state.getIn(["newContractGroup", "loading"]),
  projectInfo: state.getIn(["newContractGroup", "projectInfo"]),
  projectsPreviousAddress: state.getIn([
    "newContractGroup",
    "projectsPreviousAddress",
  ]),
  allUsers: state.getIn(["newContractGroup", "allUsers"]),
  members: state.getIn(["newContractGroup", "members"]),
  dueDates: state.getIn(["newContractGroup", "dueDates"]),
  orgType: state.getIn(["manageAccount", "orgType"]),
  symbol: state.getIn(["config", "accountConfig", "currency"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});

const mapDispatchToProps = (dispatch) => ({
  readProject(proId, isAdmin) {
    dispatch(actionCreators.readProject(proId));
    isAdmin && dispatch(actionCreators.readUsers());
    dispatch(actionCreators.readMembers(proId));
    dispatch(actionCreatorsAccount.getAccountInfo());
  },
  updateImage(proId, type, file) {
    dispatch(actionCreators.uploadImage(proId, type, file));
  },
  updateProject(proId, value) {
    dispatch(actionCreators.projectsPreviousAddress(proId));
    dispatch(actionCreators.updateProject(proId, value));
  },
  updateRetention(proId, type, info, scale) {
    dispatch(actionCreators.updateRetention(proId, type, info, scale));
  },
  updateProjectMember(proId, data, mainContact) {
    dispatch(actionCreators.updateProjectMember(proId, data, mainContact));
  },
  updatePaymentTerms(proId, paymentTerms) {
    dispatch(actionCreators.updatePaymentTermsOptions(proId, paymentTerms));
  },
  calculateDueDate(proId, dueDates) {
    dispatch(actionCreators.calculateDueDate(proId, dueDates));
  },
  showWarning(title, message) {
    dispatch(
      actionCreatorsModal.showModal("alert", {
        open: true,
        title: title,
        message: message,
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
