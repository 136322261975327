import React, { useState, useEffect, useMemo, useRef } from "react";
import { connect } from "react-redux";

import { InvitationDetailWrap, SearchIcon } from "../styled";

import Input from "../../../common/form";
import AddressSearch from "../../../common/mapbox/addressSearch";
import MapboxDisplay from "../../../common/mapbox/mapboxDisplay";
import { StateWarningModal } from "../../../common/config/stateConfigInput";
import ErrorMsg from "../../../common/missingRequiredBanner";
import { validateEmailAddress, toTitleCase } from "../../../utils/helper";
import { STATUS_ACTIVE, COUNTRY_AU } from "../../../utils/constants";
import Modal from "../../project/components/modal";

import { actionCreators } from "../store";
import { actionCreators as configActionCreators } from "../../../common/config/store";

const SelfClaimCert = (props) => {
  const {
    open,
    otherParty,
    setSelfInvitation,
    setOpen,
    companyList,
    contactList,
    invitedInfo,
    readContacts,
    readCompanies,
    accountConfig,
  } = props;
  const { proId, contractId } = props.match.params;
  const desiredOptionValue = "new";
  let sectionLabel = { title: "self certifying", contact: "payer" };
  if (otherParty === "Claimer") {
    sectionLabel = {
      title: `self ${accountConfig?.getIn(["claim", "noun"])}`,
      contact: "payee",
      notice: `use the self-${accountConfig
        ?.getIn(["claim", "noun"])
        ?.toLowerCase()} feature`,
    };
  }
  const claimerWord = accountConfig?.getIn(["claim", "name"]);

  const [companyInfo, setCompanyInfo] = useState({});
  const [companyAddress, setCompanyAddress] = useState({});
  const [contactInfo, setContactInfo] = useState({});
  const [company, setCompany] = useState("");
  const [contactOption, setContactOption] = useState([]);
  const [contact, setContact] = useState("");
  const [disableFields, setDisableFields] = useState({
    company: true,
    contactDropdown: true,
    contact: true,
  });
  const [placeholderMessage, setPlaceholderMessage] = useState({
    company: "Search for company...",
    contact: `Select ${sectionLabel.contact} company first`,
  });
  const [existingContact, setExistingContact] = useState(false);
  const [existingCompany, setExistingCompany] = useState(false);
  const [errors, setErrors] = useState({});
  const [validating, setValidating] = useState(false);

  const selectCompanyRef = useRef(null);
  const selectContactRef = useRef(null);
  const companyNameRef = useRef(null);
  const streetAddressRef = useRef(null);
  const stateRef = useRef(null);
  const nameRef = useRef(null);
  const modalErrorsRef = useRef(null);

  const requiredFieldsAndRefs = [
    {
      field: "company",
      name: toTitleCase(sectionLabel.contact) + " Company",
      ref: selectCompanyRef,
      missing: errors.company,
    },
    {
      field: "contact",
      name: toTitleCase(sectionLabel.contact) + " Contact",
      ref: selectContactRef,
      missing: errors.contact,
    },
    {
      field: "entity_name",
      name: "Company Name",
      ref: companyNameRef,
      missing: errors.entity_name,
    },
    {
      field: "street_address",
      name: accountConfig.getIn(["address", "street"]),
      ref: streetAddressRef,
      missing: errors.street_address,
    },
    {
      field: "state",
      name: accountConfig.getIn(["address", "state"]),
      ref: stateRef,
      missing: errors.state,
    },
    {
      field: "name",
      name: "Full Name",
      ref: nameRef,
      missing: errors.name,
    },
  ];

  const originalCompanyOption = useMemo(
    () =>
      companyList
        .map((ele) => ({
          label: ele.get("entity_name"),
          value: ele.get("entity_id"),
        }))
        .concat({
          label: `Create a new ${sectionLabel.contact} company`,
          value: "new",
        }),
    [companyList, sectionLabel.contact],
  );

  const [companyOption, setCompanyOption] = useState(originalCompanyOption);

  useEffect(() => {
    readCompanies();
  }, [readCompanies]);

  useEffect(() => {
    // Create a new copy of the companyOption array
    const updatedCompanyOption = [...originalCompanyOption];
    // Find the index of the desired option in the updatedCompanyOption array
    const desiredCompanyOptionIndex = updatedCompanyOption.findIndex(
      (option) => option.value === desiredOptionValue,
    );
    // If the desired option is found, move it to the front of the array
    if (desiredCompanyOptionIndex !== -1) {
      const desiredCompanyOption = updatedCompanyOption.splice(
        desiredCompanyOptionIndex,
        1,
      )[0];
      updatedCompanyOption.unshift(desiredCompanyOption);
    }
    // Sort the remaining options alphabetically by label
    const otherOptions = updatedCompanyOption.slice(1); // Exclude the desired option
    otherOptions.sort((a, b) => a.label.localeCompare(b.label));
    // Update the companyOption state variable with the modified array
    setCompanyOption([updatedCompanyOption[0], ...otherOptions]);
  }, [companyList, originalCompanyOption]);

  useEffect(() => {
    //update contact dropdown options after selected a different company
    let contactOption = contactList.map((ele) => ({
      label: ele.get("contact_name"),
      value: ele.get("contact_id"),
    }));
    contactOption = contactOption.concat({
      label: `Create a new ${sectionLabel.contact} contact`,
      value: "new",
    });

    // Create a new copy of the contactOption array
    const updatedContactOption = [...contactOption];

    // Find the index of the desired option in the updatedContactOption array
    const desiredContactOptionIndex = updatedContactOption.findIndex(
      (option) => option.value === desiredOptionValue,
    );

    // If the desired option is found, move it to the front of the array
    if (desiredContactOptionIndex !== -1) {
      const desiredContactOption = updatedContactOption.splice(
        desiredContactOptionIndex,
        1,
      )[0];
      updatedContactOption.unshift(desiredContactOption);
    }

    // Sort the remaining options alphabetically by label
    const otherOptions = updatedContactOption.slice(1); // Exclude the desired option
    otherOptions.sort((a, b) => a.label.localeCompare(b.label));

    // Update the contactOption state variable with the modified array
    setContactOption([updatedContactOption[0], ...otherOptions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactList]);

  useEffect(() => {
    setCompanyInfo({ ...companyInfo, ...companyAddress });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyAddress]);

  useEffect(() => {
    if (invitedInfo) {
      // initialize self-invitation info based on contract info
      readContacts(invitedInfo.entity_id);
      setCompany(invitedInfo.entity_id);
      setContact(invitedInfo.id);
      setCompanyInfo(invitedInfo);
      setContactInfo(invitedInfo);
      invitedInfo.entity_id && setExistingCompany(true);
      invitedInfo.id && setExistingContact(true);
    }
    if (!open) {
      setCompany("");
      setCompanyInfo({});
      setCompanyAddress({});
      setContactOption([]);
      setContact("");
      setDisableFields({
        company: true,
        contactDropdown: true,
        contact: true,
      });
      setExistingContact(false);
      setExistingCompany(false);
      setErrors({});
      setValidating(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitedInfo, readContacts, open]);

  useEffect(() => {
    let disableCompany = true,
      disableContactDropdown = true,
      disableContact = true;

    if (company === "new") {
      // new company
      disableCompany = false;
      disableContactDropdown = false;
      disableContact = contact === "new" ? false : true;
    } else if (!company) {
      // no company picked
      disableCompany = true;
      disableContactDropdown = true;
      disableContact = true;
    } else {
      // company picked
      disableCompany = true;
      disableContactDropdown = false;
      disableContact = contact === "new" ? false : true;
    }
    setDisableFields({
      company: disableCompany,
      contactDropdown: disableContactDropdown,
      contact: disableContact,
    });

    // set contact placeholder message
    if (company) {
      setPlaceholderMessage({
        ...placeholderMessage,
        contact: "Select contact...",
      });
    } else {
      setPlaceholderMessage({
        ...placeholderMessage,
        contact: `Select ${sectionLabel.contact} company first`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, contact]);
  const onValidate = (company, contact, companyInfo, contactInfo) => {
    let errors = {};
    if (company === "new") {
      ["entity_name", "street_address", "state"].forEach((element) => {
        if (
          element === "state" &&
          companyInfo.country === COUNTRY_AU &&
          !companyInfo.state
        ) {
          errors[element] = "Required";
          companyInfo[element] = "";
        }
        if (element !== "state" && !companyInfo[element]) {
          errors[element] = "Required";
          companyInfo[element] = "";
        }
      });
    } else {
      errors = {};
    }

    if (contact === "new") {
      ["name"].forEach((element) => {
        if (
          element === "email" &&
          contactInfo?.email &&
          !validateEmailAddress(contactInfo?.email)
        ) {
          errors[element] = "Required";
        }
        if (element !== "email" && !contactInfo[element]) {
          errors[element] = "Required";
          contactInfo[element] = "";
        }
      });
    } else {
      errors = {};
    }

    if (!company) {
      errors["company"] = "Required";
    }

    if (!contact) {
      errors["contact"] = "Required";
    }

    setErrors(errors);
    return errors;
  };

  const validate = async () => {
    var valid = true;
    const errors = onValidate(company, contact, companyInfo, contactInfo);
    valid &= Object.keys(errors).length === 0;
    if (!valid) {
      modalErrorsRef.current &&
        modalErrorsRef.current.scrollIntoView({ behavior: "smooth" });
    }
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidating(true);

    if (!(await validate())) return;

    setSelfInvitation(
      proId,
      contractId,
      company,
      contact,
      companyInfo,
      contactInfo,
    );
    setOpen(false);
  };

  const selectCompany = (companyId, hasContact) => {
    if (!companyId) {
      setCompanyInfo({});
      setPlaceholderMessage({
        ...placeholderMessage,
        contact: `Select ${sectionLabel.contact} company first`,
      });
      setContactOption([]);
      selectContact("");
      setExistingCompany(false);
    } else if (companyId !== "new") {
      // select an existing company
      // then set company info and request that company's contacts
      let companyInfo = companyList.find(
        (ele) => ele.get("entity_id") === companyId,
      );
      if (companyInfo) {
        setExistingContact(true);
        setContact("");
        setContactInfo({});
        setContactOption([]);
        setCompanyInfo(companyInfo.toJS());
        props.readContacts(companyId);
        setExistingCompany(true);
      }
    } else {
      // if choose to create a new company, empty that company info and contact list

      setCompanyInfo({});
      setExistingCompany(false);
      setContactOption([
        { label: `Create a new ${sectionLabel.contact} contact`, value: "new" },
      ]);
      // make new contact
      if (!hasContact) {
        setContactInfo({});
        setExistingContact(false);
        setContact("new");
      }
    }
    setCompany(companyId);
    validating && onValidate(companyId, contact, companyInfo, contactInfo);
  };

  const selectContact = (contactId) => {
    setContact(contactId);

    // clear contact details if no contact or making a new one
    if (!contactId || contactId === "new") {
      setContactInfo({});
      setExistingContact(false);
    } else {
      // if select an existing contact
      // then set input value to that selected contact info
      let contactInfo = contactList.find(
        (ele) => ele.get("contact_id") === contactId,
      );
      if (contactInfo) {
        setContactInfo({
          name: contactInfo.get("contact_name"),
          email: contactInfo.get("contact_email"),
          phone: contactInfo.get("contact_tel"),
        });
        setExistingContact(true);
      }
    }
    validating && onValidate(company, contactId, companyInfo, contactInfo);
  };

  const handleChange = (name, event) => {
    const newCompanyInfo = {
      ...companyInfo,
      [name]: event?.value ? event?.value : event.target.value,
    };
    setCompanyInfo(newCompanyInfo);
    validating && onValidate(company, contact, newCompanyInfo, contactInfo);
  };

  const handleContactChange = (name, event) => {
    const newContactInfo = {
      ...contactInfo,
      [name]: event && event.target ? event.target.value : event,
    };
    setContactInfo(newContactInfo);
    validating && onValidate(company, contact, companyInfo, newContactInfo);
  };

  // always return "create new" option (along with those that matches search)
  const customFilter = (option, searchText) => {
    return (
      option.value === desiredOptionValue ||
      option.label.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  return (
    <Modal
      title={sectionLabel.title}
      open={open}
      close={() => setOpen(false)}
      submit={(e) => {
        handleSubmit(e);
      }}
    >
      <span ref={modalErrorsRef}></span>
      <ErrorMsg fieldsAndRefs={requiredFieldsAndRefs} />
      <div>
        If you {sectionLabel.notice || `are ${sectionLabel.title}`}, please fill
        in the {sectionLabel.contact} details below.
      </div>
      <h1 className="form-container">
        {otherParty === "Claimer" ? claimerWord : otherParty} Company
      </h1>
      <InvitationDetailWrap>
        <div>
          <Input
            label={sectionLabel.contact + " Company"}
            ref={selectCompanyRef}
            field="search"
            required={true}
            error={errors?.company}
            options={companyOption}
            onChange={(e) => selectCompany(e?.value)}
            value={company}
            width={424}
            isClearable={true}
            placeholder={placeholderMessage.company}
            control={<SearchIcon />}
            separateFirstOption
            filterOption={customFilter}
            marginBottom="16px"
          />
          {company === "new" && (
            <div className="has-big-margin">
              <MapboxDisplay
                setAddress={(data) => {
                  data.postal_code = data.postcode;
                  setCompanyAddress(data);
                }}
              >
                <AddressSearch
                  hasRestrictCountry
                  setAddress={(data) => {
                    data.postal_code = data.postcode;
                    setCompanyAddress(data);
                  }}
                />
              </MapboxDisplay>
            </div>
          )}
        </div>
        <div className="detail">
          <Input
            label="Company Name"
            ref={companyNameRef}
            required={true}
            error={errors.entity_name}
            value={companyInfo.entity_name || ""}
            onChange={(e) => handleChange("entity_name", e)}
            disabled={existingCompany}
            marginBottom="16px"
          />

          <div className="gst-field">
            <Input
              label={accountConfig?.getIn(["gst_number", "title"])}
              value={companyInfo.gst_no || ""}
              onChange={(e) => handleChange("gst_no", e)}
              disabled={existingCompany}
              marginBottom="16px"
            />
            {accountConfig?.getIn(["buyer_created_tax_invoices", "status"]) ===
              STATUS_ACTIVE && (
              <div className="sub-text">
                Used for{" "}
                {accountConfig?.getIn(["buyer_created_tax_invoices", "title"])}
              </div>
            )}
          </div>
          <Input
            label={accountConfig.getIn(["address", "street"])}
            ref={streetAddressRef}
            required={true}
            error={errors.street_address}
            value={companyInfo.street_address || ""}
            onChange={(e) => handleChange("street_address", e)}
            disabled={existingCompany}
            marginBottom="16px"
          />
          <Input
            label={accountConfig.getIn(["address", "suburb"])}
            className="mini-width has-margin"
            value={companyInfo.suburb || ""}
            onChange={(e) => handleChange("suburb", e)}
            disabled={existingCompany}
            marginBottom="16px"
          />
          <Input
            label={accountConfig.getIn(["address", "city"])}
            className="mini-width"
            value={companyInfo.city || ""}
            onChange={(e) => handleChange("city", e)}
            disabled={existingCompany}
            marginBottom="16px"
          />
          <Input
            label={accountConfig.getIn(["address", "postcode"])}
            className="mini-width has-margin"
            value={companyInfo.postal_code || ""}
            onChange={(e) => handleChange("postal_code", e)}
            disabled={existingCompany}
            marginBottom="16px"
          />
          <span ref={stateRef}>
            {accountConfig.getIn(["country", "code"]) !== COUNTRY_AU ||
            existingCompany ? (
              <Input
                label={accountConfig.getIn(["address", "state"])}
                className="mini-width"
                value={companyInfo.state || ""}
                onChange={(e) => handleChange("state", e)}
                disabled={existingCompany}
                marginBottom="16px"
              />
            ) : (
              <Input
                label={accountConfig.getIn(["address", "state"])}
                className="mini-width"
                value={companyInfo.state || ""}
                onChange={(e) => handleChange("state", e)}
                disabled={existingCompany}
                options={accountConfig.get("state")?.toJS()}
                field={"dropdown"}
                placeholder={`Select ${accountConfig.getIn(["address", "state"])}`}
                width="196px"
                marginBottom="16px"
                required={true}
                error={errors.state}
              />
            )}
          </span>
          <Input
            field="lock-country"
            value={companyInfo.country}
            width="425px"
            marginBottom="16px"
          />
          {!existingCompany && (
            <StateWarningModal
              country={companyInfo.country}
              state={companyInfo.state}
              showError={errors.state === "Required"}
            />
          )}
        </div>
      </InvitationDetailWrap>
      <h1 className="form-container">
        {otherParty === "Claimer" ? claimerWord : otherParty} Contact
      </h1>
      <InvitationDetailWrap className="form-container">
        <Input
          label={sectionLabel.contact + " Contact"}
          ref={selectContactRef}
          field="search"
          required={true}
          error={errors.contact}
          options={contactOption}
          onChange={(e) => selectContact(e?.value)}
          value={contact}
          width={424}
          disabled={disableFields.contactDropdown}
          placeholder={placeholderMessage.contact}
          isClearable={true}
          control={<SearchIcon />}
          separateFirstOption
          marginBottom="16px"
          filterOption={customFilter}
        />
        <div className="detail">
          <Input
            label="Full Name"
            ref={nameRef}
            required={true}
            error={errors.name}
            value={contactInfo.name || ""}
            onChange={(e) => handleContactChange("name", e)}
            disabled={existingContact}
            marginBottom="16px"
          />

          <Input
            type="email"
            label={"Email"}
            value={contactInfo.email || ""}
            onChange={(e) => handleContactChange("email", e)}
            disabled={disableFields.contact}
            marginBottom="16px"
          />
          <Input
            field="telephone"
            label="Phone"
            addPhoneNumber={contactInfo.phone}
            handleOnChange={(phone) => handleContactChange("phone", phone)}
            wrapperClassName="phone-wrap"
            disabled={disableFields.contact}
            marginBottom="16px"
          />
        </div>
      </InvitationDetailWrap>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  companyList: state.getIn(["contract", "companyList"]),
  contactList: state.getIn(["contract", "contactList"]),
  countriesList: state.getIn(["config", "countriesList"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    readCompanies() {
      dispatch(actionCreators.readCompanies());
    },
    readContacts(companyId) {
      dispatch(actionCreators.readCompanyContacts(companyId));
    },
    setSelfInvitation(
      proId,
      contractId,
      company,
      contact,
      companyInfo,
      contactInfo,
    ) {
      dispatch(
        actionCreators.setSelfInvitation(
          proId,
          contractId,
          company,
          contact,
          companyInfo,
          contactInfo,
        ),
      );
    },
    getCountries() {
      dispatch(configActionCreators.readCountries());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelfClaimCert);
