import React, { Component } from "react";
import { connect } from "react-redux";
import { fromJS } from "immutable";

import ReviewCert from "./pages/reviewCert";
import ApprovedCert from "./pages/reviewApprovedCert";

import Loading from "../../common/loading";
import profile from "../../utils/profile";
import {
  CONTRACT,
  CLAIM_CERT,
  STATUS_DRAFT,
  STATUS_APPROVED,
} from "../../utils/constants";

import { actionCreators } from "./store";
import { actionCreators as actionCreatorsContract } from "../contract/store";

class index extends Component {
  state = {
    allowCert: true,
  };
  render() {
    const { certInfo } = this.props;
    let readOnly = !this.state.allowCert;
    const issueInfo = fromJS({
      createDate: certInfo?.get("cert_create_date"),
      createUser: certInfo?.get("create_user_name"),
      issueDate: certInfo?.get("cert_issued_date"),
      issueUser: certInfo?.get("issue_user_name"),
    });
    return (
      <>
        {certInfo.get("cert_status") ? (
          <>
            {certInfo.get("cert_status") === STATUS_DRAFT && (
              <ReviewCert
                {...this.props}
                readOnly={readOnly}
                issueInfo={issueInfo}
              />
            )}
            {certInfo.get("cert_status") === STATUS_APPROVED && (
              <ApprovedCert
                {...this.props}
                readOnly={readOnly}
                issueInfo={issueInfo}
              />
            )}
          </>
        ) : (
          <Loading title="Loading info" />
        )}
      </>
    );
  }
  componentDidMount() {
    const { proId, contractId, claimId, contractType } =
      this.props.match.params;
    this.props.getAllEntityList();

    profile
      .checkPermission(CONTRACT, CLAIM_CERT, proId, contractId)
      .then((res) => {
        this.props.readProjectInfo(proId);
        // contract info
        this.props.readClaim(proId, contractId, claimId, contractType);
        this.setState({ allowCert: res });
      });

    //set contract config
    this.props.setContractConfig(proId, contractId);
  }

  componentDidUpdate(prevProps) {
    // Check if propValue has changed
    if (this.props.componentKey !== prevProps.componentKey) {
      const { proId, contractId, claimId, contractType } =
        this.props.match.params;
      this.props.readProjectInfo(proId);
      // contract info
      this.props.readClaim(proId, contractId, claimId, contractType);
    }
  }

  componentWillUnmount() {
    this.props.resetInfo();
  }
}
const mapStateToProps = (state) => ({
  projectInfo: state.getIn(["makeacert", "projectInfo"]),
  accountId: state.getIn(["headers", "profile", "payclaim_account_id"]),
  getInfoReady: state.getIn(["makeacert", "getInfoReady"]),
  certInfo: state.getIn(["makeacert", "projectlist"]),
  certId: state.getIn(["makeacert", "certId"]),

  baseContractTable: state.getIn(["makeacert", "baseContractTable"]),
  baseCertTotal: state.getIn(["makeacert", "baseCertTotal"]),
  baseCertTotalExcludeRetention: state.getIn([
    "makeacert",
    "baseCertTotalExcludeRetention",
  ]),
  baseThisCertTotal: state.getIn(["makeacert", "baseThisCertTotal"]),

  variationsTable: state.getIn(["makeacert", "variationsTable"]),
  variationCertTotal: state.getIn(["makeacert", "variationCertTotal"]),
  variationCertTotalExcludeRetention: state.getIn([
    "makeacert",
    "variationCertTotalExcludeRetention",
  ]),
  variationThisCertTotal: state.getIn(["makeacert", "variationThisCertTotal"]),

  paidValue: state.getIn(["makeacert", "paidValue"]),
  previousType: state.getIn(["makeacert", "previousType"]),
  lessRetention: state.getIn(["makeacert", "lessRetention"]),
  claimDetail: state.getIn(["makeacert", "claimDetail"]),
  certificate_number: state.getIn(["makeacert", "certificate_number"]),
  emailToList: state.getIn(["makeacert", "emailToList"]),
  entityList: state.getIn(["contract", "companyList"]),
  emailSubject: state.getIn(["makeacert", "emailSubject"]),
  isSendEmail: state.getIn(["makeacert", "isSendEmail"]),
  cert_attachments: state.getIn(["makeacert", "cert_attachments"]),
  shared_cert_attachments: state.getIn([
    "makeacert",
    "shared_cert_attachments",
  ]),
  attachedEmailFiles: state.getIn(["makeacert", "attachedEmailFiles"]),
  isAuditAttached: state.getIn(["makeacert", "isAuditAttached"]),
  approvalList: state.getIn(["makeacert", "approvalList"]),
  sendingEmail: state.getIn(["makeacert", "sendingEmail"]),
  exceedAttachLimit: state.getIn(["makeacert", "exceedAttachLimit"]),

  // material info
  materialsTable: state.getIn(["makeacert", "materialsTable"]),
  materialsTotal: state.getIn(["makeacert", "materialsTotal"]),
  materialsTotalExcludeRetention: state.getIn([
    "makeacert",
    "materialsTotalExcludeRetention",
  ]),
  componentKey: state.getIn(["makeacert", "componentKey"]),
});

const mapDispatchToProps = (dispatch) => ({
  readClaim(proId, contractId, claimId, contractType) {
    dispatch(
      actionCreators.readClaim(contractId, claimId, contractType, false, proId),
    );
  },
  readProjectInfo(proId) {
    dispatch(actionCreators.readProject(proId));
  },
  getAllEntityList() {
    dispatch(actionCreatorsContract.readCompanies());
  },
  handleSetChipData(data, field) {
    dispatch(actionCreators.removeEmail(data, field));
  },
  updateComment(
    contractType,
    contractId,
    claimId,
    certId,
    action,
    content,
    comment_id,
    lineId,
    proId,
  ) {
    dispatch(
      actionCreators.updateComment(
        contractType,
        contractId,
        claimId,
        certId,
        action,
        content,
        comment_id,
        lineId,
        proId,
      ),
    );
  },
  deleteFile(proId, contractId, claimId, certId, fileId) {
    dispatch(
      actionCreators.deleteFile(proId, contractId, claimId, certId, fileId),
    );
  },
  downloadFile(fileId, fileName, token, accId, isDownload) {
    dispatch(
      actionCreators.downloadFile(fileId, fileName, token, accId, isDownload),
    );
  },
  downloadPdf(proId, contractId, claimId, certId, isDownload) {
    dispatch(
      actionCreators.downloadPdf(
        proId,
        contractId,
        claimId,
        certId,
        isDownload,
      ),
    );
  },
  changeSharedFiles(
    proId,
    contractType,
    contractId,
    claimId,
    certId,
    fileId,
    isAdd,
  ) {
    dispatch(
      actionCreators.changeSharedFiles(
        proId,
        contractType,
        contractId,
        claimId,
        certId,
        fileId,
        isAdd,
      ),
    );
  },
  selectAttachedFile(name, value, field) {
    dispatch(actionCreators.selectAttachedFile(name, value, field));
  },
  setPdfAudit(proId, contractId, claimId, certId, value) {
    dispatch(
      actionCreators.setAuditSetting(proId, contractId, claimId, certId, value),
    );
  },
  resetInfo() {
    dispatch(actionCreators.resetStage());
  },
  setContractConfig(proId, contractId) {
    dispatch(actionCreators.setContractConfig(proId, contractId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
