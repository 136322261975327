import React, { Component } from "react";
import { connect } from "react-redux";
import { fromJS } from "immutable";

import { NoticeWrap } from "./styled";
import {
  Container,
  ProjectBackground,
  ProjectDetailWrapper,
  ProjectContentWrapper,
} from "../project/styled";
import { ContractDetailWrapper } from "../contract/styled";

import {
  BlueOutlinedBtn as Button,
  GreenWhiteBtn as AcceptBtn,
  RedBtn as DeclineBtn,
} from "../../common/button";
import Link from "../../common/linkWithQuery";
import Menu from "../../common/breadcrumbs";
import Loading from "../../common/loading";

import LineItem from "../contract/components/lineItem";
import NoteSection from "../contract/components/noteSection";
import DateSchedule from "../contract/components/dateSchedule";
import TimezoneSection from "../project/components/timezone";
import RetentionSection from "../project/components/retention";

import SelectionModal from "./components/selectionModal";
import ContractDetails from "./components/contractDetail";

import { actionCreators } from "./store";
import { actionCreators as actionCreatorsConfig } from "../../common/config/store";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      expandTreeKeys: [],
    };
  }
  openSelection = (value) => {
    this.setState({
      open: value,
    });
  };
  updateExpandTreeKeys = (keys) => {
    this.setState({ expandTreeKeys: keys });
  };
  render() {
    const {
      declineInvite,
      config,
      contractInfo,
      projectInfo,
      accountList,
      loading,
      symbol,
      accountConfigClaim,
    } = this.props;

    const inviteId = this.props.match.params.code;
    const invitationType = !contractInfo.get("cert_account")
      ? "Certifier"
      : accountConfigClaim?.get("name");
    const inviteAccount = !contractInfo.get("cert_account")
      ? contractInfo.get("claim_account")
      : contractInfo.get("cert_account");

    let hasRetention =
      contractInfo && contractInfo.get("retention") ? true : false;

    var link = [{ link: "/projects", text: "Projects" }];
    let paymentTermsOptions = {};
    if (typeof config.payclaim !== "undefined") {
      paymentTermsOptions = {
        pay_cert_options: config.payclaim["pay_cert_options"],
        pay_claim_options: config.payclaim["pay_claim_options"],
        payment_options: config.payclaim["payment_options"],
      };
    }

    if (loading) {
      return <Loading />;
    }

    return (
      <Container>
        <div className="page-breadcrumbs">
          <Menu
            link={link}
            content={projectInfo.get("name")}
            className="page-breadcrumbs-label"
          />
          <Link to={"/projects"}>
            <Button title="Back To Projects" />
          </Link>
        </div>
        <ProjectBackground src={projectInfo.get("cover_url")} />
        <ProjectDetailWrapper>
          <ProjectContentWrapper projectImage={projectInfo.get("avatar_url")}>
            <div className="project-image" />
            <div className="content">
              <div className="title">{projectInfo.get("name")}</div>
              {projectInfo.get("number") && (
                <div className="number">{projectInfo.get("number")}</div>
              )}
              {projectInfo.get("description") && (
                <div className="des">{projectInfo.get("description")}</div>
              )}
            </div>
          </ProjectContentWrapper>
        </ProjectDetailWrapper>
        {accountList.size > 0 && (
          <NoticeWrap>
            <div className="title">
              You’re Invited! <div className="icon" />
            </div>
            <div className="content">
              You have been invited to be a <b>{invitationType}</b> on this
              contract. <br />
              Review the details of the contract below before accepting or
              declining the contract.
            </div>
            <DeclineBtn
              title="Decline"
              onClick={() => declineInvite(inviteId)}
            />
            <AcceptBtn
              title="Accept"
              onClick={() => this.openSelection(true)}
            />
          </NoticeWrap>
        )}
        {accountList.size === 0 && (
          <NoticeWrap isWarning>
            <div className="title">Error proceeding with the invite.</div>
            <div className="content">No account associated to you.</div>
          </NoticeWrap>
        )}

        <SelectionModal
          open={this.state.open}
          setOpen={this.openSelection}
          invitedId={inviteId}
          invitationType={invitationType}
          accountList={accountList}
          inviteAccount={inviteAccount}
        />

        <ContractDetailWrapper style={{ marginTop: 0 }}>
          <ContractDetails {...this.props} />{" "}
        </ContractDetailWrapper>
        <DateSchedule {...this.props} options={paymentTermsOptions} readOnly />
        <TimezoneSection
          timezone={contractInfo.get("time_zone")}
          readOnly
          level="contract"
          {...this.props}
        />
        <LineItem
          {...this.props}
          itemsType={"baseContract"}
          expandTreeKeys={this.state.expandTreeKeys}
          setExpandKeys={this.updateExpandTreeKeys}
          readOnly
        />
        <LineItem
          {...this.props}
          itemsType={"variations"}
          expandTreeKeys={this.state.expandTreeKeys}
          setExpandKeys={this.updateExpandTreeKeys}
          readOnly
        />
        {hasRetention && (
          <RetentionSection
            {...this.props}
            retention={contractInfo.get("retention") || fromJS({})}
            readOnly
            currecy={symbol?.title}
          />
        )}
        <NoteSection
          side={this.props.contractInfo.get("claim_account") ? "cert" : "claim"}
          info={this.props.contractInfo}
          readOnly
        />
      </Container>
    );
  }
  componentDidMount() {
    const inviteId = this.props.match.params.code;
    this.props.readAllInfo(inviteId);
    this.props.loadConfig();
  }
  componentDidUpdate() {
    // reload config if it's still undefined
    if (typeof this.props.accountConfig === "undefined") {
      this.props.loadConfig();
    }
  }
  componentWillUnmount() {
    this.props.resetInfo();
  }
}

const mapStateToProps = (state) => ({
  config: state.getIn(["headers", "config"]),
  contractInfo: state.getIn(["invite", "contractInfo"]),
  projectInfo: state.getIn(["invite", "projectInfo"]),
  accountList: state.getIn(["headers", "accountList"]),
  loading: state.getIn(["invite", "loading"]),
  symbol: state.getIn(["headers", "config", "countries_config", "currency"]),
  accountConfig: state.getIn(["config", "accountConfig", "currency", "title"]),
  accountConfigClaim: state.getIn(["config", "accountConfig", "claim"]),
});
const mapDispatchToProps = (dispatch) => {
  return {
    readAllInfo(inviteId) {
      dispatch(actionCreators.readAllInfo(inviteId));
    },
    declineInvite(inviteId) {
      dispatch(actionCreators.declineInvitation(inviteId));
    },
    resetInfo() {
      dispatch(actionCreators.resetInfo());
    },
    loadConfig() {
      dispatch(actionCreatorsConfig.getAccountConfig());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(index);
