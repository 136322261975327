import React from "react";
import { FormGroup } from "../../../common/form/styled";
import { ContentWrapper, InputWrapper } from "../styled";
import Input from "../../../common/form";
import {
  BlueWhiteBtn as SearchBtn,
  BlueOutlinedBtn as ClearBtn,
} from "../../../common/button";

function sortStatusOption(arr) {
  if (arr !== undefined) {
    arr = arr.sort((a, b) => b.value > a.value);
  }
  return arr;
}

const LineItemForm = ({ option, optList, onChange, search, reset }) => {
  let S1 = "contract_status",
    S2 = "type",
    S3 = "type_status",
    S4 = "date",
    S5 = "start_date",
    S6 = "end_date";
  return (
    <div className="form_body">
      <Input
        label="Project Status"
        name={S1}
        width="180px"
        field="dropdown"
        value={option[S1]}
        options={sortStatusOption(optList[S1 + "_options"])}
        onChange={(e) => onChange(S1, e.value)}
      />
      <InputWrapper className="first">
        <Input
          label="Type"
          name={S2}
          width="120px"
          field="dropdown"
          value={option[S2]}
          options={optList[S2 + "_options"]}
          onChange={(e) => onChange(S2, e.value)}
        />
        <Input
          label="Status"
          name={S3}
          width="180px"
          field="dropdown"
          value={option[S3]}
          options={optList[`${option["type"]}_options`]}
          onChange={(e) => onChange(S3, e.value)}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          label="Filter by"
          name={S4}
          width="180px"
          field="dropdown"
          value={option[S4]}
          options={optList[S4 + "_options"]}
          onChange={(e) => onChange(S4, e.value)}
        />
        <Input
          label="Start Date"
          name={S5}
          width="152px"
          field="date"
          initialValue={option[S5]}
          handleChange={(e) => onChange(S5, e)}
          radius={"4px"}
        />
        <Input
          label="End Date"
          name={S6}
          width="152px"
          field="date"
          initialValue={option[S6]}
          handleChange={(e) => onChange(S6, e)}
          radius={"4px"}
        />
      </InputWrapper>
      <InputWrapper className="last">
        <SearchBtn title="Search" height="44px" onClick={search} />
        <ClearBtn title="Clear" height="44px" onClick={reset} />
      </InputWrapper>
    </div>
  );
};
export default function searchBar(props) {
  return (
    <ContentWrapper className="cc_step1">
      <FormGroup>
        <LineItemForm
          option={props.option}
          optList={props.optList}
          onChange={props.selectOption}
          reset={props.clear}
          search={props.search}
        />
      </FormGroup>
    </ContentWrapper>
  );
}
