import React, { useState, useEffect, Fragment } from "react";

import { MemberWrapper, DelButton } from "../styled";
import { toTitleCase } from "../../../utils/helper";
import {
  OWNER_ROLE,
  ADMIN_ROLE,
  VIEW_ROLE,
  PROJECT_ADMIN,
  PROJECT_VIEW,
  NONE_ROLE,
  CONTRACT,
  CONTRACT_VIEW,
  CONTRACT_ADMIN,
} from "../../../utils/constants";

import { AddBtn as AddButton } from "../../../common/button";
import Input from "../../../common/form";
import Modal from "./modal";

// members display order
const priority = {
  [ADMIN_ROLE]: 1,
  [VIEW_ROLE]: 2,
  [PROJECT_ADMIN]: 3,
  [PROJECT_VIEW]: 4,
  [CONTRACT_ADMIN]: 5,
  [CONTRACT_VIEW]: 6,
};

const roleList = (level, role, originalRole) => {
  const selectedRole =
    originalRole && priority[originalRole] < priority[role]
      ? originalRole
      : role;

  // account view can only be changed to project/contract admin
  const roles = [
    { label: `${toTitleCase(level)} Admin`, value: `${level}_admin` },
    selectedRole === VIEW_ROLE
      ? { label: `Account Viewer`, value: VIEW_ROLE }
      : { label: `${toTitleCase(level)} View`, value: `${level}_view` },
  ];

  // allow project viewers to become contract admins
  if (level === CONTRACT && role === PROJECT_VIEW) {
    roles.push({ label: `Project Viewer`, value: PROJECT_VIEW });
    roles.splice(
      roles.findIndex((role) => role.value === CONTRACT_VIEW),
      1,
    );
  }

  return roles;
};

const Member = ({
  isAdmin,
  person,
  options,
  extraOptions,
  setMember,
  showDelete,
  deleteMember,
  level,
  keyContact,
  setKeyContact,
}) => {
  const isChecked = keyContact ? keyContact.id === person.id : false;

  return (
    <div className="list-wrap">
      <input
        type="radio"
        id={person.id}
        name="main_contact"
        checked={isChecked}
        onChange={(e) => {
          if (e.target.checked) {
            setKeyContact(person);
          }
        }}
      />
      {isAdmin ? (
        <Fragment>
          <Input value={person.name} disabled />
          <Input value={person.role_description} disabled />
        </Fragment>
      ) : (
        <Fragment>
          <Input
            field="search"
            onChange={(e) => setMember(person.id, "id", e.value)}
            value={person.id}
            options={options}
            extraOption={extraOptions}
            placeholder="Search and select a member"
            width="362px"
            noOptionsMessage={`
            No results found. You must add users into your account before
            you can add them here.
          `}
          />
          <Input
            field="search"
            onChange={(e) => setMember(person.id, "role_id", e.value)}
            value={person.role_id}
            options={roleList(level, person.role_id, person.original_role)}
            placeholder="Select role"
            width="362px"
          />
          {showDelete && (
            <DelButton
              className="has-margin"
              onClick={() => deleteMember(person.id)}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};

const ProjectMemberModal = (props) => {
  const {
    open,
    setOpen,
    allUsers,
    showPrefilledTag,
    hideTag,
    updateMembers,
    level,
    members,
    currentLevel,
  } = props;
  const [info, setInfo] = useState([]);
  const [options, setOptions] = useState([]);
  const [pickFrom, setPickFrom] = useState([]);
  const [userList, setUserList] = useState([]);
  const [keyContact, setKeyContact] = useState("");

  // Filters
  let adminRolesArray = [ADMIN_ROLE, OWNER_ROLE];
  // members that do not have project/contract roles (also includes empty to-be-added users)
  let notCurrentLevel = [ADMIN_ROLE, OWNER_ROLE];
  let accViewFilter = [VIEW_ROLE];
  let dropdownFilter = currentLevel;
  dropdownFilter.push(NONE_ROLE, VIEW_ROLE);

  if (level === CONTRACT) {
    adminRolesArray.push(PROJECT_ADMIN);
    notCurrentLevel.push(PROJECT_ADMIN);
    accViewFilter.push(PROJECT_VIEW);
    dropdownFilter.push(PROJECT_VIEW);
  }

  let adminsList = info.filter((m) => adminRolesArray.includes(m.role_id));
  let levelMembers = info.filter((m) => !notCurrentLevel.includes(m.role_id));
  // sort list
  adminsList = adminsList.sort(
    (a, b) => priority[a.role_id] - priority[b.role_id],
  );
  levelMembers = levelMembers.sort(
    (a, b) => priority[a.role_id] - priority[b.role_id],
  );

  useEffect(() => {
    // make info a copy of members (so that changing info does not change members until submitted)
    let newInfo = members.map((e) => ({ ...e }));
    let keyContact = newInfo.find((e) => e.is_key_contact);
    setKeyContact(keyContact);
    setInfo(newInfo);

    if (allUsers) {
      // map of members (to prevent nested looping)
      const membersMap = members.reduce((obj, element) => {
        obj[element["id"]] = element;
        return obj;
      }, {});

      let newUserList = [],
        newPickFrom = [];

      // combine allUsers and members into one list
      allUsers.map((user) => {
        if (membersMap[user.id]) {
          newUserList.push(membersMap[user.id]);
        } else {
          newUserList.push(user);
        }
        return user;
      });

      // members dropdown options
      newUserList.map((user) => {
        if (dropdownFilter.includes(user.role_id)) {
          newPickFrom.push({
            label: user.name,
            value: user.id,
            role_id: user.role_id,
          });
        }
        return user;
      });

      let newOptions = newPickFrom.filter((user) => {
        // users available in the modal dropdowns are those that do not have any roles for this project/contract
        return !membersMap[user.value] || user.role_id === VIEW_ROLE;
      });

      setUserList(newUserList);
      setPickFrom(newPickFrom);
      setOptions(newOptions);
    }
  }, [allUsers, members, dropdownFilter, setInfo]);

  // for updating dropdown options so that people already picked as members do not show up in dropdown
  useEffect(() => {
    // map of info (to prevent nested looping)
    const infoMap = info.reduce((obj, element) => {
      obj[element["id"]] = element;
      return obj;
    }, {});

    // users that do not have any roles for this project/contract
    let newOptions = pickFrom.filter((user) => {
      return !infoMap[user.value];
    });

    setOptions(newOptions);
  }, [info, pickFrom]);

  const addMember = () => {
    let data = info.concat({ role_id: true, id: Date.now() });
    setInfo(data);
  };

  const deleteMember = (id) => {
    let data = info.filter((ele) => ele.id !== id);
    if (keyContact && keyContact.id === id) {
      setKeyContact("");
    }
    setInfo(data);
  };

  const setMember = (id, name, value) => {
    let data = info.map((item) => {
      if (item.id === id) {
        item[name] = value;
      }
      return item;
    });
    if (name === "id") {
      setUserList(userList.filter((ele) => ele.value !== value));
    }
    setInfo(data);
  };

  const closeModal = () => {
    setOpen(false);
    // make info a copy of members
    let newInfo = members.map((e) => ({ ...e }));
    setInfo(newInfo);
  };

  return (
    <Modal
      title={level + " Members"}
      open={open}
      close={() => closeModal()}
      submit={() => {
        if (showPrefilledTag) hideTag();
        updateMembers(info, keyContact.id);
        setOpen(false);
      }}
      disabled={!keyContact}
    >
      <div>
        Add people to the {level} and define their roles. Also select who will
        be the main contact for the {level} (their details will be shown on
        invites.)
      </div>
      <MemberWrapper className="selection">
        {members.length !== 0 && (
          <div className="list-wrap header">
            <div>Contact</div>
            <div>Name</div>
            <div>Role</div>
            <div />
          </div>
        )}
        {adminsList.map((person) => (
          <Member
            {...props}
            isAdmin={true}
            person={person}
            key={person.id}
            keyContact={keyContact}
            setKeyContact={setKeyContact}
          />
        ))}
        {levelMembers.map((person) => (
          <Member
            {...props}
            isAdmin={false}
            person={person}
            options={options}
            extraOptions={pickFrom}
            deleteMember={deleteMember}
            setMember={setMember}
            key={person.id}
            showDelete={!accViewFilter.includes(person.role_id)}
            keyContact={keyContact}
            setKeyContact={setKeyContact}
          />
        ))}
      </MemberWrapper>
      <AddButton title="add member" onClick={() => addMember()} />
    </Modal>
  );
};

export default ProjectMemberModal;
