import React, { useEffect, useState, useRef } from "react";
import mapBoxGl from "mapbox-gl";
import { connect } from "react-redux";
import MapBoxGeocoder from "@mapbox/mapbox-gl-geocoder";

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "./map.css";

import { removeElementsByClass } from "../../utils/helper";
import { DEFAULT_COUNTRY } from "../../utils/constants";
import DropdownCountry from "../dropdown/dropdown_with_country";

import { actionCreators } from "../../common/config/store";

const AddressSearch = (props) => {
  const {
    countriesList,
    getCountries,
    setAddress,
    accountCountry,
    hasRestrictCountry,
    getAccountCountry,
    addressClass,
  } = props;
  const [isMapboxSet, setIsMapboxSet] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const searchAddressRef = useRef(null);

  useEffect(() => {
    let country = accountCountry || DEFAULT_COUNTRY;
    setSelectedCountry(country);
    let address = {
      street_address: "",
      postcode: "",
      suburb: "",
      city: "",
      state: "",
      country: country,
    };
    setAddress(address, true);
    // eslint-disable-next-line
  }, [accountCountry]);

  useEffect(() => {
    getAccountCountry();
    getCountries();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsMapboxSet(false);
    // Reset the Mapbox
    removeElementsByClass("mapboxgl-ctrl-geocoder");
  }, [selectedCountry]);

  useEffect(() => {
    mapBoxGl.accessToken = localStorage.getItem("MAPBOX_TOKEN");

    if (countriesList.length && !isMapboxSet && mapBoxGl.accessToken !== null) {
      setIsMapboxSet(true);
      let countries = {};
      countriesList.map((country) => {
        countries[country.value] = country.label;
        return country;
      });
      const geocoder = new MapBoxGeocoder({
        accessToken: mapBoxGl.accessToken,
        types: "address",
        countries: selectedCountry, // restrict to these countries
        placeholder: "Search for an address",
        marker: true,
      });

      geocoder.addTo(searchAddressRef.current);
      // send geocoder result to address form.
      geocoder.on("result", (event) => {
        let address = {
            street_address: "",
            postcode: "",
            suburb: "",
            city: "",
            state: "",
            country: "",
          },
          data = event.result;
        if (data.properties.accuracy) {
          address.street_address =
            (data.address ? data.address : "") + " " + data.text;
        }
        data.context.map((ele) => {
          if (ele.id.includes("postcode")) {
            address.postcode = ele.text;
          }
          if (ele.id.includes("region")) {
            address.state = ele.text;
          }
          if (ele.id.includes("place")) {
            address.city = ele.text;
          }
          if (ele.id.includes("locality")) {
            address.suburb = ele.text;
          }
          if (ele.id.includes("country")) {
            address.country = ele.short_code.toUpperCase();
            //save country iso code in Upper case
          }
          return ele;
        });
        setAddress(address);
      });
    }
  }, [
    countriesList,
    setAddress,
    isMapboxSet,
    selectedCountry,
    hasRestrictCountry,
    accountCountry,
  ]);

  return (
    <>
      <div
        ref={searchAddressRef}
        className={`searchAddressClass ${addressClass}`}
      >
        <DropdownCountry
          country={selectedCountry}
          handleChange={(name, value) => setSelectedCountry(value)}
          countriesList={countriesList}
          className={"countries"}
          displayBriefName={true}
          disabled={hasRestrictCountry}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  countriesList: state.getIn(["config", "countriesList"]),
  accountCountry: state.getIn(["config", "accountCountry"]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getCountries() {
      dispatch(actionCreators.readCountries());
    },
    getAccountCountry() {
      dispatch(actionCreators.readAccountCountry());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressSearch);
