import React, { PureComponent } from "react";
import { SummaryWrapper } from "../styled";
import DateYear from "./date";
import { currencySubunitToUnit } from "../../../utils/currencyHelper";

function checkBell(data) {
  return (
    (data.unclaimed_days !== 0 && <div className="img_due unclaimed" />) ||
    (data.due_in_3_days !== 0 && <div className="img_due img_due_3" />) ||
    (data.due_in_7_days !== 0 && <div className="img_due img_due_7" />) ||
    null
  );
}
export default class Claim_summary extends PureComponent {
  render() {
    const {
      handleChangeDate,
      selDate,
      certSummary,
      tableContent,
      accountConfig,
    } = this.props;
    const certDueDays = {
      unclaimed_days: tableContent.filter(
        (item) => item.get("over_due_type") === "unclaimed_days",
      ).size,
      due_in_3_days: tableContent.filter(
        (item) => item.get("over_due_type") === "due_in_3_days",
      ).size,
      due_in_7_days: tableContent.filter(
        (item) => item.get("over_due_type") === "due_in_7_days",
      ).size,
    };
    const label = accountConfig?.getIn(["claim", "novalue"])
      ? accountConfig?.getIn(["claim", "novalue"])
      : "Unclaimed";
    return (
      <SummaryWrapper>
        <div className="claim_summary claims-received_step1">
          <div className="title_claim">
            Cert Summary
            <div className="title_date">
              <div style={{ width: "145px", position: "absolute" }}>
                <DateYear
                  handleChange={handleChangeDate}
                  selectedDate={selDate}
                />
              </div>
              <div className="img_date" />
            </div>
          </div>
          <div className="content_claim">
            <div className="content_project total">
              <div className="label">Current Contracts</div>
              <div className="money">
                {certSummary.get("current_contracts")}
              </div>
            </div>
            <div className="content_project total_claimed">
              <div className="label">Total Contracts Certified</div>
              <div className="money">
                {certSummary.get("total_contracts_certified")}
              </div>
            </div>
            <div className="content_project total_money">
              <div className="label">Total Certified Amount</div>
              <div className="money">
                {currencySubunitToUnit(
                  certSummary.get("total_certified_amount"),
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="claim_due claims-received_step2">
          <div className="title_due">
            {checkBell(certDueDays)}
            Certs Due
          </div>
          <div className="content_due">
            <div className="content_list unclaimed">
              <div className="field_claim">{label} for 30 days</div>
              <div className="money_claim">{certDueDays.unclaimed_days}</div>
            </div>
            <div className="content_list in_3_due">
              <div className="field_claim">Due in 3 Days</div>
              <div className="money_claim">{certDueDays.due_in_3_days}</div>
            </div>
            <div className="content_list in_due">
              <div className="field_claim">Due in 7 Days</div>
              <div className="money_claim">{certDueDays.due_in_7_days}</div>
            </div>
          </div>
        </div>
      </SummaryWrapper>
    );
  }
}
