import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, WorkflowContainer } from "./styled";

import Menu from "../../common/breadcrumbs";
import Link from "../../common/linkWithQuery";
import {
  GreenWhiteBtn as Button,
  BlueOutlinedBtn as ButtonBlue,
  AddBtn as ButtonAdd,
} from "../../common/button";
import Loading from "../../common/loading";
import { OverviewPageUrlContractType } from "../contract/common";
import Step from "./components/step";

import { actionCreators } from "./store";

export class index extends Component {
  render() {
    const {
      projectName,
      contractName,

      stepInfo,
      contractInfo,
      addOneStep,
      upsertWorkflow,
      loading,
      accountConfig,
    } = this.props;

    const claimTerm = accountConfig?.getIn(["claim", "noun"]);

    const { proId, contractId, claimId, certId } = this.props.match.params;
    const workflowType = !certId ? claimTerm : "Certificate";

    const guideMeg = {
      [claimTerm]: `Invite members and manage the approval of ${claimTerm?.toLowerCase()}s. A basic workflow has no approval steps between creating and issuing 
        ${claimTerm?.toLowerCase()}s. You can set up a custom approval workflow.`,
      Certificate: `Invite members and manage the approval of certificates. A basic workflow has no approval steps between creating and issuing certificates. You can set up a custom approval workflow.`,
    };

    if (loading) {
      return <Loading />;
    }

    const contractType = contractInfo.get("contract_type");
    let link = [
      { link: "/projects", text: "Projects" },
      { link: `/project/view/${proId}`, text: projectName },
      {
        link: `/contract/${proId}/${OverviewPageUrlContractType(
          contractType,
        )}/${contractId}`,
        text: contractName,
      },
    ];

    let previousURL = "";
    previousURL = certId
      ? `/project/${proId}/contract/${contractId}/${contractType}/claim/${claimId}/cert/review`
      : `/project/${proId}/contract/${contractId}/${contractType}/claim/${claimId}/review`;

    const hasWorkflow = stepInfo && stepInfo.size !== 0;

    //sort workflow by step id
    const sortedWorkflow = hasWorkflow
      ? stepInfo.sortBy((val) => val.get("step"))
      : null;

    return (
      <Container>
        <div className="page-breadcrumbs">
          <Menu
            link={link}
            content={`${workflowType} workflow`}
            className="page-breadcrumbs-label"
          />
          <Link to={previousURL}>
            <ButtonBlue title={!certId ? `Back To Claim` : `Back To Cert`} />
          </Link>
        </div>
        <h1>{workflowType} workflow</h1>
        <p>{guideMeg[workflowType]}</p>

        <WorkflowContainer>
          <Step stepName={"Create " + workflowType} />

          {hasWorkflow &&
            sortedWorkflow.map((item) => (
              <Step
                key={item.get("id")}
                stepName={item.get("name")}
                isEditable
                step={item}
                {...this.props}
              />
            ))}
          {stepInfo.size <= 4 ? (
            <div className="step action">
              <div className="v-stepper">
                <div className="circle" />
                <div className="line" />{" "}
              </div>
              <div className="content">
                <ButtonAdd
                  title="Approval Step"
                  className="button-add"
                  onClick={() => addOneStep()}
                />
              </div>
            </div>
          ) : (
            <></>
          )}

          <Step stepName={"Issue " + workflowType} />
        </WorkflowContainer>

        <div className="page-foot">
          <Link to={previousURL}>
            <ButtonBlue title="cancel" />
          </Link>
          <Button
            title="save"
            onClick={() =>
              upsertWorkflow(previousURL, proId, contractId, claimId, certId)
            }
          />
        </div>
      </Container>
    );
  }

  componentDidMount() {
    const { proId, contractId, claimId, certId } = this.props.match.params;
    this.props.readUsers(proId, contractId);
    this.props.readWorkflow(proId, contractId, claimId, certId);
  }
  componentWillUnmount() {
    this.props.resetInfo();
  }
}

const mapStateToProps = (state) => ({
  stepInfo: state.getIn(["workflow", "stepInfo"]),
  userList: state.getIn(["workflow", "userList"]),
  projectName: state.getIn(["workflow", "projectName"]),
  contractName: state.getIn(["workflow", "contractName"]),
  contractInfo: state.getIn(["workflow", "basicInfo"]),
  loading: state.getIn(["workflow", "loading"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    readUsers(proId, contractId) {
      dispatch(actionCreators.readUsers(proId, contractId));
    },
    resetInfo() {
      dispatch(actionCreators.resetInfo());
    },
    readWorkflow(proId, contractId, claimId, certId) {
      dispatch(
        actionCreators.readClaimCertWorkflow(
          proId,
          contractId,
          claimId,
          certId,
        ),
      );
    },
    upsertWorkflow(successURL, proId, contractId, claimId, certId) {
      dispatch(
        actionCreators.upsertClaimCertWorkflow(
          successURL,
          proId,
          contractId,
          claimId,
          certId,
        ),
      );
    },
    addOneStep() {
      dispatch(actionCreators.addOneStep());
    },
    deleteStep(stepIndex) {
      dispatch(actionCreators.deleteOneStep(stepIndex));
    },
    changeStepName(stepId, name) {
      dispatch(actionCreators.changeStepName(stepId, name));
    },
    addStepApprover(stepId) {
      dispatch(actionCreators.addStepApprover(stepId));
    },
    deleteStepApprover(stepId, approverId) {
      dispatch(actionCreators.deleteStepApprover(stepId, approverId));
    },
    handleApprover(stepId, approverId, field, value) {
      dispatch(
        actionCreators.changeStepApprover(stepId, approverId, field, value),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
