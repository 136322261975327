import React, { useEffect, useState } from "react";

import {
  ProjectDetailWrapper,
  SettingSection,
  SectionIcon,
  MemberWrapper,
} from "../styled";
import { PrefilledTag } from "../../contract/styled";
import { toTitleCase } from "../../../utils/helper";
import {
  OWNER_ROLE,
  ADMIN_ROLE,
  VIEW_ROLE,
  PROJECT,
  PROJECT_ADMIN,
  PROJECT_VIEW,
  CONTRACT_ADMIN,
  CONTRACT_VIEW,
  NONE_ROLE,
} from "../../../utils/constants";

import { BlueOutlinedBtn as Button } from "../../../common/button";
import ProjectMemberModal from "./projectMemberModal";

const PersonItem = ({ person }) => (
  <li className="member-wrap">
    <div className="person-avatar" />
    <div className="person-info">
      <div>
        <div className="name">{person.name}</div>
        {person.is_key_contact && <div className="main-role">Main Contact</div>}
      </div>

      <div className="email">{person.email}</div>
    </div>
    <div className="role">{person.role_description}</div>
  </li>
);

const ProjectAndContractMember = (props) => {
  const { members, level, prefilledSeen, readOnly } = props;
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState([]); // project/contract members + defaults ('none' users are not included)
  const [showAdmin, setShowAdmin] = useState(false);

  let isProject = level === PROJECT;

  // Filters
  const accountRolesArray = [ADMIN_ROLE, OWNER_ROLE, NONE_ROLE];
  let othersArray = [ADMIN_ROLE, OWNER_ROLE, VIEW_ROLE];
  let currentLevel = [];

  // Filter changes depending on the level
  if (isProject) {
    currentLevel.push(PROJECT_ADMIN, PROJECT_VIEW);
  } else {
    othersArray.push(PROJECT_ADMIN, PROJECT_VIEW);
    currentLevel.push(CONTRACT_ADMIN, CONTRACT_VIEW);
  }

  useEffect(() => {
    setInfo(members);
  }, [members]);

  const userMembers = info.filter(
      (ele) => !accountRolesArray.includes(ele.role_id) && !ele.is_key_contact,
    ),
    // list in others section
    othersList = info.filter(
      (m) => othersArray.includes(m.role_id) && !m.is_key_contact,
    ),
    // list in current members section
    currentMembers = info.filter(
      (m) =>
        currentLevel.includes(m.role_id) &&
        m.role_id !== VIEW_ROLE &&
        !m.is_key_contact,
    ),
    // key member, always on top current members section regardless of role
    keyMembers = info.filter((m) => m.is_key_contact);

  const showPrefilledTag =
    prefilledSeen &&
    !prefilledSeen.get("contractMembers") &&
    level === "contract" &&
    !readOnly &&
    userMembers.length > 0 &&
    keyMembers.length > 0;

  return (
    <ProjectDetailWrapper className="setting-page" id={`nav-${level}Member`}>
      <SettingSection>
        <div className="section-title">
          <div className="flex">
            <SectionIcon img={"member"} />
            <span>{toTitleCase(level)} Members</span>
            {showPrefilledTag && (
              <PrefilledTag>Prefilled From Project</PrefilledTag>
            )}
          </div>
          {!readOnly && <Button title="edit" onClick={() => setOpen(true)} />}
        </div>
        <div className="center">
          {!readOnly && (
            <div>
              Add people to the {level} and define their roles. Also select who
              will be the main contact for the {level} (their details will be
              shown on invites.)
            </div>
          )}
          <MemberWrapper>
            {keyMembers &&
              keyMembers.map((person) => (
                <PersonItem key={person.id} person={person} />
              ))}
            {currentMembers.map((person) => (
              <PersonItem key={person.id} person={person} />
            ))}
            <div
              className="show_hide_controller"
              onClick={() => setShowAdmin(!showAdmin)}
            >
              <div className={showAdmin ? "img" : "img down"} />
              <div className="label">{!showAdmin ? "Show" : "Hide"} Others</div>
            </div>
            {showAdmin && (
              <>
                These people have access because they are part of your account
                {isProject ? "." : " or part of the project."}
                {othersList.map((person) => (
                  <PersonItem key={person.id} person={person} />
                ))}
              </>
            )}
          </MemberWrapper>
        </div>
      </SettingSection>
      <ProjectMemberModal
        {...props}
        open={open}
        setOpen={setOpen}
        info={info}
        setInfo={(info) => setInfo(info)}
        level={level}
        currentLevel={currentLevel}
      />
    </ProjectDetailWrapper>
  );
};

export default ProjectAndContractMember;
