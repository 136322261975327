import React, { PureComponent } from "react";
import Link from "../../common/linkWithQuery";
import List from "./components/contacts";
import { connect } from "react-redux";
import profile from "../../utils/profile";
import { CONTACT, CREATE } from "../../utils/constants";
import { actionCreators } from "./store";
import { BgWrapper, HeaderTitle } from "../../common/shared_style";
import { BlueWhiteBtn as Button } from "../../common/button";
import Loading from "../../common/loading";
class Contacts extends PureComponent {
  render() {
    if (this.props.loading) {
      return <Loading />;
    }
    return (
      <BgWrapper>
        <HeaderTitle style={{ float: "left" }}>Contacts</HeaderTitle>
        {profile.checkPermission(CONTACT, CREATE) && (
          <Link to="/contacts/add" style={{ textDecoration: "none" }}>
            <Button title="ADD NEW CONTACT" />
          </Link>
        )}
        <List {...this.props} />
      </BgWrapper>
    );
  }
  componentDidMount() {
    this.props.changeHomeData();
  }
}
const mapStateToProps = (state) => ({
  list: state.getIn(["contacts", "contactList"]),
  allContact: state.getIn(["contacts", "allContact"]),
  selectedSortOption: state.getIn(["contacts", "selectedSortOption"]),
  selectedContactId: state.getIn(["contacts", "selectedContactId"]),
  loading: state.getIn(["contacts", "loading"]),
});
const mapDispatch = (dispatch) => {
  return {
    changeHomeData() {
      dispatch(actionCreators.getContactInfo());
    },
    handleDeleteContact(contactId, entityId) {
      dispatch(actionCreators.deleteContact(contactId, entityId));
    },
    selectContact(name, e) {
      if (!e) {
        dispatch(actionCreators.setSelectedInfo(name, ""));
      } else {
        dispatch(actionCreators.setSelectedInfo(name, e.value));
      }
    },
    handleSelectedOption(e) {
      dispatch(actionCreators.setSelectedInfo("selectedSortOption", e.value));
    },
  };
};
export default connect(mapStateToProps, mapDispatch)(Contacts);
