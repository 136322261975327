import React, { useEffect, useState, useMemo } from "react";
import Select, { components } from "react-select";

import DropdownIndicator from "./component/indicator";
import { valueFromId } from "./component/helper";
import { palette } from "../../theme";
import { OptionWrap } from "./styled";
import { CLAIM_CERT_SUMMARY } from "utils/constants";

const OptionList = {
  claim: [
    CLAIM_CERT_SUMMARY.SUMMARY_CERTIFIED,
    CLAIM_CERT_SUMMARY.SUMMARY_CLAIMED,
    CLAIM_CERT_SUMMARY.SUMMARY_CUSTOM,
  ],
  cert: [
    CLAIM_CERT_SUMMARY.SUMMARY_CERTIFIED,
    CLAIM_CERT_SUMMARY.SUMMARY_CUSTOM,
  ],
};

const getStyle = (isRequired) => ({
  control: (styles, { isFocused }) => ({
    ...styles,
    width: "275px",
    height: "66px",
    border: `1px solid ${isRequired ? palette.dangerRed : palette.wolfGrey}`,
    boxShadow: isFocused
      ? `0 0 0 1px ${isRequired ? palette.dangerRed : palette.tealBlue}`
      : "none",
    fontSize: "16px",
    fontWeight: "bold",
    color: `${palette.gunmetal}`,
    backgroundColor: `${palette.white}`,
    textAlign: "right",
    "&:hover": {
      border: `1px solid ${isRequired ? palette.dangerRed : palette.tealBlue}`,
    },
  }),
  menu: (styles) => ({
    ...styles,
    width: "640px",
    marginTop: "0px",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  option: (styles) => ({
    ...styles,
    width: "fit-content",
    fontSize: "16px",
    padding: "14px 16px",
    fontWeight: "normal",
    backgroundColor: "white",
    color: `${palette.gunmetal}`,
    "&:hover": { backgroundColor: palette.warmLightGrey, cursor: "pointer" },
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    paddingRight: "12px",
  }),
});

const OptionContainer = ({ icon, title, subLabel, msg, type }) => (
  <OptionWrap>
    <div className={`img ${icon}`} />
    <div>
      <div className="option-title">{title}</div>
      {subLabel && <div className="previous-info">{subLabel}</div>}
      <div className="msg">{msg[type]}</div>
    </div>
  </OptionWrap>
);

const Index = ({
  onChange,
  value,
  accountConfig,
  type,
  previousRef,
  required,
}) => {
  //type:claim/cert  previousRef: {claimed:"",certified:""}

  const [options, setOptions] = useState([]);

  const optionDefinitions = useMemo(() => {
    const claimNoun =
        accountConfig?.getIn(["claim", "noun"])?.toLowerCase() || "claim",
      claimValue = accountConfig?.getIn(["claim", "value"]) || "Claimed";
    const certNoun =
        accountConfig?.getIn(["cert", "noun"])?.toLowerCase() || "certificate",
      certValue = accountConfig?.getIn(["cert", "value"]) || "Certified";
    return {
      [CLAIM_CERT_SUMMARY.SUMMARY_CERTIFIED]: {
        label: `Previously ${certValue} Amount`,
        value: CLAIM_CERT_SUMMARY.SUMMARY_CERTIFIED,
        icon: "cert",
        title: (
          <span>
            Previously{" "}
            <span style={{ color: palette.tealBlue }}>{certValue}</span> Amount
          </span>
        ),
        msg: {
          cert: (
            <span>
              This period's {certNoun} amount will be calculated by deducting
              the <b>previous {certNoun}</b> amount from the certified amount to
              date (recommended).
            </span>
          ),
          claim: (
            <span>
              This period's {claimNoun} amount will be calculated by deducting
              the <b>previous {certNoun}</b> amount from the{" "}
              {claimValue.toLowerCase()} amount to date (recommended).
            </span>
          ),
        },
      },
      [CLAIM_CERT_SUMMARY.SUMMARY_CLAIMED]: {
        label: `Previously ${claimValue} Amount`,
        value: CLAIM_CERT_SUMMARY.SUMMARY_CLAIMED,
        icon: "claim",
        title: (
          <span>
            Previously{" "}
            <span style={{ color: palette.brightGreen }}>{claimValue}</span>{" "}
            Amount
          </span>
        ),
        msg: {
          claim: (
            <span>
              This period's {claimNoun} amount will be calculated by deducting
              amount of the <b>previous {claimNoun}</b> from the{" "}
              {claimValue.toLowerCase()} amount to date. Use this if the last{" "}
              {certNoun} is missing or incorrect.
            </span>
          ),
        },
      },
      [CLAIM_CERT_SUMMARY.SUMMARY_CUSTOM]: {
        label: "Custom Amount",
        value: CLAIM_CERT_SUMMARY.SUMMARY_CUSTOM,
        icon: "custom",
        title: <span>Custom Amount </span>,
        msg: {
          cert: `Enter a custom amount to deduct from the ${certValue} amount to date. This amount should be the amount that the other party has already paid, or agreed to pay.`,
          claim: `Enter a custom amount to deduct from the ${claimValue} amount to date. This amount should be the amount that the other party has already paid, or agreed to pay.`,
        },
      },
    };
  }, [accountConfig]);

  useEffect(() => {
    const getDefaultOptions = () => {
      let options = [];
      if (OptionList[type]) {
        OptionList[type].map((option) => {
          options.push({
            ...optionDefinitions[option],
            subLabel: previousRef[option] || null,
            option: (
              <OptionContainer
                {...optionDefinitions[option]}
                type={type}
                subLabel={previousRef[option] || null}
              />
            ),
          });

          return option;
        });
        return setOptions(options);
      }
    };
    getDefaultOptions();
  }, [type, previousRef, optionDefinitions]);

  return (
    <Select
      components={{
        DropdownIndicator,
        Option: (props) => (
          <components.Option {...props}>
            <div>{props.data.option}</div>
          </components.Option>
        ),
        SingleValue: (props) => (
          <components.SingleValue {...props}>
            <div>{props.data.label}</div>
            {props.data.subLabel && (
              <div style={{ color: palette.veryMediumGrey, fontWeight: 400 }}>
                {props.data.subLabel}
              </div>
            )}
          </components.SingleValue>
        ),
      }}
      styles={getStyle(required)}
      isSearchable={false}
      value={valueFromId(options, value) || null}
      onChange={onChange}
      options={options}
      placeholder={"Select Previous Amount"}
    />
  );
};

export default Index;
