import { combineReducers } from "redux-immutable";
import { reducer as headerReducer } from "../common/header/store";
import { reducer as contactsReducer } from "../pages/contacts/store";
import { reducer as dashboardReducer } from "../pages/dashboard/store";
import { reducer as projectsReducer } from "../pages/projects/store";
import { reducer as claimsReceivedReducer } from "../pages/claimsReceived/store";
import { reducer as formReducer } from "redux-form/immutable";
import { reducer as baseContractReducer } from "../pages/basecontract/store";
import { reducer as variationsReducer } from "../pages/variations/store";
import { reducer as manageAccountReducer } from "../pages/manageaccount/store";
import { reducer as makeAClaimReducer } from "../pages/makeclaim/store";
import { reducer as makeACertReducer } from "../pages/makecert/store";
import { reducer as inviteInfoReducer } from "../pages/invitation/store";
import { reducer as modalReducer } from "../common/modal";
import { reducer as fileReducer } from "../common/upload_file_progress/store";
import { reducer as configReducer } from "../common/config/store";
import { reducer as projectGroupReducer } from "../pages/projectGroup/store";
import { reducer as projectReducer } from "../pages/project/store";
import { reducer as workflowReducer } from "../pages/workflow/store";
import { reducer as contract } from "../pages/contract/store";
import { reducer as attachmentReducer } from "../common/claim_cert_attachment/store";
import { reducer as toastReducer } from "../common/toastNotification/store";

const reducer = combineReducers({
  headers: headerReducer,
  contacts: contactsReducer,
  dashboards: dashboardReducer,
  projects: projectsReducer,
  claimsReceived: claimsReceivedReducer,
  form: formReducer,
  basecontract: baseContractReducer,
  variation: variationsReducer,
  manageAccount: manageAccountReducer,
  makeaclaim: makeAClaimReducer,
  makeacert: makeACertReducer,
  invite: inviteInfoReducer,
  modal: modalReducer,
  files: fileReducer,
  projectGroup: projectGroupReducer,
  newContractGroup: projectReducer,
  workflow: workflowReducer,
  config: configReducer,
  contract: contract,
  attachment: attachmentReducer,
  toastNotification: toastReducer,
});

export default reducer;
