import React, { Fragment, useState, useEffect } from "react";
import debounce from "lodash/debounce";
import { fromJS } from "immutable";

import {
  ToggleButtonStyle,
  SearchContainer,
  SortContainer,
  ListWrap,
  LinkedWrap,
  LinkSelectionWrap,
  LinkedNotice,
} from "./styled";

import Skeleton from "../../../common/skeleton";

import Dropdown from "common/dropdown/dropdown_with_label";
import {
  GreenWhiteBtn as Button,
  BlueOutlinedBtn as SwitchButton,
} from "../../../common/button";
import { caseInsensitiveIncludes, toTitleCase } from "../../../utils/helper";
import Tooltip from "../../../common/toolTip";
import { handleSortIntegrationContractList } from "./integrationSortHelper";

export const ToggleButtons = (props) => {
  const { setValue } = props;
  const [active, setActive] = useState("new");
  const buttons = [
    { label: "Create New", value: "new" },
    { label: "Link Existing", value: "link" },
  ];
  const handleOnClick = (value) => {
    setActive(value);
    setValue(value);
  };

  return (
    <ToggleButtonStyle buttonWidth={"180px"}>
      <Fragment>
        <div
          className={`button left ${active === buttons[0].value && "active"}`}
          onClick={() => handleOnClick(buttons[0].value)}
        >
          {buttons[0].label}
        </div>
        <div
          className={`button right ${active === buttons[1].value && "active"}`}
          onClick={() => handleOnClick(buttons[1].value)}
        >
          {buttons[1].label}
        </div>
      </Fragment>
    </ToggleButtonStyle>
  );
};

const Loading = ({ from }) => {
  return (
    <LinkSelectionWrap>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "88% 10%",
          width: "100%",
          gridColumnGap: "16px",
        }}
      >
        <Skeleton.Line />
        <Skeleton.Line />
      </div>
      <ListWrap>
        <label>
          <b>{from}s List</b>
        </label>
        <ul>
          {Array.from({ length: 9 }, (_, i) => (
            <Skeleton.Line key={i} />
          ))}
        </ul>
      </ListWrap>
      <Skeleton.Line width={"182px"} />
    </LinkSelectionWrap>
  );
};

export const LinkSelection = (props) => {
  const { from, setLinkInfo, list, linked, loading } = props;

  const [sort, setSort] = useState("name");
  const [linkId, setLinkId] = useState("");
  const [linkDetails, setLinkDetails] = useState(fromJS({}));
  const [filterList, setFilterList] = useState(fromJS([]));

  const debounceChange = debounce((value) => handleSearch(value, sort), 400);

  const valueFromId = (opts, id) => {
    if (from === "contract" && id === "name") {
      id = "description";
    }
    return opts.find((o) => {
      return o.value === id;
    });
  };

  const handleSortList = (data, sortKey) => {
    setSort(sortKey);
    const sortedList = handleSortIntegrationContractList(data, sortKey, from);
    setFilterList(sortedList);
  };

  const handleSearch = (search, sort) => {
    let searchString = search;
    let filteredList = list;
    if (searchString) {
      filteredList = list.filter((p) =>
        ["name", from + "_number"].some((field) =>
          caseInsensitiveIncludes(p.get(field), searchString),
        ),
      );
    }
    handleSortList(filteredList, sort);
  };
  useEffect(() => {
    handleSearch("", "name");
    //eslint-disable-next-line
  }, [list]);

  const Msg = {
    contract: "Select a contract to link into Paylab as a contract.",
    project: "Select a job to link into Paylab as a project.",
  };

  function getSortOptions() {
    if (from === "contract") {
      return [
        { value: `${from}_number`, label: `${toTitleCase(from)} Number` },
        { value: "vendor_name", label: "Vendor" },
        { value: `description`, label: `Contract Description` },
      ];
    } else if (from === "project") {
      return [
        { value: "name", label: "Job Name" },
        { value: `${from}_number`, label: `Job Number` },
      ];
    }
    return [];
  }

  if (loading) {
    return <Loading from={from} />;
  }

  return (
    <>
      {!linked && (
        <LinkSelectionWrap>
          <div>
            <SearchContainer>
              <div className="img-search" />
              <input
                onChange={(e) => debounceChange(e.target.value)}
                placeholder={"Search Job Name/Number..."}
              />
            </SearchContainer>
            <SortContainer>
              <div className="sort-label">Sort by:</div>
              <Dropdown
                value={valueFromId(getSortOptions(), sort)}
                onChange={(e) => handleSortList(filterList, e.value)}
                options={getSortOptions()}
              />
            </SortContainer>
          </div>
          <ListWrap>
            <label>
              <b>{from}s List</b>
            </label>
            <ul>
              {filterList.map((e) => (
                <li
                  key={e.get("id")}
                  className={`${e.get("integration") ? "disabled" : ""} ${
                    linkId === e.get("id") ? "selected" : ""
                  }`}
                  onClick={() => {
                    if (!e.get("integration")) {
                      setLinkId(e.get("id"));
                      setLinkDetails(e);
                    }
                  }}
                >
                  {e.get("name")}{" "}
                  {e.get("integration") ? "(Already linked)" : ""}
                </li>
              ))}
            </ul>
            <div>{Msg[from]}</div>
          </ListWrap>
          <Button
            title="Continue"
            padding={"10px 72px"}
            onClick={() => setLinkInfo(linkId, linkDetails)}
            disabled={!linkId}
          />
        </LinkSelectionWrap>
      )}
      {linked && (
        <LinkedInfo {...props} handleSwitch={() => setLinkInfo(null, [])} />
      )}
    </>
  );
};

export const LinkedInfo = (props) => {
  const { from, handleSwitch, linkedName } = props;
  return (
    <LinkedWrap>
      <div className="upper-wrap">
        <label>{linkedName}</label>
        <SwitchButton title="Switch" onClick={handleSwitch} />
      </div>
      <div className="fixed-label">
        <div className="icon" />
        Some details are provided by the linked {from} and cannot be edited.
        {/* todo hide for now
        <div className="under-line-label">Find out more</div> */}
      </div>
    </LinkedWrap>
  );
};

export const LinkedInfoSettings = (props) => {
  const { from } = props;
  return (
    <Tooltip
      placement="bottom"
      title={<>Details are provided by Linked {from} and cannot be edited.</>}
    >
      <LinkedNotice>
        <div className="icon" />
        <div className="text">Linked {from}</div>
      </LinkedNotice>
    </Tooltip>
  );
};
