import React, { useState, useEffect } from "react";

import { DialogContent, ApproverWrap } from "../styled";

import Modal from "../../modal/component/modalBase";
import Input from "../../form";
import { GreenWhiteBtn as SaveBtn } from "../../button";

export default function RevertModal(props) {
  const { open, closeDialog, goToRevert, workflowType, approvers } = props;
  const [comment, setComment] = useState("");
  const [error, setError] = useState(null);

  const submit = () => {
    if (comment.trim() !== "") {
      goToRevert(comment);
    } else {
      setError("Required");
    }
  };

  const handleInput = (event) => {
    setComment(event.target.value);
    if (error && event.target.value) {
      setError(null);
    }
  };

  useEffect(() => {
    setComment("");
    setError(null);
  }, [open]);

  return (
    <Modal
      open={open}
      handleClose={closeDialog}
      title={"Revert to Previous Approval Step"}
      width="590px"
    >
      <DialogContent>
        <div>
          Reverting to the previous approval step will push the workflow back.
          All approvals for the previous step will <br />
          be removed.
        </div>
        <div className="warning-wrap">
          <div className="info-icon" />
          <span>
            The following approvers will need to re-approve the {workflowType}.
          </span>
        </div>

        <div className="sub-header">Approvers</div>
        {approvers.map((approver) => (
          <ApproverWrap key={approver.id}>
            <div className="avatar" />
            <div className="name">{approver.user_name} &nbsp;</div>
            <div className="status">
              ({approver.required ? "Required" : "Optional"})
            </div>
          </ApproverWrap>
        ))}

        <div className="sub-label">
          <Input
            label="Leave a comment"
            name="comment"
            className={`comment-field ${!error ? "" : "has-error"}`}
            field="textarea"
            value={comment}
            onChange={handleInput}
            error={error}
          />
        </div>
      </DialogContent>
      <SaveBtn title="Continue" type="submit" onClick={submit} />
    </Modal>
  );
}
