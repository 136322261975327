import React from "react";
import { fromJS } from "immutable";
import { connect } from "react-redux";
import {
  ProjectDetailWrapper,
  SettingSection,
  SectionIcon,
  WorkflowWrapper,
} from "../styled";
import { PrefilledTag } from "../../contract/styled";

import { BlueOutlinedBtn as Button } from "../../../common/button";
import Link from "../../../common/linkWithQuery";
import { LinkedInfoSettings } from "../../contract/common/integration";

const WorkflowItem = ({ step }) => (
  <div className="step">
    <div className="v-stepper">
      <div className="circle" />
      <div className="line" />
    </div>
    <div className="content">
      <div className="step-name">{step.get("name")}</div>
      <div className="approver">
        {step.get("default") && (
          <li>
            <div>{step.get("default")}</div>
          </li>
        )}

        {step.get("approvers") &&
          step.get("approvers").map((person, index) => (
            // todo render person avatar
            <li key={index}>
              <div className="person-avatar" />
              <div> {person.getIn(["user", "name"])}</div>
              {!person.get("required") && (
                <div className="secondary-label">(Optional)</div>
              )}
            </li>
          ))}
      </div>
    </div>
  </div>
);

const WorkflowInfo = ({ data, defaultWorkflow, className }) => {
  return (
    <WorkflowWrapper className={className}>
      <div className="header">
        <div>Workflow Steps</div>
        <div>Who Can Do This?</div>
      </div>
      <WorkflowItem step={defaultWorkflow.get(0)} key={"create"} />
      {data &&
        data.map((step) => <WorkflowItem step={step} key={step.get("id")} />)}
      <WorkflowItem step={defaultWorkflow.get(1)} key={"issue"} />
    </WorkflowWrapper>
  );
};

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

function Workflow(props) {
  const {
    projectId,
    workflowType,
    workflowInfo,
    contractId,
    prefilledSeen,
    hideTag,
    readOnly,
    accountConfig,
    isLinked,
  } = props;
  const editUrl = contractId
    ? `/contract/${projectId}/${workflowType}/${contractId}/workflow`
    : `/project/view/${projectId}/setting/workflow/${workflowType}`;
  const certNounWord = accountConfig?.getIn(["cert", "noun"]);
  const claimTerm = accountConfig?.getIn(["claim", "noun"]);
  const guideMeg = {
    claim: `Invite members and manage the approval of ${claimTerm?.toLowerCase()}s. A basic workflow has no approval steps between creating and issuing ${claimTerm?.toLowerCase()}s. You can set up a custom approval workflow.`,
    cert: `Invite members and manage the approval of ${certNounWord}s. A basic workflow has no approval steps between creating and issuing ${certNounWord}s. You can set up a custom approval workflow.`,
  };

  const type = workflowType === "claim" ? claimTerm : certNounWord;
  const hasWorkflow = workflowInfo && workflowInfo.size !== 0;
  //sort workflow by step id
  const sortedWorkflow = hasWorkflow
    ? workflowInfo.sortBy((val) => val.get("step"))
    : null;
  const DefaultWorkflow = fromJS([
    {
      name: "Create " + type,
      default: "Admins",
    },
    {
      name: "Issue " + type,
      default: "Admins",
    },
  ]);

  const showPrefilledTag =
    prefilledSeen &&
    !prefilledSeen.get("workflow") &&
    contractId &&
    hasWorkflow;

  return (
    <ProjectDetailWrapper
      className="setting-page"
      id={"nav-" + workflowType + "Workflow"}
    >
      <SettingSection>
        <div className="section-title">
          <div className="flex">
            <SectionIcon img={"workflow"} />
            <span>{type} Workflow</span>
            {showPrefilledTag && (
              <PrefilledTag>Prefilled From Project</PrefilledTag>
            )}
          </div>

          {isLinked && <LinkedInfoSettings from="Project" />}
          {!readOnly && !isLinked && (
            <Link to={editUrl}>
              <Button
                title="edit"
                onClick={() => {
                  if (showPrefilledTag) hideTag();
                }}
              />
            </Link>
          )}
        </div>

        {!hasWorkflow && (
          <>
            <div className="center">{guideMeg[workflowType]} </div>
            <WorkflowInfo defaultWorkflow={DefaultWorkflow} />
            <div className="notice-message">
              Private (not visible to consultants)
            </div>
          </>
        )}

        {hasWorkflow && (
          <>
            <WorkflowInfo
              data={sortedWorkflow}
              defaultWorkflow={DefaultWorkflow}
            />
            <div>
              <div className="notice-message">
                Private (not visible to consultants)
              </div>
            </div>
          </>
        )}
      </SettingSection>
    </ProjectDetailWrapper>
  );
}

export default connect(mapStateToProps, null)(Workflow);
