import React, { Fragment, useEffect, useState, useRef } from "react";
import commentEditIcon from "../../statics/img/icon-add-comment-medium-grey.svg";
import commentIcon from "../../statics/img/icon-add-comment-yellow.svg";
import {
  CommentContainer,
  CommentImg,
  InputCommentSection,
  ShareButton,
  CommentStyle,
  CommentAction,
} from "./styled";
import {
  GreenWhiteBtn as SaveBtn,
  GreyOutlinedBtn as CancelBtn,
} from "../button";
import { connect } from "react-redux";
import { checkIsParent } from "../../utils/helper";
import { convertUTCToTime } from "../../utils/dateTimeHelper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Input from "../form";
import { COMMENT_CHAR_LIMIT } from "../../utils/numberConstants";
import BootstrapTooltip from "../toolTip";

function getChildCommentSize(data, sum) {
  if (checkIsParent(data)) {
    data.childitems.forEach((item) => {
      sum += !item.comments ? 0 : item?.comments?.length;
      sum = getChildCommentSize(item, sum);
    });
  }
  return sum;
}

function calcSize(d, expands, viewOnly) {
  let num = !d.comments ? 0 : d.comments?.length;
  if (!expands.includes(d.id)) {
    let sum = 0;
    sum = getChildCommentSize(d, sum);
    num += sum;
  }
  if (num === 0) {
    if (!viewOnly) {
      return [commentEditIcon, ""];
    }
    return ["", ""];
  } else {
    return [commentIcon, num];
  }
}

const CommentItem = (props) => {
  const {
    data,
    profile,
    timezone,
    addComment,
    commentUpdate,
    deleteComment,
    isAdd,
    viewOnly,
    resetRef,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [content, setContent] = useState("");
  const [shared, setShared] = useState(false);
  const [error, setError] = useState({ limitExceeded: "" });

  const resetonClickAway = () => {
    if (isAdd) {
      setContent("");
      setError({ limitExceeded: "" });
    } else if (editMode) {
      setContent(data?.content);
      setEditMode(false);
    }
  };

  useEffect(() => {
    if (resetRef) {
      resetRef.current.push(resetonClickAway);
    }

    return () => {
      if (resetRef) {
        resetRef.current = resetRef.current.filter(
          (func) => func !== resetonClickAway,
        );
      }
    };
  }, [isAdd, editMode, resetRef]); // eslint-disable-line

  // initialise content and shared for comment edit or viewing mode
  useEffect(() => {
    if (!isAdd || editMode) {
      setContent(data.content);
      setShared(data.shared);
      setError({ limitExceeded: "" });
    }
  }, [data, editMode]); // eslint-disable-line

  const handleInput = (text) => {
    if (text.length > COMMENT_CHAR_LIMIT) {
      setError({ ...error, limitExceeded: "Character limit exceeded" });
    } else {
      setError({ ...error, limitExceeded: "" });
    }
    setContent(text);
  };

  const performAddComment = (content, shared) => {
    addComment({ comment: content, shared: shared });
    setContent("");
    setError({ ...error, limitExceeded: "" });
  };

  if (isAdd)
    return (
      <InputCommentSection>
        <div className="inputSection">
          <Input
            className={"inputField" + (error.limitExceeded ? " error" : "")}
            label="Comment"
            value={content}
            onChange={(e) => handleInput(e.target.value)}
          />

          <div className="buttons">
            <SaveBtn
              title="Add"
              className="button"
              disabled={content === "" || error.limitExceeded}
              onClick={() => performAddComment(content, shared)}
            />
            <CancelBtn
              title="Clear"
              className="button"
              onClick={() => {
                setContent("");
                setError({ ...error, limitExceeded: "" });
              }}
            />
          </div>
        </div>
        {error.limitExceeded && (
          <div className="errorMsg">Character limit exceeded</div>
        )}
        <div className="visibility">
          <ShareButton
            className={`${shared ? " shared" : ""}`}
            onClick={() => setShared(shared ? false : true)}
          >
            <div className={`icon ${shared ? " shared" : ""}`} />
          </ShareButton>
          <div className="description">
            {shared
              ? "Visible to other party"
              : "Not visible to other party, only people in your organisation can see this"}
          </div>
        </div>
      </InputCommentSection>
    );

  return (
    <CommentStyle>
      <div className="container top">
        <div className="userName">{data.user_name}</div>
        {!viewOnly && !editMode && data.user_email === profile.get("email") && (
          <div className="container action">
            <CommentAction onClick={() => setEditMode(true)}>
              <div className="icon editIcon" />
            </CommentAction>
            <CommentAction onClick={() => deleteComment(data.comment_id)}>
              <div className="icon deleteIcon" />
            </CommentAction>
          </div>
        )}
      </div>
      <div className="container content">
        {editMode ? (
          <InputCommentSection>
            <div className="inputSection">
              <Input
                className={"inputField" + (error.limitExceeded ? " error" : "")}
                label="Comment"
                value={content}
                onChange={(e) => handleInput(e.target.value)}
              />
              <div className="buttons">
                <SaveBtn
                  title="Save"
                  className="button"
                  disabled={content === "" || error.limitExceeded}
                  onClick={() => {
                    commentUpdate(data.comment_id, {
                      comment: content,
                      shared: shared,
                    });
                    setEditMode(false);
                  }}
                />
                <CancelBtn
                  title="Cancel"
                  className="button"
                  onClick={() => {
                    setContent(data.content);
                    setEditMode(false);
                  }}
                />
              </div>
            </div>
            {error.limitExceeded && (
              <div className="errorMsg">Character limit exceeded</div>
            )}
          </InputCommentSection>
        ) : (
          <Fragment>{content}</Fragment>
        )}
      </div>
      <div className="container bottom">
        <BootstrapTooltip
          title={
            shared
              ? "This comment is visible to the other party"
              : "This comment is only visible to people in your organisation"
          }
          placement="bottom"
        >
          {!data.is_other_party ? (
            !viewOnly && data.user_email === profile.get("email") ? (
              <CommentAction
                className="shareToggle"
                onClick={() =>
                  commentUpdate(data.comment_id, {
                    comment: data.content,
                    shared: !shared,
                  })
                }
              >
                <div className={`icon ${shared ? "shared" : ""}`} />
              </CommentAction>
            ) : (
              <CommentAction className="shareToggle noEdit">
                <div className={`icon ${shared ? "shared" : ""}`} />
              </CommentAction>
            )
          ) : (
            <div />
          )}
        </BootstrapTooltip>
        {convertUTCToTime(data.update_date, timezone, true)}
      </div>
    </CommentStyle>
  );
};

const CommentList = (props) => {
  const {
    data,
    expands,
    profile,
    claimInfo,
    certInfo,
    type,
    updateComment,
    onTreeExpand,
    viewOnly,
    otherParty,
  } = props;
  const timezone =
    type === "cert"
      ? certInfo?.get("contract_timezone")
      : claimInfo?.get("contract_timezone");

  const [open, setOpen] = useState(false);
  const [externalComments, setExternalComments] = useState([]);
  const [internalComments, setInternalComments] = useState([]);
  const [isIssuedClaimCert, setIsIssuedClaimCert] = useState(false);

  const childResetRefs = useRef([]);

  useEffect(() => {
    if (data.claim_status === "approved") {
      setIsIssuedClaimCert(true);
    }
  }, [data]);

  // separate external shared and internal comments
  useEffect(() => {
    let external = [],
      internal = [];
    data?.comments?.forEach((comment) => {
      if (comment.is_other_party) {
        external.push(comment);
      } else {
        internal.push(comment);
      }
    });

    setExternalComments(external);
    setInternalComments(internal);
  }, [data?.comments]);

  const handleClickAway = () => {
    if (childResetRefs.current) {
      childResetRefs.current.forEach((resetFunction) => {
        if (typeof resetFunction === "function") {
          resetFunction();
        }
      });
    }
    setOpen(false);
  };

  let commentCount = calcSize(data, expands, viewOnly)[1];
  let countView =
    (checkIsParent(data) && expands.includes(data?.id)) || commentCount > 0;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="action_container">
        {viewOnly ? (
          <CommentImg
            size={calcSize(data, expands, viewOnly)[0]}
            onClick={() => {
              if (checkIsParent(data) && !expands.includes(data?.id)) {
                onTreeExpand(data?.id);
              } else if (commentCount > 0) {
                setOpen(!open);
              }
            }}
          >
            {commentCount}
          </CommentImg>
        ) : (
          <CommentImg
            size={
              countView ? calcSize(data, expands, viewOnly)[0] : commentEditIcon
            }
            onClick={() => {
              // expand tree if the line item is a parent and the tree is not expanded, otherwise open comments
              if (checkIsParent(data) && !expands.includes(data?.id)) {
                onTreeExpand(data?.id);
              } else {
                setOpen(!open);
              }
            }}
          >
            {countView && commentCount}
          </CommentImg>
        )}
        {(!checkIsParent(data) ||
          expands.includes(data.id) ||
          (checkIsParent(data) && data.comments?.length === commentCount)) && (
          <CommentContainer show={open}>
            {!isIssuedClaimCert && !viewOnly && (
              <div className="title">
                <div className="header">
                  <span>Add a comment</span>
                  <div className="cancelIcon" onClick={handleClickAway} />
                </div>
                <CommentItem
                  isAdd
                  addComment={(comment) =>
                    updateComment("post", comment, "", data.id)
                  }
                  resetRef={childResetRefs}
                />
              </div>
            )}
            <div className="comments">
              {internalComments.map((comment, i) => (
                <Fragment key={i}>
                  {(i > 0 || !viewOnly) && <div className="divider" />}
                  <CommentItem
                    data={comment}
                    profile={profile}
                    timezone={timezone}
                    viewOnly={viewOnly || isIssuedClaimCert}
                    commentUpdate={(commentId, update) =>
                      updateComment("put", update, commentId, data.id)
                    }
                    deleteComment={(commentId) =>
                      updateComment("delete", "", commentId, data.id)
                    }
                    resetRef={childResetRefs}
                  />
                </Fragment>
              ))}
              {externalComments?.length > 0 && (
                <div className="banner">
                  Other Party <div className="party-name">{otherParty}</div>
                </div>
              )}
              {externalComments.map((comment, i) => (
                <Fragment key={i}>
                  {i > 0 && <div className="divider" />}
                  <CommentItem
                    data={comment}
                    profile={profile}
                    timezone={timezone}
                    viewOnly={true}
                  />
                </Fragment>
              ))}
            </div>
            <div className="tail" />
          </CommentContainer>
        )}
      </div>
    </ClickAwayListener>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.getIn(["headers", "profile"]),
    certInfo: state.getIn(["makeacert", "projectlist"]),
    claimInfo: state.getIn(["makeaclaim", "contractInfo"]),
  };
};

export default connect(mapStateToProps, null)(CommentList);
