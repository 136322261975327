import { httpRequest } from "../base/httpRequest";

const BASE_URL = `/api/contract/account/accId`;
const CONTRACT_URL = `/api/contract/account/accId/contract_group`;

const read_default_contract = (proId) =>
  httpRequest("get", `${CONTRACT_URL}/${proId}/default`);

const read_contract = (proId, contractId) =>
  httpRequest("get", `${CONTRACT_URL}/${proId}/contract/${contractId}`); //data

const read_contract_role = (proId, contractId) =>
  httpRequest("get", `${CONTRACT_URL}/${proId}/contract/${contractId}/role`);

const upsert_contract = (proId, contractId, data) =>
  httpRequest("patch", `${CONTRACT_URL}/${proId}/contract/${contractId}`, data);

const read_contract_history = (proId, contractId, page, pageSize) =>
  httpRequest(
    "get",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/history?page=${page}&limit=${
      pageSize || 20
    }`,
  );

const export_contract_history = (proId, contractId, config) =>
  httpRequest(
    "GET",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/history/export`,
    null,
    config,
  );

const create_one_contract = (proId, type, data) =>
  httpRequest("post", `${CONTRACT_URL}/${proId}?type=${type}`, data); //new contract

const upsert_contract_retention = (proId, contractId, data) =>
  httpRequest(
    "post",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/retention`,
    data,
  );

const remove_contract_retention = (proId, contractId) =>
  httpRequest(
    "delete",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/retention`,
  );

const read_contract_member = (proId, contractId) =>
  httpRequest("get", `${CONTRACT_URL}/${proId}/contract/${contractId}/members`);

const update_contract_member = (proId, contractId, data) =>
  httpRequest(
    "put",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/members`,
    data,
  );

const leave_contract = (proId, contractId) =>
  httpRequest("put", `${CONTRACT_URL}/${proId}/contract/${contractId}/leave`);

const start_contract = (proId, contractId) =>
  httpRequest("put", `${CONTRACT_URL}/${proId}/contract/${contractId}/start`);

const finalise_contract = (proId, contractId) =>
  httpRequest(
    "PUT",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/finalise`,
  );

const reopen_contract = (proId, contractId) =>
  httpRequest("PUT", `${CONTRACT_URL}/${proId}/contract/${contractId}/reopen`);

const archive_contract = (proId, contractId) =>
  httpRequest("PUT", `${CONTRACT_URL}/${proId}/contract/${contractId}/archive`);

const move_contract_to_project = (contractId, projectId, proId) =>
  httpRequest("patch", `${CONTRACT_URL}/${proId}/contract/${contractId}`, {
    contract_group: projectId,
  });

const update_contract_workflow = (proId, contractId, type, data) =>
  httpRequest(
    "POST",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/${type}/workflows`,
    data,
  );

//invitation
const send_contract_invitation = (proId, contractId, type, data) =>
  httpRequest(
    "post",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/invite?type=${type}`,
    data,
  );

const read_contract_invitations = (proId, contractId) =>
  httpRequest(
    "get",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/invitation/list`,
  );

const accept_invitation = (accId, projectId, contactId, invitationId) =>
  httpRequest(
    "put",
    `/api/contract/account/${accId}/contract_group/${projectId}/contact/${contactId}/invitation/${invitationId}/accept`,
  );

const edit_invitation = (invitationId, data) =>
  httpRequest("put", `${BASE_URL}/invitation/${invitationId}/update`, data);

const delete_one_invitation = (invitationId) =>
  httpRequest("delete", `${BASE_URL}/invitation/${invitationId}/delete`);

const decline_invitation = (invitationId) =>
  httpRequest("put", `/api/contract/invitation/${invitationId}/cancel`);

const read_invitation = (invitationId) =>
  httpRequest("get", `/api/contract/invitation/${invitationId}/read`);

// contract documents
const attach_contract_attachment = (proId, contractId, type, data, config) =>
  httpRequest(
    "POST",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/attach?type=${type}`,
    data,
    config,
    { "Content-Type": "multipart/form-data" },
  );

const read_contract_attachments = (proId, contractId) =>
  httpRequest(
    "GET",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/read_attachments`,
  );

const delete_contract_attachment = (proId, contractId, fileId) =>
  httpRequest(
    "DELETE",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/delete_attachment/${fileId}`,
  );

const update_contract_attachment = (proId, contractId, updateData) =>
  httpRequest(
    "PUT",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/update_attachment`,
    updateData,
  );

const download_contract_attachment = (proId, contractId, fileId, config) =>
  httpRequest(
    "GET",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/download_attachment/${fileId}`,
    null,
    config,
  );

const self_invitation = (proId, contractId, companyId, contactId) =>
  httpRequest(
    "post",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/company/${companyId}/contact/${contactId}`,
  );

const copy_project_info = (invitationId, accountId) =>
  httpRequest(
    "post",
    `/api/contract/account/${accountId}/invitation/${invitationId}/copy`,
  );

//download both party shared contract files
const download_contract_shared_file = (proId, contractId, fileId, config) =>
  httpRequest(
    "get",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/file/${fileId}`,
    null,
    config,
  ); //blob

const calculate_retention = (proId, contractId, data) =>
  httpRequest(
    "post",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/retention/cal`,
    data,
  );

//create company
const create_contract_company = (proId, contractId, data) =>
  httpRequest(
    "post",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/company`,
    data,
  );
//create contact
const create_contract_contact = (proId, contractId, companyId, data) =>
  httpRequest(
    "post",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/company/${companyId}/contact`,
    data,
  );
const check_bcti = (proId, contractId, view) =>
  httpRequest(
    "get",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/bcti?view=${view}`,
  );

//download contract summary csv report
const download_contract_csv_summary = (proId, contractId, config) =>
  httpRequest(
    "GET",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/report`,
    null,
    config,
  );

const download_contract_retention_report = (proId, contractId, config) =>
  httpRequest(
    "GET",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/download_retention/report`,
    null,
    config,
  );

//read contract config
const read_contract_config = (proId, contractId) =>
  httpRequest("get", `${CONTRACT_URL}/${proId}/contract/${contractId}/config`);

export default {
  read_contract_config,
  download_contract_csv_summary,
  create_contract_company,
  create_contract_contact,
  read_default_contract,
  read_contract,
  read_contract_role,
  read_contract_history,
  export_contract_history,
  upsert_contract,
  create_one_contract,
  upsert_contract_retention,
  remove_contract_retention,
  read_contract_member,
  update_contract_member,
  leave_contract,
  start_contract,
  finalise_contract,
  reopen_contract,
  archive_contract,
  move_contract_to_project,
  update_contract_workflow,
  send_contract_invitation,
  read_contract_invitations,
  accept_invitation,
  edit_invitation,
  decline_invitation,
  delete_one_invitation,
  read_invitation,
  attach_contract_attachment,
  read_contract_attachments,
  delete_contract_attachment,
  update_contract_attachment,
  download_contract_attachment,
  self_invitation,
  copy_project_info,
  download_contract_shared_file,
  download_contract_retention_report,
  calculate_retention,
  check_bcti,
};
