import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import debounce from "lodash/debounce";

import {
  calculateSubunitToUnit,
  calculateUnitToSubunit,
} from "../../../utils/currencyHelper";
import API from "../../../server";
import { actionCreators } from "../store";
import { roundNumber } from "../../../utils/numeralHelper";
import ClaimCertSummaryComponent from "pages/makeclaim/shared/newSummaryComponent";
import {
  SUMMARY_TYPE,
  RETENTION_VALUE_ACCRUED_RETENTION,
} from "utils/constants";

// new cert summary component
const CertSummary = (props) => {
  const {
    summaryComponent,
    handleUpdate,
    hasRetention,
    previousRef,
    baseCertTotal,
    variationCertTotal,
    materialsTotal,
    baseCertTotalExcludeRetention,
    variationCertTotalExcludeRetention,
    materialsTotalExcludeRetention,
    proId,
    contractId,
    claimRetentionValue,
    lessRetention,
    calculateCertSummary,
    retentionOption,
    calculatedCisStatus,
  } = props;
  const dispatch = useDispatch();

  const [summaryData, setSummaryData] = useState({});
  const [pcdMax, setPcdMax] = useState(0);
  const [dlpMax, setDlpMax] = useState(0);
  const [showRetentionOption, setShowRetentionOption] = useState(false);

  useEffect(() => {
    setSummaryData(summaryComponent.toJS());
  }, [summaryComponent]);

  useEffect(() => {
    if (!showRetentionOption) {
      changeReleaseValue("retentionOption", null);
    }
    if (retentionOption === RETENTION_VALUE_ACCRUED_RETENTION) {
      const retentionValues = {
        cert_retention_pcrr: parseFloat(pcdMax),
        cert_retention_dlrr: parseFloat(dlpMax),
      };
      handleUpdate(retentionValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pcdMax, dlpMax, retentionOption]);

  const requestRetention = debounce((total) => getLessRetention(total), 750);
  const getLessRetention = (total) => {
    // based on claimed value to display less retention
    API.calculate_retention(proId, contractId, {
      gross: Math.round(calculateUnitToSubunit(total)),
    }).then((response) => {
      const {
        data: { retention, pcd_value, dlp_value },
      } = response.data.data;
      changeReleaseValue("lessRetention", calculateSubunitToUnit(retention));
      setPcdMax(Math.round(pcd_value));
      setDlpMax(Math.round(dlp_value));
    });
  };

  const changeReleaseValue = (name, value) => {
    dispatch(actionCreators.setData(name, value));
  };

  useEffect(() => {
    if (proId && contractId) {
      requestRetention(
        baseCertTotalExcludeRetention +
          variationCertTotalExcludeRetention +
          calculateSubunitToUnit(
            materialsTotalExcludeRetention?.get("onSite"),
          ) +
          calculateSubunitToUnit(
            materialsTotalExcludeRetention?.get("offSite"),
          ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    baseCertTotal,
    baseCertTotalExcludeRetention,
    variationCertTotal,
    variationCertTotalExcludeRetention,
    materialsTotal,
    materialsTotalExcludeRetention,
    proId,
    contractId,
  ]);

  useEffect(() => {
    const claimPcd = claimRetentionValue?.get("pcd") || 0,
      claimDlp = claimRetentionValue?.get("dlp") || 0;

    const roundedLessRetention =
      typeof lessRetention === "number" ? roundNumber(lessRetention) : 0;

    if (roundedLessRetention < roundNumber(claimPcd + claimDlp)) {
      //when claim retention is more than cert retention, show the retention bar
      if (!showRetentionOption) {
        setShowRetentionOption(true);
      }
    } else {
      setShowRetentionOption(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessRetention, showRetentionOption]);

  useEffect(() => {
    const data = {
      baseCertTotal: baseCertTotal,
      variationCertTotal: variationCertTotal,
      materialsTotal: materialsTotal.toJS(),
      lessRetention: lessRetention,
      cisStatus: calculatedCisStatus,
    };
    calculateCertSummary(data);
    // eslint-disable-next-line
  }, [
    baseCertTotal,
    variationCertTotal,
    materialsTotal,
    lessRetention,
    calculatedCisStatus,
  ]);

  return (
    <ClaimCertSummaryComponent
      readOnly={false}
      summaryType={SUMMARY_TYPE.cert}
      retentionExists={hasRetention}
      previousRef={previousRef}
      currentToDateData={summaryData?.certToDate || {}}
      previousData={summaryData?.previous || {}}
      previousClaimData={summaryData?.claimedToDate || {}}
      thisCalculatedData={summaryData?.thisCert || {}}
      claimReleaseRetention={false}
      handleDropdownChange={(val) => handleUpdate({ previous_option: val })}
      handleNumberChange={(field, val) =>
        handleUpdate({ [field]: parseFloat(val) * 100 })
      }
      pcdMax={pcdMax}
      dlpMax={dlpMax}
      changeReleaseValue={changeReleaseValue}
      showRetentionOption={showRetentionOption}
      calculatedCisStatus={calculatedCisStatus}
      {...props}
    />
  );
};

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
  // New Summary required props
  previousRef: state.getIn(["makeacert", "previousRef"]),
});

export default connect(mapStateToProps, null)(CertSummary);
