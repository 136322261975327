import styled from "styled-components";
import * as theme from "../../../theme";
import settingIcon from "../../../statics/img/icon-setting.svg";
import editIcon from "../../../statics/img/icon-edit-medium-grey.svg";
import periodIcon from "../../../statics/img/horizontal_stripes.svg";
import downloadIcon from "../../../statics/img/download-file-medium-grey.svg";
import removeIcon from "../../../statics/img/icon-clear-medium-grey.svg";
import noticeIcon from "../../../statics/img/icon-information-yellow.svg";

export const ContractInfoWrapper = styled.div`
  .title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 8px;
    display: flex;
  }
  width: 100%;
  .trade {
    color: ${theme.palette.veryMediumGrey};
    margin-bottom: 16px;
  }
  .info-list {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    label {
      display: flex;
    }
  }
  .claim-button,
  .finalise-button {
    float: left;
    margin: 0 16px 0 0;
  }
  leave-button {
    float: right;
  }
  .setting-icon {
    width: 52px;
    height: 40px;

    border: 1px solid ${theme.palette.gunmetal};
    border-radius: 4px;
    background: url(${settingIcon}) no-repeat;
    float: left;
  }
  .sub-wrapper {
    margin-bottom: 32px;
    &.split {
      display: flex;
      justify-content: space-between;
    }
    .right {
      display: flex;
      justify-content: space-between;
      width: 351px;
      float: right;
    }
    &.flex-row {
      justify-content: center;
      align-items: center;
      padding: 12px 16px;
      gap: 8px;
      width: 100%;
      height: 44px;
      background: ${theme.palette.veryWarmLightGrey};
      display: flex;
    }
    .bcti {
      padding: 8px 16px;
      background-color: ${theme.palette.veryWarmLightGrey};
      width: 100%;
      margin-bottom: 4px;
      line-height: 24px;
    }
  }
`;

export const StatusTag = styled.div`
  box-sizing: border-box;
  height: 28px;
  width: max-content;
  padding: 4px 8px;
  margin: 0 0 0 16px;
  font-size: 16px;
  border-radius: 4px;

  &.current {
    background-color: ${theme.palette.lightPastelBlue};
    color: ${theme.palette.tealBlue};
  }
  &.completed {
    background-color: ${theme.palette.lightPastelGreen};
    color: ${theme.palette.brightGreen};
  }
  &.archived {
    background-color: ${theme.palette.warmLightGrey};
    color: ${theme.palette.veryMediumGrey};
  }
`;

export const Table = styled.div`
  margin: 32px 0 44px 0;
  box-sizing: border-box;
  line-height: 17px;

  .label-row {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    display: grid;
    grid-template-columns: 130px 371px 371px;
    grid-column-gap: 4px;
    height: 33px;
    text-transform: uppercase;

    div {
      padding: 8px 16px;
    }
    .claim {
      background-color: ${theme.palette.lightPastelGreen};
      color: ${theme.palette.brightGreen};
    }
    .certificate {
      background-color: ${theme.palette.lightPastelBlue};
      color: ${theme.palette.tealBlue};
    }
  }

  table {
    width: 100%;
    box-sizing: border-box;
    border-collapse: collapse;
    table-layout: fixed;

    th,
    td {
      padding: 8px 16px;

      &.separator {
        width: 4px;
        background-color: ${theme.palette.white};
        padding: 0;
      }
      &.percent {
        width: 180px;
      }
      &.value {
        width: 191px;
      }

      &.period-column {
        width: 130px;
      }
      .period {
        display: flex;
        justify-content: space-between;
        .icon {
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-position: center;

          &.descending {
            background-image: url(${periodIcon});
          }
          &.ascending {
            background-image: url(${periodIcon});
            transform: scaleY(-1);
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    thead {
      font-weight: bold;
      font-size: 14px;
      text-align: left;

      tr {
        height: 33px;
        th {
          background-color: ${theme.palette.gunmetal};
          color: ${theme.palette.white};
        }
      }
    }

    tbody {
      tr {
        height: 44px;
      }
      tr:nth-child(odd) {
        background-color: ${theme.palette.veryColdGrey};
      }

      .amount {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .amount-tooltip {
    display: inline-block;
  }
`;

export const LineItemBtn = styled.div`
  &.draft {
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 24px;
      height: 24px;
      background-image: url(${editIcon});
    }
  }
  &.draft:hover {
    border-radius: 50%;
    background-color: ${theme.palette.lightGrey};
    cursor: pointer;
  }

  &.button {
    font-size: 12px;
    font-weight: bold;
    height: 24px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: ${theme.palette.white};

    &.view {
      background-color: ${theme.palette.gunmetal};
      &:hover {
        background-color: #72808a;
      }
      &:active {
        background-color: #5e6e7a;
      }
    }
    &.cert {
      background-color: ${theme.palette.tealBlue};
    }
    &.save {
      background-color: ${theme.palette.brightGreen};
    }
    &.cancel {
      background-color: ${theme.palette.veryWarmLightGrey};
      color: ${theme.palette.veryMediumGrey};
      border: solid 1px ${theme.palette.wolfGrey};
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

export const HoverDiv = styled.div`
  height: max-content;
  position: relative;

  &:hover > .tooltip {
    display: block;
  }
`;

export const ToolTip = styled.div`
  &.tooltip {
    display: none;
  }

  background-color: #595c5c; // not in palette
  color: ${theme.palette.white};
  font-size: 12px;
  border-radius: 4px;
  padding: 4px 8px;
  text-align: center;
  z-index: 10;

  position: absolute;
  min-width: max-content;
  min-height: 40px;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + 8px);

  &.value {
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;
  }

  .bold {
    font-weight: bold;
  }

  .status {
    text-transform: capitalize;
  }

  .grid {
    display: grid;
    grid-template-rows: 20px 20px;
    grid-template-columns: auto auto;
    column-gap: 20px;

    .align-right {
      text-align: right;
    }
  }
`;

export const ClaimHistoryModal = styled.div`
  height: auto;
  padding: 0 40px 40px;
  display: grid;
  grid-template-rows: 20px auto;
  row-gap: 32px;
  color: ${theme.palette.veryMediumGrey};
  .comment {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 820px;
    height: 32px;

    background: ${theme.palette.paleYellow};
    border-radius: 2px;
    color: ${theme.palette.gunmetal};
    .icon {
      width: 24px;
      height: 24px;
      background: url(${noticeIcon}) no-repeat;
      transform: rotate(180deg);
      margin-right: 8px;
    }
  }
`;

export const ModalPagination = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const BasicTable = styled.table`
  text-align: left;
  color: ${theme.palette.gunmetal};
  border-collapse: collapse;
  box-sizing: border-box;
  table-layout: fixed;

  .align-right {
    text-align: right;
  }
  .align-center {
    text-align: center;
  }

  tr {
    height: 44px;
  }

  th,
  td {
    border: solid 1px ${theme.palette.white};
    padding: 8px 16px;

    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
  }

  thead {
    color: ${theme.palette.white};
    background-color: ${theme.palette.gunmetal};
  }

  tbody {
    tr:nth-child(even) {
      background-color: ${theme.palette.veryColdGrey};
    }
  }
`;

export const PaymentLineitem = styled.tr`
  &.edit {
    background-color: ${theme.palette.paleYellow} !important;
    .input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .paid-input {
        width: 100px;
        height: 20px;
        font-size: 16px;
      }
    }

    .actions {
      display: flex;
      justify-content: space-around;
    }
  }

  &.view {
  }

  .files {
    display: flex;
    justify-content: space-around;

    &.grid {
      width: 166px;
      height: 20px;
      display: grid;
      grid-template-columns: auto 20px;
      column-gap: 4px;

      .attached {
        width: 142px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .remove {
        width: 20px;
        height: 20px;
        background-image: url(${removeIcon});
        background-size: cover;
      }
      .download {
        width: 20px;
        height: 20px;
        background-image: url(${downloadIcon});
        background-size: cover;
      }

      span:hover {
        cursor: pointer;
      }
    }
  }
`;

export const ReceivedAmountCell = styled.div`
  display: grid;
  grid-template-columns: auto 27px;
  column-gap: 12px;

  .amount {
    display: inline-flex;
    align-items: center;
    flex-direction: row-reverse;
  }
`;
