import React, { Fragment, useState, useEffect } from "react";
import { format, isValid } from "date-fns";
import { connect } from "react-redux";

import * as theme from "../../theme";
import { FormGroup, ProvisionalToggle } from "../form/styled";
import {
  AddaVariationWrapper,
  AttachLeft,
  ImgClose,
  ConvertButton,
} from "./styled";

import { DeleteBtn, AddGreenBtn, GreenWhiteBtn as SaveBtn } from "../button";
import Input from "../form";
import ToggleButtons from "./toggleButtons";
import Date from "../datePicker";
import Modal from "../modal/component/modalBase";
import { AntSwitch } from "../switch";
import BootstrapTooltip from "../toolTip";
import { LUMP, RATE } from "../../utils/constants";
import FileUpload from "./uploadItemFile";
import {
  usePrevious,
  LineItemForm,
  ExcludeRetentionBox,
  ConvertModal,
} from "./sharedComponent";
import { actionCreators as actionCreatorsFile } from "../upload_file_progress/store";

// field == dialog : add variation when making cert; will show in a dialog
// status == overview :project/contract overview page
// type === "parentItem" : add vari for parent items

const InputFields = (props) => {
  const {
    tableList, //new input list
    title,
    handleClose, //close input frame
    handleSaveItem, //create or update
    handleCleanItem, //clean input or delete item
    status,
    type,
    action,
    field,
    isBlocked,
    canDelete,
    accountConfig,
    lineType,
    setLineType,
    formInfo,
    handleChange,
    handleChangeDate,
    isProvisional,
    handleSwitch,
    isDraft,
    openConvertModal,
    allowExcludeRetention,
    // cert vari line item files
    cancelAttachment,
    setFileList,
    deleteAttachment,
  } = props;

  const ExcludeRetention = () => (
    <>
      {allowExcludeRetention && (
        <ExcludeRetentionBox
          checked={formInfo?.exclude_retention}
          onChange={(e) => handleChange("exclude_retention", e.target.checked)}
          disabled={formInfo?.approved_up_to_date_total}
        />
      )}
    </>
  );

  return (
    <AddaVariationWrapper
      style={
        type === "parentItem" && field !== "dialog"
          ? {
              border: `1px solid ${theme.palette.tangerineOrange}`,
              margin: " 12px auto",
            }
          : field === "dialog"
            ? { marginTop: "0px", width: "1060px" }
            : { marginTop: "5px" }
      }
    >
      <FormGroup>
        {field !== "dialog" ? (
          <div className="form_title">
            {title}
            <div className="buttons">
              {(tableList === undefined ||
                tableList?.childitems?.length === 0) && (
                <Fragment>
                  {/*
                    - Show provisional toggle only when contract is in draft or when adding in new line items.
                    - Show switch to fixed button when editing provisionals.
                  */}
                  {isDraft || (!isDraft && action === "add") ? (
                    <ProvisionalToggle>
                      <AntSwitch
                        checked={isProvisional}
                        onChange={handleSwitch}
                        name="provisional-toggle"
                      />
                      <div>Provisional</div>
                      <BootstrapTooltip
                        title="Enabling this setting will allow the line item to be editable when the contract has become active."
                        placement="bottom"
                      >
                        <div className="info-icon" />
                      </BootstrapTooltip>
                    </ProvisionalToggle>
                  ) : (
                    tableList.is_provisional && (
                      <ConvertButton onClick={() => openConvertModal()}>
                        <div className="convert-icon" />
                        Convert to Fixed
                      </ConvertButton>
                    )
                  )}
                  <ToggleButtons
                    buttonWidth="137px"
                    buttons={[
                      { label: "Lump Sum", value: LUMP },
                      { label: "Qty & Rate", value: RATE },
                    ]}
                    setValue={(type) => setLineType(type)}
                    defaultValue={lineType}
                    isBlocked={isBlocked}
                  />
                </Fragment>
              )}
              <ImgClose onClick={handleClose} />
            </div>
          </div>
        ) : (
          <div className="buttons-dialog">
            {type !== "parentItem" && (
              <>
                <ToggleButtons
                  buttonWidth="137px"
                  buttons={[
                    { label: "Lump Sum", value: LUMP },
                    { label: "Qty & Rate", value: RATE },
                  ]}
                  setValue={(type) => setLineType(type)}
                  defaultValue={lineType}
                  isBlocked={isBlocked}
                />

                {!tableList.childitems?.length > 0 && (
                  <ProvisionalToggle>
                    <AntSwitch
                      checked={isProvisional}
                      onChange={handleSwitch}
                      name="provisional-toggle"
                    />
                    <div>Provisional</div>
                    <BootstrapTooltip
                      title="Enabling this setting will allow the line item to be editable when the contract has become active."
                      placement="bottom"
                    >
                      <div className="info-icon" />
                    </BootstrapTooltip>
                  </ProvisionalToggle>
                )}
              </>
            )}
          </div>
        )}
        <LineItemForm
          onChange={handleChange}
          form={formInfo}
          status={status}
          isBlocked={isBlocked}
          lineitemType={lineType}
          accountConfig={accountConfig}
          isParent={tableList?.childitems?.length > 0}
          field={field}
        />
        <div className="form_body" style={{ margin: "40px auto" }}>
          <Input label="Submit Date" field="child">
            <Date
              handleChange={(e) => handleChangeDate("submit_date", e)}
              initialValue={formInfo?.submit_date}
              width={status === "overview" ? "350px" : "250px"}
            />
          </Input>
          <Input label="Approved Date" field="child">
            <Date
              handleChange={(e) => handleChangeDate("approved_date", e)}
              initialValue={formInfo?.approved_date}
              width={status === "overview" ? "350px" : "250px"}
            />
          </Input>
          <Input
            label="Variation Reference"
            name="variation_reference"
            value={formInfo?.variation_reference}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            width={status === "overview" ? "387px" : "287px"}
          />
        </div>

        <div className="form_body cert_vari">
          <div className="large_container">
            <Input field="child">
              <AttachLeft>
                <div className="title">Variation Attachments</div>
                <div className="uploadFileWrapper">
                  {field !== "dialog" ? (
                    <FileUpload {...props} />
                  ) : (
                    <FileUpload
                      title={title}
                      files={props.files}
                      setFileList={setFileList}
                      handleFilesChange={(files) => {
                        title.includes("Edit")
                          ? props.uploadAttachments(files, tableList.id)
                          : props.handleFilesChange(files);
                      }}
                      handleFileCancel={(file) => {
                        title.includes("Edit")
                          ? deleteAttachment(file, tableList.id)
                          : cancelAttachment(file);
                      }}
                    />
                  )}
                </div>
              </AttachLeft>
            </Input>
            <Input
              label="Detailed Description"
              name="detailed_description"
              value={formInfo?.detailed_description}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              width={status === "overview" ? "600px" : "438px"}
              field="textarea"
            />
          </div>
          {field === "dialog" && <ExcludeRetention />}
        </div>
        {field !== "dialog" && (
          <div className="form_footer">
            <ExcludeRetention />
            {/* avoid to delete parent / certified items */}
            {canDelete && (
              <DeleteBtn
                title="Delete"
                onClick={handleCleanItem}
                margin="0 30px 0 0"
              />
            )}
            {action === "add" && (
              <AddGreenBtn
                title="Add"
                type="submit"
                onClick={() => handleSaveItem(lineType, formInfo)}
              />
            )}
            {action === "save" && (
              <SaveBtn
                title="Save"
                type="submit"
                onClick={() => handleSaveItem(lineType, formInfo)}
              />
            )}
          </div>
        )}
      </FormGroup>
    </AddaVariationWrapper>
  );
};

const InputTable = (props) => {
  const {
    tableList, //new input list
    title,
    handleClose, //close input frame
    handleSaveItem, //create or update
    handleCleanItem, //clean input or delete item
    action,
    field,
    previousLineType, // line type of latest added line item
    setEditFiles,
    // modal related
    modalOpen,
  } = props;
  const [lineType, setLineType] = useState(RATE);
  const prevLineType = usePrevious(lineType); // previous value of lineType

  const [formInfo, setFormInfo] = useState({
    label: "",
    description: "",
    quantity: 0,
    unit: "",
    rate: 0,
    detailed_description: "",
    variation_reference: "",
    submit_date: "",
    approved_date: "",
    lineitem_options: RATE,
    total: 0,
    is_provisional: false,
    exclude_retention: false,
  });
  const numValues = ["quantity", "rate", "total"];
  const [convertModalOpen, setConvertModalOpen] = useState(false);

  useEffect(() => {
    if (action === "add") {
      // copy previous added lineitem option/type (if there's one)
      setLineType(previousLineType ? previousLineType : RATE);
      setEditFiles();
    } else {
      // initialise values
      let newInfo = {};
      for (let [key, value] of Object.entries(tableList)) {
        if (key === "lineitem_options") {
          setLineType(value);
        } else if (numValues.includes(key)) {
          // for display
          newInfo[key] = value / 100;
        } else {
          newInfo[key] = value;
        }
      }
      setFormInfo(newInfo);
    }
  }, [tableList, action]); // eslint-disable-line

  useEffect(() => {
    // only works for lineType changes made by user and not from initialisation
    if (prevLineType && prevLineType !== lineType) {
      let recalculate = { ...formInfo };
      if (lineType === RATE) {
        recalculate.quantity = 1;
      } else if (lineType === LUMP) {
        recalculate.quantity = 1;
        recalculate.rate = formInfo.quantity * formInfo.rate;
      }

      setFormInfo({ ...recalculate });
    }
  }, [lineType]); // eslint-disable-line

  const handleChange = (name, value) => {
    setFormInfo({ ...formInfo, [name]: value });
  };

  const handleChangeDate = (name, e) => {
    if (e && isValid(e)) {
      let date = format(e, "yyyy-MM-dd");
      setFormInfo({ ...formInfo, [name]: date });
    }
  };

  if (field === "dialog")
    return (
      <Modal
        handleClose={() => {
          handleClose();
          // clean up redux file list when closing
          props.initialFileList();
        }}
        open={modalOpen}
        title={title}
        width="1060px"
      >
        <InputFields
          {...props}
          lineType={lineType}
          setLineType={setLineType}
          formInfo={formInfo}
          handleChange={handleChange}
          handleChangeDate={handleChangeDate}
          isProvisional={formInfo.is_provisional}
          handleSwitch={() =>
            handleChange("is_provisional", !formInfo.is_provisional)
          }
        />
        <div>
          {(action === "adding_item" || action === "adding_child_item") && (
            <AddGreenBtn
              title="Add"
              type="submit"
              onClick={() => handleSaveItem(lineType, formInfo)}
            />
          )}
          {action === "editing_item" && (
            <AddGreenBtn
              title="Save"
              type="submit"
              onClick={() => handleSaveItem(lineType, formInfo)}
            />
          )}
          {action === "save" && (
            <SaveBtn
              title="Save"
              type="submit"
              onClick={() => handleSaveItem(lineType, formInfo)}
            />
          )}
          <DeleteBtn
            title="Delete"
            onClick={handleCleanItem}
            margin="0 30px 0 0"
          />
        </div>
      </Modal>
    );

  return (
    <Fragment>
      <InputFields
        {...props}
        lineType={lineType}
        setLineType={setLineType}
        formInfo={formInfo}
        handleChange={handleChange}
        handleChangeDate={handleChangeDate}
        isProvisional={formInfo?.is_provisional}
        handleSwitch={() =>
          handleChange("is_provisional", !formInfo?.is_provisional)
        }
        openConvertModal={() => setConvertModalOpen(true)}
      />
      <ConvertModal
        open={convertModalOpen}
        setOpen={setConvertModalOpen}
        data={tableList}
        convert={() => {
          handleChange("is_provisional", false);
          handleSaveItem(lineType, { ...formInfo, is_provisional: false });
        }}
      />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setFileList(files) {
    dispatch(actionCreatorsFile.setUploadFile(files));
  },
});

export default connect(null, mapDispatchToProps)(InputTable);
