import React, { PureComponent, Fragment } from "react";
import { ProjectBottomWrapper } from "../styled";
import {
  RedOutlinedBtn as Discard,
  BlueOutlinedBtn as BlueButton,
  NextGreenBtn as Next,
} from "../../../common/button";
import Link from "../../../common/linkWithQuery";

class NavBarBottom extends PureComponent {
  render() {
    const {
      handleSaveReview,
      handleCancel,
      handleSave,
      claimId,
      previousPagePath,
      readOnly,
    } = this.props;
    return (
      <ProjectBottomWrapper>
        <Link to={previousPagePath}>
          <BlueButton
            float="left"
            title="EXIT"
            margin="0 30px 0 0"
            padding="10px 32px"
          />
        </Link>
        {!readOnly && (
          <Fragment>
            <Discard
              disabled={claimId === undefined}
              onClick={handleCancel}
              title="DISCARD"
              float="left"
            />
            <Next onClick={handleSaveReview} title="NEXT" />
            <BlueButton
              onClick={handleSave}
              title="SAVE"
              margin="0 30px 0 0"
              padding="10px 32px"
            />
          </Fragment>
        )}
      </ProjectBottomWrapper>
    );
  }
}

export default NavBarBottom;
