import React from "react";
import { UploadProgressBar } from "./styled";
import { bytesToSize } from "../../../utils/helper";

export const UploadingBar = (props) => {
  const { file, progress, width } = props;

  return (
    <UploadProgressBar width={width}>
      <div className="info">
        <div className="spin-icon" />
        <div className="title">
          <div className="file-name">{file.name}</div>
          <span className="file-size">({bytesToSize(file.size)})</span>
        </div>
        <div className="message">
          {progress === 100 ? <>Complete</> : <>uploading... {progress}%</>}
        </div>
      </div>
      <div className="progress-bar">
        <div className="progress" style={{ width: `${progress}%` }} />
      </div>
    </UploadProgressBar>
  );
};

const UploadItem = (props) => {
  return (
    <div className="file_item">
      <UploadingBar {...props} />
    </div>
  );
};

export default UploadItem;
