import React, { Component } from "react";

import { TableStyle, CellSubtitle } from "./styled";

import TableComponent from "./basic_table";
import Tooltip from "../toolTip";
import {
  getExpandProvisionalParents,
  ProvisionalDescription,
} from "../lineitem/sharedComponent";
import { convertToPercent, roundNearest } from "../../utils/numeralHelper";
import { currencySubunitToUnit } from "../../utils/currencyHelper";
import {
  dropDecimalColumns,
  headerFormatter,
} from "../../utils/tableFormatHelper";
import { LUMP } from "../../utils/constants";
import * as theme from "../../theme";
import { formatInTimeZone } from "../../utils/dateTimeHelper";
import { connect } from "react-redux";
import { BaseDetails, VariationDetails } from "./shared_component";
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandKeys: [],
    };
  }

  checkGreyDash = (line) => {
    return line.lineitem_options === LUMP || line.childitems?.length > 0;
  };

  isTreeExpanded = (id) => {
    return this.props.expandTreeKeys.includes(id);
  };

  isItemFileExpanded = (id) => {
    return this.state.expandKeys.includes(id);
  };

  expandProvisionalParent = (line) => {
    let expanded = getExpandProvisionalParents(line, this.props.expandTreeKeys);
    this.props.setExpandKeys(expanded);
    // this.setState({ expandTreeKeys: expanded });
  };

  render() {
    const {
      tableContent,
      tableCaption,
      total,
      downloadFile,

      sectionTitle,
      contractInfo,
      proId,
      contractId,
      outstandingClaims,
      accountConfig,
      isProvisional,
      tableSubtitle,
      hasTableSubtitle,
      isTableSubtitleBold,
      hasSort,
      expandTreeKeys,
      setExpandKeys,
      hasRetention,
    } = this.props;
    const other = <div className="otherEmptyTr" />;
    const isBase =
      sectionTitle.includes("Base") ||
      (tableSubtitle && tableSubtitle.includes("Base"));
    let claim_to_date = formatInTimeZone(
      contractInfo?.get("up_to_date"),
      "d MMM yyyy",
      contractInfo?.get("time_zone"),
    );
    const showCurrency = !accountConfig?.get("enable_header_currency");

    const date = (
      <div className="updateS">
        Up to <span className="dateInline">{claim_to_date || ""}</span>
      </div>
    );
    let dropDecimal = dropDecimalColumns(tableContent.toJS(), [
      "quantity",
      "rate",
      "total",
      "claimed_up_to_date_total",
      "approved_up_to_date_total",
    ]);

    let claimTotal = 0,
      approvedTotal = 0;
    if (sectionTitle.includes("Base")) {
      claimTotal = contractInfo.get("total_base_claimed_to_date") || 0;
      approvedTotal = contractInfo.get("total_base_approved_to_date") || 0;
    } else {
      claimTotal = contractInfo.get("total_vari_claimed_to_date") || 0;
      approvedTotal = contractInfo.get("total_vari_approved_to_date") || 0;
    }

    const columns_dev = [
      {
        className: "item-not-show",
        render: "label",
        group: [other, "Ref"],
        width: 67,
      },
      {
        type: "row-expand",
        group: [other, "Ref"],
        className: "file-not-show",
        width: 0,
        onClick: (d, isExpand) => {
          if (isExpand) {
            this.setState({
              expandKeys: [d.id],
            });
          } else {
            this.setState({
              expandKeys: [],
            });
          }
        },
        render: (d) => {
          return () => {
            return (
              <div className="inputWrapper">
                {isBase ? (
                  <BaseDetails
                    data={d}
                    downloadFile={downloadFile}
                    proId={proId}
                    contractId={contractId}
                    hasRetention={hasRetention}
                    timeZone={contractInfo?.get("time_zone")}
                  />
                ) : (
                  <VariationDetails
                    data={d}
                    downloadFile={downloadFile}
                    proId={proId}
                    contractId={contractId}
                    hasRetention={hasRetention}
                    timeZone={contractInfo?.get("time_zone")}
                  />
                )}
              </div>
            );
          };
        },
      },
      {
        title: "Description",
        className: "align_left",
        group: other,
        render: (d) => {
          if (isProvisional) {
            return (
              <div>
                {d.description}
                {d?.subtitle && <CellSubtitle>{d?.subtitle}</CellSubtitle>}
              </div>
            );
          }
          return (
            <div id={`${isBase ? "base_contract" : "variation"}_name_${d.id}`}>
              <ProvisionalDescription
                data={d}
                isUpdateExpanded={this.isItemFileExpanded}
                isTreeExpanded={this.isTreeExpanded}
                expandProvisionalParent={this.expandProvisionalParent}
              />
            </div>
          );
        },
        width: 226,
        treeColumnsName: "childitems",
        treeIndent: 10,
      },
      {
        title: "Qty",
        group: other,
        width: 80,
        render: (d) => {
          if (this.checkGreyDash(d) || d.quantity === 0) {
            return <div className="grey-dash"></div>;
          } else {
            return currencySubunitToUnit(
              dropDecimal["quantity"] ? roundNearest(d.quantity) : d.quantity,
              false,
            );
          }
        },
      },
      {
        title: "Unit",
        render: (d) => {
          if (this.checkGreyDash(d) || d.unit === "") {
            return <div className="grey-dash"></div>;
          } else {
            return d.unit;
          }
        },
        group: other,
        width: 80,
      },
      {
        title: headerFormatter(
          "Rate",
          accountConfig?.getIn(["currency", "title"]),
          accountConfig?.get("enable_header_currency"),
        ),
        group: other,
        width: 120,
        render: (d) => {
          if (this.checkGreyDash(d) || d.rate === 0) {
            return <div className="grey-dash"></div>;
          } else {
            return currencySubunitToUnit(
              dropDecimal["rate"] ? roundNearest(d.rate) : d.rate,
              showCurrency,
            );
          }
        },
      },
      {
        title: headerFormatter(
          "Total",
          accountConfig?.getIn(["currency", "title"]),
          accountConfig?.get("enable_header_currency"),
        ),
        group: other,
        width: 160,
        render: (d) =>
          currencySubunitToUnit(
            dropDecimal["total"] ? roundNearest(d.total) : d.total,
            showCurrency,
          ),
      },
      {
        title: headerFormatter(
          `${accountConfig?.getIn(["claim", "value"])} to date`,
          accountConfig?.getIn(["currency", "title"]),
          accountConfig?.get("enable_header_currency"),
        ),
        className: "blue_box no-border",
        render: (d) =>
          currencySubunitToUnit(
            dropDecimal["claimed_up_to_date_total"]
              ? roundNearest(d.claimed_up_to_date_total)
              : d.claimed_up_to_date_total,
            showCurrency,
            dropDecimal["claimed_up_to_date_total"],
          ),
        group: date,
        width: 185,
      },
      {
        title: "%",
        className: "blue_box",
        render: (d) => convertToPercent(d.claimed_up_to_date_percent / 100),
        group: date,
        width: 78,
      },
      {
        title: () => {
          const actualTitle = headerFormatter(
            "Approved to date",
            accountConfig?.getIn(["currency", "title"]),
            accountConfig?.get("enable_header_currency"),
          );
          if (outstandingClaims === 0) {
            return <div>{actualTitle}</div>;
          } else {
            let tooltip =
              outstandingClaims === 1
                ? `There is an outstanding claim`
                : `There are ${outstandingClaims} outstanding claims`;
            return (
              <div>
                <Tooltip title={tooltip}>
                  <div className="hoverIcon" />
                </Tooltip>
                {actualTitle}
              </div>
            );
          }
        },
        className: "blue_box no-border",
        render: (d) =>
          currencySubunitToUnit(
            dropDecimal["approved_up_to_date_total"]
              ? roundNearest(d.approved_up_to_date_total)
              : d.approved_up_to_date_total,
            showCurrency,
            dropDecimal["approved_up_to_date_total"],
          ),
        group: date,
        width: 192,
      },
      {
        title: "%",
        className: "blue_box",
        render: (d) => convertToPercent(d.approved_up_to_date_percent / 100),
        group: date,
        width: 68,
      },
    ];
    const { expandKeys } = this.state;
    return (
      <>
        <TableStyle>
          <TableComponent
            tableContent={tableContent.toJS()}
            tableColumns={columns_dev}
            tableCaption={tableCaption}
            sectionTitle={sectionTitle}
            hasSort={hasSort === undefined ? true : hasSort}
            externalSortOptions={{
              claimed_up_to_date_percent: `${accountConfig?.getIn([
                "claim",
                "value",
              ])} %`,
              approved_up_to_date_percent: "Approved %",
            }}
            hasEnhancedTableFooter
            hasEnhancedTableToolbar
            rowClassName={(record, index) => {
              if (expandKeys[0] === record.id) {
                return "highlight";
              }
            }}
            expandKeys={expandKeys}
            treeExpandKeys={expandTreeKeys}
            onTreeExpand={(keys) => {
              return setExpandKeys(keys);
            }}
            tableSubtitle={tableSubtitle}
            hasTableSubtitle={hasTableSubtitle}
            isTableSubtitleBold={isTableSubtitleBold}
          >
            {this.props.children}
            <table>
              <tfoot>
                <tr>
                  <td className="blank" />
                  <td className="title">
                    {isProvisional ? (
                      <>
                        {" "}
                        <Tooltip
                          placement="bottom"
                          color={theme.palette.slateGrey}
                          size={"12px"}
                          title={
                            "This is the sum of the provisional items. These items form part of the total base contract."
                          }
                        >
                          <div className="hoverIcon" />
                        </Tooltip>{" "}
                        Total Provisional ({tableSubtitle})
                      </>
                    ) : (
                      <>{"Total " + sectionTitle}</>
                    )}
                  </td>
                  <td className="value">
                    {currencySubunitToUnit(total, showCurrency)}
                  </td>
                  <td className="claimedTotal">
                    {currencySubunitToUnit(claimTotal, showCurrency)}
                  </td>
                  <td className="claimedTotal">
                    {currencySubunitToUnit(approvedTotal, showCurrency)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </TableComponent>
        </TableStyle>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default connect(mapStateToProps, null)(index);
