import React, { Fragment, useState, useEffect, useMemo } from "react";

import { SectionIcon } from "../../project/styled";
import {
  SettingWrapper,
  ContractDetailWrapper,
  FilesFrame,
  FileListWrap,
} from "../styled";

import {
  BlueOutlinedBtn as Button,
  BlueWhiteBtn as ButtonDark,
} from "../../../common/button";
import Input from "../../../common/form";
import Modal from "../../project/components/modal";
import Dropzone from "../../../common/upload_file_display/dropzone";
import { bytesToSize, alphabeticalSort } from "../../../utils/helper";
import BootstrapTooltip from "../../../common/toolTip";
import { connect } from "react-redux";
import { UploadingBar } from "../../../common/upload_file_progress/components/uploadItem";

const documentType = [
  { label: "Contract Document", value: "contract_documents" },
  { label: "Delivery Document", value: "delivery_documents" },
  { label: "Mobile Upload", value: "mobile_uploads" },
  { label: "Variation Document", value: "variation_documents" },
  { label: "Handover Document", value: "handover_documents" },
];

const DocModal = ({
  open,
  setOpen,
  submit,
  attachments,
  fileTypes,
  deleteFile,
  updateAttachment,
  uploadsList,
}) => {
  const [existFiles, setExistFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState({});

  useEffect(() => {
    let filesList = [];

    fileTypes.forEach((type) => {
      if (attachments[type]) {
        attachments[type].forEach((file) => {
          filesList.push({
            id: file.id,
            filename: file.file_name,
            filesize: file.file_size,
            type: file.type,
          });
        });
      }
    });

    setExistFiles(filesList);
  }, [attachments, fileTypes]);

  useEffect(() => {
    setUploadingFiles({ ...uploadsList?.toJS() });
  }, [uploadsList]);

  const uploadFiles = (newFiles) => {
    newFiles = newFiles.map((file) => {
      file.fileType = "contract_documents";
      return file;
    });

    submit(newFiles);
  };

  const updateExistingAttachment = (e, fileId) => {
    const newFileType = e.value;

    // Create a new array based on existFiles but update the fileType of the selected file.
    const newExistFiles = existFiles.map((file) =>
      file.id === fileId ? { ...file, type: newFileType } : file,
    );
    setExistFiles(newExistFiles);

    // Extract the updated file directly from newExistFiles.
    const updatedFile = newExistFiles.find((file) => file.id === fileId);

    if (updatedFile) {
      updateAttachment(updatedFile);
    }
  };

  return (
    <Modal
      title="Contract Documents"
      open={open}
      close={() => {
        setOpen(false);
      }}
      hideActions
    >
      <>
        <div>Add any contract documentation here for members to access.</div>
      </>
      <FilesFrame>
        <div className="dropzone">
          <Dropzone onFilesAdded={(newFiles) => uploadFiles(newFiles)} />
        </div>
        <div className="append-label">
          <b>Examples:</b> Contracts, Delivery, Variations, Handover Documents.
        </div>
        <div className="upload-files">
          {Object.entries(uploadingFiles).map(([key, item]) => (
            <li className="file-list files upload" key={key}>
              <UploadingBar file={item.file} progress={item.progress} />
            </li>
          ))}
        </div>
        <div className="existing-files">
          {existFiles.map((file) => (
            <li className="file-list files" key={file.id}>
              <div className="name">{file.filename}</div>
              <div className="size">({bytesToSize(file.filesize)})</div>
              <Input
                field="dropdown"
                width="242px"
                wrapperClassName={"select-wrap"}
                options={documentType}
                value={file.type}
                onChange={(e) => updateExistingAttachment(e, file.id)}
              />
              <div className="action" onClick={() => deleteFile(file.id)} />
            </li>
          ))}
        </div>
      </FilesFrame>
    </Modal>
  );
};

const Attachments = ({ fileTypes, attachments, download }) => {
  return (
    <FileListWrap>
      {fileTypes.map((type) => (
        <Fragment key={type}>
          {attachments[type] &&
            attachments[type].map((file) => {
              return (
                <li key={file.id} className="file-wrap">
                  <div className="top">
                    <BootstrapTooltip title={file.file_name} placement="top">
                      <div className="name">
                        <span onClick={() => download(file.id)}>
                          {file.file_name}
                        </span>
                      </div>
                    </BootstrapTooltip>
                    <div className="type size">
                      {bytesToSize(file.file_size)}
                    </div>
                  </div>
                  <div className="type">
                    {documentType.find((ele) => ele.value === type).label}
                  </div>
                </li>
              );
            })}
        </Fragment>
      ))}
    </FileListWrap>
  );
};

function Setting(props) {
  const {
    attachments,
    uploadsList, // list of files being uploaded
    uploadFiles,
    handleDelete,
    handleDownload,
    readOnly,
    updateAttachment,
  } = props;
  const [open, setOpen] = useState(false);
  const [sortedAttachments, setSortedAttachments] = useState({});
  const fileTypes = useMemo(
    () => documentType.map((ele) => ele.value).sort(alphabeticalSort),
    [],
  );

  useEffect(() => {
    let sortedAttachments = {};

    fileTypes.forEach((type) => {
      if (attachments.get(type)) {
        const sorted = attachments
          .get(type)
          .sort((a, b) =>
            alphabeticalSort(a.get("file_name"), b.get("file_name")),
          );
        sortedAttachments[type] = sorted.toJS();
      }
    });
    setSortedAttachments(sortedAttachments);
  }, [attachments, fileTypes]);

  const hasDocuments = attachments.size > 1;

  return (
    <ContractDetailWrapper className="setting-page" id="nav-document">
      <SettingWrapper>
        <div className="section-title">
          <div>
            <SectionIcon img={"document"} />
            <span>Contract Documents</span>
          </div>
          {hasDocuments && !readOnly && (
            <Button title="edit" onClick={() => setOpen(true)} />
          )}
        </div>
        {!hasDocuments && (
          <>
            <div className="center">
              Add contract specific documents for access.
            </div>
            {!readOnly && (
              <div>
                <ButtonDark
                  title="Add Documents"
                  onClick={() => setOpen(true)}
                />
              </div>
            )}
          </>
        )}
        {hasDocuments && (
          <div className="center">
            <Attachments
              fileTypes={fileTypes}
              attachments={sortedAttachments}
              download={handleDownload}
            />
          </div>
        )}
      </SettingWrapper>
      <DocModal
        open={open}
        setOpen={setOpen}
        submit={(files) => uploadFiles(files)}
        attachments={sortedAttachments}
        uploadsList={uploadsList}
        fileTypes={fileTypes}
        deleteFile={handleDelete}
        updateAttachment={updateAttachment}
      />
    </ContractDetailWrapper>
  );
}

const mapStateToProps = (state) => ({
  uploadsList: state.getIn(["contract", "uploadsList"]),
});

export default connect(mapStateToProps, null)(Setting);
