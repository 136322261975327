export const addDepthLabel = (line, depth) => {
  if (line && depth) {
    line["depth"] = depth;
    if (line.childitems?.length > 0) {
      line.childitems.forEach((child, index) => {
        line.childitems[index] = addDepthLabel(child, depth + 1);
      });
    }

    return line;
  }
  return {};
};
