import React from "react";
import { ProjectNavList, ReviewWrapper, Note } from "../../../makeclaim/styled";

import Input from "../../../../common/form";

const Index = ({ claimInfo, isReadOnly, handleChangeNote }) => {
  let ifHide = isReadOnly ? "readonly" : "";
  return (
    <ReviewWrapper className={ifHide}>
      <ProjectNavList>
        {claimInfo.get("claimer_name")
          ? `Notes from ${claimInfo.get("claimer_name")}`
          : `Notes`}
      </ProjectNavList>
      <Note className={ifHide}>
        <Input
          field="textarea"
          className={!handleChangeNote ? `detail ${ifHide}` : "textarea"}
          name="claim_external_notes"
          value={claimInfo.get("claim_external_notes") || "No notes to display"}
          readOnly={!handleChangeNote}
          onChange={(e) => handleChangeNote(e)}
        />
      </Note>
    </ReviewWrapper>
  );
};

export default Index;
