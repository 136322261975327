import styled from "styled-components";
import warningIcon from "../../statics/img/icon-notification-red.svg";
import noticeIcon from "../../statics/img/icon-information-white.svg";
import helpDeskIcon from "../../statics/img/icon-error-white.svg";
import closeIcon from "../../statics/img/icon-clear-white.svg";
import upIcon from "../../statics/img/icon-dropdown-list-up-light-grey.svg";
import sortUp from "../../statics/img/icon-dropdown-down-dark-grey.svg";
import filterIcon from "../../statics/img/icon-filter-dark-grey.svg";
import checkIcon from "../../statics/img/icon-check-box-check-dark-grey.svg";
import uncheckIcon from "../../statics/img/icon-check-box-un-check-dark-grey.svg";
import pinIcon from "../../statics/img/icon-pin.svg";
import pinNotActiveIcon from "../../statics/img/icon-pin-not-active.svg";
import searchIcon from "../../statics/img/icon-search-dark-grey.svg";
import sortUpWhite from "../../statics/img/icon-dropdown-white.svg";
import infoIconOutline from "../../statics/img/icon-error-outline.svg";
import infoIconGrey from "../../statics/img/icon-information-light-grey.svg";
import certIcon from "../../statics/img/icon-workflow-cert-grey.svg";
import certIconGreen from "../../statics/img/icon-workflow-cert-green.svg";
import * as theme from "../../theme";

export const Notice = styled.div`
  height: auto;
  background-color: ${theme.palette.turquoiseBlue};
  .container {
    margin: 0px auto;
    text-align: center;
    padding: 6px 0;
    display: flex;
    width: 100%;
    justify-content: center;
    border-bottom: 1px solid ${theme.palette.tealBlue};
    &.help_desk {
      background-color: ${theme.palette.watermelonRed};
      border-bottom: none;
    }
  }
  .img_icon {
    height: 24px;
    width: 24px;
    background: url(${noticeIcon});
    margin-right: 10px;
    &.help_desk {
      background: url(${helpDeskIcon});
    }
  }
  .label {
    color: white;
    line-height: 24px;
    font-weight: ${theme.font.mainFontWeight};
    margin-right: 221px;
  }
  .close_icon {
    height: 24px;
    width: 24px;
    background: url(${closeIcon});
    cursor: pointer;
  }
`;

export const Header = styled.div`
  height: 40px;
`;
export const TopContainer = styled.div`
  margin: 41px 0 30px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
  }
  box-sizing: border-box;
  flex-wrap: wrap;
`;
export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  width: ${(props) => props.width || "49%"};
  margin: 15px 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: ${theme.palette.white};
  &.task {
    width: 100%;
  }
  &.group {
    width: 1360px;
    margin: 15px -108px;
  }
  .sumType {
    font-size: 24px;
    font-weight: ${theme.font.mainFontWeight};
    width: 100%;
    text-transform: capitalize;
    padding: 30px;
    border-bottom: 1px solid ${theme.palette.lightWolfGrey};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 900px) {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .selectMonthFrame {
    float: right;
  }
`;
export const InfoSection = styled.div`
  padding: 18px 30px 30px 30px;
  .emptyNotice {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 22px 10px 10px;
    color: ${theme.palette.veryMediumGrey};
  }
`;
export const TotalSection = styled.div`
  width: 100%;
  hr {
    border: 0;
    border-top: 1px solid ${theme.palette.lightWolfGrey};
    margin: 0;
    height: 1px;
  }
  .group-data {
    padding: 12px 0;
    line-height: 24px;
    height: 48px;
    box-sizing: border-box;
    .label-box {
      height: 12px;
      width: 12px;
      background-color: ${theme.palette.lightWolfGrey};
      &.out {
        background-color: ${theme.palette.tangerineOrange};
      }
      float: left;
      margin: 6px 10px 0 0;
    }
    .label {
      font-weight: ${theme.font.mainFontWeight};
      color: ${theme.palette.veryMediumGrey};
      float: left;
    }
    .value {
      font-size: 24px;
      float: right;
    }
  }
`;
export const ChartSection = styled.div`
  &.chart {
    margin-top: 0;
    height: 251px;
  }
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  .title-bar {
    line-height: 24px;
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: ${theme.font.mainFontWeight};
    .label-icon {
      height: 24px;
      width: 24px;
      background: url(${warningIcon});
      margin-right: 8px;
      float: left;
    }
    .label {
      float: left;
      font-size: 20px;
    }
    .view-btn {
      line-height: 22px;
      color: ${theme.palette.turquoiseBlue};
      border-bottom: 2px solid ${theme.palette.turquoiseBlue};
      cursor: pointer;
      &:hover {
        color: ${theme.palette.tealBlue};
        border-bottom: 2px solid ${theme.palette.tealBlue};
      }
      &:active {
        color: ${theme.palette.darkBlue};
        border-bottom: 2px solid ${theme.palette.darkBlue};
      }
      &:disabled {
        color: ${theme.palette.warmLightGrey};
        border-bottom: 2px solid ${theme.palette.warmLightGrey};
      }
    }
  }
  .info-bar {
    margin-top: 10px;
    padding: 12px 20px;
    height: 39px;
    border-radius: 4px;
    background-color: ${theme.palette.lightPastelPink};
    color: ${theme.palette.watermelonRed};
    font-weight: ${theme.font.mainFontWeight};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &.un-due {
      background-color: ${theme.palette.veryColdGrey};
      color: ${theme.palette.gunmetal};
    }
    &.due5 {
      background-color: ${theme.palette.lightPastelBlue};
      color: ${theme.palette.gunmetal};
    }
  }
`;

//projects list div
export const ProjectItems = styled.div`
  height: 292px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 40px 40px 40px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  &.current {
    background-color: ${theme.palette.lightPastelGreen};
  }
  &.completed {
    background-color: ${theme.palette.lightPastelBlue};
  }
  &.draft {
    background-color: ${theme.palette.veryLightBlue};
  }
  &.archived {
    background-color: ${theme.palette.warmLightGrey};
  }
  @media (max-width: 900px) {
    height: auto;
  }
`;
export const ProjectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ProjectInfoWrapper = styled.div`
  margin-top: 30px;
  box-sizing: border-box;
  height: 149px;
  width: 100%;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  display: flex;
  flex-direction: row;
  @media (max-width: 900px) {
    flex-direction: column;
    height: auto;
  }
`;

export const InfoProject = styled.div`
  height: 100%;
  border-left: 1px solid ${theme.palette.lightWolfGrey};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.project {
    border-left: 0;
    width: 10%;
    @media (max-width: 900px) {
      display: grid;
      grid-template-columns: 25% 50% 20%;
      height: 48px;
      width: 100%;
      border-top: 0;
      padding: 0 10%;
    }
  }
  width: 19%;
  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: 25% 50% 20%;
    height: 48px;
    width: 100%;
    border-top: 1px solid ${theme.palette.lightWolfGrey};
    border-left: 0;
    padding: 0 10%;
  }
`;
export const ProjectParamTitle = styled.div`
  font-size: 14px;
  font-weight: ${theme.font.mainFontWeight};
  color: ${theme.palette.veryMediumGrey};
  line-height: 16px;
`;
export const ProjectValue = styled.div`
  font-size: 18px;
  line-height: 19px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 20px 0 16px 0;
  max-width: 80%;
  @media (max-width: 900px) {
    margin: 0;
  }
`;
export const Date = styled.div`
  font-size: 14px;
  height: 15px;
  color: ${theme.palette.veryMediumGrey};
`;

export const ListItem = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 1fr 1.5fr 1fr;
  grid-gap: 10px;
  border-bottom: 1px solid ${theme.palette.lightWolfGrey};
  height: auto;
  min-height: 48px;
  justify-items: left;
  align-items: center;
  text-align: left;
  span {
    line-height: 18px;
  }
  &.last_one-without-line {
    border: none;
  }
  .list_header-label {
    font-weight: bold;
    position: relative;
    width: fit-content;
    cursor: pointer;
    &.hasMargin {
      padding-left: 34px;
    }
    .sort_icon {
      height: 24px;
      width: 24px;
      cursor: pointer;
      box-sizing: border-box;
      position: absolute;
      background: url(${sortUp}) 0% 0% / contain no-repeat;
      right: -32px;
      opacity: 0.2;
    }
    .sort_up {
      transform: rotate(180deg);
      bottom: 0px;
    }
    .sort_down {
      bottom: -10px;
    }
    .active {
      opacity: 1;
    }
  }
  .task_name_wrap {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }
  .label_date {
    color: ${theme.palette.veryMediumGrey};
    line-height: 24px;
  }
  .optional_label {
    width: 64px;
    height: 24px;
    margin-right: 8px;
    border-radius: 4px;
    background-color: ${theme.palette.veryColdGrey};
    font-size: 14px;
    text-align: center;
    padding: 5px;
  }
  .button {
    height: 27px;
    border-radius: 4px;
    font-weight: bold;
    border: none;
    &.view {
      width: 66px;
      font-size: 14px;
      background-color: ${theme.palette.gunmetal};
      color: ${theme.palette.white};
      cursor: pointer;
    }
    &.cert {
      width: 90px;
      padding: 6px 0px;
      background-color: ${theme.palette.lightPastelBlue};
      color: ${theme.palette.tealBlue};
      text-align: center;
    }
    &.claim {
      width: 90px;
      padding: 6px 0px;
      background-color: ${theme.palette.lightPastelGreen};
      color: ${theme.palette.brightGreen};
      text-align: center;
    }
  }
  .show_hide_controller {
    display: flex;
    cursor: pointer;
    .label {
      color: ${theme.palette.veryMediumGrey};
      line-height: 24px;
      font-weight: bold;
    }
    .img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background: url(${upIcon}) no-repeat;
      object-fit: contain;
      &.down {
        transform: rotate(180deg);
      }
    }
  }
`;

export const FilterContainer = styled.div`
  width: 200px;
  height: 44px;
  padding: 10px 16px;
  border-radius: 4px;
  border: solid 1px ${theme.palette.wolfGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: ${theme.font.normalFontWeight};
  position: relative;
  .filterIcon {
    height: 24px;
    width: 24px;
    display: block;
    cursor: pointer;
    border: none;
    background: url(${filterIcon}) no-repeat;
  }
`;
export const FilterForm = styled.div`
  height: 208px;
  width: 240px;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.104);
  color: ${theme.palette.gunmetal};
  padding: 24px 0px 12px 18px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  transition:
    opacity 0.3s ease,
    transform 0.2s ease;
  visibility: ${(props) => (props.expand ? "visible" : "hidden")};
  opacity: ${(props) => (!props.expand ? 0 : 1)};
  position: absolute;
  top: 30px;
  left: -75px;
  z-index: 10;

  .title {
    font-weight: ${theme.font.mainFontWeight};
    margin-bottom: 5px;
    font-size: 12px;
  }
  .list_item {
    display: flex;
    align-items: center;
  }
  hr {
    background: ${theme.palette.lightWolfGrey};
    border: none;
    margin-left: -18px;
  }
  label {
    margin-left: 28px;
  }
  input[type="checkbox"] {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    background: url(${uncheckIcon}) no-repeat;
    background-size: contain;
    cursor: pointer;
    :checked {
      background: url(${checkIcon}) no-repeat;
    }
  }
`;

export const ProjectSummaryTable = styled.div`
  padding: 24px 30px;
  .table_top-widget {
    display: flex;
    justify-content: space-between;
    padding: 0 130px 24px;
    .widget-wrap {
      width: 320px;
      height: 88px;
      padding: 16px 24px;
      display: inherit;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      &.claim {
        background-color: ${theme.palette.lightPastelGreen};
        label {
          color: ${theme.palette.brightGreen};
        }
      }
      &.cert {
        background-color: ${theme.palette.lightPastelBlue};
        label {
          color: ${theme.palette.tealBlue};
        }
      }
      &.difference {
        background-color: ${theme.palette.veryColdGrey};
        label {
          color: ${theme.palette.veryMediumGrey};
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
        .hover_icon {
          height: 24px;
          width: 24px;
          background: url(${infoIconGrey});
          background-size: contain;
        }
      }
      label {
        font-size: 20px;
        font-weight: bold;
        span {
          font-size: 16px;
          font-weight: 400;
        }
      }
      .value {
        font-size: 32px;
        color: ${theme.palette.gunmetal};
        &.negative {
          color: ${theme.palette.watermelonRed};
        }
      }
    }
  }
  .empty_notice-label {
    margin: 100px auto;
    font-size: 16px;
    text-align: center;
    .link_label {
      color: ${theme.palette.turquoiseBlue};
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
  table {
    font-family: ${theme.font.mainFont};
    height: auto;
    font-size: 12px;
    font-weight: 400;
    border-spacing: 1px;
    table-layout: fixed;
    width: 1300px;
  }
  thead th {
    height: 44px;
    color: ${theme.palette.white};
    background-color: ${theme.palette.gunmetal};
    text-align: center;
    font-weight: ${theme.font.mainFontWeight};
    padding: 0 5px;
    &.project {
      text-align: left;
      font-weight: bold;
      position: relative;
      width: fit-content;
      cursor: pointer;
      padding: 0 10px;
      &.hasMargin {
        padding-left: 34px;
      }
      .sort_icon {
        height: 30px;
        width: 30px;
        cursor: pointer;
        box-sizing: border-box;
        position: absolute;
        background: url(${sortUpWhite}) 0% 0% / contain no-repeat;
        left: 100px;
        top: 6px;
      }
      .sort_up {
        transform: rotate(180deg);
      }
    }
    .has_icon {
      display: flex;
      justify-content: flex-end;
      padding: 0 7px;
      .img_icon {
        height: 24px;
        width: 24px;
        background: url(${infoIconOutline});
        margin-left: 10px;
      }
    }
  }
  tbody td {
    min-height: 44px;
    color: ${theme.palette.gunmetal};
    font-weight: 400;
    font-size: 16px;
    padding: 0 12px;
    text-align: right;
    &.alignToCenter {
      text-align: center;
      padding: 0 12px;
    }
    &.projectName {
      display: flex;
      align-items: center;
      text-align: left;
      .groupName {
        max-width: 238px;
        display: grid;
        grid-template-columns: 210px 24px;
        column-gap: 4px;
        margin: 10px 0px;
        word-wrap: break-word;
      }
      .pinIcon {
        width: 24px;
        height: 24px;
        background: url(${pinIcon});
        background-size: contain;
        &.unActive {
          background: url(${pinNotActiveIcon});
          cursor: pointer;
        }
      }
    }
  }
  .so-table-icon-tree-expand .so-table-icon-tree-plus {
    display: inline-block;
  }

  .so-table .so-table-icon-tree-expand {
    margin-top: 10px;
  }

  .so-table .so-table-icon-tree-expand.so-table-icon-tree-plus {
    background: url(${upIcon}) no-repeat;
    transform: rotate(180deg);
  }

  .so-table .so-table-icon-tree-expand.so-table-icon-tree-sub {
    background: url(${upIcon}) no-repeat;
  }

  .table_bottom-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .link_url {
    text-decoration: none;
    cursor: pointer;
    color: ${theme.palette.gunmetal};
  }
  .view_button {
    width: 43px;
    height: 26px;
    border-radius: 4px;
    background-color: ${theme.palette.gunmetal};
    color: ${theme.palette.white};
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.2px;
    text-align: center;
    border: none;
    cursor: pointer;
  }
`;
export const ToolTipWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 188px;
  .list {
    display: flex;
    flex-wrap: nowrap;
    margin: 8px 0;
    line-height: 14px;
  }
  .point {
    width: 12px;
    height: 12px;
    display: block;
    margin-right: 8px;
    border-radius: 50%;
    flex-shrink: 0;
    &.point_grey {
      border-radius: 50%;
      background-color: ${theme.palette.gunmetal};
    }
    &.point_yellow {
      background-color: ${theme.palette.tangerineOrange};
    }
    &.point_red {
      background-color: ${theme.palette.watermelonRed};
    }
    &.point_green {
      background-color: ${theme.palette.brightGreen};
    }
  }
`;

// brightGreen / tangerineOrange / watermelonRed /wolfGrey
export const NumDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  &.pie_chart {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  .value_container {
    display: flex;
    align-items: center;
  }
  .num_bar {
    width: 24px;
    height: 11px;
    margin-left: 12px;
    border-radius: 2px;
    background-color: ${theme.palette.wolfGrey};
    .complete {
      height: inherit;
      background-color: ${(props) => theme["palette"][props.color]};
    }
  }
  .num_pie {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-left: 12px;
  }
`;

export const SearchInput = styled.div`
  height: 44px;
  width: 360px;
  background-color: ${theme.palette.white};
  box-sizing: border-box;
  float: left;
  border-radius: 4px;
  padding: 10px 10px 10px 16px;
  border: solid 1px ${theme.palette.wolfGrey};
  display: flex;
  margin-right: 20px;
  input {
    ::placeholder {
      color: ${theme.palette.wolfGrey};
    }
    outline: none;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-family: ${theme.font.mainFont};
    font-weight: ${theme.font.normalFontWeight};
    float: left;
    width: 300px;
    color: ${theme.palette.gunmetal};
  }
  .imgStyle {
    width: 24px;
    height: 24px;
    background: url(${searchIcon}) no-repeat scroll;
    float: left;
    margin-right: 12px;
  }
`;

export const WorkflowStep = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: flex-start;
    width: 130px;
  }

  .stepper-item {
    counter-reset: step;
  }
  .stepper-item li {
    list-style: none;
    display: inline-block;
    width: 16px;
    position: relative;
    text-align: center;
  }
  .stepper-item li.step:before {
    content: "";
    counter-increment: step;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${theme.palette.lightWolfGrey};
    display: block;
    text-align: center;
    margin: 0 auto 6px auto;
  }
  .stepper-item li.step:after {
    content: "";
    position: absolute;
    width: 12px;
    height: 2px;
    background-color: ${theme.palette.lightWolfGrey};
    top: 4px;
    left: -50%;
    z-index: 2;
  }
  .stepper-item li.step:first-child:after {
    content: none;
  }

  .stepper-item li.step.active:before {
    background-color: ${theme.palette.brightGreen};
  }

  .stepper-item li.step.active + li.step:after {
    background-color: ${theme.palette.brightGreen};
  }

  .stepper-item li.cert {
    margin-left: 4px;
    height: 20px;
    width: 24px;
    background: url(${certIcon}) no-repeat;
    background-size: contain;
  }
  .stepper-item li.cert:first-child,
  li.cert.active:first-child {
    margin-left: 0;
  }
  .stepper-item li.cert.active {
    background: url(${certIconGreen}) no-repeat;
    background-size: contain;
  }

  .stepper-item li.cert:before {
    content: "";
    position: absolute;
    width: 7px;
    height: 2px;
    background-color: ${theme.palette.lightWolfGrey};
    top: 8px;
    left: -7px;
    z-index: 2;
  }
  .stepper-item li.cert.active:before {
    background-color: ${theme.palette.brightGreen};
  }
  .stepper-item li.cert:first-child:before,
  li.cert.active:first-child:before {
    position: static;
  }

  .approver-wrapper {
    z-index: 1;
    position: relative;
    .approver-name-container {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: ${theme.palette.gunmetal};
      color: ${theme.palette.white};
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      padding: 5px 0 0;
      float: left;
    }
    .more-approver {
      width: 22px;
      height: 22px;
      opacity: 0.5;
      background-color: ${theme.palette.gunmetal};
      position: absolute;
      left: 10px;
      top: 2px;
      border-radius: 50%;
      z-index: -1;
    }
  }
`;

export const DayIndicator = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  .days-indicator {
    margin-right: 10px;
    .progress-circle {
      position: relative;
      padding: 0;
      width: 24px;
      height: 24px;
      background-color: ${theme.palette.lightWolfGrey};
      border-radius: 50%;
      line-height: 24px;
    }

    .progress-circle:after {
      border: none;
      position: absolute;
      top: 4px;
      left: 4px;
      text-align: center;
      display: block;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background-color: white;
      content: " ";
    }

    .progress-circle-clipper {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      position: absolute;
      clip: rect(0, 24px, 24px, 12px);
    }
    .progress-circle.over50 .progress-circle-clipper {
      clip: rect(auto, auto, auto, auto);
    }

    .progress-circle-value-bar {
      position: absolute;
      clip: rect(0, 12px, 24px, 0);
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 6px solid;
      border-color: ${(props) => theme["palette"][props.color]};
      box-sizing: border-box;
    }
    .progress-circle.over50 .progress-circle-bar {
      position: absolute;
      clip: rect(0, 24px, 24px, 12px);
      background-color: ${(props) => theme["palette"][props.color]};
      border-radius: 50%;
      width: 24px;
      height: 24px;
    }

    .progress-circle:not(.over50) .progress-circle-bar {
      display: none;
    }

    .progress-circle .progress-circle-value-bar {
      transform: ${(props) => {
        return `rotate(${props.deg}deg)`;
      }};
    }
  }
`;
