import React, { useState, useEffect } from "react";
import { TableContainer } from "../styled";
import { IconLink } from "../../project/styled";
import { LinkTooltip } from "../../project/components/integration";

import { currencySubunitToUnit } from "../../../utils/currencyHelper";
import PageBarPart from "../../../common/pagination";

import { openProject } from "../index";
import ProjectSubsetBadge from "./projectSubsetBadge";
import Skeleton from "../../../common/skeleton";

function compareByOption(field) {
  //sort function based on the table columns
  let sortLabel = field.split("$")[0];
  let reverse = field.split("$")[1] === "up" ? 1 : -1;
  if (sortLabel !== "name") {
    return function (a, b) {
      var result = a > b ? 1 : b > a ? -1 : 0;
      return result * reverse;
    };
  } else
    return function (a, b) {
      var result =
        a.toString().toLowerCase().trim() > b.toString().toLowerCase().trim()
          ? 1
          : b.toString().toLowerCase().trim() >
              a.toString().toLowerCase().trim()
            ? -1
            : 0;
      return result * reverse;
    };
}

const TableHeaderItem = (props) => {
  const { className, label, sortLabel } = props.column;
  const { handleSort, activeSortField } = props;
  return (
    <th className={className}>
      {label}
      {sortLabel && (
        <>
          <span
            className={
              "img-sort up " +
              (sortLabel + "$up" === activeSortField ? "active" : "")
            }
            onClick={() => handleSort(sortLabel + "$up")}
          />
          <span
            className={
              "img-sort " +
              (sortLabel + "$down" === activeSortField ? "active" : "")
            }
            onClick={() => handleSort(sortLabel + "$down")}
          />
        </>
      )}
    </th>
  );
};

export function Table(props) {
  const {
    caption,
    tableData,
    type,
    accountId,
    orgType,
    loading,
    accountConfig,
  } = props;
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState(type + ".total_contract_value$up");
  const [totalItems, setTotalItems] = useState(0);

  const handleSetRows = (data) => {
    setCurrentPage(1);
    setRows(data);
  };
  const handleSort = (field) => {
    setSortField(field);
  };

  const tableHeader = [
    { label: "Project Name", sortLabel: "name", className: "name" },
    {
      label: "Contracts",
      sortLabel: type + ".contract_number",
      className: "number",
    },
    {
      label: `${accountConfig?.getIn(["claim", "value"])} to Date`,
      sortLabel: type + ".claimed_to_date_value",
      className: "value",
    },
    {
      label: `${accountConfig?.getIn(["cert", "value"])} to Date`,
      sortLabel: type + ".cert_to_date_value",
      className: "value",
    },
    {
      label: "Total Contract",
      sortLabel: type + ".total_contract_value",
      className: "value",
    },
  ];

  useEffect(() => {
    let sortedTable = tableData.sortBy((val) => {
      let label = sortField.split("$")[0];
      let sortOption = label.split(".");
      if (sortOption.length === 2) {
        return val.getIn([sortOption[0], sortOption[1]]);
      }
      return val.get(label);
    }, compareByOption(sortField));
    let filterTable = sortedTable;
    if (type === "project") {
      filterTable = sortedTable.filter(
        (item) => item.getIn([type, "contract_number"]) === 0,
      );
    } else {
      if (type === "cert_contracts") {
        // show projects only have cert contracts no claim contracts in the Certifying section
        filterTable = sortedTable.filter(
          (item) =>
            item.getIn(["claim_contracts", "contract_number"]) === 0 &&
            item.getIn([type, "contract_number"]) !== 0,
        );
      } else {
        filterTable = sortedTable.filter(
          (item) => item.getIn([type, "contract_number"]) !== 0,
        );
      }
    }
    setData(filterTable);
    setTotal(
      filterTable.reduce(
        (total, obj) => obj.getIn([type, "total_contract_value"]) + total,
        0,
      ),
    );
    setTotalItems(filterTable.size);
  }, [tableData, sortField, type]);
  return (
    <TableContainer className="project-view">
      <table>
        <caption>{loading ? <Skeleton.TextLine /> : caption}</caption>
        <thead>
          <tr>
            {tableHeader.map((column) => (
              <TableHeaderItem
                key={column.label}
                handleSort={handleSort}
                activeSortField={sortField}
                column={column}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <Skeleton.TableSkeleton columnNum={tableHeader.length} />
          ) : (
            data.map((item, index) => {
              let page = currentPage - 1;
              if (index >= page * rows && index < (page + 1) * rows) {
                return (
                  <tr
                    key={item.get("id")}
                    onClick={() => openProject(accountId, item, orgType)}
                    onAuxClick={() =>
                      openProject(accountId, item, orgType, "new_win")
                    }
                  >
                    <td className="align-left">
                      <div className="project-title-container">
                        <div className="project-title">{item.get("name")}</div>
                        {item.get("is_not_covered") && <ProjectSubsetBadge />}
                        {item.get("integration") && (
                          <LinkTooltip from="Project" hideMessage>
                            <IconLink className={"margin-left"} />
                          </LinkTooltip>
                        )}
                      </div>
                      <div className="secondary-label">
                        {item.get("project_number")}
                      </div>
                    </td>
                    <td>{item.getIn([type, "contract_number"])}</td>
                    <td>
                      {currencySubunitToUnit(
                        item.getIn([type, "claimed_to_date_value"]),
                        true,
                        false,
                        true,
                      )}
                    </td>
                    <td>
                      {currencySubunitToUnit(
                        item.getIn([type, "cert_to_date_value"]),
                        true,
                        false,
                        true,
                      )}
                    </td>
                    <td>
                      {currencySubunitToUnit(
                        item.getIn([type, "total_contract_value"]),
                        true,
                        false,
                        true,
                      )}
                    </td>
                  </tr>
                );
              } else return null;
            })
          )}
        </tbody>
      </table>

      <div className="table-container_total">
        {loading ? (
          <Skeleton.TextLine theme={"secondary"} />
        ) : (
          <>
            <div>Projects Total</div>
            <div className="number">{currencySubunitToUnit(total)}</div>
          </>
        )}
      </div>

      <PageBarPart
        total={totalItems}
        limit={rows}
        pageCount={5}
        currentPage={currentPage}
        handlePageChange={setCurrentPage}
        handleSetData={handleSetRows}
        field="big"
      />
    </TableContainer>
  );
}

export default function TableView(props) {
  const { accountId, data, orgType, loading, accountConfig } = props;
  let emptyContractsCount = data.reduce(
    (n, item) => (item.getIn(["project", "contract_number"]) === 0 ? n + 1 : n),
    0,
  );
  return (
    <>
      {emptyContractsCount !== 0 && (
        <Table
          caption={"Empty Projects"}
          type="project"
          tableData={data}
          accountId={accountId}
          loading={loading}
          accountConfig={accountConfig}
        />
      )}
      <Table
        caption={accountConfig?.getIn(["claim", "ongoing_verb"])}
        type="claim_contracts"
        tableData={data}
        accountId={accountId}
        orgType={orgType}
        loading={loading}
        accountConfig={accountConfig}
      />
      <Table
        caption={"Certifying"}
        type="cert_contracts"
        tableData={data}
        accountId={accountId}
        orgType={orgType}
        loading={loading}
        accountConfig={accountConfig}
      />
    </>
  );
}
