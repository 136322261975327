import React, { PureComponent, Fragment } from "react";
import {
  InfoSection,
  SummaryContainer,
  ChartSection,
  TotalSection,
} from "../styled";
import Link from "../../../common/linkWithQuery";
export default class TableContainer extends PureComponent {
  render() {
    const { section, data, dataDue, stepName } = this.props;

    return (
      <SummaryContainer width="49%" className={stepName}>
        <div className="sumType">{section.title}</div>
        <InfoSection>
          <TotalSection>
            {data.map((item, index) => {
              return (
                <Fragment key={index}>
                  <div className="group-data">
                    <div className="label">{item.label}</div>
                    <div className="value">{item.value}</div>
                  </div>
                  {index === data.length - 1 ? null : <hr />}
                </Fragment>
              );
            })}
          </TotalSection>
          <ChartSection>
            <div className="title-bar">
              <div>
                <div className={section.due_icon} />
                <div className="label">{section.due_title}</div>
              </div>
              {section.link !== "" && (
                <Link to={section.link}>
                  <div className="view-btn">View Details</div>
                </Link>
              )}
            </div>

            {dataDue.map((item, index) => (
              <div className={item.style} key={index}>
                <span>{item.label}</span>
                <span>{item.value}</span>
              </div>
            ))}
          </ChartSection>
        </InfoSection>
      </SummaryContainer>
    );
  }
}
