import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import * as mainTheme from "../../theme";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(0.5),
  },
  chip: {
    margin: "0 10px 10px 0",
    backgroundColor: mainTheme.palette.lightPastelBlue,
    fontSize: "16px",
    color: mainTheme.palette.tealBlue,
    fontFamily: mainTheme.font.mainFont,
  },
  chipHistory: {
    margin: "0 10px 10px 0",
    backgroundColor: mainTheme.palette.veryWarmLightGrey,
    fontSize: "16px",
    color: mainTheme.palette.wolfGrey,
    fontFamily: mainTheme.font.mainFont,
  },
}));

export default function ChipsArray(props) {
  const classes = useStyles();
  const handleDelete = (chipToDelete) => () => {
    props.handleSetChipData(chipToDelete);
  };
  return props.chipData.map((data, index) => {
    return (
      <Chip
        key={index}
        label={data.get("contact_email")}
        onDelete={props.status === "history" ? undefined : handleDelete(data)}
        className={
          props.status !== "history" ? classes.chip : classes.chipHistory
        }
      />
    );
  });
}
