import styled from "styled-components";
import * as theme from "../../theme";
import editIcon from "../../statics/img/icon-edit-medium-grey.svg";
import delIcon from "../../statics/img/icon-delete-medium-grey.svg";
import { IconButton } from "../../common/button";
import avatar from "../../statics/img/avatar.svg";

export const SortInput = styled.div`
  height: 44px;
  width: 222px;
  border: 1px solid ${theme.palette.wolfGrey};
  border-radius: 4px;
  float: left;
  box-sizing: border-box;
  margin-left: 24px;
  background-color: ${theme.palette.white};
  display: grid;
  grid-template-columns: 70px auto;
  .sortTxt {
    grid-column: 1/2;
    text-align: left;
    line-height: 42px;
    width: 50px;
    height: 42px;
    margin-left: 16px;
    font-size: 14px;
    font-weight: ${theme.font.mainFontWeight};
    vertilical-align: center;
    position: absolute;
  }
  .inputSelect {
    grid-column: 2/3;
    float: right;
  }
`;

export const SearchInput = styled.div`
  height: 44px;
  width: 378px;
  box-sizing: border-box;
  float: left;
`;

export const TopicItem = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: space-between;

  ${SearchInput} {
    width: 456px;
  }

  ${SortInput} {
    width: 320px;
    margin-left: 0;
  }
`;

// list
export const TableHeader = styled.div`
  font-weight: ${theme.font.mainFontWeight};
  letter-spacing: 1px;
  margin-bottom: 12px;
  margin-top: 36px;
  display: grid;

  &.contact {
    grid-template-columns: 221px 160px 284px 240px 148px;
    .firstItem {
      padding-left: 40px;
    }
  }
  &.view-only {
    box-sizing: border-box;
    grid-template-columns: repeat(4, 250px);
    grid-column-gap: 20px;
    padding: 0 40px;
  }
`;

export const ListItemWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const ListItem = styled.div`
  &:hover {
    background-color: ${theme.palette.greyWhite};
  }
  min-height: 84px;
  width: 100%;
  height: auto;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  padding: 24px 40px;
  box-sizing: border-box;
  margin: 8px 0;
  font-size: 16px;
  line-height: 20px;
  display: grid;
  grid-column-gap: 20px;

  &.contact {
    grid-template-columns: 164px 135px 270px 210px 120px 65px;
  }
  &.view-only {
    grid-template-columns: repeat(4, 250px);
  }
  .icon {
    height: 40px;
    width: 40px;
    background: url(${avatar}) no-repeat;
    object-fit: contain;
  }
  .align_middle {
    align-self: center;
  }
  .light_grey_text {
    color: ${theme.palette.veryMediumGrey};
    width: 100%;
  }
  .company {
    position: relative;
    .company_id {
      position: absolute;
    }
  }
  .nowrap_text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .person_name {
    display: flex;
    flex-direction: column;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .align_center {
    text-align: center;
  }
`;
export const ImgEdit = styled(IconButton)`
  background: url(${editIcon}) no-repeat;
  float: left;
`;
export const ImgDelete = styled(IconButton)`
  background: url(${delIcon}) no-repeat;
  margin-left: 16px;
  float: left;

  &.disable {
    cursor: not-allowed;
    background: none;
    background-color: ${theme.palette.warmLightGrey};
    mask: url(${delIcon});

    &:active,
    &:hover {
      background-color: ${theme.palette.warmLightGrey};
      border-radius: none;
    }
  }
`;

export const UploadCSV = styled.div`
  padding: 40px;
  .header {
    margin-bottom: 40px;
  }
  .description {
    line-height: 19px;
    height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.custom_height {
      height: 250px;
    }
    .item {
      .link {
        color: ${theme.palette.turquoiseBlue};
        cursor: pointer;
      }
      .browser {
        font-weight: ${theme.font.mainFontWeight};
        color: ${theme.palette.gunmetal};
      }
    }
    table {
      width: 100%;
      thead {
        height: 44px;
        background-color: ${theme.palette.gunmetal};
        color: white;
        text-align: left;
      }
      th {
        height: 44px;
        padding-left: 16px;
      }
    }
    .note {
      padding: 20px 24px;
      background-color: ${theme.palette.lightPastelBlue};
      border-radius: 4px;
      color: ${theme.palette.tealBlue};
    }
  }
`;

export const BottomPagination = styled.div`
  margin-top: 24px;
  height: 44px;
  .account {
    min-width: 438px;
    height: fit-content;
    margin-right: 16px;
    background-color: ${theme.palette.veryColdGrey};
    .buttonWrapper {
      padding: 0px;
      .select_div {
        background-color: ${theme.palette.white};
      }
      .number_count {
        margin-left: 40px;
        margin-right: 56px;
      }
    }
  }
`;
export const EmptyState = styled.div`
  margin: 80px auto;
  text-align: center;
  font-size: 26px;
  letter-spacing: 0.14px;
`;
