import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form/immutable";
import {
  DetailsWrapper,
  HelpIconButton,
  SecondTitle,
  Input,
  Label,
  HelperText,
  AddressPanel,
  Error,
} from "./style";
import PhoneInput from "../../../../common/form/telephone";
import InputCountry from "../../../../common/form";
import AddressSearch from "../../../../common/mapbox/addressSearch";
import { StateWarningModal } from "../../../../common/config/stateConfigInput";
import RenderSelectInput from "../../components/select_frame";
import { actionCreators } from "../../store";
import MapboxDisplay from "../../../../common/mapbox/mapboxDisplay";
import { STATUS_ACTIVE, COUNTRY_AU } from "../../../../utils/constants";

function openNZBNweb() {
  window.open("https://companies-register.companiesoffice.govt.nz/");
}

const renderField = ({
  input,
  label,
  type,
  placeholder,
  width = "100%",
  help,
  helperText,
  required,
  meta: { touched, error },
}) => {
  return (
    <div>
      {label !== "" ? (
        <Label>
          {label}
          {required && <span className="required">*</span>}
          {help && <HelpIconButton onClick={openNZBNweb} type="button" />}
        </Label>
      ) : (
        <div />
      )}
      <Input
        {...input}
        type={type || "text"}
        className={touched && error ? "error" : ""}
        placeholder={placeholder}
        width={width}
      />
      {helperText && <HelperText>{helperText}</HelperText>}
      {touched && error && (
        <Error>
          <div className="error_img" />
          {error}
        </Error>
      )}
    </div>
  );
};

const InputSection = (props) => {
  const { setAddress, formName, accountConfig, form, errors } = props;

  const RenderInputField = ({
    input,
    name,
    label,
    placeholder,
    width,
    helperText,
  }) => {
    let inputValue = props.info.get(name);
    return (
      <div>
        {label && <Label>{label}</Label>}
        <Input
          {...input}
          name={name}
          value={inputValue}
          readOnly
          type="text"
          placeholder={placeholder}
          width={width}
        />
        {helperText && <HelperText>{helperText}</HelperText>}
      </div>
    );
  };
  let type = props.type === "create" && props.status;

  const handleStateChange = (formName, e) => {
    setAddress({ state: e }, formName);
  };

  return (
    <Fragment>
      <span ref={props.formRef}></span>
      <DetailsWrapper>
        <SecondTitle>Details</SecondTitle>
        {type ? (
          <div className="company-details">
            <RenderInputField
              label="Company Name*"
              name="entity_name"
              width="100%"
            />
            <RenderInputField
              label={accountConfig?.getIn(["gst_number", "title"])}
              name="gst_no"
              helperText={
                accountConfig?.getIn([
                  "buyer_created_tax_invoices",
                  "status",
                ]) === STATUS_ACTIVE
                  ? `Used for ${accountConfig?.getIn([
                      "buyer_created_tax_invoices",
                      "title",
                    ])}`
                  : ""
              }
              width="100%"
            />
            <AddressPanel className={type ? "view-only" : ""}>
              <div className="title">Company Address</div>
              <RenderInputField
                label={accountConfig.getIn(["address", "street"])}
                name="street_address"
                placeholder={accountConfig.getIn(["address", "street"])}
              />
              <div className="addressPanel">
                <RenderInputField
                  label={accountConfig.getIn(["address", "suburb"])}
                  name="suburb"
                  placeholder={accountConfig.getIn(["address", "suburb"])}
                  width="215px"
                />
                <RenderInputField
                  label={accountConfig.getIn(["address", "city"])}
                  name="city"
                  placeholder={accountConfig.getIn(["address", "city"])}
                  width="215px"
                />
              </div>
              <div className="addressPanel">
                <RenderInputField
                  label={accountConfig.getIn(["address", "postcode"])}
                  name="postal_code"
                  placeholder={accountConfig.getIn(["address", "postcode"])}
                  width="215px"
                />
                <RenderInputField
                  label={accountConfig.getIn(["address", "state"])}
                  name="state"
                  placeholder={accountConfig.getIn(["address", "state"])}
                  width="215px"
                />
              </div>
              <InputCountry
                name="country"
                width="100%"
                field="lock-country"
                value={props.info.get("country")}
              />
            </AddressPanel>
          </div>
        ) : (
          <div className="company-details">
            <Field
              label="Company Name"
              name="entity_name"
              required={true}
              component={renderField}
            />
            <Field
              label={accountConfig?.getIn(["gst_number", "title"])}
              name="gst_no"
              component={renderField}
              width="100%"
              helperText={
                accountConfig?.getIn([
                  "buyer_created_tax_invoices",
                  "status",
                ]) === STATUS_ACTIVE
                  ? `Used for ${accountConfig?.getIn([
                      "buyer_created_tax_invoices",
                      "title",
                    ])}`
                  : ""
              }
            />
            <AddressPanel>
              <div className="title">Company Address</div>
              <MapboxDisplay setAddress={(data) => setAddress(data, formName)}>
                <AddressSearch
                  hasRestrictCountry
                  setAddress={(data) => {
                    return setAddress(data, formName);
                  }}
                />
              </MapboxDisplay>
              <Field
                label={accountConfig.getIn(["address", "street"])}
                name="street_address"
                required={true}
                component={renderField}
                placeholder={accountConfig.getIn(["address", "street"])}
              />
              <div className="addressPanel">
                <Field
                  label={accountConfig.getIn(["address", "suburb"])}
                  name="suburb"
                  component={renderField}
                  placeholder={accountConfig.getIn(["address", "suburb"])}
                  width="215px"
                />
                <Field
                  label={accountConfig.getIn(["address", "city"])}
                  name="city"
                  component={renderField}
                  placeholder={accountConfig.getIn(["address", "city"])}
                  width="215px"
                />
              </div>
              <div className="addressPanel">
                <Field
                  label={accountConfig.getIn(["address", "postcode"])}
                  name="postal_code"
                  component={renderField}
                  placeholder={accountConfig.getIn(["address", "postcode"])}
                  width="215px"
                />
                {form?.get("country") !== COUNTRY_AU ? (
                  <Field
                    name="state"
                    label={accountConfig.getIn(["address", "state"])}
                    component={renderField}
                    placeholder={accountConfig.getIn(["address", "state"])}
                    width="215px"
                  />
                ) : (
                  <Field
                    name="state"
                    label={accountConfig.getIn(["address", "state"])}
                    placeholder={`Select ${accountConfig.getIn(["address", "state"])}`}
                    width="215px"
                    component={RenderSelectInput}
                    options={accountConfig?.get("state")?.toJS() || []}
                    onChange={(e) => handleStateChange(formName, e)}
                    error={!form?.get("state") ? errors?.state : null}
                    required={true}
                  />
                )}
              </div>
              <InputCountry
                name="country"
                width="100%"
                field="lock-country"
                hasInitialValue={props.type !== "create"}
                value={form?.get("country")}
              />
            </AddressPanel>
            <StateWarningModal
              country={form?.get("country")}
              state={form?.get("state")}
              showError={!form?.get("state")}
            />
          </div>
        )}
      </DetailsWrapper>
      <div className="line" />
      <DetailsWrapper>
        <SecondTitle>Contact</SecondTitle>
        <div className="contact-details">
          <Field
            label="Full Name"
            required={true}
            name="payerName"
            component={renderField}
            type="text"
            placeholder="Person specified in contract"
          />
          <Field
            label="Email"
            component={renderField}
            name="email"
            type="email"
          />
          <div>
            <div className="label">Phone</div>
            <Field component={PhoneInput} name="phone" type="text" />
          </div>
        </div>
      </DetailsWrapper>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  countriesList: state.getIn(["config", "countriesList"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
  form: state.getIn(["contacts", "formValue"]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    setAddress(data, formName) {
      dispatch(actionCreators.setTargetAddress(data, formName));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InputSection);
