import React from "react";
import { Toggle } from "./styled";
import { AntSwitch } from "../switch";

export default function toggle(props) {
  const { checked, onChange, name, disabled, label, style, toggleText } = props;
  return (
    <div className="form_input" style={style}>
      <Toggle>
        <AntSwitch
          checked={checked}
          onChange={onChange}
          name={name}
          disabled={disabled}
          id={name}
        />
        <label
          className={checked ? "toggle-label" : "toggle-label unchecked"}
          htmlFor={name}
        >
          {toggleText || label}
        </label>
      </Toggle>
    </div>
  );
}
