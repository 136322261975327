import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { BgWrapper, HeaderTitle } from "../../../common/shared_style";
import { ProjectInfoWrapper } from "../styled";

import Menu from "../../../common/breadcrumbs";
import Attachment from "../../../common/claim_cert_attachment";
import Loading from "../../../common/loading";

import ProjectInfo from "./components/projectInfo";
import Table from "./components/paycertReviewTable";
import Summary from "./components/summary";
import Notes from "./components/notes";
import EmailService from "./components/emailInfo";
import NavBarBottom from "./components/navBarBottom";
import ApprovalChart from "../../../common/approval_flow";
import { getMenuLink } from "../components/pageMenu";

import { actionCreators } from "../store";
import { convertUTCToTime } from "../../../utils/dateTimeHelper";
import { CONTRACT_TYPE, STATUS_DRAFT } from "../../../utils/constants";
// issued cert info page
// this page can be viewed by claimer/ self-certifier/ certifier / self-claim
class index extends PureComponent {
  updateComment = (action, content, comment_id, lineId) => {
    this.props.updateComment(
      this.props.match.params.contractType,
      this.props.match.params.contractId,
      this.props.match.params.claimId,
      this.props.certId,
      action,
      content,
      comment_id,
      lineId,
      this.props.match.params.proId,
    );
  };
  render() {
    const {
      accountId,
      certInfo,
      loading,
      readOnly,

      baseContractTable,
      baseCertTotal,
      baseThisCertTotal,

      variationsTable,
      variationCertTotal,
      variationThisCertTotal,

      claimDetail,
      cert_attachments,
      shared_cert_attachments,
      emailToList,
      emailSubject,
      attachedEmailFiles,
      isSendEmail,
      sendingEmail,
      exceedAttachLimit,

      isAuditAttached,
      setPdfAudit,
      approvalList,
      handleSendEmail,
      deleteFile,
      downloadFile,
      downloadPdf,
      changeSharedFiles,
      projectInfo,
      certId,

      materialsTable,
      materialsTotal,
      readClaim,
    } = this.props;

    const { proId, contractId, claimId, contractType } =
      this.props.match.params;

    const hasMaterialData = materialsTable.size !== 0;

    return (
      <BgWrapper style={{ width: "1295px" }}>
        <Menu
          link={getMenuLink(
            projectInfo,
            contractId,
            certInfo.get("contract_name"),
            contractType,
            claimId,
          )}
          content={certInfo.get("cert_certificate_ref")}
        />
        <HeaderTitle className="review">
          {certInfo.get("cert_certificate_ref")}
          <div className="issued-date">
            Issued:{" "}
            {
              convertUTCToTime(
                certInfo?.get("cert_issued_date"),
                certInfo?.get("contract_timezone"),
                false,
                "dd/MM/yyyy",
              )[0]
            }
          </div>
        </HeaderTitle>
        {!loading ? (
          <ProjectInfoWrapper>
            <ProjectInfo claimDetail={claimDetail} certInfo={certInfo} />
            <Table
              table_title="Base Contract"
              tableContent={baseContractTable}
              projectData={certInfo}
              certTotal={baseCertTotal}
              thisCertTotal={baseThisCertTotal}
              updateComment={this.updateComment}
              // only cert can update/read comment
              isNewCert={
                contractType !== CONTRACT_TYPE.cert &&
                contractType !== CONTRACT_TYPE.selfCert
              }
              isApproved={true}
              bottom="0"
              commentViewOnly={true}
              contractType={contractType}
            />
            <Table
              table_title="Variations"
              projectData={certInfo}
              tableContent={variationsTable}
              certTotal={variationCertTotal}
              thisCertTotal={variationThisCertTotal}
              updateComment={this.updateComment}
              isNewCert={
                contractType !== CONTRACT_TYPE.cert &&
                contractType !== CONTRACT_TYPE.selfCert
              }
              commentViewOnly={true}
              isApproved={true}
              contractType={contractType}
            />
            {hasMaterialData && (
              <Table
                table_title="Materials On/Off Site"
                tableContent={materialsTable}
                projectData={certInfo}
                contractType={contractType}
                updateComment={this.updateComment}
                isMaterial
                isNewCert={
                  contractType !== CONTRACT_TYPE.cert &&
                  contractType !== CONTRACT_TYPE.selfCert
                }
                isApproved={true}
                commentViewOnly={true}
              />
            )}
            <div className="bottomWrapper">
              <Summary
                data={certInfo}
                materialsTable={materialsTable}
                materialsTotal={materialsTotal}
                claimDetail={claimDetail}
              />
              <div className="line" />
              <Notes claimInfo={claimDetail} isReadOnly />
              <div className="line" />
              {/* hide attachment section for claim */}
              {contractType !== CONTRACT_TYPE.claim && (
                <>
                  <div className="line" />
                  <Attachment
                    title={"Certificate"}
                    data={cert_attachments}
                    readOnly={readOnly}
                    hasSharedFiles
                    changeSharedFiles={(fileId, isAdd) =>
                      changeSharedFiles(
                        proId,
                        contractType,
                        contractId,
                        claimId,
                        certId,
                        fileId,
                        isAdd,
                      )
                    }
                    sharedFiles={shared_cert_attachments}
                    uploadFileApi={{
                      name: "upload_file_contract_cert",
                      config: [contractId, certId],
                    }}
                    readInfo={() =>
                      readClaim(proId, contractId, claimId, contractType)
                    }
                    deleteFile={(fileId) =>
                      deleteFile(
                        contractType,
                        contractId,
                        claimId,
                        certId,
                        fileId,
                      )
                    }
                    downloadFile={downloadFile}
                  />
                </>
              )}
              {/* show certifier shared attachments for claimer */}
              {contractType === CONTRACT_TYPE.claim && (
                <Attachment
                  title={"Certificate"}
                  displayOnly
                  hasSharedFiles
                  noMargin
                  sharedFiles={shared_cert_attachments?.filter(
                    (file) => file.get("type") === "cert",
                  )}
                  downloadFile={downloadFile}
                  otherPartyName={certInfo?.get("payer_entity_name")}
                />
              )}
              {/* only display workflow for certifier */}
              {(contractType === CONTRACT_TYPE.cert ||
                contractType === CONTRACT_TYPE.selfClaim) && (
                <ApprovalChart
                  data={approvalList}
                  editable={false}
                  isAuditAttached={isAuditAttached}
                  isComplete={
                    (certInfo.get("cert_status") || STATUS_DRAFT) !==
                    STATUS_DRAFT
                  }
                  timezone={certInfo?.get("time_zone")}
                  setPdfAudit={(e) =>
                    setPdfAudit(
                      proId,
                      contractId,
                      claimId,
                      certId,
                      e.target.checked,
                    )
                  }
                />
              )}
              {/* only hide email service for claimer */}
              {contractType !== CONTRACT_TYPE.claim && !readOnly && (
                <>
                  <div className="line" />
                  <EmailService {...this.props} />
                </>
              )}
            </div>
            <NavBarBottom
              readOnly={readOnly}
              isApprovedCert
              contractType={contractType}
              proId={proId}
              contractId={contractId}
              claimId={claimId}
              accountId={accountId}
              certId={certId}
              isSendEmail={isSendEmail}
              sendingEmail={sendingEmail}
              exceedAttachLimit={exceedAttachLimit}
              options={[
                {
                  label: "View file in new tab",
                  value: () =>
                    downloadPdf(proId, contractId, claimId, certId, false),
                },
                {
                  label: "Save file to your device",
                  value: () =>
                    downloadPdf(proId, contractId, claimId, certId, true),
                },
              ]}
              handleResendEmail={() =>
                handleSendEmail(
                  proId,
                  claimId,
                  certId,
                  isSendEmail,
                  emailToList,
                  emailSubject,
                  attachedEmailFiles,
                  contractId,
                )
              }
            />
          </ProjectInfoWrapper>
        ) : (
          <Loading />
        )}
      </BgWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleSendEmail(
      proId,
      claimId,
      certId,
      isSendEmail,
      emailToList,
      subject,
      attachedEmailFiles,
      contractId,
    ) {
      dispatch(
        actionCreators.sendEmail(
          proId,
          claimId,
          certId,
          isSendEmail,
          emailToList,
          subject,
          attachedEmailFiles,
          contractId,
        ),
      );
    },
  };
};
export default connect(null, mapDispatchToProps)(index);
