import React from "react";
import { ContractStatusTag } from "../styled";

const CertReceived = ({ certWord }) => (
  <ContractStatusTag className="green">
    <span className="new-icon" />
    {certWord} Received
  </ContractStatusTag>
);
const ClaimReceived = ({ claimWord }) => (
  <ContractStatusTag className="green">
    <span className="new-icon" />
    {claimWord} Received
  </ContractStatusTag>
);
const ClaimOverdue = ({ claimWord }) => (
  <ContractStatusTag className="red">{claimWord} Overdue</ContractStatusTag>
);
const ClaimDue = ({ claimWord }) => (
  <ContractStatusTag className="yellow">{claimWord} Due</ContractStatusTag>
);
const CertOverdue = ({ certWord }) => (
  <ContractStatusTag className="red">{certWord} Overdue</ContractStatusTag>
);

const GetCurrentCertBadge = (certDueDays, certWord, claimWord) => {
  if (certDueDays < 0) {
    return <CertOverdue certWord={certWord} />;
  } else if (certDueDays === 0) {
    return (
      <ContractStatusTag className="yellow">
        {certWord} Due today
      </ContractStatusTag>
    );
  } else if (certDueDays > 0 && certDueDays < 7) {
    const daysText = certDueDays === 1 ? "day" : "days";
    return (
      <ContractStatusTag className="yellow">
        {certWord} Due in {certDueDays} {daysText}
      </ContractStatusTag>
    );
  } else {
    return <ClaimReceived claimWord={claimWord} />;
  }
};

const GetCurrentStatusBadge = (
  claimToDateStatus,
  contractType,
  claimDueTerms,
  claimDueDays,
  certDueDays,
  certWord,
  claimWord,
) => {
  if (claimToDateStatus === "issue") {
    if (contractType === "claim" || contractType === "self-claim") {
      if (claimDueTerms === "over_due") {
        return <ClaimOverdue claimWord={claimWord} />;
      } else if (claimDueTerms !== "claimed") {
        if (claimDueDays === 0) {
          return (
            <ContractStatusTag className="yellow">
              {claimWord} Due today
            </ContractStatusTag>
          );
        } else if (claimDueDays > 0 && claimDueDays < 7) {
          const daysText = claimDueDays === 1 ? "day" : "days";
          return (
            <ContractStatusTag className="yellow">
              {claimWord} Due in {claimDueDays} {daysText}
            </ContractStatusTag>
          );
        }
      }
    } else if (contractType === "cert" || contractType === "self-cert") {
      return GetCurrentCertBadge(certDueDays, certWord, claimWord);
    }
  } else if (claimToDateStatus === "certified") {
    if (contractType === "claim") {
      return <CertReceived certWord={certWord} />;
    }
    return "";
  }
  return "";
};

const GetWhenNoClaimBadge = (
  contractType,
  claimDueTerms,
  claimDueDays,
  claimWord,
) => {
  if (contractType === "claim" || contractType === "self-claim") {
    // When no claim is made, then check if the claim is due.
    if (claimDueTerms === "over_due") {
      return <ClaimOverdue />;
    } else if (
      claimDueTerms === "due_in_3_days" ||
      claimDueTerms === "due_in_7_days"
    ) {
      if (claimDueDays === 0) {
        return (
          <ContractStatusTag className="yellow">
            {claimWord} Due today
          </ContractStatusTag>
        );
      } else if (claimDueDays > 0 && claimDueDays < 7) {
        const daysText = claimDueDays === 1 ? "day" : "days";
        return (
          <ContractStatusTag className="yellow">
            {claimWord} Due in {claimDueDays} {daysText}
          </ContractStatusTag>
        );
      } else {
        return <ClaimDue claimWord={claimWord} />;
      }
    }
  }
  return "";
};

export const getContractTag = (info, accountConfig) => {
  // get the latest claim status
  let claimToDateStatus = info.get("claimed_to_date_status");
  // get the latest claim's cert due days
  // the calculated_cert_due_days has been calculated between now and cert_due_date
  let certDueDays = info.get("calculated_cert_due_days");
  // let claimDueDays = info.get("calculated_claim_due_days")
  let contractType = info.get("contract_type");
  let claimDueDays = info.get("claim_due_days");
  let claimDueTerms = info.get("claim_due_terms");
  let status = info.get("status");
  let certWord = accountConfig?.getIn(["cert", "noun"]);
  let claimWord = accountConfig?.getIn(["claim", "noun"]);
  /*
   * Cert OverDue : when issued claim's cert_due_date has been passed
   * Cert Due In X Days: when issued claim's cert_due_date under due in X days.
   * Claims Received : when issued claims's cert_due_date
   * Nothing: when no claim or has "certified" claims
   */

  if (claimToDateStatus && status === "current") {
    return GetCurrentStatusBadge(
      claimToDateStatus,
      contractType,
      claimDueTerms,
      claimDueDays,
      certDueDays,
      certWord,
      claimWord,
    );
  } else if (claimToDateStatus === null) {
    return GetWhenNoClaimBadge(contractType, claimDueTerms, claimDueDays);
  }
  // no claim, return empty tag
  return "";
};
