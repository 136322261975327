import * as constants from "./constants";
import { fromJS } from "immutable";
import profile from "../../../utils/profile";
const defaultstate = fromJS({
  openUserList: false,
  isLoggedIn: false,
  profile: {},
  accountList: [],
  selectedAccountIndex: 0,
  profile_image: {},
  due_projects: 0,
  due_contracts: 0,
  noticeNum: 0,
  allNotice: [],
  accountRole: "admin",
  firstLogin: false,
  setupProcess: {},
  setupExpand: false,
  free_trial: {},
  accountFilterList: [],
  is_gst_include: false,
  openSupportDialog: false,
  is_help_desk_mode: false,
  config: {},
});

const setSetupInfo = (state, action) => {
  let setupExpand =
    !action.payload["is_completed_company_details"] &&
    localStorage.getItem("close_checklist") !== "true" &&
    !action.triggerClose;

  let setupProcess = {
    progress: action.payload,
    completedSteps: Object.values(action.payload).filter(
      (item) => item === true,
    ).length,
  };

  return state
    .set("setupProcess", fromJS(setupProcess))
    .set("setupExpand", setupExpand);
};

const setDueInfo = (state, action) => {
  let due_number =
    action.result.over_due +
    action.result.due_in_3_days +
    action.result.due_in_7_days;
  return state.set("due_projects", due_number);
};

const setAccountId = (state, action) => {
  let data = action.profile;
  let firstLogin = data.first_login;

  let accountList = data.payclaim_account_id;
  let accId = localStorage.getItem("paylab_account_id") || "";
  let index = 0;
  if (!accountList) {
    window.location = "/error_401_no_accounts";
    return;
  }
  if (accId !== "") {
    index = accountList.findIndex((item) => item.account_id === accId);
    if (index === -1) {
      index = 0;
    }
  }
  localStorage.setItem("paylab_account_id", accountList[index].account_id);
  let userProfile = {
    email: data.email,
    userName: data.name,
    payclaim_account_id: accountList[index].account_id,
    accountName: accountList[index].account_name,
    role: accountList[index].role_key,
    account_status: accountList[index].type,
  };
  let is_help_desk_mode = accountList[index].is_help_desk_mode;
  let image = {};
  image.image_64 = accountList[index].image_base64;
  image.image_type = accountList[index].image_type;
  let free_trial = {};
  free_trial.status = accountList[index].is_free_trial;
  free_trial.days = accountList[index].left_free_trial_days;
  profile.setProfile(userProfile);
  return state.merge(
    fromJS({
      isLoggedIn: true,
      profile: userProfile,
      accountList: accountList,
      profile_image: image,
      accountRole: accountList[index].role_key,
      firstLogin: firstLogin,
      free_trial: free_trial,
      is_help_desk_mode: is_help_desk_mode,
    }),
  );
};

export default (state = defaultstate, action) => {
  switch (action.type) {
    case constants.LOGIN_SUCCESS:
      return setAccountId(state, action);
    case constants.LOGIN_FAILED:
      return state.set("isLoggedIn", false);
    case constants.DROPDOWN_OPEN:
      return state.set("openUserList", !state.get("openUserList"));
    case constants.SET_DATA:
      return state.set(action.name, action.value);
    case constants.SET_IMU_DATA:
      return state.set(action.name, fromJS(action.value));
    case constants.SET_DUE_PROJECT:
      return setDueInfo(state, action);
    case constants.SET_NOTICE_DATA:
      return state.set("allNotice", fromJS(action.payload));
    case constants.SET_UP_PROCESS:
      return setSetupInfo(state, action);
    default:
      return state;
  }
};
