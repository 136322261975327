import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { BgWrapper, HeaderTitle } from "../../common/shared_style";
import { ProjectWrapper, SemiWrapper } from "./styled";

import Menu from "../../common/breadcrumbs";
import BctiModal from "../../common/bcti";
import Loading from "../../common/loading";
import Prompt from "../../common/modal/component/prompt";

import { OverviewPageUrlContractType } from "../contract/common";
import ClaimDetail from "./components/claimDetail";
import ContractOverview from "./components/contractOverview";
import ClaimTableComponent from "./components/claimTableComponent";
import Summary from "./components/summary";
import NavBarBottom from "./components/navBarBottom";
import { getMenuLink, ClaimContract } from "./components/pageMenu";
import ClaimNotes from "./shared/note";
import MakeClaimMaterials from "./components/materialsTable";

import profile from "../../utils/profile";
import { CLAIM_CERT, CONTRACT, CONTRACT_TYPE } from "../../utils/constants";
import history from "../../utils/history";
import { getAutoReferenceNumber } from "../../utils/helper";
import { getLastDateOfMonth, compareDates } from "../../utils/dateTimeHelper";

import API from "../../server";

import { actionCreators } from "./store";
import { actionCreators as actionCreatorsContract } from "../contract/store";
import ErrorStripBanner from "./shared/errorStripBanner";

class ProjectMakeClaim extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      bctiInfo: {},
      open: false,
      allowClaim: true,
      claimCounts: {},
      latestDate: new Date(),
      isChanged: false,
      retentionLoaded: false,
      errors: {},
    };
    this.claimDetailRef = React.createRef();
    this.previousOptionRef = React.createRef();
    this.currentRetentionPCRRRef = React.createRef();
    this.currentRetentionDLRRRef = React.createRef();
    this.previousLabourElementRef = React.createRef();
    this.labourElementRef = React.createRef();
    this._isMounted = false;
  }

  validateForm = () => {
    let isValid = true;
    if (this.props.claimDetail?.get("claim_ref") === "") {
      this.setState({ errors: { claim_ref: "Claim Reference" } });
      isValid = false;
    } else {
      this.setState((prevState) => {
        const updatedError = { ...prevState.errors };
        delete updatedError.claim_ref;
        return { errors: updatedError };
      });
    }

    if (
      compareDates(
        this.props.claimDetail.get("claim_from"),
        this.props.claimDetail.get("claim_to"),
      ) === "greater"
    ) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          claim_period_to: "Claim Period - To",
        },
      }));
      isValid = false;
    }

    // Validate the New Claim Summary DropDown
    // Check if the previous_option is selected
    // If not, then show the error
    // If selected, then remove the error
    if (!this.props.previous_option) {
      this.setState((prevState) => {
        const updatedError = { ...prevState.errors };
        updatedError.previousOptionRef = "Select Previous Amount";
        return { errors: updatedError };
      });
      isValid = false;
    } else {
      this.setState((prevState) => {
        const updatedError = { ...prevState.errors };
        delete updatedError.previousOptionRef;
        return { errors: updatedError };
      });
    }

    // Check for specific retention errors
    if (
      this.state.errors.currentRetentionPCRRRef ||
      this.state.errors.currentRetentionDLRRRef ||
      this.state.errors.labourElementRef ||
      this.state.errors.previousLabourElementRef
    ) {
      return false;
    }

    if (isValid) {
      this.setState({ errors: {} });
    }

    return isValid;
  };

  performErrorCheckCurrentRetention = (type, isError) => {
    const errorMessages = {
      pcrr: "Exceeded Practical Completion Retention Release Total Accrued Retention",
      dlrr: "Exceeded Defects Liability Retention Release Total Accrued Retention",
    };

    this.setState((prevState) => {
      const updatedErrors = { ...prevState.errors };
      const errorKey = `currentRetention${type.toUpperCase()}Ref`;

      if (isError) {
        updatedErrors[errorKey] = errorMessages[type];
      } else {
        delete updatedErrors[errorKey];
      }
      return { errors: updatedErrors };
    });
  };

  checkInputErrors = (field, isError, ref) => {
    const error = `This must be less than the ${field.includes("previous") ? "previous " : ""}gross amount`;
    if (this._isMounted) {
      this.setState((prevState) => {
        const updatedErrors = { ...prevState.errors };
        if (isError) {
          updatedErrors[ref] = error;
        } else {
          delete updatedErrors[ref];
        }
        return { errors: updatedErrors };
      });
    }
  };

  // Performing the Save Review
  performSaveReview = (reviewStatus) => {
    const { proId, contractId, claimId, contractType } =
      this.props.match.params;
    const {
      claimDetail,
      baseClaimList,
      variationClaimList,
      paidValue,
      lessRetention,
      previousType,
      saveReview,
      claimLessPreviousOption,
      accountConfig,
    } = this.props;

    saveReview(
      claimDetail,
      paidValue,
      baseClaimList,
      variationClaimList,
      proId,
      contractId,
      claimId,
      reviewStatus,
      lessRetention,
      previousType,
      contractType,
      this.state.claimCounts,
      claimLessPreviousOption,
      accountConfig?.getIn(["claim", "noun"]),
    );
  };

  performBctiSubmit = (data) => {
    const { proId, contractId, contractType } = this.props.match.params;
    const { contractInfo, accountId } = this.props;

    const isAccountAdmin = profile.isAdminOrOwner();

    if (isAccountAdmin) {
      if (
        contractType === CONTRACT_TYPE.claim ||
        contractType === CONTRACT_TYPE.selfCert
      ) {
        // Update Details at the Account Level
        this.props.updateBctiInfo(proId, contractId, data);
      } else if (contractType === CONTRACT_TYPE.selfClaim) {
        const payeeCompany = contractInfo?.get("payee_company");

        // Update Details at the company level
        data.entity_id =
          payeeCompany?.get("entity_id") ||
          contractInfo?.get("payee_entity_id");
        data.entity_name =
          payeeCompany?.get("entity_name") ||
          contractInfo?.get("payee_entity_name");

        // If BCTI Modal is not returning the data, then use the saved one from the payee details
        data.street_address =
          data?.street_address ||
          payeeCompany?.get("street_address") ||
          contractInfo?.get("payee_entity_streetaddress");
        data.postal_code =
          data?.postcode ||
          payeeCompany?.get("postal_code") ||
          contractInfo?.get("payee_entity_postal_code");
        data.suburb =
          data?.suburb ||
          payeeCompany?.get("suburb") ||
          contractInfo?.get("payee_entity_suburb");
        data.city =
          data?.city ||
          payeeCompany?.get("city") ||
          contractInfo?.get("payee_entity_city");
        data.state =
          data?.state ||
          payeeCompany?.get("state") ||
          contractInfo?.get("payee_entity_state");
        data.country =
          data?.country ||
          payeeCompany?.get("country") ||
          contractInfo?.get("payee_entity_country");
        data.gst_no =
          data?.gst_number ||
          payeeCompany?.get("gst_no") ||
          contractInfo?.get("payee_gst_number");

        API.upsert_account_company(data);
      }
    } else {
      history.push(
        `/contract/${proId}/${OverviewPageUrlContractType(
          contractType,
        )}/${contractId}?account_id=${accountId}`,
      );
    }

    this.setState({ open: false });
  };

  updateComment = (action, content, comment_id, lineId) => {
    this.props.updateComment(
      this.props.match.params.proId,
      this.props.match.params.contractId,
      this.props.match.params.claimId,
      action,
      content,
      comment_id,
      lineId,
    );
  };

  render() {
    const {
      claimDetail,
      contractInfo,
      paymentsOption,

      baseContractTable,
      baseTotalValue,
      baseClaimTotal,
      baseClaimList,
      baseNetClaimTotal,
      base_claim_method,

      variationsTable,
      variationsTotal,
      variationClaimTotal,
      variationClaimList,
      variationNetClaimTotal,
      variation_claim_method,

      materialsTable,
      materialsClaimList,
      materialsTotal,
      handleInputMaterials,

      handleInputClaim,
      handleChangeNote,
      discardClaim,

      projectInfo,

      loading,
      accountConfig,
    } = this.props;

    const { proId, contractId, claimId, contractType } =
      this.props.match.params;

    const isAccountAdmin = profile.isAdminOrOwner();
    const payeeCompany = contractInfo?.get("payee_company");
    const payeeEntityName =
      payeeCompany?.get("entity_name") ||
      contractInfo?.get("payee_entity_name");
    const claimTerm = accountConfig.get("claim");
    return (
      <BgWrapper className="large">
        <Prompt
          when={
            this.state.isChanged &&
            this.props?.match?.params?.claimId !== undefined
          }
        />
        <SemiWrapper>
          <Menu
            link={getMenuLink(
              projectInfo,
              contractId,
              contractInfo.get("name") || contractInfo.get("contract_name"),
              contractType,
            )}
            content={
              claimTerm?.get("noun")?.toLowerCase() === "claim"
                ? "Make a Claim"
                : claimTerm?.get("verb")
            }
          />
          <HeaderTitle>
            {claimTerm?.get("noun")?.toLowerCase() === "claim"
              ? "Make a Claim"
              : claimTerm?.get("verb")}
          </HeaderTitle>
        </SemiWrapper>
        <ErrorStripBanner
          errors={this.state.errors}
          refs={this.refsObject}
          claimDetailRef={this.claimDetailRef}
          previousOptionRef={this.previousOptionRef}
          currentRetentionPCRRRef={this.currentRetentionPCRRRef}
          currentRetentionDLRRRef={this.currentRetentionDLRRRef}
          labourElementRef={this.labourElementRef}
          previousLabourElementRef={this.previousLabourElementRef}
        />
        <ProjectWrapper>
          {claimId && !loading && (
            <ContractOverview
              projectInfo={projectInfo}
              proId={proId}
              contractId={contractId}
              accountConfig={accountConfig}
            />
          )}
          <div ref={this.claimDetailRef} />
          {claimId && !loading ? (
            <>
              <ClaimDetail
                claimInfo={claimDetail}
                payment={contractInfo.get("payment_terms") || ""}
                newAutoClaimRef={`${claimTerm?.get(
                  "noun",
                )} - ${getAutoReferenceNumber(this.state.claimCounts)}`}
                paymentsOption={paymentsOption}
                proId={proId}
                contractId={contractId}
                claimId={this.props?.match?.params?.claimId}
                performSave={(status) => this.performSaveReview(status)}
                componentUpdated={() => {
                  this.setState({ isChanged: true });
                }}
                latestDate={this.state.latestDate}
                accountConfig={accountConfig}
                contractInfo={contractInfo}
                contractType={contractType}
              />
              <ClaimTableComponent
                title="Base Contract"
                status="none"
                tableContent={baseContractTable}
                totalTable={baseTotalValue}
                inputClaim={(id, field, e, onLoad, fromPrctQty) => {
                  if (!onLoad) {
                    this.setState({ isChanged: true });
                  }
                  return handleInputClaim(
                    id,
                    field,
                    e,
                    "base",
                    onLoad,
                    fromPrctQty,
                  );
                }}
                claimList={baseClaimList}
                calculateClaimTotal={baseClaimTotal}
                calNetClaim={baseNetClaimTotal}
                input_method={base_claim_method}
                updateComment={this.updateComment}
                contractId={contractId}
                claimId={claimId}
                accountConfig={accountConfig}
              />
              <ClaimTableComponent
                title="Variations"
                status="block"
                tableContent={variationsTable}
                totalTable={variationsTotal}
                inputClaim={(id, field, e, onLoad, fromPrctQty) => {
                  if (!onLoad) {
                    this.setState({ isChanged: true });
                  }
                  return handleInputClaim(
                    id,
                    field,
                    e,
                    "variation",
                    onLoad,
                    fromPrctQty,
                  );
                }}
                claimList={variationClaimList}
                calculateClaimTotal={variationClaimTotal}
                calNetClaim={variationNetClaimTotal}
                input_method={variation_claim_method}
                updateComment={this.updateComment}
                contractId={contractId}
                claimId={claimId}
                accountConfig={accountConfig}
              />
              <MakeClaimMaterials
                data={materialsTable}
                materialsClaimList={materialsClaimList}
                totals={materialsTotal}
                accountConfig={accountConfig}
                updateComment={this.updateComment}
                handleInput={handleInputMaterials}
                contractId={contractId}
                proId={proId}
                claimId={claimId}
              />
              <Summary
                gst={contractInfo.get("gst")}
                proId={proId}
                contractId={contractId}
                retentionExist={claimDetail.get("has_retention")}
                toDate={claimDetail.get("claim_to")}
                previousOptionRef={this.previousOptionRef}
                currentRetentionPCRRRef={this.currentRetentionPCRRRef}
                currentRetentionDLRRRef={this.currentRetentionDLRRRef}
                labourElementRef={this.labourElementRef}
                previousLabourElementRef={this.previousLabourElementRef}
                performErrorCheckCurrentRetention={
                  this.performErrorCheckCurrentRetention
                }
                checkInputErrors={this.checkInputErrors}
                errors={this.state.errors}
                {...this.props}
              />
              <ClaimNotes
                claimInfo={claimDetail}
                handleChangeNote={(name, value) => {
                  this.setState({ isChanged: true });
                  return handleChangeNote(name, value);
                }}
              />
              <SemiWrapper>
                <NavBarBottom
                  readOnly={!this.state.allowClaim}
                  handleSaveReview={() => {
                    if (!this.validateForm()) {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      return false;
                    }
                    this.setState({ isChanged: false });
                    return this.performSaveReview("claim");
                  }}
                  handleCancel={() =>
                    discardClaim(proId, contractId, claimId, contractType)
                  }
                  handleSave={() => {
                    if (!this.validateForm()) {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      return false;
                    }
                    this.setState({ isChanged: false });
                    return this.performSaveReview("draft");
                  }}
                  claimId={claimId}
                  previousPagePath={`/contract/${proId}/${
                    ClaimContract.includes(contractType) ? "claim" : "cert"
                  }/${contractId}`}
                />
              </SemiWrapper>
            </>
          ) : (
            <Loading />
          )}
        </ProjectWrapper>
        <BctiModal
          isOpen={this.state.open}
          submit={(data) => {
            this.performBctiSubmit(data);
          }}
          close={() => {
            this.setState({ open: false });
          }}
          bctiInfo={this.state.bctiInfo}
          isAccountAdmin={isAccountAdmin}
          page="claim"
          contactCompany={
            contractType === CONTRACT_TYPE.selfClaim
              ? payeeEntityName
              : undefined
          }
        />
      </BgWrapper>
    );
  }
  componentDidMount() {
    const { proId, contractId, claimId, contractType } =
      this.props.match.params;
    profile
      .checkPermission(CONTRACT, CLAIM_CERT, proId, contractId)
      .then((res) => {
        this.props.readProjectInfo(proId);
        // contract info
        this.props.requestContractInfo(
          proId,
          contractId,
          claimId,
          contractType,
        );
        this.setState({ allowClaim: res });
      });

    this.props.getClaimSummary(contractId);
    this.props.getPreviousClaimCert(
      contractId,
      claimId ? claimId : "",
      contractType,
    );
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    const { claimId, proId, contractId } = this.props.match.params;

    if (
      this.props.contractInfo &&
      this.props.contractInfo?.get("is_bcti") !==
        prevProps.contractInfo?.get("is_bcti")
    ) {
      const { contractInfo } = this.props;

      if (contractInfo?.get("is_bcti")) {
        const view =
          contractInfo?.get("contract_type") === CONTRACT_TYPE.selfClaim
            ? CONTRACT_TYPE.selfClaim
            : CONTRACT_TYPE.claim;

        API.check_bcti(proId, contractId, view)
          .then((res) => res.data.data)
          .then((res) => {
            if (
              !res.bcti["has_gst"] ||
              !res.bcti["has_name"] ||
              !res.bcti["has_address"]
            ) {
              this.setState({ open: true, bctiInfo: res.bcti });
            }
          });
      }
    }

    let latestDate = new Date();

    if (
      !claimId &&
      this.props.claimSummary &&
      this.props.claimSummary !== prevProps.claimSummary
    ) {
      const { claimSummary } = this.props;

      // Create an object to store the counts
      const counts = {};
      // Loop through the payload and count occurrences of each period_ending date by MM-YYYY
      for (const entry of claimSummary.toJS()) {
        const monthYear =
          entry.period_ending.split("-")[1] +
          "-" +
          entry.period_ending.split("-")[0];

        if (getLastDateOfMonth(monthYear) > latestDate) {
          latestDate = getLastDateOfMonth(monthYear);
        }

        if (counts[monthYear]) {
          counts[monthYear]++;
        } else {
          counts[monthYear] = 1;
        }
      }

      this.setState({ claimCounts: counts, latestDate });
    }

    let total =
      this.props.baseClaimTotalExcludeRetention +
      this.props.variationClaimTotalExcludeRetention +
      this.props.materialsTotalExcludeRetention?.get("onSite") / 100 +
      this.props.materialsTotalExcludeRetention?.get("offSite") / 100;
    let hasRetention = this.props.claimDetail?.get("has_retention");

    if (!this.props.loading && !this.state.retentionLoaded && hasRetention) {
      API.calculate_retention(proId, contractId, {
        gross: Math.round(total * 100),
      })
        .then((res) => res.data)
        .then((res) => res.data.data)
        .then((res) => {
          this.props.changeValue("lessRetention", res.retention / 100);
          this.setState({ retentionLoaded: true });
        });
    }

    if (!claimId && !this.props.loading && hasRetention !== undefined) {
      if (hasRetention) {
        if (
          this.props.loading !== prevProps.loading ||
          this.state.retentionLoaded
        ) {
          if (this.state.retentionLoaded) {
            this.performSaveReview("draft");
          }
        }
      } else {
        if (this.props.loading !== prevProps.loading) {
          this.performSaveReview("draft");
        }
      }
    }

    // New Claim Summary DropDown Update
    if (
      this.props.previous_option !== undefined &&
      this.props.previous_option !== prevProps.previous_option
    ) {
      // Run validation after the dropDown update
      this.validateForm();
    }

    if (!this.props.loading && prevProps.loading !== this.props.loading) {
      this.claimDetailRef = React.createRef();
      this.previousOptionRef = React.createRef();
    }
  }

  componentWillUnmount() {
    this.props.resetInfo();
    this._isMounted = false;
  }
}

const mapStateToProps = (state) => ({
  loading: state.getIn(["makeaclaim", "loading"]),
  contractInfo: state.getIn(["makeaclaim", "contractInfo"]),
  baseContractTable: state.getIn(["makeaclaim", "baseContractTable"]),
  baseTotalValue: state.getIn(["makeaclaim", "baseContractTotal"]),
  variationsTable: state.getIn(["makeaclaim", "variationsTable"]),
  variationsTotal: state.getIn(["makeaclaim", "variationsTotal"]),
  // project info
  projectInfo: state.getIn(["makeaclaim", "projectInfo"]),
  baseClaimList: state.getIn(["makeaclaim", "baseClaimList"]),
  variationClaimList: state.getIn(["makeaclaim", "variationClaimList"]),
  baseClaimTotal: state.getIn(["makeaclaim", "baseClaimTotal"]),
  baseClaimTotalExcludeRetention: state.getIn([
    "makeaclaim",
    "baseClaimTotalExcludeRetention",
  ]),
  variationClaimTotal: state.getIn(["makeaclaim", "variationClaimTotal"]),
  variationClaimTotalExcludeRetention: state.getIn([
    "makeaclaim",
    "variationClaimTotalExcludeRetention",
  ]),
  paidValue: state.getIn(["makeaclaim", "paidValue"]),
  lessRetention: state.getIn(["makeaclaim", "lessRetention"]),
  claimDetail: state.getIn(["makeaclaim", "claimDetail"]),
  claimId: state.getIn(["makeaclaim", "claimid"]),
  paymentsOption: state.getIn(["headers", "config", "payclaim"]),
  variationNetClaimTotal: state.getIn(["makeaclaim", "variationNetClaimTotal"]),
  baseNetClaimTotal: state.getIn(["makeaclaim", "baseNetClaimTotal"]),
  previousType: state.getIn(["makeaclaim", "previousType"]),
  base_claim_method: state.getIn(["makeaclaim", "base_claim_method"]),
  variation_claim_method: state.getIn(["makeaclaim", "variation_claim_method"]),
  dlpReleaseValue: state.getIn(["makeaclaim", "dlpReleaseValue"]),
  pcdReleaseValue: state.getIn(["makeaclaim", "pcdReleaseValue"]),
  toClaimRetention: state.getIn(["makeaclaim", "toClaimRetention"]),
  certRetention: state.getIn(["makeaclaim", "certRetention"]),
  accountId: state.getIn(["headers", "profile", "payclaim_account_id"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
  claimSummary: state.getIn(["contract", "claimSummary"]),
  // material component info
  materialsTable: state.getIn(["makeaclaim", "materialsTable"]),
  materialsClaimList: state.getIn(["makeaclaim", "materialsClaimList"]),
  materialsTotal: state.getIn(["makeaclaim", "materialsTotal"]),
  materialsTotalExcludeRetention: state.getIn([
    "makeaclaim",
    "materialsTotalExcludeRetention",
  ]),

  claimLessPreviousOption: state.getIn([
    "makeaclaim",
    "claimLessPreviousOption",
  ]),
  // New Summary required props
  previousRef: state.getIn(["makeaclaim", "previousRef"]),
  // Need This Here Checking if previous_option value exists or not in the Error Banner
  previous_option: state.getIn(["makeaclaim", "previous_option"]),
});
const mapDispatchToProps = (dispatch) => {
  return {
    readProjectInfo(proId) {
      dispatch(actionCreators.readProject(proId));
    },
    changeValue(name, value) {
      dispatch(actionCreators.setData(name, value));
    },
    requestContractInfo(proId, contractId, claimId, contractType) {
      dispatch(
        actionCreators.requestContractInfo(
          proId,
          contractId,
          claimId,
          contractType,
        ),
      );
    },
    handleInputClaim(id, field, e, tableName, onLoad, fromPrctQty) {
      dispatch(
        actionCreators.setInputValue(
          id,
          field,
          e,
          tableName,
          onLoad,
          fromPrctQty,
        ),
      );
    },
    handleInputMaterials(lineId, value, onSite) {
      dispatch(actionCreators.setMaterialInput(lineId, value, onSite));
    },
    handlePaidValue(name, value) {
      dispatch(actionCreators.setValue(name, value));
    },
    resetInfo() {
      dispatch(actionCreators.resetStage());
    },
    discardClaim(proId, contractId, claimId, contractType) {
      dispatch(
        actionCreators.deleteClaim(proId, contractId, claimId, contractType),
      );
    },
    saveReview(
      claimDetail,
      lessPaid,
      baseList,
      variationList,
      proId,
      contractId,
      claimId,
      field,
      lessRetention,
      previousType,
      contractType,
      claimCounts,
      lessPreviousOption,
      claimWord,
    ) {
      if (claimId) {
        dispatch(
          actionCreators.updateAclaim(
            claimDetail,
            lessPaid,
            baseList,
            variationList,
            proId,
            contractId,
            claimId,
            field,
            lessRetention,
            previousType,
            contractType,
            lessPreviousOption,
          ),
        );
      } else {
        dispatch(
          actionCreators.makeAclaim(
            claimDetail,
            lessPaid,
            baseList,
            variationList,
            proId,
            contractId,
            field,
            lessRetention,
            previousType,
            contractType,
            claimCounts,
            claimWord,
          ),
        );
      }
    },
    updateComment(
      proId,
      contractId,
      claimId,
      action,
      content,
      comment_id,
      lineId,
    ) {
      dispatch(
        actionCreators.updateComment(
          proId,
          contractId,
          claimId,
          action,
          content,
          comment_id,
          lineId,
        ),
      );
    },
    handleChangeNote(name, value) {
      dispatch(actionCreators.setClaimInfo(name, value));
    },
    updateBctiInfo(proId, contractId, value) {
      dispatch(actionCreatorsContract.updateBctiInfo(proId, contractId, value));
    },
    getClaimSummary(contractId) {
      dispatch(actionCreatorsContract.getClaimSummary(contractId));
    },
    getPreviousClaimCert(contractId, claimId, contractType) {
      dispatch(
        actionCreators.getPreviousClaimCert(contractId, claimId, contractType),
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectMakeClaim);
