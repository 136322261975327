import React from "react";
import Tooltip from "../../../common/toolTip";

export const LinkTooltip = (props) => {
  return (
    <Tooltip
      placement={"bottom"}
      title={
        <div style={{ display: "grid" }}>
          <span>
            <b>Linked {props.from}</b>
          </span>
          {!props?.hideMessage && (
            <span>Sync projects to get latest details.</span>
          )}
        </div>
      }
    >
      {props.children}
    </Tooltip>
  );
};
