import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { YellowBtn } from "../../common/button";
import history from "../../utils/history";

const PostSignUp = () => {
  const [nextLocation, setNextLocation] = useState(null);
  let { search } = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    setNextLocation(searchParams.get("next"));
  }, [search]);

  return (
    <>
      <div className="title">Account Created</div>
      <div>
        <p className="center">
          Your PayLab account has been successfully created.{" "}
        </p>
        <p className="center">
          An email has been sent to your email to verify your email address.
          Please check your email and click link inside to activate your account
          and get started using PayLab.
        </p>
      </div>
      <div className="notice-label-wrapper">
        <YellowBtn
          title="Go to Log In"
          submitting={false}
          onClick={(e) => history.push(`/login?next=${nextLocation}`)}
        />
      </div>
    </>
  );
};

export default PostSignUp;
