import React, { Component } from "react";
import { TableFooter, TableStyle } from "./styled";
import TableComponent from "../table/basic_table";
import InputTable from "./materialsInputTable";
import { checkIsParent } from "../../utils/helper";
import { skeletonCol, skeletonData } from "../skeleton/component/table";
import { MaterialDetails } from "../table/material_table";
import Skeleton from "../skeleton";
import { AddBtn as AddButton } from "../button";
import { STATUS_DRAFT } from "../../utils/constants";

class Table extends Component {
  constructor() {
    super();
    this.state = {
      expandKeys: [],
      expandTreeKeys: [],
      expandEdit: false,
      expandChildKeys: [],
      parentId: "",
      lastChildKeys: [],
      expandAttach: false,
      addingItem: false,
      previousLineType: "rate",
    };
  }

  handleCloseEdit = () => {
    return this.setState({
      expandKeys: [],
      parentId: "",
      expandChildKeys: [],
      lastChildKeys: [],
    });
  };
  handleCleanItem = (id) => {
    this.props.deleteItem(id);
    return this.setState({
      expandKeys: [],
      parentId: "",
      expandChildKeys: [],
      lastChildKeys: [],
    });
  };

  handleSaveEdit = (id, onSite, editInfo) => {
    // only copy objects with keys specified in edit fields or else saving will cause server errors
    const editFields = [
      "label",
      "description",
      "total",
      "detailed_description",
      "exclude_retention",
    ];
    let edited = {};

    for (let [key, value] of Object.entries(editInfo)) {
      if (editFields.includes(key)) {
        edited[key] = value;
      }
    }

    edited.on_site = onSite;

    this.props.handleSaveEdit(edited, id);

    return this.setState({
      expandKeys: [],
      parentId: "",
      expandChildKeys: [],
      lastChildKeys: [],
    });
  };

  setIntialValue = (table) => {
    this.props.setEditFiles(table.uploaded_files);
  };

  render() {
    const {
      tableContent,
      addRow,
      handleFileCancel,
      handleFilesChange,
      files,
      editFileList,
      handleUploadFile,
      handleFileDelete,
      UIpage,
      setFileList,
      tableSubtitle,
      tableCaption,
      tableCaptionSubtitle,
      loading,
      proId,
      contractId,
      downloadFile,
      accountConfig,
      setEditFiles,
      contractInfo,
      isTableSubtitleBold,
      showAddButton,
      hideTableToolbar,
      setSortState,
      sortState,
    } = this.props;
    const {
      expandKeys,
      expandTreeKeys,
      parentId,
      expandChildKeys,
      lastChildKeys,
      expandEdit,
      addingItem,
      previousLineType,
    } = this.state;

    const Actions = <span className="actionHeader">Action</span>;
    const columns = [
      {
        title: "Ref",
        className: "align_left lineitem-ref",
        render: "label",
        width: "10%",
      },
      {
        title: "Description",
        className: "align_left",
        treeColumnsName: "childitems",
        treeIndent: 10,
        width: "82%",
        render: "description",
      },
      {
        type: "expand",
        className: "edit hidden",
        width: "6%",
        onClick: (d, isExpand) => {
          this.setState({
            parentId: d.id,
            expandKeys: [d.id],
            expandEdit: true,
            expandChildKeys: [],
            lastChildKeys: [],
            expandAttach: false,
          });
          this.setIntialValue(d);
          this.setState({ addingItem: false });
        },
        render: (d) => {
          if (this.state.expandAttach) {
            return () => {
              return (
                <MaterialDetails
                  data={d}
                  downloadFile={downloadFile}
                  proId={proId}
                  contractId={contractId}
                  readOnlyExpandKeys={expandKeys}
                  handleClose={() => this.handleCloseEdit()}
                  timeZone={contractInfo?.get("time_zone")}
                />
              );
            };
          }
          return () => (
            //edit item
            <div className="inputWrapper">
              <InputTable
                id={d.id}
                title={"Edit Item: " + d.description}
                handleSaveItem={(onSite, editRow) => {
                  return this.handleSaveEdit(d.id, onSite, editRow);
                }}
                handleCleanItem={() => this.handleCleanItem(d.id)}
                handleClose={() => this.handleCloseEdit()}
                tableList={d}
                files={editFileList} //file list
                handleFilesChange={(files, uploadingFileField) =>
                  handleUploadFile(d.id, files, uploadingFileField)
                } //upload file
                handleFileCancel={(fileid) => handleFileDelete(d.id, fileid)}
                status={UIpage}
                action="save"
                setFileList={(files) => setFileList(files)}
                //prevent item from editing quantity/rate if item is parent or certified
                isBlocked={
                  d.approved_up_to_date_total !== 0 || checkIsParent(d)
                }
                canDelete={d.approved_up_to_date_total === 0}
                accountConfig={accountConfig}
                isDraft={contractInfo.get("status") === STATUS_DRAFT}
                allowExcludeRetention={contractInfo.get("retention")}
              />
            </div>
          );
        },
        group: Actions,
      },
      {
        type: "expand",
        className: "file file-not-show",
        group: Actions,
        width: "2%",
        onClick: (d, isExpand) => {
          if (d.uploaded_files) {
            this.setState({
              expandChildKeys: [],
              parentId: d.id,
              expandKeys: [d.id],
              expandEdit: false,
              lastChildKeys: [],
              expandAttach: true,
            });
          } else {
            this.setState({
              expandChildKeys: [],
              parentId: "",
              expandKeys: [],
              expandEdit: false,
              lastChildKeys: [],
              expandAttach: false,
            });
          }
        },
        render: (d) => {
          if (!d.uploaded_files) {
            return undefined;
          }
          return () => <div />;
        },
      },
    ];

    return (
      <TableStyle>
        <TableComponent
          tableCaption={tableCaption}
          tableContent={loading ? skeletonData : tableContent}
          tableColumns={loading ? skeletonCol : columns}
          tableCaptionSubtitle={tableCaptionSubtitle}
          isTableSubtitleBold={isTableSubtitleBold}
          hideTableToolbar={hideTableToolbar}
          tableSubtitleClass="normal-margin"
          expandKeys={expandKeys}
          rowClassName={(record, index) => {
            if (record.id === parentId) {
              return "highlight";
            }
            if (expandChildKeys.indexOf(record.id) !== -1) {
              return "border-highlight";
            }
          }}
          onTreeExpand={(keys) => {
            if (!expandEdit) {
              if (lastChildKeys.length !== 0) {
                let res = lastChildKeys.filter((x) => !keys.includes(x));
                if (res.length !== 0) {
                  this.setState({
                    expandKeys: [res[0]],
                  });
                } else {
                  this.setState({
                    expandKeys: [expandChildKeys[expandChildKeys.length - 2]],
                  });
                }
              }
            }
            return this.setState({ expandTreeKeys: keys });
          }}
          treeExpandKeys={expandTreeKeys}
          hasSort
          overwriteDefaultSortOptions={{
            created_date: "Date Created",
            label: "Reference",
            description: "Description",
          }}
          hasEnhancedTableToolbar
          hasTableSubtitle={tableSubtitle ? true : false}
          tableSubtitle={tableContent.length === 0 ? "" : tableSubtitle}
          hideTable={tableContent.length === 0 ? true : false}
          setSortState={setSortState}
          sortState={sortState}
        >
          {this.props.children}
        </TableComponent>

        {addingItem && (
          <InputTable
            type="parentItem"
            title="Add Item"
            handleClose={() => this.setState({ addingItem: false })}
            files={files}
            handleFilesChange={handleFilesChange}
            handleFileCancel={handleFileCancel}
            accountConfig={accountConfig}
            handleSaveItem={(lineType, newRow) => {
              addRow(lineType, newRow);
              this.setState({ previousLineType: lineType, addingItem: false });
            }}
            action="add"
            previousLineType={previousLineType}
            setEditFiles={setEditFiles}
            allowExcludeRetention={contractInfo.get("retention")}
          />
        )}
        {showAddButton && (
          <TableFooter>
            {loading ? (
              <Skeleton.TextLine />
            ) : (
              <AddButton
                onClick={() => this.setState({ addingItem: true })}
                disabled={addingItem}
                title="ADD ITEM"
                float="none"
              />
            )}
          </TableFooter>
        )}
      </TableStyle>
    );
  }

  componentDidUpdate(_, prevState) {
    if (
      this.state.addingItem !== prevState.addingItem &&
      this.state.addingItem
    ) {
      this.handleCloseEdit();
    }
  }
}
export default Table;
