import styled from "styled-components";
import * as theme from "../../theme";
import pattern from "../../statics/img/slate-pattern.svg";
import downloadImg from "../../statics/img/images-download.svg";
import cone from "../../statics/img/images-traffic-cone.svg";

export const PageLayout = styled.div`
  display: grid;
  grid-template-rows: 124px auto;
  grid-row-gap: 56px;
  letter-spacing: 0.2px;

  .pattern {
    width: 776px;
    height: 380px;
    background: url(${pattern});
    background-repeat: no-repeat;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
`;

export const Header = styled.div`
  background-color: ${theme.palette.tangerineOrange};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  .logo {
    height: 100px;
    width: 150px;
    margin-left: 140px;
  }
`;

export const Main = styled.div`
  width: 700px;
  height: 680px;
  background-color: ${theme.palette.white};
  padding: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin: 0 auto;

  .header {
    margin: 0;
    height: fit-content;
    font-size: 56px;
    color: ${theme.palette.tangerineOrange};
    letter-spacing: 0.2px;
    font-weight: bold;
  }

  .sub-header {
    width: 400px;
    margin: 0;
    margin-top: 24px;
    color: ${theme.palette.gunmetal};
    font-size: 16px;
    text-align: center;
  }

  .file-name {
    font-weight: bold;
    margin-bottom: 4px;
    font-size: 20px;
    letter-spacing: 0.25px;
  }
`;

export const Image = styled.img.attrs((props) => ({
  src: props.status === "error" ? cone : downloadImg,
}))`
  width: 320px;
  height: 320px;
  margin-top: 40px;
  @keyframes fadeIn {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.6;
    }
  }
  &.loading {
    animation-name: fadeIn;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
  &.ready {
    opacity: 1;
  }
`;
