import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BasicModal } from "./index";

const PageBtnLabel = {
  contract: {
    saveText: "Go Back",
  },
  claim: {
    saveText: "Go Back",
    cancelText: "Continue Anyway",
  },
  issueClaim: {
    saveText: "Go Back",
  },
  cert: {
    saveText: "Go Back",
    cancelText: "Continue Anyway",
  },
  issueCert: {
    saveText: "Go Back",
  },
};

function BctiWarningModal(props) {
  const { submit, close, page, contactCompany, open, bctiInfo, accountConfig } =
    props;
  const [missingInfo, setMissingInfo] = useState([]);

  useEffect(() => {
    if (bctiInfo) {
      let missingInfo = [];
      !bctiInfo?.has_gst &&
        missingInfo.push(accountConfig?.getIn(["gst_number", "title"]));
      !bctiInfo?.has_name && missingInfo.push("Company Name");
      !bctiInfo?.has_address && missingInfo.push("Company Address");
      setMissingInfo(missingInfo);
    }
  }, [bctiInfo, accountConfig]);

  return (
    <BasicModal
      open={open}
      close={() => close()}
      submit={() => submit()}
      saveText={PageBtnLabel[page]["saveText"]}
      cancelText={PageBtnLabel[page]["cancelText"]}
    >
      <>
        {bctiInfo?.is_bcti && (
          <h2 className="has_margin">
            {accountConfig?.getIn(["buyer_created_tax_invoices", "title"])} is
            switched on.{" "}
          </h2>
        )}
        {page === "contract" && !bctiInfo?.is_bcti && (
          <h2 className="has_margin">
            {accountConfig?.getIn(["buyer_created_tax_invoices", "title"])}{" "}
            cannot be switched on.{" "}
          </h2>
        )}
        Please contact your Account Admin to update these details in{" "}
        {contactCompany ? "Contact" : "Manage Account"}:<br />
        {contactCompany && <h3>[{contactCompany}]</h3>}
        <ul className="has_padding">
          {missingInfo.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
        <br />
        You can find Account Admin details in Contract Members within Settings.
        <br />
      </>
    </BasicModal>
  );
}

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default connect(mapStateToProps, null)(BctiWarningModal);
