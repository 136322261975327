import React from "react";
import { Loading } from "./styled";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoadingBar = ({ message }) => (
  <center>
    <Loading>
      <CircularProgress style={{ color: "#fff" }} />
      <div className="message">{message || "Loading Information ..."}</div>
    </Loading>
  </center>
);
export default LoadingBar;
