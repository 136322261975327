import "react-phone-number-input/style.css";
import { connect } from "react-redux";
import PhoneInput from "react-phone-number-input";
import React, { Component } from "react";
import "./telephone-style.css";
import { DEFAULT_COUNTRY } from "../../utils/constants";

class renderFieldPhone extends Component {
  render() {
    const {
      input: { value, onChange },
      readOnly,
    } = this.props;
    return !readOnly ? (
      <div>
        <PhoneComponent addPhoneNumber={value} handleOnChange={onChange} />
      </div>
    ) : (
      <div className="readOnly">{value}</div>
    );
  }
}

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export const PhoneComponent = connect(
  mapStateToProps,
  null,
)(({
  handleOnChange,
  addPhoneNumber,
  disabled,
  accountConfig,
  defaultValue,
}) => {
  let countryDefault =
    accountConfig?.getIn(["phone_number", "code"]) || DEFAULT_COUNTRY;
  if (defaultValue) {
    countryDefault = defaultValue;
  }
  return (
    <div>
      <PhoneInput
        placeholder="Enter phone number here.."
        onChange={handleOnChange}
        value={addPhoneNumber}
        international
        defaultCountry={countryDefault}
        countryOptionsOrder={["NZ", "AU", "|"]}
        disabled={disabled}
      />
    </div>
  );
});

export default renderFieldPhone;
