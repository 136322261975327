export const INITIAL_ITEM_LIST =
  "create-new-contract/base_contract/INITIAL_ITEM_LIST";
export const RESET = "create-new-contract/base_contract/RESET";
export const EMPTY_ITEM_LIST =
  "create-new-contract/base_contract/EMPTY_ITEM_LIST";
export const LOADING_STATUS_CHANGE =
  "create-new-contract/base_contract/LOADING_STATUS_CHANGE";
export const SET_VALUE = "create-new-contract/base_contract/SET_VALUE";
export const INITIAL_FILE_LIST =
  "create-new-contract/base_contract/INITIAL_FILE_LIST";
export const CHANGE_FILE_LIST =
  "create-new-contract/base_contract/CHANGE_FILE_LIST";
export const CANCEL_ONE_FILE =
  "create-new-contract/base_contract/CANCEL_ONE_FILE";
