import styled from "styled-components";
import * as theme from "../../theme";
import yellowIcon from "../../statics/img/icon-information-yellow.svg";
import redIcon from "../../statics/img/icon-information-red.svg";

export const WarningWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  margin: 16px 0;

  width: 100%
  height: auto;

  background: ${(props) =>
    props.type === "error"
      ? theme.palette.lightPastelPink
      : theme.palette.paleYellow};
  border-radius: 4px;

  .icon{
    align-self: flex-start;
    width: 24px;
    height: 24px;
    background: ${(props) =>
      props.type === "error"
        ? `url(${redIcon}) no-repeat`
        : `url(${yellowIcon}) no-repeat`};
    transform:  ${(props) =>
      props.type !== "error" ? `rotate(180deg)` : `rotate(0deg)`};
    margin-right: 8px;
  }

  &.margins {
    padding: 30px 50px;
    .icon {
      padding-right: 50px;
    }

    a {
      color: ${theme.palette.gunmetal};
      cursor: pointer;
      text-decoration: underline;
    }
  }

`;

export const ErrorWrapper = styled.div`
  .error_item {
    padding-top: 7px;
  }
`;
