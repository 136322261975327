import React, { useEffect, useState } from "react";
import { ToastStyle } from "./style";

// fixed toast themes
const TOAST_TYPES = {
  default: { icon: "", colour: "burnt" },
  information: { icon: "info", colour: "burnt" },
  success: { icon: "check", colour: "avocado" },
  warning: { icon: "warning", colour: "honey" },
  error: { icon: "error", colour: "ketchup" },
};

const Toast = (props) => {
  const {
    id,
    title,
    description,
    icon,
    colour,
    actionTitle,
    action,
    dismiss,
    autoDismissOnly,
  } = props;
  const [theme, setTheme] = useState(TOAST_TYPES.default);

  useEffect(() => {
    let initialTheme = { ...TOAST_TYPES.default };
    if (props.type) {
      initialTheme = TOAST_TYPES[props.type];
    } else {
      initialTheme = {
        icon: icon ? icon : TOAST_TYPES.default.icon,
        colour: colour ? colour : TOAST_TYPES.default.colour,
      };
    }
    setTheme(initialTheme);
    setTimeout(() => dismiss(id), 2000);
  }, []); // eslint-disable-line

  return (
    <ToastStyle className={`toast ${theme.colour}`}>
      {theme.icon && <div className={`icon ${theme.icon}`} />}
      {title && <div className="title">{title}</div>}
      {description && <div className="description">{description}</div>}
      {action && (
        <div className="action" onClick={action}>
          {actionTitle || "Action"}
        </div>
      )}
      {!autoDismissOnly && id && (
        <div
          className={`dismiss ${theme.colour}`}
          onClick={() => dismiss(id)}
        />
      )}
    </ToastStyle>
  );
};

export default Toast;
