import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BlueWhiteBtn as Apply,
  BlueOutlinedBtn as BlueButton,
} from "../button";
import { FilterContainer } from "./styled";
import Select from "./selectChip";
import { actionCreators } from "../header/store";

class FilterBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selAccount: this.props.accountFilterList.toJS(),
    };
  }
  render() {
    const { openFilter, selAccount } = this.state;
    const { accountList, role } = this.props;
    let options = [];
    accountList.map((item, index) => {
      let a = {
        value: item.get("account_id"),
        label: item.get("account_name"),
        isDisabled: item.get("role_key") === "user",
      };
      options = options.concat(a);
      return options;
    });

    return (
      (role === "owner" || role === "admin") && (
        <FilterContainer>
          <div className={`filter_bar ${openFilter ? "show" : "hide"}`}>
            <div className="filter_bar_top">
              <div className="title">View Multiple Accounts</div>
              <div className="sub_title">
                Filter will be applied to your Dashboard, Projects, Claims
                Received and Claims & Certs page.
              </div>
              <div>
                <label htmlFor="" className="title small">
                  View Page As…
                </label>
                <Select
                  options={options}
                  value={selAccount}
                  action={(value) =>
                    this.setState({
                      selAccount: value,
                    })
                  }
                />
              </div>
            </div>
            <hr />
            <div className="filter_bar_action">
              <Apply
                title="Apply"
                margin="0 0 0 20px"
                onClick={() => {
                  this.props.setAccount(selAccount);
                  this.props.applyFilter(selAccount);
                  this.setState({
                    openFilter: false,
                  });
                }}
                disabled={selAccount === null || selAccount.length === 0}
              />
              <BlueButton
                title="Cancel"
                onClick={() =>
                  this.setState({
                    openFilter: false,
                    selAccount: this.props.accountFilterList.toJS(),
                  })
                }
              />
              <BlueButton
                title="Clear"
                float="left"
                onClick={() => {
                  this.setState({ openFilter: false, selAccount: [] });
                  this.props.setAccount([]);
                  this.props.applyFilter([]);
                }}
              />
            </div>
          </div>
        </FilterContainer>
      )
    );
  }
}
const mapStateToProps = (state) => ({
  role: state.getIn(["headers", "accountRole"]),
  accountList: state.getIn(["headers", "accountList"]),
  accountFilterList: state.getIn(["headers", "accountFilterList"]),
});

const mapDispatch = (dispatch) => {
  return {
    setAccount(list) {
      dispatch(actionCreators.setImuData("accountFilterList", list));
    },
  };
};

export default connect(mapStateToProps, mapDispatch)(FilterBar);
