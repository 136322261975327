import { createBrowserHistory } from "history";
import ReactGA from "react-ga4";

const history = createBrowserHistory();
history.listen(function (location) {
  if (ReactGA._hasLoadedGA) {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }
});

export default history;
