import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import {
  OverviewWrapper,
  OverviewDetailWrapper,
  ContactWrapper,
} from "../styled";

import { capitalizeAddress } from "../../../utils/helper";
import { currencySubunitToUnit } from "../../../utils/currencyHelper";
import { getContractTag } from "../../project/components/dueWarningBadge";
import { formatDate } from "../../../utils/dateTimeHelper";
import { headerFormatter } from "../../../utils/tableFormatHelper";

import { actionCreators } from "../store";

class index extends PureComponent {
  render() {
    const { contractDetail, accountConfig } = this.props;
    const showCurrency = !accountConfig?.get("enable_header_currency");

    return (
      <Fragment>
        <OverviewWrapper>
          <OverviewDetailWrapper>
            <div className="profile">
              <div className="title">
                {contractDetail?.get("payer_info")?.get("account_name")}
              </div>
              <div className="detail less-margin">
                {contractDetail.get("contract_number")}
              </div>
              <div className="detail">
                {contractDetail.get("contract_name")}
              </div>
              <div>{getContractTag(contractDetail, accountConfig)}</div>
            </div>
            {[
              {
                label: headerFormatter(
                  "Contract Total",
                  accountConfig?.getIn(["currency", "code"]),
                  accountConfig?.get("enable_header_currency"),
                ),
                value: contractDetail.get("total"),
              },
              {
                label: headerFormatter(
                  `${accountConfig?.getIn(["claim", "value"])} to Date`,
                  accountConfig?.getIn(["currency", "code"]),
                  accountConfig?.get("enable_header_currency"),
                ),
                value: contractDetail.get("claim_value"),
                date: contractDetail.get("claim_date"),
              },
              {
                label: headerFormatter(
                  "Approved to Date",
                  accountConfig?.getIn(["currency", "code"]),
                  accountConfig?.get("enable_header_currency"),
                ),
                value: contractDetail.get("cert_value"),
                date: contractDetail.get("cert_date"),
              },
              {
                label: headerFormatter(
                  "Remaining",
                  accountConfig?.getIn(["currency", "code"]),
                  accountConfig?.get("enable_header_currency"),
                ),
                value: contractDetail.get("remaining"),
              },
            ].map((item, index) => (
              <div className="value" key={index}>
                <div className="title">
                  {item.label}
                  {item.date && (
                    <div className="downDate">
                      {formatDate(item.date, "D/MM/YY")}
                    </div>
                  )}
                  {!item.date && <div className="downDateNo" />}
                </div>
                <div className="instance">
                  {currencySubunitToUnit(item.value, showCurrency)}
                </div>
              </div>
            ))}
          </OverviewDetailWrapper>

          <ContactWrapper>
            <div className="title">Contract Info</div>
            <div className="content">
              {[
                {
                  label: "contract address",
                  value: capitalizeAddress(
                    contractDetail?.get("street_address"),
                    contractDetail?.get("suburb"),
                    contractDetail?.get("city"),
                    contractDetail?.get("postal_code"),
                    contractDetail?.get("state"),
                    contractDetail?.get("country"),
                  ),
                },
                {
                  label: "contact name",
                  value: contractDetail?.get("payer_info")?.get("name"),
                },
                {
                  label: "email",
                  value: contractDetail?.get("payer_info")?.get("email"),
                },
                {
                  label: "phone",
                  value: contractDetail?.get("payer_info")?.get("phone"),
                },
              ].map((item, index) => (
                <div className="item" key={index}>
                  <label>{item.label}</label>
                  <div className="instance">{item.value}</div>
                </div>
              ))}
            </div>
          </ContactWrapper>
        </OverviewWrapper>
      </Fragment>
    );
  }
  componentDidMount() {
    const { proId, contractId } = this.props;
    this.props.readContract(proId, contractId);
  }
}
const mapStateToProps = (state) => ({
  contractDetail: state.getIn(["makeaclaim", "contractDetail"]),
});
const mapDispatchToProps = (dispatch) => {
  return {
    readContract(proId, contractId) {
      dispatch(actionCreators.readContract(proId, contractId));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(index);
