import React from "react";
import {
  BlueOutlinedBtn as Button,
  GreenWhiteBtn as ButtonGreen,
} from "../../../../common/button";
import { BottomWrapper } from "./style";
import Link from "../../../../common/linkWithQuery";
export default function NavBottom(props) {
  return (
    <BottomWrapper>
      <ButtonGreen title="SAVE" padding="10px 41px" type="submit" />
      <Link to="/contacts">
        <Button title="CANCEL" margin="0 30px 0 0" padding="10px 34px" />
      </Link>
    </BottomWrapper>
  );
}
