import styled from "styled-components";
import * as theme from "../../theme";

export const BgWrapper = styled.div`
  width: 1183px;

  margin: 56px auto;
  padding: 0 20px;
  box-sizing: border-box;
  height: auto;
  font-weight: ${theme.font.normalFontWeight};
  font-size: 16px;
  color: ${theme.palette.gunmetal};
  font-family: ${theme.font.mainFont};
  &.header {
    margin: 0 auto;
  }
  &.medium {
    width: 1380px;
  }
  &.big {
    width: 1400px;
  }
  &.large {
    width: 1480px;
  }
  &.account {
    width: 960px;
    padding: 0;
  }
`;
export const HeaderTitle = styled.h3`
  font-size: 34px;
  margin-bottom: 56px;
  font-weight: ${theme.font.mainFontWeight};
  letter-spacing: normal;
  .subtitle {
    font-size: 24px;
    margin-top: 8px;
  }
  &.align-left {
    float: left;
  }

  .issued-date {
    font-size: 16px;
    margin-top: 8px;
    line-height: 20px;
  }
`;
