import { fromJS } from "immutable";
import { constants } from ".";

const defaultState = fromJS({
  defaultInfo: [],
  projectInfo: [],
  contractInfo: [],
  completionSteps: {
    "nav-dateSchedule": false,
    "nav-baseContracts": false,
    "nav-invitation": false,
    "nav-tax": false,
  },
  loading: true,
  members: [],
  invitationList: [],
  prefilledSeen: {
    dateSchedule: true,
    contractMembers: true,
    workflow: true,
    timezone: true,
    retentions: true,
  },
  attachments: [],
  uploadsList: {}, // uploading files
  companyList: [],
  contactList: [],
  contractType: "claim",
  dueDates: {},
  claimSummary: [],
  claimInfo: {},
  payHistory: [],
});

const uploadsListAdd = (state, action) => {
  let uploadsList = state.get("uploadsList").toJS();

  uploadsList = {
    ...uploadsList,
    [action.payload.file.name]: {
      file: action.payload.file,
      progress: action.payload.progress,
    },
  };
  return state.set("uploadsList", fromJS(uploadsList));
};

const uploadListRemove = (state, action) => {
  let uploadsList = state.get("uploadsList").toJS();
  delete uploadsList[action.fileName];
  return state.set("uploadsList", fromJS(uploadsList));
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SET_DATA:
      return state.set(action.payload.name, fromJS(action.payload.value));
    case constants.RESET_DATA:
      return defaultState;
    case constants.UPLOADS_LIST_ADD:
      return uploadsListAdd(state, action);
    case constants.UPLOADS_LIST_REMOVE:
      return uploadListRemove(state, action);
    default:
      return state;
  }
};
