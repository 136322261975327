import styled from "styled-components";
import * as theme from "../../theme";
import checkIcon from "../../statics/img/icon-check-box-check-dark-grey.svg";
import uncheckIcon from "../../statics/img/icon-check-box-un-check-dark-grey.svg";
import delFileIcon from "../../statics/img/icon-clear-medium-grey.svg";
import noticeIcon from "../../statics/img/icon-information-light-grey.svg";
import rightIcon from "../../statics/img/icon-chevron-right-light-grey.svg";
import infoIcon from "../../statics/img/icon-information-white.svg";
import infoBlueIcon from "../../statics/img/icon-information-blue.svg";
import errorIcon from "../../statics/img/icon-information-red.svg";

export const CloseIcon = styled.div`
  height: 24px;
  width: 24px;
  background: url(${delFileIcon}) no-repeat;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
`;
export const ProjectWrapper = styled.div`
  height: auto;
  width: 100%;
`;
export const SemiWrapper = styled.div`
  padding: 0 44px;
`;
export const ErrorWrapper = styled.div`
  .error_item {
    padding-top: 7px;
  }
`;
export const OutLayerWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 40px;
  margin-bottom: 56px;
  box-sizing: border-box;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  display: table;
  color: ${theme.palette.gunmetal};
  &.tableWidth {
    padding: 40px 18px;
  }
  // Style for lineitem_options based on lump
  .grey-dash {
    display: inline-block;
    width: 10px;
    height: 1px;
    background-color: grey;
  }
`;

export const ProjectNavList = styled.div`
  width: 100%;
  line-height: 24px;
  font-size: 24px;
  font-weight: ${theme.font.mainFontWeight};
  margin-bottom: 20px;
  &.sub_title {
    font-size: 20px;
    line-height: 20px;
  }
  &.no_margin {
    margin: 0;
  }
`;

export const ClaimWrapper = styled.div`
  width: 1020px;
  height: auto;
  display: flex;
  flex-direction: column;
  .header {
    font-size: 24px;
    font-weight: ${theme.font.mainFontWeight};
    height: 24px;
    margin-bottom: 40px;
  }
  .sub_header {
    font-size: 24px;
    font-weight: ${theme.font.mainFontWeight};
    height: 24px;
    margin: 40px 0 30px 0;
  }

  .line_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &.sub {
      margin-top: 24px;
    }
    &.bottom {
      margin-top: 60px;
    }
  }
  .multiDate {
    margin-top: 60px;
    display: grid;
    grid-auto-rows: fit-content;
    grid-row-gap: 32px;
  }
  .inputStyle {
    width: 450px;
  }
  .inputStyle_days {
    width: 54px;
    margin-right: 6px;
  }
  .inputStyle_term {
    width: 390px;
  }
`;

//summary style
export const SummaryWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 40px;
  box-sizing: border-box;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  display: table;
  &.claim {
    margin-bottom: 56px;
  }
  &.review {
    box-shadow: none;
  }
  .dataWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;
export const ItemListWrapper = styled.div`
  margin-top: 20px;
  width: 600px;
  .noticeImage {
    height: 24px;
    width: 24px;
    background: url(${noticeIcon}) no-repeat;
    background-size: contain;
    margin: 10px 10px 10px 50px;
  }
`;
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  .row {
    height: fit-content;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: ${theme.font.normalFontWeight};
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      float: left;
      line-height: 44px;
      text-align: center;
    }

    .less-previous-container {
      margin-top: 16px;
    }

    .grey-dash {
      display: inline-block;
      width: 10px;
      height: 1px;
      background-color: grey;
    }

    .bold {
      font-weight: ${theme.font.mainFontWeight};
    }
    .selectFrame {
      margin-left: 12px;
      float: left;
    }
    .inputFrame {
      height: 44px;
      width: 156px;
      border: 1px solid ${theme.palette.wolfGrey};
      font-weight: ${theme.font.normalFontWeight};
      font-family: ${theme.font.mainFont};
      font-size: 16px;
      text-align: right;
      color: ${theme.palette.gunmetal};
      padding: 0 16px 0 0;
      box-sizing: border-box;
      -moz-appearance: textfield;
      border-radius: 4px;
      &.required {
        border: 1px solid ${theme.palette.turquoiseBlue};
      }
    }
  }
  .bottom_line {
    height: 1px;
    background-color: ${theme.palette.lightWolfGrey};
  }
  .checkBox {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    background: url(${uncheckIcon}) no-repeat;
    background-size: contain;
    display: block;
    cursor: pointer;
    float: left;
    margin: 0 12px 0 0;
    :checked {
      background: url(${checkIcon}) no-repeat;
    }
  }
  .claim-all-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 1px 1px 8px;
    margin: 0 10px;
    margin-left: auto;

    width: fit-content;
    height: 44px;
    border: 1px solid ${theme.palette.wolfGrey};
    border-radius: 2px;
    cursor: pointer;

    .right-icon {
      width: 24px;
      height: 24px;
      background: url(${rightIcon}) no-repeat;
      background-size: contain;
      margin-left: 8px;
    }
  }
  .split-wrap {
    width: 145px;
  }
  .has-margin {
    margin-left: auto;
    margin-right: 180px;
  }
  .addition-bar {
    display: flex;
    margin: 14px 0 16px 0;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background: linear-gradient(
        0deg,
        ${theme.palette.lightPastelBlue},
        ${theme.palette.lightPastelBlue}
      ),
      ${theme.palette.greyWhite};
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
    color: ${theme.palette.gunmetal};
    line-height: 20px;
    position: relative;
    .arrow {
      &:before {
        position: absolute;
        content: "";
        right: 40px;
        border: 18px solid transparent;
        border-bottom: 24px solid ${theme.palette.lightPastelBlue};
        pointer-events: none;
        top: -40px;
        filter: drop-shadow(0px -2px 2px rgba(0, 0, 0, 0.09));
      }
    }
    .info-icon {
      width: 24px;
      height: 24px;
      mask: url(${infoIcon}) no-repeat center;
      background-color: ${theme.palette.tealBlue};
      margin-right: 16px;
    }
    .container {
      display: flex;
      margin-bottom: 8px;
    }
    .message {
      width: fit-content;
    }
  }
`;

export const OptionBoxWrap = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  margin: 8px 0;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid ${theme.palette.wolfGrey};
  background-color: ${(props) =>
      props.selected ? theme.palette.white : theme.palette.lightPastelBlue}
    .content {
    margin-left: 36px;
  }
  &:hover {
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  }
`;

export const TotalWrapper = styled.div`
  height: 76px;
  width: 614px;
  background-color: ${theme.palette.tealBlue};
  box-sizing: border-box;
  color: ${theme.palette.white};
  padding: 12px 16px;
  .titleLeft {
    font-size: 16px;
    font-weight: ${theme.font.mainFontWeight};
  }
  .titleRight {
    float: right;
    font-size: 28px;
    font-weight: ${theme.font.mainFontWeight};
  }
`;

//bottom btn style
export const ProjectBottomWrapper = styled.div`
  width: 100%;
  height: 40px;
  margin: 0 0 62px 0;
  &.review,
  .nav-right {
    display: flex;
  }
  &.review {
    justify-content: space-between;
    float: left;
    margin-bottom: 90px; // Gives more space for the pdf dropdown
  }
`;

// review page
export const ProjectInfoWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 0 0 56px 0;

  .bottomWrapper {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
    color: ${theme.palette.gunmetal};
    background-color: ${theme.palette.white};
  }
  .line {
    margin: auto;
    height: 1px;
    background-color: ${theme.palette.lightWolfGrey};
  }
  .approved {
    height: auto;
    width: 1440px;
    margin-left: -148px;
    background-color: ${theme.palette.veryColdGrey};
    float: left;
  }

  th:has(.claimed-total) {
    text-align: left;
    span {
      padding: 0 15px;
    }
  }
`;
export const ReviewWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 40px;
  box-sizing: border-box;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  &.project {
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  }
  &.readonly {
    color: ${theme.palette.wolfGrey};
  }
  &.wide_container {
    margin: 56px 0;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
    &.var {
      margin-top: 0;
    }
  }
  &.approver {
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;
export const ReviewApprovedWrapper = styled.div`
  margin-top: 56px;
  height: auto;
  padding: 40px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  color: ${theme.palette.gunmetal};
  background-color: ${theme.palette.white};
`;

// project info section
export const DetailWrapper = styled.div`
  height: auto;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const SubDetail = styled.div`
  &.review {
    width: 576px;
  }
  width: 619px;
  height: auto;
  padding: 40px;
  box-sizing: border-box;
  margin-bottom: 20px;
  background-color: ${theme.palette.veryColdGrey};
  .titleS {
    padding-bottom: 8px;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: ${theme.font.mainFontWeight};
    color: ${theme.palette.gunmetal};
  }
`;

export const ColumnItemWrapper = styled.div`
  height: auto;
  width: 100%;
  color: ${theme.palette.gunmetal};
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .item_wrapper {
    border-bottom: 1px solid ${theme.palette.wolfGrey};
    height: auto;
    min-height: 21px;
    padding-bottom: 4px;
    box-sizing: border-box;
    margin-top: 12px;
  }
  .attribute_name {
    font-weight: ${theme.font.mainFontWeight};
    float: left;
  }
  .value {
    font-weight: ${theme.font.normalFontWeight};
    margin-left: 131px;
    width: 290px;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 17px;
  }
  .period {
    margin-left: 131px;
    width: 293px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .from {
      width: 136px;
      .fromS {
        font-weight: ${theme.font.mainFontWeight};
        margin-right: 12px;
        float: left;
      }
    }
  }
`;
export const Comment = styled.div`
  font-size: 14px;
  font-weight: ${theme.font.normalFontWeight};
  color: ${theme.palette.gunmetal};
  margin-bottom: 40px;
`;
//note
export const Note = styled.div`
  width: 100%;
  height: auto;
  margin-top: 40px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  .textarea {
    width: 100%;
    height: 124px;
    padding: 12px;
  }
  &.readonly {
    color: ${theme.palette.wolfGrey};
  }
  .bottom {
    margin-top: 40px;
  }
  .detail {
    width: 100%;
    height: auto;
    resize: vertical;
    border: none;
    font-size: 16px;
    outline: none;
    background-color: white;
    padding: 10px 0;
    &.readonly {
      color: ${theme.palette.wolfGrey};
    }
  }
`;

//contract overview style
const normal = theme.font.mainFontWeight,
  lighter = theme.font.normalFontWeight;

export const OverviewWrapper = styled.div`
  margin: 0 0 56px 0;
  height: auto;
  border-radius: 4px;
  background-color: ${theme.palette.white};
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
`;
export const OverviewDetailWrapper = styled.div`
  height: auto;
  min-height: 224px;
  border-bottom: 1px solid ${theme.palette.lightWolfGrey};
  width: 100%;
  display: flex;
  flex-direction: row;
  color: ${theme.palette.gunmetal};
  font-weight: ${lighter};
  font-size: 16px;
  .profile {
    width: 392px;
    float: left;
    box-sizing: border-box;
    padding: 40px 20px 40px 40px;
    .title {
      font-size: 24px;
      font-weight: ${normal};
    }
    .detail {
      margin: 24px 0;
      &.less-margin {
        margin-top: 8px;
      }
    }
  }
  .value {
    float: left;
    box-sizing: border-box;
    width: 261px;
    min-height: 224px;
    border-left: 1px solid ${theme.palette.lightWolfGrey};
    padding: 62px 20px;
    text-align: center;
    .title {
      font-weight: ${normal};
      color: ${theme.palette.veryMediumGrey};
    }
    .downDate {
      font-size: 14px;
      font-weight: 400;
      color: ${theme.palette.veryMediumGrey};
      margin: 12px auto 20px;
      height: 28px;
      width: 100px;
      background-color: ${theme.palette.veryWarmLightGrey};
      border-radius: 4px;
      line-height: 28px;
    }
    .downDateNo {
      margin: 12px auto 20px;
      height: 28px;
      width: 100px;
    }
    .instance {
      font-size: 24px;
      font-weight: ${normal};
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;

export const ContactWrapper = styled.div`
  height: auto;
  padding: 30px 40px;
  width: 85%;
  box-sizing: border-box;
  display: block;
  .title {
    font-size: 20px;
    font-weight: ${normal};
    margin-bottom: 24px;
  }
  .content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .item {
      float: left;
      height: 100%;
      margin-right: 10px;
      max-width: 300px;
      label {
        font-size: 16px;
        font-weight: ${normal};
        text-transform: capitalize;
      }
      .instance {
        font-size: 16px;
        margin-top: 4px;
      }
    }
  }
`;

export const CheckboxWrapper = styled.div`
  .checkBox {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    mask: url(${uncheckIcon}) no-repeat;
    background-size: contain;

    margin-right: 12px;
    background-color: ${theme.palette.gunmetal};
    :checked {
      mask: url(${checkIcon}) no-repeat;
    }
    :disabled {
      background-color: ${theme.palette.veryMediumGrey};
      cursor: not-allowed;
    }
  }
  label {
    cursor: pointer;
  }
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 24px;
`;

export const MaterialsSection = styled.div`
  width: 100%;
  max-width: 1360px;
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: 16px;

  .title-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;

    span {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .materials {
    display: grid;
    grid-auto-rows: auto;
    grid-row-gap: 16px;
    .sub-title{
      font-size: 20px;
      font-weight: bold;
    }
  }

  .claiming-cell {
    height: 100%;
    // width: calc(100% - 32px);
    padding 8px 20px;
    display: grid;
    grid-template-columns: auto 20px;
    grid-column-gap: 8px;


    .form_input {
      padding: 8px;
      height: 26px;
      border-radius: 0;
      font-size: 14px;
    }

    .info-icon {
      mask: url(${infoIcon}) no-repeat center;
      background-color: ${theme.palette.veryMediumGrey};
      height: 20px;
      align-self: center;
    }

  }

  // table headers
  .header_table {
    font-size: 16px;
    font-weight: ${theme.font.mainFontWeight};
    letter-spacing: 0.25px;
    .label-claim {
      height: 44px;
      width: 290px;
      color: ${theme.palette.tealBlue};
      text-align: center;
      background-color: ${theme.palette.lightPastelBlue};
      padding: 14px 0;
      font-weight: bold;
    }
  }

  .table {
    th {
      span {
        font-size: 16px;
      }
    }
    td {
      &.net-claim {
        .inlineDiv {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        .action_container {
          position: relative;
          width: 24px;
          height: 24px;
          margin-left: 10px;
        }
      }
    }
    
    tfoot {
      .empty {
        width: 923px;
      }
      .claim {
        width: 290px;
        background-color: ${theme.palette.turquoiseBlue};
      }
      .net {
        background-color: ${theme.palette.veryMediumGrey};
      }
    }
  }
`;

export const StyledTooltip = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-top: 24px;
  margin-bottom: 16px;
  background-color: ${theme.palette.lightPastelBlue};
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  width: auto;

  .infoIcon {
    width: 24px;
    height: 24px;
    background: url(${infoBlueIcon}) no-repeat;
    background-size: contain;
    margin-right: 18px;
  }

  .selectOption {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }

  .additionalContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 85%;
    border: 18px solid transparent;
    border-bottom: 24px solid ${theme.palette.lightPastelBlue};
    pointer-events: none;
    filter: drop-shadow(0px -2px 2px rgba(0, 0, 0, 0.09));
  }
`;

export const Error = styled.div`
  font-size: 12px;
  color: ${theme.palette.watermelonRed};
  display: flex;
  align-items: center;
  margin-top: 6px;
  font-weight: ${theme.font.mainFontWeight};
  .error_img {
    background: url(${errorIcon});
    background-size: contain;
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`;
