import React, { useState } from "react";
import { connect } from "react-redux";

import { BCTIBanner, Error } from "./styled";
import Input from "../form";

import AddressSearch from "../mapbox/addressSearch";
import { BasicModal } from "./index";
import { StateWarningModal } from "../config/stateConfigInput";

import { COUNTRY_AU } from "../../utils/constants";

import { actionCreators as configActionCreators } from "../config/store";
import MapboxDisplay from "../mapbox/mapboxDisplay";

const RequiredInput = (props) => {
  return (
    <div>
      <Input {...props} />
      {props.error && (
        <Error>
          <div className="error_img" />
          {props.error}
        </Error>
      )}
    </div>
  );
};

const PageBtnLabel = {
  contract: {
    saveText: "Save",
    cancelText: "Cancel",
  },
  claim: {
    saveText: "Save",
    cancelText: "Continue Anyway",
  },
  issueClaim: {
    saveText: "Save & Issue",
    cancelText: "Cancel",
  },
  cert: {
    saveText: "Save",
    cancelText: "Continue Anyway",
  },
  issueCert: {
    saveText: "Save & Issue",
    cancelText: "Cancel",
  },
};

const BctiInfoModal = (props) => {
  const { close, open, page, bctiInfo, submit, accountConfig } = props;
  const [info, setInfo] = useState({});
  const [address, setAddress] = useState({});
  const [missingState, setMissingState] = useState(false);

  const handleChange = (e) => {
    let name = e.target.name,
      value = e.target.value;
    let newInfo = Object.assign({}, info, {
      [name]: value,
    });
    return setInfo(newInfo);
  };

  const handleChangeAddress = (e, field) => {
    let name = field || e.target.name,
      value = field ? e.value : e.target.value,
      newInfo = {};
    newInfo = Object.assign({}, address, {
      [name]: value,
    });
    if (field === "country") {
      if (!bctiInfo?.has_address && e.value === COUNTRY_AU && !address.state) {
        setMissingState(true);
      }
    }
    return setAddress(newInfo);
  };

  const setTargetAddress = (data) => {
    let newInfo = Object.assign({}, address, data);
    if (!data.state && data.country === COUNTRY_AU) {
      setMissingState(true);
    }
    return setAddress(newInfo);
  };

  const checkCompleted = () => {
    if (
      !bctiInfo?.has_address &&
      (!address.street_address || !address.country)
    ) {
      return false;
    }
    if (
      !bctiInfo?.has_address &&
      address.country === COUNTRY_AU &&
      !address.state
    ) {
      return false;
    }
    if (!bctiInfo?.has_gst && !info.gst_number) {
      return false;
    }
    if (!bctiInfo?.has_name && !info.name) {
      return false;
    }
    return true;
  };

  return (
    <BasicModal
      className="align-row"
      open={open}
      close={() => {
        close();
        setInfo({});
        setAddress({});
      }}
      submit={() => submit({ ...info, ...address })}
      saveText={PageBtnLabel[page]["saveText"]}
      cancelText={PageBtnLabel[page]["cancelText"]}
      disabled={!checkCompleted()}
    >
      <>
        <BCTIBanner isBCTIOn={bctiInfo?.is_bcti}>
          {bctiInfo?.is_bcti && (
            <b>
              {accountConfig?.getIn(["buyer_created_tax_invoices", "title"])} is
              switched on.{" "}
            </b>
          )}
          Some more details are needed for{" "}
          {accountConfig?.getIn(["buyer_created_tax_invoices", "title"])}. Enter
          the details below and it will be saved to your account and used on the
          invoice.
          <br />
          <br />
          {bctiInfo?.is_bcti && (
            <>
              If this is not needed. Please go to Contract Settings to turn off{" "}
              {accountConfig?.getIn(["bcti", "title"])}.
            </>
          )}
        </BCTIBanner>

        {bctiInfo?.has_gst && bctiInfo?.has_name && <div>&nbsp;</div>}

        {!bctiInfo?.has_gst && (
          <Input
            label={accountConfig?.getIn(["gst_number", "title"])}
            name="gst_number"
            wrapperClassName={
              !bctiInfo?.has_name
                ? "small-width input-margin"
                : "input-margin full-width"
            }
            value={info.gst_number || ""}
            onChange={handleChange}
          />
        )}
        {!bctiInfo?.has_name && (
          <Input
            label="Company Name"
            name="name"
            wrapperClassName={
              !bctiInfo?.has_gst
                ? "small-width input-margin"
                : "input-margin full-width"
            }
            value={info.name || ""}
            onChange={handleChange}
          />
        )}

        {!bctiInfo?.has_address && (
          <div className="info-bar">
            <b>Company Address</b>
            <MapboxDisplay setAddress={(data) => setTargetAddress(data)}>
              <AddressSearch
                hasRestrictCountry
                setAddress={(data) => setTargetAddress(data)}
              />
            </MapboxDisplay>
            <RequiredInput
              label={accountConfig.getIn(["address", "street"])}
              name="street_address"
              wrapperClassName="input-small-margin full-width"
              onChange={handleChangeAddress}
              value={address.street_address || ""}
            />
            <div className="flex-row">
              <RequiredInput
                label={accountConfig.getIn(["address", "suburb"])}
                name="suburb"
                wrapperClassName="input-small-margin medium-width"
                onChange={handleChangeAddress}
                value={address?.suburb || ""}
              />
              <RequiredInput
                label={accountConfig.getIn(["address", "city"])}
                name="city"
                wrapperClassName="input-small-margin small-width"
                onChange={handleChangeAddress}
                value={address?.city || ""}
              />
            </div>
            <div className="flex-row">
              <RequiredInput
                label={accountConfig.getIn(["address", "postcode"])}
                name="postcode"
                wrapperClassName="mini-width input-small-margin"
                onChange={handleChangeAddress}
                value={address?.postcode || ""}
              />
              {address.country !== "AU" ? (
                <RequiredInput
                  label={accountConfig.getIn(["address", "state"])}
                  name="state"
                  wrapperClassName="input-small-margin mini-width"
                  onChange={handleChangeAddress}
                  value={address?.state || ""}
                />
              ) : (
                <RequiredInput
                  label={accountConfig.getIn(["address", "state"])}
                  name="state"
                  field="dropdown"
                  placeholder={`Select ${accountConfig.getIn(["address", "state"])}`}
                  wrapperClassName="input-small-margin mini-width"
                  width="181px"
                  options={accountConfig.get("state")?.toJS() || []}
                  onChange={(e) => handleChangeAddress(e, "state")}
                  value={address?.state || ""}
                  missingRequired={missingState && !address.state}
                />
              )}
              <RequiredInput
                field="lock-country"
                wrapperClassName="input-small-margin mini-width"
                width="181px"
                country={address?.country}
              />
            </div>
            <StateWarningModal
              country={address?.country}
              state={address?.state}
              showError={missingState}
            />
          </div>
        )}
      </>
    </BasicModal>
  );
};

const mapStateToProps = (state) => ({
  countriesList: state.getIn(["config", "countriesList"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});

const mapDispatchToProps = (dispatch) => ({
  getCountries() {
    dispatch(configActionCreators.readCountries());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BctiInfoModal);
