import React, { useState } from "react";

import { InvitationWrap, WelcomeWrapper, CompanyImage } from "../styled";
import { DownloadBtn as ButtonYellow } from "../../../common/button";

const InvitationBar = (props) => {
  const { contractInfo, accountConfig } = props;

  const inviter = contractInfo.get("claim_account")
      ? accountConfig?.getIn(["claim", "name"])
      : "Certifier",
    contactType = contractInfo.get("cert_account") ? "payer" : "payee",
    accountType = contractInfo.get("cert_account") ? "cert" : "claim";
  const [noticeSeen, setNoticeSeen] = useState(false);

  return (
    <>
      <InvitationWrap className="full-info">
        <div>
          <div className="person-icon" />
          <h1>{inviter}</h1>
        </div>
        <div className="flex-wrap">
          <div className="pending-wrap approved">
            <div className="person-avatar" />
            <h2 className="has-margin">
              {contractInfo.getIn([contactType, "name"])}
            </h2>
            <h3>{contractInfo.getIn([contactType, "email"])}</h3>
            <h3>{contractInfo.getIn([contactType, "phone"])}</h3>
          </div>
          <div className="pending-wrap approved">
            <CompanyImage
              url={contractInfo.getIn([
                accountType + "_account",
                "account_image_url",
              ])}
            />
            <h2 className="has-margin">
              {contractInfo.getIn([accountType + "_account", "name"])}
            </h2>
            <h3>{contractInfo.getIn([accountType + "_account", "website"])}</h3>
            <h3>{contractInfo.getIn([accountType + "_account", "phone"])}</h3>
          </div>
        </div>
      </InvitationWrap>
      {!noticeSeen && (
        <WelcomeWrapper>
          <div className="arrow" />
          <label>
            This is the {inviter} contact. If you have questions about the
            contract, contact this person.
          </label>
          <ButtonYellow title="Got it" onClick={() => setNoticeSeen(true)} />
        </WelcomeWrapper>
      )}
    </>
  );
};

export default InvitationBar;
