import React, { Fragment, useEffect, useState } from "react";
import { ToggleButtonStyle } from "./styled";

const ToggleButtons = (props) => {
  const { height, buttonWidth, buttons, setValue, defaultValue, isBlocked } =
    props;
  const [active, setActive] = useState(defaultValue);

  useEffect(() => {
    setActive(defaultValue);
  }, [defaultValue]);

  const handleOnClick = (value) => {
    setActive(value);
    setValue(value);
  };

  return (
    <ToggleButtonStyle height={height} buttonWidth={buttonWidth}>
      {!isBlocked && (
        <Fragment>
          <div
            className={`button left ${active === buttons[0].value && "active"}`}
            onClick={() => handleOnClick(buttons[0].value)}
          >
            {buttons[0].label}
          </div>
          <div
            className={`button right ${
              active === buttons[1].value && "active"
            }`}
            onClick={() => handleOnClick(buttons[1].value)}
          >
            {buttons[1].label}
          </div>
        </Fragment>
      )}
      {props.render && props.render(active)}
    </ToggleButtonStyle>
  );
};

export default ToggleButtons;

export const ToggleMaterialButtons = (props) => {
  const { buttons, isBlocked } = props;
  return (
    <ToggleButtons
      {...props}
      render={(active) => (
        <>
          {isBlocked && (
            <Fragment>
              <div
                className={`button left disabled ${
                  active === buttons[0].value && "disabled-active"
                }`}
              >
                {buttons[0].label}
              </div>
              <div
                className={`button right disabled ${
                  active === buttons[1].value && "disabled-active"
                }`}
              >
                {buttons[1].label}
              </div>
            </Fragment>
          )}
        </>
      )}
    ></ToggleButtons>
  );
};
