import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { BgWrapper, HeaderTitle } from "../../common/shared_style";
import { ProjectWrapper, SemiWrapper } from "../makeclaim/styled";

import Loading from "../../common/loading";
import Menu from "../../common/breadcrumbs";
import BctiModal from "../../common/bcti";
import Attachment from "../../common/claim_cert_attachment";
import Prompt from "../../common/modal/component/prompt";

import CertDetail from "./components/certDetails";
import Table from "./components/paycertTable";
import Summary from "./components/summary";
import NavBarBottom from "./components/navBarBottom";
import Notes from "./pages/components/notes";
import { getMenuLink, CertContract } from "./components/pageMenu";
import { OverviewPageUrlContractType } from "../contract/common";

import profile from "../../utils/profile";
import { roundNumber } from "../../utils/numeralHelper";
import {
  CONTRACT,
  CLAIM_CERT,
  CONTRACT_TYPE,
  CERT_MATERIAL_ITEM,
  CERT_VARIATION_ITEM,
  CERT_ITEM_ACTION,
  STATUS_PENDING,
} from "../../utils/constants";
import history from "../../utils/history";
import { findKeyByValue } from "../../utils/helper";

import { actionCreators } from "./store";
import { actionCreators as actionCreatorsContract } from "../contract/store";
import MakeCertMaterials from "./components/materialsTable/index";
import API from "../../server";
import ErrorStripBanner from "pages/makeclaim/shared/errorStripBanner";
import CertOptionModal from "pages/contract/components/certOptionalModal";

class index extends Component {
  constructor(props) {
    super();
    this.state = {
      bctiInfo: {},
      open: false,
      allowCert: true,
      isChanged: false,
      openCertModal: false,
      errors: {},
      claimCertDetails: {},
    };

    this.currentRetentionPCRRRef = React.createRef();
    this.currentRetentionDLRRRef = React.createRef();
    this.currentRetentionPopupRef = React.createRef();
    this.previousLabourElementRef = React.createRef();
    this.labourElementRef = React.createRef();
  }

  // Performing the Save Review
  performSaveReview = (reviewStatus) => {
    const { saveReview, certId } = this.props;

    const { proId, contractId, claimId, contractType } =
      this.props.match.params;

    saveReview(proId, claimId, certId, reviewStatus, contractId, contractType);
  };

  performBctiSubmit = (data) => {
    const { proId, contractId, contractType } = this.props.match.params;
    const { certInfo, accountId } = this.props;
    const isAccountAdmin = profile.isAdminOrOwner();

    if (isAccountAdmin) {
      if (
        contractType === CONTRACT_TYPE.cert ||
        contractType === CONTRACT_TYPE.selfClaim
      ) {
        // Update Details at the Account Level
        this.props.updateBctiInfo(proId, contractId, data);
      } else if (contractType === CONTRACT_TYPE.selfCert) {
        // Update the Payer's details
        const payerCompany = certInfo?.get("payer_company");

        data.entity_id =
          payerCompany?.get("entity_id") || certInfo?.get("payer_entity_id");
        data.entity_name =
          payerCompany?.get("entity_name") ||
          certInfo?.get("payer_entity_name");

        // If BCTI Modal is not returning the data, then use the saved one from the payer details
        data.street_address =
          data?.street_address ||
          payerCompany?.get("street_address") ||
          certInfo?.get("payer_entity_streetaddress");
        data.postal_code =
          data?.postcode ||
          payerCompany?.get("postal_code") ||
          certInfo?.get("payer_entity_postal_code");
        data.suburb =
          data?.suburb ||
          payerCompany?.get("suburb") ||
          certInfo?.get("payer_entity_suburb");
        data.city =
          data?.city ||
          payerCompany?.get("city") ||
          certInfo?.get("payer_entity_city");
        data.state =
          data?.state ||
          payerCompany?.get("state") ||
          certInfo?.get("payer_entity_state");
        data.country =
          data?.country ||
          payerCompany?.get("country") ||
          certInfo?.get("payer_entity_country");
        data.gst_no =
          data?.gst_number ||
          payerCompany?.get("gst_no") ||
          certInfo?.get("payer_gst_number");
        API.upsert_account_company(data);
      }
    } else {
      history.push(
        `/contract/${proId}/${OverviewPageUrlContractType(
          contractType,
        )}/${contractId}?account_id=${accountId}`,
      );
    }

    this.setState({ open: false });
  };

  updateComment = (action, content, comment_id, lineId) => {
    const { contractType, contractId, claimId, proId } =
      this.props.match.params;
    const { updateComment, certId } = this.props;

    updateComment(
      contractType,
      contractId,
      claimId,
      certId,
      action,
      content,
      comment_id,
      lineId,
      proId,
    );
  };

  validateForm = () => {
    const {
      hasRetention,
      retentionOption,
      lessRetention,
      claimRetentionValue,
    } = this.props;
    const { errors } = this.state;
    const errorKey = `currentRetentionPopupRef`;

    const roundedLessRetention =
      lessRetention || lessRetention === 0 ? roundNumber(lessRetention) : 0;
    const claimPcd = claimRetentionValue?.get("pcd") || 0,
      claimDlp = claimRetentionValue?.get("dlp") || 0;

    if (
      hasRetention &&
      roundedLessRetention < roundNumber(claimPcd + claimDlp)
    ) {
      if (retentionOption === null || retentionOption === "") {
        this.setState((prevState) => {
          const updatedErrors = { ...prevState.errors };

          updatedErrors[errorKey] =
            "Select an option for Retention, or enter a new value manually.";

          return { errors: updatedErrors };
        });
        return false;
      } else {
        delete errors[errorKey];
      }
    }

    // Check for specific retention and labour errors
    if (
      errors.currentRetentionPCRRRef ||
      errors.currentRetentionDLRRRef ||
      errors.labourElementRef ||
      errors.previousLabourElementRef
    ) {
      return false;
    }

    return true;
  };

  performErrorCheckCurrentRetention = (type, isError) => {
    const errorMessages = {
      pcrr: "Exceeded Practical Completion Retention Release Total Accrued Retention",
      dlrr: "Exceeded Defects Liability Retention Release Total Accrued Retention",
    };

    this.setState((prevState) => {
      const updatedErrors = { ...prevState.errors };
      const errorKey = `currentRetention${type.toUpperCase()}Ref`;

      if (isError) {
        updatedErrors[errorKey] = errorMessages[type];
      } else {
        delete updatedErrors[errorKey];
      }

      return { errors: updatedErrors };
    });
  };

  checkInputErrors = (field, isError, ref) => {
    const error = `This must be less than the ${field.includes("previous") ? "previous " : ""}gross amount`;

    this.setState((prevState) => {
      const updatedErrors = { ...prevState.errors };
      if (isError) {
        updatedErrors[ref] = error;
      } else {
        delete updatedErrors[ref];
      }
      return { errors: updatedErrors };
    });
  };
  handleDraftCert = (isNew) => {
    const { proId, contractId, contractType } = this.props.match.params;
    const { claimCertDetails } = this.state;

    this.props.handlePendingCert(
      proId,
      contractId,
      claimCertDetails?.claim_id,
      claimCertDetails?.cert_id,
      isNew,
      contractType,
    );
    this.setState({ openCertModal: false });
  };

  render() {
    const {
      accountConfig,
      loading,

      claimDetail,
      certificate_number,
      certInfo,
      paymentsOption,
      shared_cert_attachments,

      baseContractTable,
      baseCertList,
      baseCertTotal,
      baseTotalValues,
      base_cert_method,

      variationsTable,
      variationCertList,
      variationCertTotal,
      variationsTotalValues,
      variation_cert_method,

      materialsTable,
      materialsCertList,
      materialsTotal,
      handleInputMaterials,

      hasRetention,
      dlpReleaseValue,
      pcdReleaseValue,

      reasonOption,

      handleInputCert,
      inputReason,
      handlePaidValue,

      handleCertItem,

      downloadFile,

      projectInfo,
      certId,

      editFileList,
      uploadFiles,
      handleFilesChange,
      uploadAttachments,
      cancelAttachment,
      deleteAttachment,
      initialFileList,

      updateSummary,
      cis_status,
    } = this.props;
    const cisJson = accountConfig?.getIn(["cis", "json", "status"])?.toJS();
    const cisStatus = findKeyByValue(cisJson, cis_status);

    const { proId, contractId, claimId, contractType } =
      this.props.match.params;

    const { isChanged, openCertModal, open, bctiInfo, claimCertDetails } =
      this.state;

    let readOnly = !this.state.allowCert;
    const isAccountAdmin = profile.isAdminOrOwner();
    const payerCompany = certInfo?.get("payer_company");
    const payerEntityName =
      payerCompany?.get("entity_name") || certInfo?.get("payer_entity_name");
    const allowExcludeRetention = certInfo?.get("has_retention");

    return (
      <BgWrapper className="large">
        <Prompt when={isChanged} />
        {!loading && certId !== "" ? (
          <Fragment>
            <SemiWrapper>
              <Menu
                link={getMenuLink(
                  projectInfo,
                  contractId,
                  certInfo.get("contract_name"),
                  contractType,
                  claimId,
                )}
                content={`Add ${accountConfig?.getIn(["cert", "noun"])}`}
              />
              <HeaderTitle>
                {`Add ${accountConfig?.getIn(["cert", "noun"])}`}
                <div className="subtitle">
                  for {certInfo.get("payee_entity_name")}
                </div>
              </HeaderTitle>
            </SemiWrapper>
            <ErrorStripBanner
              errors={this.state.errors}
              currentRetentionPCRRRef={this.currentRetentionPCRRRef}
              currentRetentionDLRRRef={this.currentRetentionDLRRRef}
              currentRetentionPopupRef={this.currentRetentionPopupRef}
              labourElementRef={this.labourElementRef}
              previousLabourElementRef={this.previousLabourElementRef}
            />
            <ProjectWrapper>
              <Fragment>
                <CertDetail
                  claimInfo={claimDetail}
                  projectAllInfo={certInfo}
                  paymentsOption={paymentsOption}
                  handleChange={handlePaidValue}
                  certificate_number={certificate_number}
                  certId={certId}
                  performSaveReview={(status) => this.performSaveReview(status)}
                  accountConfig={accountConfig}
                  componentUpdated={() => {
                    this.setState({ isChanged: true });
                  }}
                />
                <Table
                  table_title="Base Contract"
                  projectData={certInfo}
                  totalValues={baseTotalValues}
                  tableContent={baseContractTable}
                  certList={baseCertList}
                  certTotal={baseCertTotal}
                  input_method={base_cert_method}
                  inputCert={(id, field, data, onLoad, fromPrctQty) => {
                    if (!onLoad) {
                      this.setState({ isChanged: true });
                    }
                    return handleInputCert(
                      id,
                      field,
                      data,
                      "base",
                      onLoad,
                      fromPrctQty,
                    );
                  }}
                  inputReason={(id, reason) => inputReason(id, reason, "base")}
                  isNewCert={!certId}
                  updateComment={this.updateComment}
                  reasonOption={reasonOption}
                />
                <Table
                  table_title="Variations"
                  projectData={certInfo}
                  totalValues={variationsTotalValues}
                  tableContent={variationsTable}
                  allowExcludeRetention={allowExcludeRetention}
                  contractLinked={this.props.contractLinked}
                  inputCert={(id, field, data, onLoad, fromPrctQty) => {
                    if (!onLoad) {
                      this.setState({ isChanged: true });
                    }
                    return handleInputCert(
                      id,
                      field,
                      data,
                      "variation",
                      onLoad,
                      fromPrctQty,
                    );
                  }}
                  certList={variationCertList}
                  certTotal={variationCertTotal}
                  input_method={variation_cert_method}
                  inputReason={(id, reason) =>
                    inputReason(id, reason, "variation")
                  }
                  reasonOption={reasonOption}
                  handleItem={(action, lineId, data) => {
                    if (action === CERT_ITEM_ACTION.ADD) {
                      return handleCertItem(
                        action,
                        proId,
                        contractId,
                        claimId,
                        certId,
                        "",
                        CERT_VARIATION_ITEM,

                        data,
                        contractType,
                        lineId,
                        variationsTable,
                      );
                    }
                    return handleCertItem(
                      action,
                      proId,
                      contractId,
                      claimId,
                      certId,
                      lineId,
                      CERT_VARIATION_ITEM,
                      data,
                    );
                  }}
                  isNewCert={!certId}
                  updateComment={this.updateComment}
                  // cert vari files
                  editFileList={editFileList}
                  uploadFiles={uploadFiles}
                  handleFilesChange={handleFilesChange}
                  uploadAttachments={(files, lineId) =>
                    uploadAttachments(
                      proId,
                      contractId,
                      lineId,
                      files,
                      claimId,
                      certId,
                    )
                  }
                  cancelAttachment={cancelAttachment}
                  initialFileList={initialFileList}
                  deleteAttachment={(fileId, lineId) =>
                    deleteAttachment(proId, contractId, lineId, fileId)
                  }
                />
                <MakeCertMaterials
                  data={materialsTable}
                  projectData={certInfo}
                  materialsCertList={materialsCertList}
                  totals={materialsTotal}
                  accountConfig={accountConfig}
                  updateComment={this.updateComment}
                  handleInput={handleInputMaterials}
                  contractId={contractId}
                  proId={proId}
                  claimId={claimId}
                  isNewCert={!certId}
                  allowExcludeRetention={allowExcludeRetention}
                  inputReason={(id, reason) =>
                    inputReason(id, reason, "materials")
                  }
                  reasonOption={reasonOption}
                  handleItem={(action, lineId, data) =>
                    handleCertItem(
                      action,
                      proId,
                      contractId,
                      claimId,
                      certId,
                      lineId,
                      CERT_MATERIAL_ITEM,
                      data,
                      contractType,
                    )
                  }
                  // attachments
                  editFileList={editFileList}
                  uploadFiles={uploadFiles}
                  handleFilesChange={(files) => {
                    handleFilesChange(files);
                  }}
                  uploadAttachments={(files, lineId) =>
                    uploadAttachments(
                      proId,
                      contractId,
                      lineId,
                      files,
                      claimId,
                      certId,
                    )
                  }
                  cancelAttachment={cancelAttachment}
                  deleteAttachment={(fileId, lineId) =>
                    deleteAttachment(proId, contractId, lineId, fileId)
                  }
                  initialFileList={initialFileList}
                  contractLinked={this.props.contractLinked}
                />
                <Summary
                  proId={proId}
                  contractId={contractId}
                  gst={certInfo.get("gst")}
                  toDate={claimDetail.get("claim_to")}
                  handleUpdate={(data) => updateSummary(data, cisStatus)}
                  currentRetentionPCRRRef={this.currentRetentionPCRRRef}
                  currentRetentionDLRRRef={this.currentRetentionDLRRRef}
                  currentRetentionPopupRef={this.currentRetentionPopupRef}
                  labourElementRef={this.labourElementRef}
                  previousLabourElementRef={this.previousLabourElementRef}
                  performErrorCheckCurrentRetention={
                    this.performErrorCheckCurrentRetention
                  }
                  checkInputErrors={this.checkInputErrors}
                  calculatedCisStatus={cisStatus}
                  errors={this.state.errors}
                  {...this.props}
                />
                <Notes claimInfo={claimDetail} />
                <Attachment
                  hasSharedFiles
                  sharedFiles={shared_cert_attachments}
                  downloadFile={downloadFile}
                  displayOnly
                  otherPartyName={claimDetail.get("claimer_name") || ""}
                />
                <SemiWrapper>
                  <NavBarBottom
                    readOnly={readOnly}
                    previousPath={`/contract/${proId}/${
                      CertContract.includes(contractType) ? "cert" : "claim"
                    }/${contractId}`}
                    handleSaveReview={() => {
                      if (!this.validateForm()) {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                        return false;
                      }
                      this.setState({ isChanged: false });
                      return this.performSaveReview("cert");
                    }}
                    handleSave={() => {
                      if (!this.validateForm()) {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                        return false;
                      }
                      this.setState({ isChanged: false });
                      return this.performSaveReview("cert_draft");
                    }}
                    certData={[
                      ...baseCertList,
                      ...variationCertList,
                      ...materialsCertList,
                    ]}
                    requireRetentionValue={
                      hasRetention &&
                      ((!dlpReleaseValue && dlpReleaseValue !== 0) ||
                        (!pcdReleaseValue && pcdReleaseValue !== 0))
                    }
                  />
                </SemiWrapper>
              </Fragment>
            </ProjectWrapper>
          </Fragment>
        ) : (
          <Loading />
        )}
        <BctiModal
          isOpen={open}
          submit={(data) => {
            this.performBctiSubmit(data);
          }}
          close={() => {
            this.setState({ open: false });
          }}
          bctiInfo={bctiInfo}
          isAccountAdmin={isAccountAdmin}
          page="cert"
          contactCompany={
            contractType === CONTRACT_TYPE.selfCert
              ? payerEntityName
              : undefined
          }
        />
        <CertOptionModal
          open={openCertModal}
          closeDialog={() => this.setState({ openCertModal: false })}
          chooseOption={this.handleDraftCert}
          certName={claimCertDetails?.cert_number}
        />
      </BgWrapper>
    );
  }
  componentDidMount() {
    const { proId, contractId, claimId, contractType } =
      this.props.match.params;
    profile
      .checkPermission(CONTRACT, CLAIM_CERT, proId, contractId)
      .then((res) => {
        this.props.readProjectInfo(proId);
        // contract info
        this.props.readClaim(contractId, claimId, contractType, proId);
        this.setState({ allowCert: res });
        this.props.readContract(proId, contractId);
      });
  }

  componentDidUpdate(prevProps) {
    const { proId, contractId, claimId } = this.props.match.params;
    const { certInfo } = this.props;

    if (
      this.props.certInfo &&
      this.props.certInfo?.get("is_bcti") !== prevProps.certInfo?.get("is_bcti")
    ) {
      if (certInfo?.get("is_bcti")) {
        const view =
          certInfo?.get("contract_type") === CONTRACT_TYPE.selfCert
            ? CONTRACT_TYPE.selfCert
            : CONTRACT_TYPE.cert;

        API.check_bcti(proId, contractId, view)
          .then((res) => res.data.data)
          .then((res) => {
            if (
              !res.bcti["has_gst"] ||
              !res.bcti["has_name"] ||
              !res.bcti["has_address"]
            ) {
              this.setState({ open: true, bctiInfo: res.bcti });
            }
          });
      }
    }

    if (
      this.props.claimDetail?.get("claim_ref") &&
      this.props.certId === "" &&
      !this.props.loading &&
      this.props.loading !== prevProps.loading
    ) {
      this.props.handlePaidValue(
        "certificate_number",
        `Certificate - ${this.props.claimDetail.get("claim_ref")}`,
      );

      const urlParams = new URLSearchParams(window.location.search);
      const emailParam = urlParams.get("email");

      if (emailParam) {
        // Link clicked from the email
        API.read_payment_history(contractId)
          .then((res) => res.data.data.data)
          .then((history) => {
            const resultClaimCert = history.find(
              (item) => item.claim_id === claimId,
            );
            this.setState({ claimCertDetails: resultClaimCert });
            if (resultClaimCert?.cert_status === STATUS_PENDING) {
              this.setState({ openCertModal: true });
            } else {
              this.performSaveReview("cert_draft");
              this.setState({ isChanged: false });
            }
          });
      } else {
        this.performSaveReview("cert_draft");
        this.setState({ isChanged: false });
      }
    }
  }

  componentWillUnmount() {
    this.props.resetInfo();
  }
}
const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
  // project info
  projectInfo: state.getIn(["makeacert", "projectInfo"]),
  certId: state.getIn(["makeacert", "certId"]),

  loading: state.getIn(["makeacert", "loading"]),
  certInfo: state.getIn(["makeacert", "projectlist"]),
  baseContractTable: state.getIn(["makeacert", "baseContractTable"]),
  baseTotalValue: state.getIn(["makeacert", "baseContractTotal"]),
  baseCertList: state.getIn(["makeacert", "baseCertList"]),
  baseCertTotal: state.getIn(["makeacert", "baseCertTotal"]),
  baseCertTotalExcludeRetention: state.getIn([
    "makeacert",
    "baseCertTotalExcludeRetention",
  ]),
  base_cert_method: state.getIn(["makeacert", "base_cert_method"]),

  baseTotalValues: state.getIn(["makeacert", "baseTotalValues"]),
  variationsTotalValues: state.getIn(["makeacert", "variationsTotalValues"]),
  variationsTable: state.getIn(["makeacert", "variationsTable"]),
  variationCertList: state.getIn(["makeacert", "variationCertList"]),
  variationCertTotal: state.getIn(["makeacert", "variationCertTotal"]),
  variationCertTotalExcludeRetention: state.getIn([
    "makeacert",
    "variationCertTotalExcludeRetention",
  ]),
  variation_cert_method: state.getIn(["makeacert", "variation_cert_method"]),

  paidValue: state.getIn(["makeacert", "paidValue"]),
  previousType: state.getIn(["makeacert", "previousType"]),
  lessRetention: state.getIn(["makeacert", "lessRetention"]),
  dlpReleaseValue: state.getIn(["makeacert", "dlpReleaseValue"]),
  pcdReleaseValue: state.getIn(["makeacert", "pcdReleaseValue"]),
  claimRetentionValue: state.getIn(["makeacert", "claimRetentionValue"]),
  certRetentionValue: state.getIn(["makeacert", "certRetentionValue"]),
  hasRetention: state.getIn(["makeacert", "projectlist", "has_retention"]),

  claimDetail: state.getIn(["makeacert", "claimDetail"]),
  certificate_number: state.getIn(["makeacert", "certificate_number"]),
  paymentsOption: state.getIn(["headers", "config", "payclaim"]),
  accountId: state.getIn(["headers", "profile", "payclaim_account_id"]),
  shared_cert_attachments: state.getIn([
    "makeacert",
    "shared_cert_attachments",
  ]),
  reasonOption: state.getIn(["makeacert", "reasonOption"]),
  retentionOption: state.getIn(["makeacert", "retentionOption"]),

  // material info
  materialsTable: state.getIn(["makeacert", "materialsTable"]),
  materialsCertList: state.getIn(["makeacert", "materialsCertList"]),
  materialsTotal: state.getIn(["makeacert", "materialsTotal"]),
  materialsTotalExcludeRetention: state.getIn([
    "makeacert",
    "materialsTotalExcludeRetention",
  ]),

  // cert variation attachments
  uploadFiles: state.getIn(["makeacert", "uploadFiles"]),
  editFileList: state.getIn(["makeacert", "editFileList"]),
  contractLinked: state.getIn(["makeacert", "contractLinked"]),

  // new cert summary component
  summaryComponent: state.getIn(["makeacert", "summaryComponent"]),
  previousCertfied: state.getIn(["makeacert", "previousCertfied"]),
  cis_status: state.getIn(["makeacert", "cis_status"]),
  domestic_reverse_charge: state.getIn([
    "makeacert",
    "domestic_reverse_charge",
  ]),
  certDlrr: state.getIn(["makeacert", "cert_retention_dlrr"]),
  certPcrr: state.getIn(["makeacert", "cert_retention_pcrr"]),
});
const mapDispatchToProps = (dispatch) => {
  return {
    readProjectInfo(proId) {
      dispatch(actionCreators.readProject(proId));
    },
    readContract(proId, contractId) {
      dispatch(actionCreators.readContract(proId, contractId));
    },
    resetInfo() {
      dispatch(actionCreators.resetStage());
    },
    readClaim(contractId, claimId, contractType, proId) {
      dispatch(
        actionCreators.readClaim(
          contractId,
          claimId,
          contractType,
          "new",
          proId,
        ),
      );
    },
    handleInputCert(id, field, e, tableName, onLoad, fromPrctQty) {
      dispatch(
        actionCreators.setInputCertValue(
          id,
          field,
          e,
          tableName,
          onLoad,
          fromPrctQty,
        ),
      );
    },
    handleInputMaterials(lineId, value, onSite) {
      dispatch(actionCreators.setMaterialInput(lineId, value, onSite));
    },
    inputReason(id, reason, field) {
      dispatch(actionCreators.set_reason(id, reason, field));
    },
    handlePaidValue(name, value) {
      dispatch(actionCreators.setValue(name, value));
    },
    saveReview(proId, claimId, certId, field, contractId, contractType) {
      if (certId) {
        dispatch(
          actionCreators.addACert(
            proId,
            contractId,
            claimId,
            certId,
            field,
            "update",
            contractType,
          ),
        );
      } else {
        dispatch(
          actionCreators.addACert(
            proId,
            contractId,
            claimId,
            "",
            field,
            "create",
            contractType,
          ),
        );
      }
    },
    handleCertItem(
      action,
      proId,
      contractId,
      claimId,
      certId,
      lineId,
      lineItemType,

      data,
      contractType,
      parentId,
      table,
    ) {
      if (action === CERT_ITEM_ACTION.DELETE) {
        dispatch(
          actionCreators.deleteCertItem(
            proId,
            contractId,
            claimId,
            certId,
            lineId,
            lineItemType,
          ),
        );
      }
      if (action === CERT_ITEM_ACTION.EDIT && lineId) {
        dispatch(
          actionCreators.updateCertItem(
            proId,
            contractId,
            claimId,
            certId,
            lineId,
            lineItemType,
            data,
          ),
        );
      }
      if (action === CERT_ITEM_ACTION.ADD) {
        if (lineItemType === CERT_VARIATION_ITEM) {
          dispatch(
            actionCreators.addVari(
              proId,
              contractId,
              claimId,
              certId,
              data,
              table,
              contractType,
              lineItemType,
              parentId,
            ),
          );
        } else {
          dispatch(
            actionCreators.addMaterial(
              proId,
              contractId,
              claimId,
              certId,
              data,
              contractType,
              lineItemType,
            ),
          );
        }
      }
    },
    updateComment(
      contractType,
      contractId,
      claimId,
      certId,
      action,
      content,
      comment_id,
      lineId,
      proId,
    ) {
      dispatch(
        actionCreators.updateComment(
          contractType,
          contractId,
          claimId,
          certId,
          action,
          content,
          comment_id,
          lineId,
          proId,
        ),
      );
    },
    downloadFile(fileId, fileName, token, accId, isDownload) {
      dispatch(
        actionCreators.downloadFile(fileId, fileName, token, accId, isDownload),
      );
    },
    updateBctiInfo(proId, contractId, value) {
      dispatch(actionCreatorsContract.updateBctiInfo(proId, contractId, value));
    },
    initialFileList(files) {
      dispatch(actionCreators.initialFileList(files));
    },
    handleFilesChange(files) {
      dispatch(actionCreators.changeFilesList(files));
    },
    uploadAttachments(proId, cotnractId, lineId, files) {
      dispatch(
        actionCreators.uploadAttachments(proId, cotnractId, lineId, files),
      );
    },
    cancelAttachment(name) {
      dispatch(actionCreators.cancelAttachment(name));
    },
    deleteAttachment(proId, contractId, lineId, fileId) {
      dispatch(
        actionCreators.deleteAttachment(proId, contractId, lineId, fileId),
      );
    },
    updateSummary(data, cisStatus) {
      dispatch(actionCreators.updateCertSummary(data, cisStatus));
    },
    calculateCertSummary(data) {
      dispatch(actionCreators.calculateCertSummary(data));
    },
    handlePendingCert(proId, contractId, claimId, certId, data, contractType) {
      dispatch(
        actionCreatorsContract.handlePendingCert(
          proId,
          contractId,
          claimId,
          certId,
          data,
          contractType,
        ),
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(index);
