import styled from "styled-components";
import * as theme from "../../../theme";
import helpIcon from "../../../statics/img/icon-help-light-blue.svg";
import errorIcon from "../../../statics/img/icon-information-red.svg";

export const HelpIconButton = styled.button.attrs({
  title: "You can check the NZBN here",
})`
  width: 16px;
  height: 16px;
  border: none;
  background: url(${helpIcon}) no-repeat;
  background-size: contain;
  margin-left: 4px;
  cursor: pointer;
`;
export const BottomWrapper = styled.div`
  width: 100%;
  margin-top: 56px;
  display: flex;
  justify-content: flex-end;
`;
export const Input = styled.input`
  ::placeholder {
    color: ${theme.palette.wolfGrey};
  }
  :read-only {
    outline: none;
    background-color: ${theme.palette.greyWhite};
  }
  width: ${(props) => props.width || "446px"};
  color: ${theme.palette.gunmetal};
  height: 44px;
  font-size: 16px;
  font-weight: ${theme.font.normalFontWeight};
  padding: 0 16px;
  box-sizing: border-box;
  background-color: ${theme.palette.white};
  border: 1px solid ${theme.palette.wolfGrey};
  border-radius: 4px;
  font-family: ${theme.font.mainFont};
`;
export const Label = styled.label`
  font-size: 16px;
  font-weight: ${theme.font.mainFontWeight};
  margin-bottom: 8px;
  float: left;
  width: 100%;
  display: flex;
`;

export const ErrorStyle = styled.div`
  font-size: 12px;
  color: ${theme.palette.watermelonRed};
  display: flex;
  align-items: center;
  margin: 4px 0;
  .error_img {
    background: url(${errorIcon});
    background-size: contain;
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`;
