import React, { Fragment, useEffect, useState } from "react";

import { ModalContent } from "../styled";

import {
  GreenWhiteBtn as Button,
  GreyOutlinedBtn as CancelButton,
  AddBtn,
} from "../../../common/button";
import Input from "../../../common/form";
import Modal from "../../../common/modal/component/modalBase";
import { validateEmailAddress } from "../../../utils/helper";

export default ({ emailList, open, title, close, submit, disabled }) => {
  const [emails, setEmails] = useState([]);
  const [errors, setErrors] = useState([]);
  const emailType = title?.includes("Claim") ? "claims" : "certificates";

  useEffect(() => {
    if (emailList) {
      const emails = emailList.map((email, index) => ({
        id: index + 1,
        value: email,
      }));

      setEmails(emails);
    }
    return () => {
      setEmails([]);
      setErrors([]);
    };
  }, [emailList]);

  const updateEmails = (action, emailId, value) => {
    let newEmails = [];
    switch (action) {
      case "add":
        newEmails = [
          ...emails,
          {
            id: Date.now(),
            value: "",
          },
        ];
        break;

      case "delete":
        newEmails = emails.filter((email) => email.id !== emailId);
        break;

      case "edit":
        const editedEmail = emails.find((email) => email.id === emailId);
        newEmails = editedEmail
          ? emails.map((email) =>
              email.id === emailId ? { ...email, value: value } : email,
            )
          : emails;
        break;

      default:
        newEmails = emails;
    }
    setEmails(newEmails);
  };

  const validateEmail = (emailId) => {
    const email = emails.find((item) => item.id === emailId);
    if (email && email.value.trim() !== "") {
      const result = validateEmailAddress(email.value);

      if (result && errors.includes(emailId)) {
        setErrors(errors.filter((error) => error !== emailId));
      } else if (!result && !errors.includes(emailId)) {
        setErrors([...errors, emailId]);
      }
    }
  };

  return (
    <Modal
      open={open}
      title={title}
      divider={false}
      width={"960px"}
      handleClose={close}
    >
      <ModalContent>
        <h3>
          List of default recipients who will receive emails when {emailType}{" "}
          are issued.
        </h3>
        {emails.map((email) => (
          <Fragment key={email.id}>
            <li>
              <div
                className={`email-list ${
                  errors.includes(email.id) ? "has-error" : ""
                }`}
              >
                <div className="email-icon" />
                <Input
                  value={email.value}
                  onChange={(event) =>
                    updateEmails("edit", email.id, event.target.value)
                  }
                  onBlur={() => validateEmail(email.id)}
                />
              </div>
              <div
                className="delete-icon"
                onClick={() => updateEmails("delete", email.id)}
              />
            </li>
            {errors.includes(email.id) && (
              <div className="error-msg">Not a valid email address</div>
            )}
          </Fragment>
        ))}
        <AddBtn
          margin={"24px 0 0 0"}
          title="Add Email Address"
          onClick={() => updateEmails("add")}
        />
      </ModalContent>
      <div>
        <Button
          title="save"
          onClick={() => {
            submit(emails.filter((email) => email.value.trim() !== ""));
            close();
          }}
          disabled={errors.length !== 0}
        />
        <CancelButton title="cancel" margin={"0 16px 0 0"} onClick={close} />
      </div>
    </Modal>
  );
};
