import React, { PureComponent, Fragment } from "react";
import InfoContainer from "./infoComponent";
import { convertToCurrencyUnit } from "../../../utils/currencyHelper";
import ClaimsReceivedSummary from "./claimsReceivedSummary";
export default class TableContainer extends PureComponent {
  render() {
    const {
      claimSummary,
      certSummary,
      retentionSummary,
      claimsReceivedSummary,
      accountConfig,
    } = this.props;
    const claimTerm = accountConfig.get("claim");

    const listHasNonZero = (listData) => {
      let hasNonZero = false;
      for (let item of listData) {
        if (typeof item.value === "string") {
          if (parseFloat(item.value.replace(/[^a-zA-Z0-9]/g, "")) > 0) {
            hasNonZero = true;
            break;
          }
        } else {
          if (item.value > 0) {
            hasNonZero = true;
            break;
          }
        }
      }
      return hasNonZero;
    };
    const isShowComponent = (index) => {
      let hasNonZero = listHasNonZero(data[index]);

      if (hasNonZero === false) {
        hasNonZero = listHasNonZero(dataClaim[index]);
      }
      return hasNonZero;
    };
    const isShowclaimsReceivedSummary = () => {
      return (
        claimsReceivedSummary &&
        (claimsReceivedSummary.get("field_one") > 0 ||
          claimsReceivedSummary.get("field_two") > 0)
      );
    };
    const totalNoneZeroComponents = () => {
      let total = 0;
      if (isShowclaimsReceivedSummary()) {
        total++;
      }
      for (var i = 0; i < 3; i++) {
        if (isShowComponent(i)) {
          total++;
        }
      }
      return total;
    };
    let data = [
      [
        {
          label: "Current Projects",
          value: claimSummary.get("current_contracts"),
        },
        {
          label: "Contract Value",
          value: convertToCurrencyUnit(
            claimSummary.get("contract_value"),
            true,
            false,
            true,
          ),
        },
        {
          label: `${claimTerm?.get("value")} to Date`,
          value: convertToCurrencyUnit(
            claimSummary.get("claimed_to_date"),
            true,
            false,
            true,
          ),
        },
        {
          label: "Remaining Procured Work",
          value: convertToCurrencyUnit(
            claimSummary.get("remaining_procured_work"),
            true,
            false,
            true,
          ),
        },
      ],
      [
        {
          label: "Total Retentions",
          value: convertToCurrencyUnit(
            retentionSummary.get("total_retentions"),
            true,
            false,
            true,
          ),
        },
        {
          label: "Retentions Paid",
          value: convertToCurrencyUnit(
            retentionSummary.get("retentions_paid"),
            true,
            false,
            true,
          ),
        },
        {
          label: "Variance",
          value: convertToCurrencyUnit(
            retentionSummary.get("variance"),
            true,
            false,
            true,
          ),
        },
      ],
      [
        {
          label: `Current ${claimTerm?.get("noun")}s`,
          value: certSummary.get("current_claims"),
        },
        {
          label: `Total ${claimTerm?.get("noun")}s Certified`,
          value: certSummary.get("total_claims_certified"),
        },
        {
          label: "Total Certified Amount",
          value: convertToCurrencyUnit(
            certSummary.get("total_certified_amount"),
            true,
            false,
            true,
          ),
        },
      ],
    ];
    let dataClaim = [
      [
        {
          label: "This Month",
          value: claimSummary.get("this_month"),
          style: "info-bar",
        },
        {
          label: `Upcoming ${claimTerm?.get("noun")}s`,
          value: convertToCurrencyUnit(
            claimSummary.get("up_coming_claims"),
            true,
            false,
            true,
          ),
          style: "info-bar un-due",
        },
      ],
      [
        {
          label: "Overdue",
          value: convertToCurrencyUnit(
            retentionSummary.get("overdue") || 0,
            true,
            false,
            true,
          ),
          style: "info-bar",
        },
        {
          label: "Due in 5 Days",
          value: convertToCurrencyUnit(
            retentionSummary.get("due_in_5_days") || 0,
            true,
            false,
            true,
          ),
          style: "info-bar due5",
        },
        {
          label: "Due in 30 Days",
          value: convertToCurrencyUnit(
            retentionSummary.get("due_in_30_days") || 0,
            true,
            false,
            true,
          ),
          style: "info-bar un-due",
        },
      ],
      [
        {
          label: "Uncertified for 20 Days",
          value: certSummary.get("uncertifiedfor20days"),
          style: "info-bar",
        },
        {
          label: "Certifications Due",
          value: convertToCurrencyUnit(
            certSummary.get("certifications_due"),
            true,
            false,
            true,
          ),
          style: "info-bar un-due",
        },
      ],
    ];
    let section = [
      {
        title: "Active Contracts",
        link: "/projects",
        due_title: `${claimTerm?.get("noun")}s Due`,
        due_icon: claimSummary.get("this_month") !== 0 ? "label-icon" : "",
      },
      {
        title: "Retentions Summary",
        link: "",
        due_title: "Retentions Due",
        due_icon: retentionSummary.get("overdue") !== 0 ? "label-icon" : "",
      },
      {
        title: "Upcoming Certificate Summary",
        link: "",
        due_title: "Certificates Due",
        due_icon:
          certSummary.get("uncertifiedfor20days") !== 0 ? "label-icon" : "",
      },
    ];
    let bar = [];
    for (var i = 0; i < 3; i++) {
      let num = i + 4;
      bar.push(
        <Fragment key={num}>
          {isShowComponent(i) && (
            <InfoContainer
              key={num}
              data={data[i]}
              dataDue={dataClaim[i]}
              section={section[i]}
              totalNoneZero={totalNoneZeroComponents()}
              componentIndex={i + 1}
              stepName={"dashboard_step" + num}
            />
          )}
        </Fragment>,
      );
    }
    return (
      <Fragment>
        {isShowclaimsReceivedSummary() && (
          <ClaimsReceivedSummary
            {...this.props}
            totalNoneZero={totalNoneZeroComponents()}
          />
        )}
        <Fragment>{bar}</Fragment>
      </Fragment>
    );
  }
}
