import React, { Fragment } from "react";
import { RedBtn as Confirm, BlueOutlinedBtn as Cancel } from "../../button";
import Modal from "./modalBase";

const megStyle = {
  padding: "33px 30px",
  lineHeight: "30px",
};

const LeaveContractDialog = (props) => {
  const { closeModal, open, deleteItem, isDelete } = props;

  return (
    <Modal
      width="590px"
      title={isDelete ? `Delete Contract?` : `Leave Contract?`}
      handleClose={() => closeModal("cancel")}
      open={open}
    >
      <div style={megStyle}>
        {isDelete ? (
          <Fragment>
            Are you sure you want to delete this contract? This action cannot be
            undone.
          </Fragment>
        ) : (
          <Fragment>
            You will no longer have access to the contract. To access it again
            you will need a new invite from the other party.
            <br />
            Are you sure you want to leave?
          </Fragment>
        )}
      </div>
      <div>
        <Confirm
          onClick={() => deleteItem()}
          title={isDelete ? "Delete" : "Leave"}
          padding="10px 34px"
        />
        <Cancel
          onClick={() => closeModal("cancel")}
          title="Cancel"
          margin="0 20px 0 0"
          padding="10px 34px"
        />
      </div>
    </Modal>
  );
};
export default LeaveContractDialog;
