export const REQUEST_CLAIM_INFO = "/makeacert/REQUEST_CLAIM_INFO";
export const REQUEST_CERT_INFO = "/makeacert/REQUEST_CERT_INFO";
export const SET_INPUT_VALUE = "/makeacert/SET_INPUT_VALUE";
export const SET_CERT_INPUT_METHOD = "/makeacert/SET_CERT_INPUT_METHOD";
export const SET_REASON_VALUE = "/makeacert/SET_REASON_VALUE";
export const SET_NAME_VALUE = "/makeacert/SET_NAME_VALUE";
export const RESET_STAGE = "/makeacert/RESET_STAGE";
export const SET_EMAIL_SUBJECT = "/makeacert/SET_EMAIL_SUBJECT";
export const REMOVE_EMAIL = "/makeacert/REMOVE_EMAIL";
export const ADD_EMAIL = "/makeacert/ADD_EMAIL";
export const SET_ARRAY_DATA = "/makeacert/SET_ARRAY_DATA";
export const SET_DATA = "/makeacert/SET_DATA";
export const SET_LINE_COMMENTS = "/makeacert/SET_LINE_COMMENTS";
export const SET_MATERIAL_INPUT = "/makeacert/SET_MATERIAL_INPUT";
export const DELETE_FILE = "/MAKE_CLAIM/DELETE_FILE";
export const UPDATE_SUMMARY = "/makeacert/UPDATE_SUMMARY";
export const SET_PREVIOUS_CERTIFIED = "/makeacert/SET_PREVIOUS_CERTIFIED";
// cert vari attachments
export const CHANGE_FILE_LIST = "/makeacert/CHANGE_FILE_LIST";
export const INITIAL_FILE_LIST = "makeacert/INITIAL_FILE_LIST";
export const CANCEL_ONE_FILE = "/makeacert/CANCEL_ONE_FILE";
