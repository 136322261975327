import styled, { css, keyframes } from "styled-components";
import { palette } from "../../../../theme";
import * as theme from "../../../../theme";
import avatar from "../../../../statics/img/avatar.svg";
import removeIcon from "../../../../statics/img/icon-close-grey.svg";
import sendEmailIcon from "../../../../statics/img/icon-resend-email.svg";
import personIcon from "../../../../statics/img/icon-avatar-grey.svg";
import activeIcon from "../../../../statics/img/accept-invite.svg";
import actionIcon from "../../../../statics/img/icon-more-horizontal.svg";
import radioOnIcon from "../../../../statics/img/icon-radio-on.svg";
import radioOffIcon from "../../../../statics/img/icon-radio-off.svg";
import downDirIcon from "../../../../statics/img/icon-dropdown-down-dark-grey.svg";
import searchIcon from "../../../../statics/img/icon-search-dark-grey.svg";

export const InactiveSwitch = styled.div`
  width: 320px;
  height: 100%;
  background-color: ${palette.white}
  border: solid 1px ${palette.wolfGrey};
  border-radius: 4px;
  padding: 0px 16px;
  display:flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
`;

export const SortInput = styled.div`
  height: 44px;
  width: 222px;
  border: 1px solid ${theme.palette.wolfGrey};
  border-radius: 4px;
  float: left;
  box-sizing: border-box;
  margin-left: 24px;
  background-color: ${theme.palette.white};
  display: grid;
  grid-template-columns: 70px auto;
  .sortTxt {
    grid-column: 1/2;
    text-align: left;
    line-height: 42px;
    width: 50px;
    height: 42px;
    margin-left: 16px;
    font-size: 14px;
    font-weight: ${theme.font.mainFontWeight};
    vertilical-align: center;
    position: absolute;
  }
  .inputSelect {
    grid-column: 2/3;
    float: right;
  }
`;

export const SearchContainer = styled.div`
  height: 44px;
  width: 480px;
  background-color: ${theme.palette.white};
  box-sizing: border-box;
  float: left;
  border-radius: 4px;
  padding: 10px 10px 10px 16px;
  border: 1px solid ${theme.palette.lightWolfGrey};

  input {
    ::placeholder {
      color: ${theme.palette.wolfGrey};
    }
    outline: none;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-family: ${theme.font.mainFont};
    font-weight: ${theme.font.normalFontWeight};
    float: right;
    height: 100%;
    width: 415px;
    color: ${theme.palette.gunmetal};
  }
  .img-search {
    height: 24px;
    width: 24px;
    background: url(${searchIcon}) no-repeat scroll;
    background-size: 24px;
    float: left;
    cursor: pointer;
  }
`;

export const TopicItem = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: space-between;

  ${SortInput} {
    width: 320px;
    margin-left: 0;
  }
`;

// list
export const TableHeader = styled.div`
  font-weight: ${theme.font.mainFontWeight};
  letter-spacing: 1px;
  margin-bottom: 12px;
  margin-top: 36px;
  display: grid;
  grid-template-columns: 368px 120px 120px 250px 160px;
  grid-column-gap: 24px;
  .firstItem {
    padding-left: 104px;
  }
`;

export const ListItemWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const ListItem = styled.div`
  &:hover {
    background-color: ${theme.palette.greyWhite};
  }
  min-height: 84px;
  width: 100%;
  height: auto;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  padding: 24px 40px;
  box-sizing: border-box;
  margin: 8px 0;
  font-size: 16px;
  line-height: 20px;
  display: grid;
  grid-column-gap: 24px;
  &.user {
    grid-template-columns: 41px 262px 120px 120px 250px 80px 24px;
  }

  .icon {
    height: 40px;
    width: 40px;
    background: url(${avatar}) no-repeat;
    object-fit: contain;
  }
  .align_middle {
    align-self: center;
  }

  .person_name {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 20px;
  }
  .align_center {
    text-align: center;
  }
  .actionWrap {
    position: relative;
  }
  .actionIcon {
    width: 24px;
    height: 24px;
    background: url(${actionIcon}) no-repeat;
    cursor: pointer;
  }
  .light_grey_text {
    color: ${theme.palette.rareGrey};
    width: 100%;
    font-size: 14px;
    line-height: 17px;
  }
  .light_border {
    box-sizing: border-box;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 8px;

    width: 120px;
    height: fit-content;
    font-size: 14px;
    border: 1px solid ${theme.palette.rareGrey};
    border-radius: 4px;
    cursor: pointer;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`;
export const BottomPagination = styled.div`
  margin-top: 24px;
  height: 44px;
  min-width: 438px;
  height: fit-content;
  margin-right: 16px;
  .account {
    background-color: ${theme.palette.veryColdGrey};
  }
  .buttonWrapper {
    padding: 0px;
    .select_div {
      background-color: ${theme.palette.white};
    }
    .number_count {
      margin-left: 40px;
      margin-right: 56px;
    }
  }
`;
export const EmptyState = styled.div`
  margin: 80px auto;
  text-align: center;
  font-size: 26px;
  letter-spacing: 0.14px;
`;

export const ActionsWrap = styled.div`
  position: absolute;
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 8px;

  width: 366px;
  height: auto;
  left: -344px;
  top: 20px;

  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);

  flex: none;
  z-index: 9;
  background-color: white;

  .actionItemWrap {
    display: flex;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
      background-color: ${theme.palette.white};
    }
  }
  .comment {
    font-size: 12px;
  }
  .disabled-font {
    color: ${theme.palette.rareGrey};
  }
`;

export const ActionIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 24px;
  &.mailIcon {
    mask: url(${sendEmailIcon}) no-repeat;
    background-color: ${theme.palette.gunmetal};
    background-size: contain;
    animation: ${(props) =>
      props.animating
        ? css`
            ${rotate} 1s linear infinite
          `
        : ""};
  }
  &.avatarIcon {
    mask: url(${personIcon}) no-repeat;
    background-color: ${theme.palette.gunmetal};
    background-size: contain;
    &.disabled {
      background-color: ${theme.palette.rareGrey};
    }
  }
  &.deActiveIcon {
    mask: url(${removeIcon}) no-repeat;
    background-color: ${theme.palette.gunmetal};
    background-size: contain;
    &.disabled {
      background-color: ${theme.palette.rareGrey};
    }
  }
  &.activeIcon {
    mask: url(${activeIcon}) no-repeat;
    background-color: ${theme.palette.gunmetal};
    background-size: contain;
    animation: ${(props) =>
      props.animating
        ? css`
            ${rotate} 1s linear infinite
          `
        : ""};
  }
`;
export const ModalContent = styled.div`
  padding: 20px 40px;
  &.warning {
    width: 640px;
    line-height: 20px;
  }
`;

export const ModalTitle = styled.div`
  display: flex;
  .tabWrap {
    display: flex;
    margin-left: 103px;
    border-radius: 4px;
    border: 1px solid ${theme.palette.gunmetal};
  }
  .tab {
    padding: 8px 24px;
    width: 180px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    &.selected {
      color: ${theme.palette.white};
      background-color: ${theme.palette.gunmetal};
    }
    &.unselected {
      color: ${theme.palette.gunmetal};
    }
  }
`;

export const TableWrap = styled.div`
  margin-top: 32px;
  table {
    width: 100%;
  }

  th {
    padding: 8px 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${theme.palette.white};
    height: 44px;
    background: ${theme.palette.gunmetal};
    text-align: left;
    &.project {
      width: 639px;
      text-transform: capitalize;
    }
    &.access {
      width: 240px;
    }
  }
  tr {
    cursor: pointer;
    &:hover {
      background-color: ${theme.palette.greyWhite};
    }
  }
  tr:nth-child(even) {
    background-color: ${theme.palette.warmLightGrey};
  }
  td {
    padding: 8px 16px;
    font-size: 16px;
    line-height: 20px;
    height: 44px;
    text-align: left;
    &.roleDes {
      text-transform: capitalize;
    }
    &.alignCenter {
      text-align: center;
    }
  }
`;

export const DropdownWrapper = styled.div`
  width: 320px;
  height: 40px;
  padding: 8px 12px;
  border-radius: 4px;
  border: solid 1px ${theme.palette.wolfGrey};
  float: left;

  position: relative;
  background-color: white;
  cursor: pointer;
  hr {
    margin: 0 !important;
  }
  .dropdown-title {
    display: flex;
    align-items: center;
    width: 100%;
    label {
      font-size: 16px;
      font-weight: bold;
      margin-right: 4px;
    }
    .icon {
      width: 24px;
      height: 24px;
      background: url(${downDirIcon});
      object-fit: contain;
      margin: 0 0 0 auto;
      opacity: ${(props) => (props.active ? 1 : 0.2)};
      :hover {
        opacity: ${(props) => (props.active ? 1 : 0.5)};
      }
    }
  }

  .dropdown-list {
    z-index: 100;
    position: relative;
    top: 8px;
    background: white;
    width: 320px;
    left: -12px;
    padding: 8px 0;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 30%);
  }
  ul {
    list-style: none;
    li {
      height: 40px;
      display: flex;
      align-items: center;
      input[type="radio"] {
        -webkit-appearance: none;
        height: 24px;
        width: 24px;
        background: url(${radioOffIcon}) no-repeat;
        background-size: contain;
        margin: 0 8px 0 12px;
        cursor: pointer;
        :checked {
          background: url(${radioOnIcon}) no-repeat;
          background-size: contain;
        }
      }
    }
  }
`;
