import * as constants from "./constants";

export const setUploadFile = (data, uploadingFileField) => ({
  type: constants.SET_UPLOAD_FILE,
  payload: data,
  uploadingFileField: uploadingFileField,
});
export const clearFiles = () => ({
  type: constants.CLEAR_FILE_LIST,
});
export const setUploading = (name, payload) => ({
  type: constants.SET_UPLOADING,
  name,
  payload,
});
export const setUploadProgress = (id, progress) => ({
  type: constants.SET_UPLOAD_PROGRESS,
  payload: {
    id,
    progress,
  },
});

export const successUploadFile = (id) => ({
  type: constants.SUCCESS_UPLOAD_FILE,
  payload: id,
});

export const failureUploadFile = (id) => ({
  type: constants.FAILURE_UPLOAD_FILE,
  payload: id,
});
