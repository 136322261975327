import styled from "styled-components";
import * as theme from "../../theme";
import delFileIcon from "../../statics/img/icon-clear-medium-grey.svg";
import uploadCloudIcon from "../../statics/img/icon-upload-cloud-light-grey.svg";
import greenTickIcon from "../../statics/img/icon-tick-green.svg";
import inactiveEditIcon from "../../statics/img/icon-edit-inactive.svg";
import editIcon from "../../statics/img/icon-edit-medium-grey.svg";
import downloadFileIcon from "../../statics/img/download-file-medium-grey.svg";
import attachIcon from "../../statics/img/icon-file-pin-light-grey.svg";
import checkIcon from "../../statics/img/icon-check-box-check-dark-grey.svg";
import uncheckIcon from "../../statics/img/icon-check-box-un-check-dark-grey.svg";
import noticeIcon from "../../statics/img/icon-information-yellow.svg";

export const FileFrame = styled.div`
  border: 1px solid rgb(198, 204, 208);
  margin-bottom: 40px;
  .dropzone {
    padding: 12px 16px;
    .Highlight {
      background-color: ${theme.palette.veryMediumGrey};
    }
  }
  .dragFrame {
    background-color: ${theme.palette.veryColdGrey};
    border-radius: 4px;
    border: 2px dashed rgb(198, 204, 208);
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 30px;
    height: 80px;
    padding: 25px 0;
  }
  .uploadText {
    display: flex;
    justify-content: center;
  }
  .uploadCloudImg {
    height: 30px;
    width: 30px;
    background: url(${uploadCloudIcon}) no-repeat;
    background-size: contain;
    display: block;
    margin-right: 16px;
  }

  .drag {
    font-weight: ${theme.font.mainFontWeight};
    color: ${theme.palette.wolfGrey};
  }
  .upload {
    margin: 0 8px;
    text-align: center;
    color: ${theme.palette.wolfGrey};
  }
  .browser {
    font-weight: ${theme.font.mainFontWeight};
    color: ${theme.palette.gunmetal};
  }
  .uploadfiles {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
  }
  .filelist {
    height: 44px;
    line-height: 24px;
    font-size: 14px;
    padding: 8px 16px 12px;
    &.files {
      padding: 10px 0;
      height: auto;
      border-bottom: 1px solid ${theme.palette.lightWolfGrey};
      &:last-child {
        border: none;
      }
    }
    .uploadIcon {
      height: 24px;
      width: 24px;
      background: url(${greenTickIcon}) no-repeat;
      background-size: contain;
      display: block;
      float: left;
      margin-right: 10px;
    }
    .name {
      font-weight: ${theme.font.mainFontWeight};
      float: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${theme.palette.gunmetal};
      &.small {
        max-width: 70%;
      }
    }
    .size {
      float: left;
      margin-left: 8px;
      color: ${theme.palette.veryMediumGrey};
    }
    .action {
      height: 24px;
      width: 24px;
      background: url(${delFileIcon}) no-repeat;
      background-size: contain;
      display: block;
      float: right;
      border: none;
      cursor: pointer;
    }
    .status {
      float: right;
      margin-right: 12px;
      color: ${theme.palette.veryMediumGrey};
    }
  }
`;
export const ReviewWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 40px;
  box-sizing: border-box;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  &.hasMargin {
    margin-top: 56px;
  }
  .sub-wrapper {
    margin-bottom: 32px;
    &.split {
      display: flex;
    }
    .right {
      display: flex;
      width: 351px;
      float: right;
    }
    &.flex-row {
      align-items: left;
      padding: 12px 16px;
      gap: 8px;
      width: 100%;
      height: 44px;
      background: ${theme.palette.paleYellow};
      display: flex;
    }
  }
`;

export const ProjectNavList = styled.div`
  width: 100%;
  line-height: 24px;
  font-size: 24px;
  font-weight: ${theme.font.mainFontWeight};
  margin-bottom: 20px;
  &.comment {
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;
    margin: 40px 0;
  }
  .imgEdit {
    height: 24px;
    width: 24px;
    background: url(${editIcon});
    background-size: contain;
    cursor: pointer;
    float: right;
    &.inactive {
      background: url(${inactiveEditIcon});
    }
  }
`;
export const AttachmentList = styled.div`
  margin-top: 40px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const AttachmentWrapper = styled.div`
  &.hasMargin {
    margin-top: 40px;
  }
  .title {
    font-size: 16px;
    font-weight: ${theme.font.mainFontWeight};
    margin-bottom: 8px;
  }
  .uploadFileWrapper {
    height: auto;
    background-color: ${theme.palette.white};
    width: 100%;
  }
  .button_wrapper {
    margin-top: 30px;
  }
`;

export const NoFileWrapper = styled.div`
  height: 90px;
  box-sizing: border-box;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FileWrapper = styled.div`
  width: 100%;
  max-height: 200px;
  padding: 0 24px;
  box-sizing: border-box;
  overflow: auto;
  float: left;
  border: 1px solid ${theme.palette.wolfGrey};
  color: ${theme.palette.veryMediumGrey};
  background-color: ${theme.palette.greyWhite};
  li {
    list-style-type: none;
    border-bottom: 1px solid ${theme.palette.lightWolfGrey};
  }
  li:last-child {
    border: none;
  }
`;

export const FileDetail = styled.div`
  height: 44px;
  box-sizing: border-box;
  padding: 10px 0;
  font-size: 14px;

  .fileImg {
    height: 24px;
    width: 24px;
    background: url(${greenTickIcon}) no-repeat;
    background-size: contain;
    display: block;
    float: left;
    margin-right: 10px;
    &.attachment {
      background: url(${attachIcon}) no-repeat;
    }
  }
  .fileName {
    font-weight: ${theme.font.mainFontWeight};
    margin-right: 8px;
    line-height: 24px;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 300px;
    &.adjust_size {
      width: 70%;
      max-width: 70%;
      width: auto;
    }
  }
  .size {
    font-weight: ${theme.font.normalFontWeight};
    float: left;
    line-height: 24px;
    color: ${theme.palette.veryMediumGrey};
  }
  .share_file_wrapper {
    float: right;
    margin-right: 24px;
    color: ${theme.palette.gunmetal};
    font-weight: ${theme.font.normalFontWeight};
    height: 24px;
    display: flex;
    align-items: center;
    font-size: 14px;
    .checkBox {
      -webkit-appearance: none;
      height: 24px;
      width: 24px;
      mask: url(${uncheckIcon}) no-repeat;
      background-color: ${theme.palette.gunmetal};
      background-size: contain;
      cursor: pointer;
      margin-right: 10px;
      :checked {
        mask: url(${checkIcon}) no-repeat;
        background-color: ${theme.palette.gunmetal};
      }
      :disabled {
        background-color: ${theme.palette.veryMediumGrey};
        cursor: not-allowed;
      }
      :disabled + label {
        color: ${theme.palette.veryMediumGrey};
      }
    }
  }

  .delFileImg {
    height: 24px;
    width: 24px;
    background: url(${delFileIcon}) no-repeat;
    background-size: contain;
    display: block;
    float: right;
    cursor: pointer;
    border: none;
    &.downloadFileImg {
      background: url(${downloadFileIcon}) no-repeat;
    }
    &:hover {
      background-color: ${theme.palette.veryWarmLightGrey};
      border-radius: 50%;
    }
    &:active {
      background-color: ${theme.palette.wolfGrey};
      border-radius: 50%;
    }
  }

  &.attachment {
    display: flex;
    justify-content: space-between;
    .right {
      display: flex;
      // mostly here to prevent dropdown icon from moving when hovered
      .dropdown-spacer {
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const NoticeWrap = styled.div`
  max-width: 600px;
  height: auto;
  margin: 10px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: ${theme.palette.paleYellow};
  display: flex;
  align-items: center;
  line-height: 16px;
  .noticeIcon {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    background: url(${noticeIcon}) no-repeat;
    background-size: contain;
  }
`;
