import React from "react";
import {
  DownloadBtn as Button,
  GreyOutlinedBtn as ButtonGrey,
} from "../../common/button";
import RequestPopup from "../../common/modal/component/supportDialog";
import { ErrorBoundaryPage } from "./index";
import API from "../../server";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      openSupportDialog: false,
      sent: false,
    };
  }

  sendError(data) {
    API.report_error(data);
  }

  componentDidCatch(error, errorInfo) {
    // catch errors below and render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  clearError(e) {
    e.stopPropagation();
    this.setState({
      error: null,
      errorInfo: null,
    });
  }

  render() {
    const { errorInfo, sent, openSupportDialog, error } = this.state;
    if (errorInfo) {
      if (!sent) {
        this.sendError({
          error_path: window.location.hostname + window.location.pathname,
          error_stack: error.stack,
        });
        this.setState({
          sent: true,
        });
      }
      return (
        <>
          <ErrorBoundaryPage>
            <div>
              <ButtonGrey
                title="report error"
                margin={"0 0 0 16px "}
                onClick={() => this.setState({ openSupportDialog: true })}
              />
              <Button
                title="refresh page"
                onClick={(e) => this.clearError(e)}
              />
            </div>
          </ErrorBoundaryPage>
          <RequestPopup
            open={openSupportDialog}
            closeDialog={() => this.setState({ openSupportDialog: false })}
          />
        </>
      );
    }
    // if no error, just render normal component
    return this.props.children;
  }
}

export default ErrorBoundary;
