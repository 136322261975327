import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FileFrame } from "../styled";

import { bytesToSize } from "../../../utils/helper";
import Dropzone from "./dropzone";
import UploadBar from "./uploadBar";

import {
  uploadFileWithProgress,
  clearFiles,
  uploadFileRetry,
  deleteUploadingFile,
} from "../store/actionCreators";

const FileUpload = (props) => {
  const { uploadedFiles, deleteFileFromDB, uploadFileApi } = props;

  const dispatch = useDispatch();
  const uploadingFiles = useSelector((state) =>
    state.getIn(["attachment", "files"]),
  );

  const handleRetry = (file) => {
    dispatch(uploadFileRetry(file.file_id));
    dispatch(uploadFileWithProgress(uploadFileApi, file.file, file.file_id));
  };

  const handleDelete = (fileId) => {
    if (typeof fileId === "string") {
      deleteFileFromDB(fileId);
    } else {
      dispatch(deleteUploadingFile(fileId));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearFiles());
    };
  }, [dispatch]);

  const File = ({ file }) => (
    <div className="file-list files" key={file.get("file_id")}>
      <div className="uploadIcon" />
      <div className="name">{file.get("file_name")}</div>
      <div className="size">({bytesToSize(file.get("file_size"))})</div>
      <div
        className="action"
        onClick={() => deleteFileFromDB(file.get("file_id"))}
      />
      <div className="status">Complete</div>
    </div>
  );
  return (
    <FileFrame>
      <Dropzone api={uploadFileApi} />
      <hr />
      <div className="upload-files">
        {uploadedFiles.map((file) => (
          <File key={file.get("file_id")} file={file} />
        ))}
        {uploadingFiles.map((file) => (
          <UploadBar
            key={file.get("file_id")}
            file={file.toJS()}
            retryFile={handleRetry}
            removeFile={handleDelete}
          />
        ))}
      </div>
    </FileFrame>
  );
};

export default FileUpload;
