import Tooltip from "../../../common/toolTip";
import React from "react";
import { SubsetBadge } from "../styled";

function ProjectSubsetBadge(props) {
  return (
    <Tooltip
      title={
        "You are seeing a subset of project totals based on contracts you have access to."
      }
      placement="bottom"
    >
      <SubsetBadge className={props.className}>Subset</SubsetBadge>
    </Tooltip>
  );
}

export default ProjectSubsetBadge;
