import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { isDirty } from "redux-form/immutable";

import { BgWrapper, HeaderTitle } from "../../../../../common/shared_style";

import Menu from "../../../../../common/breadcrumbs";
import Prompt from "../../../../../common/modal/component/prompt";
import Form from "./components/form";

import { actionCreators } from "../../../store";

class Index extends PureComponent {
  render() {
    var link = [{ link: "/manage-account/view", text: "Manage Account" }];
    return (
      <BgWrapper>
        <Prompt when={this.props.isFormDirty && !this.props.saved} />
        <Menu link={link} content="Add New User" />
        <HeaderTitle>Add New User</HeaderTitle>
        <Form
          initialValues={{ role_id: "none" }}
          onSubmit={(values) => this.props.addNewUser(values)}
          saving={this.props.saving}
          accountConfig={this.props.accountConfig}
        />
      </BgWrapper>
    );
  }
  componentWillUnmount() {
    this.props.reset();
  }
}
const mapStateToProps = (state) => ({
  isFormDirty: isDirty("MewUserDetailsForm")(state),
  saved: state.getIn(["manageAccount", "saved"]),
  allAccount: state.getIn(["manageAccount", "allAccount"]),
  saving: state.getIn(["manageAccount", "saving"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});
const mapDispatchToProps = (dispatch) => {
  return {
    addNewUser(values) {
      dispatch(actionCreators.inviteANewUser(values));
    },
    reset() {
      dispatch(actionCreators.reset());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
