import React, { useEffect, useState } from "react";
import { AdditionalWrapper } from "../../project/styled";
import Input from "../../../common/form";
import AddressSearch from "../../../common/mapbox/addressSearch";
import MapboxDisplay from "../../../common/mapbox/mapboxDisplay";
import { StateWarningModal } from "../../../common/config/stateConfigInput";
import { COUNTRY_AU } from "../../../utils/constants";

export const RequiredInput = React.forwardRef((props, ref) => {
  return (
    <div className={"has_margin " + props?.customClassName || ""}>
      <Input
        {...props}
        ref={ref}
        field={props.linked ? "link-field" : props.field}
      />
    </div>
  );
});
const Address = (props) => {
  const {
    address,
    projectInfo,
    errors,
    handleChange,
    setTargetAddress,
    className,
    readOnly,
    isSettings,
    isDraft,
    accountConfig,
    refs,
    linked,
  } = props;
  const hasMapService =
    localStorage.getItem("MAPBOX_TOKEN") !== null &&
    localStorage.getItem("MAPBOX_TOKEN") !== "";
  const isCreate = isDraft === undefined && isSettings === undefined;

  const [displayAddress, setDisplayAddress] = useState(address);

  useEffect(() => {
    setDisplayAddress({ ...address });
    // use project address in create contract page
    if (linked && isCreate) {
      setDisplayAddress({ ...projectInfo?.toJS() });
    }
  }, [address, linked, isCreate, projectInfo]);

  const createInputField = (label, name, css, options = {}) => (
    <Input
      label={label}
      name={name}
      className={`input-field${css}`}
      error={errors?.[name]}
      value={displayAddress[name] || ""}
      {...options}
      field={linked && name !== "country" ? "link-field" : options.field}
    />
  );

  return (
    <AdditionalWrapper className={className}>
      <h3>Contract Address</h3>
      {!linked && (
        <>
          {!isSettings && (
            <p>
              This is the location where construction work is carried out and
              determines the legislation details of{" "}
              {accountConfig?.getIn(["claim", "noun"])?.toLowerCase()}s and
              certificates.
            </p>
          )}
          {hasMapService && !readOnly && (
            <MapboxDisplay addSpacing={true}>
              <AddressSearch
                hasRestrictCountry
                setAddress={(data, insertDefaultCountry) =>
                  setTargetAddress(data, insertDefaultCountry)
                }
              />
            </MapboxDisplay>
          )}
        </>
      )}
      <div className="flex-row full-size">
        <Input
          label={accountConfig.getIn(["address", "street"])}
          name={"street_address"}
          className={`input-field ${"full-width"}`}
          error={errors?.["street_address"]}
          value={displayAddress["street_address"] || ""}
          disabled={readOnly}
          marginBottom="24px"
          required={true}
          width="100%"
          ref={refs?.streetAddressRef}
          onChange={(e) =>
            setDisplayAddress({
              ...displayAddress,
              street_address: e.target.value,
            })
          }
          {...(linked && {
            field: "link-field",
            value: displayAddress.street_address,
          })}
        />
      </div>
      <div className="flex-row full-size">
        {createInputField(
          accountConfig.getIn(["address", "suburb"]),
          "suburb",
          " middle",
          {
            onChange: (e) =>
              setDisplayAddress({ ...displayAddress, suburb: e.target.value }),
            onBlur: handleChange,
            disabled: readOnly,
            ...(linked && {
              field: "link-field",
              value: displayAddress.suburb,
            }),
          },
        )}
        {createInputField(
          accountConfig.getIn(["address", "city"]),
          "city",
          " middle",
          {
            onChange: (e) =>
              setDisplayAddress({ ...displayAddress, city: e.target.value }),
            onBlur: handleChange,
            disabled: readOnly,
            ...(linked && { field: "link-field", value: displayAddress.city }),
          },
        )}
      </div>
      <div className="flex-row full-size">
        {createInputField(
          accountConfig.getIn(["address", "postcode"]),
          "postal_code",
          " small",
          {
            onChange: (e) =>
              setDisplayAddress({
                ...displayAddress,
                postal_code: e.target.value,
              }),
            onBlur: handleChange,
            disabled: readOnly,
            ...(linked && {
              field: "link-field",
              value: isCreate
                ? displayAddress.postcode
                : displayAddress.postal_code,
            }),
          },
        )}
        <span ref={refs?.stateRef} />
        {address.country !== COUNTRY_AU ? (
          <>
            {createInputField(
              accountConfig.getIn(["address", "state"]),
              "state",
              " small",
              {
                onChange: (e) =>
                  setDisplayAddress({
                    ...displayAddress,
                    state: e.target.value,
                  }),
                onBlur: handleChange,
                disabled: readOnly,
                ...(linked && {
                  field: "link-field",
                  value: displayAddress.state,
                }),
              },
            )}
          </>
        ) : (
          <div
            className={"has_margin " + `${errors.state ? "" : "margin-bottom"}`}
          >
            {createInputField(
              accountConfig.getIn(["address", "state"]),
              "state",
              " small",
              {
                onChange: (e) =>
                  setDisplayAddress({
                    ...displayAddress,
                    state: e.target.value,
                  }),
                onBlur: handleChange,
                disabled: readOnly,
                field: "dropdown",
                placeholder: `Select ${accountConfig.getIn(["address", "state"])}`,
                width: 256,
                options: accountConfig.get("state")?.toJS() || [],
                value: address?.state || displayAddress?.state || "",
                missingRequired: !address.state && errors.state !== undefined,
                required: true,
              },
            )}
          </div>
        )}
        {createInputField(
          accountConfig.getIn(["address", "country"]),
          "country",
          " small",
          {
            disabled: readOnly,
            field: "lock-country",
            width: "272px",
            value: displayAddress.country || "",
          },
        )}
      </div>
      <StateWarningModal
        country={address?.country}
        state={address?.state}
        showError={!address.state && errors.state !== undefined}
      />
    </AdditionalWrapper>
  );
};

export default Address;
