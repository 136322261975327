import React, { Component } from "react";
import Select from "react-select";
import * as mainTheme from "../../theme";
import DropdownIndicator from "./component/indicator";
import { overrideColor } from "./component/helper";

export default class InputSelect extends Component {
  render() {
    const { options, value, onChange, width, controlWidth, margin } =
      this.props;

    const selectInputStyle = {
      control: (styles) => ({
        ...styles,
        width: controlWidth || "100%",
        height: "40px",
        border: "none",
        fontSize: "14px",
        boxShadow: "none",
      }),
      option: (provided, state) => ({
        ...provided,
        width: width || "100%",
        height: "40px",
        fontSize: "14px",
        color: mainTheme.palette.gunmetal,
        "&:hover": {
          backgroundColor: mainTheme.palette.warmLightGrey,
          cursor: "pointer",
        },
      }),
      indicatorSeparator: (styles) => ({
        ...styles,
        display: "none",
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        padding: "14px",
      }),
      menu: (styles) => ({
        ...styles,
        width: width || "calc(100% + 70px)",
        color: mainTheme.palette.gunmetal,
        fontFamily: mainTheme.font.mainFont,
        margin: margin || "10px 0 0 -70px",
      }),
      singleValue: (styles) => ({
        ...styles,
        lineHeight: "16px",
        color: mainTheme.palette.gunmetal,
        fontFamily: mainTheme.font.mainFont,
      }),
      menuPortal: (styles) => ({
        ...styles,
        zIndex: 9999,
      }),
    };

    return (
      <Select
        components={{ DropdownIndicator, indicatorSeparator: null }}
        options={options}
        value={value}
        onChange={onChange}
        styles={selectInputStyle}
        closeMenuOnSelect={true}
        isSearchable={false}
        theme={(theme) => overrideColor(theme)}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        minMenuHeight={options.length * 40 + 10} // +10 to avoid scrollbars inside the dropdown
      />
    );
  }
}
