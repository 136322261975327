import React from "react";
import { GreenWhiteBtn, BlueOutlinedBtn } from "../../button";
import Modal from "./modalBase";
import { Icon } from "./dialogStyle";

const Confirm = ({
  visible,
  close,
  content,
  iconType = "none",
  textStyle,
  finalise,
}) => {
  return (
    <Modal
      open={visible}
      divider={false}
      title="Finalise Contract"
      handleClose={() => close()}
    >
      <div
        style={
          textStyle ? textStyle : { padding: "33px 30px", lineHeight: "30px" }
        }
      >
        {iconType === "none" ? "" : <Icon type={iconType} />}{" "}
        {/* icon - actually modal types from dialogStyle.js */}
        {content}
      </div>
      <div>
        <GreenWhiteBtn
          title="Finalise"
          width="125px"
          onClick={() => finalise()}
        />
        <BlueOutlinedBtn
          title="Cancel"
          margin="0 30px 0 0"
          width="125px"
          onClick={() => close()}
        />
      </div>
    </Modal>
  );
};

export default Confirm;
