import React from "react";
import { connect } from "react-redux";
import { ContractInfoWrapper } from "../styled";
import InvitationBar from "./invitationDetail";

const DetailItem = ({ item, label }) => {
  if (item) {
    return (
      <div>
        <label>
          <b>{label}</b>
        </label>
        <label>
          {item} {label.includes("GST") ? "%" : ""}
        </label>
      </div>
    );
  } else {
    return null;
  }
};

const Index = (props) => {
  const { contractInfo, accountConfig } = props;
  const contractDetails = {
    contract_number: "Contract Number",
    payee_ref: "Payee Reference",
    po_number: "PO Number",
    gst: accountConfig?.getIn(["gst_rate", "title"]) || "",
  };
  return (
    <ContractInfoWrapper>
      <div className="sub-wrapper">
        <div className="title">{contractInfo.get("name")}</div>
        {contractInfo.get("trade") && (
          <div className="trade">{contractInfo.get("trade")}</div>
        )}
        {contractInfo.get("description") && (
          <div>{contractInfo.get("description")}</div>
        )}
        <div className="info-list">
          {Object.keys(contractDetails).map((ele) => (
            <DetailItem
              item={contractInfo.get(ele)}
              label={contractDetails[ele]}
              key={ele}
            />
          ))}
        </div>
      </div>
      <div className="sub-wrapper">
        <InvitationBar {...props} />
      </div>
    </ContractInfoWrapper>
  );
};

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default connect(mapStateToProps, null)(Index);
