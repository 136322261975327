import React, { PureComponent } from "react";
import { Wrapper, ExpandIcon } from "../styled";
import ListItem from "./list_item";
export default class ListTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expand: true,
    };
  }
  render() {
    const { filterType, list, deleteNotice, title, accountConfig } = this.props;
    const { expand } = this.state;
    const filteredList = list.filter(
      (item) =>
        filterType === "all" || item.get("type").indexOf(filterType) !== -1,
    );
    return (
      <Wrapper>
        <div className="top">
          <ExpandIcon
            className={expand ? "" : "hide"}
            onClick={() =>
              this.setState({
                expand: !expand,
              })
            }
          />
          <div className="label">{title}</div>
        </div>
        <div className={expand ? "content" : "content hide"}>
          {filteredList.map((item) => (
            <ListItem
              content={item}
              deleteNotice={deleteNotice}
              key={item.get("id")}
              accountConfig={accountConfig}
            />
          ))}
        </div>
      </Wrapper>
    );
  }
}
