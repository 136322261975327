import React, { useState, useEffect, Fragment } from "react";

import {
  ProjectDetailWrapper,
  SettingSection,
  SectionIcon,
} from "pages/project/styled";

import {
  BlueOutlinedBtn as Button,
  BlueWhiteBtn as ButtonDark,
} from "common/button";
import PaymentDatesModal from "./paymentDatesModal";
import DateScheduleBar from "./dateScheduleBar";
import { calculateMonthPosition } from "utils/dateScheduleHelper";
import { DEFAULT_DUE_DATE_TERMS } from "utils/constants";

export default function DateSchedule(props) {
  const {
    config,
    payTerms,
    submitDueTerms,
    calculateExample,
    dueDates,
    accountConfig,
  } = props;
  const [initial, setInitial] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [monthPosition, setMonthPosition] = useState({});
  const [paymentTermsOptions, setPaymentTermsOptions] = useState({});

  useEffect(() => {
    let paymentTermsOptions = {
      pay_cert_options: config?.payclaim["pay_cert_options"],
      pay_claim_options: config?.payclaim["pay_claim_options"],
      payment_options: config?.payclaim["payment_options"],
    };
    setPaymentTermsOptions(paymentTermsOptions);
  }, [config]);

  useEffect(() => {
    // check if the payment due dates for this project has been set before
    if (
      payTerms.get("claim_due_term") !== null &&
      payTerms.get("cert_due_term") !== null &&
      payTerms.get("payment_due_term") !== null
    ) {
      if (!initial) {
        setInitial(true);
      }

      let newPayTerms = {
        claim: {
          days: payTerms.get("claim_due_days"),
          terms: payTerms.get("claim_due_term"),
        },
        cert: {
          days: payTerms.get("cert_due_days"),
          terms: payTerms.get("cert_due_term"),
        },
        pay: {
          days: payTerms.get("payment_due_days"),
          terms: payTerms.get("payment_due_term"),
        },
      };
      let monthPosition = calculateMonthPosition(
        newPayTerms.claim,
        newPayTerms.cert,
        newPayTerms.pay,
      );

      setMonthPosition(monthPosition);
    }
  }, [payTerms, initial]);

  const handleSubmit = (terms) => {
    setShowModal(false);
    submitDueTerms(terms);
  };

  return (
    <ProjectDetailWrapper className="setting-page" id="nav-dateSchedule">
      <SettingSection>
        <div className="section-title">
          <div>
            <SectionIcon img={"date"} />
            <span>Date Schedule</span>
          </div>
          {initial && (
            <Button title="edit" onClick={() => setShowModal(true)} />
          )}
        </div>

        {initial ? (
          <DateScheduleBar
            monthPosition={monthPosition}
            options={paymentTermsOptions}
          />
        ) : (
          <Fragment>
            <div className="center">
              Set up date for {accountConfig?.getIn(["claim", "noun"])}s,
              Certificates and Payments. You can use the same dates as set up in
              the project if needed.
            </div>
            <div>
              <ButtonDark
                title="Set Up Dates"
                onClick={() => setShowModal(true)}
              />
              <Button
                title="Use Default"
                margin={"0 16px 0 0"}
                onClick={() => submitDueTerms(DEFAULT_DUE_DATE_TERMS)}
              />
            </div>
          </Fragment>
        )}

        <PaymentDatesModal
          open={showModal}
          close={() => setShowModal(false)}
          title="Payment Due Dates"
          options={paymentTermsOptions}
          handleSubmit={handleSubmit}
          initial={payTerms}
          calculateMonthPosition={calculateMonthPosition}
          calculateExample={calculateExample}
          dueDates={dueDates}
          isProject
          accountConfig={accountConfig}
        />
      </SettingSection>
    </ProjectDetailWrapper>
  );
}
