import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "../linkWithQuery";
import Typography from "@material-ui/core/Typography";
import React from "react";
import * as theme from "../../theme";

const linkStyle = {
  fontFamily: theme.font.mainFont,
  color: theme.palette.gunmetal,
  cursor: "pointer",
  textTransform: "capitalize",
};

const activeLinkStyle = {
  color: theme.palette.turquoiseBlue,
  fontFamily: theme.font.mainFont,
  textTransform: "capitalize",
};

const container = {
  margin: "-26px 0 30px 0",
};

export default function SimpleBreadcrumbs(props) {
  return (
    <div style={container} className={props.className}>
      <Breadcrumbs aria-label="breadcrumb">
        {props.link.map((item, index) => (
          <Link
            style={linkStyle}
            to={item.link}
            key={index}
            onClick={item.onClick}
          >
            {item.text}
          </Link>
        ))}
        <Typography style={activeLinkStyle}>{props.content}</Typography>
      </Breadcrumbs>
    </div>
  );
}
