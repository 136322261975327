import styled from "styled-components";
import * as theme from "../../theme";
import pattern from "../../statics/img/slate-pattern.svg";

export const PageLayout = styled.div`
  display: grid;
  grid-template-rows: 124px auto;
  grid-row-gap: 56px;

  .pattern {
    width: 776px;
    height: 380px;
    background: url(${pattern});
    background-repeat: no-repeat;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
`;

export const Header = styled.div`
  background-color: ${theme.palette.tangerineOrange};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  .logo {
    height: 100px;
    width: 150px;
    margin-left: 140px;
  }
`;

export const Body = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  width: 700px;
  background-color: ${theme.palette.white};
  padding: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  h1 {
    margin: 0;
    height: fit-content;
    font-size: 56px;
    color: ${theme.palette.tangerineOrange};
    letter-spacing: 0.2px;
    font-weight: bold;
  }

  p {
    margin: 0;
    margin-top: 24px;
    color: ${theme.palette.gunmetal};
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.2px;
  }

  button {
    margin-top: 40px;
    padding: 16px 32px;
    height: auto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.2px;
    border-radius: 4px;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const Image = styled.img.attrs((props) => ({
  src: props.src,
}))`
  margin-top: 64px;
  width: 320px;
  height: 320px;
`;
