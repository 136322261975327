import React from "react";
import Release from "./Release";
import { Router, Route, Switch } from "react-router-dom";
import { clearCache } from "clear-cache";

import { GlobalStyle } from "./style";
import "mapbox-gl/dist/mapbox-gl.css";

import history from "./utils/history";
import ToTop from "./common/scroll";
import Modal from "./common/modal/modal";
import ToastContainer from "./common/toastNotification";
import Login from "./pages/loginPage/login";
import SignUp from "./pages/loginPage/signUp";
import FileDownload from "./pages/fileDownload";
import MainPageRoutes from "./MainPage";
import { Error401NoAcc, Error403AccessDenied } from "./pages/errorPage";
import ErrorBoundary from "./pages/errorPage/errorBoundary";

class App extends React.Component {
  componentDidMount() {
    const NODE_ENV = process.env.NODE_ENV;
    console.log(`Running under ${NODE_ENV} environment...`);
    if (NODE_ENV !== "development") {
      let version = localStorage.getItem("version");
      if (version !== Release?.version) {
        localStorage.clear();
        localStorage.setItem("version", Release?.version);

        setTimeout(() => {
          console.log(
            "Production Version has been changed, reloading in 3 seconds...",
          );
          clearCache();
        }, 3000);
      }
      console.log(`version: ${version}`);
      console.log(`package version: ${Release?.version}`);
    }
  }

  render() {
    return (
      <Router history={history}>
        <GlobalStyle />
        <ErrorBoundary>
          <Switch>
            <Route path="/login" exact render={() => <Login />} />
            <Route path="/sign_up" exact render={() => <SignUp />} />
            <Route
              path="/error_401_no_accounts"
              exact
              render={() => <Error401NoAcc />}
            />
            <Route
              path="/access-denied"
              exact
              render={() => <Error403AccessDenied />}
            />
            <Route
              path="/file/:token"
              exact
              render={(props) => <FileDownload {...props} />}
            />
            <MainPageRoutes />
          </Switch>
        </ErrorBoundary>
        <ToastContainer />
        <Modal />
        <ToTop />
      </Router>
    );
  }
}

export default App;
