import { fromJS } from "immutable";
import * as constants from "./constants";

const defaultState = fromJS({
  step: [],
  stepInfo: [],
  userList: [],
  basicInfo: {},
  loading: true,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SET_DATA:
      return state.set(
        action.name,
        action.immutable ? fromJS(action.value) : action.value,
      );
    case constants.RESET:
      return defaultState;
    default:
      return state;
  }
};
