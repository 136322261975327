import React from "react";
import numeral from "numeral";
import store from "../store";

import BootstrapTooltip from "../common/toolTip";

// Units        |       SubUnits
// Dollar       |       Cent
// Pound        |       Pence
// Saudi Riyal  |       Halalah

// Function does the simple division here to convert to dollar
export const calculateSubunitToUnit = (num) => {
  return num ? num / 100 : 0;
};

// Function does the simple multiplication here to convert to cent
// also using math.round for maintaining precision for calculations which fixes unwanted floating-point precisions.
export const calculateUnitToSubunit = (num) => {
  return num ? Math.round(num * 100) : 0;
};

export const convertToCurrencyUnit = (
  num,
  hasSymbol = true,
  dropDecimal = false,
  moduleShorten = false,
) => {
  let symbol = hasSymbol
    ? store.getState().getIn(["config", "accountConfig", "currency", "title"])
    : "";
  let direction = store
    .getState()
    .getIn(["config", "accountConfig", "direction", "value"]);
  let enableCurrencyShorten = store
    .getState()
    .getIn(["config", "accountConfig", "enable_currency_shorten"]);
  let enableHeaderCurrency = store
    .getState()
    .getIn(["config", "accountConfig", "enable_header_currency"]);
  let number = num || 0;
  let format = "";

  // Only numbers millions should be shortened
  if (
    moduleShorten &&
    enableCurrencyShorten &&
    (number > 999999 || number < -999999)
  ) {
    // When shortening the currency
    format = new Intl.NumberFormat("en", {
      notation: "compact",
      minimumFractionDigits: dropDecimal ? 0 : 2,
    }).format(number);
  } else {
    // When not shortening the currency
    format = numeral(number).format(`0,0${dropDecimal ? "" : ".00"}`);
  }

  if (!hasSymbol) {
    return format;
  }

  let space = enableHeaderCurrency && symbol?.length > 1 ? " " : "";

  return symbol
    ? direction === "RTL"
      ? format + space + symbol
      : symbol + space + format
    : format;
};

export const currencySubunitToUnit = (
  num,
  hasSymbol = true,
  dropDecimal = false,
  moduleShorten = false,
  bracketFormat = false,
) => {
  let symbol = hasSymbol
    ? store.getState().getIn(["config", "accountConfig", "currency", "title"])
    : "";
  let direction = store
    .getState()
    .getIn(["config", "accountConfig", "direction", "value"]);
  let enableCurrencyShorten = store
    .getState()
    .getIn(["config", "accountConfig", "enable_currency_shorten"]);
  let enableHeaderCurrency = store
    .getState()
    .getIn(["config", "accountConfig", "enable_header_currency"]);

  let number = calculateSubunitToUnit(num);
  let format = "";
  // Only numbers millions should be shortened
  if (
    moduleShorten &&
    enableCurrencyShorten &&
    (number > 999999 || number < -999999)
  ) {
    // When shortening the currency
    format = new Intl.NumberFormat("en", {
      notation: "compact",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(number);
  } else {
    // When not shortening the currency
    format = numeral(number).format(`0,0${dropDecimal ? "" : ".00"}`);
  }

  if (bracketFormat && format.startsWith("-")) {
    // Format negative values to brackets.
    format = `(${format.slice(1)})`;
  }

  if (!hasSymbol) {
    return format;
  }

  let space = enableHeaderCurrency && symbol?.length > 1 ? " " : "";

  return symbol
    ? direction === "RTL"
      ? format + space + symbol
      : symbol + space + format
    : format;
};

export const formatLargeCurrency = (num, showTooltip = false) => {
  const accountConfig = store.getState().getIn(["config", "accountConfig"]);

  let displaygorssItem;
  if (num > 100000000000) {
    // Convert the number to a string
    const numberString = num.toString();

    // Get the integer part of the number
    const integerPart = numberString.split(".")[0];

    // Calculate the number of characters before the ellipsis
    const charactersBeforeEllipsis = integerPart.length - 5; // Keep two decimal places

    // Create a pattern for the regular expression
    const pattern = new RegExp(`^(\\d{${charactersBeforeEllipsis}})\\d*`);

    // Extract and format the number part before the ellipsis
    const formattedNumber = integerPart.replace(pattern, "$1");

    // Add commas to the formatted number
    const numberWithCommas = formattedNumber.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ",",
    );

    if (showTooltip) {
      // eslint-disable-next-line
      displaygorssItem = (
        <BootstrapTooltip
          className="amount-tooltip"
          title={currencySubunitToUnit(num, true, false, false)}
          placement="bottom"
        >
          <div>
            {!accountConfig?.get("enable_header_currency") &&
              accountConfig?.getIn(["currency", "title"])}
            {numberWithCommas + "..."}
          </div>
        </BootstrapTooltip>
      );
    } else {
      displaygorssItem = (
        <>
          {!accountConfig?.get("enable_header_currency") &&
            accountConfig?.getIn(["currency", "title"])}
          {numberWithCommas + "..."}
        </>
      );
    }
  } else {
    displaygorssItem = currencySubunitToUnit(
      num,
      !accountConfig?.get("enable_header_currency"),
      false,
      false,
    );
  }
  return displaygorssItem;
};
