import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { parsePercent } from "../../../utils/numeralHelper";
import { currencySubunitToUnit } from "../../../utils/currencyHelper";
import {
  HIDE_DRAFT,
  HIDE_CURRENT,
  HIDE_COMPLETE,
  SHOW_ARCHIVED,
  STATUS_CURRENT,
  STATUS_DRAFT,
  STATUS_COMPLETED,
  STATUS_ARCHIVE,
  OVERDUE,
  DUE_IN_3_DAYS,
  DUE_IN_7_DAYS,
} from "../../../utils/constants";
import {
  ContractWrap,
  ContractListWrapper,
  ContractSummary,
  IconLink,
} from "../styled";
import { BlueWhiteBtn as Button } from "../../../common/button";
import Tooltip from "../../../common/toolTip";
import TableView from "./tableView";
import ActionBar from "../../projects/components/actionBar";
import { fromJS } from "immutable";
import Link from "../../../common/linkWithQuery";
import CompletionInfo from "./completionInfo";
import ProjectSubsetBadge from "../../projects/components/projectSubsetBadge";
import { getContractTag } from "./dueWarningBadge";
import { caseInsensitiveIncludes } from "../../../utils/helper";
import { LinkTooltip } from "./integration";

const filterOptions = {
  "Contract Status": [
    { label: "Hide Draft", value: HIDE_DRAFT },
    { label: "Hide Active", value: HIDE_CURRENT },
    { label: "Hide Complete", value: HIDE_COMPLETE },
    { label: "Show Archived", value: SHOW_ARCHIVED },
  ],
  "Certificate Due": [
    { label: "Overdue", value: OVERDUE },
    { label: "Due in 3 Days", value: DUE_IN_3_DAYS },
    { label: "Due in 7 Days", value: DUE_IN_7_DAYS },
  ],
};

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export const Contract = connect(
  mapStateToProps,
  null,
)((props) => {
  const { info, isClaimContract, accountConfig } = props;
  const proId = isClaimContract
      ? info.get("claim_contract_group")
      : info.get("cert_contract_group"),
    type = isClaimContract ? "claim" : "cert";
  const viewPath = `/contract/${proId}/${type}/${info.get("id")}`;
  const otherAccName =
    type === "claim"
      ? info?.get("payer_company")?.get("entity_name")
      : info?.get("payee_company")?.get("entity_name");

  return (
    <ContractWrap>
      <div className="header-bar">
        <div className="contract-name">{info.get("name")}</div>
        {getContractTag(info, accountConfig) && (
          <div>{getContractTag(info, accountConfig)}</div>
        )}
        {info.get("integration") && (
          <LinkTooltip from="Contract">
            <IconLink />
          </LinkTooltip>
        )}
        <CompletionInfo info={info} />
      </div>
      <div className="other-party-name">{otherAccName}</div>
      <div className="info-bar">
        <div className="num">
          {currencySubunitToUnit(
            info.get("total_contract_value"),
            true,
            false,
            true,
          )}
        </div>
        <div className="num">
          {currencySubunitToUnit(
            info.get("claimed_to_date_value"),
            true,
            false,
            true,
          )}
        </div>
        <div className="num">
          {currencySubunitToUnit(
            info.get("cert_to_date_value"),
            true,
            false,
            true,
          )}
        </div>
        <Link to={viewPath} className="view-button">
          <Button title="view" padding="10px 43px" />
        </Link>
        <label>Total contract value</label>
        <label>{accountConfig?.getIn(["claim", "value"])} to date</label>
        <label>{accountConfig?.getIn(["cert", "value"])} to date</label>
      </div>
    </ContractWrap>
  );
});

export function ContractListView(props) {
  const { data, isClaimContract, sortby } = props;

  function compareByOption(field) {
    //sort function based sort dropdown
    let sortLabel = field;
    if (sortLabel !== "name") {
      return function (a, b) {
        var result = a > b ? 1 : b > a ? -1 : 0;
        return result;
      };
    } else
      return function (a, b) {
        var result =
          a.toString().toLowerCase().trim() > b.toString().toLowerCase().trim()
            ? 1
            : b.toString().toLowerCase().trim() >
                a.toString().toLowerCase().trim()
              ? -1
              : 0;
        return result;
      };
  }

  let sortedData = sortby
    ? data.sortBy((val) => {
        return val.get(sortby);
      }, compareByOption(sortby))
    : data;

  return (
    <div>
      {data &&
        sortedData.map((contract) => (
          <Contract
            key={contract.get("id")}
            info={contract}
            isClaimContract={isClaimContract}
          />
        ))}
    </div>
  );
}

export const CompleteTooltip = (props) => {
  return (
    <Tooltip
      placement={props.placement || "bottom"}
      title={
        <div style={{ textAlign: "center" }}>
          <b>
            <div style={{ fontSize: 20, marginBottom: 4 }}>
              {currencySubunitToUnit(props.num)}
            </div>
          </b>
          <div>{props.label}</div>
        </div>
      }
    >
      {props.children}
    </Tooltip>
  );
};

export const ContractList = connect(
  mapStateToProps,
  null,
)((props) => {
  const { contracts, accountId, isNotCovered, accountConfig } = props;
  const [data, setData] = useState(fromJS([]));
  const [summary, setSummary] = useState({
    total_number: 0,
    total_draft_number: 0,
    total_contract_value: 0,
    claimed_to_date_value: 0,
    cert_to_date_value: 0,
    remaining: 0,
  });
  const [layout, setLayout] = useState("list");
  const [sortField, setSortField] = useState("total_contract_value");
  const [searchString, setSearchString] = useState("");
  const [filterOption, setFilterOption] = useState({});
  // default displayed
  const [displayedStatus, setDisplayedStatus] = useState([
    STATUS_CURRENT,
    STATUS_DRAFT,
    STATUS_COMPLETED,
  ]);

  const handleFilterStatus = (list, filters) => {
    let activeStatus = displayedStatus;

    // check which statuses are active based on filters
    for (const [filter, v] of Object.entries(filters["Contract Status"])) {
      // check for draft
      if (filter === HIDE_DRAFT) {
        if (v && activeStatus.includes(STATUS_DRAFT)) {
          activeStatus.splice(activeStatus.indexOf(STATUS_DRAFT), 1);
        } else if (!v && !activeStatus.includes(STATUS_DRAFT)) {
          activeStatus.push(STATUS_DRAFT);
        }
      }
      // check for active/current
      if (filter === HIDE_CURRENT) {
        if (v && activeStatus.includes(STATUS_CURRENT)) {
          activeStatus.splice(activeStatus.indexOf(STATUS_CURRENT), 1);
        } else if (!v && !activeStatus.includes(STATUS_CURRENT)) {
          activeStatus.push(STATUS_CURRENT);
        }
      }
      // check for finalised/completed
      if (filter === HIDE_COMPLETE) {
        if (v && activeStatus.includes(STATUS_COMPLETED)) {
          activeStatus.splice(activeStatus.indexOf(STATUS_COMPLETED), 1);
        } else if (!v && !activeStatus.includes(STATUS_COMPLETED)) {
          activeStatus.push(STATUS_COMPLETED);
        }
      }
      // check for archived (not shown by default)
      if (filter === SHOW_ARCHIVED) {
        if (v && !activeStatus.includes(STATUS_ARCHIVE)) {
          activeStatus.push(STATUS_ARCHIVE);
        } else if (!v && activeStatus.includes(STATUS_ARCHIVE)) {
          activeStatus.splice(activeStatus.indexOf(STATUS_ARCHIVE), 1);
        }
      }
    }
    setDisplayedStatus(activeStatus);
    return list.filter((c) => {
      return activeStatus.includes(c.get("status"));
    });
  };

  const filterCertificateDue = (dueDays, filter) => {
    if (filter["Certificate Due"]) {
      let dueResult = null;
      Object.keys(filter["Certificate Due"]).map((item) => {
        if (filter["Certificate Due"][item]) {
          switch (item) {
            case "overdue":
              dueResult = parseInt(dueDays) < 0;
              break;
            case "due_in_3_days":
              dueResult = parseInt(dueDays) <= 3 && parseInt(dueDays) >= 0;
              break;
            case "due_in_7_days":
              dueResult = parseInt(dueDays) <= 7 && parseInt(dueDays) >= 4;
              break;
            default:
              dueResult = true;
          }
          return dueResult;
        }
        return dueResult;
      });

      // return true if no certificate due status is selected
      if (dueResult === null) {
        return true;
      }
      return dueResult;
    }
    return true;
  };

  const setupSummary = (data) => {
    let contracts = data.filter((item) => item.get("status") !== "draft");
    let totalValue = {
      total_number: contracts.size,
      total_draft_number: data.size - contracts.size,
      total_contract_value: contracts.reduce(
        (total, obj) => obj.get("total_contract_value") + total,
        0,
      ),
      claimed_to_date_value: contracts.reduce(
        (total, obj) => obj.get("claimed_to_date_value") + total,
        0,
      ),
      cert_to_date_value: contracts.reduce(
        (total, obj) => obj.get("cert_to_date_value") + total,
        0,
      ),
      remaining: 0,
    };
    totalValue.remaining =
      totalValue.total_contract_value - totalValue.cert_to_date_value;
    setSummary(totalValue);
  };
  // initial view
  useEffect(() => {
    if (contracts) {
      let display = contracts;
      // filter based by statuses
      display = display.filter((c) => {
        return displayedStatus.includes(c.get("status"));
      });
      setData(display);
      setupSummary(contracts);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (contracts) {
      setupSummary(contracts);

      let displayData = contracts;
      // handle searching, used only for cert/sub contracts
      if (searchString) {
        displayData = displayData.filter(
          (c) =>
            caseInsensitiveIncludes(c.get("name"), searchString) ||
            caseInsensitiveIncludes(
              c.get("payee_company")?.get("entity_name"),
              searchString,
            ) ||
            caseInsensitiveIncludes(c.get("contract_number"), searchString),
        );
      }

      // handle filtering
      if (Object.keys(filterOption).length > 0) {
        displayData = handleFilterStatus(displayData, filterOption);
        displayData = displayData.filter((c) => {
          return filterCertificateDue(
            c.get("calculated_cert_due_days"),
            filterOption,
          );
        });
      }
      setData(displayData);
    }
    // eslint-disable-next-line
  }, [contracts, searchString, filterOption]);

  return (
    <>
      <ContractSummary>
        <div className="header-bar">
          <div className="name">Summary</div>
          {isNotCovered && <ProjectSubsetBadge className="subset-badge" />}
          <div className="complete-info">
            {parsePercent(
              summary.cert_to_date_value,
              summary.total_contract_value,
            )}
          </div>
        </div>
        <div className="info-bar">
          <div className="num">
            {summary.total_number}
            {summary.total_draft_number === 0 ? (
              <></>
            ) : (
              <div className="draft">
                ({summary.total_draft_number} in draft)
              </div>
            )}
          </div>
          <div className="num">
            {currencySubunitToUnit(
              summary.total_contract_value,
              true,
              false,
              true,
            )}
          </div>
          <div className="num">
            {currencySubunitToUnit(
              summary.claimed_to_date_value,
              true,
              false,
              true,
            )}
          </div>
          <div className="num">
            {currencySubunitToUnit(
              summary.cert_to_date_value,
              true,
              false,
              true,
            )}
          </div>
          <div className="num">
            {currencySubunitToUnit(summary.remaining, true, false, true)}
          </div>
          <label>Contracts</label>
          <label>Total contract value</label>
          <label>{accountConfig?.getIn(["claim", "value"])} to date</label>
          <label>{accountConfig?.getIn(["cert", "value"])} to date</label>
          <label>Remaining</label>
        </div>
        <div className="complete-bar">
          <CompleteTooltip
            label="Claim to Date"
            num={summary.claimed_to_date_value}
            placement="bottom-end"
          >
            <div
              className="claim"
              style={{
                width:
                  summary.claimed_to_date_value / summary.total_contract_value >
                  1
                    ? "100%"
                    : parsePercent(
                        summary.claimed_to_date_value,
                        summary.total_contract_value,
                      ),
              }}
            />
          </CompleteTooltip>
          <CompleteTooltip
            label={`${accountConfig?.getIn(["cert", "value"])} to Date`}
            num={summary.cert_to_date_value}
          >
            <div
              className="cert"
              style={{
                width:
                  summary.cert_to_date_value / summary.total_contract_value > 1
                    ? "100%"
                    : parsePercent(
                        summary.cert_to_date_value,
                        summary.total_contract_value,
                      ),
              }}
            />
          </CompleteTooltip>
        </div>
      </ContractSummary>
      <ContractListWrapper>
        <ActionBar
          layout={layout}
          handleChangeLayout={setLayout}
          sortListField={sortField}
          handleSortList={setSortField}
          handleFilter={(value) => setFilterOption(value)}
          handleSearch={(value) => setSearchString(value)}
          sortOption={[
            { label: "Contract Name", value: "name" },
            { label: "Contract Total", value: "total_contract_value" },
            {
              label: `${accountConfig?.getIn(["claim", "value"])} to Date`,
              value: "claimed_to_date_value",
            },
            {
              label: `${accountConfig?.getIn(["cert", "value"])} to Date`,
              value: "cert_to_date_value",
            },
          ]}
          filterOptions={filterOptions}
          from={"project"}
          searchPlaceHolder={"Search contract name/number or Other party name…"}
          hasReset
        />
        {layout === "table" ? (
          <TableView
            data={data}
            accountId={accountId}
            accountConfig={accountConfig}
          />
        ) : (
          <ContractListView
            data={data}
            sortby={sortField}
            accountConfig={accountConfig}
          />
        )}
      </ContractListWrapper>
    </>
  );
});

export default ContractList;
