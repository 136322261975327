import React from "react";
import { Checkbox } from "@material-ui/core";
import BootstrapTooltip from "../toolTip";
import { CheckBoxStyle } from "./styled";

const StyledCheckbox = (props) => {
  const classes = CheckBoxStyle();
  return (
    <div className={classes.checkBoxWrap}>
      <Checkbox
        {...props}
        className={classes.checkbox}
        checked={props.checked}
        onChange={props.onChange}
      />
      <div className={classes.label}>
        <span>{props.label}</span>
        {props.tooltiptext && (
          <BootstrapTooltip title={props.tooltiptext} placement="bottom">
            <div className={classes.icon} />
          </BootstrapTooltip>
        )}
      </div>
    </div>
  );
};

export default StyledCheckbox;
