import styled from "styled-components";
import * as theme from "../../theme";
import delFileIcon from "../../statics/img/icon-clear-medium-grey.svg";
import { IconButton } from "../../common/button";

export const CloseIcon = styled(IconButton)`
  background: url(${delFileIcon}) no-repeat;
  position: absolute;
  right: 12px;
  top: 12px;
`;

export const ProjectBottomWrapper = styled.div`
  width: 100%;
  height: 44px;
  margin: 56px 0;
`;
export const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${theme.palette.lightWolfGrey};
`;

// above is the navbar and bottom

export const SectionWrapper = styled.div`
  width: 100%;
  margin: 56px 0;
  height: auto;
  display: block;
  padding: 40px;
  box-sizing: border-box;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  color: ${theme.palette.gunmetal};
`;
export const Title = styled.div`
  line-height: 40px;
  padding-bottom: 32px;
  font-size: 24px;
  font-weight: ${theme.font.mainFontWeight};
`;
export const Subtitle = styled.div`
  font-size: 16px;
  font-weight: ${theme.font.normalFontWeight};
`;
export const TableFooter = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .totalDiv {
    margin-top: 40px;
    margin-left: auto;
    height: 72px;
    width: 560px;
    background-color: ${theme.palette.tealBlue};
    padding: 9px 16px;
    box-sizing: border-box;
    color: ${theme.palette.white};
    font-weight: ${theme.font.mainFontWeight};
    .titleLeft {
      font-size: 16px;
      line-height: 19px;
    }
    .titleRight {
      font-size: 22px;
      float: right;
      line-height: 25px;
    }
  }
  .notice {
    margin-top: 20px;
    font-size: 14px;
    margin-left: auto;
  }
`;
export const AddItemWrapper = styled.div`
  width: 100%;
  height: 151px;
  padding: 40px;
  box-sizing: border-box;
  background-color: ${theme.palette.veryColdGrey};
  margin-top: 12px;
`;

export const Container = styled.div`
  width: 1183px;
  height: auto;
  margin: 0 auto;
  .page-breadcrumbs {
    width: 100%;
    margin: 24px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .page-breadcrumbs-label {
      margin: 0 !important;
    }
    button {
      min-width: 200px;
    }
  }
  h1 {
    font-weight: bold;
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 8px;
  }
  p {
    line-height: 20px;
  }
`;
