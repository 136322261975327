import styled from "styled-components";
import * as theme from "../../theme";

export const LineWrap = styled.div`
  .title-skeleton {
    width: 100%;
    border-radius: 4px;
  }
  width: ${(props) => props.width || "100%"};
  height: 24px;
  display: flex;
  margin-bottom: 8px;
`;

export const TitleWrap = styled.div`
  .title-skeleton {
    width: 234px;
    border-radius: 4px;
  }
  height: 32px;
  display: flex;
  margin-bottom: -20px;
`;

// projects list card
export const ProjectCardWrap = styled.div`
  height: 252px;
  left: 0px;
  top: 48px;
  background: ${theme.palette.white};
  border-radius: 4px;
  display: flex;
  position: relative;
  margin: 16px 0;

  .image-skeleton {
    position: absolute;
    width: 128px;
    height: 128px;
    left: 32px;
    top: 24px;

    border-radius: 4px;
  }
  .name-skeleton {
    width: 334px;
    height: 24px;
    top: 110px;
    left: 192px;
    position: absolute;
    border-radius: 4px;
  }
  .count-skeleton {
    position: absolute;
    width: 60px;
    height: 36px;
    right: 24px;
    top: 104px;
    border-radius: 4px;
  }
  .lines {
    top: 186px;
    left: 196px;
    position: absolute;
    width: 88%;
    height: 100%;
    display: flex;
  }
  .line-wrap {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .line-skeleton {
    width: 174px;
    height: 20px;
    border-radius: 4px;
  }
  .line-skeleton-1 {
    width: 104.4px;
    height: 16px;
    border-radius: 4px;
    margin-top: 6px;
  }
`;

// component wrap for project/contract overview pages
export const ComponentCardWrap = styled.div`
  height: 400px;
  width: 100%;
  background: ${theme.palette.white};
  border-radius: 4px;
  padding: 40px;

  .title-section {
    display: flex;
    justify-content: space-between;
    height: 40px;

    .button {
      width: 92px;
    }
    .title {
      align-self: center;
      height: 24px;
      width: 334px;
    }
  }

  .content-section {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    .thick-line {
      height: 34px;
      width: 424px;
      margin-bottom: 24px;
    }
    .line {
      height: 16px;
      width: 100%;
      margin-bottom: 10px;
      &.short {
        width: 528px;
      }
    }
  }
`;

export const DetailsCardWrap = styled.div`
  width: 960px;
  height: ${(props) => props.inputHeight || "894px"};
  padding: 40px;

  margin: -148px auto 16px;
  border-radius: 4px;
  background-color: ${theme.palette.white};
  box-sizing: border-box;

  position: relative;
  z-index: 5;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContractDetailsWrap = styled.div`
  // skeleton lines
  .title {
    width: 334px;
    height: 24px;
  }
  .line {
    height: 16px;
    width: 100%;

    &.thick{
      height: 20px;
    }
    &.thin {
      height: 14px;
    }
  }

  .title-section {
    margin-bottom: 173px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      height: 32px;
    }
    .line {
      width: 200px;
    }
  }

  .detail-section {
    height: 580px;
    .summary {
      margin-bottom: 84px;
      height: 146px;
      display: grid;
      grid-row-gap: 24px;
      grid-template-rows: 40px auto 16px;
      .title-bar {
        width: 100%
        display: flex;
        justify-content: space-between;
        .percentage {
          height: 40px;
          width: 60px;
        }
      }
      .values {
        display: flex;
        justify-content: space-between;
        .line.thick {
          width: 180px;
        }
      }
    }

    .other-party {
      height: 350px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .split-section {
        width: 100%;
        height: 266px;
        align-self: center;
        padding: 0 56px;
        display: flex;
        justify-content: space-between;

        .section {
          width: 340px;
          display: inherit;
          flex-direction: column;

          .lines {
            margin-top: 24px;
            height: 68px;
            display: inherit;
            flex-direction: column;
            justify-content: space-between;
          }

          .line {
            width: 100%;
            &.short {
              width: 204px;
            }
            &.very-thick {
              width: 200px;
              height: 48px;
            }
          }
        }
      }
    }
  }
`;

export const ProjectDetailsCardWrap = styled.div`
  height: 300px;
  background:${theme.palette.white};
  border-radius: 4px;
  display: flex;
  margin: 16px 0;

  .image-skeleton {
    position: absolute;
    width: 128px;
    height: 128px;
    left: 38px;
    top: 30px;

    border-radius: 4px;
  }
  .name-skeleton {
    width: 400px;
    height: 36px;
    top: 110px;
    left: 198px;
    position: absolute;
    border-radius: 4px;
  }
  .count-skeleton {
    position: absolute;
    width: 60px;
    height: 36px;
    right: 38px;
    top: 104px;
    border-radius: 4px;
  }

  .map-wrap {
    margin-top: 330px
    height: 282px;
    width: 100%;
    border-radius: 4px;
  }

  .button-wrap {
    margin-top: 200px;
    display: flex;
    justify-content: space-between;

    .delete-button {
      height: 40px;
      width: 180px;
      position: absolute;
      right: 38px;
      border-radius: 4px;
    }
    .archive-button {
      width: 180px;
      height: 40px;
      left: 200px;
      position: absolute;
      border-radius: 4px;
      float:left;
    }
    .setIcon {
      width: 48px;
      height: 40px;
      left: 400px;
      position: absolute;
      border-radius: 4px;
      float:left;
    }
  }
`;
