import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  ClaimHistoryModal,
  ModalPagination,
  BasicTable,
  PaymentLineitem,
  LineItemBtn,
} from "../styled";

import { currencySubunitToUnit } from "../../../../utils/currencyHelper";
import { convertUTCToTime } from "../../../../utils/dateTimeHelper";
import Input from "../../../../common/form";
import PageBarPart from "../../../../common/pagination";
import Modal from "../../../../common/modal/component/modalBase";

const TableLineItem = (props) => {
  const {
    pay,
    update,
    download,
    readOnly,
    accountInfo,
    accountConfig,
    contractInfo,
  } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [file, setFile] = useState({});
  const [paid, setPaid] = useState(0);
  const [removeAttached, setRemoveAttached] = useState(false);

  let timezone =
    contractInfo?.get("time_zone") || accountInfo?.get("time_zone");
  let period = convertUTCToTime(
    pay.claim_period,
    timezone,
    false,
    "dd/MM/yyyy",
  )[0];
  const hiddenFileInput = React.createRef();

  useEffect(() => {
    setPaid(pay.paid_amount / 100);
  }, [pay.paid_amount]);

  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSave = () => {
    setIsEdit(false);
    setFile({});
    update(pay.claim_id, file, paid);
    setRemoveAttached(false);
  };

  const handleCancel = () => {
    setIsEdit(false);
    setFile({});
    setPaid(pay.paid_amount / 100);
    setRemoveAttached(false);
  };

  return (
    <PaymentLineitem className={isEdit ? "edit" : "view"}>
      <td>{pay.claim_number}</td>
      <td className="align-right">
        {currencySubunitToUnit(
          pay.this_claim_with_gst,
          !accountConfig?.get("enable_header_currency"),
          false,
          false,
        )}
      </td>
      <td>{pay.cert_number}</td>
      <td className="align-right">
        {currencySubunitToUnit(
          pay.this_cert_with_gst,
          !accountConfig?.get("enable_header_currency"),
          false,
          false,
        )}
      </td>
      <td>{period}</td>
      <td className="align-right">
        <div className="input">
          {isEdit ? (
            <React.Fragment>
              {!accountConfig?.get("enable_header_currency") &&
                accountConfig?.getIn(["currency", "title"])}
              <Input
                className="paid-input"
                field="number"
                onChange={(e) => setPaid(e.target.value)}
                value={paid}
              />
            </React.Fragment>
          ) : (
            currencySubunitToUnit(
              pay.paid_amount,
              !accountConfig?.get("enable_header_currency"),
              false,
              false,
            )
          )}
        </div>
      </td>
      <td>
        {(isEdit && !file.size && !pay.attach_file_name) ||
        (isEdit && removeAttached && !file.size) ? (
          <div className="files">
            <LineItemBtn className="button view" onClick={handleClick}>
              Upload
            </LineItemBtn>
            <input
              name="Upload"
              type="file"
              ref={hiddenFileInput}
              onChange={(e) => handleFileUpload(e)}
              style={{ display: "none" }}
            />
          </div>
        ) : isEdit && file.size ? (
          <div className="files grid">
            <div className="attached">{file.name}</div>
            <span className="remove" onClick={() => setFile("")} />
          </div>
        ) : isEdit && pay.attach_file_name && !removeAttached ? (
          <div className="files grid">
            <div className="attached">{pay.attach_file_name}</div>
            <span
              className="remove"
              onClick={() => {
                setFile({});
                setRemoveAttached(true);
              }}
            />
          </div>
        ) : !isEdit && pay.attach_file_name !== "" ? (
          <div className="files grid">
            <div className="attached">{pay.attach_file_name}</div>
            <span
              className="download"
              onClick={() => download(pay.attach_file_id, pay.attach_file_name)}
            />
          </div>
        ) : (
          ""
        )}
      </td>
      {!readOnly && (
        <td>
          <div className="actions">
            {isEdit ? (
              <React.Fragment>
                <LineItemBtn
                  className="button save"
                  onClick={() => handleSave()}
                >
                  Save
                </LineItemBtn>
                <LineItemBtn
                  className="button cancel"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </LineItemBtn>
              </React.Fragment>
            ) : (
              <LineItemBtn className="draft">
                <div className="icon" onClick={() => setIsEdit(true)} />
              </LineItemBtn>
            )}
          </div>
        </td>
      )}
    </PaymentLineitem>
  );
};

const PaymentTable = (props) => {
  const {
    payments,
    open,
    handleClose,
    readOnly,
    accountConfig,
    accountInfo,
    contractInfo,
  } = props;
  // pagination stuff
  const [limit, setLimit] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSetLimit = (data) => {
    setCurrentPage(1);
    setLimit(data);
  };
  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title="Payment"
      divider={false}
      width={"960px"}
    >
      <ClaimHistoryModal>
        <div className="comment">
          <div className="icon" />
          These values are the{" "}
          {accountConfig?.getIn(["claim", "noun"]).toLowerCase()}/certificate
          amount less previously certified and less retention (if applicable)
          and plus {accountConfig?.getIn(["gst", "title"])}
        </div>
        <BasicTable>
          <thead>
            <tr>
              <th width="90">{accountConfig?.getIn(["claim", "noun"])} #</th>
              <th width="150" className="align-right">
                This {accountConfig?.getIn(["claim", "noun"])}
                {accountConfig?.get("enable_header_currency") &&
                  ` (${accountConfig?.getIn(["currency", "code"])})`}
              </th>
              <th width="110">{accountConfig?.getIn(["cert", "noun"])} #</th>
              <th width="190" className="align-right">
                This {accountConfig?.getIn(["cert", "noun"])}
                {accountConfig?.get("enable_header_currency") &&
                  ` (${accountConfig?.getIn(["currency", "code"])})`}
              </th>
              <th width="138">Period (End)</th>
              <th width="220" className="align-right">
                Received Amount
                {accountConfig?.get("enable_header_currency") &&
                  ` (${accountConfig?.getIn(["currency", "code"])})`}
              </th>
              <th width="200">Attached Files</th>
              {!readOnly && <th width="90">Actions</th>}
            </tr>
          </thead>

          <tbody>
            {payments.toJS().map((pay, index) => {
              if (
                index >= (currentPage - 1) * limit &&
                index < currentPage * limit
              ) {
                return (
                  <TableLineItem
                    key={index}
                    pay={pay}
                    update={props.update}
                    download={props.download}
                    accountInfo={accountInfo}
                    contractInfo={contractInfo}
                    accountConfig={accountConfig}
                    readOnly={readOnly}
                  />
                );
              } else {
                return null;
              }
            })}
          </tbody>
        </BasicTable>
      </ClaimHistoryModal>
      <ModalPagination>
        <PageBarPart
          total={payments.size}
          limit={limit}
          pageCount={Math.ceil(payments.size / limit)}
          currentPage={currentPage}
          handlePageChange={setCurrentPage}
          handleSetData={handleSetLimit}
          field="fill"
        />
      </ModalPagination>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default connect(mapStateToProps, null)(PaymentTable);
