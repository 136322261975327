import React, { Component } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { UserDropdownList, UserDropdownItems } from "../style";
import RequestForm from "../../modal/component/supportDialog";
import { AntSwitch } from "../../switch";
import BootstrapTooltip from "../../toolTip";

class SupportHub extends Component {
  constructor() {
    super();
    this.state = {
      expand: false,
    };
  }
  openSupportWeb = () => {
    window.open("https://support.asbuiltvault.com/", "_blank");
  };

  render() {
    const { expand } = this.state;
    const {
      openSupportDialog,
      changeValue,
      is_help_desk_mode,
      setHelpDeskMode,
    } = this.props;
    return (
      <ClickAwayListener onClickAway={() => this.setState({ expand: false })}>
        <UserDropdownList>
          <div
            onClick={() => {
              this.setState({ expand: !this.state.expand });
            }}
            className="supportIcon"
          />
          <UserDropdownItems visible={expand ? "visible" : "hidden"}>
            <div
              className="dropdownDetail"
              onClick={() =>
                this.setState({ expand: false }, this.openSupportWeb)
              }
            >
              <div className="icon hubIcon" />
              Support Hub
            </div>
            <div
              className="dropdownDetail"
              onClick={() => {
                this.setState({ expand: false });
                changeValue("openSupportDialog", true);
              }}
            >
              <div className="icon formIcon" />
              Request Support
            </div>

            <div className="line" />
            <div className="dropdownDetail switch">
              <AntSwitch
                checked={is_help_desk_mode}
                onChange={(event) => {
                  setHelpDeskMode(event);
                }}
                name="is_help_desk_mode"
              />
              <div className="label">Help Desk Mode</div>
              <BootstrapTooltip
                title={
                  "This mode enables remote control for our support team, allowing access to your account."
                }
                placement="bottom"
              >
                <div className="icon hoverIcon" />
              </BootstrapTooltip>
              <div className="label secondary_label">
                Only enable this mode whilst you are talking with our support
                team.
              </div>
            </div>
            <RequestForm
              open={openSupportDialog}
              closeDialog={() => changeValue("openSupportDialog", false)}
            />
          </UserDropdownItems>
        </UserDropdownList>
      </ClickAwayListener>
    );
  }
}

export default SupportHub;
