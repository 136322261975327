import React from "react";
import { YellowBtn } from "../../common/button";
import DropdownCountry from "common/dropdown/dropdown_with_country";

const PreSignUp = (props) => {
  const { form, handleChange, nextStep, countriesList } = props;

  return (
    <>
      <div className="title">Create A New Account</div>
      <form>
        <label className="text-header">Select your country</label>
        <DropdownCountry
          country={form.country}
          handleChange={handleChange}
          countriesList={countriesList}
        />
        <div className="hasMargin">
          <ul className="bullets">
            <li>
              This is the country where construction work is taking place.
            </li>
            <li>Determines the regulations that contracts will abide to.</li>
            <li>
              Account subscription via Stripe will be tied to this country and
              currency.
            </li>
            <li>Cannot be changed later.</li>
          </ul>
        </div>
        <div className="hasMargin">
          <label className="text-header">Note:</label>
          <p>
            If you carry out work in more than one country, you should create
            multiple accounts with different countries. You can do this with the
            same email address.
          </p>
        </div>
        <YellowBtn
          title="Next"
          submitting={false}
          onClick={(e) => nextStep(e)}
          className="full-width"
        />
      </form>
    </>
  );
};

export default PreSignUp;
