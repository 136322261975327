import React, { useState, useEffect } from "react";
import Link from "../../../../common/linkWithQuery";
import { useSelector, connect } from "react-redux";

import { BgWrapper, HeaderTitle } from "../../../../common/shared_style";
import { FormWrapper, RoleWrapper } from "./styled";
import { BottomWrapper } from "../../component/style";
import {
  BlueOutlinedBtn as Cancel,
  GreenWhiteBtn as Save,
} from "../../../../common/button";
import Menu from "../../../../common/breadcrumbs";

import API from "../../../../server";
import history from "../../../../utils/history";

function AccountRole(props) {
  const { accountConfig } = props;
  const [accountRole, setAccountRole] = useState("");
  const claimTerm = accountConfig.get("claim");
  const accountId = useSelector((state) =>
    state.getIn(["headers", "profile", "payclaim_account_id"]),
  );
  const updateInfo = (role) => {
    API.update_account_detail({ role: role }).then((res) => {
      history.push(`/manage-account/view?account_id=${accountId}`);
    });
  };
  useEffect(() => {
    API.read_account_detail()
      .then((res) => res.data.data)
      .then((res) => {
        setAccountRole(res.data.role);
      });
  }, []);

  const RoleMeg = [
    {
      name: `${claimTerm?.get("ongoing_verb")} Only`,
      image: "claim",
      meg: `Projects will only have a single contract that your organisation makes ${claimTerm
        ?.get("noun")
        .toLowerCase()}s against`,
      roles: [
        {
          name: "Subcontractor",
          id: "subcontractor",
          meg: "Subcontractor means a person who is to carry out construction work or supply related goods and services under a construction contract otherwise than as head contractor.",
        },
        {
          name: "Consultant",
          id: "consultant",
          meg: "Consultants are professionals, typically, appointed by the client to perform expert tasks on a project. This is including Architect, Cost consultant, Services engineer, Structural engineer.",
        },
        {
          name: "Client Project Manager",
          id: "client_project_manager",
          meg: "A specialist advisor that represents the client and is responsible for the day-to-day management of a project.",
        },
      ],
    },
    {
      name: "Certifying Only",
      image: "cert",
      meg: "Projects will only have contracts that your organisation certifies",
      roles: [
        {
          name: "Owner / Developer",
          id: "owner",
          meg: "Entity, individual or organisation commissioning and funding the project, directly or indirectly.",
        },
        {
          name: "Home Builder",
          id: "home_builder",
          meg: "Building contractor that specialising in building houses. ",
        },
      ],
    },
    {
      name: `Certifying and ${claimTerm?.get("ongoing_verb")}`,
      image: "both",
      meg: `Projects will have a mixture of contracts that your organisation makes ${claimTerm
        ?.get("noun")
        .toLowerCase()}s and certifies`,
      roles: [
        {
          name: "Head / Main / General Contractor",
          id: "general_contractor",
          meg: "Head contractor means the person who is to carry out construction work or supply related goods and services for the principal under a construction contract (the main contract) and for whom construction work is to be carried out or related goods and services supplied under a construction contract as part of or incidental to the work or goods and services carried out or supplied under the main contract.",
        },
      ],
    },
  ];

  return (
    <BgWrapper>
      <Menu
        link={[{ link: "/manage-account", text: "Manage Account" }]}
        content="Change Organisation Type"
      />
      <HeaderTitle>Organisation Type</HeaderTitle>
      <div style={{ marginTop: "-40px" }}>
        Select what type of organisation you belong to. This determines the
        types of projects that will be created by default.
      </div>
      <FormWrapper>
        {RoleMeg.map((role) => (
          <RoleWrapper img={role.image} key={role.name}>
            <div className="role-header">
              <div className="role-image" />
              <div className="role-info">
                <div className="title">{role.name}</div>
                <div>{role.meg}</div>
              </div>
            </div>
            {role.roles.map((ele) => (
              <div
                className={
                  "role-selection " + (ele.id === accountRole ? "selected" : "")
                }
                key={ele.id}
                onClick={() => setAccountRole(ele.id)}
              >
                <div className="title">
                  {ele.name}{" "}
                  {ele.id === accountRole && <div className="selIcon" />}
                </div>
                <div>{ele.meg}</div>
              </div>
            ))}
          </RoleWrapper>
        ))}
      </FormWrapper>
      <BottomWrapper>
        <Link to={"/manage-account"}>
          <Cancel title="CANCEL" margin="0 30px 0 0" padding="10px 34px" />
        </Link>
        <Save
          title="SAVE"
          padding="10px 41px"
          onClick={() => updateInfo(accountRole)}
        />
      </BottomWrapper>
    </BgWrapper>
  );
}

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default connect(mapStateToProps, null)(AccountRole);
