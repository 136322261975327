import styled from "styled-components";
import * as theme from "theme";

import claimDueIcon from "statics/img/claim_due_diamond.svg";
import certDueIcon from "statics/img/cert_due_diamond.svg";
import payDueIcon from "statics/img/pay_due_diamond.svg";
import errorIcon from "statics/img/icon-information-red.svg";

export const PaymentTermStyle = styled.div`
  h3 {
    margin: 0 0 8px 0;
  }

  .input-fields {
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px 0;
  }

  .days-input,
  .blocked-input {
    width: 60px;
    text-align: center;
    margin: 0 8px 0 0;
  }

  .blocked-input {
    border: solid 1px ${theme.palette.lightWolfGrey};
    border-radius: 4px;
    background-color: ${theme.palette.greyWhite};
  }

  .error {
    width: 100%;
    height: 16px;
    margin-bottom: 16px;
    color: ${theme.palette.watermelonRed};
    font-size: 16px;
  }
`;

export const DueDatesDialog = styled.div`
  box-sizing: border-box;
  width: 880px;
  padding: 12px 16px;
  background-color: ${theme.palette.lightPastelBlue};
  border-radius: 4px;
  line-height: 19px;
  color: ${theme.palette.tealBlue};

  &.error {
    background-color: ${theme.palette.lightPastelPink};
    color: ${theme.palette.gunmetal};
    display: flex;
  }

  .bold {
    font-weight: ${theme.font.mainFontWeight};
  }

  .error-icon {
    width: 20px;
    height: 20px;
    background-image: url(${errorIcon});
    background-size: 20px 20px;
    margin: 0 18px 0 0;
  }
`;

export const PaymentDueModalDescription = styled.p`
  margin: 0 0 32px 0;
`;

export const ScheduleBars = styled.div`
  height: 187px;
  width: 100%;
  margin: 40px 0 0 0;
  display: grid;
  grid-template-columns: repeat(3, 288px);
  column-gap: 8px;

  .bar-container {
    display: flex;
    align-items: center;
  }
`;

export const ProgressBarStyle = styled.div`
  height: 32px;
  width: 100%;
  background-color: ${theme.palette.veryColdGrey};
  padding: 0 6px;

  .icons {
    width: calc(100% - 38px);
    height: 65px;

    position: relative;
    top: -16.5px;

    display: flex;
    justify-content: space-between;

    .icon-holder {
      width: 3px;
      height: 65px;
      position: absolute;

      .icon {
        position: relative;
        width: 41px;
        height: 100%;

        &.claim {
          background: url(${claimDueIcon});
          z-index: 3;
        }
        &.cert {
          background: url(${certDueIcon});
          z-index: 2;
        }
        &.pay {
          background: url(${payDueIcon});
        }
        &.offset-right {
          top: -65px;
          left: 42px;
        }
      }
      .icon:hover {
        z-index: 4; // cert/pay diamond will popup temporarily
      }

      .description {
        position: absolute;
        &.up {
          top: -78px;
        }
        &.left {
          left: -46px;
          width: 110px;
          &.start {
            left: -65px;
          }
        }
        &.right {
          left: 26px;
          &.end {
            left: 9px;
          }
        }
        &.plus {
          left: 0px;
        }

        &.end {
          left: -46px;
          width: 88px;
        }
        left: -45.5px;
        margin: 10px 0 0 0;
        width: 132px;
        font-size: 14px;
        text-align: center;
        line-height: 17px;

        .description-heading {
          font-weight: bold;

          &.claim {
            color: ${theme.palette.brightGreen};
          }
          &.cert {
            color: ${theme.palette.tealBlue};
          }
          &.pay {
            color: ${theme.palette.tangerineOrange};
          }
        }

        .bold {
          font-weight: bold;
        }
      }
    }
  }
`;
