import { httpRequest } from "../base/httpRequest";

const CONTRACT_URL = `/api/contract/account/accId/contract_group`;

const upsert_one_lineitem = (proId, contractId, data, type, config) =>
  httpRequest(
    "POST",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/lineitem?type=${type}`,
    data,
    config,
    { "Content-Type": "multipart/form-data" },
  );

const update_one_lineitem = (proId, contractId, itemId, data) =>
  httpRequest(
    "PATCH",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/lineitem/${itemId}`,
    data,
  );

const create_one_lineitem_child = (proId, contractId, itemId, data, config) =>
  httpRequest(
    "post",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/lineitem/${itemId}/childitem`,
    data,
    config,
    { "Content-Type": "multipart/form-data" },
  );

const delete_one_lineitem = (proId, contractId, itemId) =>
  httpRequest(
    "delete",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/lineitem/${itemId}`,
  );

const upload_one_file = (proId, contractId, itemId, data, config) =>
  httpRequest(
    "post",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/lineitem/${itemId}/uploadfile`,
    data,
    config,
    { "Content-Type": "multipart/form-data" },
  );

const read_line_item_files = (proId, contractId, itemId) =>
  httpRequest(
    "get",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/lineitem/${itemId}/files`,
  );

const delete_one_file = (proId, contractId, itemId, fileId) =>
  httpRequest(
    "delete",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/lineitem/${itemId}/fileitem/${fileId}`,
  ); //file

// * line item
const import_line_items = (proId, contractId, data, config) =>
  httpRequest(
    "post",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/lineitem/template/upload`,
    data,
    config,
    { "Content-Type": "multipart/form-data" },
  );

// line item comments
const create_lineitem_comment = (proId, contractId, lineitemId, data) =>
  httpRequest(
    "POST",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/lineitem/${lineitemId}/comment`,
    data,
  );

const update_lineitem_comment = (proId, contractId, lineitemId, data) =>
  httpRequest(
    "PUT",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/lineitem/${lineitemId}/comment`,
    data,
  );

const delete_lineitem_comment = (proId, contractId, lineitemId, data) =>
  httpRequest(
    "DELETE",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/lineitem/${lineitemId}/comment`,
    data,
  );

const upload_csv_file = (proId, contractId, data, config) =>
  httpRequest(
    "post",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/lineitem/template`,
    data,
    config,
    { "Content-Type": "multipart/form-data" },
  ); //blob

const read_contract_lineitem_history = (
  proId,
  contractId,
  page,
  pageSize,
  lineId,
) =>
  httpRequest(
    "get",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/lineitem/${lineId}/history?page=${page}&limit=${pageSize}`,
  );

const export_contract_lineitem_history = (proId, contractId, lineId, config) =>
  httpRequest(
    "GET",
    `${CONTRACT_URL}/${proId}/contract/${contractId}/lineitem/${lineId}/history/export`,
    null,
    config,
  );

export default {
  upsert_one_lineitem,
  update_one_lineitem,
  create_one_lineitem_child,
  delete_one_lineitem,
  upload_one_file,
  read_line_item_files,
  delete_one_file,
  import_line_items,
  upload_csv_file,
  create_lineitem_comment,
  update_lineitem_comment,
  delete_lineitem_comment,
  read_contract_lineitem_history,
  export_contract_lineitem_history,
};
