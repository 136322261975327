import React, { Component } from "react";
import { connect } from "react-redux";
import { fromJS } from "immutable";

import AddressSearch from "../../../common/mapbox/addressSearch";
import {
  Container,
  ProjectBackground,
  EditImg,
  ProjectDetailWrapper,
  LinkLabel,
  AdditionalWrapper,
  CloseIcon,
  CheckBoxStyle,
} from "../styled";

import { GreenWhiteBtn as Button } from "../../../common/button";
import Input from "../../../common/form";
import profile from "../../../utils/profile";
import { CREATE, PROJECT, COUNTRY_AU } from "../../../utils/constants";

import UploadImageModal from "../components/uploadImageModal";

import { actionCreators } from "../store";
import { actionCreators as actionCreatorsModal } from "../../../common/modal";
import { actionCreators as configActionCreators } from "../../../common/config/store";
import { actionCreators as projectActionCreators } from "../../projects/store";
import { createNewUUID } from "../../contract/store/actionCreators";
import MapboxDisplay from "../../../common/mapbox/mapboxDisplay";
import { StateWarningModal } from "../../../common/config/stateConfigInput";
import ErrorMsg from "../../../common/missingRequiredBanner";
import {
  ToggleButtons,
  LinkSelection,
} from "../../contract/common/integration";
import API from "../../../server";

const CheckBox = (props) => (
  <CheckBoxStyle>
    <input {...props} id="checkbox" />
    <label className={props.className} htmlFor="checkbox">
      Duplicate setting from another project
    </label>
  </CheckBoxStyle>
);

const requiredFromIntegration = {
  name: { locked: true, required: "name" },
  street_address: { locked: true, required: "street address" },
  suburb: { locked: true },
  city: { locked: true },
  postcode: { locked: true },
  state: { locked: true },
};

const lockedFields = Object.entries(requiredFromIntegration)
  .filter(([key, value]) => value.locked)
  .map(([key]) => key);

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDes: false,
      openAddDes: false,
      project: {
        name: "",
        description: "",
        street_address: "",
        suburb: "",
        city: "",
        postcode: "",
        state: "",
        country: "",
        gst: null,
        project_number: "",
        vault_url: "",
        cover_image: "",
        avatar_image: "",
        id: "",
        contractId: "",
      },
      hasSaved: false,
      errors: {},
      cropImgVisible: false,

      uploadImage: null,
      isCover: false,

      avatarImage: null,
      coverImage: null,
      avatarImageData: null,
      coverImageData: null,

      openDuplicate: false,
      duplicate: "",

      linked: "",
      linkProjectType: "new",
      loadIntegration: false,
      integratedProjects: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGetResultImgUrl = this.handleGetResultImgUrl.bind(this);
    this.cancelAddDes = this.cancelAddDes.bind(this);
    this.setTargetAddress = this.setTargetAddress.bind(this);

    this.modalErrorsRef = React.createRef();
    this.streetAddressRef = React.createRef();
    this.stateRef = React.createRef();
    this.nameRef = React.createRef();
  }

  async componentDidMount() {
    this.props.getCountries();
    this.props.readProjects();
    const projectResult = await createNewUUID();
    this.setState((prevState) => ({
      project: {
        ...prevState.project,
        id: projectResult,
      },
    }));
    const contractResult = await createNewUUID();
    this.setState((prevState) => ({
      project: {
        ...prevState.project,
        contractId: contractResult,
      },
    }));
  }

  getErrorFields() {
    const { errors, hasSaved } = this.state;
    const { accountConfig } = this.props;
    return [
      {
        field: "name",
        name: "Project Name",
        ref: this.nameRef,
        missing: hasSaved && errors.name ? errors.name : "",
      },
      {
        field: "street_address",
        name: accountConfig.getIn(["address", "street"]),
        ref: this.streetAddressRef,
        missing: hasSaved && errors.street_address ? errors.street_address : "",
      },
      {
        field: "state",
        name: accountConfig.getIn(["address", "state"]),
        ref: this.stateRef,
        missing: hasSaved && errors.state ? errors.state : "",
      },
    ];
  }

  handleChange(e, field) {
    const fieldName = field || e.target.name;
    let value;

    if (field === "gst") {
      value = e;
    } else if (e?.value) {
      value = e.value;
    } else {
      value = e.target.value;
    }

    this.setState((prevState) => {
      // Clear the error for the field being changed
      const errors = { ...prevState.errors };
      if (errors[fieldName]) {
        delete errors[fieldName];
      }
      return {
        project: {
          ...prevState.project,
          [fieldName]: value,
        },
        errors,
      };
    });
  }

  handleImageChange = (e) => {
    const file = e.target.files[0];
    const SIZE_LIMIT = 2 * 1024 * 1024;
    if (file.type === "image/png" || file.type === "image/jpeg") {
      if (file.size < SIZE_LIMIT) {
        this.setState({ uploadImage: file }, () => {
          this.setState({ cropImgVisible: true });
        });
      } else {
        this.props.showWarning(
          "Error!",
          "The file is too big! Please choose another one.",
        );
      }
    } else {
      this.props.showWarning(
        "Invalid upload!",
        "Only png or jpg files allowed.",
      );
    }
  };

  handleGetResultImgUrl = (key, blob) => {
    const fileUrl = URL.createObjectURL(blob);
    this.setState({ [key]: fileUrl, [key + "Data"]: blob });
  };

  validateField(field) {
    const { accountConfig } = this.props;
    const { project } = this.state;
    let newErr = "";

    if (field === "state") {
      if (accountConfig.getIn(["country", "code"]) === COUNTRY_AU) {
        newErr = project[field] ? "" : "Required";
      }
    } else {
      newErr = project[field] ? "" : "Required";
    }
    return newErr;
  }

  handleSubmit(e) {
    e.preventDefault();

    let checkFields = ["name", "state", "street_address"];
    let errs = { ...this.state.errors };
    checkFields.forEach((field) => {
      errs[field] = this.validateField(field);
    });
    this.setState({ hasSaved: true, errors: errs });

    const { linkProjectType, linked } = this.state;
    let missingFields = this.checkMissingFields();
    if (linkProjectType === "link" && linked && missingFields.length !== 0) {
      this.props.showWarning(
        "Error!",
        `Before linking this project, you need to populate the ${missingFields.join(
          ", ",
        )} in the IPM.`,
      );
      return;
    }

    if (!this.hasValidationErrors(errs)) {
      this.props.createNewProject(
        this.state.project,
        this.state.avatarImageData,
        this.state.coverImageData,
        this.state.duplicate,
        this.state.linked || null,
      );
    }
  }

  checkMissingFields = () => {
    const { projectInfo } = this.props;
    const missingFields = [];

    for (var [k, v] of Object.entries(requiredFromIntegration)) {
      if (!projectInfo?.get(k) && v.required) {
        missingFields.push(v.required);
      }
    }
    return missingFields;
  };

  hasValidationErrors = (errs) => {
    return Object.values(errs).some((error) => error);
  };

  cancelAddDes(descriptionType) {
    if (descriptionType === "additional") {
      !this.state.linked &&
        this.setState({
          openAddDes: false,
          project: {
            ...this.state.project,
            gst: null,
            project_number: "",
            vault_url: "",
          },
        });
      this.state.linked &&
        this.setState({
          openAddDes: false,
          project: {
            ...this.state.project,
            gst: this.props.projectInfo.get("gst"),
            project_number: this.props.projectInfo.get("project_number") || "",
            vault_url: this.props.projectInfo.get("vault_url") || "",
          },
        });
    } else {
      this.setState({
        openDes: false,
        project: { ...this.state.project, description: "" },
      });
    }
  }

  setTargetAddress(address) {
    const isAddressChanged = Object.keys(address).some(
      (key) => this.state.project[key] !== address[key],
    );

    if (isAddressChanged) {
      this.setState(
        // save searched address and delete street_address error
        (prevState) => {
          let errors = { ...prevState?.errors };
          if (errors["street_address"]) {
            delete errors["street_address"];
          }
          return {
            project: { ...this.state.project, ...address },
            errors,
          };
        },
      );
    }
  }

  handleCheckbox(e) {
    if (!e.target.checked) {
      this.setState({ duplicate: "" });
    }
    this.setState({ openDuplicate: e.target.checked });
  }

  readIntegrationProjects = () => {
    this.setState({ loadIntegration: true });
    API.read_ipm_projects()
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        this.setState({ loadIntegration: false, integratedProjects: res });
      });
  };

  switchLinkProjectType = (type) => {
    this.setState({
      linked: "",
      linkProjectType: type,
      errors: {},
    });
    if (type === "link") {
      this.state.integratedProjects.length === 0 &&
        this.readIntegrationProjects();
    }

    if (type === "new") {
      this.setState({
        project: {
          name: "",
          description: "",
          street_address: "",
          suburb: "",
          city: "",
          postcode: "",
          state: "",
          country: "",
          gst: null,
          project_number: "",
          vault_url: "",
          cover_image: "",
          avatar_image: "",
          id: "",
          contractId: "",
        },
      });
    }
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.projectInfo !== nextProps.projectInfo) {
      let data = nextProps.projectInfo ? nextProps.projectInfo.toJS() : [];
      nextProps.projectInfo &&
        this.setState({
          project: {
            ...data,
            country: data.country || this.props.accountInfo?.get("country"),
          },
        });
      if (data.description) {
        this.setState({ openDes: true });
      }
    }
    return true;
  }

  render() {
    const {
      openDes,
      openAddDes,
      openDuplicate,
      errors,
      cropImgVisible,
      isCover,
      uploadImage,
      avatarImage,
      coverImage,
      project,
      duplicate,

      linked,
      linkProjectType,
      integratedProjects,
      loadIntegration,
    } = this.state;

    const hasMapService =
      localStorage.getItem("MAPBOX_TOKEN") !== null &&
      localStorage.getItem("MAPBOX_TOKEN") !== "";
    const { accountConfig, allProjects, loading, hasIntegration, projectInfo } =
      this.props;

    const createInputField = (label, name, css, options = {}) => (
      <Input
        label={label}
        name={name}
        className={`input-field${css}`}
        onChange={this.handleChange}
        error={this.state.errors?.[name]}
        value={project[name] || ""}
        {...options}
        field={
          linked &&
          name !== "country" &&
          (projectInfo.get(name) || lockedFields.includes(name))
            ? "link-field"
            : options.field
        }
      />
    );

    const EditImageContainer = ({ className, fileField }) => {
      let inputref;
      return (
        <>
          <input
            type="file"
            accept="image/jpeg,image/jpg,image/png"
            ref={(ref) => (inputref = ref)}
            onChange={(e) => {
              this.setState({ isCover: fileField.includes("cover") });
              this.handleImageChange(e);
            }}
            style={{ display: "none" }}
          />
          <EditImg onClick={() => inputref.click()} className={className} />
        </>
      );
    };

    let projects = [];
    allProjects.map((p) =>
      projects.push({ value: p.get("id"), label: p.get("name") }),
    );
    return (
      <Container>
        {cropImgVisible && (
          <UploadImageModal
            uploadedImageFile={uploadImage}
            onClose={() => this.setState({ cropImgVisible: false })}
            onSubmit={(blob) =>
              this.handleGetResultImgUrl(
                isCover ? "coverImage" : "avatarImage",
                blob,
              )
            }
          />
        )}
        <ProjectBackground src={coverImage}>
          <EditImageContainer fileField="cover" key="cover" />
        </ProjectBackground>
        <ProjectDetailWrapper
          ref={this.modalErrorsRef}
          projectImage={avatarImage}
          linked={linked !== ""}
        >
          <ErrorMsg fieldsAndRefs={this.getErrorFields()} />

          {hasIntegration && (
            <ToggleButtons
              setValue={(type) => this.switchLinkProjectType(type)}
            />
          )}

          {linkProjectType === "link" && (
            <LinkSelection
              from="project"
              linked={linked}
              list={fromJS(integratedProjects || [])}
              setLinkInfo={(id) => {
                this.setState({ linked: id });
                id && this.props.readIntegratedProject(id);
              }}
              loading={loadIntegration}
              linkedName={projectInfo?.get("name")}
            />
          )}

          {(linkProjectType !== "link" || linked) && (
            <>
              <div className="avatar">
                <EditImageContainer
                  className="bottom-position"
                  fileField="avatar"
                  key="project"
                />
              </div>
              <>
                <h3>Project Name</h3>
                {createInputField(null, "name", "_name", {
                  placeholder: "Untitled Project",
                  required: true,
                  noRequiredStar: true,
                  justifyContent: "center",
                  onBlur: () => {
                    this.setState({
                      errors: { ...errors, name: this.validateField("name") },
                    });
                  },
                })}
              </>

              {openDes ? (
                <div className="des-wrapper">
                  {createInputField(null, "description", "_description", {
                    placeholder: "Add description for the project",
                  })}
                  {!(linked && projectInfo?.get("description")) && (
                    <CloseIcon
                      onClick={this.cancelAddDes}
                      title="remove description"
                    />
                  )}
                </div>
              ) : (
                <LinkLabel onClick={() => this.setState({ openDes: true })}>
                  Add Description (Optional)
                </LinkLabel>
              )}

              <AdditionalWrapper>
                <h3>Project Address</h3>
                {hasMapService && !linked && (
                  <>
                    <MapboxDisplay setAddress={this.setTargetAddress}>
                      <AddressSearch
                        hasRestrictCountry
                        setAddress={(data) => this.setTargetAddress(data)}
                      />
                    </MapboxDisplay>
                  </>
                )}
                {createInputField(
                  accountConfig.getIn(["address", "street"]),
                  "street_address",
                  "",
                  {
                    ref: this.streetAddressRef,
                    onBlur: () => {
                      this.setState({
                        errors: {
                          ...errors,
                          street_address: this.validateField("street_address"),
                        },
                      });
                    },
                    marginBottom: "24px",
                    required: true,
                  },
                )}
                <div className="flex-row">
                  {createInputField(
                    accountConfig.getIn(["address", "suburb"]),
                    "suburb",
                    " middle",
                  )}
                  {createInputField(
                    accountConfig.getIn(["address", "city"]),
                    "city",
                    " middle",
                  )}
                </div>
                <div className="flex-row">
                  {createInputField(
                    accountConfig.getIn(["address", "postcode"]),
                    "postcode",
                    " small",
                  )}
                  <span ref={this.stateRef}>
                    {project.country !== COUNTRY_AU ? (
                      <>
                        {createInputField(
                          accountConfig.getIn(["address", "state"]),
                          "state",
                          " small",
                        )}
                      </>
                    ) : (
                      <div
                        onBlur={() => {
                          this.setState({
                            errors: {
                              ...errors,
                              state: this.validateField("state"),
                            },
                          });
                        }}
                      >
                        <div
                          className={
                            "has_margin " +
                            `${errors.state ? "" : "margin-bottom"}`
                          }
                        >
                          {createInputField(
                            accountConfig.getIn(["address", "state"]),
                            "state",
                            " small",
                            {
                              customClassName: errors.state
                                ? ""
                                : "margin-bottom",
                              placeholder: `Select ${accountConfig.getIn(["address", "state"])}`,
                              onChange: (e) => this.handleChange(e, "state"),
                              options: accountConfig.get("state")?.toJS(),
                              field: "dropdown",
                              width: 256,
                              required: true,
                              noRequiredStar: true,
                            },
                          )}
                        </div>
                      </div>
                    )}
                  </span>
                  {createInputField(null, "country", " small", {
                    field: "lock-country",
                    width: 272,
                  })}
                </div>
                <StateWarningModal
                  country={project.country}
                  state={project.state}
                />
              </AdditionalWrapper>

              {openAddDes ? (
                <AdditionalWrapper>
                  <div className="flex-row">
                    <h3>Additional Details</h3>
                    <CloseIcon
                      onClick={() => this.cancelAddDes("additional")}
                      title="remove additional description"
                    />
                  </div>
                  <div className="flex-row">
                    {createInputField(
                      "Project Number",
                      "project_number",
                      " middle",
                      {
                        placeholder: "Enter number",
                      },
                    )}
                    {createInputField(
                      accountConfig?.getIn(["gst_rate", "title"]),
                      "gst",
                      " middle number",
                      {
                        placeholder:
                          accountConfig?.getIn(["gst_rate", "value"]) + "%",
                        field: "number",
                        decimalScale: 0,
                        onValueChange: (e) =>
                          this.handleChange(e.floatValue, "gst"),
                        isAllowed: (values) => {
                          const { formattedValue, floatValue } = values;
                          return formattedValue === "" || floatValue <= 100;
                        },
                      },
                    )}
                  </div>
                  {createInputField("Vault Asset URL", "vault_url", "", {
                    placeholder: "Type or paste URL",
                    field: "textarea",
                  })}
                </AdditionalWrapper>
              ) : (
                <LinkLabel onClick={() => this.setState({ openAddDes: true })}>
                  Add Additional Details (Optional)
                </LinkLabel>
              )}

              {!linked &&
                !loading &&
                allProjects.size > 0 &&
                !openDuplicate && (
                  <CheckBox
                    className="margin-bottom"
                    type="checkbox"
                    checked={openDuplicate}
                    onChange={(e) => this.handleCheckbox(e)}
                  />
                )}

              {openDuplicate && (
                <AdditionalWrapper className="duplicate-project">
                  <CheckBox
                    type="checkbox"
                    checked={openDuplicate}
                    onChange={(e) => this.handleCheckbox(e)}
                  />

                  <Input
                    field="dropdown"
                    width="830px"
                    placeholder="Select Project"
                    options={projects}
                    value={duplicate}
                    onChange={(e) => this.setState({ duplicate: e.value })}
                  />
                </AdditionalWrapper>
              )}

              {profile.checkPermission(PROJECT, CREATE) && (
                <Button
                  title={!linked ? "Create New Project" : "Link Project"}
                  onClick={this.handleSubmit}
                />
              )}
            </>
          )}
        </ProjectDetailWrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.getIn(["headers", "accountRole"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
  allProjects: state.getIn(["projects", "allProjects"]),
  loading: state.getIn(["projects", "loading"]),
  hasIntegration: state.getIn(["manageAccount", "integration"]),
  accountInfo: state.getIn(["manageAccount", "accountInfo"]),
  integratedProjects: state.getIn(["newContractGroup", "integratedProjects"]),
  projectInfo: state.getIn(["newContractGroup", "projectInfo"]),
});

const mapDispatchToProps = (dispatch) => ({
  showWarning(title, message) {
    dispatch(
      actionCreatorsModal.showModal("alert", {
        open: true,
        title: title,
        message: message,
      }),
    );
  },
  createNewProject(projectInfo, avatarImage, coverImage, duplicate, linkedId) {
    dispatch(
      actionCreators.createNewProject(
        projectInfo,
        avatarImage,
        coverImage,
        duplicate,
        linkedId,
      ),
    );
  },
  getCountries() {
    dispatch(configActionCreators.readCountries());
  },
  readProjects() {
    dispatch(projectActionCreators.readProjects());
  },
  readIntegratedProject(proId) {
    dispatch(actionCreators.readIntegratedProject(proId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
