import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Container, SectionWrapper } from "./styled";

import Table from "../../common/lineitem/baseTable";
import { BlueOutlinedBtn as ButtonBlue } from "../../common/button";
import UploadDialog from "../../common/lineitem/csvUploadDialog";
import Menu from "../../common/breadcrumbs";
import Link from "../../common/linkWithQuery";

import { actionCreators } from "./store";
import { actionCreators as actionCreatorsContract } from "../contract/store";
import { actionCreators as actionCreatorsFile } from "../../common/upload_file_progress/store";

const BaseContract = (props) => {
  const {
    baseContractTable,
    addingItem,
    files,
    uploadFiles,
    projectInfo,
    contractInfo,

    handleEditItem,
    addNewRow,
    handleDeleteItem,
    fileDelete,
    readContract,
    resetBaseStatus,
  } = props;
  const { proId, type, contractId } = props.match.params;

  const previousURL = `/contract/${proId}/${type}/${contractId}`;
  const link = [
    { link: "/projects", text: "Projects" },
    { link: `/project/view/${proId}`, text: projectInfo.get("name") },
    { link: previousURL, text: contractInfo.get("name") },
  ];
  const headers = "Base Contract";

  // on mount
  useEffect(() => {
    readContract(proId, contractId);
    // reset on unmount
    return () => {
      resetBaseStatus();
    };
  }, []); // eslint-disable-line

  return (
    <Container>
      <div className="page-breadcrumbs">
        <Menu
          link={link}
          content={headers}
          className="page-breadcrumbs-label"
        />
        <Link to={previousURL}>
          <ButtonBlue title="Back To Contract" />
        </Link>
      </div>
      <h1>{headers}</h1>
      <p>Set up {headers} Items.</p>

      <SectionWrapper>
        <Table
          tableSubtitle="Add items to the base contract."
          tableCaption={headers + " Items"}
          tableContent={baseContractTable.toJS()}
          deleteItem={(lineId) => handleDeleteItem(proId, contractId, lineId)}
          handleSaveEdit={(list, lineId) =>
            handleEditItem(proId, contractId, lineId, list)
          }
          addChildRow={(parentId, lineType, newRow) =>
            addNewRow(
              proId,
              contractId,
              newRow,
              files,
              parentId,
              baseContractTable,
              lineType,
            )
          }
          addRow={(lineType, newRow) =>
            addNewRow(
              proId,
              contractId,
              newRow,
              files,
              "",
              baseContractTable,
              lineType,
            )
          }
          addInputStatus={addingItem}
          // files
          handleUploadFile={(lineId, file) =>
            uploadFiles(proId, contractId, lineId, file)
          }
          handleFileDelete={(lineId, fileId) =>
            fileDelete(proId, contractId, lineId, fileId)
          }
          proId={proId}
          contractId={contractId}
          {...props}
        >
          <UploadDialog
            type="baseContract"
            proId={proId}
            contractId={contractId}
            resetInfo={() => readContract(proId, contractId)}
          />
        </Table>
      </SectionWrapper>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  baseContractTable: state.getIn(["basecontract", "baseContractTable"]),
  baseContractTotal: state.getIn(["basecontract", "baseContractTotal"]),
  redirectToProjects: state.getIn(["basecontract", "redirectToProjects"]),
  addingItem: state.getIn(["basecontract", "addingItem"]),
  loading: state.getIn(["basecontract", "loading"]),
  files: state.getIn(["basecontract", "uploadFiles"]),
  editFileList: state.getIn(["basecontract", "editFileList"]),
  projectInfo: state.getIn(["contract", "projectInfo"]),
  contractInfo: state.getIn(["basecontract", "contractInfo"]),
  currency: state.getIn(["config", "accountConfig", "currency", "title"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
  headersConfig: state.getIn(["headers", "config"]),
});

const mapPropsToDispatch = (dispatch) => {
  return {
    resetBaseStatus() {
      dispatch(actionCreators.reset());
      dispatch(actionCreatorsFile.clearFiles());
    },
    handleDeleteItem(proId, contractId, lineId) {
      dispatch(actionCreators.deleteLineItem(proId, contractId, lineId));
    },
    handleEditItem(proId, contractId, lineId, value) {
      dispatch(actionCreators.updateLineItem(proId, contractId, lineId, value));
    },
    readContract(proId, contractId) {
      dispatch(actionCreatorsContract.readProject(proId));
      dispatch(actionCreators.getInitialBaseItems(proId, contractId));
    },
    setFileList(files) {
      dispatch(actionCreatorsFile.setUploadFile(files));
    },
    setEditFiles(fileList) {
      dispatch(actionCreators.initialFileList(fileList));
    },
    uploadFiles(proId, contractId, lineId, files) {
      dispatch(actionCreators.uploadFileToDB(proId, contractId, lineId, files));
    },
    fileDelete(proId, contractId, lineId, fileId) {
      dispatch(actionCreators.deleteFile(proId, contractId, lineId, fileId));
    },
    handleFilesChange(files) {
      dispatch(actionCreators.changeFilesList(files));
    },
    handleFileCancel(name) {
      dispatch(actionCreators.cancelOneFile(name));
    },
    addNewRow(
      proId,
      contractId,
      tableList,
      files,
      parentItemId,
      table,
      lineitemType,
    ) {
      if (parentItemId) {
        dispatch(
          actionCreators.createNewChildRow(
            proId,
            contractId,
            tableList,
            files,
            parentItemId,
            table,
            lineitemType,
          ),
        );
      } else {
        dispatch(
          actionCreators.createNewRow(
            proId,
            contractId,
            tableList,
            files,
            table,
            lineitemType,
          ),
        );
      }
    },
    downloadFile(proId, contractId, fileId, fileName, isView) {
      dispatch(
        actionCreatorsContract.downloadItemFile(
          proId,
          contractId,
          fileId,
          fileName,
          isView,
        ),
      );
    },
  };
};
export default connect(mapStateToProps, mapPropsToDispatch)(BaseContract);
