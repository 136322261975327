import API from "../../../server";
import * as constants from "./constants";
import history from "../../../utils/history";
import {
  CLAIM_ONLY,
  PROJECT,
  EDIT,
  RETENTION_OPTIONS,
} from "../../../utils/constants";
import { actionCreators } from "../../../common/modal";
import { format } from "date-fns";
import profile from "../../../utils/profile";
import { getOrgType } from "../../../utils/checkOrgType";
import { getTimezone } from "../../../common/mapbox/map";
import { downloadFile } from "../../../utils/createDownloadLink";
import { roundNumber } from "utils/numeralHelper";

var numeral = require("numeral");

export const getAddressString = (address) => {
  if (!address) {
    return "";
  }

  const addressParts = [
    address.street_address,
    address.suburb,
    address.city,
    address.postcode,
    address.state,
    address.country,
  ];

  return addressParts.filter(Boolean).join(", ");
};

export const createNewProject = (
  projectInfo,
  avatarImage,
  coverImage,
  duplicate = "",
  linkedId,
) => {
  return (dispatch, getState) => {
    projectInfo.gst =
      projectInfo.gst || parseInt(projectInfo.gst) === 0
        ? parseInt(projectInfo.gst)
        : parseInt(
            getState().getIn(["config", "accountConfig", "gst_rate", "value"]),
          );

    let token = getState().getIn(["headers", "config", "mapbox_token"]);
    let address = getAddressString(projectInfo);
    let accountInfo = getState().getIn(["manageAccount", "accountInfo"]);

    const create_the_project = (projectInfo) => {
      if (linkedId) {
        let integration = getState().getIn(["manageAccount", "integration"]);
        let type =
          integration.size > 0 ? integration?.get(0)?.get("type") : "ipm";
        projectInfo.country = accountInfo?.get("country");
        projectInfo.integration = type;
      }
      API.create_one_project_group(projectInfo, duplicate)
        .then((res) => res.data.data)
        .then((res) => {
          afterCreate(res);
        });
    };

    const afterCreate = (res) => {
      const proId = res.contract_group_id;
      if (!avatarImage && !coverImage) {
        dispatch(actionAfterProject(proId, projectInfo.contractId));
      } else {
        let promiseArray = [];
        const avatarData = new FormData();
        avatarData.append("image", avatarImage);
        avatarImage &&
          promiseArray.push(
            API.upload_one_project_image(proId, "avatar", avatarData),
          );
        const coverData = new FormData();
        coverData.append("image", coverImage);
        coverImage &&
          promiseArray.push(
            API.upload_one_project_image(proId, "cover", coverData),
          );

        Promise.all(promiseArray)
          .then(() => {
            dispatch(actionAfterProject(proId, projectInfo.contractId));
          })
          .catch((res) => {
            dispatch(actionAfterProject(proId, projectInfo.contractId));
          });
      }
    };

    getTimezone(
      address,
      token,
      (fetchedTimezone) => {
        projectInfo.time_zone = fetchedTimezone;
        create_the_project(projectInfo);
      },
      () => {
        create_the_project(projectInfo);
      },
    );
  };
};

const actionAfterProject = (proId, contractId) => {
  return (dispatch, getState) => {
    const accountId = getState().getIn([
        "headers",
        "profile",
        "payclaim_account_id",
      ]),
      config = getState().getIn(["headers", "config"]);

    API.read_account_detail()
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        let orgType = getOrgType(config, res.role);
        if (orgType === CLAIM_ONLY) {
          API.read_one_project_group(proId)
            .then((res) => res.data)
            .then((res) => res.data.data)
            .then((res) => {
              API.create_one_contract(proId, "claim", {
                name: "Contract 1",
                street_address: res.street_address,
                country: res.country,
                state: res.state,
                suburb: res.suburb,
                postal_code: res.postcode,
                city: res.city,
                time_zone: res.time_zone,
                id: contractId,
              })
                .then((res) => res.data.data)
                .then((res) => {
                  history.push(
                    `/contract/${proId}/claim/${res.id}?account_id=${accountId}`,
                  );
                });
            });
        } else {
          history.push(`/project/view/${proId}?account_id=${accountId}`);
        }
      });
  };
};

const setData = (name, value) => ({
  type: constants.SET_DATA,
  payload: {
    name,
    value,
  },
});

export const readProject = (proId) => {
  return (dispatch, getState) => {
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    API.read_one_project_group(proId)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("projectInfo", res.data));
        dispatch(setData("loading", false));
        dispatch(
          setData(
            "welcomeInfoSeen",
            localStorage.getItem(proId + "-project-welcome-info"),
          ),
        );
      })
      .catch(() => {
        dispatch(
          actionCreators.setModalInfo({
            url: `/projects?account_id=${accountId}`,
          }),
        );
      });
  };
};

export const hideWelcome = (proId) => {
  return (dispatch) => {
    localStorage.setItem(proId + "-project-welcome-info", proId);
    dispatch(setData("welcomeInfoSeen", proId));
  };
};

export const uploadImage = (proId, type, fileData) => {
  return (dispatch) => {
    const data = new FormData();
    data.append("image", fileData);
    API.upload_one_project_image(proId, type, data).then((res) => {
      dispatch(readProject(proId));
    });
  };
};

export const projectsPreviousAddress = (proId) => {
  return (dispatch) => {
    dispatch(setData("loading", true));
    API.read_one_project_group(proId)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("projectsPreviousAddress", res.data));
      });
  };
};

export const updateProject = (proId, data) => {
  return (dispatch) => {
    if (data.gst) {
      data.gst = parseInt(data.gst, 10);
    }
    API.upsert_project(proId, data).then((res) => {
      dispatch(readProject(proId));
    });
  };
};

export const updateRetention = (
  proId,
  retentionType,
  retention,
  retentionScale,
) => {
  return (dispatch, getState) => {
    let initialRetention = getState().getIn([
      "newContractGroup",
      "projectInfo",
      "retention",
    ]);
    const dollar = ["firstAmount", "excessAmount", "maximum"],
      percent = [
        "excessPercent",
        "dlpPercent",
        "firstPercent",
        "pcdPercent",
        "dlpDays",
      ];
    Object.keys(retention).map((key) => {
      if (dollar.includes(key)) {
        retention[key] = numeral(retention[key]).value() * 100; //change the dollar money to cents
      }
      if (percent.includes(key)) {
        retention[key] = numeral(retention[key]).value(); //change the input string to int
      }
      if (key === "pcdDate" && retention["pcdDate"]) {
        retention["pcdDate"] = format(
          new Date(retention["pcdDate"]),
          "yyyy-MM-dd",
        ); //change date
      }
      return retention;
    });

    if (
      initialRetention &&
      retentionType === RETENTION_OPTIONS.NO_RETENTION.value
    ) {
      return API.remove_project_retention(
        proId,
        initialRetention.get("id"),
      ).then((res) => {
        dispatch(readProject(proId));
      });
    }
    let basicData = {
      retention_type: retentionType,
      firstpercent: retention.firstPercent,
      firstamount: retention.firstAmount,
      pcd_percent: roundNumber(retention.pcdPercent),
      pcd_date: retention.pcdDate,
      release_dlp_type: retention.dlpType,
      dlppercent: roundNumber(retention.dlpPercent),
      dlpdays: retention.dlpDays,
    };
    if (
      [
        RETENTION_OPTIONS.CONTRACT.value,
        RETENTION_OPTIONS.FIXED.value,
      ].includes(retentionType)
    ) {
      if (retentionType === RETENTION_OPTIONS.CONTRACT.value) {
        delete basicData.firstamount;
      }
      initialRetention &&
        API.update_project_retention(
          proId,
          initialRetention.get("id"),
          basicData,
        ).then((res) => {
          dispatch(readProject(proId));
        });
      !initialRetention &&
        API.create_project_retention(proId, basicData).then((res) => {
          dispatch(readProject(proId));
        });
    }

    if (retentionType === RETENTION_OPTIONS.SCALE.value) {
      basicData = {
        ...basicData,
        excesspercent: retention.excessPercent,
        excessamount: retention.excessAmount,
        maximum: retention.maximum,
      };
      for (var i = 0; i < retentionScale.length; i++) {
        if (i === 0) {
          basicData["firstnextpercent"] = numeral(
            retentionScale[i].percentage,
          ).value();
          basicData["firstnextamount"] = Math.round(
            retentionScale[i].amount * 100,
          );
        } else if (i === 1) {
          basicData["secondnextpercent"] = numeral(
            retentionScale[i].percentage,
          ).value();
          basicData["secondnextamount"] = Math.round(
            retentionScale[i].amount * 100,
          );
        } else if (i === 2) {
          basicData["thirdnextpercent"] = numeral(
            retentionScale[i].percentage,
          ).value();
          basicData["thirdnextamount"] = Math.round(
            retentionScale[i].amount * 100,
          );
        }
      }

      initialRetention &&
        API.update_project_retention(
          proId,
          initialRetention.get("id"),
          basicData,
        ).then((res) => {
          dispatch(readProject(proId));
        });
      !initialRetention &&
        API.create_project_retention(proId, basicData).then((res) => {
          dispatch(readProject(proId));
        });
    }
  };
};

export const readUsers = () => {
  return (dispatch) => {
    API.read_account_user("all", "active")
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("allUsers", res.users));
      });
  };
};

export const readMembers = (proId) => {
  return (dispatch) => {
    API.read_project_member(proId)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("members", res.members));
      });
  };
};

export const updateProjectMember = (proId, data, mainContact) => {
  return (dispatch, getState) => {
    let dataSend = [];
    data.map((ele) => {
      if (typeof ele.id === "string") {
        dataSend.push({
          user_id: ele.id,
          role: ele.role_id,
          is_key_contact: ele.id === mainContact,
        });
      }
      return ele;
    });
    API.update_project_member(proId, { members: dataSend }).then((res) => {
      const accountId = getState().getIn([
        "headers",
        "profile",
        "payclaim_account_id",
      ]);
      profile.checkPermission(PROJECT, EDIT, proId).then((isProjectAdmin) => {
        if (!isProjectAdmin) {
          history.push(`/project/view/${proId}?account_id=${accountId}`);
        }
      });
      dispatch(readMembers(proId));
    });
  };
};

export const updatePaymentTermsOptions = (proId, paymentTerms) => {
  let update = {
    payment_terms: {
      claim_due_days: parseInt(paymentTerms["claim"].days),
      claim_due_term: paymentTerms["claim"].terms,
      cert_due_days: parseInt(paymentTerms["cert"].days),
      cert_due_term: paymentTerms["cert"].terms,
      payment_due_days: parseInt(paymentTerms["pay"].days),
      payment_due_term: paymentTerms["pay"].terms,
    },
  };

  return (dispatch) => {
    API.upsert_project(proId, update).then((res) =>
      dispatch(readProject(proId)),
    );
  };
};

export const calculateDueDate = (proId, paymentTerms) => {
  let data = {
    claim_due_days: parseInt(paymentTerms["claim"].days),
    claim_due_term: paymentTerms["claim"].terms,
    cert_due_days: parseInt(paymentTerms["cert"].days),
    cert_due_term: paymentTerms["cert"].terms,
    payment_due_days: parseInt(paymentTerms["pay"].days),
    payment_due_term: paymentTerms["pay"].terms,
  };
  return (dispatch) => {
    API.calculate_due_dates(proId, data)
      .then((res) => res.data.data.data)
      .then((res) => {
        dispatch(setData("dueDates", res));
      });
  };
};

export const changeState = (proId, state) => {
  return (dispatch) => {
    switch (state) {
      case "archive":
        API.archive_project(proId).then((res) => dispatch(readProject(proId)));
        break;
      case "delete":
        dispatch(deleteProject(proId));
        break;
      case "re-open":
        API.reopen_project(proId).then((res) => dispatch(readProject(proId)));
        break;
      default:
        break;
    }
  };
};

export const downloadProjectSummary = (proId) => {
  return (dispatch) => {
    API.download_project_csv_summary(proId, { responseType: "blob" }).then(
      (res) => {
        downloadFile(res);
      },
    );
  };
};

export const deleteProject = (proId) => {
  return (dispatch, getState) => {
    const accId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    dispatch(
      actionCreators.showModal("delete", {
        open: true,
        title: "Project",
        message: "project",
        url: `/projects?account_id=${accId}`,
        api: { name: "delete_project", config: [proId] },
      }),
    );
  };
};

export const reset = () => ({
  type: constants.RESET,
});

// integration actions

export const readIntegrationProjects = () => {
  return (dispatch) => {
    dispatch(setData("loadIntegration", true));
    API.read_ipm_projects()
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("loadIntegration", false));
        dispatch(setData("integratedProjects", res));
      });
  };
};

export const readIntegratedProject = (proId) => {
  return (dispatch) => {
    API.read_one_ipm_project(proId)
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("projectInfo", res));
      });
  };
};
