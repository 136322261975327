import React, { Component, Fragment } from "react";
import PageBarPart from "../../../common/pagination";
import { Table } from "./tableComponent";
import Loading from "../../../common/loading";

function compareByOption(field) {
  //sort function based on the table columns
  let name = field.split("$")[0];
  let reverse = field.split("$")[1] === "up" ? 1 : -1;
  if (
    name === "total" ||
    name === "claimed" ||
    name === "remaining" ||
    name === "approved"
  ) {
    return function (a, b) {
      var result = a > b ? 1 : b > a ? -1 : 0;
      return result * reverse;
    };
  } else
    return function (a, b) {
      var result =
        a.toString().toLowerCase().trim() > b.toString().toLowerCase().trim()
          ? 1
          : b.toString().toLowerCase().trim() >
              a.toString().toLowerCase().trim()
            ? -1
            : 0;
      return result * reverse;
    };
}

export default class searchBar extends Component {
  constructor(props) {
    super(props);
    this.handleSetDataNum = this.handleSetDataNum.bind(this);
    this.sortOption = this.sortOption.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.setProjectTotalInfo = this.setProjectTotalInfo.bind(this);
    this.state = {
      currentPage: 1,
      sortField: "contract_name$up",
      limit: 50,
      tableTotal: {
        ProjectTotal: 0,
        Claimed: 0,
        Approved: 0,
        Remaining: 0,
      },
    };
  }
  sortOption(name) {
    //set sort fields
    return this.setState({
      sortField: name,
    });
  }
  handlePageChange = (page) => {
    this.setState({
      currentPage: page,
    });
  };
  handleSetDataNum(data) {
    this.handlePageChange(1);
    return this.setState({
      limit: data,
    });
  }
  setProjectTotalInfo(table) {
    //calculate project total based on the table
    let projectList = table.toJS();
    let sumTotal = 0,
      sumApproved = 0,
      sumClaimed = 0,
      sumRemaining = 0;
    let status = !projectList ? false : true;
    if (status) {
      projectList.map((item) => {
        sumTotal += item.total;
        sumClaimed += item.claimed;
        sumApproved += item.approved;
        sumRemaining += item.remaining;
        return { sumTotal, sumClaimed, sumApproved, sumRemaining };
      });
    }
    return this.setState({
      tableTotal: {
        ProjectTotal: sumTotal,
        Claimed: sumClaimed,
        Approved: sumApproved,
        Remaining: sumRemaining,
      },
    });
  }
  render() {
    const { list, loading } = this.props;
    const { currentPage, limit, tableTotal } = this.state;
    const total = list.size;
    let table = list.sortBy((val, index, obj) => {
      return val.get(this.state.sortField.split("$")[0]);
    }, compareByOption(this.state.sortField));
    if (loading) {
      return <Loading />;
    }
    return (
      <Fragment>
        <Table
          table={table}
          limit={limit}
          currentPage={currentPage}
          tableTotal={tableTotal}
          sortOption={this.sortOption}
        />
        <div style={{ height: "104px", marginRight: "10px" }}>
          <PageBarPart
            total={total}
            limit={limit}
            pageCount={5}
            currentPage={currentPage}
            handlePageChange={this.handlePageChange}
            handleSetData={this.handleSetDataNum}
            field="big"
          />
        </div>
      </Fragment>
    );
  }
  componentDidMount() {
    this.setProjectTotalInfo(this.props.list);
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.list !== nextProps.list) {
      this.setProjectTotalInfo(nextProps.list);
    }
    return true;
  }
}
