import * as constants from "./constants";

export const dismissToast = (id) => ({
  type: constants.DISMISS_TOAST,
  id,
});

export const addToast = (id, props) => ({
  type: constants.ADD_TOAST,
  id,
  props,
});

export const readToasts = () => ({
  type: constants.GET_STORAGE_TOAST,
});

export const saveToast = (id, props) => ({
  type: constants.SET_STORAGE_TOAST,
  id,
  props,
});
