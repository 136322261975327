import styled from "styled-components";
import * as theme from "../../theme";
import whiteIcon from "../../statics/img/icon-dropdown-white.svg";
import whiteSetIcon from "../../statics/img/icon-dropdown-up-white.svg";
import delGreyIcon from "../../statics/img/icon-delete-medium-grey.svg";
import editItemIcon from "../../statics/img/icon-edit-medium-grey.svg";

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  background-color: ${theme.palette.white};
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 30px 40px;
  box-sizing: border-box;
`;
export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 502px;
  &.first {
    width: 312px;
  }
  &.last {
    margin-top: 24px;
    width: 196px;
  }
`;
export const Claim = styled.div`
  height: 27px;
  width: 70px;
  background-color: ${theme.palette.lightPastelGreen};
  border-radius: 4px;
  color: ${theme.palette.brightGreen};
  font-weight: bold;
  text-align: center;
  padding: 5px;
`;
export const Cert = styled(Claim)`
  background-color: ${theme.palette.lightPastelBlue};
  color: ${theme.palette.tealBlue};
`;

export const TableWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 40px;
  table {
    height: 100%;
    border-spacing: 1px 0;
    border-collapse: separate;
    table-layout: fixed;
    font-size: 16px;
    font-weight: ${theme.font.normalFontWeight};
    color: ${theme.palette.gunmetal};
    tbody {
      tr:nth-child(even) {
        background-color: ${theme.palette.veryColdGrey};
      }
      tr:nth-child(odd) {
        background-color: ${theme.palette.white};
      }
      tr {
        &:hover {
          cursor: pointer;
          background-color: ${theme.palette.lightGrey};
        }
      }
    }
  }

  thead {
    background-color: ${theme.palette.gunmetal};
    height: 44px;
    box-sizing: border-box;
  }
  th {
    text-align: left;
    padding: 0 15px;
    color: ${theme.palette.white};
    font-weight: ${theme.font.mainFontWeight};
    line-height: 24px;
    position: relative;
    box-sizing: border-box;
    height: 44px;
    &.client {
      width: 190px;
    }
    &.project {
      width: 274px;
    }
    &.total {
      width: 170px;
    }
    &.value {
      width: 157px;
    }
    &.draft-project {
      &.total {
        width: 307px;
      }
      &.action {
        text-align: center;
        width: 96px;
      }
      &.name {
        width: 425px;
      }
      &.client {
        width: 282px;
      }
    }
  }
  td {
    padding: 4px 15px;
    box-sizing: border-box;
    height: 44px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: right;
    &.alignToLeft {
      text-align: left;
    }
    &.action {
      text-align: center;
      padding: 0;
    }
    &.empty {
      background-color: ${theme.palette.white} !important;
    }
  }
  tfoot {
    background-color: ${theme.palette.tealBlue} !important;
    color: ${theme.palette.white};
    height: 44px;
    font-weight: ${theme.font.mainFontWeight};
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .imgSort {
    height: 24px;
    width: 24px;
    background: url(${whiteIcon}) no-repeat;
    background-size: contain;
    cursor: pointer;
    box-sizing: border-box;
    position: absolute;
    right: 10px;
    bottom: 5px;
    &.up {
      background: url(${whiteSetIcon}) no-repeat;
      background-size: contain;
      bottom: 15px;
    }
  }

  .imgEdit {
    text-align: center;
    height: 24px;
    width: 24px;
    background: url(${editItemIcon}) no-repeat;
    background-size: contain;
    display: block;
    float: left;
    cursor: pointer;
    margin: 0 16px;
    &:hover {
      background-color: ${theme.palette.veryWarmLightGrey};
      border-radius: 50%;
    }
    &:active {
      background-color: ${theme.palette.wolfGrey};
      border-radius: 50%;
    }
    &:disabled {
      cursor: not-allowed;
      pointer-events: auto;
    }
  }
  .imgDel {
    text-align: center;
    height: 24px;
    width: 24px;
    background: url(${delGreyIcon}) no-repeat;
    background-size: contain;
    display: block;
    float: left;
    cursor: pointer;
    &:hover {
      background-color: ${theme.palette.veryWarmLightGrey};
      border-radius: 50%;
    }
    &:active {
      background-color: ${theme.palette.wolfGrey};
      border-radius: 50%;
    }
    &:disabled {
      cursor: not-allowed;
      pointer-events: auto;
    }
  }
`;
