import styled from "styled-components";
import * as theme from "../../../../theme";
import editActionIcon from "../../../../statics/img/icon-edit-medium-grey.svg";

export const MaterialsSection = styled.div`
  width: 100%;
  max-width: 1360px;
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: 16px;

  .title-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    .title{
      font-size: 24px;
      font-weight: bold;
    }
  }

  .materials {
    display: grid;
    grid-auto-rows: auto;
    grid-row-gap: 16px;
    .sub-title {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .claiming-cell {
    height: 100%;
    padding 8px 16px;

    .form_input {
      padding: 8px;
      height: 26px;
      border-radius: 0;
      font-size: 12px;
      outline: none;
    }

  }

  .AddBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    width: 200px;
  }

  // table headers
  .header_table {
    font-size: 16px;
    font-weight: ${theme.font.mainFontWeight};
    letter-spacing: 0.25px;
    text-align: center;
    .label-claim {
      height: 44px;
      width: 356px;
      color: ${theme.palette.tealBlue};
      text-align: center;
      background-color: ${theme.palette.lightPastelBlue};
      padding: 14px 0;
      font-weight: bold;
      text-transform: uppercase;
    }

    &.progress {
      background-color: ${theme.palette.veryColdGrey};
      color: ${theme.palette.gunmetal};
      width: 400px;
    }
  }

  .table {
    th {
      span {
        font-size: 12px;
      }
      &.emptyColumn {
        background-color: ${theme.palette.white};
      }
      &.group_header {
        text-align: center;
        &.grey {
          background-color: ${theme.palette.veryMediumGrey};
        }
      }
    }
    td {
      padding: 8px 15px 8px 5px;
      &.emptyColumn {
        background-color: ${theme.palette.white};
      }
      &.reason {
        .inlineDiv {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .reason_button_wrap {
            display: flex;
            align-items: center;
            .warning_label {
              margin-left: 5px;
              color: ${theme.palette.watermelonRed};
            }
          }
          .reason_label {
            text-align: left;
            padding: 5px 10px;
            width: 40%;
            height: auto;
          }
          .action_container {
            position: relative;
            width: 44px;
            height: 24px;
            padding: 0px 10px;
            margin-right: -15px;
          }

          .editIcon {
            height: 24px;
            width: 24px;
            background: url(${editActionIcon}) no-repeat;
            background-size: contain;
            cursor: pointer;
          }
        }
      }
    }

    tfoot {
      .empty {
        width: 908px;
      }
      .current-claim {
        width: 114px;
        background-color: ${theme.palette.turquoiseBlue};
      }
      .cert-value {
        width: 244px;
        background-color: ${theme.palette.turquoiseBlue};
      }
    }
  }

  .group_value {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MaterialTableWrap = styled.div`
  chrome @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .inputWrapper {
      margin-right: -1px;
      margin-left: 0px;
    }
  }
  firefox @-moz-document url-prefix() {
    .inputWrapper {
      margin-right: 0px;
      margin-left: -1px;
    }
  }

  margin-top: ${(props) =>
    props?.marginTop !== undefined ? props?.marginTop : "20"}px;

  table {
    border-spacing: 0;
    width: 100%;
    border-collapse: collapse;
    box-sizing: border-box;
    table-layout: fixed;
    font-size: 12px;
  }

  //tables in general
  table {
    th,
    td {
      height: 44px;
      padding: 8px 10px;
      border-left: solid 1px ${theme.palette.white};
      border-right: solid 1px ${theme.palette.white};
      box-sizing: border-box;
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
      &.so-table-checkbox {
        border: none;
      }
    }
    .align_left {
      text-align: left;
      .so-table-expand-wrapped {
        display: inline-flex;
        .space {
          width: 16px;
          display: inline-block;
        }
      }
    }
    .align_center {
      text-align: center;
      padding: 0;
    }

    th {
      background-color: ${theme.palette.gunmetal};
      color: ${theme.palette.white};
      font-weight: ${theme.font.mainFontWeight};
      text-align: right;
      &.th_align_center {
        padding: 8px 5px;
        text-align: center;
      }
      &.th_align_left {
        text-align: left;
      }
    }

    td {
      padding: 8px 10px;
      text-align: right;
      color: ${theme.palette.gunmetal};
      &.td_align_center {
        padding: 8px 5px;
        text-align: center;
      }
      &.td_align_left {
        text-align: left;
      }
    }
    thead {
      .hidden {
        height: 0px;
        border: none;
      }
    }

    tfoot {
      td {
        color: ${theme.palette.white};
        font-weight: bold;
        padding: 8px 10px;
      }
    }
  }
`;
