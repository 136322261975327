import React from "react";
import Link from "../../../../common/linkWithQuery";
import { getTimezoneAbbr } from "../../../../utils/dateTimeHelper";
import BootstrapTooltip from "../../../../common/toolTip/index";
import { COUNTRY_SA, COUNTRY_GB } from "../../../../utils/constants";

import {
  ImgEdit,
  DetailBox,
  DetailTitle,
  AccountDetail,
  DetailItem,
  Title,
  DetailPlan,
  DetailInfo,
  RoleInfo,
  PlanMessage,
} from "../style";

import { BlueWhiteBtn as Button } from "../../../../common/button";
import { capitalizeAddress } from "../../../../utils/helper";
import AccountImg from "./accountImg";
import { findOwnerName } from "../../../../utils/user/userUtils";

const AccountInfo = ({ accountInfo, config }) => (
  <AccountDetail>
    <DetailTitle>{accountInfo.get("name")}</DetailTitle>
    <DetailItem>
      <label>Company Address</label>
      <div>
        {capitalizeAddress(
          accountInfo.get("street_address"),
          accountInfo.get("suburb"),
          accountInfo.get("city"),
          accountInfo.get("postal_code"),
        )}
      </div>
    </DetailItem>
    <DetailItem>
      <label>Phone</label>
      <div>{accountInfo.get("phone")}</div>
    </DetailItem>
    <DetailItem>
      <label>Website</label>
      <div>{accountInfo.get("website")}</div>
    </DetailItem>
    <DetailItem className="long">
      <div>
        All amounts are expressed in {config?.getIn(["currency", "value"])}.
      </div>
    </DetailItem>
  </AccountDetail>
);

const AccountInfoPart = (props) => {
  return (
    <DetailBox className="account">
      <AccountImg {...props} />
      <AccountInfo
        accountInfo={props.accountInfo}
        config={props.accountConfig}
      />
      <Link to="/manage-account/edit">
        <ImgEdit />
      </Link>
    </DetailBox>
  );
};

const PlanInfoPart = ({
  info,
  userList,
  noPermission,
  accountType,
  accountConfig,
  env,
}) => {
  const currency = accountConfig?.getIn(["currency", "title"]);

  const planInfo = info.get("current_plan");
  const noPlan =
    info.get("status") === "not_registed" || info.get("status") === "canceled";
  const ownerName = findOwnerName(userList);

  let planTitle = "No plan selected";
  let planDescription = "Select a plan to get started";
  let planDescriptionOwner = `Please contact the account owner ${
    ownerName ? ownerName : ""
  } to get started.`;

  let PlanButton = () => (
    <Link
      to={{
        pathname: `/manage-account/plan-price`,
        state: { noPermission: noPermission },
      }}
    >
      <Button title="Choose a Plan" padding="12px 30px" />
    </Link>
  );

  // If the account is in production and the country is SA or GB, the user will not be able to select a plan
  if (
    env === "prod" &&
    [COUNTRY_SA, COUNTRY_GB].includes(accountConfig?.getIn(["country", "code"]))
  ) {
    planTitle = "No subscription";
    planDescription = "Contact us to get started";
    PlanButton = () => (
      <Button
        onClick={() => {
          window.location.href = "https://paylab.asbuiltvault.com/pricing";
        }}
        title="Get in touch"
        padding="12px 30px"
      />
    );
  }
  return (
    <DetailBox>
      <Title>Plan & Payment</Title>
      <div>
        {noPlan ? (
          <>
            {accountType === "super" ? (
              <>
                <DetailInfo>
                  <div>
                    <label>
                      <b>PayLab Enterprise Plan</b>
                    </label>
                  </div>
                </DetailInfo>
                <PlanMessage>
                  Invoicing is done outside of PayLab please contact your
                  account administrator for more information.
                </PlanMessage>
              </>
            ) : !noPermission ? (
              <DetailInfo>
                <div>
                  <label>
                    <b>{planTitle}</b>
                  </label>
                  <div>{planDescription}</div>
                </div>

                <PlanButton />
              </DetailInfo>
            ) : (
              <>
                <DetailInfo>
                  <div>
                    <label>
                      <b>{planTitle}</b>
                    </label>
                  </div>
                </DetailInfo>
                <PlanMessage>{planDescriptionOwner}</PlanMessage>
              </>
            )}
          </>
        ) : (
          <>
            <DetailInfo>
              {!planInfo ? (
                <DetailPlan>Plan Info</DetailPlan>
              ) : (
                <DetailPlan>
                  <div>
                    <label>
                      <b>The {planInfo.get("product_name")} Toolbox</b>
                    </label>
                    <div>
                      {currency}
                      {planInfo.get("price_decimal")}{" "}
                      {planInfo.get("price_currency")} / Billed{" "}
                      {planInfo.get("price_interval").toLowerCase()}ly{" "}
                    </div>
                  </div>
                  <div>
                    <label>
                      <b>Projects Active</b>
                    </label>
                    <div>{planInfo.get("product_des")}</div>
                  </div>
                </DetailPlan>
              )}
              {!noPermission && (
                <Link to="/manage-account/plan-payment">
                  <ImgEdit />
                </Link>
              )}
            </DetailInfo>
            {noPermission && (
              <PlanMessage>
                Please contact the account owner {ownerName ? ownerName : ""} to
                make changes.
              </PlanMessage>
            )}
          </>
        )}
      </div>
    </DetailBox>
  );
};

const UserInfoPart = ({ list }) => {
  const ActiveUsersList = list.filter(
    (item) => item.get("status") === "active",
  );
  const InactiveUsersList = list.filter(
    (item) => item.get("status") !== "active",
  );
  return (
    <DetailBox>
      <Title>Users & Permissions</Title>
      <DetailInfo>
        <div>
          {ActiveUsersList.size} Active Users, {InactiveUsersList.size}{" "}
          Deactivated Users
        </div>
        <Link to="/manage-account/users">
          <ImgEdit />
        </Link>
      </DetailInfo>
    </DetailBox>
  );
};

const TimezoneTooltip = () => {
  return (
    <BootstrapTooltip title={getTimezoneAbbr("zzzz")} placement="top">
      <span className="">{getTimezoneAbbr()}</span>
    </BootstrapTooltip>
  );
};

const EmailSetting = ({ data }) => {
  return (
    <DetailBox>
      <Title>Email Summaries</Title>
      <DetailInfo>
        <div>
          Daily emails at {data.getIn(["time_string", "day_time"])}{" "}
          <TimezoneTooltip />
          <br />
          Weekly emails every {data.getIn(["time_string", "week"])} at{" "}
          {data.getIn(["time_string", "week_time"])} <TimezoneTooltip />
        </div>
        <Link to="/manage-account/email-summaries">
          <ImgEdit />
        </Link>
      </DetailInfo>
    </DetailBox>
  );
};

const AccountRole = ({ noPermission, info, config, accountConfig }) => {
  const RoleMeg = {
    claiming: `Projects will only have a single contract that your organisation makes ${accountConfig
      ?.getIn(["claim", "noun"])
      ?.toLowerCase()}s against`,
    certifying:
      "Projects will only have contracts that your organisation certifies",
    claim_cert: `Projects will have a mixture of contracts that your organisation makes ${accountConfig
      ?.getIn(["claim", "noun"])
      ?.toLowerCase()}s and certifies`,
  };
  let accountTypes = config.account_types || {},
    roleType = "claiming",
    role = "";

  Object.keys(accountTypes).map((ele) => {
    if (Object.keys(accountTypes[ele]).includes(info.get("role"))) {
      roleType = ele;
      role = accountTypes[ele][info.get("role")];
    }
    return ele;
  });

  return (
    <DetailBox>
      <Title>Organisation Type</Title>
      <DetailInfo>
        {info.get("role") ? (
          <>
            <RoleInfo img={roleType}>
              <div className="role-image" />
              <div className="role-info">
                <div className="title">{role}</div>
                <div>{RoleMeg[roleType]}</div>
              </div>
            </RoleInfo>
            <Link to="/manage-account/role">
              <ImgEdit />
            </Link>
          </>
        ) : (
          <>
            <label>
              Select your role. This will affect the kinds of projects you can
              create.
            </label>
            {!noPermission && (
              <Link to={"/manage-account/role"}>
                <Button title="Select a Role" padding="12px 34px" />
              </Link>
            )}
          </>
        )}
      </DetailInfo>
    </DetailBox>
  );
};

export {
  AccountInfoPart,
  PlanInfoPart,
  UserInfoPart,
  EmailSetting,
  AccountRole,
};
