import React from "react";
import { Table } from "shineout";

import { MaterialTableWrap } from "./styled";

import Input from "../../../../common/form";
import CommentList from "../../../../common/table/comment_table";
import { currencySubunitToUnit } from "../../../../utils/currencyHelper";
import { roundNearest } from "../../../../utils/numeralHelper";
import Dialog from "../../../../common/modal/component/certReasonDialog";
import {
  dropDecimalColumns,
  dropDecimal,
  headerFormatter,
} from "../../../../utils/tableFormatHelper";
import { CERT_MATERIAL_ITEM } from "../../../../utils/constants";

const MaterialsTable = (props) => {
  const {
    title,
    tableData,
    certMaterialList,
    accountConfig,
    updateComment,
    handleInput,
    claimTotal,
    certTotal,
    permissions,
    isNewCert,
    inputReason,
    reasonOption,
    setOpenDialog,
    setInputType,
    setSelectedItem,
    setEditSelectedItem,
    projectData,
  } = props;

  const handleMoreActions = async (d) => {
    setOpenDialog(true);
    setInputType("editing_item");
    setSelectedItem(d);
    setEditSelectedItem(d);
  };

  const showCurrency = !accountConfig?.get("enable_header_currency");
  const claimTerm = accountConfig.get("claim");

  const truncateColumn = dropDecimalColumns(tableData, [
    "claim_to_date_value",
    "cert_to_date_value",
    "last_cert_value",
    "current_claim",
  ]);

  const getReason = (itemId, list) => {
    let obj = list.find((ele) => ele.id === itemId);
    if (!obj) {
      return { reason: "", isRequiredReason: null };
    }
    return {
      reason: obj.payment_comment || "",
      isRequiredReason: obj.required_reason,
    };
  };

  const getCertValue = (lineItemId) => {
    let value = 0;
    var obj = certMaterialList.find((obj) => obj.id === lineItemId);
    if (!obj) {
      return null;
    }
    value = obj.payment_value;
    return value / 100;
  };

  const columns = [
    {
      title: "Ref",
      className: "align_left lineitem-ref",
      width: 56,
      render: "label",
    },
    {
      title: "Description",
      className: "align_left",
      width: 333,
      render: "description",
    },
    {
      title: headerFormatter(
        `${claimTerm?.get("value")} to Date`,
        accountConfig?.getIn(["currency", "code"]),
        accountConfig?.get("enable_header_currency"),
      ),
      className: "group_header grey",
      render: (d) => {
        return (
          <div className="group_value">
            <div>
              {currencySubunitToUnit(
                truncateColumn["claim_to_date_value"]
                  ? roundNearest(d.claimed_to_date_value)
                  : d.claimed_to_date_value,
                !accountConfig?.get("enable_header_currency"),
                truncateColumn["claim_to_date_value"],
                showCurrency,
              )}
            </div>
          </div>
        );
      },
      width: 168,
    },
    {
      title: headerFormatter(
        `${accountConfig?.getIn(["cert", "value"])} to Date`,
        accountConfig?.getIn(["currency", "code"]),
        accountConfig?.get("enable_header_currency"),
      ),
      render: (d) =>
        currencySubunitToUnit(
          d.cert_to_date_value,
          !accountConfig?.get("enable_header_currency"),
          truncateColumn["cert_to_date_value"],
          showCurrency,
        ),
      width: 113,
    },
    {
      title: headerFormatter(
        "Variance to Date",
        accountConfig?.getIn(["currency", "code"]),
        accountConfig?.get("enable_header_currency"),
      ),
      render: (d) =>
        currencySubunitToUnit(
          dropDecimal(d.claimed_to_date_value - d.cert_to_date_value)
            ? roundNearest(d.claimed_to_date_value - d.cert_to_date_value)
            : d.claimed_to_date_value - d.cert_to_date_value,
          !accountConfig?.get("enable_header_currency"),
          dropDecimal(d.claimed_to_date_value - d.cert_to_date_value),
          showCurrency,
        ),
      width: 113,
    },
    {
      title: headerFormatter(
        `Previous Net ${accountConfig?.getIn(["cert", "value"])}`,
        accountConfig?.getIn(["currency", "code"]),
        accountConfig?.get("enable_header_currency"),
      ),
      render: (d) =>
        currencySubunitToUnit(
          truncateColumn["last_cert_value"]
            ? roundNearest(d.last_cert_value)
            : d.last_cert_value,
          !accountConfig?.get("enable_header_currency"),
          truncateColumn["last_cert_value"],
          showCurrency,
        ),
      width: 113,
    },
    {
      className: "emptyColumn",
      render: "",
      width: 8,
    },
    {
      title: headerFormatter(
        `Current ${claimTerm?.get("noun")}`,
        accountConfig?.getIn(["currency", "code"]),
        accountConfig?.get("enable_header_currency"),
      ),
      render: (d) =>
        currencySubunitToUnit(
          truncateColumn["current_claim"]
            ? roundNearest(d.current_claim)
            : d.current_claim,
          !accountConfig?.get("enable_header_currency"),
          truncateColumn["current_claim"],
          showCurrency,
        ),
      width: 114,
    },
    {
      title: headerFormatter(
        `Current ${accountConfig?.getIn(["cert", "noun"])} Value`,
        accountConfig?.getIn(["currency", "code"]),
        accountConfig?.get("enable_header_currency"),
      ),
      className: "align_center",
      width: 242,
      render: (d) => {
        return (
          <div className="claiming-cell">
            <Input
              name="materials-cert"
              field="number"
              value={getCertValue(d.id)}
              onValueChange={(e) => handleInput(d.id, e.floatValue)}
              decimalScale={2}
              style={{ textAlign: "right" }}
            />
          </div>
        );
      },
    },
    {
      title: "Reason",
      className: "reason align_left",
      width: 144,
      render: (d) => {
        return (
          <div className="inlineDiv">
            <Dialog
              title={"Reason for Materials to Submitted Claim"}
              width="20px"
              height="20px"
              initialValue={getReason(d.id, certMaterialList)}
              inputReason={(reason, e) => inputReason(d.id, reason)}
              reasonOption={reasonOption}
            />
            {d.status === CERT_MATERIAL_ITEM && (
              <div className="editIcon" onClick={() => handleMoreActions(d)} />
            )}
            {!isNewCert && (permissions.edit || permissions.view) && (
              <CommentList
                data={d}
                type={"cert"}
                expands={[]}
                viewOnly={permissions.view}
                updateComment={updateComment}
                otherParty={projectData?.get("payee_entity_name")}
              />
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div className="materials">
      <div className="sub-title">{title}</div>
      <MaterialTableWrap className="table" marginTop="0">
        <table className="header_table">
          <thead>
            <tr>
              <td style={{ width: 389 }} />
              <td className="header_table progress"> PROGRESS TO DATE</td>
              <td style={{ width: 121 }} />
              <td className="label-claim">
                {accountConfig?.getIn(["cert", "noun"])}
              </td>
              <td style={{ width: 144 }} />
            </tr>
          </thead>
        </table>
        <Table data={tableData} columns={columns}>
          <table>
            <tfoot>
              <tr>
                <td className="empty" />
                <td className="current-claim">
                  {currencySubunitToUnit(claimTotal, showCurrency)}
                </td>
                <td className="cert-value">
                  {currencySubunitToUnit(certTotal, showCurrency)}
                </td>
                <td />
              </tr>
            </tfoot>
          </table>
        </Table>
      </MaterialTableWrap>
    </div>
  );
};

export default MaterialsTable;
