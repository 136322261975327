import React, { useEffect, useState } from "react";
import { ClickAwayListener } from "@material-ui/core";

import { MaterialClaimTableWrapper } from "./review_claim_style";
import { Sort } from "./basic_table";
import { Table } from "shineout";
import {
  dropDecimalColumns,
  headerFormatter,
} from "../../utils/tableFormatHelper";
import { toKebabCase } from "../../utils/stringHelper";
import { ProvisionalDescription } from "../lineitem/sharedComponent";
import { roundNearest } from "../../utils/numeralHelper";
import CommentList from "./comment_table";
import { currencySubunitToUnit } from "../../utils/currencyHelper";
import { countComments } from "./table_utils";
import { TableContainer } from "./basic_table_style";
import { ApprovedTableWrapper } from "./styled";
import { handleTableSorter } from "../../utils/helper";

const ApprovedMaterialsTable = (props) => {
  const { title, data, accountConfig, otherParty } = props;
  const [total, setTotal] = useState({
    claim: 0,
    approved: 0,
  });

  const Approved = (
    <span className="approvedHeader">
      {headerFormatter(
        `Certified`,
        accountConfig?.getIn(["currency", "code"]),
        accountConfig?.get("enable_header_currency"),
      )}
    </span>
  );

  const showCurrency = !accountConfig?.get("enable_header_currency");
  const dropDecimal = dropDecimalColumns(data, ["claim_value"]);

  const materialColumns = [
    {
      title: "Ref",
      className: "align_left lineitem-ref",
      render: "label",
      width: 78,
    },
    {
      title: "Description",
      className: "align_left",
      width: 638,
      treeColumnsName: "childitems",
      render: (d) => (
        <div>
          <ProvisionalDescription
            data={d}
            isUpdateExpanded={() => {
              return false;
            }}
          />
        </div>
      ),
    },
    {
      title: headerFormatter(
        `${accountConfig?.getIn(["claim", "value"])} Total`,
        accountConfig?.getIn(["currency", "code"]),
        accountConfig?.get("enable_header_currency"),
      ),
      width: 296,
      className: "th_align_center",
      render: (d) =>
        currencySubunitToUnit(
          dropDecimal["claim_value"]
            ? roundNearest(d.claim_value, true)
            : d.claim_value,
          showCurrency,
          dropDecimal["claim_value"],
        ),
    },
    {
      className: "hidden",
      render: (d) =>
        currencySubunitToUnit(
          dropDecimal["approved_total"]
            ? roundNearest(d.approved_total, true)
            : d.approved_total,
          showCurrency,
          dropDecimal["approved_total"],
        ),
      width: 208,
      group: Approved,
    },
    {
      className: "hidden approveComment",
      width: 40,
      render: (d) => (
        <div className="inlineDiv">
          {countComments(d, d?.id) > 0 && (
            <CommentList
              data={d}
              type={"claim"}
              viewOnly={true}
              otherParty={otherParty}
              expands={[]}
            />
          )}
        </div>
      ),
      group: Approved,
    },
  ];

  useEffect(() => {
    let claimTotal = 0,
      approvedTotal = 0;
    data.forEach((item) => {
      claimTotal += item.claim_value;
      approvedTotal += item.approved_total;
    });
    setTotal({ claim: claimTotal, approved: approvedTotal });
  }, [data]);

  return (
    <div className="materials">
      <span className="sub-title">{title}</span>
      <TableContainer className={toKebabCase(title)}>
        <ApprovedTableWrapper>
          <table className="header_table">
            <tbody>
              <tr>
                <td style={{ width: 716 }} />
                <td className="label-claim">
                  {accountConfig?.getIn(["claim", "noun"])}
                </td>
                <td className="label-certificate">
                  {accountConfig?.getIn(["cert", "noun"])}
                </td>
                <td style={{ width: 158 }} />
              </tr>
            </tbody>
          </table>
          <Table data={data} columns={materialColumns}>
            <table>
              <tfoot className="materials_footer">
                <tr>
                  <td className="blank first" />
                  <td className="title">Sub Total</td>
                  <td className="claimed">
                    {currencySubunitToUnit(total.claim, showCurrency)}
                  </td>
                  <td className="approve">
                    {currencySubunitToUnit(total.approved, showCurrency)}
                  </td>
                  <td className="blank last" />
                </tr>
              </tfoot>
            </table>
          </Table>
        </ApprovedTableWrapper>
      </TableContainer>
    </div>
  );
};

export const ApprovedClaimMaterials = (props) => {
  const { tableContent, accountConfig, isClaim, claimInfo } = props;
  const [onSite, setOnSite] = useState([]);
  const [offSite, setOffSite] = useState([]);
  const [sortOpen, setSortOpen] = useState(false);
  const [sortOption, setSortOption] = useState({
    field: "created_date",
    sep: "asc",
  });
  const sortingOptions = {
    options: {
      created_date: "Date Created",
      label: "Reference",
      description: "Description",
      claim_value: `${accountConfig?.getIn(["claim", "value"])} Value`,
      approved_total: "Approved Value",
    },
    sep: {
      asc: "Ascending",
      desc: "Descending",
    },
  };
  const otherParty = isClaim
    ? claimInfo?.get("payer_entity_name")
    : claimInfo?.get("payee_entity_name");

  useEffect(() => {
    let table = tableContent.toJS();
    table = table.sort(handleTableSorter(sortOption.field, sortOption.sep));

    let onSite = [],
      offSite = [];
    table.forEach((item) => {
      if (item.on_site) {
        onSite.push(item);
      } else {
        offSite.push(item);
      }
    });
    setOnSite(onSite);
    setOffSite(offSite);
  }, [tableContent, sortOption]);

  const setSort = (option) => {
    if (sortingOptions.options[option]) {
      setSortOption({ ...sortOption, field: option });
    } else if (sortingOptions.sep[option]) {
      setSortOption({ ...sortOption, sep: option });
    }
    setOnSite(handleSort(onSite, sortOption));
    setOffSite(handleSort(offSite, sortOption));
  };

  const handleSort = (list, sortOptions) => {
    let newList = [...list];
    newList.sort(handleTableSorter(sortOptions.field, sortOptions.sep));
    return newList;
  };

  return (
    <MaterialClaimTableWrapper>
      <div className="title-field">
        <span>Materials On/Off Site</span>
        <ClickAwayListener onClickAway={() => setSortOpen(false)}>
          <div>
            <Sort
              open={sortOpen}
              openDropdown={() => setSortOpen(true)}
              setSort={(e) => setSort(e.target.value)}
              sortField={sortOption.field}
              sortSep={sortOption.sep}
              sortOptions={sortingOptions}
              otherParty={otherParty}
            />
          </div>
        </ClickAwayListener>
      </div>

      {onSite.length > 0 && (
        <ApprovedMaterialsTable
          title="On Site"
          data={onSite}
          accountConfig={accountConfig}
          isClaim={isClaim}
        />
      )}
      {offSite.length > 0 && (
        <ApprovedMaterialsTable
          title="Off Site"
          data={offSite}
          accountConfig={accountConfig}
          isClaim={isClaim}
          otherParty={otherParty}
        />
      )}
    </MaterialClaimTableWrapper>
  );
};
