import React, { useEffect, useState } from "react";
import { ClickAwayListener } from "@material-ui/core";

import { OutLayerWrapper } from "../../styled";
import { MaterialsSection } from "./styled";
import { TableWarning } from "../../../../common/table/styled";

import { Sort } from "../../../../common/table/basic_table";
import { handleTableSorter } from "../../../../utils/helper";
import profile from "../../../../utils/profile";
import {
  CERT_ITEM_ACTION,
  CONTRACT,
  EDIT,
  VIEW,
} from "../../../../utils/constants";
import { AddBtn } from "../../../../common/button";
import InputDialogue from "../../../../common/lineitem/materialsInputTable";
import MaterialsTable from "./table";

const MakeCertMaterials = (props) => {
  const {
    data,
    materialsCertList,
    totals,
    handleInput,
    contractId,
    proId,
    handleItem,
    allowExcludeRetention,

    handleFilesChange,
    uploadAttachments,
    cancelAttachment,
    deleteAttachment,
    initialFileList,
    contractLinked,
  } = props;
  const [permissions, setPermissions] = useState({
    edit: true,
    view: false,
  });
  const [onSite, setOnsite] = useState([]);
  const [offSite, setOffSite] = useState([]);
  const [separateMaterialList, setSeparateMaterialList] = useState({
    onSite: [],
    offSite: [],
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [inputType, setInputType] = useState("");
  const [selectedItem, setSelectedItem] = useState({});
  const [editSelectedItem, setEditSelectedItem] = useState({ id: "" });

  const [sortOpen, setSortOpen] = useState(false);
  const [sortOption, setSortOption] = useState({
    field: "created_date",
    sep: "asc",
  });
  const sortingOptions = {
    options: {
      created_date: "Date Created",
      label: "Reference",
      description: "Description",
    },
    sep: {
      asc: "Ascending",
      desc: "Descending",
    },
  };

  // get permissions
  useEffect(() => {
    let edit = permissions.edit,
      view = permissions.view;
    profile.checkPermission(CONTRACT, EDIT, proId, contractId).then((res) => {
      edit = res;
    });
    profile.checkPermission(CONTRACT, VIEW, proId, contractId).then((res) => {
      view = res;
    });
    setPermissions({ view: view, edit: edit });
  }, []); // eslint-disable-line

  // separate on/off site items
  useEffect(() => {
    let matOnSite = [],
      matOffSite = [];
    if (data.size !== 0) {
      data.toJS().forEach((line) => {
        if (line.on_site) {
          matOnSite.push(line);
        } else {
          matOffSite.push(line);
        }
      });
      setOnsite(handleSort(matOnSite, sortOption));
      setOffSite(handleSort(matOffSite, sortOption));
    }
  }, [data]); // eslint-disable-line

  useEffect(() => {
    let materialsOnSite = [];
    let materialsOffSite = [];

    materialsCertList.toJS().forEach((value) => {
      if (value.onSite) {
        materialsOnSite.push(value);
      } else {
        materialsOffSite.push(value);
      }
    });

    setSeparateMaterialList({
      onSite: materialsOnSite,
      offSite: materialsOffSite,
    });
  }, [materialsCertList]);

  const setSort = (option) => {
    if (sortingOptions.options[option]) {
      setSortOption({ ...sortOption, field: option });
      setOnsite(handleSort(onSite, { ...sortOption, field: option }));
      setOffSite(handleSort(offSite, { ...sortOption, field: option }));
    } else if (sortingOptions.sep[option]) {
      setSortOption({ ...sortOption, sep: option });
      setOnsite(handleSort(onSite, { ...sortOption, sep: option }));
      setOffSite(handleSort(offSite, { ...sortOption, sep: option }));
    }
  };

  const handleSort = (list, sortOptions) => {
    let newList = [...list] || [];
    newList.sort(handleTableSorter(sortOptions.field, sortOptions.sep));
    return newList;
  };

  const getModalTitle = (description) => {
    let title =
      inputType === "adding_item" ? "Add Item" : "Edit Item - " + description;

    return title;
  };

  const handleDialogueClose = () => {
    setOpenDialog(false);
    setEditSelectedItem({ id: "" });
  };

  const handleDialogueSave = (id, info, lineType) => {
    let lineItem = { ...info, onSite: lineType };
    // add new child or parent item, edit item
    if (inputType === "adding_item") {
      handleItem(CERT_ITEM_ACTION.ADD, "", lineItem);
    } else {
      handleItem(CERT_ITEM_ACTION.EDIT, id, lineItem);
    }

    return setOpenDialog(false);
  };

  const handleAddButton = () => {
    setOpenDialog(true);
    setInputType("adding_item");
    handleCloseEdit();
  };

  const handleCloseEdit = () => {
    setSelectedItem({});
  };

  const handleDeleteSelectedItem = (id) => {
    //delete or clean input item
    if (inputType === "editing_item") {
      handleItem(CERT_ITEM_ACTION.DELETE, id);
      return setOpenDialog(false);
    } else {
      handleCloseEdit();
    }
  };

  return data?.size > 0 ? (
    <OutLayerWrapper className="tableWidth">
      <MaterialsSection>
        <div className="title-field">
          <div className="title">Materials On/Off Site</div>
          <ClickAwayListener onClickAway={() => setSortOpen(false)}>
            <div>
              <Sort
                open={sortOpen}
                openDropdown={() => setSortOpen(true)}
                setSort={(e) => setSort(e.target.value)}
                sortField={sortOption.field}
                sortSep={sortOption.sep}
                sortOptions={sortingOptions}
              />
            </div>
          </ClickAwayListener>
        </div>

        {onSite?.length > 0 && (
          <MaterialsTable
            title="On Site"
            tableData={onSite}
            {...props}
            certMaterialList={separateMaterialList.onSite}
            handleInput={(lineId, value) => handleInput(lineId, value, true)}
            claimTotal={totals.get("onSiteClaim")}
            certTotal={totals.get("onSite")}
            permissions={permissions}
            setOpenDialog={setOpenDialog}
            setInputType={setInputType}
            setSelectedItem={setSelectedItem}
            setEditSelectedItem={setEditSelectedItem}
          />
        )}
        {offSite?.length > 0 && (
          <MaterialsTable
            title="Off Site"
            tableData={offSite}
            {...props}
            certMaterialList={separateMaterialList.offSite}
            handleInput={(lineId, value) => handleInput(lineId, value, false)}
            claimTotal={totals.get("offSiteClaim")}
            certTotal={totals.get("offSite")}
            permissions={permissions}
            setOpenDialog={setOpenDialog}
            setInputType={setInputType}
            setSelectedItem={setSelectedItem}
            setEditSelectedItem={setEditSelectedItem}
          />
        )}
        {!contractLinked && (
          <div className="AddBtn">
            <AddBtn
              onClick={handleAddButton}
              title="ADD ITEM"
              float="none"
              margin="20px 0 0"
            />
          </div>
        )}
        <InputDialogue
          title={getModalTitle(editSelectedItem.description)}
          tableList={selectedItem}
          field="dialog"
          modalOpen={openDialog}
          action={inputType}
          allowExcludeRetention={allowExcludeRetention}
          handleClose={handleDialogueClose}
          handleSaveItem={(lineType, form) =>
            handleDialogueSave(editSelectedItem.id, form, lineType)
          }
          handleCleanItem={() => handleDeleteSelectedItem(editSelectedItem.id)}
          // file attachments
          files={
            getModalTitle(editSelectedItem.description).includes("Edit")
              ? props.editFileList
              : props.uploadFiles
          }
          handleFilesChange={(files) => {
            handleFilesChange(files);
          }}
          uploadAttachments={uploadAttachments}
          cancelAttachment={cancelAttachment}
          deleteAttachment={deleteAttachment}
          initialFileList={initialFileList}
        />
      </MaterialsSection>
      {materialsCertList.some((item) => item.get("required_reason")) && (
        <TableWarning>
          <div className="warningIcon" />
          <span>
            Reasons are required when changing cert values. <br />
            Please enter a reason for all the items indicated.
          </span>
        </TableWarning>
      )}
    </OutLayerWrapper>
  ) : null;
};

export default MakeCertMaterials;
