import React, { PureComponent } from "react";
import ClaimDetail from "../../makeclaim/shared/claimDetail";
import debounce from "lodash/debounce";
import { convertUTCToTime } from "../../../utils/dateTimeHelper";

export default class index extends PureComponent {
  updatePageChangeStatus = (field, value) => {
    this.props.componentUpdated();
    this.props.handleChange(field, value);
  };

  render() {
    const {
      claimInfo,
      projectAllInfo,
      paymentsOption,
      certificate_number,
      accountConfig,
    } = this.props;
    const wordCertNoun = accountConfig.getIn(["cert", "noun"]);
    const claimWord = accountConfig?.getIn(["claim", "noun"]);

    const debounceChange = debounce(
      (field, value) => this.updatePageChangeStatus(field, value),
      400,
    );
    const payment = projectAllInfo.get("payment_terms");
    let dateList = {
      claim_due_days: "",
      claim_due_term: "",
      cert_due_days: "",
      cert_due_term: "",
      payment_due_days: "",
      payment_due_term: "",
    };
    if (payment && paymentsOption) {
      Object.keys(dateList).forEach((item) => {
        if (item.includes("term")) {
          let optionName = item.includes("claim")
            ? "pay_claim_options"
            : item.includes("cert")
              ? "pay_cert_options"
              : "payment_options";
          dateList[item] = paymentsOption[optionName][payment.get(item)];
        } else {
          dateList[item] = payment.get(item);
        }
      });
    }

    const data = {
      summary: [
        {
          label: wordCertNoun + " Reference",
          value: certificate_number,
          name: "certificate_number",
          action: (field, e) => debounceChange(field, e.target.value),
          autoFocus: true,
        },
        { label: `${claimWord} Reference`, value: claimInfo.get("claim_ref") },
      ],
      period: [
        { label: "From", value: claimInfo.get("claim_from") },
        { label: "To", value: claimInfo.get("claim_to") },
      ],
      claim_issued: [
        {
          label: `${claimWord} Issued`,
          value: convertUTCToTime(
            projectAllInfo?.get("claim_issued_date"),
            projectAllInfo?.get("contract_timezone"),
            false,
            "yyyy-MM-dd",
          )[0],
        },
      ],
      due_date: [
        {
          label: `Due Date for ${claimWord}`,
          value: claimInfo.get("claim_due_date"),
          component: [
            { className: "inputStyle_days", value: dateList.claim_due_days },
            { className: "inputStyle_term", value: dateList.claim_due_term },
          ],
        },
        {
          label: "Due Date for " + wordCertNoun,
          value: claimInfo.get("cert_due_date"),
          component: [
            { className: "inputStyle_days", value: dateList.cert_due_days },
            { className: "inputStyle_term", value: dateList.cert_due_term },
          ],
        },
        {
          label: "Due Date for Payment",
          value: claimInfo.get("pay_due_date"),
          component: [
            { className: "inputStyle_days", value: dateList.payment_due_days },
            { className: "inputStyle_term", value: dateList.payment_due_term },
          ],
        },
      ],
      retention_date: [
        {
          label: "Practical Completion Date",
          value: claimInfo.getIn(["read_retention", "pcd_date"]),
        },
        {
          label: "Defects Liability Period",
          value: claimInfo.getIn(["read_retention", "dlp_date"]),
        },
      ],
    };

    return (
      <ClaimDetail
        header={wordCertNoun}
        data={data}
        has_retention={claimInfo.get("has_retention")}
        accountConfig={accountConfig}
      />
    );
  }
}
