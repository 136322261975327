import styled from "styled-components";

export const RetentionDetails = styled.div`
  .label {
    font-weight: bold;
    margin-bottom: 4px;
    line-height: 20px;
  }
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;
