import React, { useEffect, useState } from "react";

import { ReviewWrapper } from "./styled";

import { RedOutlinedBtn as Revert, GreenWhiteBtn as Approve } from "../button";
import RevertModal from "./components/revert_modal";

export default function Index({
  goToRevert,
  approvers,
  approving,
  handleApprove,
  workflowType,
}) {
  const [open, setOpen] = useState(false);
  const [revert, showRevert] = useState(false);
  const [currentStepId, setCurrentStepId] = useState("");
  const [preStepApprovers, setPreStepApprovers] = useState([]);

  useEffect(() => {
    const workflow = approvers.toJS();
    const currentStep = workflow.current_step_index;
    const totalSteps = workflow?.steps?.length;

    const currentStepInfo = workflow?.steps?.find(
      (item) => item.step === currentStep,
    );
    const preStepInfo = workflow?.steps?.find(
      (item) => item.step === currentStep - 1,
    );

    if (currentStepInfo) {
      setCurrentStepId(currentStepInfo.id);
    }

    if (preStepInfo) {
      setPreStepApprovers(preStepInfo?.approvals || []);
    }

    const shouldShowRevert = totalSteps > 3 && currentStep > 1;
    showRevert(shouldShowRevert);
  }, [approvers]);

  return (
    <>
      <ReviewWrapper className={`button-wrap ${workflowType}`}>
        {revert && (
          <Revert
            title={"Revert to Previous Approval Step"}
            upperCase
            margin={"0 24px 0 0"}
            onClick={() => setOpen(true)}
          />
        )}
        <Approve
          title="Approve"
          submitting={approving}
          onClick={handleApprove}
        />
      </ReviewWrapper>
      <RevertModal
        open={open}
        closeDialog={() => setOpen(false)}
        goToRevert={(comment) => {
          goToRevert(comment, currentStepId);
          setOpen(false);
        }}
        workflowType={workflowType}
        approvers={preStepApprovers}
      />
    </>
  );
}
