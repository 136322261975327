import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ClaimTableWrapper } from "./review_claim_style";
import TableComponent from "./basic_table";
import CommentList from "./comment_table";

import { convertToPercent, roundNearest } from "../../utils/numeralHelper";
import {
  convertToCurrencyUnit,
  currencySubunitToUnit,
} from "../../utils/currencyHelper";
import {
  dropDecimalColumns,
  headerFormatter,
} from "../../utils/tableFormatHelper";
import profile from "../../utils/profile";
import { CONTRACT, EDIT, LUMP } from "../../utils/constants";
import { countComments } from "./table_utils";
import {
  ProvisionalDescription,
  getExpandProvisionalParents,
} from "../lineitem/sharedComponent";

class Table extends Component {
  constructor() {
    super();
    this.state = {
      expands: [],
      allowEditContract: true,
    };
  }

  isTreeExpanded = (id) => {
    return this.state.expands.includes(id);
  };

  expandProvisionalParent = (line) => {
    let expanded = getExpandProvisionalParents(line, this.state.expands);
    this.setState({ expands: expanded });
  };

  render() {
    const { expands, allowEditContract } = this.state;
    const {
      title,
      tableContent,
      calculateClaimTotal,
      totalTable,
      allowAddComment,
      commentViewOnly,
      updateComment,
      accountConfig,
      isIssued,
    } = this.props;
    let dropDecimal = dropDecimalColumns(tableContent.toJS(), [
      "quantity",
      "rate",
      "total",
      "claim_value",
    ]);
    const showCurrency = !accountConfig?.get("enable_header_currency");
    const claimTerm = accountConfig.get("claim");
    const ClaimedTotal = (
      <span className="claimed-total">
        {headerFormatter(
          `${claimTerm?.get("value")} Total`,
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        )}
      </span>
    );

    // base/variation columns
    const columns_dev = [
      {
        title: "Ref",
        className: "align_left lineitem-ref",
        render: "label",
        width: 81,
      },
      {
        title: "Description",
        className: "align_left",
        width: 336,
        treeColumnsName: "childitems",
        render: (d) => (
          <div>
            <ProvisionalDescription
              data={d}
              isUpdateExpanded={() => {
                return false;
              }}
              isTreeExpanded={this.isTreeExpanded}
              expandProvisionalParent={this.expandProvisionalParent}
            />
          </div>
        ),
      },
      {
        title: "Qty",
        className: "th_align_left",
        width: 96,
        render: (d) => {
          if (d.lineitem_options === LUMP || d.childitems?.length > 0) {
            return <div className="grey-dash"></div>;
          } else {
            return convertToCurrencyUnit(
              dropDecimal["quantity"] ? roundNearest(d.quantity) : d.quantity,
              false,
            );
          }
        },
      },
      {
        title: "Unit",
        className: "th_align_left",
        render: (d) => {
          if (d.lineitem_options === LUMP || d.childitems?.length > 0) {
            return <div className="grey-dash"></div>;
          } else {
            return d.unit;
          }
        },
      },
      {
        title: headerFormatter(
          "Rate",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        className: "th_align_left",
        width: 116,
        render: (d) => {
          if (d.lineitem_options === LUMP || d.childitems?.length > 0) {
            return <div className="grey-dash"></div>;
          } else {
            return currencySubunitToUnit(
              dropDecimal["rate"] ? roundNearest(d.rate) : d.rate,
              showCurrency,
            );
          }
        },
      },
      {
        title: headerFormatter(
          "Total",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        className: "th_align_left",
        render: (d) => {
          let total = d.total;
          return currencySubunitToUnit(
            dropDecimal["total"] ? roundNearest(total) : total,
            showCurrency,
          );
        },
        width: 156,
      },
      {
        title: `${claimTerm?.get("value")} Qty | %`,
        className: "th_align_left",
        width: 187,
        render: (d) => {
          return (
            <div className="percent_qty">
              <div>
                {d.lineitem_options === LUMP || d.childitems?.length > 0 ? (
                  <div className="grey-dash"></div>
                ) : (
                  d.claim_qty
                )}
              </div>
              <div>
                {d.claim_percent
                  ? convertToPercent(d.claim_percent / 100)
                  : convertToPercent(0)}
              </div>
            </div>
          );
        },
      },
      {
        className: "hidden th_align_left claimTotal",
        width: 160,
        group: ClaimedTotal,
        render: (d) => (
          <div className="inlineDiv">
            <div>
              {currencySubunitToUnit(
                dropDecimal["claim_value"]
                  ? roundNearest(d.claim_value)
                  : d.claim_value,
                showCurrency,
                dropDecimal["claim_value"],
              )}
            </div>
          </div>
        ),
      },
      {
        className: "hidden th_align_left reviewClaimComment",
        width: 40,
        group: ClaimedTotal,
        render: (d) => (
          <div className="commentDiv">
            {!isIssued
              ? allowAddComment && (
                  <CommentList
                    data={d}
                    expands={expands}
                    onTreeExpand={(key) => {
                      return this.setState({
                        expands: [...this.state.expands, key],
                      });
                    }}
                    updateComment={updateComment}
                    type={"claim"}
                    viewOnly={commentViewOnly || !allowEditContract}
                  />
                )
              : countComments(d, d?.id) > 0 && (
                  <CommentList
                    data={d}
                    expands={expands}
                    onTreeExpand={(key) => {
                      return this.setState({
                        expands: [...this.state.expands, key],
                      });
                    }}
                    updateComment={updateComment}
                    type={"claim"}
                    viewOnly={true}
                  />
                )}
          </div>
        ),
      },
    ];

    return (
      <ClaimTableWrapper>
        <TableComponent
          onTreeExpand={(keys) => this.setState({ expands: keys })}
          treeExpandKeys={expands}
          tableContent={tableContent.toJS()}
          tableColumns={columns_dev}
          tableCaption={title}
          hasSort
          externalSortOptions={{
            claim_percent: `${claimTerm?.get("value")} %`,
          }}
          hasEnhancedTableFooter
        >
          <table>
            <tfoot>
              <tr>
                <td className="empty" />
                <td className="title">Sub Total</td>
                <td className="rate">
                  {convertToCurrencyUnit(totalTable, showCurrency)}
                </td>
                <td className="claimed">
                  {" "}
                  {convertToCurrencyUnit(calculateClaimTotal, showCurrency)}
                </td>
              </tr>
            </tfoot>
          </table>
        </TableComponent>
      </ClaimTableWrapper>
    );
  }
  componentDidMount() {
    const { proId, contractId } = this.props.match.params;
    profile.checkPermission(CONTRACT, EDIT, proId, contractId).then((res) => {
      this.setState({ allowEditContract: res });
    });
  }
}

export default withRouter(Table);
