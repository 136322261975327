import React from "react";
import {
  withStyles,
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { CloseIcon } from "./dialogStyle";
import * as themeStyle from "../../../theme";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 24,
    color: themeStyle.palette.gunmetal,
    fontFamily: themeStyle.font.mainFont,
    fontWeight: themeStyle.font.mainFontWeight,
    padding: "40px",
    textTransform: "capitalize",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.title} {...other}>
      {children}
      {onClose ? <CloseIcon onClick={onClose} /> : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    color: themeStyle.palette.gunmetal,
    padding: 0,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: "20px 40px",
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const theme = createTheme({
    overrides: {
      MuiDialog: {
        paperWidthSm: {
          maxWidth: "1270px",
          minWidth: "400px",
          height: props.height || "auto",
        },
      },
    },
  });
  let isDividers = props.divider !== undefined ? false : true;
  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
          {props.title}
        </DialogTitle>
        <DialogContent dividers={isDividers}>{props.children[0]}</DialogContent>
        <DialogActions>{props.children[1]}</DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
}
