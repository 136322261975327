import React from "react";
import PropTypes from "prop-types";
import { HistoryTableStyle, NoticeImage } from "./style";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { currencySubunitToUnit } from "../../utils/currencyHelper";

//used for making a claim page:
//status == "lineitem" : last three months claim history
//status == "payment-history" : payment history for claim
function BootstrapTooltip(props) {
  const { accountConfig } = props;
  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: "#fff",
      fontSize: 24,
    },
    tooltip: {
      position: "relative",
      maxWidth: "984px",
      backgroundColor: "#fff",
      borderRadius: "4px",
      boxShadow: "0 2px 4px 0 rgb(0 0 0 / 10%)",
      pointerEvents: "auto",
    },
  }));
  const classes = useStylesBootstrap();
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
    if (props.status === "lineitem") {
      return props.getTable();
    }
  };
  const content = (
    <>
      {props.status === "payment-history" ? (
        <HistoryTableStyle className="payment">
          <div className="title">
            Payment History
            <div className="img_close" onClick={handleTooltipClose} />
          </div>
          <table className="table">
            <thead className="thead paymentHeader">
              <tr>
                <td colSpan="5" style={{ backgroundColor: "#fff" }} />
                <td colSpan="2" className="tdTitleItem titleSum">
                  {" "}
                  RECEIVED AMOUNT
                </td>
              </tr>
              <tr style={{ backgroundColor: "#8e99a1" }}>
                <td className="tdTitleItem claim">
                  {accountConfig?.getIn(["claim", "noun"])} #
                </td>
                <td className="tdTitleItem claim">
                  Net {accountConfig?.getIn(["claim", "value"])}
                </td>
                <td className="tdTitleItem claim">Cert #</td>
                <td className="tdTitleItem claim">Net Certified</td>
                <td className="tdTitleItem total">
                  {accountConfig?.getIn(["claim", "noun"])} Period (End)
                </td>
                <td className="tdTitleItem total"> Excluding GST</td>
                <td className="tdTitleItem total"> Including GST</td>
              </tr>
            </thead>
            <tbody className="tbody">
              {props.title.map((item) => (
                <tr key={item.get("cert_number")}>
                  <td className="tdItem actions ">
                    {item.get("claim_number")}
                  </td>
                  <td className="tdItem total">
                    {currencySubunitToUnit(item.get("net_claimed_value"))}
                  </td>
                  <td className="tdItem actions">{item.get("cert_number")}</td>
                  <td className="tdItem total">
                    {currencySubunitToUnit(item.get("net_certified_value"))}
                  </td>
                  <td className="tdItem actions">{item.get("claim_period")}</td>
                  <td className="tdItem total">
                    {currencySubunitToUnit(item.get("exclude_paid_amount"))}
                  </td>
                  <td className="tdItem total">
                    {currencySubunitToUnit(item.get("paid_amount"))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </HistoryTableStyle>
      ) : (
        <HistoryTableStyle>
          <div className="title"> Last 3 Months</div>
          <table className="table">
            <thead className="thead">
              <tr>
                <td className="tdTitleItem claim">
                  {accountConfig?.getIn(["claim", "noun"])} Reference
                </td>
                <td className="tdTitleItem date">From</td>
                <td className="tdTitleItem date">To</td>
                <td className="tdTitleItem percent_qty">
                  {accountConfig?.getIn(["claim", "noun"])} Qty | %
                </td>
                <td className="tdTitleItem total">
                  {accountConfig?.getIn(["claim", "noun"])} Total
                </td>
              </tr>
            </thead>
            <tbody className="tbody">
              {props.title.map((item) => (
                <tr key={item.claim_reference}>
                  <td className="tdItem desItem ">
                    {item.claim_reference || ""}
                  </td>
                  <td className="tdItem actions ">{item.claim_from}</td>
                  <td className="tdItem actions">{item.claim_to}</td>
                  <td className="tdItem actions">
                    <div className="percent_qty">
                      <div>
                        {props?.isParent !== undefined && props?.isParent
                          ? "-"
                          : item.quantity || "-"}
                      </div>
                      <div>{item.percent || 0}%</div>
                    </div>
                  </td>
                  <td className="tdItem total">
                    {currencySubunitToUnit(item.claim_total)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </HistoryTableStyle>
      )}
    </>
  );

  return (
    <Tooltip
      arrow
      classes={classes}
      {...props}
      PopperProps={{
        disablePortal: true,
      }}
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
      open={open}
      trigger="click"
      disableHoverListener={props.status !== "lineitem"}
      title={content}
      placement="top-end"
    >
      <NoticeImage
        className={
          props.status === "lineitem" ? "notice_lineitem" : "notice_payment"
        }
        id={props.id || "payment"}
        onClick={handleTooltipOpen}
      />
    </Tooltip>
  );
}

BootstrapTooltip.propTypes = {
  title: PropTypes.node,
};

export default BootstrapTooltip;
