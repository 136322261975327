import styled from "styled-components";
import * as theme from "../../../theme";
import uploadingIcon from "../../../statics/img/icon-uploading.svg";

export const UploadFile = styled.div`
  .file_upload {
    padding: 10px 16px;
    .dropzone {
      height: 80px;
      background-color: ${theme.palette.veryColdGrey};
      border-radius: 4px;
      border: 2px dashed rgb(198, 204, 208);
    }
    border-bottom: 1px solid ${theme.palette.wolfGrey};
  }
  .file_item {
    box-sizing: border-box;
    padding: 10px 0;
    font-size: 14px;
    border-bottom: 1px solid ${theme.palette.lightWolfGrey};
  }
`;

export const UploadProgressBar = styled.div`
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  width: ${(props) => props.width || "100%"};
  display: grid;
  grid-template-rows: 24px 5px;
  grid-row-gap: 6px;

  .info {
    display: grid;
    grid-template-columns: 24px calc(60% - 34px) calc(40% - 10px);
    grid-column-gap: 10px;
    color: ${theme.palette.veryMediumGrey};
    line-height: 24px;
    height: 24px;
    max-width: ${(props) => props.width || "100%"};

    .spin-icon {
      background: url(${uploadingIcon}) no-repeat;
      animation: spin 1s linear infinite;
      margin: 0;
      width: 24px;
      height: 24px;
    }
    .title {
      height: 24px;
      text-align: left;
      color: ${theme.palette.wolfGrey};
      display: flex;

      .file-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(100% - 60px);
        font-weight: bold;
        display: inline-block;
      }
      .file-size {
        width: 50px;
        margin-left: 8px;
        right: 0px;
        color: ${theme.palette.veryMediumGrey};
        font-size: 14px;
        display: inline-block;
      }
    }
    .message {
      text-align: right;
      margin-right: 12px;
    }
  }

  .progress-bar {
    width: 100%;
    background-color: ${theme.palette.veryColdGrey};
    height: 5px;
    .progress {
      background-color: ${theme.palette.turquoiseBlue};
      height: 5px;
    }
  }
`;
