import React, { useEffect, useState } from "react";

import { TableStyle, CellSubtitle } from "./styled";
import { checkIsParent, findTreeParentIds } from "../../../../utils/helper";
import TableComponent from "../../../../common/table/basic_table";
import { roundNearest } from "../../../../utils/numeralHelper";
import { currencySubunitToUnit } from "../../../../utils/currencyHelper";
import { dropDecimalColumns } from "../../../../utils/tableFormatHelper";
import { LUMP, MATERIAL } from "../../../../utils/constants";
import {
  tableDataParam,
  getElementKeyByLabel,
  getKeyByLabel,
} from "../../common/constants";

function convertToDesiredFormat(lineItem, path = "") {
  let result = [];
  lineItem.forEach((item) => {
    const newItem = { ...item };

    const newPath = path ? `${path} / ${item.description}` : item.description;

    newItem.subtitle = path;
    result.push(newItem);

    // Recursively process child items if the current item is a parent
    if (checkIsParent(item)) {
      const childItems = convertToDesiredFormat(item.childitems, newPath);
      result.push(...childItems);
    }
  });
  return result;
}

function convertToDesiredFormatMaterial(lineItem, path = "") {
  // Use map to create a new array with modified elements
  let result = lineItem.map((item) => ({
    ...item,
    subtitle: path + " / " + (item.on_site ? "On Site" : "Off Site"), // Modify the subtitle property
  }));
  return result;
}

export default function Index({ contractInfo, accountConfig, setExpandKeys }) {
  const [data, setData] = useState([]);

  const handleRowClick = (d) => {
    let elementType = getElementKeyByLabel(d.subtitle);
    let dataKey = getKeyByLabel(d.subtitle);
    const el = document.querySelector(`#${elementType}_name_${d.id}`);
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      el.click();
    } else {
      if (d.type === "childitem") {
        let tree = contractInfo?.get(dataKey).toJS();
        let keys = findTreeParentIds(tree, d.id);
        setExpandKeys(keys, true, elementType);
      }
    }
  };

  useEffect(() => {
    const extractItems = (key, label) => {
      if (contractInfo?.get(key)) {
        let items = [];
        if (key.includes("material")) {
          items = convertToDesiredFormatMaterial(
            contractInfo?.get(key).toJS(),
            label,
          );
        } else {
          items = convertToDesiredFormat(contractInfo?.get(key).toJS(), label);
        }
        return items || [];
      }
      return [];
    };

    const res = [];

    tableDataParam.forEach(({ key, label }) => {
      const extractedItems = extractItems(key, label);
      res.push(...extractedItems); // Use push to add elements to res array
    });
    setData(
      res.filter(
        (obj) =>
          obj.exclude_retention &&
          (!obj.childitems || obj.childitems.length < 1),
      ),
    );
  }, [contractInfo]);

  const checkGreyDash = (line, field) => {
    if (line.type === MATERIAL) {
      return true;
    }
    if (field !== "total") {
      return (
        line.lineitem_options === LUMP ||
        line.childitems?.length > 0 ||
        !line[field]
      );
    } else {
      return false;
    }
  };
  const showCurrency = !accountConfig.get("enable_header_currency");

  let dropDecimal = dropDecimalColumns(data, ["quantity", "rate", "total"]);
  const columns = [
    { title: "Ref", className: "lineitem-ref", render: "label", width: "7%" },
    {
      title: "Description",
      className: "align_left",
      width: "31%",
      render: (d) => (
        <div id={`name_${d.id}`}>
          {d.description}
          {d?.subtitle && <CellSubtitle>{d?.subtitle}</CellSubtitle>}
        </div>
      ),
    },
    {
      title: "Qty",
      width: "14%",
      render: (d) => {
        if (checkGreyDash(d, "quantity")) {
          return <div className="grey-dash"></div>;
        } else {
          return currencySubunitToUnit(
            dropDecimal["quantity"] ? roundNearest(d.quantity) : d.quantity,
            false,
            false,
          );
        }
      },
    },
    {
      title: "Unit",
      render: (d) => {
        if (checkGreyDash(d, "unit")) {
          return <div className="grey-dash"></div>;
        } else {
          return d.unit;
        }
      },
      width: "9%",
    },
    {
      title:
        "Rate" +
        (accountConfig?.get("enable_header_currency")
          ? ` (${accountConfig?.getIn(["currency", "code"])})`
          : ""),
      width: "15%",
      render: (d) => {
        if (checkGreyDash(d, "rate")) {
          return <div className="grey-dash"></div>;
        } else {
          return currencySubunitToUnit(
            dropDecimal["rate"] ? roundNearest(d.rate) : d.rate,
            showCurrency,
            false,
            false,
          );
        }
      },
    },
    {
      title:
        "Total" +
        (accountConfig?.get("enable_header_currency")
          ? ` (${accountConfig?.getIn(["currency", "code"])})`
          : ""),
      width: "15%",
      render: (d) => {
        if (checkGreyDash(d, "total")) {
          return <div className="grey-dash" />;
        } else {
          return currencySubunitToUnit(
            dropDecimal["total"] ? roundNearest(d.total) : d.total,
            showCurrency,
            false,
            false,
          );
        }
      },
    },
  ];

  return (
    <div>
      {data?.length > 0 && (
        <TableStyle>
          <div className="table-caption">Items Exclude From Retention</div>
          <TableComponent
            tableContent={data}
            tableColumns={columns}
            hideTableToolbar
            onRowClick={(d, index) => handleRowClick(d, index)}
            tableRef={(t) => setTable(t)}
          ></TableComponent>
        </TableStyle>
      )}
    </div>
  );
}
