import styled, { css } from "styled-components";
import * as theme from "../../../theme";
import editIcon from "../../../statics/img/icon-edit-medium-grey.svg";
import profileIcon from "../../../statics/img/avatar.svg";
import checkIcon from "../../../statics/img/icon-check-box-check-dark-grey.svg";
import uncheckIcon from "../../../statics/img/icon-check-box-un-check-dark-grey.svg";
import claimingIcon from "../../../statics/img/image-construction.svg";
import certIcon from "../../../statics/img/image-coffee-mug.svg";
import bothRoleIcon from "../../../statics/img/image-pencil-holder.svg";
import timezoneIcon from "../../../statics/img/icon-time-zone.svg";
import { IconButton } from "../../../common/button";

const headerFontSize = css`
  font-size: 24px;
`;

export const Title = styled.div`
  font-weight: ${theme.font.mainFontWeight};
  ${headerFontSize}
  display: flex;
`;

export const ContentWrapper = styled.div`
  width: 66%;
  height: auto;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 24px;
`;

export const ImgEdit = styled(IconButton)`
  background: url(${editIcon}) no-repeat;
  float: right;
`;

export const DetailBox = styled.div`
  width: 100%;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  padding: 40px;
  box-sizing: border-box;

  &.account {
    height: 336px;
    padding-left: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .margin-top {
    margin-top: 24px;
  }
`;

export const AvatarWrapper = styled.div`
  width: 166px;
  height: auto;
  margin-right: 30px;
  .profileImg {
    height: 140px;
    width: 140px;
    background: url(${profileIcon}) no-repeat;
    background-size: contain;
    display: block;
    margin-left: 13px;
  }
  .img-container {
    height: 140px;
    width: 140px;
    margin-left: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .img {
      width: 140px;
      height: auto;
    }
  }
`;

export const DetailTitle = styled.div`
  margin-bottom: 20px;
  ${headerFontSize}
  &.email {
    font-weight: bold;
    margin-bottom: 4px;
  }
`;

export const DetailInfo = styled.div`
  width: 100%;
  margin-top: 40px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DetailPlan = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 110px;
  label {
    line-height: 16px;
    display: inline-block;
    margin-bottom: 4px;
  }
`;

export const PlanMessage = styled.div`
  background-color: #f2f2f2;
  padding: 8px 16px;
  width: 100%;
  margin-top: 16px;
`;

export const AccountDetail = styled.div`
  height: 100%;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DetailItem = styled.div`
  label {
    font-weight: ${theme.font.mainFontWeight};
    margin-bottom: 4px;
    display: inline-block;
  }
  overflow-wrap: break-word;
  width: 200px;

  &.long {
    width: 100%;
  }
`;

export const EmailInfo = styled.div`
  margin-top: 40px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  .item {
    display: flex;
    align-items: center;
    &.bottom {
      margin-top: 8px;
    }
  }
  .checkBox {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    background: url(${uncheckIcon}) no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-right: 10px;
    :checked {
      background: url(${checkIcon}) no-repeat;
    }
  }
  .email-alert-title {
    margin-bottom: 12px;
  }
  .email-summary-title {
    margin-top: 40px;
  }
`;

export const RoleInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${theme.palette.veryMediumGrey};
  line-height: 20px;

  .role-info {
    .title {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: ${theme.palette.gunmetal};
      margin-bottom: 4px;
      text-transform: capitalize;
    }
    margin-left: 16px;
  }
  .role-image {
    width: 64px;
    height: 64px;
    background: ${(props) => {
        let url = "";
        switch (props.img) {
          case "claiming":
            url = claimingIcon;
            break;
          case "certifying":
            url = certIcon;
            break;
          case "claim_cert":
            url = bothRoleIcon;
            break;
          default:
            url = "";
        }
        return `url(${url})`;
      }}
      no-repeat;
    background-size: contain;
  }
`;

export const TimezoneIcon = styled.div`
  height: 24px;
  width: 24px;
  background: url(${timezoneIcon}) no-repeat;
  margin-right: 14px;
  background-size: auto;
  background-position: center;
`;

export const DropdownComponent = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 8px;
  width: 450px;

  .sub-text {
    font-size: 12px;
  }
`;

export const ModalContent = styled.div`
  width: 960px;
  max-width: 960px;
  height: auto;
  padding: 0 40px 40px;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 32px;
`;

export const ModalButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;

  button {
    width: 96px;
  }
`;
