import React, { useEffect } from "react";

import { SectionIcon } from "../../../project/styled";
import { SwitchWrap } from "../../styled";

import { BlueOutlinedBtn as ButtonSecondary } from "../../../../common/button";
import { AntSwitch } from "../../../../common/switch";
import SimpleBaseTable from "../../../../common/lineitem/simpleBaseTable";
import MaterialClaimTable from "../../../../common/table/material_table";

import Link from "../../../../common/linkWithQuery";

const Table = (props) => {
  if (props.tableType === "claim") {
    return <MaterialClaimTable {...props}>{props.children}</MaterialClaimTable>;
  } else {
    return <SimpleBaseTable {...props}>{props.children}</SimpleBaseTable>;
  }
};

const RenderTable = (props) => {
  const {
    table,
    sectionTitle,
    proId,
    contractId,
    expand,
    outstandingClaims,
    switchOnExpand,
    readOnly,
    hasExpand,
    type,
    tableContent,
    hasSort,
    downloadFile,
    ...otherProps
  } = props;

  let tableType = expand ? "claim" : "";
  return (
    <Table
      tableContent={tableContent ? tableContent : table}
      tableCaption={
        sectionTitle && (
          <div>
            <SectionIcon img="materials" />
            <span>{sectionTitle}</span>
          </div>
        )
      }
      tableType={tableType}
      sectionTitle={sectionTitle}
      proId={proId}
      contractId={contractId}
      outstandingClaims={outstandingClaims}
      hasSort={hasSort === undefined ? true : hasSort}
      isTableSubtitleBold={true}
      downloadFile={downloadFile}
      {...otherProps}
    >
      <>
        {hasExpand && (
          <SwitchWrap>
            <AntSwitch
              checked={expand}
              onChange={(event) => switchOnExpand(event)}
              id="expand"
            />
            <label htmlFor="expand" style={{ marginLeft: 16 }}>
              Show values to date
            </label>
          </SwitchWrap>
        )}
        {!readOnly && (
          <Link to={`/contract/${proId}/${type}/${contractId}/materials`}>
            <ButtonSecondary title="Edit" className="edit-button" />
          </Link>
        )}
      </>
    </Table>
  );
};

export default function LineItems(props) {
  const {
    contractInfo,
    itemsType,
    hasExpand,
    downloadFile,
    outstandingClaims,
    readOnly,
    accountConfig,
    setExpanded,
    tableContent,
    tableTitle,
    hasSort,
    isExpanded,
  } = props;
  const { proId, contractId, type } = props.match.params;

  const [expand, setExpand] = React.useState(false);

  useEffect(() => {
    if (isExpanded !== undefined) {
      setExpand(isExpanded);
      setExpanded(isExpanded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  const switchOnExpand = (event) => {
    setExpand(event.target.checked);
    setExpanded(event.target.checked);
  };

  const sectionTitle = tableTitle !== undefined ? tableTitle : "",
    table = tableContent;

  return (
    <RenderTable
      table={table}
      itemsType={itemsType}
      sectionTitle={sectionTitle}
      contractInfo={contractInfo}
      downloadFile={downloadFile}
      tableContent={tableContent}
      proId={proId}
      type={type}
      contractId={contractId}
      outstandingClaims={outstandingClaims}
      accountConfig={accountConfig}
      expand={expand}
      switchOnExpand={switchOnExpand}
      marginLeft={16}
      {...props}
      readOnly={readOnly}
      hasExpand={hasExpand}
      hasSort={hasSort}
    />
  );
}
