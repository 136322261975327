import { httpRequest } from "../base/httpRequest";

const DASHBOARD_URL = `/api/dashboard/account/accId`;

const read_certifier_group_table = (params) =>
  httpRequest("get", `${DASHBOARD_URL}/certifier_table`, null, { params });

const read_task_list = () => httpRequest("get", `${DASHBOARD_URL}/tasks`);

const read_claims_summary = (params) =>
  httpRequest("get", `${DASHBOARD_URL}/claims-summary`, null, { params }); //result

export default {
  read_certifier_group_table,
  read_task_list,
  read_claims_summary,
};
