import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { ModalContent } from "../styled";
import { GreenWhiteBtn as Button } from "../../../common/button";
import Modal from "../../../common/modal/component/modalBase";
import Input from "../../../common/form";

import { sortByLabel as compare } from "../../../utils/helper";
import { ACCESS, ACCOUNT, PROJECT_ADMIN } from "../../../utils/constants";
import accountPermissions from "../../../utils/accountPermissions";

import { actionCreators } from "../store";

const forbiddenReason = {
  noAccountPermission:
    "You cannot use this account because you do not have admin access",
  noProjectPermission:
    "You cannot use this project because you do not have admin access",
  notAllowSameAccount:
    "You cannot accept with the same account as the other party",
  notSameCountry:
    "You cannot accept with this account because it is in a different country to the contract",
  notProjectAdmin: "You need to have admin access to create projects",
};

const Selection = (props) => {
  const {
    open,
    setOpen,
    projectList,
    contactList,
    acceptInvite,
    invitedId,
    invitationType,
    readProjectList,
    readContactList,
    inviteAccount,
    accountList,
  } = props;
  const [accounts, setAccounts] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isAccepted, setIsAccepted] = useState(false);

  const [info, setInfo] = useState({ account: {} });
  const Dropdown = (props) => (
    <Input
      field="dropdown"
      width="424px"
      wrapperClassName="has-margin"
      {...props}
    />
  );

  useEffect(() => {
    if (open) {
      setInfo({ ...info, account: "", contact: "", project: "" });
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (accountList.size !== 0 && inviteAccount) {
      let accounts = [];
      let subLabel = "";
      let disabled = false;
      accountList.map((acc) => {
        subLabel = null;
        accountPermissions.setAccount(acc.toJS());

        if (inviteAccount.get("id") === acc.get("account_id")) {
          subLabel = forbiddenReason.notAllowSameAccount;
          disabled = true;
        } else if (inviteAccount.get("country") !== acc.get("country")) {
          disabled = true;
          subLabel = forbiddenReason.notSameCountry;
        } else {
          if (accountPermissions.checkPermission(ACCOUNT, ACCESS)) {
            // Now check if admin at account level, then allow
            disabled = false;
            subLabel = null;
          } else {
            props.readProjectList(acc.get("account_id"));
            disabled = true;
            subLabel = forbiddenReason.noAccountPermission;
          }
        }
        accounts.push({
          label: acc.get("account_name"),
          subLabel: subLabel,
          value: acc.get("account_id"),
          isDisabled: disabled,
        });
        return acc;
      });

      setAccounts(accounts.sort(compare));
    }

    // eslint-disable-next-line
  }, [inviteAccount, readContactList, readProjectList, accountList]);

  useEffect(() => {
    let accountProjects = projectList;
    let selectedAccount = accountList?.find(
      (acc) => acc.get("account_id") === info?.account,
    );

    if (selectedAccount) {
      accountPermissions.setAccount(selectedAccount.toJS());
    }
    let selectedAccountHasPermission = accountPermissions.checkPermission(
      ACCOUNT,
      ACCESS,
    );

    if (projectList.length > 0) {
      let hasProjectAccess = false;

      // From the Project list check if User has Project Admin Access against the account
      projectList.every((prj) => {
        if (prj?.user_role === PROJECT_ADMIN) {
          hasProjectAccess = true;
          return false;
        }
        return true;
      });

      // Get the Account ID from the Project List
      let accId = projectList[0]?.accountId;

      let updatedAccountPermissions = accounts.map((item, i) => {
        let projectAccount = accountList?.find(
          (obj) => obj.get("account_id") === item?.value,
        );
        let projectAccountAdminPermission = true;

        if (projectAccount) {
          accountPermissions.setAccount(projectAccount.toJS());
          projectAccountAdminPermission = accountPermissions.checkPermission(
            ACCOUNT,
            ACCESS,
          );
        }

        // Go through account projects and enable parent account if have permissions
        accountProjects = projectList.map((pr) => {
          let project = {
            ...pr,
            isDisabled:
              pr?.user_role !== PROJECT_ADMIN && !selectedAccountHasPermission,
            subLabel:
              pr?.user_role !== PROJECT_ADMIN && !selectedAccountHasPermission
                ? forbiddenReason.noProjectPermission
                : false,
          };
          return project;
        });

        if (projectAccount && projectAccountAdminPermission) {
          return item;
        } else if (item.value === accId) {
          return {
            ...item,
            isDisabled: !hasProjectAccess,
            subLabel: hasProjectAccess
              ? null
              : forbiddenReason.noAccountPermission,
          };
        }
        return item;
      });
      setAccounts(updatedAccountPermissions);
    }

    // Permissions for the Create New Project
    accountProjects.unshift({
      label: "Create a new project based on this contract",
      value: "new",
      isDisabled: !selectedAccountHasPermission,
      subLabel: !selectedAccountHasPermission
        ? forbiddenReason.notProjectAdmin
        : null,
    });

    setProjects(accountProjects);
    // eslint-disable-next-line
  }, [projectList]);

  const changeAccount = (accountId) => {
    if (accountId !== info.account) {
      setInfo({ ...info, account: accountId, contact: "", project: "" });
      props.readContactList(accountId);
      props.readProjectList(accountId);
    }
  };
  const handleChange = (name, value) => {
    setInfo({ ...info, [name]: value });
  };

  const performAccept = (invitedId, info) => {
    setIsAccepted(true);
    acceptInvite(invitedId, info);
  };

  return (
    <Modal
      open={open}
      title={"Accept Contract"}
      divider={false}
      width={"640px"}
      handleClose={() => setOpen(false)}
    >
      <ModalContent>
        <div>
          <div className="content has-margin">
            Which account do you want to add this contract to?
          </div>
          <Dropdown
            placeholder="Select Account"
            value={info.account}
            options={accounts}
            width={560}
            height={50}
            boldOnSelected={true}
            usePortal={true}
            onChange={(e) => changeAccount(e.value)}
          />

          <div className="content has-margin">
            Select which project you want this contract to be a part of.
          </div>
          <Dropdown
            placeholder="Select Project"
            value={info.project}
            width={560}
            height={50}
            separateFirstOption
            boldOnSelected={true}
            options={projects}
            usePortal={true}
            onChange={(e) => handleChange("project", e.value)}
          />

          <div className="content has-margin">
            Which person in your organisation do you want to be the key contact?
          </div>
          <Dropdown
            placeholder={"Select " + invitationType + " Contact"}
            value={info.contact}
            width={560}
            height={50}
            boldOnSelected={true}
            options={contactList.sort(compare)}
            usePortal={true}
            onChange={(e) => handleChange("contact", e.value)}
          />
        </div>
      </ModalContent>
      <div>
        <Button
          title="Accept contract"
          onClick={() => performAccept(invitedId, info)}
          disabled={
            !info.project || !info.account || !info.contact || isAccepted
          }
        />
      </div>
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  contactList: state.getIn(["invite", "contactList"]),
  projectList: state.getIn(["invite", "projectList"]),
  accountList: state.getIn(["headers", "accountList"]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    readProjectList(accountId) {
      dispatch(actionCreators.setProjectOption(accountId));
    },
    readContactList(accountId) {
      dispatch(actionCreators.setContactOption(accountId));
    },
    acceptInvite(inviteId, data) {
      if (data.project === "new") {
        dispatch(actionCreators.createProject(inviteId, data));
      } else {
        // based on the invitation info to accept
        dispatch(
          actionCreators.acceptInvitation(
            inviteId,
            data.project,
            data.contact,
            data.account,
          ),
        );
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Selection);
