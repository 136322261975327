import { CONTRACT_TYPE } from "../../../utils/constants";

export const OverviewPageUrlContractType = (contractType) => {
  return [CONTRACT_TYPE.cert, CONTRACT_TYPE.selfClaim].includes(contractType)
    ? CONTRACT_TYPE.cert
    : CONTRACT_TYPE.claim;
};

export const GetContractOverviewLink = (
  accountId,
  contractType,
  proId,
  contractId,
) =>
  `/contract/${proId}/${OverviewPageUrlContractType(
    contractType,
  )}/${contractId}?account_id=${accountId}`;
