import { fromJS } from "immutable";
import { constants } from ".";

const defaultState = fromJS({
  countriesList: [],
  accountConfig: {},
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SET_COUNTRIES:
      return state.set("countriesList", action.payload);
    case constants.SET_DATA:
      return state.set(action.name, fromJS(action.data));
    case constants.SET_PUBLIC_IP_ADDRESS_DETAILS:
      return state.set("ipAddressDetails", action.payload);
    default:
      return state;
  }
};
