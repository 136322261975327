import React, { Component } from "react";
import { connect } from "react-redux";
import { fromJS } from "immutable";

import ReviewClaim from "./pages/reviewClaim";
import IssuedClaim from "./pages/reviewIssuedClaim";
import CertifiedClaim from "./pages/reviewApprovedClaim";
import profile from "../../utils/profile";
import {
  CONTRACT,
  CLAIM_CERT,
  STATUS_DRAFT,
  STATUS_APPROVED,
  STATUS_CERTIFIED,
  STATUS_ISSUE,
} from "../../utils/constants";

import Loading from "../../common/loading";

import { actionCreators } from "./store";
import { actionCreators as actionCreatorsContract } from "../contract/store";

class index extends Component {
  state = {
    allowClaim: true,
  };
  render() {
    const { contractInfo } = this.props;
    var readOnly = !this.state.allowClaim;
    const issueInfo = fromJS({
      createDate: contractInfo?.get("claim_create_date"),
      createUser: contractInfo?.get("create_user_name"),
      issueDate: contractInfo?.get("claim_issued_date"),
      issueUser: contractInfo?.get("issue_user_name"),
    });
    const timeZone = contractInfo?.get("contract_timezone");
    return (
      <>
        {contractInfo.get("status") ? (
          <>
            {contractInfo.get("status") === STATUS_DRAFT && (
              <ReviewClaim
                {...this.props}
                readOnly={readOnly}
                issueInfo={issueInfo}
                timeZone={timeZone}
              />
            )}
            {contractInfo.get("status") === STATUS_APPROVED && (
              <ReviewClaim
                {...this.props}
                readOnly={readOnly}
                issueInfo={issueInfo}
                timeZone={timeZone}
              />
            )}
            {contractInfo.get("status") === STATUS_ISSUE && (
              <IssuedClaim
                {...this.props}
                readOnly={readOnly}
                issueInfo={issueInfo}
                timeZone={timeZone}
              />
            )}
            {contractInfo.get("status") === STATUS_CERTIFIED && (
              <CertifiedClaim
                {...this.props}
                readOnly={readOnly}
                issueInfo={issueInfo}
                timeZone={timeZone}
              />
            )}
          </>
        ) : (
          <Loading title="Loading info" />
        )}
      </>
    );
  }
  componentDidMount() {
    const { proId, contractId, claimId, contractType } =
      this.props.match.params;
    this.props.getAllEntityList();
    profile
      .checkPermission(CONTRACT, CLAIM_CERT, proId, contractId)
      .then((res) => {
        this.props.readProjectInfo(proId);
        // contract info
        this.props.requestContractInfo(
          proId,
          contractId,
          claimId,
          contractType,
        );
        this.setState({ allowClaim: res });
      });
    //set contract config
    this.props.setContractConfig(proId, contractId);
  }

  componentDidUpdate(prevProps) {
    // Check if propValue has changed
    if (this.props.componentKey !== prevProps.componentKey) {
      const { proId, contractId, claimId, contractType } =
        this.props.match.params;
      this.props.requestContractInfo(proId, contractId, claimId, contractType);
    }
  }

  componentWillUnmount() {
    this.props.resetInfo();
  }
}
const mapStateToProps = (state) => ({
  accountId: state.getIn(["headers", "profile", "payclaim_account_id"]),
  baseContractTable: state.getIn(["makeaclaim", "baseContractTable"]),
  baseTotalValue: state.getIn(["makeaclaim", "baseContractTotal"]),
  variationsTable: state.getIn(["makeaclaim", "variationsTable"]),
  variationsTotal: state.getIn(["makeaclaim", "variationsTotal"]),
  baseClaimList: state.getIn(["makeaclaim", "baseClaimList"]),
  variationClaimList: state.getIn(["makeaclaim", "variationClaimList"]),
  baseClaimTotal: state.getIn(["makeaclaim", "baseClaimTotal"]),
  variationClaimTotal: state.getIn(["makeaclaim", "variationClaimTotal"]),
  paidValue: state.getIn(["makeaclaim", "paidValue"]),
  contractInfo: state.getIn(["makeaclaim", "contractInfo"]),
  projectInfo: state.getIn(["makeaclaim", "projectInfo"]),
  claimDetail: state.getIn(["makeaclaim", "claimDetail"]),
  emailToList: state.getIn(["makeaclaim", "emailToList"]),
  emailSubject: state.getIn(["makeaclaim", "emailSubject"]),
  attachedEmailFiles: state.getIn(["makeaclaim", "attachedEmailFiles"]),
  autoSendEmails: state.getIn(["makeaclaim", "autoSendEmails"]),
  isSendEmail: state.getIn(["makeaclaim", "isSendEmail"]),
  entityList: state.getIn(["contract", "companyList"]),
  claim_attachments: state.getIn(["makeaclaim", "claim_attachments"]),
  sendingEmail: state.getIn(["makeaclaim", "sendingEmail"]),
  exceedAttachLimit: state.getIn(["makeaclaim", "exceedAttachLimit"]),
  approvalList: state.getIn(["makeaclaim", "approvalList"]),
  isAuditAttached: state.getIn(["makeaclaim", "isAuditAttached"]),
  sharedAttachments: state.getIn(["makeaclaim", "sharedAttachments"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
  materialsTable: state.getIn(["makeaclaim", "materialsTable"]),
  materialsTotal: state.getIn(["makeaclaim", "materialsTotal"]),
  materialsTotalExcludeRetention: state.getIn([
    "makeaclaim",
    "materialsTotalExcludeRetention",
  ]),
  componentKey: state.getIn(["makeaclaim", "componentKey"]),
});

const mapDispatchToProps = (dispatch) => ({
  requestContractInfo(proId, contractId, claimId, contractType) {
    dispatch(
      actionCreators.requestContractInfo(
        proId,
        contractId,
        claimId,
        contractType,
      ),
    );
  },
  readProjectInfo(proId) {
    dispatch(actionCreators.readProject(proId));
  },
  getAllEntityList() {
    dispatch(actionCreatorsContract.readCompanies());
  },
  downloadPdf(proId, contractId, claimId, isDownload) {
    dispatch(
      actionCreators.downloadPdf(proId, contractId, claimId, isDownload),
    );
  },
  handleRevertDraft(proId, contractId, claimId, contractType) {
    dispatch(
      actionCreators.revertToDraft(proId, contractId, claimId, contractType),
    );
  },
  handleSetChipData(data, field) {
    dispatch(actionCreators.removeEmail(data, field));
  },
  updateComment(
    proId,
    contractId,
    claimId,
    action,
    content,
    comment_id,
    lineId,
  ) {
    dispatch(
      actionCreators.updateComment(
        proId,
        contractId,
        claimId,
        action,
        content,
        comment_id,
        lineId,
      ),
    );
  },
  deleteFile(proId, contractId, claimId, fileId) {
    dispatch(actionCreators.deleteFile(proId, contractId, claimId, fileId));
  },
  downloadFile(fileId, fileName, token, accId, isDownload) {
    dispatch(
      actionCreators.downloadFile(fileId, fileName, token, accId, isDownload),
    );
  },
  changeSharedFiles(proId, contractId, claimId, fileId, isAdd) {
    dispatch(
      actionCreators.changeSharedFiles(
        proId,
        contractId,
        claimId,
        fileId,
        isAdd,
      ),
    );
  },
  selectAttachedFile(name, value, field) {
    dispatch(actionCreators.selectAttachedFile(name, value, field));
  },
  resetInfo() {
    dispatch(actionCreators.resetStage());
  },
  setPdfAudit(proId, contractId, claimId, value) {
    dispatch(actionCreators.setAuditSetting(proId, contractId, claimId, value));
  },
  setContractConfig(proId, contractId) {
    dispatch(actionCreators.setContractConfig(proId, contractId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
