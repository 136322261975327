import React, { useState, useEffect } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { ValueInput, Wrap } from "./styled";

import Input from "common/form";
import {
  convertToCurrencyUnit,
  calculateSubunitToUnit,
} from "utils/currencyHelper";
import { DEFAULT_CURRENCY, SUMMARY_TYPE } from "utils/constants";

const Index = ({
  displayValue,
  maximum,
  accountConfig,
  handleValue,
  setErrorCheck,
  showOptions,
  summaryType,
  id,
}) => {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(0);
  const [maximumValue, setMaximumValue] = useState(0);

  const summaryLabel = `${accountConfig?.getIn([summaryType === SUMMARY_TYPE.claim ? "claim" : "cert", "verb"]) || (summaryType === SUMMARY_TYPE.claim ? "Claim" : "Certify")} All`;

  const [option, setOption] = useState({
    p: {
      label: "Partial Amount",
      value: value,
    },
    c: {
      label: summaryLabel,
      value: maximumValue,
    },
  });
  const [error, setError] = useState(false);
  const [dollarSymbol, setDollarSymbol] = useState({
    prefix: DEFAULT_CURRENCY,
    suffix: "",
  });

  const retentionOptions = showOptions !== undefined ? showOptions : true;

  useEffect(() => {
    setOption((prevOption) => ({
      ...prevOption,
      c: {
        ...prevOption.c,
        value: maximumValue,
      },
    }));
  }, [maximumValue]);

  useEffect(() => {
    setOption((prevOption) => ({
      ...prevOption,
      p: {
        ...prevOption.p,
        value: value,
      },
    }));
    setError(checkExceedMaximum(value, maximumValue));
    setErrorCheck && setErrorCheck(checkExceedMaximum(value, maximumValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, maximumValue]);

  // Update value with Units: incoming display value is in Subunits
  // Update maximum value with Units: incoming maximum value is in Subunits
  // Calculate newValue and newMaximumValue
  const newValue = calculateSubunitToUnit(displayValue);
  const newMaximumValue = calculateSubunitToUnit(maximum);

  useEffect(() => {
    if (displayValue === null) {
      setValue("");
    } else {
      setValue((prevValue) => (prevValue !== newValue ? newValue : prevValue));
    }

    setMaximumValue((prevMaximumValue) =>
      prevMaximumValue !== newMaximumValue ? newMaximumValue : prevMaximumValue,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newValue, newMaximumValue]);

  useEffect(() => {
    const prefix =
      accountConfig?.getIn(["direction", "value"]) === "LTR"
        ? accountConfig?.getIn(["currency", "title"]) || DEFAULT_CURRENCY
        : "";
    const suffix =
      accountConfig?.getIn(["direction", "value"]) === "RTL"
        ? accountConfig?.getIn(["currency", "title"]) || DEFAULT_CURRENCY
        : "";
    setDollarSymbol({ prefix, suffix });
  }, [accountConfig]);

  const checkExceedMaximum = (value, maximum) => {
    const parsedValue = value && maximum ? parseFloat(value, 10) : 0;
    return parsedValue > maximum;
  };

  const handleSelect = (field) => {
    setValue(option[field]?.value);
  };

  const onChange = (value) => {
    let parsedValue = value
      ?.replace(dollarSymbol.prefix, "")
      .replace(dollarSymbol.suffix, "")
      .replace(/,/g, "");

    parsedValue = parseFloat(parsedValue, 10);

    setShow(true);
    setValue(parsedValue);
  };

  // Handle click away: update value with units if incoming display value in units differs
  const handleClickAway = () => {
    if (show && newValue !== value) {
      handleValue(value);
    }
    setShow(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Wrap>
        <ValueInput error={error}>
          {error && <div className="errorIcon" />}
          <Input
            id={id}
            className="number-style"
            field="number"
            onChange={(e) => onChange(e.target.value)}
            value={value}
            decimalScale={2}
            onFocus={() => setShow(true)}
            prefix={dollarSymbol.prefix}
            suffix={dollarSymbol.suffix}
          />
          {retentionOptions && (
            <div className="icon" onClick={() => setShow(!show)} />
          )}
        </ValueInput>
        {retentionOptions && show && (
          <div className="dropdown">
            {Object.keys(option).map((field) => {
              const item = option[field],
                showError =
                  field === "p" && checkExceedMaximum(item.value, maximumValue);
              return (
                <div
                  className={"option " + `${showError ? "error" : ""}`}
                  key={item.label}
                  onClick={() => handleSelect(field)}
                >
                  <div>{item.label}</div>
                  <div className="text">
                    {convertToCurrencyUnit(item.value)}
                  </div>
                  {showError && (
                    <div>This amount exceeds the total accrued retention</div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </Wrap>
    </ClickAwayListener>
  );
};

export default Index;
