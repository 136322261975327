import * as constants from "./constants";
import history from "../../../utils/history";
import { actionCreators as actionCreatorsModal } from "../../../common/modal";
import { actionCreators as actionCreatorsUser } from "../../manageaccount/store";
import { actionCreators as actionCreatorsToast } from "../../../common/toastNotification/store";
import { deleteUploadingFile } from "../../../common/claim_cert_attachment/store/actionCreators";
import API from "../../../server";
import {
  downloadFile as downloadLink,
  viewPDF,
} from "../../../utils/createDownloadLink";
import { format } from "date-fns";
import { ClaimContract } from "../components/pageMenu";
import { convertTimeToUTC, compareDates } from "../../../utils/dateTimeHelper";
import { roundNumber } from "../../../utils/numeralHelper";
import { getAutoReferenceNumber } from "../../../utils/helper";

var numeral = require("numeral");
const getApprovalList = (proId, contractId, claimId) => {
  return (dispatch) => {
    API.read_claim_workflow(proId, contractId, claimId)
      .then((res) => res.data)
      .then((ele) => {
        dispatch(setTableData("approvalList", ele.data.data));
      });
  };
};

const sendContractOverviewInfo = (contractInfo, period, pre_claim_name) => ({
  type: constants.REQUEST_CONTRACT_INFO,
  contractInfo,
  period,
  pre_claim_name,
});
const setInitialClaimInfo = (claimInfo, pre_claim_name, third_party_info) => ({
  type: constants.REQUEST_CLAIM_INFO,
  claimInfo,
  pre_claim_name,
  third_party_info,
});
//to read a claim (pdf version), previously requestProjectInfo()
export const requestContractInfo = (
  proId,
  contractId,
  claimId,
  contractType,
) => {
  /*
   * if the route has claimid
   * go to read a claim and set up info
   * if it is new and to create a new claim
   * go to  empty fields and set up environment
   */
  return (dispatch) => {
    // to get the previous latest claim reference
    API.read_contract_summary(contractId)
      .then((res) => res.data.data)
      .then((res) => {
        let pre_claim_name = "";
        let data = res.contractsummary;
        let arr = ["approved", "issue", "certified"];

        if (data) {
          let obj = data.find((item) => arr.includes(item.claim_status));
          pre_claim_name = obj ? obj.claim_name : "";
        }

        if (claimId) {
          if (contractType !== "cert") {
            API.read_claim_notify(contractId, claimId)
              .then((res) => res.data.data)
              .then((res) => res.contacts)
              .then((third_party_info) => {
                API.read_one_claim(proId, contractId, claimId)
                  .then((res) => res.data.data)
                  .then((res) => res.readpayclaim)
                  .then((res) => {
                    //overwrite previous_claim_name base on select claim to_date
                    if (data) {
                      let obj = data.find(
                        (item) =>
                          arr.includes(item.claim_status) &&
                          compareDates(item.period_ending, res.claim_to) !==
                            "greater",
                      );
                      pre_claim_name = obj ? obj.claim_name : "";
                    }
                    dispatch(
                      setInitialClaimInfo(
                        res,
                        pre_claim_name,
                        third_party_info,
                      ),
                    );
                    dispatch(actionCreatorsUser.getAllUsers());
                    dispatch(getApprovalList(proId, contractId, claimId));
                    dispatch(getClaimNetCert(proId, contractId, res.claim_to));
                    if (res.has_retention) {
                      // check whether claim retention
                      // only when pcd/dlp value is not equal to previous cert release value
                      // mark it release claim retention
                      let date = res.claim_to;
                      API.read_claim_net_cert(contractId, date)
                        .then((res) => res.data.data)
                        .then((cert) => {
                          if (
                            res.dlp_release_value !== cert.value.cert_dlp ||
                            res.pcd_release_value !== cert.value.cert_pcd
                          ) {
                            dispatch(setData("toClaimRetention", true));
                            //new Summary Values
                            dispatch(
                              setData(
                                "claimDlpReleaseValue",
                                res.dlp_release_value,
                              ),
                            );
                            dispatch(
                              setData(
                                "claimPcdReleaseValue",
                                res.pcd_release_value,
                              ),
                            );
                          }
                        });
                    }
                  });
              });
          } else {
            API.read_one_claim(proId, contractId, claimId)
              .then((res) => res.data.data)
              .then((res) => res.readpayclaim)
              .then((res) => {
                dispatch(
                  setInitialClaimInfo(res, pre_claim_name, { to_contacts: "" }),
                );
                dispatch(getClaimNetCert(proId, contractId, res.claim_to));
              });
          }
          // New Claim Cert Summary Component
          dispatch(getPreviousClaimCert(contractId, claimId));
        } else {
          let claimPeriod = {};
          API.read_contract_claim_period(contractId)
            .then((res) => res.data.data.data)
            .then((res) => {
              claimPeriod = res;
              dispatch(getClaimNetCert(proId, contractId, res.claim_to));
              API.read_contract(proId, contractId)
                .then((res) => res.data.data.data)
                .then((res) => {
                  dispatch(
                    sendContractOverviewInfo(res, claimPeriod, pre_claim_name),
                  );
                });
            });
        }
      });
  };
};

export const readContract = (proId, contractId) => {
  return (dispatch) => {
    API.read_contract(proId, contractId)
      .then((res) => res.data.data.data)
      .then((res) => {
        dispatch({
          type: constants.SET_CONTRACT_DETAIL,
          payload: res,
        });
      });
  };
};

// delete a claim
export const deleteClaim = (proId, contractId, claimId, contractType) => {
  return (dispatch, getState) => {
    // return to project overview page (or should it be contract overview page?) after deleting claim
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    let contractPageType = ClaimContract.includes(contractType)
      ? "claim"
      : "cert";
    let return_url = `/contract/${proId}/${contractPageType}/${contractId}?account_id=${accountId}`;
    if (claimId) {
      dispatch(
        actionCreatorsModal.showModal("delete", {
          open: true,
          title: "Claim",
          message: "claim",
          url: return_url,
          api: {
            name: "delete_one_claim",
            config: [proId, contractId, claimId],
          },
        }),
      );
    } else {
      history.push(return_url);
    }
  };
};

export const setInputValue = (
  id,
  field,
  value,
  name,
  loadVal,
  fromPrctQty,
) => ({
  type: constants.SET_INPUT_VALUE,
  id,
  field,
  value,
  name,
  fromLoad: loadVal,
  fromPrctQty: fromPrctQty,
});

export const setMaterialInput = (lineId, value, onSite) => ({
  type: constants.SET_MATERIAL_INPUT,
  lineId,
  value,
  onSite,
});

export const setValue = (name, value) => ({
  type: constants.SET_NAME_VALUE,
  name: name,
  value: value,
});

export const setClaimInfo = (
  name,
  value,
  proId,
  contractId,
  claimId = "",
  integration = "",
  contractType,
) => {
  return (dispatch, getState) => {
    dispatch({
      type: constants.SET_CLAIM_DATES,
      name,
      value,
    });
    if (contractId) {
      let claimFrom = getState().getIn([
          "makeaclaim",
          "claimDetail",
          "claim_from",
        ]),
        claimTo = getState().getIn(["makeaclaim", "claimDetail", "claim_to"]),
        params = new URLSearchParams(),
        date = format(new Date(value), "yyyy-MM-dd");

      claimFrom = format(new Date(claimFrom), "yyyy-MM-dd");
      claimTo = format(new Date(claimTo), "yyyy-MM-dd");

      if (name === "claim_from") {
        params.append("claim_from", date);
      }
      if (name === "claim_to") {
        params.append("claim_from", claimFrom);
        params.append("claim_to", date);
      }
      if (name === "claim_due_date") {
        params.append("claim_from", claimFrom);
        params.append("claim_to", claimTo);
        params.append("claim_due", date);
      }

      API.read_contract_claim_period(contractId, params)
        .then((res) => res.data.data.data)
        .then((claimPeriod) => {
          dispatch(setClaimPeriod(claimPeriod));
          dispatch(getClaimNetCert(proId, contractId, claimPeriod.claim_to));
          //update previous option if claim_to date has changed. the previous claim/cert also need to be updated.
          dispatch(
            getPreviousClaimCert(
              contractId,
              claimId,
              contractType,
              claimPeriod.claim_to,
            ),
          );
          if (
            integration === "ipm" &&
            (name === "claim_from" || name === "claim_to")
          ) {
            dispatch(saveDateChange(proId, contractId, claimId, claimPeriod));
          }
        });
    }
  };
};

export const saveDateChange = (proId, contractId, claimId, claimDetail) => {
  return (dispatch) => {
    // rename to fit claim struct
    let dataSet = {
      from_date: claimDetail.claim_from,
      to_date: claimDetail.claim_to,
      payment_due_date: claimDetail.pay_due_date,
      cert_due_date: claimDetail.cert_due_date,
      claim_due_date: claimDetail.claim_due_date,
    };

    API.update_claim_info(proId, contractId, claimId, dataSet).then(() => {
      // reload claim details to update vari items
      dispatch(readMakeClaimDetails(proId, contractId, claimId));
    });
  };
};

const setClaimPeriod = (claimPeriod) => ({
  type: constants.UPDATE_CLAIM_DATES,
  claimPeriod,
});

// to make a claim (draft)
export const makeAclaim = (
  claimDetail,
  lessPaid,
  baseList,
  variationList,
  proId,
  contractId,
  field,
  lessRetention,
  previousType,
  contractType,
  claimCounts,
  claimWord,
) => {
  baseList = baseList.toJS();
  baseList = baseList.map((item) => {
    item.percent = numeral(item.percent).value();
    item.value = Math.round(item.value);
    item.claim_qty = roundNumber(
      (parseFloat(item?.value) / parseFloat(item?.total)) *
        item?.total_item_quantity,
    );
    delete item.parent_id;
    return item;
  });
  variationList = variationList.toJS();
  variationList = variationList.map((item) => {
    item.percent = numeral(item.percent).value();
    item.value = Math.round(item.value);
    item.claim_qty = roundNumber(
      (parseFloat(item?.value) / parseFloat(item?.total)) *
        item?.total_item_quantity,
    );
    delete item.parent_id;
    return item;
  });
  const timezone = claimDetail?.get("contract_timezone");
  let dataSet = {
    title:
      claimDetail.get("claim_ref") === ""
        ? `${claimWord} - ${getAutoReferenceNumber(claimCounts)}`
        : claimDetail.get("claim_ref"),
    from: convertTimeToUTC(
      claimDetail.get("claim_from") + " 00:00:00",
      timezone,
    ),
    to: convertTimeToUTC(claimDetail.get("claim_to") + " 23:59:59", timezone),
    last: convertTimeToUTC(
      claimDetail.get("claim_due_date") + " 23:59:59",
      timezone,
    ),
    due: convertTimeToUTC(
      claimDetail.get("pay_due_date") + " 23:59:59",
      timezone,
    ),
    cert: convertTimeToUTC(
      claimDetail.get("cert_due_date") + " 23:59:59",
      timezone,
    ),
    claim_internal_notes: claimDetail.get("claim_internal_notes"),
    claim_external_notes: claimDetail.get("claim_external_notes"),
    less_paid_to_date: Math.round(numeral(lessPaid).value() * 100),
    less_retention: Math.round(numeral(lessRetention).value() * 100),
    previous_paid_to_date: previousType,
    baseitems: baseList,
    variations: variationList,
  };
  return (dispatch, getState) => {
    let contractInfo = getState().getIn(["makeaclaim", "contractInfo"]),
      // New Summary Values:
      pcdReleaseValue = getState().getIn([
        "makeaclaim",
        "claimPcdReleaseValue",
      ]),
      dlpReleaseValue = getState().getIn([
        "makeaclaim",
        "claimDlpReleaseValue",
      ]),
      certRetention = getState().getIn(["makeaclaim", "certRetention"]),
      toClaimRetention = getState().getIn(["makeaclaim", "toClaimRetention"]),
      materialList = getState().getIn(["makeaclaim", "materialsClaimList"]);
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    if (contractInfo.get("retention")) {
      if (toClaimRetention) {
        dataSet["pcd_release_value"] = Math.floor(pcdReleaseValue);
        dataSet["dlp_release_value"] = Math.floor(dlpReleaseValue);
      } else {
        dataSet["pcd_release_value"] = Math.floor(
          certRetention.get("pcd") * 100,
        );
        dataSet["dlp_release_value"] = Math.floor(
          certRetention.get("dlp") * 100,
        );
      }
      dataSet["pcd_date"] = contractInfo.get("retention").get("pcd_date");
      dataSet["dlp_date"] = contractInfo.get("retention").get("dlp_date");
    }
    let materials = [];
    materialList.map((item) => {
      materials.push(item.toJS());
      return null;
    });
    dataSet["materials"] = materials;

    API.create_one_claim(proId, contractId, JSON.stringify(dataSet))
      .then((res) => res.data)
      .then((res) => res.data.makepayclaim.id)
      .then((res) => {
        let nextPath = `/project/${proId}/contract/${contractId}/${contractType}/claim/${res}/review?account_id=${accountId}`;
        if (field === "claim") {
          history.push(nextPath);
        } else {
          history.replace(
            `/project/${proId}/contract/${contractId}/${contractType}/claim/${res}?account_id=${accountId}`,
          );
        }

        // New Claim Cert Summary Component
        dispatch(getPreviousClaimCert(contractId, res));
      });
  };
};
//download the pdf
export const downloadPdf = (proId, contractId, claimId, isDownload) => () => {
  return API.download_claim_pdf(proId, contractId, claimId, {
    responseType: "blob",
  }).then((res) => {
    if (isDownload) {
      downloadLink(res);
    } else {
      viewPDF(res);
    }
  });
};

export const resetStage = () => ({
  type: constants.RESET_STAGE,
});

export const changeClaimToApproved = (proId, contractId, claimId) => {
  return (dispatch, getState) => {
    const claimWord = getState().getIn([
      "config",
      "accountConfig",
      "claim",
      "noun",
    ]);
    API.approve_one_claim(proId, contractId, claimId)
      .then((res) => res.data)
      .then((res) => {
        dispatch(setValue("approvingClaim", false));
        dispatch(requestContractInfo(proId, contractId, claimId));
        dispatch(
          actionCreatorsToast.addToast(claimId, {
            type: "information",
            title: `${claimWord} approved`,
          }),
        );
      });
  };
};

//approve a claim
export const approveClaim = (proId, contractId, claimId) => {
  return (dispatch, getState) => {
    let approveList = getState().getIn(["makeaclaim", "approvalList"]);
    if (
      approveList.get("current_step_index") >=
      approveList.get("steps").size - 1
    ) {
      dispatch(setValue("approvingClaim", true));
      dispatch(changeClaimToApproved(proId, contractId, claimId));
    } else {
      dispatch(setValue("leaveComment", true));
    }
  };
};

export const approveClaimByStep = (proId, contractId, claimId) => {
  return (dispatch, getState) => {
    dispatch(setValue("approvingClaim", true));
    let approveList = getState().getIn(["makeaclaim", "approvalList"]),
      comment = {
        comment: getState().getIn(["makeaclaim", "approval_comment"]),
      };

    let currentStep = approveList
      .get("steps")
      .find(
        (item) => item.get("step") === approveList.get("current_step_index"),
      );
    if (currentStep) {
      API.approve_claim_step(
        proId,
        contractId,
        claimId,
        currentStep.get("id"),
        comment,
      )
        .then((res) => res.data)
        .then((res) => {
          API.read_claim_workflow(proId, contractId, claimId)
            .then((res) => res.data)
            .then((res) => {
              dispatch(closeDialog());
              dispatch(setTableData("approvalList", res.data.data));
              if (
                res.data.data.current_step_index >=
                res.data.data.steps.length - 1
              ) {
                dispatch(changeClaimToApproved(proId, contractId, claimId));
              } else {
                dispatch(setValue("approvingClaim", false));
              }
            });
        });
    }
  };
};

export const revertByStep = (proId, contractId, claimId, stepId, comment) => {
  return (dispatch) => {
    API.revert_claim_workflow(proId, contractId, claimId, stepId, {
      comment,
    }).then((res) => {
      dispatch(getApprovalList(proId, contractId, claimId));
    });
  };
};

export const closeDialog = () => {
  return (dispatch) => {
    dispatch(setValue("approval_comment", ""));
    dispatch(setValue("leaveComment", false));
  };
};

//issue a claim
export const issueClaim = (
  proId,
  contractId,
  claimId,
  isSendEmail,
  emailToList,
  subject,
  attachedEmailFiles,
  isLinked,
) => {
  let attachments = attachedEmailFiles.filter((item) =>
    item.get("is_attached_email"),
  );
  let contacts = {
    to_contacts: emailToList.toJS(),
    subject: subject,
    attachments: attachments.toJS(),
  };
  return (dispatch, getState) => {
    if (isSendEmail && contacts.to_contacts.length < 1) {
      dispatch(
        actionCreatorsModal.showModal("alert", {
          open: true,
          title: "Error!",
          message: "Please input at least one email address!",
        }),
      );
    } else {
      dispatch(setValue("sendingEmail", true));
      let isPlural = contacts.to_contacts.length > 1;
      const claimWord = getState().getIn([
        "config",
        "accountConfig",
        "claim",
        "noun",
      ]);
      API.issue_one_claim(proId, contractId, claimId, contacts)
        .then((res) => res.data)
        .then((res) => {
          API.send_payclaim_email(
            proId,
            contractId,
            claimId,
            isSendEmail,
            contacts,
          )
            .then((res) => res.data)
            .then((res) => {
              dispatch(setValue("sendingEmail", false));
              dispatch(
                setData(
                  "componentKey",
                  Math.random().toString(36).substring(2),
                ),
              );

              dispatch(
                actionCreatorsToast.addToast(claimId, {
                  type: "information",
                  title: `${claimWord} issued and ${
                    isPlural ? "emails" : "email"
                  } sent`,
                }),
              );
            });

          API.issue_one_claim_integration(proId, contractId, claimId, contacts)
            .then((res) => res.data)
            .then((res) => {
              if (res?.status === 201) {
                if (isLinked) {
                  dispatch(
                    actionCreatorsToast.addToast(claimId, {
                      type: "information",
                      title: `${claimWord} Information has been dispatched to IPM successfully!`,
                    }),
                  );
                }
              }
            });
        });
    }
  };
};

export const removeEmail = (data, field) => ({
  type: constants.REMOVE_EMAIL,
  data,
  field,
});
export const setEmailSubject = (name, value) => ({
  type: constants.SET_EMAIL_SUBJECT,
  name: name,
  value: value,
});
export const addEmail = (data, field) => ({
  type: constants.ADD_EMAIL,
  data,
  field,
});
export const addContact_Email = (entityId, contactId, field) => {
  return (dispatch) => {
    API.read_company_contact(entityId, contactId)
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(addEmail(res, field));
      });
  };
};

export const revertToDraft = (proId, contractId, claimId, contractType) => {
  return (dispatch, getState) => {
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    API.revert_claim_to_draft(proId, contractId, claimId)
      .then((res) => res.data)
      .then((res) => {
        history.push(
          `/project/${proId}/contract/${contractId}/${contractType}/claim/${claimId}?account_id=${accountId}`,
        );
      });

    // New Claim Cert Summary Component
    dispatch(getPreviousClaimCert(contractId, claimId));
  };
};

const setTableData = (name, data) => ({
  type: constants.SET_TABLE_DATA,
  name,
  data,
});

export const sendEmail = (
  proId,
  contractId,
  claimId,
  isSendEmail,
  emailToList,
  subject,
  attachedEmailFiles,
) => {
  let attachments = attachedEmailFiles.filter((item) =>
    item.get("is_attached_email"),
  );
  let contacts = {
    to_contacts: emailToList.toJS(),
    subject: subject,
    attachments: attachments.toJS(),
  };
  return (dispatch) => {
    dispatch(setValue("sendingEmail", true));
    let isPlural = contacts.to_contacts.length > 1;
    API.send_payclaim_email(proId, contractId, claimId, isSendEmail, contacts)
      .then((res) => res.data)
      .then((res) => {
        dispatch(
          actionCreatorsToast.addToast(claimId, {
            type: "information",
            title: `${isPlural ? "Emails" : "Email"} sent successfully`,
          }),
        );
        dispatch(setValue("sendingEmail", false));
        dispatch(getApprovalList(proId, contractId, claimId));
      });
  };
};

export const updateComment = (
  proId,
  contractId,
  claimId,
  action,
  content,
  comment_id,
  lineId,
) => {
  let data = {
    content: content.comment,
    is_claim: true,
    doc_uuid: claimId,
    comment_id: comment_id,
    shared: content.shared,
  };

  let api;
  if (action === "post") {
    delete data.comment_id;
    api = API.create_lineitem_comment(proId, contractId, lineId, data);
  } else if (action === "delete") {
    api = API.delete_lineitem_comment(proId, contractId, lineId, data);
  } else {
    api = API.update_lineitem_comment(proId, contractId, lineId, data);
  }

  return (dispatch) => {
    api.then(() => {
      dispatch(readLineitemComments(proId, contractId, claimId));
    });
  };
};

// read comments (for specific lineitem) and make them the only state to update in redux
export const readLineitemComments = (proId, contractId, claimId) => {
  return (dispatch) => {
    API.read_one_claim(proId, contractId, claimId)
      .then((res) => res.data.data.readpayclaim)
      .then((res) => {
        dispatch(
          setLineitemComments(
            res?.baseitems,
            res?.variation_items,
            res?.materials,
          ),
        );
      });
  };
};

const setLineitemComments = (baseItems, variationItems, materialItems) => ({
  type: constants.SET_LINE_COMMENTS,
  baseItems,
  variationItems,
  materialItems,
});

export const deleteFile = (proId, contractId, claimId, fileId) => {
  return (dispatch) => {
    dispatch(
      actionCreatorsModal.showModal("delete", {
        open: true,
        title: "Files",
        message: "file",
        api: {
          name: "delete_file_claim",
          config: [contractId, fileId],
        },
        action: [removeFile(fileId), deleteUploadingFile(fileId)],
      }),
    );
  };
};

const removeFile = (fileId) => ({
  type: constants.DELETE_FILE,
  payload: fileId,
});

//download File
export const downloadFile = (fileId, fileName, token, accId, isDownload) => {
  const openFunc = isDownload ? downloadLink : viewPDF;
  return () => {
    if (token || accId) {
      token
        ? API.download_one_shared_file_by_token(token, {
            responseType: "blob",
          }).then((res) => {
            openFunc(res);
          })
        : API.download_one_shared_file(
            `/api/payclaim/account/${accId}/file/${fileId}`,
            { responseType: "blob" },
          ).then((res) => {
            openFunc(res);
          });
    } else {
      API.download_one_file(fileId, { responseType: "blob" }).then((res) => {
        openFunc(res, fileName);
      });
    }
  };
};

export const changeSharedFiles = (
  proId,
  contractId,
  claimId,
  fileId,
  isAdd,
) => {
  return (dispatch) => {
    API.update_claim_shared_attachment(contractId, claimId, {
      file_id: fileId,
      is_shared: isAdd,
    })
      .then((res) => res.data)
      .then((res) => {
        dispatch(requestContractInfo(proId, contractId, claimId));
      });
  };
};

export const selectAttachedFile = (name, value, field) => {
  return (dispatch, getState) => {
    const MAX_ATTACH_FILE_SIZE = getState().getIn(["headers", "config"])
      .file_size.file_upload_size_limit;
    let fileList = getState()
        .getIn(["makeaclaim", "attachedEmailFiles"])
        .toJS(),
      attachmentSize = 0;
    fileList.map((item) => {
      if (name === "all") {
        item[field] = value;
      } else {
        if (item.file_id === name) {
          item[field] = value;
        }
      }
      if (item.is_attached_email && item.attach_method === "file") {
        attachmentSize += item.file_size;
      }
      return item;
    });
    if (attachmentSize > MAX_ATTACH_FILE_SIZE) {
      dispatch(setData("exceedAttachLimit", true));
    } else {
      dispatch(setData("exceedAttachLimit", false));
    }
    dispatch(setTableData("attachedEmailFiles", fileList));
  };
};

export const getClaimNetCert = (proId, contractId, toDate) => {
  return (dispatch) => {
    // get previous cert info based on claim_to date
    API.read_claim_net_cert(contractId, toDate)
      .then((res) => res.data.data)
      .then((cert) => {
        // based on previous cert retention to calculate max dlp/pcd
        API.calculate_retention(proId, contractId, {
          retention: Math.round(cert.value.cert_retention),
        })
          .then((res) => res.data)
          .then((res) => res.data.data)
          .then((res) => {
            dispatch(
              setData("certRetention", {
                dlp: cert.value.cert_dlp / 100,
                pcd: cert.value.cert_pcd / 100,
                preNetTotal: cert.value.cert_value / 100,
              }),
            );
          });
      });
  };
};

// New project and contract details (P2C)
export const setData = (name, value) => ({
  type: constants.SET_DATA,
  payload: {
    name,
    value,
  },
});

export const readProject = (proId) => {
  return (dispatch) => {
    API.read_one_project_group(proId)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("projectInfo", res.data));
      });
  };
};

export const setAuditSetting = (proId, contractId, claimId, value) => {
  return (dispatch) => {
    API.update_claim_info(proId, contractId, claimId, {
      attach_audit_trail: value,
    })
      .then((res) => res.data)
      .then((res) => {
        dispatch(setData("isAuditAttached", value));
      });
  };
};

export const setContractConfig = (proId, contractId) => {
  return (dispatch) => {
    API.read_contract_config(proId, contractId)
      .then((res) => res.data)
      .then((res) => {
        dispatch(setData("contractConfig", res.data.data));
      });
  };
};

// read and update make claim details (for claims that already exist)
export const readMakeClaimDetails = (proId, contractId, claimId) => {
  return (dispatch) => {
    API.read_contract_summary(contractId)
      .then((res) => res.data.data.contractsummary)
      .then((contractsummary) => {
        let pre_claim_name = "";
        let arr = ["approved", "issue", "certified"];
        API.read_claim_notify(contractId, claimId)
          .then((res) => res.data.data.contacts)
          .then((third_party_info) => {
            API.read_one_claim(proId, contractId, claimId)
              .then((res) => res.data.data.readpayclaim)
              .then((res) => {
                //overwrite previous_claim_name base on select claim to_date
                if (contractsummary) {
                  let obj = contractsummary.find(
                    (item) =>
                      arr.includes(item.claim_status) &&
                      compareDates(item.period_ending, res.claim_to) !==
                        "greater",
                  );
                  pre_claim_name = obj ? obj.claim_name : "";
                }
                dispatch(
                  setInitialClaimInfo(res, pre_claim_name, third_party_info),
                );
                dispatch(getClaimNetCert(proId, contractId, res.claim_to));
                if (res.has_retention) {
                  let date = res.claim_to;
                  API.read_claim_net_cert(contractId, date)
                    .then((res) => res.data.data)
                    .then((cert) => {
                      if (
                        res.dlp_release_value !== cert.value.cert_dlp ||
                        res.pcd_release_value !== cert.value.cert_pcd
                      ) {
                        dispatch(setData("toClaimRetention", true));
                        //new Summary Values
                        dispatch(
                          setData(
                            "claimDlpReleaseValue",
                            res.dlp_release_value,
                          ),
                        );
                        dispatch(
                          setData(
                            "claimPcdReleaseValue",
                            res.pcd_release_value,
                          ),
                        );
                      }
                    });
                }
              });
          });
      });

    // New Claim Cert Summary Component
    dispatch(getPreviousClaimCert(contractId, claimId));
  };
};

// New Claim Cert Summary Actions

//New update a claim
export const updateAclaim = (
  claimDetail,
  lessPaid,
  baseList,
  variationList,
  proId,
  contractId,
  claimId,
  field,
  lessRetention,
  previousType,
  contractType,
  lessPreviousOption,
) => {
  baseList = baseList.toJS();
  baseList = baseList.map((item) => {
    item.percent = numeral(item.percent).value();
    item.value = Math.round(item.value);
    item.claim_qty = roundNumber(
      (parseFloat(item?.value) / parseFloat(item?.total)) *
        item?.total_item_quantity,
    );
    return item;
  });
  variationList = variationList.toJS();
  variationList = variationList.map((item) => {
    item.percent = numeral(item.percent).value();
    item.value = Math.round(item.value);
    item.claim_qty = roundNumber(
      (parseFloat(item?.value) / parseFloat(item?.total)) *
        item?.total_item_quantity,
    );
    return item;
  });
  const timezone = claimDetail?.get("contract_timezone");

  let dataSet = {
    title: claimDetail.get("claim_ref"),
    from: convertTimeToUTC(
      claimDetail.get("claim_from") + " 00:00:00",
      timezone,
    ),
    to: convertTimeToUTC(claimDetail.get("claim_to") + " 23:59:59", timezone),
    last: convertTimeToUTC(
      claimDetail.get("claim_due_date") + " 23:59:59",
      timezone,
    ),
    due: convertTimeToUTC(
      claimDetail.get("pay_due_date") + " 23:59:59",
      timezone,
    ),
    cert: convertTimeToUTC(
      claimDetail.get("cert_due_date") + " 23:59:59",
      timezone,
    ),
    claim_internal_notes: claimDetail.get("claim_internal_notes"),
    claim_external_notes: claimDetail.get("claim_external_notes"),
    less_paid_to_date: Math.round(numeral(lessPaid).value() * 100),
    less_retention: Math.round(numeral(lessRetention).value() * 100),
    baseitems: baseList,
    variations: variationList,
    previous_paid_to_date: previousType,
  };
  return (dispatch, getState) => {
    let contractInfo = getState().getIn(["makeaclaim", "contractInfo"]),
      pcdReleaseValue = getState().getIn([
        "makeaclaim",
        "claimPcdReleaseValue",
      ]),
      dlpReleaseValue = getState().getIn([
        "makeaclaim",
        "claimDlpReleaseValue",
      ]),
      certRetention = getState().getIn(["makeaclaim", "certRetention"]),
      toClaimRetention = getState().getIn(["makeaclaim", "toClaimRetention"]),
      materialList = getState().getIn(["makeaclaim", "materialsClaimList"]),
      claimWord = getState().getIn([
        "config",
        "accountConfig",
        "claim",
        "noun",
      ]),
      previous_option = getState().getIn(["makeaclaim", "previous_option"]),
      previous_object_id = getState().getIn([
        "makeaclaim",
        "previous_object_id",
      ]),
      previous_gross = getState().getIn(["makeaclaim", "previous_gross"]),
      previous_retention = getState().getIn([
        "makeaclaim",
        "previous_retention",
      ]),
      previous_retention_pcrr = getState().getIn([
        "makeaclaim",
        "previous_retention_pcrr",
      ]),
      previous_retention_dlrr = getState().getIn([
        "makeaclaim",
        "previous_retention_dlrr",
      ]),
      previous_labour_element = getState().getIn([
        "makeaclaim",
        "previous_labour_element",
      ]),
      labour_element = getState().getIn(["makeaclaim", "labour_element"]);
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    if (claimDetail.get("has_retention")) {
      if (toClaimRetention) {
        dataSet["pcd_release_value"] = Math.floor(pcdReleaseValue);
        dataSet["dlp_release_value"] = Math.floor(dlpReleaseValue);
      } else {
        dataSet["pcd_release_value"] = Math.floor(
          certRetention.get("pcd") * 100,
        );
        dataSet["dlp_release_value"] = Math.floor(
          certRetention.get("dlp") * 100,
        );
      }
      dataSet["pcd_date"] = contractInfo.get("pcd_date");
      dataSet["dlp_date"] = contractInfo.get("dlp_date");
    }
    let materials = [];
    materialList.map((item) => {
      materials.push(item.toJS());
      return null;
    });
    dataSet["materials"] = materials;
    dataSet["claim_less_previous_option"] = lessPreviousOption;
    dataSet["previous_option"] = previous_option;
    dataSet["previous_gross"] = previous_gross;
    dataSet["previous_retention"] = previous_retention;
    dataSet["previous_retention_pcrr"] = previous_retention_pcrr;
    dataSet["previous_retention_dlrr"] = previous_retention_dlrr;
    dataSet["previous_object_id"] = previous_object_id;
    dataSet["previous_labour_element"] = previous_labour_element;
    dataSet["labour_element"] = labour_element;
    API.update_one_claim(proId, contractId, claimId, JSON.stringify(dataSet))
      .then((res) => res.data)
      .then((res) => {
        let nextPath = `/project/${proId}/contract/${contractId}/${contractType}/claim/${claimId}/review?account_id=${accountId}`;
        if (field === "claim") {
          history.push(nextPath);
        } else {
          dispatch(
            actionCreatorsModal.showModal("confirm", {
              open: true,
              title: "Success!",
              message: `You have updated the ${claimWord} successfully！`,
              action: requestContractInfo(
                proId,
                contractId,
                claimId,
                contractType,
              ),
            }),
          );
        }
      });
  };
};

// get previous claim and cert data
export const getPreviousClaimCert = (
  contractId,
  claimId,
  contractType,
  claim_to,
) => {
  return (dispatch) => {
    API.read_contract_summary(contractId)
      .then((res) => res.data.data.contractsummary)
      .then((res) => {
        dispatch(setPreviousClaimCert(res, claimId, contractType, claim_to));
      });
  };
};

// set previous claim/cert data in redux store
const setPreviousClaimCert = (data, claimId, contractType, claim_to) => ({
  type: constants.SET_PREVIOUS_CLAIM_CERT,
  currentClaimID: claimId,
  data: data,
  contractType: contractType,
  claim_to: claim_to,
});

export const setPreviousOption = (previousOption = "") => ({
  type: constants.SET_PREVIOUS_OPTION,
  option: previousOption,
});
