import React, { Component } from "react";
import Modal from "../../../common/modal/component/modalBase";
import { BlueOutlinedBtn as Button } from "../../../common/button";

//shared with dashboard task list
export default class index extends Component {
  render() {
    const { open, closeDialog, chooseOption, certName } = this.props;
    return (
      <Modal
        open={open}
        handleClose={closeDialog}
        title={"Draft Certificate"}
        divider={false}
      >
        <>
          <div style={{ padding: "0px 40px 20px 40px", width: "639px" }}>
            There is a draft certificate ({certName}) from previously issued
            claim. This usually happens when the other party has retracted their
            claim and re-issued it.
            <br />
            <br />
            Do you want to use your draft or do you want to start a new
            certificate?
          </div>
        </>
        <>
          <Button
            title="Use Existing Draft"
            onClick={() => chooseOption(false)}
          />
          <Button
            title="Discard and Start New"
            onClick={() => chooseOption(true)}
          />
        </>
      </Modal>
    );
  }
}
