import React from "react";
import { connect } from "react-redux";
import { Container, ProjectDetailWrapper } from "../../project/styled";
import { CompleteWrap } from "../styled";

import {
  SubmitGreenBtn as Button,
  RedOutlinedBtn as ButtonSecondary,
} from "../../../common/button";
import { COUNTRY_GB } from "../../../utils/constants";

const CompletionBar = (props) => {
  const {
    isClaimContract,
    completionSteps,
    startContract,
    fullContractType,
    leaveContract,
    readOnly,
    accountConfig,
    contractInfo,
  } = props;
  // steps descriptions
  let requiredStep = [
    "Add Date Schedule",
    "Add Base Contract Items",
    `Add ${
      isClaimContract
        ? "Certifier"
        : accountConfig?.getIn(["claim", "noun"]) === "Claim"
          ? "Claimer"
          : accountConfig?.getIn(["claim", "name"])
    }`,
  ];
  requiredStep =
    contractInfo.get("country") === COUNTRY_GB
      ? [...requiredStep, "Review Tax Details"]
      : requiredStep;
  //check completed steps numbers
  const steps = Object.values(completionSteps).filter((ele) => ele).length;
  const totalSteps = requiredStep.length;
  return (
    <Container id="completion-bar">
      <ProjectDetailWrapper style={{ margin: "56px auto" }}>
        <CompleteWrap completed={steps * (360 / (totalSteps * 2))}>
          <div className="circle-wrap">
            <div className="circle">
              <div className="mask full">
                <div className="fill" />
              </div>
              <div className="mask half">
                <div className="fill" />
              </div>
              <div className="inside-circle">
                <span>
                  {steps}/{totalSteps}
                </span>
                <span className="sub-label">STEPS</span>
              </div>
            </div>
          </div>
          <div>
            <div className="title">
              {steps === totalSteps
                ? "Contract Is Ready!"
                : "Contract Is Almost Ready"}
            </div>
            <div className="sub-title">
              {steps === totalSteps
                ? "All steps are complete."
                : "This contract is in draft. Complete these steps to start the contract."}
            </div>
            <ul>
              {/* render steps with completed or uncompleted style */}
              {Object.keys(completionSteps).map((step, index) => (
                <li
                  key={step}
                  className={
                    completionSteps[step] ? "completed" : "uncompleted"
                  }
                  onClick={() => {
                    var element = document.getElementById(step);
                    const offset = 16;
                    if (element) {
                      const bodyRect =
                        document.body.getBoundingClientRect().top;
                      const elementRect = element.getBoundingClientRect().top;
                      const elementPosition = elementRect - bodyRect;
                      const offsetPosition = elementPosition - offset;
                      window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth",
                      });
                    }
                  }}
                >
                  {completionSteps[step] ? (
                    <>
                      <div className="check-icon" />
                      <span>{requiredStep[index]}</span>
                    </>
                  ) : (
                    <>
                      <span>{requiredStep[index]}</span>
                      <div className="right-icon" htmlFor={step} />
                    </>
                  )}
                </li>
              ))}
            </ul>
            <div>
              {!readOnly && (
                <React.Fragment>
                  <Button
                    title="start contract"
                    style={{ width: "fit-content" }}
                    disabled={steps !== totalSteps}
                    onClick={startContract}
                  />
                  <ButtonSecondary
                    title={
                      ["claim", "cert"].indexOf(fullContractType) >= 0
                        ? "leave"
                        : "delete"
                    }
                    style={{ float: "right" }}
                    margin={"20px 0px 50px 0px"}
                    onClick={leaveContract}
                  />
                </React.Fragment>
              )}
            </div>
          </div>
        </CompleteWrap>
      </ProjectDetailWrapper>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  contractInfo: state.getIn(["contract", "contractInfo"]),
  projectInfo: state.getIn(["contract", "projectInfo"]),
});

export default connect(mapStateToProps, null)(CompletionBar);
