import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { fromJS } from "immutable";
import debounce from "lodash/debounce";

import { Container, ProjectBackground } from "../../project/styled";

import Menu from "../../../common/breadcrumbs";
import { BlueOutlinedBtn as Button } from "../../../common/button";
import Link from "../../../common/linkWithQuery";
import profile from "../../../utils/profile";

import ContractDetails from "../components/editSettingsContractDetails";
import Settings from "../components/settingsComponent";

import TimezoneSection from "../../project/components/timezone";
import RetentionSection from "../../project/components/retention";
import Move from "../components/move";
import MemberSection from "../../project/components/projectMember";
import DateSchedule from "../components/dateSchedule";
import WorkflowSection from "../../project/components/workflow";
import Navigator, { navTemplate } from "../../../common/navigator";
import { CONTRACT, EDIT, VIEW_ROLE } from "../../../utils/constants";
import history from "../../../utils/history";

import { actionCreators } from "../store";
import { actionCreators as actionCreatorsProjects } from "../../projects/store";
import { actionCreators as actionCreatorsSingleProject } from "../../project/store";
import TaxSection from "../components/tax";
// update active contract settings page
class index extends PureComponent {
  constructor(props) {
    super(props);
    this.calculateDates = debounce(
      (proId, dueDates) => this.props.calculateDueDate(proId, dueDates),
      300,
    );

    const { type } = this.props.match.params;

    this.state = {
      allowEditContract: false,
      contractSettingsNavigator: [
        "nav-contractInfo",
        "nav-contractMember",
        "nav-dateSchedule",
        `nav-${type}Workflow`,
        "nav-timezone",
        "nav-retention",
        "nav-tax",
        "nav-setting",
      ].map((id) => {
        let processed = navTemplate[id];
        return { id, ...processed };
      }),
    };
  }

  render() {
    const {
      projectInfo,
      contractInfo,
      members,
      allUsers,
      updateContract,
      updateRetention,
      allProjects,
      updateContractMember,
      config,
      hidePrefilledTag,
      accountId,
      symbol,
      updatePaymentTerms,
      deleteContract,
    } = this.props;
    const { proId, contractId, type } = this.props.match.params;

    let workflowSteps = null;
    if (type === "cert") {
      workflowSteps = contractInfo.get("cert_workflow_steps");
    } else if (type === "claim") {
      workflowSteps = contractInfo.get("claim_workflow_steps");
    }

    const link = [
      { link: "/projects", text: "Projects" },
      { link: `/project/view/${proId}`, text: projectInfo.get("name") },
      {
        link: `/contract/${proId}/${type}/${contractId}`,
        text: contractInfo.get("name"),
      },
    ];
    let paymentTermsOptions = {};
    if (typeof config.payclaim !== "undefined") {
      paymentTermsOptions = {
        pay_cert_options: config.payclaim["pay_cert_options"],
        pay_claim_options: config.payclaim["pay_claim_options"],
        payment_options: config.payclaim["payment_options"],
      };
    }
    const isLinked = contractInfo.get("integration") ? true : false;

    return (
      <Container>
        <div className="page-breadcrumbs">
          <Menu
            link={link}
            content={"Settings"}
            className="page-breadcrumbs-label"
          />
          <Link to={`/contract/${proId}/${type}/${contractId}`}>
            <Button title="Back To Contract" />
          </Link>
        </div>
        <ProjectBackground
          src={projectInfo.get("coverImage")}
          id="background-banner"
        />
        <Navigator
          data={this.state.contractSettingsNavigator}
          anchor={{ element: "background-banner", direction: "top" }}
        />
        <ContractDetails
          className={"move-to-top"}
          {...this.props}
          updateInfo={(data) => updateContract(proId, contractId, data, false)}
          readOnly={!this.state.allowEditContract}
        />
        <MemberSection
          updateMembers={(data, mainContact) =>
            updateContractMember(
              proId,
              contractId,
              data,
              mainContact,
              contractInfo.get("status"),
              accountId,
              type,
            )
          }
          allUsers={allUsers.toJS()}
          members={members.toJS()}
          level={"contract"}
        />
        <DateSchedule
          {...this.props}
          options={paymentTermsOptions}
          submit={(data) => updatePaymentTerms(proId, contractId, data)}
          hideTag={() => hidePrefilledTag("dateSchedule")}
          calculateExample={(dueDates) => this.calculateDates(proId, dueDates)}
        />
        <WorkflowSection
          workflowType={type}
          projectId={proId}
          workflowInfo={workflowSteps}
          contractId={contractId}
          hideTag={() => hidePrefilledTag("workflow")}
          prefilledSeen={this.props.prefilledSeen}
        />
        <TimezoneSection
          timezone={contractInfo?.get("time_zone")}
          updateInfo={(data) =>
            updateContract(proId, contractId, { time_zone: data })
          }
          level="contract"
          {...this.props}
        />
        <RetentionSection
          {...this.props}
          retention={contractInfo?.get("retention") || fromJS({})}
          updateInfo={(type, info, scale, retentionAccounts) =>
            updateRetention(
              proId,
              contractId,
              type,
              info,
              scale,
              retentionAccounts,
            )
          }
          currency={symbol?.get("title")}
          isContract
          isLinked={isLinked}
        />
        <TaxSection
          info={contractInfo}
          handleSubmit={(data, isRefresh) =>
            updateContract(proId, contractId, data, isRefresh)
          }
          {...this.props}
        />
        <Settings
          {...this.props}
          updateInfo={(data) => updateContract(proId, contractId, data, false)}
          isClaimContract={type === "claim"}
          proId={proId}
          contractId={contractId}
        />
        <Move
          allProjects={allProjects}
          projectId={proId}
          contractId={contractId}
          isMain={type === "claim" ? true : false}
          deleteContract={() =>
            deleteContract(proId, contractId, contractInfo.get("contract_type"))
          }
          selfContract={
            contractInfo.get("contract_type") === "self-claim" ||
            contractInfo.get("contract_type") === "self-cert"
          }
          contractLinked={contractInfo.get("integration")}
        />
      </Container>
    );
  }
  componentDidMount() {
    const proId = this.props.match.params.proId,
      contractId = this.props.match.params.contractId;
    this.props.readProject(proId);
    this.props.readProjects();
    this.props.readContract(proId, contractId);
    this.props.readContractRole(proId, contractId);

    profile.checkPermission(CONTRACT, EDIT, proId, contractId).then((res) => {
      this.setState({ allowEditContract: res });
    });

    // Access location and state from props
    const { location } = this.props;
    if (location.state && location.state.id) {
      const { id } = location.state;

      const targetSection = document.getElementById(id);
      if (targetSection) {
        setTimeout(function () {
          targetSection.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 100);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { contractRole, accountId, accountConfig } = this.props;
    const { proId, contractId, type } = this.props.match.params;

    // Redirect the user back to the previous page, if the role is view
    if (contractRole !== prevProps.contractRole && contractRole === VIEW_ROLE) {
      history.push(
        `/contract/${proId}/${type}/${contractId}?account_id=${accountId}`,
      );
    }

    // Update the Navigation when accountConfig is updated
    if (accountConfig !== prevProps.accountConfig) {
      const prevContractSettingsNavigator =
        this.state.contractSettingsNavigator;
      prevContractSettingsNavigator.map((item) => {
        if (item?.id.includes("claim")) {
          item.label = item.label.replace(
            "Claim",
            accountConfig?.getIn(["claim", "noun"]),
          );
        }
        return item;
      });

      this.setState({
        contractSettingsNavigator: prevContractSettingsNavigator,
      });
    }
  }
}

const mapStateToProps = (state) => ({
  config: state.getIn(["headers", "config"]),
  loading: state.getIn(["contract", "loading"]),
  projectInfo: state.getIn(["contract", "projectInfo"]),
  defaultInfo: state.getIn(["contract", "defaultInfo"]),
  contractInfo: state.getIn(["contract", "contractInfo"]),
  contractsPreviousAddress: state.getIn([
    "contract",
    "contractsPreviousAddress",
  ]),
  allProjects: state.getIn(["projects", "allProjects"]),
  members: state.getIn(["contract", "members"]),
  allUsers: state.getIn(["newContractGroup", "allUsers"]),
  contractRole: state.getIn(["contract", "contractRole"]),
  attachments: state.getIn(["contract", "attachments"]),
  accountId: state.getIn(["headers", "profile", "payclaim_account_id"]),
  dueDates: state.getIn(["contract", "dueDates"]),
  symbol: state.getIn(["config", "accountConfig", "currency"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});

const mapDispatchToProps = (dispatch) => ({
  readProject(proId) {
    dispatch(actionCreators.readProject(proId));
    dispatch(actionCreators.readDefault(proId));
  },
  readProjects() {
    dispatch(actionCreatorsProjects.readProjects());
  },
  readContract(proId, contractId) {
    dispatch(actionCreators.readContract(proId, contractId));
    dispatch(actionCreatorsSingleProject.readUsers());
    dispatch(actionCreators.readMembers(proId, contractId));
    dispatch(actionCreators.readAttachments(proId, contractId));
  },
  readContractRole(proId, contractId) {
    dispatch(actionCreators.readContractRole(proId, contractId));
  },
  updateContract(proId, contractId, data, isRefreshPage) {
    dispatch(actionCreators.contractsPreviousAddress(proId, contractId));
    dispatch(
      actionCreators.updateContract(proId, contractId, data, isRefreshPage),
    );
  },
  updateRetention(proId, contractId, type, info, scale, retentionAccounts) {
    dispatch(
      actionCreators.updateRetention(
        proId,
        contractId,
        type,
        info,
        scale,
        retentionAccounts,
      ),
    );
  },
  updateContractMember(
    proId,
    contractId,
    data,
    mainContact,
    contractStatus,
    accountId,
    type,
  ) {
    dispatch(
      actionCreators.updateContractMember(
        proId,
        contractId,
        data,
        mainContact,
        contractStatus,
        accountId,
        type,
      ),
    );
  },
  updatePaymentTerms(proId, contractId, update) {
    dispatch(actionCreators.updatePaymentTerms(proId, contractId, update));
  },
  hidePrefilledTag(component) {
    dispatch(actionCreators.hidePrefilledTag(component));
  },
  calculateDueDate(proId, dueDates) {
    dispatch(actionCreators.calculateDueDate(proId, dueDates));
  },
  updateBctiInfo(proId, contractId, value) {
    dispatch(actionCreators.updateBctiInfo(proId, contractId, value));
  },
  deleteContract(proId, contractId, type) {
    dispatch(actionCreators.leaveContract(proId, contractId, type));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
