import React from "react";
import moment from "moment";

import { RetentionDetails } from "./styled";
import { currencySubunitToUnit } from "../../../../utils/currencyHelper";
import {
  RETENTION_OPTIONS,
  RETENTION_SCALE,
} from "../../../../utils/constants";

const ScaleItem = ({ data, percent, amount, label }) => (
  <li key={amount}>
    {data.get(percent) && <span>{data.get(percent)}%</span>}{" "}
    <span>{label}</span>{" "}
    {amount && currencySubunitToUnit(data.get(amount)) && (
      <span>{currencySubunitToUnit(data.get(amount))}</span>
    )}
  </li>
);
export const ScaleItems = ({ data }) => {
  const retentionType = data?.get("retention_type");
  if (!retentionType) return null;
  let itemsToRender = null;
  switch (retentionType) {
    case RETENTION_OPTIONS.CONTRACT.value:
      itemsToRender = (
        <ScaleItem
          data={data}
          percent="firstpercent"
          label={RETENTION_OPTIONS.CONTRACT.description}
        />
      );
      break;
    case RETENTION_OPTIONS.FIXED.value:
      itemsToRender = (
        <ScaleItem
          data={data}
          percent="firstpercent"
          amount="firstamount"
          label={RETENTION_OPTIONS.FIXED.description}
        />
      );
      break;
    case RETENTION_OPTIONS.SCALE.value:
      itemsToRender = (
        <>
          <ScaleItem
            data={data}
            percent="firstpercent"
            amount="firstamount"
            label={RETENTION_OPTIONS.SCALE.description}
          />
          {RETENTION_SCALE.map((item) => {
            const nextPercentKey = `${item}nextpercent`;
            const nextAmountKey = `${item}nextamount`;
            if (data.get(nextPercentKey) && data.get(nextAmountKey)) {
              return (
                <ScaleItem
                  key={item}
                  data={data}
                  percent={nextPercentKey}
                  amount={nextAmountKey}
                  label="of the next"
                />
              );
            }
            return null;
          })}
          {data?.get("excesspercent") && data?.get("excessamount") && (
            <ScaleItem
              data={data}
              percent={"excesspercent"}
              amount={"excessamount"}
              label="in excess of"
            />
          )}
          {data?.get("maximum") > 0 && (
            <ScaleItem
              data={data}
              amount={"maximum"}
              label="With a maximum of "
            />
          )}
        </>
      );
      break;
    default:
      break;
  }

  return itemsToRender;
};

const RetentionInfo = ({ data, config }) => {
  let dlpType = "";
  if (config.size !== 0 && data?.get("release_dlp_type")) {
    let dlpConfig = config.payclaim.payclaim_retention_dlp_option || {};
    dlpType = dlpConfig[data?.get("release_dlp_type")];
  }
  return (
    <RetentionDetails>
      <div>
        <div className="label">Retention Schedule</div>
        <div>
          <ScaleItems data={data} />
        </div>
      </div>
      {typeof data?.get("pcd_percent") === "number" && (
        <div>
          <div className="label">Practical Completion</div>
          <li>
            <span>{data.get("pcd_percent")}% released </span>
            {data?.get("pcd_date") && (
              <span>
                on {moment(data?.get("pcd_date")).format("DD-MM-YYYY")}
              </span>
            )}
          </li>
        </div>
      )}
      {typeof data?.get("dlppercent") === "number" && (
        <div>
          <div className="label">Defect Liability Period (DLP)</div>
          <li>
            <span>{data.get("dlppercent")}% released </span>
            {dlpType ? (
              <>
                {data?.get("dlpdays") && <span>{data?.get("dlpdays")}</span>}{" "}
                <span>{dlpType}</span>
              </>
            ) : null}
          </li>
        </div>
      )}
    </RetentionDetails>
  );
};

export default RetentionInfo;
