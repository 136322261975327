import React from "react";
import { connect } from "react-redux";
import {
  ProjectsWrapper,
  ProjectItemWrapper,
  Caption,
  EmptyState,
} from "../styled";
import { LinkTooltip } from "../../project/components/integration";
import { IconLink } from "../../project/styled";

import { parsePercent } from "../../../utils/numeralHelper";
import { currencySubunitToUnit } from "../../../utils/currencyHelper";
import { openProject } from "../index";
import ProjectSubsetBadge from "./projectSubsetBadge";
import Skeleton from "../../../common/skeleton";

function compareByOption(field) {
  //sort function based sort dropdown
  let sortLabel = field;
  if (sortLabel !== "name" && sortLabel !== "created_date") {
    return function (a, b) {
      var result = a > b ? 1 : b > a ? -1 : 0;
      return result;
    };
  } else
    return function (a, b) {
      var result =
        a.toString().toLowerCase().trim() > b.toString().toLowerCase().trim()
          ? 1
          : b.toString().toLowerCase().trim() >
              a.toString().toLowerCase().trim()
            ? -1
            : 0;
      return result;
    };
}

// This is causes re-render when currency becomes available
const mapStateToProps = (state) => ({
  currency: state.getIn(["config", "accountConfig", "currency", "title"]),
});

export const List = connect(
  mapStateToProps,
  null,
)((props) => {
  const {
    sortListField,
    listData,
    accountId,
    type,
    caption,
    orgType,
    accountConfig,
  } = props;
  let table = listData.sortBy((val) => {
    if (sortListField !== "name" && sortListField !== "created_date") {
      return val.getIn([type, sortListField]);
    } else {
      return val.get(sortListField);
    }
  }, compareByOption(sortListField));
  return (
    <>
      {listData.size !== 0 && <Caption>{caption}</Caption>}
      <ProjectsWrapper>
        {table.map((project) => (
          <ProjectItemWrapper
            key={project.get("id")}
            cover={project.get("cover_url")}
            avatar={project.get("avatar_url")}
            onClick={() => openProject(accountId, project, orgType)}
            onAuxClick={() =>
              openProject(accountId, project, orgType, "new_win")
            }
          >
            <div className="cover-image"></div>
            <>
              <div className="avatar-image" />
              <div className="complete-num">
                {parsePercent(
                  project.getIn([type, "cert_to_date_value"]),
                  project.getIn([type, "total_contract_value"]),
                )}
              </div>
              <div className="project-info">
                <div className="project-title-container">
                  <div className="title">{project.get("name")}</div>
                  {project.get("is_not_covered") && <ProjectSubsetBadge />}
                  {project.get("integration") && (
                    <LinkTooltip from="Project" hideMessage>
                      <IconLink className={"margin-left"} />
                    </LinkTooltip>
                  )}
                </div>
                <label>{project.get("project_number")}</label>
                <div className="project-data">
                  <div>{project.getIn([type, "contract_number"])}</div>
                  <div>
                    {currencySubunitToUnit(
                      project.getIn([type, "total_contract_value"]),
                      true,
                      false,
                      true,
                    )}
                  </div>
                  <div>
                    {currencySubunitToUnit(
                      project.getIn([type, "claimed_to_date_value"]),
                      true,
                      false,
                      true,
                    )}
                  </div>
                  <div>
                    {currencySubunitToUnit(
                      project.getIn([type, "cert_to_date_value"]),
                      true,
                      false,
                      true,
                    )}
                  </div>
                  <label>Contracts</label>
                  <label>Total contract value</label>
                  <label>
                    {accountConfig?.getIn(["claim", "value"])} to date
                  </label>
                  <label>
                    {accountConfig?.getIn(["cert", "value"])} to date
                  </label>
                </div>
              </div>
            </>
          </ProjectItemWrapper>
        ))}
      </ProjectsWrapper>
    </>
  );
});

export default function ListView(props) {
  const { data, loading, accountConfig } = props;
  if (loading) {
    return (
      <>
        <Skeleton.TextLine />
        <Skeleton.ProjectCard count={10} />
      </>
    );
  }
  return (
    <>
      {data.size === 0 && (
        <EmptyState>
          <div className="top-label">No Results Found</div>
          <div>There weren’t any projects matching your search.</div>
        </EmptyState>
      )}
      <List
        caption={"Empty Projects"}
        type="project"
        listData={data.filter(
          (item) => item.getIn(["project", "contract_number"]) === 0,
        )}
        {...props}
      />
      <List
        caption={accountConfig?.getIn(["claim", "ongoing_verb"])}
        type="claim_contracts"
        listData={data.filter(
          (item) => item.getIn(["claim_contracts", "contract_number"]) !== 0,
        )}
        {...props}
      />
      {/* show projects only have cert contracts no claim contracts in the Certifying section */}
      <List
        caption={"Certifying"}
        type="cert_contracts"
        listData={data.filter(
          (item) =>
            item.getIn(["claim_contracts", "contract_number"]) === 0 &&
            item.getIn(["cert_contracts", "contract_number"]) !== 0,
        )}
        {...props}
      />
    </>
  );
}
