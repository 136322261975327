import React from "react";
import {
  BlueWhiteBtn as OKbtn,
  BlueOutlinedBtn as Cancelbtn,
} from "../../button";
import { Icon } from "./dialogStyle";
import Modal from "./modalBase";

const Dialog = (props) => {
  return (
    <Modal {...props} handleClose={() => props.closeModal()} width="590px">
      <div style={{ padding: "33px 30px", lineHeight: "30px" }}>
        <Icon type={props.type} />
        Do you want to leave with any unsaved changes?
      </div>
      <div>
        <OKbtn
          onClick={() => {
            props.leavePage();
          }}
          title="Ok"
          padding="10px 34px"
        />
        <Cancelbtn
          onClick={() => {
            props.closeModal();
          }}
          title="Cancel"
          margin="0 20px 0 0"
          padding="10px 34px"
        />
      </div>
    </Modal>
  );
};
export default Dialog;
