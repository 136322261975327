import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fromJS } from "immutable";

import LineItems from "./lineItem";
import { SettingWrapper, ContractDetailWrapper } from "../../styled";
import { SettingSection, SectionIcon } from "../../../project/styled";
import { BlueWhiteBtn as ButtonDark } from "../../../../common/button";
import Link from "../../../../common/linkWithQuery";
import UploadLineItem from "../../../../common/lineitem/csvUploadDialog";

function Materials(props) {
  const {
    isAdmin,
    contractInfo,
    hasExpand,
    setNavExpanded,
    readOnly,
    proId,
    type,
    contractId,
    claimSummary,
    readContract,
  } = props;

  const [isMaterialExpanded, setIsMaterialExpanded] = useState(false);

  const setIntermediateMaterialExpanded = (value) => {
    setIsMaterialExpanded(value);
    setNavExpanded(value);
  };

  const [sortState, setSortState] = useState({
    sortField: "created_date",
    sortSep: "asc",
  });
  const sortingOptions = {
    created_date: "Date Created",
    label: "Reference",
    description: "Description",
  };

  const [hasMaterials, setHasMaterials] = useState(false);

  const [materialOnSiteItems, setMaterialOnSiteItems] = useState([]);
  const [materialOffSiteItems, setMaterialOffSiteItems] = useState([]);

  useEffect(() => {
    if (contractInfo?.get("materials") !== undefined) {
      setMaterialOnSiteItems(
        contractInfo
          ?.get("materials")
          .toJS()
          .filter((obj) => obj?.on_site),
      );
      setMaterialOffSiteItems(
        contractInfo
          ?.get("materials")
          .toJS()
          .filter((obj) => !obj?.on_site),
      );
    }
  }, [contractInfo]);

  useEffect(() => {
    if (materialOnSiteItems.length > 0 || materialOffSiteItems.length > 0) {
      setHasMaterials(true);
    }
  }, [materialOnSiteItems, materialOffSiteItems]);

  let outstandingClaims = claimSummary.filter(
    (claim) => claim.get("claim_status") === "issue",
  ).size;

  return (
    <ContractDetailWrapper
      className={"setting-page " + (isMaterialExpanded ? "expand-table" : "")}
      id="nav-materials"
    >
      <SettingWrapper>
        {!hasMaterials && (
          <SettingSection>
            <div className="section-title">
              <div className="flex">
                <SectionIcon img="materials" />
                <span>Materials On/Off Site</span>
              </div>
            </div>
            <div className="center">
              <div>No On/Off Site Materials</div>
            </div>
            <div>
              {!readOnly && (
                <div>
                  <Link
                    to={`/contract/${proId}/${type}/${contractId}/materials`}
                  >
                    <ButtonDark title="Add Material" margin={"0 0 0 16px"} />
                  </Link>
                  <UploadLineItem
                    type={"materials"}
                    proId={proId}
                    contractId={contractId}
                    readOnly={readOnly}
                    resetInfo={() =>
                      readContract(proId, contractId, isAdmin, type)
                    }
                  />
                </div>
              )}
            </div>
          </SettingSection>
        )}

        {hasMaterials && (
          <>
            {materialOnSiteItems.length > 0 && (
              <LineItems
                key="on-site"
                isAdmin={isAdmin}
                setExpanded={setIntermediateMaterialExpanded}
                tableContent={fromJS(materialOnSiteItems)}
                tableTitle="Materials On/Off Site"
                tableSubtitle="On Site"
                hasTableSubtitle
                externalSortOptions={{}}
                outstandingClaims={outstandingClaims}
                hasSort={true}
                {...props}
                readOnly={readOnly}
                hasExpand={hasExpand}
                setSortState={setSortState}
                overwriteDefaultSortOptions={sortingOptions}
              />
            )}

            {materialOffSiteItems.length > 0 && (
              <LineItems
                key="off-site"
                isAdmin={isAdmin}
                setExpanded={setIntermediateMaterialExpanded}
                tableContent={fromJS(materialOffSiteItems)}
                tableTitle={
                  materialOnSiteItems.length > 0 ? "" : "Materials On/Off Site"
                }
                tableSubtitle="Off Site"
                hasTableSubtitle
                hasSort={materialOnSiteItems.length > 0 ? false : true}
                {...props}
                readOnly={
                  readOnly || materialOnSiteItems.length > 0 ? true : false
                }
                hasExpand={materialOnSiteItems.length > 0 ? false : hasExpand}
                sortState={sortState}
                isExpanded={isMaterialExpanded}
                overwriteDefaultSortOptions={sortingOptions}
                outstandingClaims={outstandingClaims}
              />
            )}
          </>
        )}
      </SettingWrapper>
    </ContractDetailWrapper>
  );
}

const mapStateToProps = (state) => ({
  claimSummary: state.getIn(["contract", "claimSummary"]),
});

export default connect(mapStateToProps, null)(Materials);
