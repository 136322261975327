import React, { useEffect, useState } from "react";

import {
  ProjectDetailWrapper,
  SettingSection,
  SectionIcon,
  SettingsWrapper,
} from "../styled";

import { AttachOptions } from "../../contract/components/settingsComponent";
import Input from "../../../common/form";

export const FileAttachmentSection = ({
  attachType,
  options,
  handleChange,
  accountConfig,
}) => (
  <SettingsWrapper>
    <div className="item-title">
      File Attachments in {accountConfig?.getIn(["claim", "noun"])}
      s/Certificates
    </div>
    <div>
      This sets the preferred way to include files{" "}
      {accountConfig?.getIn(["claim", "noun"])?.toLowerCase()}/certificate
      emails. It can still be changed during the{" "}
      {accountConfig?.getIn(["claim", "noun"])?.toLowerCase()}/certify process.
      <br />
      Note: The total file size for file attachments cannot exceed 10 MB.
    </div>
    <Input
      label="Include Files as"
      field="dropdown"
      wrapperClassName="input"
      onChange={handleChange}
      value={attachType}
      options={options}
      width="424px"
    />
  </SettingsWrapper>
);

export default function SettingsSection(props) {
  const { projectInfo, submitUpdate, accountConfig } = props;
  const [attachType, setAttachType] = useState("");

  useEffect(() => {
    setAttachType(projectInfo.get("attach_method"));
  }, [projectInfo]);

  const handleChange = (attachType) => {
    setAttachType(attachType);
    submitUpdate(attachType);
  };

  return (
    <ProjectDetailWrapper className="setting-page" id="nav-setting">
      <SettingSection>
        <div className="section-title">
          <div className="flex">
            <SectionIcon img={"setting"} />
            <span>Settings</span>
          </div>
        </div>
        <div className="center">
          <FileAttachmentSection
            attachType={attachType}
            options={AttachOptions}
            handleChange={(e) => handleChange(e.value)}
            accountConfig={accountConfig}
          />
        </div>
      </SettingSection>
    </ProjectDetailWrapper>
  );
}
