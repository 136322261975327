import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { ToastContainer } from "./style";
import Toast from "./toast";
import { actionCreators } from "./store";

const Index = (props) => {
  const { data, dismissToast, readToasts } = props;
  const [toasts, setToasts] = useState({});

  useEffect(() => {
    let initialToasts = {};
    data?.map((toast, id) => {
      initialToasts[id] = (
        <Toast
          id={id}
          title={toast.get("title") || ""}
          description={toast.get("description") || ""}
          actionTitle={toast.get("actionTitle") || ""}
          action={toast.get("action") ? () => toast.get("action")(id) : null}
          type={toast.get("type") || ""}
          icon={toast.get("icon") || ""}
          autoDismissOnly={toast.get("autoDismissOnly") || false}
          dismiss={dismissToast}
        />
      );
      return null;
    });

    setToasts(initialToasts);
  }, [data, readToasts, dismissToast]);

  useEffect(() => {
    readToasts();
  }, []); // eslint-disable-line

  return (
    <ToastContainer>
      {Object.keys(toasts).map((id) => (
        <div key={id}>{toasts[id]}</div>
      ))}
    </ToastContainer>
  );
};

const mapStateToProps = (state) => ({
  data: state.getIn(["toastNotification", "toasts"]),
});

const mapDispatch = (dispatch) => {
  return {
    dismissToast(id) {
      dispatch(actionCreators.dismissToast(id));
    },
    readToasts() {
      dispatch(actionCreators.readToasts());
    },
  };
};

export default connect(mapStateToProps, mapDispatch)(Index);
