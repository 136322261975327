import styled, { css, keyframes } from "styled-components";
import * as themeStyle from "../../theme";

const theme = themeStyle.palette;
const Button = styled.button`
  border: 0;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-family: ${themeStyle.font.mainFont};
  font-weight: ${themeStyle.font.mainFontWeight};
  letter-spacing: 0.2px;
  line-height: ${(props) => props.lineHeight || 1};
  height: ${(props) => props.height || "40px"};
  padding: ${(props) => props.padding || "10px 24px"};
  float: ${(props) => props.float || "right"};
  margin: ${(props) => props.margin || 0};
  text-wrap: ${(props) => props.textWrap || "normal"};
  transition:
    background-color 0.2s ease,
    border-color.2s ease,
    color 0.2s ease;
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`;
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const Img = styled.div`
  height: ${(props) => props.height || "24px"};
  width: ${(props) => props.width || "24px"};
  background: url(${(props) => props.name}) no-repeat;
  background-size: contain;
  display: block;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  float: ${(props) => props.float || "right"};
  margin: ${(props) => props.margin || 0};
  animation: ${(props) =>
    props.animating
      ? css`
          ${rotate} 1s linear infinite
        `
      : ""};
  transform: ${(props) =>
    props.rotateDeg ? `rotate(${props.rotateDeg}deg)` : `none`};
  &.unique-color {
    background: none;
    mask: url(${(props) => props.name}) no-repeat center;
    background-color: ${themeStyle.palette.gunmetal};
  }
`;

export const BluePrimaryButton = styled(Button)`
  color: ${theme.white};
  background-color: ${theme.gunmetal};
  &:hover {
    background-color: #72808a;
  }
  &:active {
    background-color: #5e6e7a;
  }
  &:disabled {
    background-color: ${theme.warmLightGrey};
  }
`;
export const LightBluePrimaryButton = styled(Button)`
  color: ${theme.white};
  background-color: ${theme.turquoiseBlue};
  &:hover {
    background-color: ${theme.tealBlue};
  }
  &:active {
    background-color: ${theme.darkBlue};
  }
  &:disabled {
    background-color: ${(props) =>
      props.submitting ? theme.turquoiseBlue : theme.warmLightGrey};
    opacity: ${(props) => (props.submitting ? 0.6 : 1)};
  }
`;

export const BlueSecondaryButton = styled(Button)`
  color: ${theme.gunmetal};
  background-color: ${theme.white};
  border: 1px solid ${theme.gunmetal};
  &:hover {
    background-color: rgba(66, 85, 99, 0.05);
  }
  &:active {
    background-color: rgba(6, 85, 99, 0.2);
  }
  &:disabled {
    border: 1px solid ${theme.wolfGrey};
    color: ${(props) => (props.submitting ? theme.white : theme.wolfGrey)};
    background-color: ${(props) =>
      props.submitting ? theme.wolfGrey : theme.white};
    opacity: ${(props) => (props.submitting ? 0.6 : 1)};
  }
`;

export const GreenPrimaryButton = styled(Button)`
  color: ${theme.white};
  background-color: ${theme.brightGreen};
  &:hover {
    background-color: #38b74b;
  }
  &:active {
    background-color: #45ae55;
  }
  &:disabled {
    background-color: ${(props) =>
      props.submitting ? theme.brightGreen : theme.warmLightGrey};
    opacity: ${(props) => (props.submitting ? 0.6 : 1)};
  }
`;

export const RedPrimaryButton = styled(Button)`
  color: ${theme.white};
  background-color: #f34228;
  box-shadow: none;
  &:hover {
    background-color: #e83f26;
  }
  &:active {
    background-color: #e0371e;
  }
  &:disabled {
    background-color: ${theme.warmLightGrey};
  }
`;

export const RedSecondaryButton = styled(Button)`
  color: ${theme.watermelonRed};
  border: 1px solid ${theme.watermelonRed};
  box-shadow: none;
  background-color: transparent;
  &.solid {
    background-color: ${theme.white};
  }
  &:hover {
    background-color: rgba(240, 91, 92, 0.05);
  }
  &:active {
    background-color: rgba(240, 91, 92, 0.2);
  }
  &:disabled {
    background-color: ${theme.white};
    border: 1px solid ${theme.wolfGrey};
    color: ${theme.wolfGrey};
  }
`;

export const GreySecondaryButton = styled(Button)`
  color: ${theme.veryMediumGrey};
  background-color: ${theme.veryWarmLightGrey};
  border: 1px solid ${theme.wolfGrey};
  &:hover {
    background-color: ${theme.warmLightGrey};
  }
  &:active {
    background-color: ${theme.wolfGrey};
  }
  &:disabled {
    color: ${theme.white};
    background-color: ${theme.warmLightGrey};
  }
`;

export const YellowPrimaryButton = styled(Button)`
  color: white;
  background-color: ${theme.tangerineOrange};
  &:hover {
    background-color: #eeb30a;
  }
  &:active {
    background-color: #e1a909;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${(props) =>
      props.submitting ? theme.tangerineOrange : theme.warmLightGrey};
    opacity: ${(props) => (props.submitting ? 0.6 : 1)};
  }
`;

export const IconButton = styled.button`
  height: 24px;
  width: 24px;
  background-size: contain;
  display: block;
  cursor: pointer;
  border: none;
  &:active {
    background-color: ${theme.wolfGrey};
    border-radius: 50%;
  }
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
    opacity: 50%;
  }
  &:hover {
    background-color: ${theme.veryWarmLightGrey};
    border-radius: 50%;
  }
`;
