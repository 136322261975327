import * as theme from "../../theme";
import styled from "styled-components";
import { IconButton } from "../button";
export const ImgDirection = styled(IconButton)`
  height: 20px;
  width: 20px;
  background: url(${(props) => props.icon}) no-repeat;
  background-size: contain;
`;

export const PageBar = styled.div`
  float: right;
  margin-top: 30px;
  height: 36px;
  width: 260px;
  &.big {
    width: 500px;
  }
  &.account {
    width: 438px;
    margin-top: 0;
  }
  &.fill {
    width: 100%;
  }
  background-color: ${theme.palette.white};
  border-radius: 4px;
  font-family: ${theme.font.mainFont};
  color: ${theme.palette.gunmetal};
  .right {
    display: flex;
    justify-content: space-between;
  }
  .buttonWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    padding: 8px 20px;
    box-sizing: border-box;
    .select_div {
      height: 44px;
      width: 201px;
      border: 1px solid ${theme.palette.wolfGrey};
      box-sizing: border-box;
      border-radius: 4px;
      margin: -12px;
      .sortTxt {
        text-align: center;
        line-height: 42px;
        width: 39px;
        height: 42px;
        margin-left: 16px;
        font-size: 14px;
        font-weight: ${theme.font.mainFontWeight};
        vertilical-align: center;
        position: absolute;
      }
    }
  }
  .number_count {
    width: 110px;
    margin-right: 8px;
    font-size: 14px;
    line-height: 20px;
  }
  .btn {
    font-weight: ${theme.font.mainFontWeight};
    color: ${theme.palette.veryMediumGrey};
    height: 20px;
    width: 20px;
    font-size: 14px;
    line-height: 20px;
    box-sizing: border-box;
    cursor: pointer;
  }
`;
