import React, { useState, useEffect } from "react";
import { SectionIcon } from "../../project/styled";
import Input from "../../../common/form";
import {
  ContractDetailWrapper,
  SettingWrapper,
  TaxSectionWrapper,
} from "../styled";
import { COUNTRY_GB } from "../../../utils/constants";
import { transformKeyToLabel } from "../../../utils/stringHelper";

export default function TaxSection(props) {
  const { info, accountConfig, handleSubmit } = props;
  const country = info?.get("country");
  const isGB = country === COUNTRY_GB;
  const [isRequired, setIsRequired] = useState(isGB);

  const gstRateTitle = accountConfig?.getIn(["gst_rate", "title"]);

  const cisJson = accountConfig?.getIn(["cis", "json", "status"])?.toJS();
  const cisOptions = cisJson ? Object.values(cisJson) : [];
  const [formData, setFormData] = useState({
    gst: info?.get("gst"),
    domestic_reverse_charge: info?.get("domestic_reverse_charge"),
    cis_status: info?.get("cis_status"),
  });

  useEffect(() => {
    if (info) {
      setFormData({
        gst: info.get("gst"),
        domestic_reverse_charge: info.get("domestic_reverse_charge") || false,
        cis_status: info.get("cis_status"),
      });
    }
  }, [info]);

  useEffect(() => {
    if (isGB && formData.cis_status && formData.gst) {
      setIsRequired(false);
    } else if (isGB) {
      setIsRequired(true);
    }
  }, [formData, isGB]);

  const handleChangeAndBlur = (value, name, isRefresh) => {
    const updatedData = { ...formData, [name]: value };
    setFormData(updatedData);
    handleSubmit(updatedData, isRefresh);
  };

  const taxFields = [
    {
      label: gstRateTitle,
      value: formData.gst,
      name: "gst",
      field: "number",
      suffix: "%",
      className: "input-field number mb-0 medium-width",
      decimalScale: 0,
      onBlur: (e) => handleChangeAndBlur(e.target.value, "gst", false),
      isAllowed: (values) => {
        const { formattedValue, floatValue } = values;
        return formattedValue === "" || floatValue <= 100;
      },
    },
    isGB && {
      label: transformKeyToLabel(
        Object.keys(
          accountConfig?.getIn(["cis", "json", "domestic"])?.toJS() || {},
        )[0],
      ),
      name: "domestic_reverse_charge",
      field: "checkbox",
      type: "checkbox",
      id: "domestic",
      checked: formData.domestic_reverse_charge,
      tooltiptext: accountConfig?.getIn([
        "cis",
        "json",
        "domestic",
        "domestic_reverse_charge",
      ]),
      onChange: (e) =>
        handleChangeAndBlur(e.target.checked, "domestic_reverse_charge", false),
    },
    isGB && {
      label: accountConfig?.getIn(["cis", "title"]),
      name: "cis_status",
      value: formData.cis_status,
      field: "dropdown",
      options: cisOptions,
      placeholder: "Select Status",
      onChange: (e) => handleChangeAndBlur(e.value, "cis_status", true),
      required: true,
      suffix: (
        <div className="description">
          {accountConfig?.getIn(["cis", "description"])}
          <a
            className="description-link"
            rel="noreferrer"
            target="_blank"
            href={accountConfig?.getIn(["cis", "json", "button_link"])}
          >
            {accountConfig?.getIn(["cis", "json", "button_text"])}
          </a>
        </div>
      ),
    },
  ].filter(Boolean);

  return (
    <ContractDetailWrapper
      className={`setting-page ${isRequired ? "required" : ""}`}
      id="nav-tax"
    >
      <SettingWrapper>
        <div className="section-title">
          <div className="flex">
            <SectionIcon img="tax" />
            <span>Tax</span>
          </div>
        </div>
      </SettingWrapper>
      <TaxSectionWrapper>
        {taxFields.map((field) => (
          <div key={field.name} className={field.className}>
            <Input {...field} />
          </div>
        ))}
      </TaxSectionWrapper>
    </ContractDetailWrapper>
  );
}
