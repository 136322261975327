import React from "react";
import { OutLayerWrapper, ClaimWrapper } from "../styled";
import Input from "../../../common/form";

const ListItem = ({ item, claimWord }) => {
  //if not pass claimWord, then do not transfer "claim" word
  // eg claim reference value
  const processLabel = (label) => {
    if (!claimWord) {
      return label;
    }
    let processed = label;
    if (typeof label === "string" && label?.toLowerCase().includes("claim")) {
      processed = label.replace(/claim/gi, claimWord);
    }

    return processed;
  };
  return (
    <>
      {item.field === "date" ? (
        <Input
          label={item.label}
          field="date"
          error={item?.error}
          handleChange={(e) => item.action(item.name, e)}
          initialValue={item.value}
          name={item.name}
          width="450px"
          disable={!item.action}
        />
      ) : item.field === "date-required" ? (
        <Input
          label={item.label}
          field={item.field}
          error={item?.error}
          handleChange={(e) => item.action(item.name, e)}
          initialValue={item.value}
          name={item.name}
          width="450px"
          disable={!item.action}
        />
      ) : (
        <Input
          label={item.label}
          required={item?.required}
          error={item?.error}
          name={item.name}
          className={item.className || "inputStyle"}
          defaultValue={processLabel(item.value)}
          onChange={(e) => item.action(item.name, e)}
          readOnly={!item.action}
          autoFocus={item.autoFocus}
        />
      )}
    </>
  );
};

const Index = ({ header, data, has_retention, accountConfig }) => (
  <OutLayerWrapper>
    <ClaimWrapper>
      <div className="header">{header} Details</div>
      <div className="line_container">
        {data.summary.map((item, index) => (
          <ListItem item={item} key={index} />
        ))}
      </div>
      <div className="sub_header">
        {accountConfig?.getIn(["claim", "noun"])} Period
      </div>
      <div className="line_container">
        {data.period.map((item, index) => (
          <ListItem item={item} key={index} />
        ))}
      </div>

      <div className="multiDate">
        {data.claim_issued && (
          <div className="line_container">
            <ListItem item={data.claim_issued[0]} />
          </div>
        )}
        {data.due_date.map((item, index) => (
          <div className="line_container" key={index}>
            <ListItem item={item} />
            {item.component && (
              <div className="line_container sub">
                {item.component.map((item, index) => (
                  <ListItem
                    item={item}
                    key={index}
                    claimWord={accountConfig
                      ?.getIn(["claim", "noun"])
                      .toLowerCase()}
                  />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      {has_retention && (
        <div className="line_container bottom">
          {data.retention_date.map((item, index) => (
            <ListItem item={item} key={index} />
          ))}
        </div>
      )}
    </ClaimWrapper>
  </OutLayerWrapper>
);
export default Index;
