import React from "react";
import API from "../../../server";
import * as constants from "./constants";
import history from "../../../utils/history";
import { format } from "date-fns";
import { actionCreators } from "../../../common/modal";
import { downloadFile, viewPDF } from "../../../utils/createDownloadLink";
import {
  STATUS_DRAFT,
  CONTRACT,
  EDIT,
  RETENTION_OPTIONS,
  COUNTRY_GB,
} from "../../../utils/constants";
import {
  deleteProject,
  getAddressString,
} from "../../project/store/actionCreators";
import profile from "../../../utils/profile";
import { GetContractOverviewLink } from "../common";
import { getTimezone } from "../../../common/mapbox/map";
import store from "../../../store";
import { roundNumber } from "utils/numeralHelper";

var numeral = require("numeral");

export const createNewContract = (proId, type, data) => {
  return (dispatch, getState) => {
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    if (data.gst) {
      data.gst = parseInt(data.gst);
    }
    if (data.trade === "Other") {
      data.trade = data.tradeText || "Other";
    }
    let token = getState().getIn(["headers", "config", "mapbox_token"]);
    let address = getAddressString(data);

    data.country = data.country || accountInfo?.get("country");
    const create_the_contract = (proId, type, data, accountId) => {
      API.create_one_contract(proId, type, data)
        .then((res) => res.data.data)
        .then((res) => {
          let contractId = res?.id;

          // If contract creation has some message
          if (!contractId) {
            contractId = res?.contract?.id;

            if (res?.contract?.message) {
              store.dispatch(
                actionCreators.showModal("alert", {
                  open: true,
                  title: "Warning!",
                  message: res?.contract?.message,
                }),
              );
            }
          }

          history.push(
            `/contract/${proId}/${type}/${contractId}?account_id=${accountId}`,
          );
        });
      // set claimSummary to an empty array to prevent new contracts having the claimSummary of the recently closed one
      dispatch(setData("claimSummary", []));
    };

    getTimezone(
      address,
      token,
      (fetchedTimezone) => {
        data.time_zone = fetchedTimezone;
        create_the_contract(proId, type, data, accountId);
      },
      () => {
        create_the_contract(proId, type, data, accountId);
      },
    );
  };
};

export const createNewUUID = async () => {
  return API.get_uuid()
    .then((res) => res.data.data)
    .then((res) => {
      return res.uuid;
    });
};

const setPrefilledTag = (bool) => {
  return {
    dateSchedule: bool,
    contractMembers: bool,
    workflow: bool,
    timezone: bool,
    retentions: bool,
  };
};

export const setData = (name, value) => ({
  type: constants.SET_DATA,
  payload: {
    name,
    value,
  },
});

export const readDefault = (proId) => {
  return (dispatch) => {
    API.read_default_contract(proId)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("defaultInfo", res.data));
      });
  };
};

export const readProject = (proId) => {
  return (dispatch) => {
    API.read_one_project_group(proId)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("projectInfo", res.data));
      });
  };
};

export const readContract = (proId, contractId) => {
  return (dispatch) => {
    dispatch(setData("loading", true));
    API.read_contract(proId, contractId)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("contractInfo", res.data));
        dispatch(checkCompleted(res.data));
        dispatch(getClaimSummary(contractId));
      });
  };
};

export const readContractRole = (proId, contractId) => {
  return (dispatch) => {
    API.read_contract_role(proId, contractId)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("contractRole", res?.role));
      });
  };
};

export const changeState = (proId, state) => {
  return (dispatch) => {
    switch (state) {
      case "archive":
        API.archive_project(proId).then((res) => dispatch(readProject(proId)));
        break;
      case "delete":
        dispatch(deleteProject(proId));
        break;
      case "re-open":
        API.reopen_project(proId).then((res) => dispatch(readProject(proId)));
        break;
      default:
        break;
    }
  };
};

export const contractsPreviousAddress = (proId, contractId) => {
  return (dispatch) => {
    API.read_contract(proId, contractId)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("contractsPreviousAddress", res.data));
      });
  };
};

export const updateContract = (
  proId,
  contractId,
  data,
  isRefreshPage = true,
) => {
  if (data.gst) {
    data.gst = parseInt(data.gst);
  }
  if (data.trade === "Other") {
    data.trade = data.tradeText || "Other";
  }

  return (dispatch, getState) => {
    let token = getState().getIn(["headers", "config", "mapbox_token"]);
    let address = `
      ${data.street_address},
      ${data.suburb},
      ${data.city},
      ${data.postal_code},
      ${data.state},
      ${data.country}
    `;
    const update = (proId, contractId, data) => {
      API.upsert_contract(proId, contractId, data)
        .then((res) => res.data)
        .then((res) => {
          isRefreshPage && dispatch(readContract(proId, contractId));
        });
    };

    // check if there's no address update included
    if (
      !data.street_address &&
      !data.suburb &&
      !data.city &&
      !data.postal_code &&
      !data.state
    ) {
      update(proId, contractId, data);
    }
    // update timezone if there's an address update
    else {
      getTimezone(
        address,
        token,
        (timezone) => {
          data.time_zone = timezone;
          update(proId, contractId, data);
        },
        () => {
          update(proId, contractId, data);
        },
      );
    }
  };
};

export const updateRetention = (
  proId,
  contractId,
  retentionType,
  retention,
  retentionScale,
  retentionAccounts,
) => {
  return (dispatch, getState) => {
    let initialRetention = getState().getIn([
      "contract",
      "contractInfo",
      "retention",
    ]);
    const dollar = ["firstAmount", "excessAmount", "maximum"],
      percent = [
        "excessPercent",
        "dlpPercent",
        "firstPercent",
        "pcdPercent",
        "dlpDays",
      ];
    Object.keys(retention).map((key) => {
      if (dollar.includes(key)) {
        retention[key] = Math.round(parseFloat(retention[key]) * 100); //change the dollar money to cents
      }
      if (percent.includes(key)) {
        retention[key] = numeral(retention[key]).value(); //change the input string to int
      }
      if (key === "pcdDate" && retention["pcdDate"]) {
        retention["pcdDate"] = format(
          new Date(retention["pcdDate"]),
          "yyyy-MM-dd",
        ); //change date
      }
      return retention;
    });

    if (
      initialRetention &&
      retentionType === RETENTION_OPTIONS.NO_RETENTION.value
    ) {
      return API.remove_contract_retention(proId, contractId).then((res) => {
        dispatch(readContract(proId, contractId));
      });
    }
    let basicData = {
      retention_type: retentionType,
      firstpercent: retention.firstPercent,
      firstamount: retention.firstAmount,
      pcd_percent: roundNumber(retention.pcdPercent),
      pcd_date: retention.pcdDate,
      release_dlp_type: retention.dlpType,
      dlppercent: roundNumber(retention.dlpPercent),
      dlpdays: retention.dlpDays,
      retention_account: retentionAccounts.map((item) => item.msg),
    };
    if (
      retentionType === RETENTION_OPTIONS.CONTRACT.value ||
      retentionType === RETENTION_OPTIONS.FIXED.value
    ) {
      if (retentionType === RETENTION_OPTIONS.CONTRACT.value) {
        delete basicData.firstamount;
      }
      API.upsert_contract_retention(proId, contractId, basicData).then(
        (res) => {
          dispatch(readContract(proId, contractId));
        },
      );
    }

    if (retentionType === RETENTION_OPTIONS.SCALE.value) {
      basicData = {
        ...basicData,
        excesspercent: retention.excessPercent,
        excessamount: retention.excessAmount,
        maximum: retention.maximum,
      };
      for (var i = 0; i < retentionScale.length; i++) {
        if (i === 0) {
          basicData["firstnextpercent"] = numeral(
            retentionScale[i].percentage,
          ).value();
          basicData["firstnextamount"] = Math.round(
            retentionScale[i].amount * 100,
          );
        } else if (i === 1) {
          basicData["secondnextpercent"] = numeral(
            retentionScale[i].percentage,
          ).value();
          basicData["secondnextamount"] = Math.round(
            retentionScale[i].amount * 100,
          );
        } else if (i === 2) {
          basicData["thirdnextpercent"] = numeral(
            retentionScale[i].percentage,
          ).value();
          basicData["thirdnextamount"] = Math.round(
            retentionScale[i].amount * 100,
          );
        }
      }

      API.upsert_contract_retention(proId, contractId, basicData).then(
        (res) => {
          dispatch(readContract(proId, contractId));
        },
      );
    }
  };
};

export const checkCompleted = (contract) => {
  return (dispatch, getState) => {
    const contractType = getState().getIn(["contract", "contractType"]);
    let hasInvitation = false;
    if (contractType === "claim") {
      hasInvitation =
        contract.payer !== null && contract.payer_company.entity_id !== null;
    } else {
      hasInvitation =
        contract.payee !== null && contract.payee_company.entity_id !== null;
    }

    let completion = {
      "nav-dateSchedule": Object.values(contract.payment_terms).every(
        (ele) => ele !== null,
      ),
      "nav-baseContracts": contract.baseitems !== undefined,
      "nav-invitation": hasInvitation,
    };

    // Only add nav-tax if country is GB
    if (contract.country === COUNTRY_GB) {
      completion["nav-tax"] =
        contract.cis_status !== null && contract.gst !== null;
    }

    dispatch(setData("completionSteps", completion));
  };
};

//delete contract and return to project page
export const leaveContract = (proId, contractId, contractType) => {
  return (dispatch, getState) => {
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    const isDelete = ["claim", "cert"].indexOf(contractType) < 0;

    // leave contract if were not self claim/cert
    dispatch(
      actionCreators.showModal("leaveContract", {
        open: true,
        isDelete: isDelete,
        url: `/project/view/${proId}?account_id=${accountId}`,
        api: { name: "leave_contract", config: [proId, contractId] },
      }),
    );
  };
};

export const startContract = (proId, type, contractId) => {
  return (dispatch) => {
    API.start_contract(proId, contractId).then((res) => {
      dispatch(readContract(proId, contractId));
    });
  };
};

export const moveContract = (contractId, projectId, currentProjectId) => {
  return (dispatch, getState) => {
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    API.move_contract_to_project(contractId, projectId, currentProjectId).then(
      (res) => {
        history.push(`/project/view/${projectId}?account_id=${accountId}`);
      },
    );
  };
};

export const readMembers = (proId, contractId) => {
  return (dispatch) => {
    API.read_contract_member(proId, contractId)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("members", res.members));
      });
  };
};

export const readInvitationList = (proId, contractId) => {
  return (dispatch) => {
    API.read_contract_invitations(proId, contractId)
      .then((res) => res.data)
      .then((res) => res.data.invite_list)
      .then((res) => {
        dispatch(setData("invitationList", res || []));
      });
  };
};

export const updateContractMember = (
  proId,
  contractId,
  data,
  mainContact,
  contractStatus,
  accountId,
  type,
) => {
  return (dispatch) => {
    let dataSend = [];
    data.map((ele) => {
      if (typeof ele.id === "string") {
        dataSend.push({
          user_id: ele.id,
          role: ele.role_id,
          is_key_contact: ele.id === mainContact,
        });
      }
      return ele;
    });
    API.update_contract_member(proId, contractId, { members: dataSend }).then(
      (res) => {
        profile
          .checkPermission(CONTRACT, EDIT, proId, contractId)
          .then((isContractAdmin) => {
            if (!isContractAdmin) {
              if (contractStatus === STATUS_DRAFT) {
                window.location.reload();
              } else {
                history.push(
                  GetContractOverviewLink(accountId, type, proId, contractId),
                );
              }
            }
          });
        dispatch(readMembers(proId, contractId));
      },
    );
  };
};

export const sendInvitation = (proId, contractId, data, contractType) => {
  const bodyData = JSON.stringify({
    to_contacts: [
      {
        contact_name: data.companyName,
        contact_email: data.email,
      },
    ],
  });
  const invitationType = contractType === "claim" ? "cert" : "claim";
  return (dispatch) => {
    API.send_contract_invitation(proId, contractId, invitationType, bodyData)
      .then((res) => res.data)
      .then((res) => {
        dispatch(
          actionCreators.showModal("confirm", {
            open: true,
            title: "Success!",
            message: "You have sent the invitation successfully!",
          }),
        );
        dispatch(readInvitationList(proId, contractId));
        dispatch(readContract(proId, contractId));
      });
  };
};

export const deleteInvitation = (
  proId,
  contractId,
  type,
  invitationId,
  showWarning,
) => {
  const invitationType = type !== "claim" ? "Claimer" : "Certifier";
  return (dispatch) => {
    if (showWarning) {
      dispatch(
        actionCreators.showModal("delete", {
          open: true,
          uniqueTitle: "Remove " + invitationType,
          uniqueMessage: (
            <div>
              This person will be removed as the {invitationType}. They will no
              longer have access to the contract information.
              <div style={{ margin: "15px 0" }}>
                Are you sure you want to remove this person?
              </div>
            </div>
          ),
          uniqueAction: "Remove",
          api: { name: "delete_one_invitation", config: [invitationId] },
          action: [
            readInvitationList(proId, contractId),
            readContract(proId, contractId),
          ],
        }),
      );
    } else {
      API.delete_one_invitation(invitationId).then((res) => {
        dispatch(readInvitationList(proId, contractId));
        dispatch(readContract(proId, contractId));
      });
    }
  };
};

export const cancelInvitation = (proId, contractId, invitationId) => {
  return (dispatch) => {
    API.decline_invitation(invitationId).then((res) => {
      dispatch(readInvitationList(proId, contractId));
      dispatch(readContract(proId, contractId));
    });
  };
};

export const readAttachments = (proId, contractId) => {
  return (dispatch) => {
    API.read_contract_attachments(proId, contractId)
      .then((res) => res.data.data.attachments)
      .then((res) => {
        dispatch(setData("attachments", res));
      });
  };
};

export const deleteAttachment = (proId, contractId, fileId) => {
  return (dispatch) => {
    API.delete_contract_attachment(proId, contractId, fileId).then((res) =>
      dispatch(readAttachments(proId, contractId)),
    );
  };
};

export const downloadAttachment = (proId, contractId, fileId) => {
  return (dispatch) => {
    API.download_contract_attachment(proId, contractId, fileId, {
      responseType: "blob",
    }).then((res) => downloadFile(res));
  };
};

export const updateAttachment = (proId, contractId, attachment) => {
  return (dispatch) => {
    API.update_contract_attachment(proId, contractId, attachment)
      .then((res) => {
        dispatch(readAttachments(proId, contractId));
      })
      .catch((error) => {
        if (error.response) {
          alert(error?.response?.data?.data?.errors);
        } else {
          console.log(error.message);
        }
      });
  };
};

export const uploadsListAdd = (file, progress) => ({
  type: constants.UPLOADS_LIST_ADD,
  payload: {
    file,
    progress,
  },
});

export const uploadListRemove = (fileName) => ({
  type: constants.UPLOADS_LIST_REMOVE,
  fileName,
});

export const uploadFiles = (proId, contractId, fileList) => {
  return (dispatch, getState) => {
    const MAX_CONTRACT_DOCUMENT_SIZE = getState().getIn(["headers", "config"])
      .file_size.contract_document_size_limit;
    if (fileList.length !== 0) {
      // upload files one by one
      fileList.forEach((item) => {
        if (item.size > MAX_CONTRACT_DOCUMENT_SIZE) {
          dispatch(uploadListRemove(item.name));
          dispatch(
            actionCreators.showModal("alert", {
              open: true,
              title: "Error!",
              message:
                "The file is too big to be uploaded. Please use a file that is less than 100MB.",
            }),
          );
        } else {
          let dataSend = new FormData();
          dataSend.append(item.fileType, item);
          API.attach_contract_attachment(
            proId,
            contractId,
            item.fileType,
            dataSend,
            {
              mimeType: "multipart/form-data",
              onUploadProgress: (progress) => {
                const { loaded, total } = progress;
                let percentProgress = Math.floor((loaded / total) * 100);
                dispatch(uploadsListAdd(item, percentProgress));
              },
            },
          )
            .then((res) => res.data.data.attachments[0])
            .then((res) => {
              dispatch(uploadListRemove(res.file_name));
              dispatch(readAttachments(proId, contractId));
            })
            .catch((error) => {
              // remove file from upload list if an error occurs while uploading
              dispatch(uploadListRemove(item.name));
              if (error.response) {
                console.error(error?.response?.data?.data?.errors);
              } else {
                console.log(error.message);
              }
            });
        }
      });
    }
  };
};

export const updatePaymentTerms = (proId, contractId, paymentTerms) => {
  let update = {
    payment_terms: {
      claim_due_days: parseInt(paymentTerms["claim"].days),
      claim_due_term: paymentTerms["claim"].terms,
      cert_due_days: parseInt(paymentTerms["cert"].days),
      cert_due_term: paymentTerms["cert"].terms,
      payment_due_days: parseInt(paymentTerms["pay"].days),
      payment_due_term: paymentTerms["pay"].terms,
    },
  };
  return (dispatch) => {
    API.upsert_contract(proId, contractId, update).then((res) =>
      dispatch(readContract(proId, contractId)),
    );
  };
};

export const calculateDueDate = (proId, paymentTerms) => {
  let data = {
    claim_due_days: parseInt(paymentTerms["claim"].days),
    claim_due_term: paymentTerms["claim"].terms,
    cert_due_days: parseInt(paymentTerms["cert"].days),
    cert_due_term: paymentTerms["cert"].terms,
    payment_due_days: parseInt(paymentTerms["pay"].days),
    payment_due_term: paymentTerms["pay"].terms,
  };
  return (dispatch) => {
    API.calculate_due_dates(proId, data)
      .then((res) => res.data.data.data)
      .then((res) => {
        dispatch(setData("dueDates", res));
      });
  };
};

export const hidePrefilledTag = (component) => {
  return (dispatch, getState) => {
    let prefilledSeen = Object.fromEntries(
      getState().getIn(["contract", "prefilledSeen"]),
    );
    if (component === "all") {
      prefilledSeen = setPrefilledTag(true);
    } else {
      prefilledSeen[component] = true;
    }
    dispatch(setData("prefilledSeen", prefilledSeen));
  };
};

export const readCompanies = () => {
  //request all entity of this account
  return (dispatch) => {
    API.read_account_companies()
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("companyList", res || []));
      });
  };
};

export const readCompanyContacts = (companyId) => {
  return (dispatch) => {
    API.read_company_contacts(companyId)
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("contactList", res));
      });
  };
};

const sendSelfInvitation = (proId, contractId, company, contact) => {
  return (dispatch) => {
    API.self_invitation(proId, contractId, company, contact).then((res) => {
      dispatch(readContract(proId, contractId));
    });
  };
};

export const setSelfInvitation = (
  proId,
  contractId,
  company,
  contact,
  companyInfo,
  contactInfo,
) => {
  return (dispatch) => {
    if (company !== "new" && contact !== "new") {
      dispatch(sendSelfInvitation(proId, contractId, company, contact));
    }

    if (company === "new") {
      // create entity / contact
      API.create_contract_company(proId, contractId, companyInfo)
        .then((res) => res.data)
        .then((res) => res.data.id)
        .then((res) => {
          let entityId = res;
          API.create_contract_contact(proId, contractId, entityId, contactInfo)
            .then((res) => res.data)
            .then((res) => res.data.id)
            .then((res) => {
              dispatch(sendSelfInvitation(proId, contractId, entityId, res));
              dispatch(readCompanies());
            });
        });
    }
    if (company !== "new" && contact === "new") {
      // add contact to entity first
      API.create_contract_contact(proId, contractId, company, contactInfo)
        .then((res) => res.data)
        .then((res) => res.data.id)
        .then((res) => {
          dispatch(sendSelfInvitation(proId, contractId, company, res));
        });
    }
  };
};

export const changeContractStatus = (proId, contractId, action) => {
  return (dispatch) => {
    if (action === "finalise") {
      API.finalise_contract(proId, contractId, action).then((res) =>
        dispatch(readContract(proId, contractId)),
      );
    } else if (action === "reopen") {
      API.reopen_contract(proId, contractId, action).then((res) =>
        dispatch(readContract(proId, contractId)),
      );
    } else if (action === "archive") {
      API.archive_contract(proId, contractId, action).then((res) => {
        dispatch(readContract(proId, contractId));
        dispatch(readProject(proId));
      });
    }
  };
};

export const getClaimSummary = (contractId) => {
  let claimSummary = [];
  return (dispatch) => {
    dispatch(setData("loading", true));
    // Clean up claimSummary first so claimSummary of one contract doesn't appear on other contracts
    dispatch(setData("claimSummary", claimSummary));
    API.read_contract_summary(contractId)
      .then((res) => res.data.data.contractsummary)
      .then((summary) => {
        if (summary) {
          let summaryWithIndex = summary.map((claim, index) => {
            return { ...claim, default_index: index };
          });
          dispatch(setData("claimSummary", summaryWithIndex));
          dispatch(getPayment(contractId, summary));
        }
        dispatch(setData("loading", false));
      });
  };
};

const getPayment = (contractId, claimSummary) => {
  return (dispatch) => {
    let paymentData = [];
    API.read_payment_history(contractId)
      .then((res) => res.data.data.data)
      .then((payHistory) => {
        if (payHistory) {
          paymentData = payHistory.filter((pay) => {
            let claim = claimSummary.find((claim) => claim.id === pay.claim_id);
            return claim && claim.claim_status === "certified";
          });
        }
        dispatch(setData("payHistory", paymentData));
      });
  };
};

export const downloadClaimSummary = (proId, contractId, name) => {
  return (dispatch) => {
    API.download_contract_csv_summary(proId, contractId, {
      responseType: "blob",
    }).then((res) => {
      let fileName = name + "_claimSummary.xlsx";
      downloadFile(res, fileName);
    });
  };
};

export const downloadContractHistory = (proId, contractId, name) => {
  return (dispatch) => {
    API.export_contract_history(proId, contractId, {
      responseType: "blob",
    }).then((res) => {
      let fileName = name + "_History Export.xlsx";
      downloadFile(res, fileName);
    });
  };
};

export const downloadContractLineitemHistory = (
  proId,
  contractId,
  lineId,
  name,
) => {
  return (dispatch) => {
    API.export_contract_lineitem_history(proId, contractId, lineId, {
      responseType: "blob",
    }).then((res) => {
      let fileName = name + "_Line Item History Export.xlsx";
      downloadFile(res, fileName);
    });
  };
};

export const updatePayHistory = (contractId, claimId, file, paidAmount) => {
  let data = new FormData();
  data.append(
    "data",
    JSON.stringify({
      paid_amount: Math.round(numeral(paidAmount).value() * 100),
    }),
  );
  if (file) {
    data.append("file", file);
  }

  return (dispatch) => {
    API.create_claim_payment(contractId, claimId, data).then((res) => {
      dispatch(getClaimSummary(contractId));
    });
  };
};

export const downloadPaymentFile = (fileId, fileName) => {
  return (dispatch) => {
    API.download_one_file(fileId, { responseType: "blob" }).then((res) =>
      downloadFile(res, fileName),
    );
  };
};

//download the contract shared file
export const downloadItemFile = (
  proId,
  contractId,
  fileId,
  fileName,
  isView,
) => {
  return () => {
    API.download_contract_shared_file(proId, contractId, fileId, {
      responseType: "blob",
    }).then((res) => {
      if (isView) {
        viewPDF(res);
      } else {
        downloadFile(res, fileName);
      }
    });
  };
};

export const cleanInfo = () => ({
  type: constants.RESET_DATA,
});

export const updateBctiInfo = (proId, contractId, data) => {
  return (dispatch) => {
    API.update_account_detail(data)
      .then((res) => res.data)
      .then((res) => {
        dispatch(updateContract(proId, contractId, { is_bcti: true }, false));
      });
  };
};

export const handlePendingCert = (
  proId,
  contractId,
  claimId,
  certId,
  isNew,
  contractType,
) => {
  return (dispatch, getState) => {
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);

    API.handle_pending_cert(proId, contractId, claimId, certId, isNew)
      .then((res) => res.data)
      .then(() => {
        return history.push(
          `/project/${proId}/contract/${contractId}/${contractType}/claim/${claimId}/cert?account_id=${accountId}`,
        );
      });
  };
};
export const downloadRetentionPdf =
  (proId, contractId, isDownload) => async () => {
    return API.download_contract_retention_report(proId, contractId, {
      responseType: "blob",
    }).then((res) => {
      if (isDownload) {
        downloadFile(res);
      } else {
        viewPDF(res);
      }
    });
  };

export const readContractDetails = (proId, contractId) => {
  return (dispatch) => {
    API.read_contract(proId, contractId)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("contractInfo", res.data));
      });
  };
};

export const readIntegrationContract = (proId, contractId) => {
  return (dispatch) => {
    API.read_one_ipm_contract(proId, contractId)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("defaultInfo", res.data));
      });
  };
};
