import styled from "styled-components";
import * as theme from "../../theme";
import delIcon from "../../statics/img/icon-clear-medium-grey.svg";
import { IconButton } from "../../common/button";
import expandImg from "../../statics/img/icon-chevron-down-dark-grey.svg";

export const Wrapper = styled.div`
  margin-top: 30px;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  .top {
    height: 64px;
    width: 100%;
    padding: 20px 36px;
    border-bottom: 1px solid ${theme.palette.lightWolfGrey};
    .label {
      font-size: 24px;
      font-weight: ${theme.font.mainFontWeight};
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    &.hide {
      display: none;
    }
  }
`;

export const Top = styled.div`
  margin-bottom: 56px;
  width: 100%;
  height: 44px;
  .filter {
    float: right;
    height: 44px;
    width: 242px;
    background-color: ${theme.palette.white};
    border-radius: 4px;
    .filterText {
      line-height: 42px;
      width: 50px;
      margin-left: 16px;
      font-weight: ${theme.font.mainFontWeight};
      float: left;
    }
  }
`;

export const Delete = styled(IconButton)`
  background: url(${delIcon}) no-repeat;
  float: right;
  margin-top: 8px;
`;
export const ExpandIcon = styled(IconButton)`
  float: right;
  width: 30px;
  height: 30px;
  background: url(${expandImg}) no-repeat;
  background-size: contain;
  cursor: pointer;
  &.hide {
    transform: rotate(180deg);
  }
`;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${theme.palette.lightWolfGrey};
`;

export const ItemWrapper = styled.div`
  width: 100%;
  height: auto;
  border-bottom: 1px solid ${theme.palette.lightWolfGrey};
  padding: 20px 36px;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: ${theme.palette.greyWhite};
  }
`;
export const Icon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 30px;
  background: url(${(props) => props.icon}) no-repeat;
  background-size: contain;
  display: block;
  float: left;
  cursor: pointer;
`;
export const Content = styled.div`
  height: auto;
  width: 720px;
  float: left;
  font-weight: ${theme.font.normalFontWeight};
  .label {
    font-weight: ${theme.font.mainFontWeight};
  }
  .notice {
    margin: 10px 0 20px 0;
  }
  .date {
    color: ${theme.palette.veryMediumGrey};
  }
`;
export const Buttons = styled.div`
  float: right;
  width: 260px;
  margin-top: 19px;
`;
