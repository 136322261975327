import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  LineItemChip,
  CheckBoxWrap,
  ConvertModalButtons,
  ConvertModalContent,
} from "./styled";
import BootstrapTooltip from "../toolTip";
import Input from "../form";

import { checkIsParent } from "../../utils/helper";
import Modal from "../modal/component/modalBase";
import { BlueOutlinedBtn as Button } from "../button";
import {
  currencySubunitToUnit,
  convertToCurrencyUnit,
} from "../../utils/currencyHelper";
import {
  DEFAULT_CURRENCY,
  ON_SITE,
  OFF_SITE,
  LUMP,
  RATE,
} from "../../utils/constants";
//get the expand item's all children key
export const getAllChildKeys = (arr, keys) => {
  if (arr !== null) {
    for (let value of arr) {
      keys.push(value.id);
      keys = getAllChildKeys(value.childitems, keys);
    }
  }
  return keys;
};

// get the last child tree keys of the expand item
export const getLastChildParentKeys = (parentItem, keys) => {
  if (checkIsParent(parentItem)) {
    keys.push(parentItem.id);
    keys = getLastChildParentKeys(
      parentItem.childitems[parentItem.childitems.length - 1],
      keys,
    );
  }
  return keys;
};

export const hasProvisionalChild = (children, hasChild) => {
  children?.forEach((child) => {
    if (child?.is_provisional) {
      hasChild = true;
    }
    // check if any of its descendants are provisional
    if (checkIsParent(child)) {
      hasChild = hasProvisionalChild(child?.childitems, hasChild);
    }
  });

  return hasChild;
};

// returns an array of parent keys (for tree expanding) that have provisional children/descendants
export const getExpandProvisionalParents = (line, expandedList) => {
  let newExpanded = expandedList;
  newExpanded.push(line.id);

  // check if any of its children have provisional children/descendants
  line.childitems?.forEach((child) => {
    if (hasProvisionalChild(child.childitems, false)) {
      getExpandProvisionalParents(child, newExpanded);
    }
  });

  return newExpanded;
};

export const ProvisionalDescription = ({
  data,
  isUpdateExpanded,
  isTreeExpanded,
  expandProvisionalParent,
}) => {
  // show description section with provisional chip
  let provisionalChip = "";
  if (data?.is_provisional) {
    provisionalChip = (
      <LineItemChip className={isUpdateExpanded(data.id) ? "edit" : ""}>
        Provisional
      </LineItemChip>
    );
  } else if (
    hasProvisionalChild(data?.childitems, false) &&
    !isTreeExpanded(data.id)
  ) {
    provisionalChip = (
      <BootstrapTooltip
        title="There are child items that are provisional"
        placement="bottom"
      >
        <LineItemChip
          className={isUpdateExpanded(data.id) ? "edit" : ""}
          onClick={() => expandProvisionalParent(data)}
        >
          P
        </LineItemChip>
      </BootstrapTooltip>
    );
  }

  return (
    <Fragment>
      {data?.description}
      <div className="space" />
      {provisionalChip}
    </Fragment>
  );
};

export const ConvertModal = ({ open, setOpen, data, convert }) => (
  <Modal
    handleClose={() => setOpen(false)}
    open={open}
    title="Convert to Fixed Value"
    divider={false}
  >
    <ConvertModalContent>
      <div className="blue-banner">
        <div className="top">
          <span>Value to be fixed</span>
          <span>{currencySubunitToUnit(data?.total)}</span>
        </div>
        <div>
          <span>Certified to date</span>
          <span>{currencySubunitToUnit(data?.approved_up_to_date_total)}</span>
        </div>
      </div>
      This will change this provisional item to a fixed value and cannot be
      reverted back.
    </ConvertModalContent>
    <ConvertModalButtons>
      <Button
        title="Convert to Fixed"
        className="margin-left"
        onClick={() => {
          setOpen(false);
          convert();
        }}
      />
      <Button title="Leave as Provisional" onClick={() => setOpen(false)} />
    </ConvertModalButtons>
  </Modal>
);

export const LineItemForm = ({
  onChange,
  form,
  status,
  isBlocked,
  lineitemType,
  accountConfig,
  isParent,
  field,
}) => {
  const [displayTotal, setDisplayTotal] = useState("");
  return (
    <div className="form_body">
      <Input
        label="Ref"
        onChange={(e) => onChange(e.target.name, e.target.value)}
        name="label"
        value={form.label}
        width="88px"
      />
      <Input
        label="Description"
        onChange={(e) => onChange(e.target.name, e.target.value)}
        name="description"
        value={form.description}
        width={
          lineitemType === RATE && !isParent
            ? status === "overview"
              ? "482px"
              : "320px"
            : lineitemType === ON_SITE || lineitemType === OFF_SITE
              ? status === "overview"
                ? "966px"
                : "910px"
              : field && field === "dialog"
                ? "706px"
                : "730px"
        }
      />
      {lineitemType === RATE && !isParent && (
        <Fragment>
          <Input
            label="Qty"
            name="quantity"
            field="number"
            onValueChange={(e) => onChange("quantity", e.floatValue)}
            value={form.quantity}
            allowNegative={true}
            decimalScale={2}
            style={{ width: "139px" }}
            disabled={isBlocked}
          />

          <Input
            label="Unit"
            onChange={(e) => onChange(e.target.name, e.target.value)}
            name="unit"
            value={form.unit}
            width="98px"
          />
          <Input
            label="Rate"
            name="rate"
            field="number"
            onValueChange={(e) => onChange(RATE, e.floatValue)}
            value={form.rate}
            allowNegative={true}
            decimalScale={2}
            style={{ width: status === "overview" ? "200px" : "131px" }}
            disabled={isBlocked}
          />
          <Input
            label="Total"
            name="total"
            value={convertToCurrencyUnit(form.rate * form.quantity)}
            disabled
            width={status === "overview" ? "230px" : "174px"}
          />
        </Fragment>
      )}
      {/* For a lumpsum lineitem, relabel "rate" as "total" */}
      {(lineitemType === LUMP || isParent) && ( // parent lineitems use this format regardless of type
        <Input
          label="Total"
          name="rate"
          field="number"
          onValueChange={(e) => {
            setDisplayTotal(e.floatValue);
            onChange(RATE, e.floatValue);
          }}
          placeholder={convertToCurrencyUnit(form.rate * form.quantity)}
          value={displayTotal}
          prefix={
            accountConfig?.getIn(["direction", "value"]) === "LTR"
              ? accountConfig?.getIn(["currency", "title"]) || DEFAULT_CURRENCY
              : ""
          }
          suffix={
            accountConfig?.getIn(["direction", "value"]) === "RTL"
              ? accountConfig?.getIn(["currency", "title"]) || DEFAULT_CURRENCY
              : ""
          }
          allowNegative={true}
          decimalScale={2}
          style={{ width: status === "overview" ? "230px" : "174px" }}
          disabled={isBlocked}
        />
      )}
    </div>
  );
};

// for getting previous value of a state
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const ExcludeRetentionBox = ({
  checked,
  onChange,
  disabled,
  className,
}) => {
  return (
    <CheckBoxWrap disabled={disabled} className={className}>
      <input
        type="checkbox"
        id="exclude_retention"
        checked={checked}
        onChange={onChange}
        className="checkBox"
        disabled={disabled}
      />
      <label htmlFor="exclude_retention">Exclude From Retention</label>
      <BootstrapTooltip
        title="Enabling this setting will exclude this line item from retention calculations."
        placement="bottom"
      >
        <div className="info-icon" />
      </BootstrapTooltip>
    </CheckBoxWrap>
  );
};
