const validate = (values) => {
  const errors = {};

  if (!values.get("name")) {
    errors.name = "Required";
  }

  if (!values.get("phone")) {
    errors.phone = "Required";
  }

  if (!values.get("role")) {
    errors.role = "Required";
  }

  return errors;
};

export default validate;
