import React, { Fragment } from "react";
import { SearchContainer } from "../styled";
import FilterTable from "./filter_table";
export default class DropDownComponent extends React.Component {
  constructor(props) {
    super(props);
    this.setFilter = this.setFilter.bind(this);
    this.state = {
      searchInput: "",
      list: {
        overdue: false,
        due_in_3: false,
        due_in_7: false,
      },
    };
  }

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () =>
      this.handleFilter(this.state.list, this.props.data),
    );
  };
  setFilter = (list) => {
    return this.setState({ ...this.state, list: list });
  };
  handleFilter = (list, table) => {
    let filteredData = table;
    if (list.overdue || list.due_in_3 || list.due_in_7) {
      filteredData = table.filter((value) => {
        return (
          (list.overdue && value.get("over_due_type") === "over_due") ||
          (list.due_in_3 && value.get("over_due_type") === "due_in_3_days") ||
          (list.due_in_7 && value.get("over_due_type") === "due_in_7_days")
        );
      });
      this.globalSearch(filteredData);
    } else this.globalSearch(this.props.data);
  };
  globalSearch = (data) => {
    let { searchInput } = this.state,
      type = this.props.type;
    let filteredData = data.filter((value) => {
      if (type === "claim") {
        return (
          value
            .get("client_name")
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          value.get("name").toLowerCase().includes(searchInput.toLowerCase()) ||
          value
            .get("contract_number")
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        );
      } else {
        return (
          value
            .get("claim_name")
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          value
            .get("contract_name")
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          value
            .get("contract_number")
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        );
      }
    });
    this.props.handleSetData(filteredData);
  };

  render() {
    return (
      <Fragment>
        <FilterTable
          handleFilter={(list) => this.handleFilter(list, this.props.data)}
          setFilter={(list) => this.setFilter(list)}
        />
        <SearchContainer>
          <input
            name="searchInput"
            value={this.state.searchInput || ""}
            onChange={this.handleChange}
            placeholder={this.props.searchLabel}
          />
          <div className="img-search" />
        </SearchContainer>
      </Fragment>
    );
  }
}
