import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, ProjectBackground } from "../project/styled";
import { BlueOutlinedBtn as Button } from "../../common/button";
import Link from "../../common/linkWithQuery";
import profile from "../../utils/profile";
import {
  STATUS_DRAFT,
  CLAIM_ONLY,
  CONTRACT,
  EDIT,
} from "../../utils/constants";
import DraftContract from "./create/settings";
import UnDraftContract from "./view/index";
import Skeleton from "../../common/skeleton";

import { actionCreators } from "./store";
import { actionCreators as actionCreatorsAccount } from "../manageaccount/store";

export const ReturnAction = ({ projectInfo, orgType, action }) => {
  const certContractNum = projectInfo?.get("cert_contracts")?.size || 0;
  // when account org is claiming-only and no cert contracts for the project,
  // will allow users to return projects list page directly from contract page
  if (orgType === CLAIM_ONLY && certContractNum === 0) {
    return (
      <Link to={`/projects`}>
        <Button title="Back To Projects" onClick={action} />
      </Link>
    );
  } else {
    return (
      <Link to={`/project/view/${projectInfo.get("id")}`}>
        <Button title="Back To Project" onClick={action} />
      </Link>
    );
  }
};
class index extends Component {
  state = {
    allowEditContract: true,
  };
  render() {
    const { contractInfo, projectInfo, orgType } = this.props;
    const { allowEditContract } = this.state;

    return (
      <>
        {contractInfo.get("status") ? (
          <>
            {contractInfo.get("status") === STATUS_DRAFT && (
              <DraftContract {...this.props} readOnly={!allowEditContract} />
            )}
            {contractInfo.get("status") !== STATUS_DRAFT && (
              <UnDraftContract {...this.props} readOnly={!allowEditContract} />
            )}
          </>
        ) : (
          <Container>
            <div className="page-breadcrumbs">
              <div />
              <ReturnAction projectInfo={projectInfo} orgType={orgType} />
            </div>
            <ProjectBackground />
            <Skeleton.DetailsCard />
            <div className="skeleton-components">
              <Skeleton.ComponentCard count={10} />
            </div>
          </Container>
        )}
      </>
    );
  }
  componentDidMount() {
    const { proId, contractId } = this.props.match.params;
    profile.checkPermission(CONTRACT, EDIT, proId, contractId).then((res) => {
      this.props.readProject(proId);
      this.props.readContract(proId, contractId);
      this.setState({ allowEditContract: res });
    });
  }
  componentWillUnmount() {
    this.props.cleanInfo();
  }
}
const mapStateToProps = (state) => ({
  config: state.getIn(["headers", "config"]),
  loading: state.getIn(["contract", "loading"]),
  projectInfo: state.getIn(["contract", "projectInfo"]),
  contractInfo: state.getIn(["contract", "contractInfo"]),
  contractsPreviousAddress: state.getIn([
    "contract",
    "contractsPreviousAddress",
  ]),
  members: state.getIn(["contract", "members"]),
  attachments: state.getIn(["contract", "attachments"]),
  orgType: state.getIn(["manageAccount", "orgType"]),
});

const mapDispatchToProps = (dispatch) => ({
  readProject(proId) {
    dispatch(actionCreators.readProject(proId));
  },
  readContract(proId, contractId) {
    dispatch(actionCreators.readContract(proId, contractId));
    dispatch(actionCreators.readMembers(proId, contractId));
    dispatch(actionCreators.readAttachments(proId, contractId));
    dispatch(actionCreatorsAccount.getAccountInfo());
  },
  updateContract(proId, contractId, data, isRefreshPage) {
    dispatch(actionCreators.contractsPreviousAddress(proId, contractId));
    dispatch(
      actionCreators.updateContract(proId, contractId, data, isRefreshPage),
    );
  },
  uploadFiles(proId, contractId, files) {
    dispatch(actionCreators.uploadFiles(proId, contractId, files));
  },
  deleteAttachment(proId, contractId, fileId) {
    dispatch(actionCreators.deleteAttachment(proId, contractId, fileId));
  },
  updateAttachment(proId, contractId, attachment) {
    dispatch(actionCreators.updateAttachment(proId, contractId, attachment));
  },
  downloadAttachment(proId, contractId, fileId) {
    dispatch(actionCreators.downloadAttachment(proId, contractId, fileId));
  },
  updateRetention(proId, contractId, type, info, scale, retentionAccounts) {
    dispatch(
      actionCreators.updateRetention(
        proId,
        contractId,
        type,
        info,
        scale,
        retentionAccounts,
      ),
    );
  },
  leaveContract(proId, contractId, fullContractType) {
    dispatch(actionCreators.leaveContract(proId, contractId, fullContractType));
  },
  cleanInfo() {
    dispatch(actionCreators.cleanInfo());
  },
  updateBctiInfo(proId, contractId, value) {
    dispatch(actionCreators.updateBctiInfo(proId, contractId, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
