import styled from "styled-components";
import * as theme from "../../theme";
import closeIcon from "../../statics/img/icon-clear-white.svg";
import infoIcon from "../../statics/img/icon-information-transparent.svg";
import checkIcon from "../../statics/img/success-yes-green.svg";
import warningIcon from "../../statics/img/icon-orange-warning.svg";
import errorIcon from "../../statics/img/icon-information-red.svg";

export const ToastContainer = styled.div`
  width: calc(100% - 188px);
  min-width: 460px;
  height: max-content;
  position: fixed;
  bottom: 20px;
  left: 94px;
  z-index: 100;
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: 8px;
  pointer-events: none;

  div {
    display: flex;
    justify-content: center;
  }
`;

export const ToastStyle = styled.div`
  height: 64px;
  padding: 8px 24px;
  border-radius: 8px;
  display: grid;
  grid-auto-column: auto;
  grid-column-gap: 16px;
  pointer-events: auto;

  div {
    height: 100%;
    display: flex;
    align-items: center;
    line-height: 27px;
    font-size: 18px;
    letter-spacing: 0.5px;
    font-weight: 500;
  }

  .description {
    font-weight: 400;
  }
  .action {
    font-size: 16px;
    cursor: pointer;
    padding: 12px;
  }
  .dismiss {
    align-self: center;
    width: 16px;
    height: 16px;
    mask-size: 16px;
    mask: url(${closeIcon}) no-repeat center;
    cursor: pointer;

    &.burnt {
      background-color: white;
    }
    &.avocado {
      background-color: ${theme.palette.leafGreen};
    }
    &.honey {
      background-color: ${theme.palette.carrotOrange};
    }
    &.ketchup {
      background-color: ${theme.palette.dangerRed};
    }
  }
  .icon {
    align-self: center;
    width: 24px;
    height: 24px;
    z-index: 101;
    &.info {
      background-color: white;
      mask: url(${infoIcon}) no-repeat center;
      mask-size: contain;
    }
    &.check {
      background-color: ${theme.palette.leafGreen};
      mask: url(${checkIcon}) no-repeat center;
      mask-size: contain;
    }
    &.warning {
      background-color: ${theme.palette.carrotOrange};
      mask: url(${warningIcon}) no-repeat center;
      mask-size: contain;
    }
    &.error {
      background-color: ${theme.palette.dangerRed};
      mask: url(${errorIcon}) no-repeat center;
      mask-size: contain;
    }
  }

  &.burnt {
    background-color: ${theme.palette.smokyBlack};
    color: white;
  }
  &.avocado {
    background-color: ${theme.palette.leafGreenSubtle};
    color: ${theme.palette.leafGreen};
  }
  &.honey {
    background-color: ${theme.palette.carrotOrangeSubtle};
    color: ${theme.palette.carrotOrange};
  }
  &.ketchup {
    background-color: ${theme.palette.dangerRedSubtle};
    color: ${theme.palette.dangerRed};
  }
`;
