import React from "react";
import { TableWrapper } from "../styled";
import history from "../../../utils/history";
import { currencySubunitToUnit } from "../../../utils/currencyHelper";
import { NoticeBell } from "./noticeBell";

function openProjects(id, accountId) {
  return history.push(
    "/claims-received/contract-overview/" + id + "?account_id=" + accountId,
  );
}

function openProjectsNewWin(id, accountId) {
  return window.open(
    "/claims-received/contract-overview/" + id + "?account_id=" + accountId,
  );
}

function TableHeaderItem(props) {
  return (
    <th className={props.className}>
      {props.label}
      {props.sortField !== null && (
        <>
          <span
            className="imgSort up"
            onClick={() => props.sortOption(props.sortField + "$up")}
          />
          <span
            className="imgSort"
            onClick={() => props.sortOption(props.sortField + "$down")}
          />
        </>
      )}
    </th>
  );
}

export const OtherTable = (props) => {
  const {
    table,
    limit,
    currentPage,
    tableTotal,
    sortOption,
    accountId,
    accountConfig,
  } = props;

  const label = accountConfig?.getIn(["claim", "value"])
    ? accountConfig?.getIn(["claim", "value"])
    : "Claimed";

  const tableHeader = [
    {
      label: "Contract Ref",
      sortField: "contract_number",
      className: "client",
    },
    { label: "Contractor", sortField: "claim_name", className: "client" },
    {
      label: "Contract Name",
      sortField: "contract_name",
      className: "project",
    },
    { label: "Contract Total", sortField: "total", className: "total_value" },
    { label: label, sortField: "claimed", className: "value" },
    { label: "Approved", sortField: "approved", className: "value" },
    { label: "Remaining", sortField: "remaining", className: "value" },
  ];

  return (
    <TableWrapper>
      <table className="claims-received_step5">
        <thead>
          <tr>
            {tableHeader.map((item) => (
              <TableHeaderItem
                key={item.label}
                sortOption={sortOption}
                label={item.label}
                sortField={item.sortField}
                className={item.className}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {table.map((item, index) => {
            let page = currentPage - 1;
            if (index >= page * limit && index < (page + 1) * limit) {
              return (
                <tr
                  onClick={() => openProjects(item.get("id"), accountId)}
                  onAuxClick={() =>
                    openProjectsNewWin(item.get("id"), accountId)
                  }
                  key={item.get("id")}
                >
                  <td className="alignToLeft">{item.get("contract_number")}</td>
                  <td className="alignToLeft">{item.get("claim_name")}</td>
                  <td className="alignToLeft">
                    <NoticeBell
                      due={item.get("over_due_type")}
                      field={"Cert"}
                      accountConfig={accountConfig}
                    />
                    {item.get("contract_name")}
                  </td>
                  <td>{currencySubunitToUnit(item.get("total"))}</td>
                  <td>{currencySubunitToUnit(item.get("claimed"))}</td>
                  <td>{currencySubunitToUnit(item.get("approved"))}</td>
                  <td>{currencySubunitToUnit(item.get("remaining"))}</td>
                </tr>
              );
            } else return "";
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="empty" colSpan="2" />
            <td>Contract Totals</td>
            <td>{currencySubunitToUnit(tableTotal.ProjectTotal)}</td>
            <td>{currencySubunitToUnit(tableTotal.Claimed)}</td>
            <td>{currencySubunitToUnit(tableTotal.Approved)}</td>
            <td>{currencySubunitToUnit(tableTotal.Remaining)}</td>
          </tr>
        </tfoot>
      </table>
    </TableWrapper>
  );
};
