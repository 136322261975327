import React, { useState, useEffect } from "react";

import Modal from "../../../../common/modal/component/modalBase";
import {
  GreenWhiteBtn as Save,
  BlueOutlinedBtn as Cancel,
} from "../../../../common/button";
import Input from "../../../../common/form";
import {
  DetailBox,
  Title,
  TimezoneIcon,
  DetailInfo,
  ImgEdit,
  ModalContent,
  ModalButtons,
  DropdownComponent,
} from "../style";
import { timezoneLabel } from "../../../../utils/dateTimeHelper";

const TimezoneModal = (props) => {
  const { updateTimezone, timezone, setOpen, open, timezoneOptions, label } =
    props;
  const [displayOption, setDisplayOption] = useState(timezone);

  useEffect(() => {
    setDisplayOption({ label: timezone, value: timezone });
  }, [timezone, open]);

  return (
    <Modal
      open={open}
      title="Time Zone"
      divider={false}
      handleClose={() => setOpen(false)}
    >
      <ModalContent>
        {label}
        <DropdownComponent>
          <h4>Time Zone</h4>
          <Input
            field="dropdown"
            options={timezoneOptions.toJS()}
            width="100%"
            onChange={(e) => setDisplayOption(e)}
            value={displayOption?.value}
            usePortal={true}
          />
          <div className="sub-text">{label}</div>
        </DropdownComponent>
      </ModalContent>
      <ModalButtons>
        <Cancel title="Cancel" onClick={() => setOpen(false)} />
        <Save
          title="Save"
          onClick={() => {
            updateTimezone(displayOption.value);
            setOpen(false);
          }}
        />
      </ModalButtons>
    </Modal>
  );
};

function Timezone(props) {
  const { accountInfo, getTimezoneOptions, accountConfig } = props;
  const [open, setOpen] = useState(false);

  const label = (
    <>
      This time zone setting affects account-level features only (such as when
      automatic emails are sent).
      <br />
      {accountConfig?.getIn(["claim", "noun"])} and Certificate dates and times
      are controlled by each Contract's time zone.
    </>
  );

  useEffect(() => {
    getTimezoneOptions(accountInfo?.get("country"));
  }, [accountInfo]); // eslint-disable-line

  return (
    <DetailBox>
      <Title>
        <TimezoneIcon />
        Time Zone
      </Title>
      <div className="margin-top">{label}</div>
      <DetailInfo>
        <div>{timezoneLabel(accountInfo?.get("time_zone"))}</div>
        <ImgEdit onClick={() => setOpen(true)} />
      </DetailInfo>
      <TimezoneModal
        {...props}
        timezone={accountInfo?.get("time_zone")}
        setOpen={setOpen}
        open={open}
        label={label}
      />
    </DetailBox>
  );
}

export default Timezone;
