import React from "react";
import moment from "moment";
import {
  utcToZonedTime,
  zonedTimeToUtc,
  format,
  getTimezoneOffset,
} from "date-fns-tz";
import { isValid, parseISO } from "date-fns";
import store from "../store";

import TimezoneTip from "../common/toolTip/timezoneTip";
import enAU from "date-fns/locale/en-AU";
import enGB from "date-fns/locale/en-GB";
import enNZ from "date-fns/locale/en-NZ";
// import enIN from "date-fns/locale/en-IN";
import { arSA } from "date-fns/locale";

import { COUNTRY_TIMEZONES, DEFAULT_COUNTRY } from "./constants";

const timeStyle = { float: "left", lineHeight: "20px" };
const locale = {
  AU: enAU,
  NZ: enNZ,
  GB: enGB,
  SA: arSA,
};

export const formatInTimeZone = (
  dateTime,
  fmt = "yyyy-MM-dd kk:mm:ss xxx",
  timezone,
) => {
  if (!dateTime) {
    return "";
  }
  const parsedTime = parseISO(dateTime);
  return format(utcToZonedTime(parsedTime, timezone), fmt, {
    timeZone: timezone,
  });
};

export function convertTimeToUTC(date, timeZone) {
  const zoneTime = new Date(date);
  const utcTime = isValid(zoneTime) ? zonedTimeToUtc(zoneTime, timeZone) : "";
  const formatTime = isValid(utcTime) ? utcTime.toISOString() : ""; //2011-10-05T14:48:00.000Z
  return formatTime;
}

export function getLocale() {
  const country = store
    .getState()
    .getIn(["config", "accountConfig", "country", "code"]);
  return locale[country] || locale[DEFAULT_COUNTRY];
}

export function getTimezoneAbbr(formatString = "zzz") {
  const date = new Date();
  const defaultCountryTimezone = store
    .getState()
    .getIn(["config", "accountConfig", "default_timezone", "value"]);
  const accountTimezone = store
    .getState()
    .getIn(["manageAccount", "accountInfo", "time_zone"]);
  const accountCountry = store
    .getState()
    .getIn(["manageAccount", "accountInfo", "country"]);
  const timezone = accountTimezone || defaultCountryTimezone;

  const tzDate = utcToZonedTime(date, timezone);
  let zoneString = format(tzDate, formatString, {
    timeZone: timezone,
    locale: getLocale(),
  });
  if (accountCountry === "SA") {
    zoneString = "AST";
  }
  return zoneString;
}

export function convertUTCToTime(
  date,
  timeZone,
  hasTimestampTip = false,
  formatString = "dd/MM/yyyy HH:mm:ss",
) {
  let locale = getLocale();
  const accountCountry = store
    .getState()
    .getIn(["manageAccount", "accountInfo", "country"]);
  const utcTime = new Date(date);
  const zoneTime = isValid(utcTime) ? utcToZonedTime(utcTime, timeZone) : "";
  if (!isValid(utcTime) || !isValid(zoneTime)) {
    return hasTimestampTip ? "" : ["", "", ""];
  }
  const formatTime = format(zoneTime, formatString, { timeZone });
  let zoneAbbr = format(zoneTime, "zzz", {
    timeZone,
    locale,
  });
  let zoneName = format(zoneTime, "zzzz", {
    timeZone,
    locale,
  });
  if (accountCountry === "SA") {
    zoneAbbr = "AST";
    zoneName = "Arabia Standard Time";
  }
  if (hasTimestampTip) {
    return (
      <div>
        <span style={timeStyle}>{formatTime}</span>
        <TimezoneTip zoneAbbr={zoneAbbr} zoneName={zoneName} />
      </div>
    );
  }
  return [formatTime, zoneAbbr, zoneName];
}

export function convertLocalToUTC(date, format = "YYYY-MM-DDTHH:mm:ss[Z]") {
  const momentDate = moment(date);
  if (!momentDate.isValid()) {
    return "";
  }
  return momentDate.utc().format(format);
}

export function getWeekList() {
  let weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  return weekdays.map((item, index) => ({
    label: item,
    value: index + 1,
  }));
}

export function getHoursList() {
  // value : 1-24 label : 01:00-12:00 AM/PM
  let res = [];
  let hours, amPm;
  for (let i = 1; i < 25; i++) {
    amPm = i <= 12 ? "AM" : "PM";
    hours = i === 12 || i === 24 ? 12 : i % 12;
    if (hours < 10) {
      hours = "0" + hours;
    }
    res.push({
      label: hours + ":00 " + amPm,
      value: i,
      badge: getTimezoneAbbr(),
    });
  }
  return res;
}

export function formatDate(date, format = "D MMM YYYY") {
  return date && moment(date).isValid() ? moment(date).format(format) : "";
}

export function timezoneLabel(timezone) {
  if (timezone) {
    var v = getTimezoneOffset(timezone) / 60 / 60 / 1000;
    var minutes = (v - Math.floor(v)) * 60;
    var label =
      "UTC " +
      (v > -1 ? "+" : "") +
      Math.floor(v) +
      (minutes > 0 ? ":" + minutes : "");

    var name = timezone.slice(timezone.indexOf("/") + 1);
    name = name.replace("_", " ");

    return label + " (" + name + " Time)";
  }
  return "";
}

export function getLastDateOfMonth(dateString) {
  if (!dateString) {
    return null;
  }
  // Split the input string into month and year parts
  const [month, year] = dateString.split("-");

  if (!year) {
    return null;
  }

  // Create a Date object for the first day of the next month
  const firstDayOfNextMonth = new Date(year, month - 1 + 1, 1);

  // Subtract one day to get the last day of the current month
  const lastDateOfMonth = new Date(firstDayOfNextMonth - 1);

  return lastDateOfMonth;
}

export const compareDates = (date1, date2) => {
  if (!Date.parse(date1) || !Date.parse(date2)) {
    return "invalid";
  }

  // Convert date1 and date2 to Date objects.
  const date1Date = new Date(date1);
  const date2Date = new Date(date2);

  // Compare the Date objects.
  if (date1Date.getTime() === date2Date.getTime()) {
    return "equal";
  } else if (date1Date.getTime() > date2Date.getTime()) {
    return "greater";
  } else {
    return "lesser";
  }
};

export const filterTimezonesByCountry = (country, timezone) => {
  const filter = COUNTRY_TIMEZONES[country?.toUpperCase()];
  if (filter && timezone) {
    return timezone?.filter((timezone) =>
      filter.some((keyword) => timezone.includes(keyword)),
    );
  }
  return timezone ? timezone : [];
};
