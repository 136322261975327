import axios from "axios";
import { publicIp } from "./apiIp";
import { httpCode, processMessage } from "./helper.js";
import { actionCreators } from "../../common/modal";
import store from "../../store";
import { MAPBOX_LOADING_ISSUE, MAPBOX_URL } from "../api/mapbox.js";

const instanceExt = axios.create({
  //axios instance
  timeout: 600000, // delay time 10 mins
  baseURL: publicIp,
});

// post header
instanceExt.defaults.headers.post["Content-Type"] = "application/json";

/** Add response interceptor  **/
instanceExt.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  async (error) => {
    if (error.response) {
      console.error(error.response.data.message);
      // According to the http status code of the request
      // failure to give the user the corresponding prompt
      let errorMsg = "";

      if (error.response.status in httpCode) {
        errorMsg = httpCode[error.response.status];
      } else if (
        error.response.request.responseType === "blob" &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        const msg = JSON.parse(await error.response.data.text());
        errorMsg = msg.data.errors;
      } else {
        // add message for mapbox errors
        if (error.request.responseURL?.includes(MAPBOX_URL)) {
          errorMsg = MAPBOX_LOADING_ISSUE;
        } else {
          errorMsg = error?.response?.data?.data?.errors;
        }
      }

      store.dispatch(
        actionCreators.showModal("alert", {
          open: true,
          title: "Error!",
          message: processMessage(errorMsg),
        }),
      );

      return Promise.reject(error);
    } else {
      const isMapbox = error.config.url?.includes(MAPBOX_URL);
      store.dispatch(
        actionCreators.showModal("alert", {
          open: true,
          title: "Error!",
          message: isMapbox
            ? MAPBOX_LOADING_ISSUE
            : "Time out, please refresh again!",
        }),
      );
      return Promise.reject(
        new Error(
          isMapbox ? MAPBOX_LOADING_ISSUE : "Time out, please refresh again!",
        ),
      );
    }
  },
);

export const httpRequestExt = (method, url, data, config = {}) => {
  return instanceExt({
    method,
    url,
    data,
    ...config,
  });
};
