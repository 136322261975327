import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { SectionIcon } from "../../project/styled";
import {
  SettingWrapper,
  ContractDetailWrapper,
  SettingContent,
} from "../styled";

import Input from "../../../common/form";
import BctiModal from "../../../common/bcti";
import {
  CONTRACT_TYPE,
  DOWNLOAD_LINK,
  STATUS_ACTIVE,
  FILE_ATTACHMENT,
} from "../../../utils/constants";
import profile from "../../../utils/profile";
import { FileAttachmentSection } from "../../project/components/settingsSection";

import API from "../../../server";

const LevelOptions = [1, 2, 3, 4, 5, 6].map((level) => ({
  label: level,
  value: level,
}));

export const AttachOptions = [
  { label: "Download Link", value: DOWNLOAD_LINK },
  { label: "File Attachment", value: FILE_ATTACHMENT },
];

const CheckEnable = (data) => (data ? "Enabled" : "Disabled");

const Setting = (props) => {
  const {
    contractInfo,
    updateInfo,
    isClaimContract,
    readOnly,
    proId,
    contractId,
    updateBctiInfo,
    accountConfig,
  } = props;
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({
    comments_visible: false,
    provisional_visible: false,
    print_level: 0,
    has_appendix: false,
    is_bcti: false,
    attach_method: "",
    visible_retention_report: false,
  });
  const [bctiInfo, setBctiInfo] = useState({});
  const settingType = isClaimContract ? "Claim" : "Certificate";
  const isAccountAdmin = profile.isAdminOrOwner();
  const claimTerm = accountConfig.get("claim");

  useEffect(() => {
    if (contractInfo) {
      setInfo({
        print_level: contractInfo.get("print_level"),
        has_appendix: contractInfo.get("has_appendix"),
        is_bcti: contractInfo.get("is_bcti"),
        attach_method: contractInfo.get("attach_method"),
        comments_visible: isClaimContract
          ? contractInfo.get("visible_claim_comments")
          : contractInfo.get("visible_cert_comments"),
        provisional_visible: isClaimContract
          ? contractInfo.get("visible_claim_provisional")
          : contractInfo.get("visible_cert_provisional"),
        visible_retention_report: contractInfo?.get("visible_retention_report"),
      });
    }
  }, [contractInfo, isClaimContract]);

  const changeSetting = (name, value) => {
    if (name === "is_bcti" && value === true) {
      const view =
        settingType === "Claim" ? CONTRACT_TYPE.claim : CONTRACT_TYPE.cert;
      API.check_bcti(proId, contractId, view)
        .then((res) => res.data.data)
        .then((res) => {
          if (
            res.bcti["has_gst"] &&
            res.bcti["has_name"] &&
            res.bcti["has_address"]
          ) {
            setInfo({ ...info, [name]: value });
            updateInfo({ [name]: value });
          } else {
            setBctiInfo(res.bcti);
            setOpen(true);
          }
        });
    } else if (name === "comments_visible") {
      if (
        [CONTRACT_TYPE.selfClaim, CONTRACT_TYPE.selfCert].includes(
          contractInfo?.get("contract_type"),
        )
      ) {
        setInfo({ ...info, [name]: value });
        updateInfo({
          visible_claim_comments: value,
          visible_cert_comments: value,
        });
      } else {
        let new_name = isClaimContract
          ? "visible_claim_comments"
          : "visible_cert_comments";
        setInfo({ ...info, [name]: value });
        updateInfo({ [new_name]: value });
      }
    } else if (name === "provisional_visible") {
      if (
        [CONTRACT_TYPE.selfClaim, CONTRACT_TYPE.selfCert].includes(
          contractInfo?.get("contract_type"),
        )
      ) {
        setInfo({ ...info, [name]: value });
        updateInfo({
          visible_claim_provisional: value,
          visible_cert_provisional: value,
        });
      } else {
        let new_name = isClaimContract
          ? "visible_claim_provisional"
          : "visible_cert_provisional";
        setInfo({ ...info, [name]: value });
        updateInfo({ [new_name]: value });
      }
    } else {
      setInfo({ ...info, [name]: value });
      updateInfo({ [name]: value });
    }
  };

  if (readOnly) {
    return (
      <ContractDetailWrapper className="setting-page" id="nav-setting">
        <SettingWrapper>
          <div className="section-title">
            <div>
              <SectionIcon img={"setting"} />
              <span>Settings</span>
            </div>
          </div>
          <div className="center">
            {[
              {
                section: `PDF Export`,
                sectionCss: "",
                fields: (
                  <div className="setting-info grid">
                    <div>
                      <b>Include Comments Visible to Other Party</b>
                    </div>
                    <div>
                      <b>Include Provisional Sums Summary</b>
                    </div>
                    <div>{CheckEnable(info.comments_visible)}</div>
                    <div>{CheckEnable(info.provisional_visible)}</div>
                  </div>
                ),
              },
              {
                section: "Certificates",
                sectionCss: "margin-top",
                fieldTitle: "Include Retention Report",
                fieldValue: CheckEnable(info.visible_retention_report),
                hide:
                  contractInfo?.get("contract_type") === CONTRACT_TYPE.claim,
              },
              {
                section: `${claimTerm?.get("noun")}s`,
                sectionCss: "margin-top",
                fields: (
                  <div className="setting-info grid">
                    <div>
                      <b>Max Claims Item Level</b>
                    </div>
                    <div>
                      <b>Include Child Items</b>
                    </div>
                    <div>{contractInfo.get("print_level")}</div>
                    <div>{CheckEnable(contractInfo.get("has_appendix"))}</div>
                  </div>
                ),
                hide: !isClaimContract,
              },
              {
                section: accountConfig?.getIn([
                  "buyer_created_tax_invoices",
                  "title",
                ]),
                sectionCss: "margin-top",
                fieldTitle: accountConfig?.getIn(["bcti", "title"]),
                fieldValue: CheckEnable(contractInfo.get("is_bcti")),
                hide:
                  accountConfig?.getIn([
                    "buyer_created_tax_invoices",
                    "status",
                  ]) !== STATUS_ACTIVE,
              },
              {
                section: "File Attachment in Claims/Certificates",
                sectionCss: "margin-top",
                fieldTitle: "Include Files as",
                fieldValue:
                  info.attach_method === DOWNLOAD_LINK
                    ? "Download Link"
                    : "File Attachment",
              },
            ].map(
              ({
                hide,
                sectionCss,
                section,
                fields,
                fieldTitle,
                fieldValue,
              }) => (
                <Fragment key={section}>
                  {!hide && (
                    <>
                      <div className={`sub-header ${sectionCss}`}>
                        {section}
                      </div>
                      {fields}
                      {!fields && (
                        <div className="setting-info margin-top">
                          <div>
                            <b>{fieldTitle}</b>
                            <div>{fieldValue}</div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </Fragment>
              ),
            )}
          </div>
        </SettingWrapper>
      </ContractDetailWrapper>
    );
  }

  const toggleSection = (name, toggleText) => (
    <Input
      field="toggle"
      name={name}
      toggleText={toggleText}
      wrapperClassName="input-margin"
      style={{ width: "424px" }}
      onChange={(e) => changeSetting(e.target.name, e.target.checked)}
      checked={info[name] ?? false}
    />
  );
  return (
    <ContractDetailWrapper className="setting-page" id="nav-setting">
      <SettingWrapper>
        <div className="section-title">
          <div>
            <SectionIcon img={"setting"} />
            <span>Settings</span>
          </div>
        </div>
        <div className="center">
          <SettingContent>
            {[
              {
                label: "PDF Export",
                toggle: "comments_visible",
                toggleText: "Include comments visible to other party",
                toggleDescription:
                  "Include line item comments that are visible to the other party",
              },
              {
                toggle: "provisional_visible",
                toggleText: "Include provisional sums summary",
                toggleDescription: `Include provisional sums summary in an appendix. This will
                  include all ${claimTerm
                    ?.get("noun")
                    ?.toLowerCase()} item levels.`,
              },
              {
                label: "Certificates",
                labelCss: "small-size",
                toggle: "visible_retention_report",
                toggleText: "Include retention report",
                toggleDescription: "Include retention report in an appendix",
                hide:
                  contractInfo?.get("contract_type") === CONTRACT_TYPE.claim,
              },
            ].map(
              ({
                label,
                labelCss,
                toggle,
                toggleText,
                toggleDescription,
                hide,
              }) => {
                return (
                  <Fragment key={`fragment-${label}`}>
                    {!hide && (
                      <span key={`unhide-${label}`}>
                        <h2 className={`top-container ${labelCss}`}>{label}</h2>
                        <div>
                          {toggleSection(toggle, toggleText)}
                          <div className="small-label">{toggleDescription}</div>
                        </div>
                      </span>
                    )}
                  </Fragment>
                );
              },
            )}

            {isClaimContract && (
              <>
                <h2 className="form-container">{claimTerm?.get("noun")}s</h2>
                <div>
                  <Input
                    label={`Max ${claimTerm?.get("noun")} Item Level`}
                    field="dropdown"
                    wrapperClassName="input-margin"
                    onChange={(e) => changeSetting("print_level", e.value)}
                    value={info.print_level}
                    options={LevelOptions}
                    width="424px"
                  />
                  <div className="small-label">
                    This is the depth of base contract and variation items that
                    will display in the first section of the generated Payment{" "}
                    {claimTerm?.get("noun")} PDF.
                  </div>
                </div>
                <div>
                  {toggleSection("has_appendix", "Include child items")}
                  <div className="small-label">
                    Include child items in an appendix
                  </div>
                </div>
              </>
            )}

            {accountConfig?.getIn(["buyer_created_tax_invoices", "status"]) ===
              STATUS_ACTIVE && (
              <div className="form-container">
                <h2 className="hasMargin">
                  {accountConfig?.getIn([
                    "buyer_created_tax_invoices",
                    "title",
                  ])}
                </h2>
                <div>
                  This allows the Payment Certificates to act as an invoice.{" "}
                  {accountConfig?.getIn(["gst_number", "title"])}, company name
                  and address is needed.
                  <br />
                  {claimTerm?.get("name")}s will also be informed when they make{" "}
                  {claimTerm?.get("noun")?.toLowerCase()}s that they do not need
                  to include a tax invoice.
                </div>
                <div>
                  {toggleSection(
                    "is_bcti",
                    `Enable ${accountConfig?.getIn([
                      "buyer_created_tax_invoices",
                      "title",
                    ])}`,
                    accountConfig?.getIn(["bcti", "title"]),
                  )}
                </div>
              </div>
            )}
            <div className="form-container">
              <FileAttachmentSection
                attachType={info.attach_method}
                options={AttachOptions}
                handleChange={(e) => changeSetting("attach_method", e.value)}
                accountConfig={accountConfig}
              />
            </div>
          </SettingContent>
        </div>
      </SettingWrapper>
      <BctiModal
        isOpen={open}
        submit={(data) => {
          if (isAccountAdmin) {
            updateBctiInfo(proId, contractId, data);
            setInfo({ ...info, is_bcti: true });
          }
          setOpen(false);
        }}
        close={() => {
          setOpen(false);
          setInfo({ ...info, is_bcti: false });
        }}
        bctiInfo={bctiInfo}
        isAccountAdmin={isAccountAdmin}
        page="contract"
      />
    </ContractDetailWrapper>
  );
};

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default connect(mapStateToProps, null)(Setting);
