import { COUNTRY_AU } from "../../../utils/constants";

export const validate = (values, props) => {
  const { companyCountry, useExistingEntity } = props;
  const errors = {};

  if (!values.get("payerName")) {
    errors.payerName = "Required";
  }

  if (!useExistingEntity) {
    if (!values.get("entity_name")) {
      errors.entity_name = "Required";
    }

    if (!values.get("street_address")) {
      errors.street_address = "Required";
    }

    if (companyCountry === COUNTRY_AU) {
      if (!values.get("state")) {
        errors.state = "Required";
      }
    }
  }

  return errors;
};
