import styled from "styled-components";
import * as theme from "../../theme";
import warningIcon from "../../statics/img/icon-information-red.svg";

export const RequiredBannerWrap = styled.div`
  width: 100%;
  background-color: ${theme.palette.lightPastelPink};
  padding: 16px 24px;
  border-radius: 4px;
  margin: ${(props) => props?.marginTop || 0} 0 40px 0;
  &.empty {
    padding: 0;
  }

  .message {
    display: flex;
    align-items: center;
    .warning-icon {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      background: url(${warningIcon}) no-repeat;
      display: inline-flex;
    }
  }

  .list {
    margin-left: 40px;

    li {
      width: max-content;
      height: 20px;
      list-style-type: none;
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;
