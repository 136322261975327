import React, { PureComponent } from "react";
import { ProjectBottomWrapper } from "../../styled";
import {
  BlueOutlinedBtn as BlueButton,
  BackBtn as Back,
  IssueBtn as Issue,
} from "../../../../common/button";
import history from "../../../../utils/history";
import { DownloadDropdown } from "common/dropdown/dropdown_with_icon";
import { ClaimContract } from "../../components/pageMenu";

function checkIfAllowIssue(
  claimType,
  autoEmails,
  toEmails,
  sendingEmail,
  exceedAttachLimit,
) {
  if (claimType === "approved") {
    if (autoEmails.get("is_invited_third_party") || toEmails.size !== 0) {
      return !sendingEmail && !exceedAttachLimit;
    }
  }
  return false;
}

class NavBarBottom extends PureComponent {
  redirect = (location) => {
    let link = "",
      { proId, contractType, contractId, claimId, accountId } = this.props;
    if (location === "overviewClaim") {
      let contractPageType = ClaimContract.includes(contractType)
        ? "claim"
        : "cert";
      // redirect to contract overview page
      link = `/contract/${proId}/${contractPageType}/${contractId}`;
    }

    if (location === "draftClaim") {
      // redirect to draft claim page
      link = `/project/${proId}/contract/${contractId}/${contractType}/claim/${claimId}`;
    }
    history.push({ pathname: link, search: `?account_id=${accountId}` });
  };
  render() {
    const {
      handleIssuePayclaim,
      status,
      type,
      handleRevertDraft,
      isSendEmail,
      handleResendEmail,
      autoSendEmails,
      emailToList,
      sendingEmail,
      options,
      contractType,
      readOnly,
      exceedAttachLimit,
      accountConfig,
      currentUserApproved,
    } = this.props;
    return (
      <ProjectBottomWrapper className="review">
        <BlueButton
          title="EXIT"
          onClick={() => this.redirect("overviewClaim")}
        />
        {/* allow to revert to draft function when claim is approved/issued or is not viewed by certifier */}
        <div className="nav-right">
          {(type === "approved" || status === "issued") &&
            contractType !== "cert" &&
            !readOnly && (
              <BlueButton
                onClick={handleRevertDraft}
                title="Revert to Draft Status"
                margin="0 30px 0 0"
              />
            )}
          {type === "draft" && (
            <Back
              label="BACK"
              float="left"
              onClick={() => this.redirect("draftClaim")}
              title="Back to make a claim page"
              margin="0 30px 0 0"
              disabled={!currentUserApproved}
            />
          )}
          {/* allow resend email function when claim is certified/issued or is not viewed by certifier */}
          {(status === "certified" || status === "issued") &&
            contractType !== "cert" &&
            !readOnly && (
              <Issue
                title="Resend Email"
                onClick={handleResendEmail}
                disabled={!isSendEmail || sendingEmail || exceedAttachLimit}
                color="blue"
                margin="0 30px 0 0"
                submitting={sendingEmail}
              />
            )}
          <DownloadDropdown
            options={options}
            isPreview={type === "draft" || type === "approved"}
          />
          {type === "approved" && !readOnly && (
            <>
              <Issue
                title={`ISSUE ${accountConfig?.getIn(["claim", "noun"])}`}
                onClick={handleIssuePayclaim}
                submitting={sendingEmail}
                margin={"0 0 0 30px"}
                disabled={
                  !checkIfAllowIssue(
                    type,
                    autoSendEmails,
                    emailToList,
                    sendingEmail,
                    sendingEmail,
                    exceedAttachLimit,
                  )
                }
              />
            </>
          )}
        </div>
      </ProjectBottomWrapper>
    );
  }
}

export default NavBarBottom;
