import React from "react";
import {
  ProjectNavList,
  ReviewWrapper,
  Note,
  OutLayerWrapper,
} from "../styled";
import Input from "../../../common/form";
import debounce from "lodash/debounce";

const Comment = ({ claimInfo, handleChangeNote, ifHide }) => {
  const debounceChange = debounce(
    (name, value) => handleChangeNote(name, value),
    400,
  );
  return (
    <>
      <ProjectNavList>Notes</ProjectNavList>
      <Note className={ifHide}>
        <Input
          field="textarea"
          label="Internal Notes"
          name="claim_internal_notes"
          className={!handleChangeNote ? `detail ${ifHide}` : "textarea"}
          defaultValue={claimInfo.get("claim_internal_notes") || ""}
          readOnly={!handleChangeNote}
          onChange={(e) => debounceChange(e.target.name, e.target.value)}
        />
        <Input
          field="textarea"
          wrapperClassName={"bottom"}
          className={!handleChangeNote ? `detail ${ifHide}` : "textarea"}
          label="External Notes"
          name="claim_external_notes"
          defaultValue={claimInfo.get("claim_external_notes") || ""}
          readOnly={!handleChangeNote}
          onChange={(e) => debounceChange(e.target.name, e.target.value)}
        />
      </Note>
    </>
  );
};

const Index = ({ claimInfo, isReadOnly, handleChangeNote, isReview }) => {
  let ifHide = isReadOnly ? "readonly" : "";
  return (
    <>
      {isReview ? (
        <ReviewWrapper className={ifHide}>
          <Comment
            claimInfo={claimInfo}
            handleChangeNote={handleChangeNote}
            ifHide={ifHide}
          />
        </ReviewWrapper>
      ) : (
        <OutLayerWrapper>
          <Comment
            claimInfo={claimInfo}
            handleChangeNote={handleChangeNote}
            ifHide={ifHide}
          />
        </OutLayerWrapper>
      )}
    </>
  );
};

export default Index;
