import React from "react";
import PropTypes from "prop-types";
import { AccountWrap } from "../../../project/styled";

import { AddBtn } from "../../../../common/button";

const accountTemplate =
  "### Edit the example below or add custom details. This line can be deleted ###\nAccount type: Bank account, Complying instrument\nBank name: KZB Bank\nBranch name: Paylabville\nAccount name: John Doe\nAccount number: 12-3456-7891011-12";

const AccountInfo = ({ data, updateRetentionAccount, errors, viewOnly }) => {
  let hasError = errors.includes(data.id) && !viewOnly;
  return (
    <>
      <AccountWrap hasError={hasError}>
        <textarea
          className="account-info"
          rows="6"
          wrap="hard"
          value={data.msg}
          onChange={(event) =>
            updateRetentionAccount("update", data.id, event.target.value)
          }
          readOnly={viewOnly}
        />
        {!viewOnly && (
          <div
            className="delete-icon"
            onClick={() => updateRetentionAccount("delete", data.id)}
          />
        )}
      </AccountWrap>
      {hasError && (
        <div className="error-info">Maximum number of lines reached.</div>
      )}
    </>
  );
};

function AccountDetail(props) {
  const { accounts, viewOnly, updateAccounts } = props;
  const [errors, setErrors] = React.useState([]);
  const addAccounts = () => {
    let newAccount = {
      id: Date.now(),
      msg: accountTemplate,
    };
    updateAccounts([...accounts, newAccount]);
  };

  const updateRetentionAccount = (actionType, id, value) => {
    let newAccounts = accounts;
    if (actionType === "update") {
      newAccounts = accounts.map((item) => {
        if (item.id === id) {
          const inputValue = value;
          const lines = inputValue.split("\n");
          // Limiting the number of rows to 6
          const maxRows = 6;
          if (lines.length <= maxRows) {
            item.msg = inputValue;
            if (errors.includes(id)) {
              const newErrors = [...errors];
              const index = newErrors.indexOf(id);
              newErrors.splice(index, 1);
              setErrors(newErrors);
            }
          } else {
            if (!errors.includes(id)) {
              setErrors([...errors, id]);
            }
            // Truncate the text to the first 6 lines
            item.msg = lines.slice(0, maxRows).join("\n");
          }
        }
        return item;
      });
    }
    if (actionType === "delete") {
      newAccounts = accounts.filter((item) => item.id !== id);
    }
    updateAccounts(newAccounts);
  };

  return (
    <>
      {viewOnly ? (
        <>
          <h2 className="has_margin">Account/Instrument Details</h2>
          {accounts.map((item) => (
            <AccountInfo key={item.id} data={item} viewOnly errors={errors} />
          ))}
        </>
      ) : (
        <>
          <h1 className="has_margin">Account/Instrument Details</h1>
          <div className="has_margin">
            Enter any account/instrument details in which Retention money is
            held. This will display in the Retention report PDF.
          </div>
          {accounts.map((item) => (
            <AccountInfo
              key={item.id}
              data={item}
              updateRetentionAccount={updateRetentionAccount}
              errors={errors}
            />
          ))}
          <AddBtn
            title="Add Account"
            margin="20px 0 32px 0"
            float="left"
            onClick={addAccounts}
          />
        </>
      )}
    </>
  );
}

AccountDetail.propTypes = {
  // retention accounts info
  accounts: PropTypes.array.isRequired,
  updateAccounts: PropTypes.func,
  viewOnly: PropTypes.bool,
};

export default AccountDetail;
