import React, { Fragment } from "react";
import { FileFrame } from "./styled";

import Dropzone from "../upload_file_display/dropzone";
import UploadingFiles from "../upload_file_progress/components/uploadFile";
import { bytesToSize } from "../../utils/helper";

const FileUpload = (props) => {
  const { title, files, setFileList, handleFilesChange, handleFileCancel } =
    props;
  //files, file list
  //setFileList update DB files list
  //handleFileCancel, delete uploaded file
  //handleFilesChange, upload local file to store
  return (
    <FileFrame className="vari">
      <div className="dropzone">
        <Dropzone
          uploadSection="lineItem"
          onFilesAdded={
            title.indexOf("Edit") !== -1 ? setFileList : handleFilesChange
          }
        />
      </div>
      <hr />
      <div className="uploadfiles">
        {title.indexOf("Edit") !== -1 ? (
          <Fragment>
            {files.map((item) => {
              return (
                <div className="filelist files" key={item.get("id")}>
                  <div className="uploadIcon" />
                  <div className="name small">{item.get("file_name")}</div>
                  <div className="size">
                    ({bytesToSize(item.get("file_size"))})
                  </div>
                  <div
                    className="action"
                    onClick={() =>
                      handleFileCancel(item.get("id") || item.get("file_id"))
                    }
                  />
                  <div className="status">Complete</div>
                </div>
              );
            })}
            <UploadingFiles
              uploadFile={(files, uploadingFileField) =>
                handleFilesChange(files, uploadingFileField)
              }
              width="446px"
            />
          </Fragment>
        ) : (
          files.map((item, index) => {
            return (
              <div className="filelist files" key={index}>
                <div className="attachIcon" />
                <div className="name small">{item.name}</div>
                <div className="size">({bytesToSize(item.size)})</div>
                <div
                  className="action"
                  onClick={() => handleFileCancel(item.name)}
                />
              </div>
            );
          })
        )}
      </div>
    </FileFrame>
  );
};

export default FileUpload;
