import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Link from "../../../../common/linkWithQuery";

import { BgWrapper, HeaderTitle } from "../../../../common/shared_style";
import {
  ContentWrapper,
  DetailBox,
  DetailTitle,
  AccountDetail,
  EmailInfo,
  DetailItem,
  ImgEdit,
} from "../../view/style";

import Input from "../../../../common/form";
import ProfileImg from "../../view/component/accountImg";

import { actionCreators } from "../../store";

const emailSettings = [
  { id: "workflow_required", label: "When I am required to approve" },
  { id: "workflow_optional", label: "When I am an optional approver" },
  { id: "has_due_alerts", label: " 3 and 7 days before items are due" },
];

const SettingItem = ({ setEmailNotice, id, label, margin, profile }) => {
  return (
    <div className={"item " + (margin ? "bottom" : "")}>
      <input
        type="checkbox"
        id={id}
        className="checkBox"
        // need the conditional here to prevent console log warning
        checked={profile.getIn(["email_preferences", id]) === true}
        onChange={(e) => setEmailNotice(e)}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

class Index extends PureComponent {
  render() {
    const { profile, config, accountInfo, setEmailNotice, setAlertNotice } =
      this.props;
    let summaryOption = [];
    if (config?.alert_summary) {
      summaryOption = Object.keys(config.alert_summary).map((item) => ({
        label: config.alert_summary[item],
        value: item,
      }));
    }
    summaryOption = summaryOption.filter((item) => item.value !== "none");
    summaryOption.unshift({ label: "Never", value: "none" });
    return (
      <BgWrapper>
        <HeaderTitle> Profile </HeaderTitle>
        <ContentWrapper>
          <DetailBox className="account">
            <ProfileImg
              image_type={profile.get("image_type")}
              image_64={profile.get("image_64")}
              hideForProfile={true}
            />
            <AccountDetail>
              <DetailTitle>{profile.get("name")}</DetailTitle>
              <DetailItem>
                <label>Role</label>
                <div>{profile.get("role")}</div>
              </DetailItem>
              <DetailItem>
                <label>Phone</label>
                <div>{profile.get("phone")}</div>
              </DetailItem>
              <DetailItem>
                <label>Email Address</label>
                <div>{profile.get("email")}</div>
              </DetailItem>
            </AccountDetail>

            <Link to="/manage-account/profile/edit">
              <ImgEdit />
            </Link>
          </DetailBox>
          <DetailBox>
            <DetailTitle className="email">Email Preferences</DetailTitle>
            <div>
              These apply for {accountInfo.get("accountName")} account. Switch
              accounts to for other accounts.
            </div>
            <EmailInfo>
              <div>
                <div className="email-alert-title">
                  <b>Receive Email Alerts</b>
                </div>
                {emailSettings.map((item, index) => (
                  <SettingItem
                    key={index}
                    profile={profile}
                    id={item.id}
                    setEmailNotice={setEmailNotice}
                    label={item.label}
                    margin={index !== 0}
                  />
                ))}
              </div>
              <div>
                <div className="email-summary-title">
                  <b>Receive Summary Emails</b>
                </div>
                <div className="item">
                  <Input
                    field="dropdown"
                    noRadius
                    value={profile.getIn([
                      "email_preferences",
                      "alert_summary",
                    ])}
                    onChange={(e) => setAlertNotice(e)}
                    options={summaryOption}
                  />
                </div>
              </div>
            </EmailInfo>
          </DetailBox>
        </ContentWrapper>
      </BgWrapper>
    );
  }
  componentDidMount() {
    this.props.getInitialInfo();
  }
}

const mapStateToProps = (state) => ({
  profile: state.getIn(["manageAccount", "profile"]),
  config: state.getIn(["headers", "config", "payclaim"]),
  accountInfo: state.getIn(["headers", "profile"]),
});

const mapDispatchToProps = (dispatch) => ({
  getInitialInfo() {
    dispatch(actionCreators.getProfileInfo());
  },
  setEmailNotice(e) {
    dispatch(actionCreators.setEmailNotice(e.target.id, e.target.checked));
  },
  setAlertNotice(e) {
    dispatch(actionCreators.setEmailNotice("alert_summary", e.value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
