import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PageLayout, Header, Main } from "./style";
import { SignUpBtn, BlueOutlinedBtn } from "../../common/button";
import { LogoWhite } from "../../common/file/getFilePath";

export default function Login() {
  const [error, setError] = useState(null);
  const [nextLocation, setNextLocation] = useState(null);
  let { search } = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    setError(searchParams.get("errors"));
    setNextLocation(searchParams.get("next"));
  }, [search]);

  const handleLogin = () => {
    if (error) {
      window.location = "/logout";
    } else {
      if (nextLocation) {
        window.location = `/auth/login?next=${nextLocation}`;
      } else {
        window.location = "/auth/login";
      }
    }
  };

  const handleSignUp = () => {
    if (nextLocation) {
      window.location = `/sign_up?next=${nextLocation}`;
    } else {
      window.location = `/sign_up`;
    }
  };

  return (
    <PageLayout>
      <div className="slogan">
        <div className="slate" />
        <div className="construction">
          Built by Construction People - for Construction People.
        </div>
      </div>
      <Header>
        <LogoWhite className="logo" />
      </Header>
      <Main>
        <div className="header">
          {" "}
          Welcome
          <br />
          to PayLab.
        </div>
        <div className="sub-header">
          Log In with <b>Microsoft</b> to continue.
        </div>
        <SignUpBtn
          title={!error ? "Log In" : "Logout and try again"}
          className="sign-up"
          onClick={handleLogin}
        />
        {error && (
          <div className="error-msg">
            <div className="error-img" />
            <div>
              <div className="error-title">Login Failed</div>
              <div>{error}</div>
            </div>
          </div>
        )}
        <hr />
        <BlueOutlinedBtn
          title="Create A New Account"
          className="get-start"
          onClick={handleSignUp}
        />
      </Main>
      <div className="pattern" />
    </PageLayout>
  );
}
