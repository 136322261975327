import { convertToCurrencyUnit } from "./currencyHelper";
import { DROP_DECIMAL_THRESHOLD } from "./numberConstants";

export const dropDecimalColumns = (tableData, columnNames) => {
  let decimalColumn = {};
  // initialise all as false
  for (let name of columnNames) {
    decimalColumn[name] = false;
  }
  if (tableData) {
    for (let line of tableData) {
      for (let name of columnNames) {
        if (dropDecimal(line[name])) {
          decimalColumn[name] = true;
        }
      }
    }
  }

  return decimalColumn;
};

export const dropDecimal = (num) => {
  return num >= DROP_DECIMAL_THRESHOLD || num <= -DROP_DECIMAL_THRESHOLD;
};

export const headerFormatter = (
  headerTitle = "",
  currencySymbol = "",
  enableHeaderCurrency = false,
) => {
  if (enableHeaderCurrency) {
    return `${headerTitle} (${currencySymbol})`;
  }
  return headerTitle;
};

export const calculateNetClaim = (
  id,
  claimList,
  claimed,
  dropDecimal,
  showSymbol,
) => {
  var value = 0;

  if (claimList && id) {
    if (Array.isArray(claimList)) {
      for (var i in claimList) {
        if (claimList[i].lineitem_id === id) {
          value = claimList[i].value;
          break;
        }
      }
    } else {
      value = claimList[id]?.value || 0;
    }
  }

  let result = "$0.00";
  let total = (value - claimed) / 100;
  result = convertToCurrencyUnit(total, showSymbol, dropDecimal);
  return result;
};
