import React from "react";
import { BlueWhiteBtn as OKbtn } from "../../button";
import { Icon } from "./dialogStyle";
import Modal from "./modalBase";

const ConfirmDialog = (props) => {
  return (
    <Modal {...props} handleClose={() => props.closeModal()} width="590px">
      <div
        style={
          props.textStyle
            ? props.textStyle
            : { padding: "33px 30px", lineHeight: "30px" }
        }
      >
        <Icon type={props.type} />
        {props.message}
      </div>
      <OKbtn
        onClick={() => {
          props.closeModal();
        }}
        title="OK"
        padding="10px 46px"
      />
    </Modal>
  );
};
export default ConfirmDialog;
