import React from "react";
import { Field, reduxForm, formValueSelector } from "redux-form/immutable";
import { connect } from "react-redux";

import { Comment, DetailBox, FormWrapper } from "../style";

import PhoneInput from "../../../../../../common/form/telephone";
import RenderSelectInput from "../../../../component/select_frame";
import NavBottom from "../../../../component/navBottom";
import RenderField from "../../../../component/inputField";
import validate from "./validate";

import {
  ADMIN_ROLE,
  NONE_ROLE,
  VIEW_ROLE,
} from "../../../../../../utils/constants";

const roleOption = [
  { label: "None", value: NONE_ROLE },
  { label: "Admin", value: ADMIN_ROLE },
  { label: "Viewer", value: VIEW_ROLE },
];

let Form = (props) => {
  const { handleSubmit, reset, editingUser, role_id, saving, accountConfig } =
    props;
  const claimWord = accountConfig?.getIn(["claim", "noun"])?.toLowerCase();
  const roleDescription = {
    admin:
      "Admin can manage all projects/contracts in this account. They can invite, add or remove other users",
    view: `Viewers can view all projects/contracts but cannot perform any actions on them e.g Invite people, certify ${claimWord}s...`,
    none: "No access to projects and contracts until added explicitly",
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormWrapper>
        <DetailBox>
          <div className="title">Add New User</div>
          <div className="comment">
            User will receive an email with a link to set their own password.
          </div>
          <div className="content">
            <Field
              name="name"
              type="text"
              component={RenderField}
              label="Full Name"
              isReadOnly={editingUser}
            />
            <Field
              name="email"
              type="email"
              component={RenderField}
              label="Email Address"
              isReadOnly={editingUser}
            />
            <div>
              <div className="label">Phone Number (Optional)</div>
              <Field
                name="phone"
                type="text"
                component={PhoneInput}
                readOnly={editingUser}
              />
            </div>
            <Field
              label="Account Level Access"
              name="role_id"
              className="SelectStyle"
              component={RenderSelectInput}
              width="446px"
              options={roleOption}
            />
            <Comment>{roleDescription[role_id]}</Comment>
          </div>
        </DetailBox>
        <NavBottom
          reset={reset}
          link="/manage-account/users"
          submitting={saving}
        />
      </FormWrapper>
    </form>
  );
};

Form = reduxForm({
  form: "MewUserDetailsForm",
  validate,
  enableReinitialize: true,
})(Form);

const selector = formValueSelector("MewUserDetailsForm");
Form = connect((state) => {
  const role_id = selector(state, "role_id");
  return {
    role_id,
  };
})(Form);

export default Form;
