import * as constants from "./constants";
import { fromJS } from "immutable";

const modifyFiles = (existingFiles, files) => {
  let fileToUpload = {};
  for (let i = 0; i < files.length; i++) {
    const id = existingFiles.size + i + 1;
    fileToUpload = {
      ...fileToUpload,
      [id]: {
        id,
        file: files[i],
        progress: 0,
      },
    };
  }
  return fileToUpload;
};

const defaultstate = fromJS({
  fileProgress: {},
  uploading: false,
  uploadingFileField: "",
});

export default (state = defaultstate, action) => {
  switch (action.type) {
    case constants.SET_UPLOAD_FILE:
      return state
        .set(
          "fileProgress",
          state
            .get("fileProgress")
            .merge(
              fromJS(modifyFiles(state.get("fileProgress"), action.payload)),
            ),
        )
        .set("uploading", true)
        .set("uploadingFileField", action.uploadingFileField);
    case constants.CLEAR_FILE_LIST:
      return defaultstate;
    case constants.SET_UPLOADING:
      return state.set(action.name, action.payload);
    case constants.SET_UPLOAD_PROGRESS:
      let fileProgress = state.get("fileProgress").toJS();
      fileProgress[action.payload.id].progress = action.payload.progress;
      return state.set("fileProgress", fromJS(fileProgress));
    case constants.SUCCESS_UPLOAD_FILE:
      let fileList = state.get("fileProgress").toJS();
      fileList[action.payload.id]["status"] = 1;
      return state.set("fileProgress", fromJS(fileList));
    case constants.FAILURE_UPLOAD_FILE:
      return state.merge({
        fileProgress: fromJS({
          ...state.get("fileProgress"),
          [action.payload]: {
            ...state.get("fileProgress").get(action.payload.id),
            status: 0,
            progress: 0,
          },
        }),
      });
    default:
      return state;
  }
};
