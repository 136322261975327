import styled from "styled-components";
import * as theme from "../../theme";
import help from "../../statics/img/icon-help-light-blue.svg";
import search from "../../statics/img/icon-add-medium-grey.svg";
import delFileIcon from "../../statics/img/icon-clear-medium-grey.svg";
import checkIcon from "../../statics/img/icon-check-box-check-dark-grey.svg";
import uncheckIcon from "../../statics/img/icon-check-box-un-check-medium-grey.svg";
import uncheckAllIcon from "../../statics/img/icon-check-box-indeterminate-dark-blue.svg";
import warningIcon from "../../statics/img/icon-information-red.svg";
//attachment section
export const Wrap = styled.div`
  margin-top: 40px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const WrapTitle = styled.div`
  width: 100%;
  line-height: 24px;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ListWrap = styled.div`
  margin-top: 20px;
  height: auto;
  border-bottom: 1px solid ${theme.palette.wolfGrey};
  width: 100%;
  padding-bottom: 10px;
  &.upS {
    margin-top: 28px;
  }
  &.readonly {
    color: ${theme.palette.wolfGrey};
  }
  .left {
    height: 31px;
    width: 56px;
    background-color: ${theme.palette.veryWarmLightGrey};
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    color: ${theme.palette.gunmetal};
    font-weight: ${theme.font.mainFontWeight};
    font-family: ${theme.font.mainFont};
    padding: 8px 0;
    box-sizing: border-box;
    margin-right: 20px;
    float: left;
    &.subject {
      font-size: 16px;
      background-color: ${theme.palette.white};
      border-radius: 4px;
    }
    &.readonly {
      color: ${theme.palette.wolfGrey};
    }
  }
  .center {
    float: left;
    font-size: 16px;
    line-height: 31px;
    height: auto;
    width: 950px;
    .inputEmail {
      border: none;
      height: 31px;
      width: 600px;
      color: ${theme.palette.gunmetal};
      font-weight: ${theme.font.normalFontWeight};
      font-family: ${theme.font.mainFont};
      font-size: 16px;
      &.readonly {
        color: ${theme.palette.wolfGrey};
      }
    }
  }
  .right {
    float: right;
    margin-left: 10px;
    height: 24px;
    width: 24px;
    background: url(${search}) no-repeat center;
    background-size: 24px;
    display: block;
    cursor: pointer;
  }
  .info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 657px;
    .message {
      width: 112px;
      float: left;
      font-size: 16px;
      font-weight: ${theme.font.normalFontWeight};
      line-height: 1.5;
    }
    .name {
      font-weight: ${theme.font.mainFontWeight};
    }
  }
  .imgS {
    margin-left: 4px;
    height: 24px;
    width: 24px;
    background: url(${help}) no-repeat center;
    background-size: 16px;
    display: block;
    cursor: pointer;
    float: left;
  }
`;

export const CloseIcon = styled.div`
  height: 24px;
  width: 24px;
  background: url(${delFileIcon}) no-repeat;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
`;
//email selection
export const SearchIcon = styled.div`
  float: right;
  margin-left: 10px;
  height: 24px;
  width: 24px;
  background: url(${search}) no-repeat center;
  background-size: 24px;
  display: block;
  cursor: pointer;
`;

//dialog content
export const SearchContainer = styled.div`
  width: 671px;
  height: 250px;
  border-top: 1px solid ${theme.palette.lightWolfGrey};
  padding: 30px 40px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.Or {
    border-top: 0;
    width: 671px;
    height: 25px;
    font-size: 20px;
    font-weight: ${theme.font.mainFontWeight};
    font-family: ${theme.font.mainFont};
    padding-left: 316px;
    padding-top: 0px;
  }
  &.Input {
    border-top: 0;
  }
  .child_container {
    height: 44px;
    width: 100%;
    .name {
      font-size: 16px;
      color: ${theme.palette.gunmetal};
      font-weight: ${theme.font.mainFontWeight};
      font-family: ${theme.font.mainFont};
      height: 19px;
      width: 128px;
      float: left;
    }
    .right {
      float: right;
    }
  }
  .option {
    font-size: 20px;
    color: ${theme.palette.gunmetal};
    font-weight: ${theme.font.mainFontWeight};
    font-family: ${theme.font.mainFont};
    margin-bottom: 16px;
  }
  .inputStyle {
    box-sizing: border-box;
    width: 446px;
    height: 44px;
    font-size: 16px;
    font-family: ${theme.font.mainFont};
    color: ${theme.palette.gunmetal};
    background-color: ${theme.palette.white};
    border: 1px solid ${theme.palette.wolfGrey};
    padding: 16px;
  }
`;
export const BottomWrap = styled.div`
  width: 671px;
  height: 80px;
  border-top: 1px solid ${theme.palette.lightWolfGrey};
  padding: 20px 40px;
  box-sizing: border-box;
`;

export const SwitchContainer = styled.div`
  height: 24px;
  width: 265px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .label {
    font-size: 14px;
    line-height: 24px;
    &.unchecked {
      color: ${theme.palette.wolfGrey};
    }
  }
`;

export const AttachFileWrap = styled(Wrap)`
  margin-top: 56px;
  .file-title {
    font-weight: ${theme.font.mainFontWeight};
    font-size: 20px;
    margin-bottom: 8px;
  }
  .comment {
    font-weight: ${theme.font.normalFontWeight};
    font-size: 12px;
  }
  label {
    font-weight: ${theme.font.mainFontWeight};
    &.comment {
      font-style: italic;
      font-weight: ${theme.font.normalFontWeight};
    }
  }
  .file_wrap {
    margin: 24px 0 20px;
    width: 608px;
    padding: 6px 16px;
    border: solid 1px ${theme.palette.wolfGrey};
  }
  ul {
    li {
      min-height: 48px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid ${theme.palette.wolfGrey};
      font-size: 14px;
      :last-child {
        border: none;
      }
      .checkbox {
        -webkit-appearance: none;
        height: 24px;
        width: 24px;
        background: url(${uncheckIcon}) no-repeat;
        background-size: contain;
        cursor: pointer;
        margin-right: 10px;
        &.all {
          background: url(${uncheckAllIcon}) no-repeat;
        }
        :checked {
          background: url(${checkIcon}) no-repeat;
        }
      }
      label {
        font-weight: ${theme.font.mainFontWeight};
        max-width: 270px;
        padding: 6px 0;
        &.unselected {
          color: ${theme.palette.veryMediumGrey};
        }
      }
      .size {
        margin-left: 8px;
        color: ${theme.palette.veryMediumGrey};
      }
    }
  }
  .select-wrap {
    margin-left: auto;
  }
`;

export const AutoServiceWrap = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  label {
    margin: 16px 0;
  }
  .email_list {
    display: flex;
    flex-wrap: wrap;
    .emailWrap {
      width: fit-content;
      height: 32px;
      margin: 5px 24px 5px 0px;
      text-align: center;
      border-radius: 14px;
      background-color: ${theme.palette.veryColdGrey};
      padding: 8px 24px;
    }
  }
`;

export const WarningWrap = styled.div`
  width: 100%;
  height: 56px;
  border-radius: 4px;
  background-color: ${theme.palette.lightPastelPink};
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  &.attachment {
    margin: 8px 0;
  }
  .warningIcon {
    width: 24px;
    height: 24px;
    background: url(${warningIcon});
    object-fit: contain;
    margin: 0 16px;
  }
`;

export const WarningIcon = styled.div`
  width: 24px;
  height: 24px;
  background: url(${warningIcon});
  object-fit: contain;
  margin: 0 12px;
  margin-bottom: 4px;
`;
