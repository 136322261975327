import React, { Fragment } from "react";
import { ApproveStep, BadgeWrap, StepRow, CheckboxWrapper } from "../styled";

export function ApprovalSteps(props) {
  const { selectedStep, onClick, data, accountConfig } = props;
  return (
    <>
      {data.map((item, index) => {
        let isSelected = selectedStep === item.id,
          isIssueStep = index === data.length - 1;
        let label = item.label.includes("Claim")
          ? item.label.replace("Claim", accountConfig?.getIn(["claim", "noun"]))
          : item.label;
        return (
          <Fragment key={item.id}>
            <ApproveStep>
              {index !== 0 && (
                <StepRow
                  className={!item.completed && !item.active ? "grey-dot" : ""}
                />
              )}
              <BadgeWrap
                onClick={(e) => onClick(item.id, e)}
                name={item.id}
                isSelected={isSelected}
              >
                {!isIssueStep && (
                  <div
                    className={
                      item.completed
                        ? "step_item completed"
                        : "step_item notStart"
                    }
                  />
                )}
                {isIssueStep && (
                  <div
                    className={
                      item.completed
                        ? "step_item completeIssue"
                        : "step_item notIssue"
                    }
                  />
                )}
                <div className={isSelected ? "label" : "label grey"}>
                  {label}
                </div>
              </BadgeWrap>
            </ApproveStep>
          </Fragment>
        );
      })}
    </>
  );
}

export function AuditCheckbox({ checked, setPdfAudit }) {
  return (
    <CheckboxWrapper>
      <input
        type="checkbox"
        id="attach_audit"
        checked={checked}
        onChange={setPdfAudit}
        className="checkBox"
      />
      <label htmlFor="attach_audit">Attach history to PDF</label>
    </CheckboxWrapper>
  );
}
