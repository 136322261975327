import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { ApprovedTableWrapper } from "./styled";
import CommentList from "./comment_table";
import TableComponent from "./basic_table";

import { convertToPercent, roundNearest } from "../../utils/numeralHelper";
import { countComments } from "./table_utils";
import {
  convertToCurrencyUnit,
  currencySubunitToUnit,
} from "../../utils/currencyHelper";
import {
  dropDecimalColumns,
  headerFormatter,
} from "../../utils/tableFormatHelper";
import { LUMP } from "../../utils/constants";
import {
  ProvisionalDescription,
  getExpandProvisionalParents,
} from "../lineitem/sharedComponent";

class Table extends Component {
  constructor() {
    super();
    this.state = {
      expands: [],
    };
  }

  isTreeExpanded = (id) => {
    return this.state.expands.includes(id);
  };

  expandProvisionalParent = (line) => {
    let expanded = getExpandProvisionalParents(line, this.state.expands);
    this.setState({ expands: expanded });
  };

  render() {
    const {
      title,
      tableContent,
      claimTotal,
      totalTable,
      approvedTotal,
      updateComment,
      accountConfig,
      isClaim, // bool that checks if it's a claim
      claimInfo,
    } = this.props;
    const { expands } = this.state;
    const Approved = (
      <span className="approvedHeader">
        {headerFormatter(
          `Certified %`,
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        )}
      </span>
    );
    let dropDecimal = dropDecimalColumns(tableContent.toJS(), [
      "quantity",
      "rate",
      "total",
      "claim_value",
      "approved_total",
    ]);
    const showCurrency = !accountConfig?.get("enable_header_currency");

    const columns_dev = [
      {
        title: "Ref",
        className: "align_left lineitem-ref",
        render: "label",
        width: 78,
      },
      {
        title: "Description",
        className: "align_left",
        width: 235,
        treeColumnsName: "childitems",
        render: (d) => (
          <div>
            <ProvisionalDescription
              data={d}
              isUpdateExpanded={() => {
                return false;
              }}
              isTreeExpanded={this.isTreeExpanded}
              expandProvisionalParent={this.expandProvisionalParent}
            />
          </div>
        ),
      },
      {
        title: "Qty",
        width: 90,
        className: "th_align_left",
        render: (d) => {
          if (d.lineitem_options === LUMP || d.childitems?.length > 0) {
            return <div className="grey-dash"></div>;
          } else {
            return convertToCurrencyUnit(
              dropDecimal["quantity"] ? roundNearest(d.quantity) : d.quantity,
              false,
            );
          }
        },
      },
      {
        title: "Unit",
        width: 68,
        className: "th_align_left",
        render: (d) => {
          if (d.lineitem_options === LUMP || d.childitems?.length > 0) {
            return <div className="grey-dash"></div>;
          } else {
            return d.unit;
          }
        },
      },
      {
        title: headerFormatter(
          "Rate",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        width: 107,
        className: "th_align_left",
        render: (d) => {
          if (d.lineitem_options === LUMP || d.childitems?.length > 0) {
            return <div className="grey-dash"></div>;
          } else {
            return currencySubunitToUnit(
              dropDecimal["rate"] ? roundNearest(d.rate) : d.rate,
              showCurrency,
            );
          }
        },
      },
      {
        title: headerFormatter(
          "Total",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        className: "th_align_left",
        width: 138,
        render: (d) =>
          currencySubunitToUnit(
            dropDecimal["total"] ? roundNearest(d.total, true) : d.total,
            showCurrency,
          ),
      },
      {
        title: `${accountConfig?.getIn(["claim", "value"])} Qty | %`,
        className: "th_align_left",
        width: 159,
        render: (d) => {
          return (
            <div className="percent_qty">
              <div>
                {d.lineitem_options === LUMP || d.childitems?.length > 0 ? (
                  <div className="grey-dash"></div>
                ) : (
                  d.claim_qty
                )}
              </div>
              <div>
                {d.claim_percent
                  ? convertToPercent(d.claim_percent / 100)
                  : convertToPercent(0)}
              </div>
            </div>
          );
        },
      },
      {
        title: headerFormatter(
          `${accountConfig?.getIn(["claim", "value"])} Total`,
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        width: 137,
        className: accountConfig?.get("enable_header_currency")
          ? "th_align_center"
          : "th_align_left",
        render: (d) =>
          currencySubunitToUnit(
            dropDecimal["claim_value"]
              ? roundNearest(d.claim_value, true)
              : d.claim_value,
            showCurrency,
            dropDecimal["claim_value"],
          ),
      },
      {
        className: "hidden td_align_center",
        render: (d) => convertToPercent(d.approved_percent / 100),
        width: 70,
        group: Approved,
      },
      {
        className: "hidden",
        render: (d) =>
          currencySubunitToUnit(
            dropDecimal["approved_total"]
              ? roundNearest(d.approved_total, true)
              : d.approved_total,
            showCurrency,
            dropDecimal["approved_total"],
          ),
        width: 138,
        group: Approved,
      },
      {
        className: "hidden approveComment",
        width: 40,
        render: (d) => (
          <div className="inlineDiv">
            {countComments(d, d?.id) > 0 && (
              <CommentList
                data={d}
                expands={expands}
                onTreeExpand={(key) => {
                  return this.setState({
                    expands: [...this.state.expands, key],
                  });
                }}
                updateComment={updateComment}
                type={"claim"}
                viewOnly={true}
                otherParty={
                  isClaim
                    ? claimInfo.get("payer_entity_name")
                    : claimInfo.get("payee_entity_name")
                }
              />
            )}
          </div>
        ),
        group: Approved,
      },
    ];

    return (
      <ApprovedTableWrapper>
        <TableComponent
          onTreeExpand={(keys) => this.setState({ expands: keys })}
          treeExpandKeys={expands}
          tableContent={tableContent.toJS()}
          tableColumns={columns_dev}
          tableCaption={title}
          hasSort
          externalSortOptions={{
            claim_percent: `${accountConfig?.getIn(["claim", "value"])} %`,
            approved_percent: "Approved %",
          }}
          hasEnhancedTableHeader
          hasEnhancedTableFooter
        >
          <table className="header_table">
            <tbody>
              <tr>
                <td style={{ width: 716 }} />
                <td className="label-claim">
                  {accountConfig?.getIn(["claim", "noun"])}
                </td>
                <td className="label-certificate">
                  {accountConfig?.getIn(["cert", "noun"])}
                </td>
                <td style={{ width: 158 }} />
              </tr>
            </tbody>
          </table>
          <table>
            <tfoot>
              <tr>
                <td className="blank first" />
                <td className="title">Sub Total</td>
                <td className="total">
                  {convertToCurrencyUnit(totalTable, showCurrency)}
                </td>
                <td className="claimed">
                  {convertToCurrencyUnit(claimTotal, showCurrency)}
                </td>
                <td className="approve">
                  {convertToCurrencyUnit(approvedTotal, showCurrency)}
                </td>
                <td className="blank last" />
              </tr>
            </tfoot>
          </table>
        </TableComponent>
      </ApprovedTableWrapper>
    );
  }
}

export default withRouter(Table);
