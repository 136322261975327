import { calculateSubunitToUnit } from "utils/currencyHelper";

export const calculateTotalAndGst = (data, gst) => {
  const parseValue = (value) => parseFloat(value ?? 0);

  let total =
    parseValue(data.gross) +
    parseValue(data.retention_dlrr) +
    parseValue(data.retention_pcrr) -
    parseValue(data.retention);

  let gstTotal = total * calculateSubunitToUnit(gst);
  return [total, gstTotal];
};
