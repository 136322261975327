import React from "react";

export const httpCode = {
  // 400: "Request parameter error",
  // 401: "Unsupport permissions, please log in again",
  //403: "The server denied this access!",
  404: "Can not find the resource!",
  500: "Server error!",
  501: "The server does not support the method used in the request!",
  502: "Gateway error!",
  504: "Gateway timeout!",
};

// helper for processing error messages
export const processMessage = (message) => {
  // check if message has multiple lines by looking for '\n'
  if (message && message[0].indexOf("\n") !== -1) {
    let processed = message[0].split("\n");
    return (
      <>
        {processed.map((msg, i) => (
          <p key={i}>{msg}</p>
        ))}
      </>
    );
  }
  return message;
};
