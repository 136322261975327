import { fromJS } from "immutable";
import * as constants from "./constants";

const defaultState = fromJS({
  loading: true,
  allProjects: [],
  layout: "list",
  sortListField: "total_contract_value",
  search: "",
  filterOption: {},
  filteredProjects: [],
});

const sumTotal = (list, key, field) => {
  return list[key].reduce((total, obj) => obj[field] + total, 0);
};

const getSearchTags = (list, otherParty) => {
  let searchTags = [];
  // take contract name, number and other party name into searchTags
  list.forEach((c) => {
    if (c[`${otherParty}_company`]?.entity_name)
      searchTags.push(c[`${otherParty}_company`]?.entity_name);
    if (c.contract_number) searchTags.push(c.contract_number);
    if (c.name) searchTags.push(c.name);
  });
  return searchTags;
};

export const setProjectTable = (state, action) => {
  let projects = action.payload,
    table = [];
  projects.map((project) => {
    let claim_total = sumTotal(
        project,
        "claim_contracts",
        "total_contract_value",
      ),
      claim_total_claimed = sumTotal(
        project,
        "claim_contracts",
        "claimed_to_date_value",
      ),
      claim_total_certed = sumTotal(
        project,
        "claim_contracts",
        "cert_to_date_value",
      ),
      claim_total_number = project["claim_contracts"].length;
    let cert_total = sumTotal(
        project,
        "cert_contracts",
        "total_contract_value",
      ),
      cert_total_claimed = sumTotal(
        project,
        "cert_contracts",
        "claimed_to_date_value",
      ),
      cert_total_certed = sumTotal(
        project,
        "cert_contracts",
        "cert_to_date_value",
      ),
      cert_total_number = project["cert_contracts"].length;

    let claimSearchTags = getSearchTags(project.claim_contracts, "payer"),
      certSearchTags = getSearchTags(project.cert_contracts, "payee");
    let ele = {
      ...project,
      project: {
        contract_number: claim_total_number + cert_total_number,
        claimed_to_date_value: claim_total_claimed + cert_total_claimed,
        cert_to_date_value: claim_total_certed + cert_total_certed,
        total_contract_value: claim_total + cert_total,
      },
      claim_contracts: {
        contract_number: claim_total_number,
        claimed_to_date_value: claim_total_claimed,
        cert_to_date_value: claim_total_certed,
        total_contract_value: claim_total,
        contract_id: project.claim_contracts[0]?.id,
        contract_search_tags: claimSearchTags,
      },
      cert_contracts: {
        contract_number: cert_total_number,
        claimed_to_date_value: cert_total_claimed,
        cert_to_date_value: cert_total_certed,
        total_contract_value: cert_total,
        contract_search_tags: certSearchTags,
      },
    };

    return table.push(ele);
  });
  return state.merge(
    fromJS({
      loading: false,
      allProjects: table || [],
      filteredProjects: table || [],
    }),
  );
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SET_PROJECT_LIST:
      return setProjectTable(state, action);
    case constants.SET_DATA:
      return state.set(action.name, action.value);
    case constants.RESET:
      return state.set("search", "");
    default:
      return state;
  }
};
