import React from "react";

import { UploadCSV } from "../style";

import { BlueWhiteBtn as ImportButton } from "../../../common/button";
import ImportCSV from "../../../common/modal/component/modalBase";
import { FileUpload } from "../../../common/lineitem/csvUpload";

const title = {
  company:
    " The following import is for bulk uploading multiple Payer Details and Contacts.",
  payer:
    "The following import is for bulk uploading multiple Payer Contacts to the selected company.",
};

const column = {
  company: [
    "Company Name",
    "Company Street Address",
    "Company Suburb",
    "Company City",
    "Company Postal Code",
    "Company Entity Type",
    "Company NZ Business Number",
    "Payer Name",
    "Payer Email",
    "Payer Phone",
  ],
  payer: ["Payer Name", "Payer Email", "Payer Phone"],
};

const note = {
  company: (
    <div className="note">
      <b>Note:</b>
      <br />
      <b>•</b> Two contacts from one company can have the same name but be
      differentiated by their email address.
      <br />
      <br />
      <b>•</b> If multiple contacts are being created for one company, certain
      details can be inputted once for the first contact -{" "}
      <b>
        Company Street Address, Company Suburb, Company City, Company Postal
        Code, Company Entity Type, Company NZ Business Number
      </b>
      .
    </div>
  ),
  payer: (
    <div className="note">
      <b>Note:</b> Two contacts from one company can have the same name but be
      differentiated by their email address.
    </div>
  ),
};

const Content = (props) => (
  <UploadCSV>
    <div className="header">{title[props.type]}</div>
    <FileUpload {...props} />
    <div
      className={
        "description " + (props.type === "payer" ? "custom_height" : "")
      }
    >
      <div className="item">
        The format of your Excel file is important.
        <br />
        Please arrange your Excel file to match the order of the headers shown
        below. You can also{" "}
        <a
          className="link"
          href={process.env.PUBLIC_URL + "/template/" + props.templateLink}
          download={props.templateLink}
        >
          download a template
        </a>{" "}
        here for reference.
      </div>
      <div className="item">
        <table>
          <thead>
            <tr>
              {column[props.type].map((item) => (
                <th key={item}>{item}</th>
              ))}
            </tr>
          </thead>
        </table>
      </div>
      {note[props.type]}
    </div>
  </UploadCSV>
);

export default function UploadDialog(props) {
  const {
    filelist,
    importCSV,
    uploadFile,
    clearUpload,
    open,
    handleOpen,
    handleClose,
    showAlert,
    type,
  } = props;
  //type: company/payer
  const templateLink =
    type === "payer"
      ? "upload_payer_detail_template.xlsx"
      : "upload_company_detail_template.xlsx";
  return (
    <ImportCSV
      title={"Import from Excel"}
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
    >
      <Content
        uploadFile={uploadFile}
        fileList={filelist.toJS()}
        clearUpload={clearUpload}
        alertBox={showAlert}
        type={type}
        templateLink={templateLink}
      />
      <ImportButton
        autoFocus
        onClick={importCSV}
        title="import"
        disabled={filelist.size === 0}
      />
    </ImportCSV>
  );
}
