import React, { Component } from "react";
import { SwitchContainer } from "./styled";
import { connect } from "react-redux";
import { actionCreators } from "../header/store";
import { AntSwitch } from "./index";

class CustomizedSwitches extends Component {
  render() {
    return (
      <SwitchContainer>
        <div className="label">GST Inclusive</div>
        <AntSwitch
          checked={this.props.is_gst_include}
          onChange={(event) => {
            this.props.setGst(event);
            this.props.apply();
          }}
          name="is_gst_include"
        />
        <div
          className={this.props.is_gst_include ? "label" : "label unchecked"}
        >
          {this.props.is_gst_include ? "On" : "OFF"}
        </div>
      </SwitchContainer>
    );
  }
}
const mapStateToProps = (state) => ({
  is_gst_include: state.getIn(["headers", "is_gst_include"]),
});

const mapDispatch = (dispatch) => {
  return {
    setGst(event) {
      dispatch(actionCreators.setData("is_gst_include", event.target.checked));
    },
  };
};

export default connect(mapStateToProps, mapDispatch)(CustomizedSwitches);
