import React, { useState } from "react";
import { ActionsWrap, ActionIcon, ModalContent } from "../style";

import {
  ADMIN_ROLE,
  NONE_ROLE,
  OWNER_ROLE,
  VIEW_ROLE,
} from "../../../../../utils/constants";
import Modal from "../../../../../common/modal/component/modalBase";
import {
  GreenWhiteBtn as ConfirmBtn,
  GreyOutlinedBtn as CancelBtn,
  RedBtn as DeactivateBtn,
} from "../../../../../common/button";

const WarningContent = {
  none: {
    title: "Remove Your Account Access",
    content:
      "You are currently an Admin. If you remove your account access, you will not be able to manage users anymore. Another admin is needed to change your role.",
  },
  view: {
    title: "Change Yourself to Viewer",
    content:
      "You are currently an Admin. If you change your role to viewer, you will not be able to manage users anymore. Another admin is needed to change your role.",
  },
  deactivate: {
    title: "Deactivate Your Account",
    content:
      "You will no longer be able to access Paylab. Another admin is needed to reactivate your account.",
  },
};

export default function UserActions(props) {
  const {
    show,
    item,
    profile,
    sendingEmail,
    handleUpdateUser,
    handleResendEmail,
    handleDeleteUser,
    handleActivateUser,
    requestingOwner,
  } = props;

  const [warning, setWarning] = useState(false);
  const [warningType, setWarningType] = useState("view");

  let sendingUserEmail =
    item.get("id") === sendingEmail.get("id") && sendingEmail.get("sending");
  let isUserSelf = item.get("email") === profile.get("email");

  const ownerActions = [
    { icon: "mailIcon", label: "Resend Invite", action: handleResendEmail },
    {
      icon: "avatarIcon",
      label: "Change to Admin",
      comment: "Assign another owner first.",
      disabled: true,
    },
    {
      icon: "avatarIcon",
      label: "Change to Viewer",
      comment: "Assign another owner first.",
      disabled: true,
    },
    {
      icon: "avatarIcon",
      label: "Remove Account Access",
      comment: "Assign another owner first.",
      disabled: true,
    },
    {
      icon: "deActiveIcon",
      label: "Deactivate User",
      comment: "Cannot deactivate owner. Assign another owner first.",
      disabled: true,
    },
  ];

  const activeActions = [
    { icon: "mailIcon", label: "Resend Invite", action: handleResendEmail },
    {
      icon: "avatarIcon",
      label: "Change to Owner",
      comment: "Manage billing and access. Manage projects and contracts.",
      action: (item) => handleUpdateUser(item, "owner"),
      disabled: item.get("role_id") === OWNER_ROLE || requestingOwner,
      hide: item.get("role_id") !== ADMIN_ROLE,
    },
    {
      icon: "avatarIcon",
      label: "Change to Admin",
      comment: "Manage projects and contracts.",
      action: (item) => handleUpdateUser(item, "admin"),
      disabled: item.get("role_id") === OWNER_ROLE,
      hide: item.get("role_id") === ADMIN_ROLE,
    },
    {
      icon: "avatarIcon",
      label: "Change to Viewer",
      comment: "View projects and contracts but not edit.",
      action: (item) => {
        if (isUserSelf) {
          setWarning(true);
          setWarningType("view");
        } else {
          handleUpdateUser(item, "view");
        }
      },
      disabled: item.get("role_id") === OWNER_ROLE,
      hide: item.get("role_id") === VIEW_ROLE,
    },
    {
      icon: "avatarIcon",
      label: "Remove Account Access",
      comment: "No access to projects and contracts until added explicitly",
      action: (item) => {
        if (isUserSelf) {
          setWarning(true);
          setWarningType("none");
        } else {
          handleUpdateUser(item, "none");
        }
      },
      disabled: item.get("role_id") === OWNER_ROLE,
      hide: item.get("role_id") === NONE_ROLE,
    },
    {
      icon: "deActiveIcon",
      label: "Deactivate User",
      action: (item) => {
        if (isUserSelf) {
          setWarning(true);
          setWarningType("deactivate");
        } else {
          handleDeleteUser(item);
        }
      },
      disabled: item.get("role_id") === OWNER_ROLE,
    },
  ];

  const inactiveActions = [
    { icon: "activeIcon", label: "Reactive User", action: handleActivateUser },
  ];

  const ActionItem = ({ action }) => {
    if (action.hide) {
      return null;
    }
    return (
      <div
        className={"actionItemWrap " + (action.disabled ? " disabled" : "")}
        onClick={() => action.action && action.action(item)}
      >
        <ActionIcon
          className={action.icon + (action.disabled ? " disabled" : "")}
          animating={sendingUserEmail}
          disabled={sendingUserEmail}
        />
        <div className={action.disabled ? "disabled-font" : ""}>
          <div>{action.label}</div>
          <div className="comment">{action.comment}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <ActionsWrap show={show} key={item.get("id")}>
        {item.get("status") === "active"
          ? item.get("role_id") === OWNER_ROLE
            ? ownerActions.map((action) => (
                <ActionItem key={action.label} action={action} />
              ))
            : activeActions.map((action) => (
                <ActionItem key={action.label} action={action} />
              ))
          : inactiveActions.map((action) => (
              <ActionItem key={action.label} action={action} />
            ))}
      </ActionsWrap>
      <Modal
        title={WarningContent[warningType]["title"]}
        width="640px"
        open={warning}
        divider={false}
        handleClose={() => setWarning(false)}
      >
        <ModalContent className="warning">
          {WarningContent[warningType]["content"]}
          <br />
          <br />
          Are you sure you want to do this?
        </ModalContent>
        <div>
          {warningType === "deactivate" ? (
            <DeactivateBtn
              onClick={() => {
                setWarning(false);
                handleDeleteUser(item, true);
              }}
              title="Deactivate"
              padding="10px 34px"
            />
          ) : (
            <ConfirmBtn
              onClick={() => {
                setWarning(false);
                handleUpdateUser(item, warningType, true);
              }}
              title={warningType === "none" ? "Remove Access" : "Change Role"}
              padding="10px 34px"
            />
          )}
          <CancelBtn
            onClick={() => setWarning(false)}
            title="Cancel"
            margin="0 20px 0 0"
            padding="10px 34px"
          />
        </div>
      </Modal>
    </>
  );
}
