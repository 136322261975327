import React, { useState } from "react";
import {
  ProjectDetailWrapper,
  ProjectContentWrapper,
  WelcomeWrapper,
  ProjectStatusTag,
  ActionsWrap,
  IconLink,
} from "../styled";
import {
  BlueWhiteBtn as ButtonDark,
  DownloadBtn as ButtonYellow,
  RedOutlinedBtn as DeleteButton,
} from "../../../common/button";
import BootstrapTooltip from "../../../common/toolTip";
import Map from "../../../common/mapbox/map";
import Link from "../../../common/linkWithQuery";
import { STATUS_ARCHIVE } from "../../../utils/constants";
import ProjectSubsetBadge from "../../projects/components/projectSubsetBadge";
import MapboxDisplay from "../../../common/mapbox/mapboxDisplay";
import { ClickAwayListener } from "@material-ui/core";
import { Img } from "../../../common/button/style";
import downloadIcon from "../../../statics/img/download-arrow.svg";
import { LinkTooltip } from "./integration";

const ProjectSummary = (props) => {
  const [edit, setEdit] = useState(false);
  const {
    projectInfo,
    welcomeInfoSeen,
    hideWelcomeInfo,
    allowEditProject,
    changeState,
    hideMap,
    downloadProjectSummary,
    accountConfig,
  } = props;

  const disableDelete = () =>
    projectInfo.get("claim_contracts")?.size > 0 ||
    projectInfo.get("cert_contracts")?.size > 0;

  const disableArchive = () => {
    let contract = projectInfo
      .get("claim_contracts")
      .filter((c) => c.get("status") !== STATUS_ARCHIVE);
    let subContract = projectInfo
      .get("cert_contracts")
      .filter((c) => c.get("status") !== STATUS_ARCHIVE);
    return contract.size > 0 || subContract.size > 0;
  };

  let project = {
    id: projectInfo.get("id"),
    name: projectInfo.get("name"),
    number: projectInfo.get("project_number"),
    description: projectInfo.get("description"),
    vaultURL: projectInfo.get("vault_url"),
    avatarImage: projectInfo.get("avatar_url"),
    isNotCovered: projectInfo.get("is_not_covered"),
  };

  var isActive = projectInfo?.get("status") === "active";

  var status = isActive
    ? projectInfo.get("percent_done").toFixed(2) + "%"
    : projectInfo.get("status");

  return (
    <ProjectDetailWrapper id={"nav-projectInfo"}>
      <ProjectContentWrapper projectImage={project.avatarImage}>
        <div className="project-image" />
        <div className="content">
          <div className="title-container">
            <div className="title-label-container">
              <div className="title">{project.name}</div>
              {project.isNotCovered && (
                <ProjectSubsetBadge className="subset-badge" />
              )}
              {projectInfo.get("integration") && (
                <LinkTooltip from="Project">
                  <IconLink />
                </LinkTooltip>
              )}
            </div>
            <ProjectStatusTag>{status}</ProjectStatusTag>
          </div>
          {project.number && <div className="number">{project.number}</div>}
          {project.description && (
            <div className="des">{project.description}</div>
          )}
          {project.vaultURL && (
            <div
              className="vault-button"
              onClick={() => {
                window.open(project.vaultURL);
              }}
            >
              <div className="logo" />
              View in Vault
            </div>
          )}
          <div
            className={`button-wrap ${!project.vaultURL ? "has-margin" : ""}`}
          >
            <div className="left-buttons">
              {isActive && allowEditProject && (
                <>
                  <BootstrapTooltip
                    title="All contracts must be archived or deleted before this project can be archived."
                    placement="bottom"
                  >
                    <div className="archive-button">
                      <ButtonDark
                        label={"archive project"}
                        float="left"
                        margin="0 12px 0 0"
                        onClick={() => changeState(project.id, "archive")}
                        disabled={disableArchive()}
                      />
                    </div>
                  </BootstrapTooltip>
                </>
              )}
              {projectInfo &&
                projectInfo.get("status") === "archived" &&
                allowEditProject && (
                  <>
                    <ButtonDark
                      title={"reopen project"}
                      float="left"
                      margin="0 12px 0 0"
                      onClick={() => changeState(project.id, "re-open")}
                    />
                  </>
                )}
              {allowEditProject && (
                <Link to={`/project/view/${project.id}/settings`}>
                  <div
                    className="setIcon"
                    onClick={() =>
                      hideWelcomeInfo && hideWelcomeInfo(project.id)
                    }
                  />
                </Link>
              )}
              {isActive && !hideMap && (
                <ClickAwayListener
                  onClickAway={() => {
                    setEdit(false);
                  }}
                >
                  <div>
                    <div
                      className="moreOption"
                      onClick={() => {
                        setEdit(!edit);
                      }}
                    ></div>
                    <ActionsWrap show={edit}>
                      <div
                        className="action"
                        onClick={() => downloadProjectSummary(project.id)}
                      >
                        <Img
                          name={downloadIcon}
                          margin="0 10px 0 0"
                          float="left"
                          height="17px"
                          width="14px"
                        />
                        Export {accountConfig?.getIn(["claim", "noun"])}s
                        Summary
                      </div>
                    </ActionsWrap>
                  </div>
                </ClickAwayListener>
              )}
            </div>
            {isActive && allowEditProject && (
              <BootstrapTooltip
                title="All contracts must be deleted before this project can be deleted."
                placement="bottom"
              >
                <div className="delete-button">
                  <DeleteButton
                    label={"delete project"}
                    onClick={() => changeState(project.id, "delete")}
                    disabled={disableDelete()}
                  />
                </div>
              </BootstrapTooltip>
            )}
          </div>
        </div>
        {!welcomeInfoSeen && allowEditProject && (
          <WelcomeWrapper>
            <div className="arrow" />
            <div>
              <div className="title">Welcome!</div>
              <div className="cheer-img" />
            </div>
            <div>
              <label>
                This is your new project. Add your contract below to start your
                project. You can also add details to the <b>Project Settings</b>{" "}
                to make adding new contracts quicker.
              </label>
              <ButtonYellow
                title="Got it"
                onClick={() => hideWelcomeInfo(project.id)}
              />
            </div>
          </WelcomeWrapper>
        )}
      </ProjectContentWrapper>

      {!hideMap && (
        <>
          <MapboxDisplay>
            <hr />
            <Map
              placeName={
                !projectInfo.get("street_address")
                  ? ""
                  : `${projectInfo.get("street_address")},${projectInfo.get(
                      "suburb",
                    )},${projectInfo.get("city")},${projectInfo.get(
                      "postcode",
                    )},${projectInfo.get("state")},${projectInfo.get(
                      "country",
                    )}`
              }
            />
          </MapboxDisplay>
        </>
      )}
    </ProjectDetailWrapper>
  );
};

export default ProjectSummary;
