import React, { Component } from "react";
import {
  Wrap,
  WrapTitle,
  ListWrap,
  SwitchContainer,
  AttachFileWrap,
  WarningWrap,
  WarningIcon,
  AutoServiceWrap,
} from "./styled";
import Chip from "./email_info_chip";
import SearchDialog from "./email_select_dialog";
import { AntSwitch } from "../switch";
import { bytesToSize } from "../../utils/helper";
import Input from "../form";
import * as theme from "../../theme";

function checkIfFixedOn(data, label, claimStatus) {
  if (label !== "Claim") {
    return false;
  } else {
    if (!data.get("is_invited_third_party") && claimStatus === "approved") {
      return true;
    } else {
      return false;
    }
  }
}
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attachMethod: "",
    };
  }

  setEmailSubject = () => {
    const {
      claimDetail,
      certInfo,
      contractInfo,
      label,
      accountConfig,
      changeEmailSubject,
    } = this.props;
    const itemName =
      label === "Claim" ? accountConfig?.getIn(["claim", "noun"]) : label;
    const subjectName =
      label === "Claim"
        ? claimDetail.get("claim_ref")
        : certInfo?.get("cert_certificate_ref");
    const contractName =
      label === "Claim"
        ? contractInfo?.get("contract_name")
        : certInfo?.get("contract_name");
    changeEmailSubject(
      "emailSubject",
      `${itemName} "${subjectName}" of ${contractName}`,
    );
  };

  componentDidMount() {
    this.setEmailSubject();
  }

  componentDidUpdate(prevProps) {
    const { claimDetail, certInfo, contractInfo } = this.props;
    if (
      prevProps.claimDetail !== claimDetail ||
      prevProps.certInfo !== certInfo ||
      prevProps.contractInfo !== contractInfo
    ) {
      this.setEmailSubject();
    }
  }

  render() {
    const {
      status,
      label,
      claimStatus,
      emailToList,
      emailSubject,
      isSendEmail,
      autoSendEmails,
      attachedEmailFiles,
      exceedAttachLimit,
      accountConfig,

      handleSetChipData,
      changeEmailSubject,
      setIsSendEmail,
      selectAttachedFile,
    } = this.props;
    const list = [{ label: "To", key: "to", value: emailToList }];
    const AttachOption = [
      { value: "link", label: "Download Link" },
      { value: "file", label: "File Attachment" },
    ];
    const sendTitle = `Send ${
      label === "Claim" ? accountConfig?.getIn(["claim", "noun"]) : label
    }`;

    const AutoEmailService = ({ data }) => {
      let is_invited = data.get("is_invited_third_party");
      return (
        <>
          {!is_invited && (
            <WarningWrap>
              <div className="warningIcon" />
              <div className="warningLabel">
                There are no Third Party Certifiers in this project. Please add
                below the email addresses of people who you want to receive this
                {accountConfig?.getIn(["clain", "noun"])?.toLowerCase()}. <br />
                You can add Third Party Certifiers in the Project Setup.
              </div>
            </WarningWrap>
          )}
          {is_invited && (
            <AutoServiceWrap>
              <label>
                An automated alert will be sent to the following email
                addresses:{" "}
              </label>
              <div className="email_list">
                {data.get("to_contacts").map((item, index) => (
                  <div className="emailWrap" key={index}>
                    {item.get("contact_email")}
                  </div>
                ))}
              </div>
            </AutoServiceWrap>
          )}
        </>
      );
    };

    const LinkSelection = ({ item }) => {
      const Selection = (props) => (
        <div className="select-wrap">
          <Input
            field="dropdown"
            noRadius={true}
            options={AttachOption}
            width="200px"
            height="32px"
            fontSize="14px"
            {...props}
          />
        </div>
      );
      if (item === "all") {
        return (
          <Selection
            value={this.state.attachMethod}
            onChange={(e) => {
              this.setState({ attachMethod: e.value });
              selectAttachedFile("all", e.value, "attach_method");
            }}
            placeholder={"Set all to…"}
          />
        );
      } else {
        return (
          <Selection
            value={item.get("attach_method")}
            onChange={(e) =>
              selectAttachedFile(item.get("file_id"), e.value, "attach_method")
            }
            fontColor={
              !item.get("is_attached_email") ? theme.palette.veryMediumGrey : ""
            }
            control={
              exceedAttachLimit &&
              item.get("attach_method") === "file" &&
              item.get("is_attached_email") ? (
                <WarningIcon />
              ) : (
                ""
              )
            }
          />
        );
      }
    };

    return (
      <>
        <WrapTitle>
          <div>{sendTitle}</div>
        </WrapTitle>
        {label === "Claim" && claimStatus === "approved" && (
          <AutoEmailService data={autoSendEmails} />
        )}
        <SwitchContainer>
          <AntSwitch
            checked={isSendEmail}
            onChange={(event) => setIsSendEmail(event)}
            name="isSendEmail"
            disabled={checkIfFixedOn(autoSendEmails, label, claimStatus)}
          />
          <div className={isSendEmail ? "label" : "label unchecked"}>
            {isSendEmail ? "On" : "OFF"}
          </div>
          <div className="label">Issue with email sending service</div>
        </SwitchContainer>
        {isSendEmail && (
          <Wrap>
            {list.map((item) => (
              <ListWrap key={item.label}>
                <div className="left">{item.label}</div>
                <div className="center">
                  <Chip
                    status={status}
                    chipData={item.value}
                    handleSetChipData={(data) =>
                      handleSetChipData(data, item.key)
                    }
                  />
                </div>
                <SearchDialog
                  title={"Add Contacts"}
                  field={item.key}
                  {...this.props}
                />
              </ListWrap>
            ))}
            <ListWrap>
              <div className="left subject">Subject</div>
              <div className="center">
                <input
                  className="inputEmail"
                  value={emailSubject}
                  name="emailSubject"
                  onChange={(e) =>
                    changeEmailSubject(e.target.name, e.target.value)
                  }
                  autoComplete="off"
                />
              </div>
            </ListWrap>
          </Wrap>
        )}
        {attachedEmailFiles.size !== 0 && isSendEmail && (
          <AttachFileWrap>
            <div className="file-title">Include Files</div>
            <div>
              Select the files that you want to send and how they are included
              in the email.
            </div>
            <div className="file_wrap">
              <ul>
                <li key={"all"}>
                  <input
                    type="checkbox"
                    className="checkbox all"
                    id="all"
                    onChange={(e) =>
                      selectAttachedFile(
                        "all",
                        e.target.checked,
                        "is_attached_email",
                      )
                    }
                    checked={
                      attachedEmailFiles.filter(
                        (item) => !item.get("is_attached_email"),
                      ).size === 0
                    }
                  />
                  <label htmlFor="all">Select All</label>
                  <LinkSelection item={"all"} />
                </li>
                {attachedEmailFiles.map((item) => (
                  <li key={item.get("file_id") + "_attach"}>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id={item.get("file_id") + "_attach"}
                      onChange={(e) =>
                        selectAttachedFile(
                          item.get("file_id"),
                          e.target.checked,
                          "is_attached_email",
                        )
                      }
                      checked={item.get("is_attached_email")}
                    />
                    <label
                      htmlFor={item.get("file_id") + "_attach"}
                      className={
                        !item.get("is_attached_email") ? "unselected" : ""
                      }
                    >
                      {item.get("file_name")}
                    </label>
                    <div className="size">
                      ({bytesToSize(item.get("file_size"))})
                    </div>
                    <LinkSelection item={item} />
                  </li>
                ))}
              </ul>
              {exceedAttachLimit && (
                <WarningWrap className="attachment">
                  <div className="warningIcon" />
                  <div>
                    The total file size for file attachments cannot exceed 10
                    MB.
                    <br />
                    You can try including some files as DownloadLinks.
                  </div>
                </WarningWrap>
              )}
            </div>
            {label === "Certificate" && (
              <div className="comment">
                Note: Only files shared with you are available to select here.
              </div>
            )}
          </AttachFileWrap>
        )}
      </>
    );
  }
}
export default index;
