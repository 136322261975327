import { Map, List } from "immutable";
import {
  UPLOAD_FILE,
  UPLOAD_PROGRESS,
  SUCCESS_UPLOAD,
  FAILURE_UPLOAD,
  RETRY_UPLOAD,
  REMOVE_FILE,
  CLEAR_FILE,
} from "./constants";

const initialState = Map({
  files: List(),
});

const fileReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_FILE:
      const fileId = action.payload.fileId;
      const existingFileIndex = state
        .get("files")
        .findIndex((file) => file.get("file_id") === fileId);
      if (existingFileIndex !== -1) {
        // File with fileId exists, update it
        return state.update("files", (files) =>
          files.map((file, index) =>
            index === existingFileIndex
              ? file
                  .set("uploading", true)
                  .set("progress", 0)
                  .set("error", null)
              : file,
          ),
        );
      } else {
        // File with fileId doesn't exist, add a new file
        const newFile = Map({
          file_id: fileId,
          uploading: true,
          progress: 0,
          success: false,
          error: null,
          file_name: action.payload.fileName,
          file_size: action.payload.fileSize,
          file: action.payload.file,
        });

        return state.update("files", (files) => files.push(newFile));
      }
    case UPLOAD_PROGRESS:
      return state.update("files", (files) =>
        files.map((file) =>
          file.get("file_id") === action.payload.fileId
            ? file.set("progress", action.payload.progress)
            : file,
        ),
      );
    case SUCCESS_UPLOAD:
      return state.update("files", (files) =>
        files.map((file) =>
          file.get("file_id") === action.payload.fileId
            ? file
                .set("file_id", action.payload.files[0].file_id)
                .set("uploading", false)
                .set("progress", 100)
                .set("success", true)
                .set("error", null)
            : file,
        ),
      );
    case FAILURE_UPLOAD:
      return state.update("files", (files) =>
        files.map((file) =>
          file.get("file_id") === action.payload.fileId
            ? file
                .set("uploading", false)
                .set("progress", 0)
                .set("success", false)
                .set("error", action.payload.error)
            : file,
        ),
      );
    case RETRY_UPLOAD:
      return state.update("files", (files) =>
        files.map((file) =>
          file.get("file_id") === action.payload.fileId
            ? file.set("uploading", true).set("progress", 0).set("error", null)
            : file,
        ),
      );
    case REMOVE_FILE:
      return state.update("files", (files) =>
        files.filter((file) => file.get("file_id") !== action.payload.fileId),
      );
    case CLEAR_FILE:
      return initialState;
    default:
      return state;
  }
};

export default fileReducer;
