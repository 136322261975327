import React, { Fragment } from "react";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import * as themeStyle from "../../../theme";

function YearMonthPicker(props) {
  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          fontFamily: themeStyle.font.mainFont,
          fontSize: "14px",
          color: themeStyle.palette.veryMediumGrey,
          fontWeight: themeStyle.font.mainFontWeight,
          cursor: "pointer",
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: themeStyle.palette.tangerineOrange,
        },
      },
      MuiTypography: {
        colorPrimary: {
          color: themeStyle.palette.tangerineOrange,
        },
      },
      MuiPickersMonth: {
        monthSelected: {
          color: themeStyle.palette.tangerineOrange,
        },
      },
      MuiButton: {
        textPrimary: {
          color: themeStyle.palette.gunmetal,
        },
      },
    },
  });
  const handleDateChange = (date) => {
    props.handleChange(date);
  };

  return (
    <Fragment>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            variant="dialog"
            openTo="year"
            views={["year", "month"]}
            value={props.selectedDate}
            onChange={handleDateChange}
            autoOk={true}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </Fragment>
  );
}

export default YearMonthPicker;
