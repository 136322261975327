import styled from "styled-components";
import * as theme from "../../theme";

export const CertTableWrapper = styled.div`
  background-color: ${theme.palette.white};
  table {
    font-size: 12px;
    th {
      text-align: left;
    }
  }

  .reviewCertComment {
    .commentDiv {
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .action_container {
        position: relative;
        width: 44px;
        height: 24px;
        margin-right: -15px;
      }
    }
  }

  tbody {
    td {
      box-sizing: border-box;
      &.reason {
        text-align: left;
        padding-left: 15px;

        .inline-wrapper {
          max-width: 205px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .action_container {
            position: relative;
            height: 24px;
            width: 44px;
            padding: 0px 10px;
            margin-right: -15px;
          }

          .reason-content {
            max-width: calc(100% - 44px);
            overflow-wrap: break-word;
          }
        }
      }
    }
  }

  tfoot {
    td {
      background-color: ${theme.palette.tealBlue};
      &.light_blue {
        background-color: ${theme.palette.turquoiseBlue};
      }
      &.white {
        background-color: ${theme.palette.white};
        &.before {
          width: 52px;
        }
        &.after {
          width: 234px;
        }
      }
      &.title {
        width: 211px;
      }
      &.value,
      &.light_blue {
        width: 110px;
        &.big {
          width: 237px;
        }
      }
    }
  }

  // Style for lineitem_options based on lump
  .grey-dash {
    display: inline-block;
    width: 10px;
    height: 1px;
    background-color: grey;
  }

  .percent_qty {
    display: grid;
    grid-template-columns: 60% 40%;
    text-align: right;
  }
`;

export const MaterialCertTableWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: bold;
      font-size: 24px;
    }
  }

  .materials {
    .sub-title {
      font-size: 20px;
      font-weight: bold;
    }

    .table {
      max-width: 1174px;
      margin-top: 20px;

      tfoot {
        td {
          &.empty {
            width: 52px;
            background-color: ${theme.palette.white};
            &.after {
              width: 234px;
            }
          }
          &.title {
            width: 321px;
          }
          &.total {
            width: 142px;
            &.this-cert {
              background-color: ${theme.palette.turquoiseBlue};
            }
          }
        }
      }
    }
  }
`;
