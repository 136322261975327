//import React from "react";
import { Redirect } from "react-router-dom";
import Notice from "./pages/notification";
import Contacts from "./pages/contacts";
import Dashboard from "./pages/dashboard";
import Projects from "./pages/projects";
import ClaimsReceived from "./pages/claimsReceived";
import UserDetail from "./pages/manageaccount/pages/profile";
import EditProfileDetail from "./pages/manageaccount/pages/profile/edit";
import NewContract from "./pages/contract/create";
import BaseContract from "./pages/basecontract";
import Materials from "./pages/materials";
import Variations from "./pages/variations";
import ManageAccount from "./pages/manageaccount/edit";
import Account from "./pages/manageaccount/view";
import ManageUsers from "./pages/manageaccount/pages/users";
import ManagePlan from "./pages/manageaccount/pages/plan";
import AddUsers from "./pages/manageaccount/pages/users/add";
import PlanPrice from "./pages/manageaccount/pages/plan/planPrice";
import EmailSummary from "./pages/manageaccount/pages/emailSummary";
import RoleSelection from "./pages/manageaccount/pages/roleSelection";
import UpdateContact from "./pages/contacts/pages/editContact";
import CreateContact from "./pages/contacts/pages/createContact";

import MakeAClaim from "./pages/makeclaim/makeClaim";
import ClaimOverview from "./pages/makeclaim/reviewClaim";

import MakeACert from "./pages/makecert/makeCert";
import CertOverview from "./pages/makecert/reviewCert";

import InvitationInfo from "./pages/invitation/index";
import ClaimCert from "./pages/claimsCerts";
import { Error401, Error401NoAcc, Error403, Error404 } from "./pages/errorPage";
import NewProject from "./pages/project/create";
import ProjectInfo from "./pages/project/view";
import ProjectSetting from "./pages/project/update";
import WorkflowSetting from "./pages/workflow";
import ContractView from "./pages/contract/index";
import ContractSetting from "./pages/contract/update";
import ContractHistory from "./pages/contract/history";
import ClaimCertWorkflow from "./pages/workflow/claimCertWorkflow";
import {
  PROJECT,
  CONTACT,
  CONTRACT,
  CREATE,
  EDIT,
  ACCOUNT,
  ACCESS,
} from "./utils/constants";

const routes = [
  {
    path: "/dashboard",
    navText: "dashboard",
    component: Dashboard,
  },
  {
    path: "/contacts",
    navText: "contacts",
    component: Contacts,
    routes: [
      {
        path: "add",
        component: CreateContact,
        auth: (profile) => profile.checkPermission(CONTACT, CREATE),
      },
      {
        path: "edit/:id",
        component: UpdateContact,
        auth: (profile) => profile.checkPermission(CONTACT, EDIT),
      },
    ],
  },
  {
    path: "/claims-received",
    navText: "claims-received",
    component: ClaimsReceived,
  },
  {
    path: "/notice",
    navText: "dashboard",
    component: Notice,
  },
  {
    path: "/projects",
    navText: "projects",
    component: Projects,
    routes: [{ path: ":projectType?", component: Projects }],
  },
  {
    path: "/claim-cert",
    navText: "claim-cert",
    component: ClaimCert,
  },
  {
    path: "/invitation",
    navText: "projects",
    component: Redirect,
    props: { to: "/" },
    routes: [
      { path: "cert/:code", component: InvitationInfo },
      { path: "subcontractor/:code", component: InvitationInfo },
    ],
  },
  {
    path: "/project",
    navText: "projects",
    component: NewProject,
    auth: (profile) => profile.checkPermission(PROJECT, CREATE),
    routes: [
      {
        path: "view/:projectId",
        component: ProjectInfo,
        auth: (profile) => profile.isFullPermission(),
      },
      {
        path: "view/:projectId/settings",
        component: ProjectSetting,
        auth: (profile, props) =>
          profile.checkPermission(PROJECT, EDIT, props.match.params.projectId),
      },
      {
        path: "view/:projectId/setting/workflow/:flowType",
        component: WorkflowSetting,
        auth: (profile, props) =>
          profile.checkPermission(PROJECT, EDIT, props.match.params.projectId),
      },
    ],
  },
  // old routes, to delete in future
  {
    path: "/create-new-contract/:proId/:type",
    navText: "projects",
    component: NewContract,
    routes: [
      { path: ":contractId", component: ContractView },
      { path: ":contractId/workflow", component: WorkflowSetting },
      { path: ":contractId/baseContract", component: BaseContract },
      { path: ":contractId/materials", component: Materials },
      { path: ":contractId/variations", component: Variations },
    ],
  },
  // old routes, to delete in future
  {
    path: "/contract/:proId/:type",
    navText: "projects",
    component: NewContract,
    routes: [
      { path: "view/:contractId", component: ContractView },
      { path: "view/:contractId/settings", component: ContractSetting },
    ],
  },
  // new added
  {
    path: "/contract/:proId/:type",
    navText: "projects",
    component: NewContract,
    auth: (profile, props) =>
      profile.checkPermission(PROJECT, EDIT, props.match.params.proId),
    routes: [
      {
        path: ":contractId",
        component: ContractView,
        auth: (profile) => profile.isFullPermission(),
      },
      {
        path: ":contractId/history",
        component: ContractHistory,
        auth: (profile) => profile.isFullPermission(),
      },
      {
        path: ":contractId/settings",
        component: ContractSetting,
        auth: (profile, props) =>
          profile.checkPermission(
            CONTRACT,
            EDIT,
            props.match.params.proId,
            props.match.params.contractId,
          ),
      },
      {
        path: ":contractId/workflow",
        component: WorkflowSetting,
        auth: (profile, props) =>
          profile.checkPermission(
            CONTRACT,
            EDIT,
            props.match.params.proId,
            props.match.params.contractId,
          ),
      },
      {
        path: ":contractId/baseContract",
        component: BaseContract,
        auth: (profile, props) =>
          profile.checkPermission(
            CONTRACT,
            EDIT,
            props.match.params.proId,
            props.match.params.contractId,
          ),
      },
      {
        path: ":contractId/materials",
        component: Materials,
        auth: (profile, props) =>
          profile.checkPermission(
            CONTRACT,
            EDIT,
            props.match.params.proId,
            props.match.params.contractId,
          ),
      },
      {
        path: ":contractId/variations",
        component: Variations,
        auth: (profile, props) =>
          profile.checkPermission(
            CONTRACT,
            EDIT,
            props.match.params.proId,
            props.match.params.contractId,
          ),
      },
    ],
  },
  // old routes, to delete in future
  {
    path: "/make-claim/:contractType/:proId/:contractId",
    navText: "projects",
    component: MakeAClaim,
    routes: [
      { path: ":claimId?", component: MakeAClaim },
      { path: ":claimId/review", component: ClaimOverview },
    ],
  },
  // old routes, to delete in future
  {
    path: "/review-claim/:contractType/:proId/:contractId/:claimId",
    navText: "projects",
    component: ClaimOverview,
    routes: [{ path: "approved", component: ClaimOverview }],
  },
  // old routes, to delete in future
  {
    path: "/make-cert/:contractType/:proId/:contractId/:claimId",
    navText: "projects",
    component: MakeACert,
    routes: [
      { path: ":certId?", component: MakeACert },
      { path: ":certId/review", component: CertOverview },
    ],
  },
  // old routes, to delete in future
  {
    path: "/review-cert/:contractType/:proId/:contractId/:claimId/:certId/approved",
    navText: "projects",
    component: CertOverview,
  },
  {
    path: "/project/:proId/contract/:contractId/:contractType/claim",
    navText: "projects",
    component: MakeAClaim,
    routes: [
      { path: ":claimId?", component: MakeAClaim },
      { path: ":claimId/review", component: ClaimOverview },
      { path: ":claimId/cert", component: MakeACert },
      { path: ":claimId/cert/review", component: CertOverview },
      { path: ":claimId/workflow/:certId?", component: ClaimCertWorkflow },
    ],
  },
  {
    path: "/manage-account",
    navText: "manage-account",
    component: Account,
    auth: (profile) => profile.checkPermission(ACCOUNT, ACCESS),
    routes: [
      { path: "view", component: Account },
      { path: "edit", component: ManageAccount },

      { path: "users", component: ManageUsers },
      { path: "users/add", component: AddUsers },

      {
        path: "profile",
        component: UserDetail,
        auth: (profile) => profile.isFullPermission(),
      },
      {
        path: "profile/edit",
        component: EditProfileDetail,
        auth: (profile) => profile.isFullPermission(),
      },

      {
        path: "plan-payment/:status?",
        component: ManagePlan,
        auth: (profile) => profile.checkPermission(ACCOUNT, EDIT),
      },
      {
        path: "plan-price",
        component: PlanPrice,
        auth: (profile) => profile.checkPermission(ACCOUNT, EDIT),
      },

      { path: "email-summaries", component: EmailSummary },

      { path: "role", component: RoleSelection },
    ],
  },
  {
    path: "/error_401",
    component: Error401,
  },
  {
    path: "/error_401_no_accounts",
    component: Error401NoAcc,
  },
  {
    path: "/error_403_link_expired",
    component: Error403,
  },
  {
    path: "/error_404",
    component: Error404,
  },
];

function* flatten(routes, root) {
  for (var route of routes) {
    var current = {
      ...root,
      ...route,
      path: (root ? root.path : "") + route.path + "/",
    };

    yield current;

    if (route.routes) {
      yield* flatten(route.routes, current);
    }
  }
}

export default Array.from(flatten(routes, null));
