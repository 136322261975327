import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useLocation } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import { NavItem, LogoPlaceholder } from "../style";
import routes from "../../../routes";
import Link from "../../linkWithQuery";
import { Logo } from "../../file/getFilePath";

export function NavList(props) {
  const [active, setActive] = useState("dashboard");
  const location = useLocation();

  useEffect(() => {
    if (location && routes) {
      let obj = routes.find((item) =>
        item.path.includes(location.pathname.split("/")[1]),
      );
      let activeHeader = obj ? obj.navText : "dashboard";
      setActive(activeHeader);
    }
  }, [location]);

  return (
    <Fragment>
      <Link to={props.navList[0]["link"]}>
        <LogoPlaceholder>
          <Logo className={"logo"} />
        </LogoPlaceholder>
      </Link>
      {props.navList.map(
        (item) =>
          item.permission && (
            <Link to={item.link} key={item.code}>
              <CSSTransition
                in={active === item.code}
                timeout={200}
                classNames="slide"
              >
                <NavItem className={active === item.code ? "focused" : ""}>
                  <Badge
                    badgeContent={item.num}
                    overlap="rectangular"
                    color="error"
                  >
                    <div className={item.num !== 0 ? "badge" : ""}>
                      {item.text}
                    </div>
                  </Badge>
                </NavItem>
              </CSSTransition>
            </Link>
          ),
      )}
    </Fragment>
  );
}
