import React from "react";
import {
  ApprovalList,
  ApprovalContainer,
  CloseIcon,
  ReviewWrapper,
  ApproverWrap,
} from "../styled";
import { convertUTCToTime } from "../../../utils/dateTimeHelper";

const HistoryItem = ({ history, timezone }) => {
  const { name, date, emails, action, message, comment } = history;
  return (
    <div className="history" key={`${name}-${date}`}>
      <div className="avatar" />

      <ApprovalList>
        <div>
          <b>{name}</b>
        </div>
        <div className="content">
          <b>{action}</b> &nbsp; {message}
        </div>
        {emails.length !== 0 && (
          <div className="content">
            {emails.map((email, index) => (
              <div className="email" key={index}>
                {email}
              </div>
            ))}
          </div>
        )}
        {comment && <div className="comment">{comment}</div>}
        <div className="date">
          {convertUTCToTime(date, timezone, true, "PPpp")}
        </div>
      </ApprovalList>
    </div>
  );
};

export function ApprovalHistory(props) {
  const { openStep, arrowRef, dataStep, step, timezone, close } = props;

  const findStepById = (id) => dataStep.find((item) => item.id === id) || {};
  const currentStep = findStepById(step);
  const currentStepInfo = {
    name: currentStep.label || "",
    approvers: currentStep.approvers || [],
    history: currentStep.history || [],
    showApprovers: step !== 1 && step !== dataStep.length,
  };
  return (
    <ApprovalContainer className={openStep ? "show" : ""}>
      <div className="arrow" ref={arrowRef} />
      <ReviewWrapper className="approver">
        <div className="container">
          <CloseIcon onClick={close} />
          <h1>{currentStepInfo.name}</h1>
          <h2 className="has-margin">Approvers</h2>

          {currentStepInfo.showApprovers ? (
            <>
              {currentStepInfo.approvers.map((approver) => (
                <ApproverWrap key={`${approver.name}-${approver.date}`}>
                  <div className="avatar" />
                  <div className="name">{approver.name} &nbsp;</div>
                  <div className="status">
                    ({approver.required ? "Required" : "Optional"})
                  </div>
                  {approver.approved ? <div className="checked" /> : <></>}
                </ApproverWrap>
              ))}
            </>
          ) : (
            <div className="no_data-label">No approvers to display</div>
          )}
        </div>

        <hr />
        <div className="container">
          <h2>History</h2>
          {currentStepInfo.history.length !== 0 ? (
            <>
              {currentStepInfo.history.map((item) => (
                <HistoryItem
                  key={`${item.name}-${item.date}`}
                  history={item}
                  timezone={timezone}
                />
              ))}
            </>
          ) : (
            <div className="no_data-label">No information to display</div>
          )}
        </div>
      </ReviewWrapper>
    </ApprovalContainer>
  );
}
