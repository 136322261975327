import { httpRequest } from "../base/httpRequest";

const BASE_URL = `/api/integration/ipm/account/accId`;

const read_ipm_projects = () => httpRequest("get", `${BASE_URL}/jobs`);

const read_one_ipm_project = (jobId) =>
  httpRequest("get", `${BASE_URL}/jobs/${jobId}`);

const read_ipm_contracts = (jobId) =>
  httpRequest("get", `${BASE_URL}/jobs/${jobId}/subcontracts`);

const read_one_ipm_contract = (jobId, subcontractId) =>
  httpRequest("get", `${BASE_URL}/jobs/${jobId}/subcontracts/${subcontractId}`);

export default {
  read_ipm_projects,
  read_one_ipm_project,
  read_ipm_contracts,
  read_one_ipm_contract,
};
