import styled from "styled-components";
import * as theme from "../../theme";
import cheerIcon from "../../statics/img/icon-celebration.svg";
import personIcon from "../../statics/img/icon-group-person.svg";
import avatar from "../../statics/img/avatar.svg";
import payLabLogo from "../../statics/img/logo-pay-lab.svg";

export const NoticeWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
  margin: 24px auto;

  width: 880px;
  height: 141px;

  background: ${(props) =>
    props.isWarning ? theme.palette.watermelonRed : theme.palette.white};

  border-radius: 4px;
  justify-content: space-between;
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    width: 100%;
    display: flex;
    color: ${(props) => (props.isWarning ? theme.palette.white : "inherit")};
    .icon {
      background: url(${cheerIcon}) no-repeat;
      height: 24px;
      width: 24px;
      margin-left: 12px;
    }
  }
  .content {
    width: 70%;
    color: ${(props) => (props.isWarning ? theme.palette.white : "inherit")};
  }
`;

export const ModalContent = styled.div`
  max-width: 960px;
  height: auto;
  padding: 0 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .has-margin {
    margin: 24px 0;
  }
  .content {
    width: 100%;
  }
`;

export const ContractInfoWrapper = styled.div`
  .title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  width: 100%;
  .trade {
    color: ${theme.palette.veryMediumGrey};
    margin-bottom: 16px;
  }
  .info-list {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    label {
      display: flex;
    }
  }
  .sub-wrapper {
    margin-bottom: 32px;
  }
`;
export const InvitationWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 56px;
  padding: 40px;

  width: 880px;
  height: 269px;

  background: ${(props) =>
    props.isClaim
      ? theme.palette.lightPastelGreen
      : theme.palette.lightPastelBlue};
  border-radius: 4px;
  justify-content: space-between;

  &.full-info {
    background: ${theme.palette.white} !important;
    border: 1px solid ${theme.palette.lightWolfGrey};
    height: auto;
    padding: 24px;
  }
  .person-icon {
    width: 24px;
    height: 24px;
    background: url(${personIcon}) no-repeat;
    margin-right: 12px;
    float: left;
  }

  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    float: left;
  }
  h2 {
    font-size: 20px;
    line-height: 24px;
  }
  h3 {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: ${theme.palette.veryMediumGrey};
    margin: 0;
  }

  .pending-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    margin: 24px 0;

    height: 160px;

    border: 1px solid ${theme.palette.lightWolfGrey};
    border-radius: 4px;
    .pending-notice {
      padding: 4px 12px;
      width: 129px;
      height: 23px;

      background: ${theme.palette.veryWarmLightGrey};
      border-radius: 16px;
      font-size: 12px;
      font-weight: bold;
    }
    .has-margin {
      margin: 20px 0 16px 0;
    }
    &.approved {
      width: 400px;
      align-items: flex-start;
      height: auto;
      float: left;
      .person-avatar {
        width: 42px;
        height: 42px;
        mask: url(${avatar});
        background-color: ${theme.palette.gunmetal};
      }
    }
  }
  .flex-wrap {
    display: flex;
    justify-content: space-between;
  }
`;

export const CompanyImage = styled.div`
  width: 100%;
  height: 50px;
  background: ${(props) =>
    props.url !== ""
      ? `url(${props.url}) no-repeat`
      : `url(${payLabLogo}) no-repeat`};
  background-size: contain;
`;

export const WelcomeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;

  width: 100%;
  margin-top: 20px;
  background: ${theme.palette.paleYellow};
  border-radius: 4px;
  position: relative;

  .arrow {
    &:before {
      position: absolute;
      content: "";
      display: block;
      width: 0;
      height: 0;
      left: 24.5%;
      margin-top: -60px;
      border: 18px solid transparent;
      border-bottom: 18px solid ${theme.palette.paleYellow};
    }
  }
  label {
    width: 85%;
    line-height: 20px;
    align-self: center;
  }
  button {
    margin: 0;
    height: 40px;
    padding: 10px 28px;
  }
`;
