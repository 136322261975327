import React from "react";
import {
  Img,
  BluePrimaryButton,
  LightBluePrimaryButton,
  BlueSecondaryButton,
  GreenPrimaryButton,
  RedPrimaryButton,
  RedSecondaryButton,
  GreySecondaryButton,
  YellowPrimaryButton,
  IconButton,
} from "./style";
import leftIcon from "../../statics/img/icon-chevron-down-dark-grey.svg";
import rightNextIcon from "../../statics/img/icon-chevron-right-white.svg";
import issueIcon from "../../statics/img/white-send.svg";
import downloadIcon from "../../statics/img/icon-download-white.svg";
import addIcon from "../../statics/img/icon-add-white.svg";
import submitIcon from "../../statics/img/icon-tick-white.svg";
import inviteIcon from "../../statics/img/invite-icon-dark-blue.svg";
import downIcon from "../../statics/img/icon-chevron-down.svg";
import upIcon from "../../statics/img/icon-chevron-up.svg";
import deleteIcon from "../../statics/img/icon-delete-medium-grey.svg";
import uploadIcon from "../../statics/img/icon-upload-cloud-dark-grey.svg";
import downloadDark from "../../statics/img/icon-download-dark-grey.svg";
import loadingIcon from "../../statics/img/icon-refresh.png";
import PayLabIcon from "../../statics/img/icon-pay-lab-arrow.svg";

function firstUpperCase(str) {
  if (!str) {
    return str;
  }
  return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
}

function BlueWhiteBtn(props) {
  return (
    <BluePrimaryButton {...props}>
      {props.label ? firstUpperCase(props.label) : firstUpperCase(props.title)}
    </BluePrimaryButton>
  );
}
function LightBlueWhiteBtn(props) {
  return (
    <LightBluePrimaryButton {...props}>
      {firstUpperCase(props.title)}
    </LightBluePrimaryButton>
  );
}

function GreenWhiteBtn(props) {
  return (
    <GreenPrimaryButton {...props}>
      {props.submitting && (
        <Img
          name={loadingIcon}
          margin=" 0 16px 0 0"
          float="left"
          animating={true}
        />
      )}
      {firstUpperCase(props.title)}
    </GreenPrimaryButton>
  );
}

function BlueOutlinedBtn(props) {
  return (
    <BlueSecondaryButton {...props}>
      {props.submitting && (
        <Img
          name={loadingIcon}
          margin="0 16px 0 0"
          float="left"
          animating={true}
        />
      )}
      {firstUpperCase(props.title)}
    </BlueSecondaryButton>
  );
}

function RedOutlinedBtn(props) {
  return (
    <RedSecondaryButton {...props}>
      {props.upperCase
        ? props.title
        : props.label
          ? firstUpperCase(props.label)
          : firstUpperCase(props.title)}
    </RedSecondaryButton>
  );
}

function RedBtn(props) {
  return (
    <RedPrimaryButton {...props}>
      {firstUpperCase(props.title)}
    </RedPrimaryButton>
  );
}

function GreyOutlinedBtn(props) {
  return (
    <GreySecondaryButton {...props}>
      {firstUpperCase(props.title)}
    </GreySecondaryButton>
  );
}

function NextGreenBtn(props) {
  return (
    <GreenPrimaryButton {...props} lineHeight="24px" padding="8px 24px">
      {props.submitting && (
        <Img
          name={loadingIcon}
          margin="0 16px 0 0"
          float="left"
          animating={true}
        />
      )}
      {firstUpperCase(props.title)}
      <Img name={rightNextIcon} margin=" 0 0 0 10px" float="right" />
    </GreenPrimaryButton>
  );
}

function SubmitGreenBtn(props) {
  return (
    <GreenPrimaryButton {...props} lineHeight="24px" padding="8px 24px">
      <Img name={submitIcon} margin=" 0 10px 0 0" float="left" />
      {firstUpperCase(props.title)}
    </GreenPrimaryButton>
  );
}
function BackBtn(props) {
  return (
    <BlueSecondaryButton {...props} lineHeight="24px" padding="8px 24px">
      <Img
        name={leftIcon}
        margin=" 0 10px 0 0"
        float="left"
        rotateDeg="90"
        disabled={props?.disabled}
      />
      {firstUpperCase(props.label)}
    </BlueSecondaryButton>
  );
}

function DeleteBtn(props) {
  return (
    <BlueSecondaryButton {...props} lineHeight="24px" padding="8px 24px">
      <Img
        className="unique-color"
        name={deleteIcon}
        margin=" 0  10px 0 0"
        float="left"
      />
      {firstUpperCase(props.title)}
    </BlueSecondaryButton>
  );
}
function AddGreenBtn(props) {
  return (
    <GreenPrimaryButton {...props} lineHeight="24px" padding="8px 24px">
      <Img name={addIcon} margin=" 0  10px 0 0" float="left" />
      {firstUpperCase(props.title)}
    </GreenPrimaryButton>
  );
}
function ViewPDFBtn(props) {
  return (
    <BluePrimaryButton {...props} lineHeight="24px" padding="8px 24px">
      <Img name={downloadIcon} margin=" 0 16px 0 0" float="left" />
      View Pdf
    </BluePrimaryButton>
  );
}
function DownloadDark(props) {
  return (
    <BlueSecondaryButton {...props} lineHeight="24px" padding="8px 24px">
      <Img name={downloadDark} margin=" 0 16px 0 0" float="left" />
      {firstUpperCase(props.title)}
    </BlueSecondaryButton>
  );
}
function AddBtn(props) {
  return props.title !== "Add Scale" ? (
    <BluePrimaryButton {...props} lineHeight="24px" padding="8px 24px">
      <Img name={addIcon} margin=" 0 16px 0 0" float="left" />
      {firstUpperCase(props.title)}
    </BluePrimaryButton>
  ) : (
    <BluePrimaryButton
      {...props}
      lineHeight="24px"
      padding="8px 44px 8px 32px"
      float="none"
    >
      <Img name={addIcon} margin="0 16px 0 0" float="left" />
      {firstUpperCase(props.title)}
    </BluePrimaryButton>
  );
}

function IssueBtn(props) {
  if (props.color === "blue") {
    return (
      <LightBluePrimaryButton {...props} lineHeight="24px" padding="8px 24px">
        <Img
          name={props.submitting ? loadingIcon : issueIcon}
          margin="0 16px 0 0"
          float="left"
          animating={props.submitting}
        />
        {firstUpperCase(props.title)}
      </LightBluePrimaryButton>
    );
  } else {
    return (
      <GreenPrimaryButton {...props} lineHeight="24px" padding="8px 24px">
        <Img
          name={props.submitting ? loadingIcon : issueIcon}
          margin="0 16px 0 0"
          float="left"
          animating={props.submitting}
        />
        {firstUpperCase(props.title)}
      </GreenPrimaryButton>
    );
  }
}

function InviteBtn(props) {
  return (
    <BlueSecondaryButton {...props} lineHeight="24px" padding="8px 20px">
      <Img
        name={props.type === "upload" ? uploadIcon : inviteIcon}
        margin="0 16px 0 0"
        float="left"
      />
      {props.title}
    </BlueSecondaryButton>
  );
}

function Details(props) {
  return (
    <BluePrimaryButton {...props} lineHeight="24px" padding="8px 24px">
      <Img
        name={!props.status ? downIcon : upIcon}
        margin="0 0 0 32px"
        float="right"
      />
      Details
    </BluePrimaryButton>
  );
}

function SignUpBtn(props) {
  return (
    <YellowPrimaryButton {...props} lineHeight="40px" padding="0 16px">
      {props.submitting && (
        <Img
          name={loadingIcon}
          float="left"
          animating={true}
          margin={"8px 0"}
        />
      )}
      {firstUpperCase(props.title)}
      <Img name={PayLabIcon} float="right" width={"40px"} height={"40px"} />
    </YellowPrimaryButton>
  );
}

function DownloadBtn(props) {
  return (
    <YellowPrimaryButton
      {...props}
      padding="16px 30px"
      margin="30px 0 0"
      height="48px"
    >
      {firstUpperCase(props.title)}
    </YellowPrimaryButton>
  );
}

// Alias of DownloadBtn, for the better naming
function YellowBtn(props) {
  return DownloadBtn(props);
}

export {
  BlueWhiteBtn,
  LightBlueWhiteBtn,
  BlueOutlinedBtn,
  GreenWhiteBtn,
  RedOutlinedBtn,
  RedBtn,
  GreyOutlinedBtn,
  NextGreenBtn,
  SubmitGreenBtn,
  BackBtn,
  DeleteBtn,
  AddGreenBtn,
  ViewPDFBtn,
  DownloadDark,
  AddBtn,
  IssueBtn,
  InviteBtn,
  DownloadBtn,
  YellowBtn,
  IconButton,
  SignUpBtn,
  Details,
};
