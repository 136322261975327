import { httpRequest } from "../base/httpRequest";

const COMPANY_URL = `/api/company/account/accId`;

const read_account_companies = () =>
  httpRequest("get", `${COMPANY_URL}/company`);
const read_account_company = (companyId) =>
  httpRequest("get", `${COMPANY_URL}/company/${companyId}`);
const upsert_account_company = (data) =>
  httpRequest("post", `${COMPANY_URL}/company`, data);
const delete_account_company = (companyId) =>
  httpRequest("delete", `${COMPANY_URL}/company/${companyId}`);

const read_company_contacts = (companyId) =>
  httpRequest("get", `${COMPANY_URL}/company/${companyId}/contact`);
const read_company_contact = (companyId, contactId) =>
  httpRequest(
    "get",
    `${COMPANY_URL}/company/${companyId}/contact/${contactId}`,
  );
const upsert_company_contact = (companyId, data) =>
  httpRequest("post", `${COMPANY_URL}/company/${companyId}/contact`, data);
const delete_company_contact = (contactId, companyId) =>
  httpRequest(
    "delete",
    `${COMPANY_URL}/company/${companyId}/contact/${contactId}`,
  );

const upload_csv_contact = (companyId, data, config) => {
  if (companyId === "") {
    return httpRequest("post", `${COMPANY_URL}/upload/company`, data, config, {
      "Content-Type": "multipart/form-data",
    });
  } else {
    return httpRequest(
      "post",
      `${COMPANY_URL}/company/${companyId}/upload/contacts`,
      data,
      config,
      { "Content-Type": "multipart/form-data" },
    );
  }
};

const read_account_contacts = () =>
  httpRequest("get", `${COMPANY_URL}/readallcontact`);
export default {
  read_account_companies,
  read_account_company,
  upsert_account_company,
  delete_account_company,
  read_company_contacts,
  read_company_contact,
  upsert_company_contact,
  delete_company_contact,
  read_account_contacts,
  upload_csv_contact,
};
