import React, { Component } from "react";
import Select from "react-select";
import DropdownIndicator from "common/dropdown/component/indicator";
import * as mainTheme from "../../../theme";
import { overrideColor } from "common/dropdown/component/helper";
export default class SelectFrame extends Component {
  render() {
    const selectInputStyle = {
      control: (styles) => ({
        ...styles,
        width: width || "215px",
        height: height || "44px",
        borderRadius: "4px",
        border: "none",
        fontSize: "16px",
        fontWeight: "bold",
        paddingLeft: paddingLeft || "10px",
        boxShadow: "none",
        minHeight: 0,
        "&:hover": {
          borderColor: mainTheme.palette.white,
          border: "none",
          cursor: "pointer",
        },
      }),

      option: (provided, state) => ({
        ...provided,
        width: width || "215px",
        height: "44px",
        fontSize: "16px",
        paddingLeft: "10px",
        color: mainTheme.palette.gunmetal,
        "&:hover": {
          backgroundColor: mainTheme.palette.warmLightGrey,
          cursor: "pointer",
        },
      }),
      indicatorSeparator: (styles) => ({
        ...styles,
        display: "none",
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        padding: padding || "14px",
      }),
      menu: (styles) => ({
        ...styles,
        width: width || "215px",
        color: mainTheme.palette.gunmetal,
        fontFamily: mainTheme.font.mainFont,
      }),
      singleValue: (styles) => ({
        ...styles,
        lineHeight: "24px",
        color: mainTheme.palette.gunmetal,
        fontFamily: mainTheme.font.mainFont,
      }),
    };
    const { options, value, onChange, width, height, padding, paddingLeft } =
      this.props;
    return (
      <Select
        {...this.props}
        components={{ DropdownIndicator }}
        options={options}
        value={value}
        onChange={onChange}
        styles={selectInputStyle}
        closeMenuOnSelect={true}
        isSearchable={false}
        theme={(theme) => overrideColor(theme)}
      />
    );
  }
}
