import React, { useState } from "react";

import { PrefilledTag } from "../../../contract/styled";
import {
  ProjectDetailWrapper,
  SettingSection,
  SectionIcon,
} from "../../styled";

import {
  BlueOutlinedBtn as Button,
  BlueWhiteBtn as ButtonDark,
} from "../../../../common/button";
import RetentionSetting from "./settings";
import RetentionUpdate from "./update";
import { LinkedInfoSettings } from "../../../contract/common/integration";

export default function Retention(props) {
  const {
    retention,
    updateInfo,
    prefilledSeen,
    hideTag,
    showLessRetention,
    readOnly,
    isContract,
    isLinked,
    config,
  } = props;

  const [open, setOpen] = useState(false);
  const [edited, setEdited] = useState(false);

  const hasRetention = retention && retention.size !== 0;
  var showPrefilledTag =
    prefilledSeen &&
    !prefilledSeen.get("retentions") &&
    hasRetention &&
    !edited;

  return (
    <ProjectDetailWrapper className="setting-page" id="nav-retention">
      <SettingSection className="retention">
        <div className="section-title">
          <div className="flex">
            <SectionIcon img={"retention"} />
            <span>Retentions</span>
            {showPrefilledTag && (
              <PrefilledTag>Prefilled From Project</PrefilledTag>
            )}
          </div>
          {isLinked && (
            <LinkedInfoSettings from={isContract ? "Contract" : "Project"} />
          )}
          {hasRetention && !readOnly && !isLinked && (
            <Button title="edit" onClick={() => setOpen(true)} />
          )}
        </div>
        {/* display retention info */}
        <div className="center">
          {!showLessRetention &&
            (!hasRetention ? (
              <div>No Retention</div>
            ) : (
              <RetentionSetting data={retention} config={config} />
            ))}
          {showLessRetention && props.render && props.render()}
        </div>
        <div>
          {!hasRetention && !readOnly && !isLinked && (
            <ButtonDark
              title="Setup Retentions"
              onClick={() => setOpen(true)}
            />
          )}
        </div>
      </SettingSection>
      <RetentionUpdate
        retention={retention}
        open={open}
        setEdited={setEdited}
        updateInfo={updateInfo}
        setOpen={setOpen}
        hideTag={hideTag}
        showPrefilledTag={showPrefilledTag}
        isContract={isContract}
        {...props}
      />
    </ProjectDetailWrapper>
  );
}
