import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const GetLogo = (fileName, fileType, props) => {
  const env = useSelector((state) => state.getIn(["headers", "config", "env"]));
  const rootPath = `${process.env.PUBLIC_URL}/statics`;

  const [src, setSrc] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (env) {
      setSrc(`${rootPath}/${env}/${fileType}/${fileName}`);
      setError(false);
    }
  }, [env, fileName, fileType, rootPath]);

  const onError = () => {
    if (!error) {
      setSrc(`${rootPath}/default/${fileType}/${fileName}`);
      setError(true);
    }
  };
  return <img src={src} onError={onError} {...props} />;
};

const Logo = (props) => GetLogo("logo.svg", "img", props);
const LogoWhite = (props) => GetLogo("logo_white.svg", "img", props);

export { Logo, LogoWhite };
