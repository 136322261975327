import React, { Component } from "react";
import Select, { components } from "react-select";
import DropdownIndicator from "./component/indicator";
import { valueFromId, overrideColor } from "./component/helper";
import * as mainTheme from "../../theme";
import { DropdownBadge } from "./styled";

const Option = (props) => {
  if (!props?.data?.isDisabled || !props?.data?.subLabel) {
    return (
      <components.Option {...props} className="label-only">
        {props?.data?.label}
        {props?.data?.badge && (
          <DropdownBadge>{props?.data?.badge}</DropdownBadge>
        )}
      </components.Option>
    );
  }
  return (
    <components.Option {...props}>
      <div className="no-border">
        {props?.data?.label}
        {props?.data?.badge && (
          <DropdownBadge>{props?.data?.badge}</DropdownBadge>
        )}
      </div>
      <div className={props.data.isDisabled ? "sub-label-disabled" : ""}>
        {props.data.subLabel}
      </div>
    </components.Option>
  );
};

export default class SelectFrame extends Component {
  render() {
    const {
      options,
      separateFirstOption,
      value,
      onChange,
      width,
      field,
      placeholder,
      extraOption,
      noRadius,
      isClearable,
      noOptionsMessage,
      disabled,
      height,
      fontSize,
      fontColor,
      control,
      boldOnSelected,
      filterOption,
      usePortal,
      placeMenu,
      missingRequired,
      error,
    } = this.props;

    const ValueContainer = ({ children, ...props }) => {
      const { getValue } = props;
      const selectedOption = getValue()[0];
      if (!selectedOption) {
        return (
          <components.ValueContainer {...props}>
            {children}
          </components.ValueContainer>
        );
      }
      return (
        <components.ValueContainer {...props}>
          {selectedOption?.subLabel ? (
            <>
              <div className={boldOnSelected ? "bold" : ""}>
                {children}
                {selectedOption?.badge && (
                  <DropdownBadge marginLeft="75px">
                    {selectedOption?.badge}
                  </DropdownBadge>
                )}
              </div>
              <div>{selectedOption.subLabel}</div>
            </>
          ) : (
            <>
              {children}
              {selectedOption?.badge && (
                <DropdownBadge marginLeft="75px">
                  {selectedOption?.badge}
                </DropdownBadge>
              )}
            </>
          )}
        </components.ValueContainer>
      );
    };

    const selectInputStyle = {
      control: (styles, { isDisabled }) => ({
        ...styles,
        width: width || "446px",
        height: height || "44px",
        borderRadius: noRadius ? "0px" : "4px",
        borderColor:
          missingRequired || error
            ? mainTheme.palette.watermelonRed
            : mainTheme.palette.wolfGrey,
        fontSize: fontSize || "16px",
        boxShadow: "none",
        minHeight: 32,
        backgroundColor: isDisabled
          ? mainTheme.palette.greyWhite
          : mainTheme.palette.white,
        cursor: isDisabled ? "not-allowed" : "pointer",
      }),
      option: (provided, state) => ({
        ...provided,
        textAlign: "left",
        width: width || "446px",
        height: height || "38px",
        fontSize: fontSize || "16px",
        color: mainTheme.palette.gunmetal,
        alignItems: "center",
        "&:hover": {
          cursor: "pointer",
        },
        ".no-border": {
          borderBottom: "none !important",
          color: mainTheme.palette.lightWolfGrey,
          fontWeight: "bold",
        },
        ".sub-label-disabled": {
          color: mainTheme.palette.watermelonRed,
          marginBottom: "5px",
          marginTop: "2px",
          fontSize: "14px",
        },
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        position: "",
        height: height || "40px",
        ".bold": {
          fontWeight: "bold",
        },
      }),
      indicatorSeparator: (styles) => ({
        ...styles,
        display: "none",
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: height || "44px",
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        padding: "14px",
      }),
      menu: (styles) => ({
        ...styles,
        zIndex: 9999,
        width: width || "446px",
        color: mainTheme.palette.gunmetal,
        fontFamily: mainTheme.font.mainFont,
        ".label-only": {
          paddingTop: boldOnSelected ? "20px" : "",
          fontWeight: boldOnSelected ? "bold" : "inherit",
        },
      }),
      singleValue: (styles, { isDisabled }) => ({
        ...styles,
        color: isDisabled
          ? mainTheme.palette.veryMediumGrey
          : fontColor || mainTheme.palette.gunmetal,
        fontFamily: mainTheme.font.mainFont,
        lineHeight: "18px",
        textAlign: "left",
        maxWidth: "calc(100% - 34px)",
      }),
      menuList: (provided, state) => {
        return {
          ...provided,
          "& :first-of-type": {
            borderBottom: separateFirstOption
              ? `1px solid ${mainTheme.palette.wolfGrey}`
              : "inherit",
          },
        };
      },
      menuPortal: (base) => ({ ...base, zIndex: 9999, position: "absolute" }),
      noOptionsMessage: (styles) => ({
        ...styles,
        padding: "16px",
        color: mainTheme.palette.gunmetal,
      }),
    };

    const iconControl = ({ children, ...props }) => (
      <components.Control {...props}>
        {control} {children}
      </components.Control>
    );

    return (
      <Select
        components={
          field === "search"
            ? {
                DropdownIndicator,
                indicatorSeparator: null,
                Control: iconControl,
                Option,
              }
            : {
                DropdownIndicator,
                indicatorSeparator: null,
                Control: iconControl,
                Option,
                ValueContainer,
              }
        }
        value={
          (extraOption
            ? valueFromId(extraOption, value)
            : valueFromId(options, value)) || null
        }
        menuPortalTarget={usePortal && document.body}
        options={options}
        onChange={onChange}
        styles={selectInputStyle}
        closeMenuOnSelect={true}
        menuPlacement={placeMenu || "auto"}
        isSearchable={field === "search"}
        placeholder={placeholder}
        isClearable={isClearable || false}
        theme={(theme) => overrideColor(theme)}
        noOptionsMessage={() => {
          return noOptionsMessage || "No options";
        }}
        isDisabled={disabled}
        filterOption={filterOption}
      />
    );
  }
}
