import React, { PureComponent } from "react";
import { PageLayout, Header, Main, Image } from "./styled";

import { DownloadBtn } from "../../common/button";
import { bytesToSize } from "../../utils/helper";
import { downloadFile as downloadLink } from "../../utils/createDownloadLink";
import { LogoWhite } from "../../common/file/getFilePath";

import API from "../../server";

export default class FileDownload extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      status: "loading",
      file: {
        fileInfo: null,
        fileName: "",
        fileSize: 0,
      },
    };
  }
  render() {
    const { status, file } = this.state;
    const pageInfo = {
      error: {
        title: "File not found",
        label: "Your link may have expired.",
      },
      loading: {
        title: "Download File",
        label: "Your file is being retrieved.",
      },
      ready: {
        title: "Download File",
        label: "Your file is ready for download.",
      },
    };
    return (
      <PageLayout>
        <Header>
          <LogoWhite className="logo" />
        </Header>
        <Main>
          <div className="header">{pageInfo[status]["title"]}</div>
          <div className="sub-header">{pageInfo[status]["label"]}</div>
          <Image className={status} status={status} />
          {status === "ready" && (
            <>
              <div className="file-name">{file.fileName}</div>
              <div>{bytesToSize(file.fileSize)}</div>
              <DownloadBtn
                title="download file"
                onClick={() => downloadLink(file.fileInfo)}
              />
            </>
          )}
        </Main>
        <div className="pattern" />
      </PageLayout>
    );
  }

  componentDidMount() {
    const token = this.props.match.params.token;
    API.download_one_shared_file_by_token(token, { responseType: "blob" }).then(
      (res) => {
        const fileName = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .replaceAll('"', "");
        return this.setState({
          file: {
            fileInfo: res,
            fileName: fileName,
            fileSize: res.data.size,
          },
          status: "ready",
        });
      },
      (error) => {
        return this.setState({
          status: "error",
        });
      },
    );
  }
}
