import React, { Component } from "react";
import { NoticeWrap } from "./styled";
class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = { hightLight: false };
    this.fileInputRef = React.createRef();
    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.fileCheckType = this.fileCheckType.bind(this);
  }
  fileCheckType(target) {
    var filetypes = [".xls", ".xlsx"];
    var filepath = target.name;
    if (target && filepath) {
      var isNext = false;
      var fileEnd = filepath.substring(filepath.indexOf("."));
      if (filetypes && filetypes.length > 0) {
        for (var i = 0; i < filetypes.length; i++) {
          if (filetypes[i] === fileEnd) {
            isNext = true;
            break;
          }
        }
      }
      if (!isNext) {
        this.props.alertBox("Don't support this type file!");
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt) {
    evt.preventDefault();
    if (this.props.disabled) return;
    const files = evt.target.files;
    const array = this.fileListToArray(files);
    if (this.props.onFilesAdded) {
      if (this.props.uploadSection === "csv") {
        this.fileCheckType(files[0]) && this.props.onFilesAdded(array);
      } else {
        this.props.onFilesAdded(array);
      }
    }
  }

  onDragOver(evt) {
    evt.preventDefault();
    if (this.props.disabled) return;
    this.setState({ hightLight: true });
  }
  onDragLeave() {
    this.setState({ hightLight: false });
  }

  onDrop(event) {
    event.preventDefault();
    if (this.props.disabled) return;
    const files = event.dataTransfer.files;
    const array = this.fileListToArray(files);
    if (this.props.onFilesAdded) {
      if (this.props.uploadSection === "csv") {
        if (files.length > 1) {
          this.props.alertBox("You can only select one file to upload!");
        } else {
          this.fileCheckType(files[0]) && this.props.onFilesAdded(array);
        }
      } else {
        this.props.onFilesAdded(array);
      }
    }
    this.setState({ hightLight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  render() {
    const { uploadSection, disabled } = this.props;
    const { hightLight } = this.state;
    return (
      <div
        className={`dragFrame ${hightLight ? "Highlight" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: disabled ? "default" : "pointer" }}
      >
        <div className="uploadText">
          <div className="uploadCloudImg" />

          <span className="drag">Drag and drop</span>
          <span className="upload">or </span>
          <input
            ref={this.fileInputRef}
            style={{ display: "none" }}
            type="file"
            multiple={uploadSection === "csv" ? false : true}
            onChange={this.onFilesAdded}
          />
          <span className="browser">Browse Files</span>
        </div>
        {uploadSection === "lineItem" && (
          <NoticeWrap>
            <div className="noticeIcon" />
            These attachments can be seen and downloaded by external members.
          </NoticeWrap>
        )}
      </div>
    );
  }
}

export default Dropzone;
