export const isString = (value) => {
  return typeof value === "string" || value instanceof String;
};

export const toKebabCase = (str) => {
  return isString(str) ? str.toLowerCase().replace(/ /g, "-") : str;
};

export const toCamelCase = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
};

/**
 * Transforms an underscore-separated key into a camel case string with each word capitalized.
 *
 * @param {string} key - The key to transform.
 * @returns {string} - The transformed key as a label.
 */
export const transformKeyToLabel = (key) => {
  // Ensure key is a string and is not undefined or null
  if (typeof key !== "string" || key === undefined || key === null) {
    return "";
  }
  // Replace underscores with spaces and convert to camel case
  return key
    .replace(/_/g, " ")
    .replace(/\b\w/g, (match) => match.toUpperCase())
    .replace(/\s+/g, " ")
    .trim();
};
