import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { fromJS } from "immutable";
import debounce from "lodash/debounce";

import { Container } from "../../project/styled";

import Menu from "../../../common/breadcrumbs";
import Loading from "../../../common/loading";
import { CONTRACT, EDIT } from "../../../utils/constants";
import profile from "../../../utils/profile";

import ContractDetails from "../components/editDraftContractDetails";
import Settings from "../components/settingsComponent";
import CompletionBar from "../components/completionBar";
import LineItem from "../components/lineItem";
import Provisional from "../components/provisional";
import Materials from "../components/materials";
import TimezoneSection from "../../project/components/timezone";
import RetentionSection from "../../project/components/retention";
import DateSchedule from "../components/dateSchedule";
import NoteSection from "../components/noteSection";
import MemberSection from "../../project/components/projectMember";
import WorkflowSection from "../../project/components/workflow";
import DocumentSection from "../components/document";
import TaxSection from "../components/tax";
import { ReturnAction } from "../index";
import Navigator, { navTemplate } from "../../../common/navigator";

import { actionCreators } from "../store";
import { actionCreators as actionCreatorsProject } from "../../project/store";

// create contract settings page
class index extends PureComponent {
  constructor(props) {
    super(props);
    this.calculateDates = debounce(
      (proId, dueDates) => this.props.calculateDueDate(proId, dueDates),
      300,
    );

    this.state = {
      isAdmin: false,
      contractType: "",
      isBaseExpanded: false,
      isVarExpanded: false,
      navExpanded: [],
      haveProvisionalItems: false,
      expandTreeKeys: [],
    };
  }

  updateHaveProvisionalItems = (status) => {
    this.setState({ haveProvisionalItems: status });
  };
  updateExpandTreeKeys = (keys) => {
    this.setState({ expandTreeKeys: keys });
  };

  render() {
    const {
      projectInfo,
      contractInfo,
      completionSteps,
      config,
      members,
      allUsers,
      readOnly, // no permission to edit contract
      loading,
      orgType,
      symbol,
      accountConfig,

      updateContract,
      updateRetention,
      leaveContract,
      startContract,
      updateContractMember,
      uploadFiles,
      updatePaymentTerms,
      hidePrefilledTag,
      accountId,
      deleteAttachment,
      downloadAttachment,
      updateAttachment,
    } = this.props;
    const fullContractType = contractInfo.get("contract_type");
    const { isAdmin, contractType } = this.state;
    const isLinked = contractInfo.get("integration") ? true : false;

    let workflowSteps = null;
    if (contractType === "cert") {
      workflowSteps = contractInfo.get("cert_workflow_steps");
    } else if (contractType === "claim") {
      workflowSteps = contractInfo.get("claim_workflow_steps");
    }

    const setNavExpanded = (value, navItem) => {
      let updatedItems = [];
      if (value) {
        updatedItems = [...this.state.navExpanded, navItem];
      } else {
        updatedItems = this.state.navExpanded.filter(
          (item) => item !== navItem,
        );
      }
      this.setState({ navExpanded: updatedItems });
    };

    const link = [
      { link: "/projects", text: "Projects" },
      {
        link: `/project/view/${projectInfo.get("id")}`,
        text: projectInfo.get("name"),
      },
    ];
    const { proId, contractId, type } = this.props.match.params;
    let paymentTermsOptions = {};
    if (typeof config.payclaim !== "undefined") {
      paymentTermsOptions = {
        pay_cert_options: config.payclaim["pay_cert_options"],
        pay_claim_options: config.payclaim["pay_claim_options"],
        payment_options: config.payclaim["payment_options"],
      };
    }

    let NewContractNavigator = [
      "nav-invitation",
      "nav-contractMember",
      "nav-dateSchedule",
      `nav-${type}Workflow`,
      "nav-timezone",
      "nav-baseContracts",
      "nav-variation",
      ...(this.state.haveProvisionalItems ? ["nav-provisional"] : []),
      ...(!isLinked ? ["nav-materials"] : []),
      "nav-retention",
      "nav-tax",
      "nav-note",
      "nav-document",
      "nav-setting",
    ].map((id) => {
      let processed = navTemplate[id];
      if (id.includes("claim"))
        processed.label = navTemplate[id].label.replace(
          "Claim",
          accountConfig?.getIn(["claim", "noun"]),
        );
      return {
        id,
        ...processed,
        ...(navTemplate[id]?.required
          ? { completed: completionSteps?.get(id) }
          : {}),
      };
    });

    if (loading) {
      return <Loading />;
    }

    return (
      <Container>
        <div className="page-breadcrumbs">
          <Menu
            link={link}
            content={"Draft Contract"}
            className="page-breadcrumbs-label"
          />
        </div>
        <div className="page-breadcrumbs">
          <h1>Draft Contract</h1>
          <ReturnAction
            projectInfo={projectInfo}
            orgType={orgType}
            action={() => hidePrefilledTag("all")}
          />
        </div>
        <Navigator
          data={NewContractNavigator}
          anchor={{ element: "completion-bar", direction: "right" }}
          isBaseExpanded={this.state.isBaseExpanded}
          isVarExpanded={this.state.isVarExpanded}
          navExpanded={this.state.navExpanded}
          country={contractInfo.get("country")}
        />
        <CompletionBar
          isClaimContract={type === "claim"}
          completionSteps={completionSteps.toJS()}
          startContract={() => startContract(proId, type, contractId)}
          fullContractType={fullContractType}
          readOnly={readOnly}
          leaveContract={() =>
            leaveContract(proId, contractId, fullContractType)
          }
          accountConfig={accountConfig}
        />
        <ContractDetails
          {...this.props}
          updateInfo={(data) => updateContract(proId, contractId, data)}
          type={type}
          config={config}
        />
        <MemberSection
          updateMembers={(data, mainContact) =>
            updateContractMember(
              proId,
              contractId,
              data,
              mainContact,
              contractInfo.get("status"),
              accountId,
              contractType,
            )
          }
          allUsers={allUsers.toJS()}
          members={members.toJS()}
          level={"contract"}
          prefilledSeen={this.props.prefilledSeen}
          readOnly={readOnly}
          hideTag={() => hidePrefilledTag("contractMembers")}
        />
        <DateSchedule
          {...this.props}
          options={paymentTermsOptions}
          submit={(data) => updatePaymentTerms(proId, contractId, data)}
          hideTag={() => hidePrefilledTag("dateSchedule")}
          calculateExample={(dueDates) => this.calculateDates(proId, dueDates)}
        />
        <WorkflowSection
          workflowType={contractType}
          projectId={projectInfo.get("id")}
          workflowInfo={workflowSteps}
          contractId={contractInfo.get("id")}
          hideTag={() => hidePrefilledTag("workflow")}
          readOnly={readOnly}
          prefilledSeen={this.props.prefilledSeen}
        />
        <TimezoneSection
          timezone={contractInfo.get("time_zone")}
          updateInfo={(data) =>
            updateContract(proId, contractId, { time_zone: data })
          }
          level="contract"
          hideTag={() => hidePrefilledTag("timezone")}
          {...this.props}
        />
        <LineItem
          key="base"
          {...this.props}
          isAdmin={isAdmin}
          itemsType={"baseContract"}
          setExpanded={(value) => setNavExpanded(value, "nav-baseContracts")}
          isLinked={isLinked}
          expandTreeKeys={this.state.expandTreeKeys}
          setExpandKeys={this.updateExpandTreeKeys}
        />
        <LineItem
          key="variation"
          {...this.props}
          isAdmin={isAdmin}
          itemsType={"variations"}
          setExpanded={(value) => setNavExpanded(value, "nav-variation")}
          isLinked={isLinked}
          expandTreeKeys={this.state.expandTreeKeys}
          setExpandKeys={this.updateExpandTreeKeys}
        />
        <Provisional
          {...this.props}
          isAdmin={isAdmin}
          setNavExpanded={(value) => setNavExpanded(value, "nav-provisional")}
          updateHaveProvisionalItems={this.updateHaveProvisionalItems}
        />
        {!isLinked && (
          <Materials
            {...this.props}
            isAdmin={isAdmin}
            proId={proId}
            contractId={contractId}
            type={type}
            setNavExpanded={(value) => setNavExpanded(value, "nav-materials")}
          />
        )}
        <RetentionSection
          {...this.props}
          retention={contractInfo.get("retention") || fromJS({})}
          isLinked={isLinked}
          updateInfo={(type, info, scale, retentionAccounts) =>
            updateRetention(
              proId,
              contractId,
              type,
              info,
              scale,
              retentionAccounts,
            )
          }
          hideTag={() => hidePrefilledTag("retentions")}
          currency={symbol?.get("title")}
          isContract
        />
        <TaxSection
          info={contractInfo}
          accountConfig={accountConfig}
          handleSubmit={(data, isRefresh) =>
            updateContract(proId, contractId, data, isRefresh)
          }
        />
        <NoteSection
          side={type}
          info={contractInfo}
          readOnly={readOnly}
          handleSubmit={(update) => updateContract(proId, contractId, update)}
        />
        <DocumentSection
          {...this.props}
          uploadFiles={(files) => uploadFiles(proId, contractId, files)}
          handleDelete={(id) =>
            deleteAttachment(projectInfo.get("id"), contractInfo.get("id"), id)
          }
          handleDownload={(id) =>
            downloadAttachment(
              projectInfo.get("id"),
              contractInfo.get("id"),
              id,
            )
          }
          updateAttachment={(updatedAttachment) =>
            updateAttachment(proId, contractId, updatedAttachment)
          }
        />
        <Settings
          {...this.props}
          proId={proId}
          contractId={contractId}
          updateInfo={(data) => updateContract(proId, contractId, data, false)}
          isClaimContract={type === "claim"}
        />
      </Container>
    );
  }

  reloadContract = () => {
    const { proId, contractId } = this.props.match.params;
    this.props.readContractDetails(proId, contractId);
  };
  componentDidMount() {
    const { proId, type, contractId } = this.props.match.params;
    profile.checkPermission(CONTRACT, EDIT, proId, contractId).then((res) => {
      const isAdmin = res;
      this.setState({ isAdmin: isAdmin });
      this.setState({ contractType: type });
      this.props.readInitialInfo(proId, isAdmin);
    });
    this.props.saveContractType(type);

    // update data on focus
    window.addEventListener("focus", this.reloadContract);
    // Disable Back Button, TODO, will remove this code block in future as per the new requirements.
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
    window.scrollTo(0, 0); //Scroll to Top
  }
  componentWillUnmount() {
    window.removeEventListener("focus", this.reloadContract);
    window.removeEventListener("popState", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }
}

const mapStateToProps = (state) => ({
  profile: state.getIn(["headers", "profile"]),
  defaultInfo: state.getIn(["contract", "defaultInfo"]),
  timezoneList: state.getIn(["newContractGroup", "timezoneList"]),
  completionSteps: state.getIn(["contract", "completionSteps"]),
  allUsers: state.getIn(["newContractGroup", "allUsers"]),
  prefilledSeen: state.getIn(["contract", "prefilledSeen"]),
  dueDates: state.getIn(["contract", "dueDates"]),
  accountId: state.getIn(["headers", "profile", "payclaim_account_id"]),
  symbol: state.getIn(["config", "accountConfig", "currency"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});

const mapDispatchToProps = (dispatch) => ({
  readInitialInfo(proId, isAdmin) {
    dispatch(actionCreators.readDefault(proId));
    isAdmin && dispatch(actionCreatorsProject.readUsers());
  },
  readContractDetails(proId, contractId) {
    dispatch(actionCreators.readContractDetails(proId, contractId));
  },
  saveContractType(contractType) {
    dispatch(actionCreators.setData("contractType", contractType)); //save contract type
  },
  startContract(proId, type, contractId) {
    dispatch(actionCreators.startContract(proId, type, contractId));
  },
  updateContractMember(
    proId,
    contractId,
    data,
    mainContact,
    contractStatus,
    accountId,
    contractType,
  ) {
    dispatch(
      actionCreators.updateContractMember(
        proId,
        contractId,
        data,
        mainContact,
        contractStatus,
        accountId,
        contractType,
      ),
    );
  },
  updatePaymentTerms(proId, contractId, update) {
    dispatch(actionCreators.updatePaymentTerms(proId, contractId, update));
  },
  hidePrefilledTag(component) {
    dispatch(actionCreators.hidePrefilledTag(component));
  },
  calculateDueDate(proId, dueDates) {
    dispatch(actionCreators.calculateDueDate(proId, dueDates));
  },
  downloadFile(proId, contractId, fileId, fileName, isView) {
    dispatch(
      actionCreators.downloadItemFile(
        proId,
        contractId,
        fileId,
        fileName,
        isView,
      ),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
