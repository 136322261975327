import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import API from "../../../server";
import Cropper from "react-cropper"; // import Cropper
import "cropperjs/dist/cropper.css"; // import Cropper css
import { Container } from "./dialogStyle";
import {
  BlueWhiteBtn as OKbtn,
  BlueOutlinedBtn as Cancelbtn,
} from "../../button";

export default class ClassCropperModal extends Component {
  static propTypes = {
    uploadedImageFile: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      src: null,
      submitting: false,
    };
    this.cropperRef = createRef();
  }

  componentDidMount() {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const dataURL = e.target.result;
      this.setState({ src: dataURL });
    };
    fileReader.readAsDataURL(this.props.uploadedImageFile);
  }

  handleSubmit = () => {
    if (!this.state.submitting) {
      // file name
      let file = this.props.uploadedImageFile;

      /*
        There's sometimes an issue with the blob being larger 
        (in terms of file size) than the original uploaded image 
        if the image is downloaded from microsft teams. Teams automatically
        reformats image files, regardless of what they were originally, to pngs
        which I think is what's causing the problem. It's more of a teams 
        bug rather than ours but I'm just leaving this note here just 
        in case someone else gets the same issue.
      */
      if (this.cropperRef?.current?.cropper) {
        this.cropperRef?.current?.cropper
          .getCroppedCanvas()
          .toBlob(async (blob) => {
            //  Create a submit form data object
            const formData = new FormData();

            //  Add files to upload
            formData.append("avatar", blob, file.name);
            // Prompt to start uploading
            this.setState({ submitting: true });

            // upload image
            // eslint-disable-next-line no-unused-vars
            const resp = await API.upload_account_avatar(formData);

            // Get the data returned by the server (resp)
            // Prompt upload completed
            this.setState({ submitting: false });

            //Pass the selected cut picture out
            this.props.onSubmit(blob);

            // Close the popup
            this.props.onClose();
          }, file.type);
      }
    }
  };

  render() {
    return (
      <Container>
        <div className="class-cropper-modal">
          <div className="modal-panel">
            <div className="cropper-container-container">
              <div className="cropper-container">
                <Cropper
                  src={this.state.src}
                  className="cropper"
                  ref={this.cropperRef}
                  viewMode={1}
                  zoomable={true}
                  guides={false}
                  preview=".cropper-preview"
                  autoCropArea={1}
                />
              </div>
              <div className="preview-container">
                <div className="cropper-preview" />
              </div>
            </div>
            <div className="button-row">
              <OKbtn title="SUBMIT" onClick={this.handleSubmit} />
              <Cancelbtn
                title="CANCEL"
                onClick={this.props.onClose}
                margin="0 0 0 20px"
              />
            </div>
          </div>
        </div>
      </Container>
    );
  }
}
