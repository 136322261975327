import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Table } from "shineout";

import {
  CertTableWrapper,
  MaterialCertTableWrapper,
} from "./review_cert_style";
import { Sort } from "./basic_table";
import CommentList from "./comment_table";
import { currencySubunitToUnit } from "../../utils/currencyHelper";
import { toKebabCase } from "utils/stringHelper";
import profile from "../../utils/profile";
import { CONTRACT, CONTRACT_TYPE, EDIT, VIEW } from "../../utils/constants";
import { headerFormatter } from "../../utils/tableFormatHelper";
import { countComments } from "./table_utils";
import { TableContainer } from "./basic_table_style";
import { handleTableSorter } from "../../utils/helper";
import { ClickAwayListener } from "@material-ui/core";

const MaterialsTable = (props) => {
  const {
    title,
    accountConfig,
    data,
    projectData,
    isNewCert,
    isOnSite,
    updateComment,
    commentViewOnly,
    showClaimerName,
    allowEditContract,
    allowViewContract,
    isApproved,
  } = props;

  const showCurrency = !accountConfig?.get("enable_header_currency");
  const Reason = "Reason";

  const [totals, setTotals] = useState({
    claimed_total: currencySubunitToUnit(0, showCurrency),
    certified_total: currencySubunitToUnit(0, showCurrency),
    variance_total: currencySubunitToUnit(0, showCurrency),
    this_cert: currencySubunitToUnit(0, showCurrency),
  });

  useEffect(() => {
    let totalsData = { ...totals };
    let thisCert = 0;

    data?.map((item) => {
      thisCert += item.cert_value - item.cert_to_date_value;
      return null;
    });
    totalsData.claimed_total = currencySubunitToUnit(
      isOnSite
        ? projectData.get(`total_claim_material_on_site`)
        : projectData.get(`total_claim_material_off_site`, showCurrency),
    );
    totalsData.variance_total = currencySubunitToUnit(
      isOnSite
        ? projectData.get(`total_claim_material_on_site`) -
            projectData.get(`total_cert_material_on_site`)
        : projectData.get(`total_claim_material_off_site`) -
            projectData.get(`total_cert_material_off_site`),
      showCurrency,
    );
    totalsData.certified_total = currencySubunitToUnit(
      isOnSite
        ? projectData.get(`total_cert_material_on_site`)
        : projectData.get(`total_cert_material_off_site`, showCurrency),
    );
    totalsData.this_cert = currencySubunitToUnit(thisCert, showCurrency);
    setTotals(totalsData);
  }, [data]); // eslint-disable-line

  const columns_material = [
    {
      title: "Ref",
      className: "align_left lineitem-ref",
      width: 52,
      render: "label",
    },
    {
      title: "Description",
      className: "align_left",
      width: 321,
      render: "description",
    },
    {
      title: headerFormatter(
        `${accountConfig?.getIn(["claim", "value"])} Total`,
        accountConfig?.getIn(["currency", "code"]),
        accountConfig?.get("enable_header_currency"),
      ),
      render: (d) => currencySubunitToUnit(d.claim_value, showCurrency),
      width: 142,
    },
    {
      title: headerFormatter(
        "Certified Total",
        accountConfig?.getIn(["currency", "code"]),
        accountConfig?.get("enable_header_currency"),
      ),
      className: "group_header group_input",
      width: 142,
      render: (d) => currencySubunitToUnit(d.cert_value, showCurrency),
    },
    {
      title: headerFormatter(
        "Variance",
        accountConfig?.getIn(["currency", "code"]),
        accountConfig?.get("enable_header_currency"),
      ),
      render: (d) =>
        currencySubunitToUnit(d.claim_value - d.cert_value, showCurrency),
      width: 142,
    },
    {
      width: 142,
      title: headerFormatter(
        `This ${accountConfig?.getIn(["cert", "noun"])}`,
        accountConfig?.getIn(["currency", "code"]),
        accountConfig?.get("enable_header_currency"),
      ),
      render: (d) =>
        currencySubunitToUnit(
          d.cert_value - d.cert_to_date_value,
          showCurrency,
        ),
    },
    {
      group: Reason,
      className: "hidden th_align_left reason",
      width: 190,
      render: (d) => (
        <div className="inline-wrapper">
          <div className="reason-content">{d.cert_reason}</div>
        </div>
      ),
    },
    {
      group: Reason,
      className: "hidden th_align_left reviewCertComment",
      width: 44,
      render: (d) => (
        <div className="commentDiv">
          {!isNewCert
            ? (allowEditContract || allowViewContract) && (
                <CommentList
                  data={d}
                  type={"cert"}
                  expands={[]}
                  updateComment={updateComment}
                  viewOnly={commentViewOnly || !allowEditContract}
                  otherParty={
                    showClaimerName
                      ? projectData.get("payee_entity_name")
                      : projectData.get("payer_entity_name")
                  }
                />
              )
            : isApproved &&
              countComments(d, d?.id) > 0 && (
                <CommentList
                  data={d}
                  type={"cert"}
                  expands={[]}
                  updateComment={updateComment}
                  viewOnly={true}
                  otherParty={
                    showClaimerName
                      ? projectData.get("payee_entity_name")
                      : projectData.get("payer_entity_name")
                  }
                />
              )}
        </div>
      ),
    },
  ];

  return (
    <div className="materials">
      <span className="sub-title">{title}</span>
      <CertTableWrapper>
        <TableContainer className={toKebabCase(title)}>
          <Table data={data} columns={columns_material} className="table">
            <table>
              <tfoot>
                <td className="empty" />
                <td className="title">Sub Total</td>
                <td className="total">{totals.claimed_total}</td>
                <td className="total">{totals.certified_total}</td>
                <td className="total">{totals.variance_total}</td>
                <td className="total this-cert">{totals.this_cert}</td>
                <td className="empty after" />
              </tfoot>
            </table>
          </Table>
        </TableContainer>
      </CertTableWrapper>
    </div>
  );
};

const ReviewTable = (props) => {
  const {
    projectData,
    tableContent,
    updateComment,
    isNewCert,
    accountConfig,
    isApproved,
    commentViewOnly,
    contractType,
  } = props;

  const [onSite, setOnSite] = useState([]);
  const [offSite, setOffSite] = useState([]);
  const [sortOpen, setSortOpen] = useState(false);
  const [sortOption, setSortOption] = useState({
    field: "created_date",
    sep: "asc",
  });
  const sortingOptions = {
    options: {
      created_date: "Date Created",
      label: "Reference",
      description: "Description",
      claim_value: `${accountConfig?.getIn(["claim", "value"])} Value`,
      cert_value: `${accountConfig?.getIn(["cert", "value"])} Value`,
    },
    sep: {
      asc: "Ascending",
      desc: "Descending",
    },
  };

  const [permissions, setPermissions] = useState({
    editContract: true,
    viewContract: false,
  });

  const showClaimerName =
    contractType === CONTRACT_TYPE.cert ||
    contractType === CONTRACT_TYPE.selfCert;

  useEffect(() => {
    let data = tableContent?.toJS() || [];
    data = data.sort(handleTableSorter(sortOption.field, sortOption.sep));

    let onSite = [],
      offSite = [];
    data.forEach((line) => {
      if (line.on_site) {
        onSite.push(line);
      } else {
        offSite.push(line);
      }
    });

    setOnSite(onSite);
    setOffSite(offSite);
  }, [tableContent, sortOption]);

  const setSort = (option) => {
    if (sortingOptions.options[option]) {
      setSortOption({ ...sortOption, field: option });
    } else if (sortingOptions.sep[option]) {
      setSortOption({ ...sortOption, sep: option });
    }
    setOnSite(handleSort(onSite, sortOption));
    setOffSite(handleSort(offSite, sortOption));
  };

  const handleSort = (list, sortOptions) => {
    let newList = [...list];
    newList.sort(handleTableSorter(sortOptions.field, sortOptions.sep));
    return newList;
  };

  // get permissions
  useEffect(() => {
    const { proId, contractId } = props.match.params;
    profile.checkPermission(CONTRACT, EDIT, proId, contractId).then((res) => {
      setPermissions({ ...permissions, editContract: res });
    });
    profile.checkPermission(CONTRACT, VIEW, proId, contractId).then((res) => {
      setPermissions({ ...permissions, viewContract: res });
    });
  }, []); // eslint-disable-line

  return (
    <MaterialCertTableWrapper>
      <div className="header">
        <span className="title">Materials On/Off Site</span>
        <ClickAwayListener onClickAway={() => setSortOpen(false)}>
          <div>
            <Sort
              open={sortOpen}
              openDropdown={() => setSortOpen(true)}
              setSort={(e) => setSort(e.target.value)}
              sortField={sortOption.field}
              sortSep={sortOption.sep}
              sortOptions={sortingOptions}
            />
          </div>
        </ClickAwayListener>
      </div>

      {onSite.length > 0 && (
        <MaterialsTable
          title="On Site"
          data={onSite}
          projectData={projectData}
          accountConfig={accountConfig}
          isNewCert={isNewCert}
          allowEditContract={permissions.editContract}
          allowViewContract={permissions.viewContract}
          updateComment={updateComment}
          commentViewOnly={commentViewOnly}
          showClaimerName={showClaimerName}
          isApproved={isApproved}
          isOnSite
        />
      )}
      {offSite.length > 0 && (
        <MaterialsTable
          title="Off Site"
          data={offSite}
          projectData={projectData}
          accountConfig={accountConfig}
          isNewCert={isNewCert}
          allowEditContract={permissions.editContract}
          allowViewContract={permissions.viewContract}
          updateComment={updateComment}
          commentViewOnly={commentViewOnly}
          showClaimerName={showClaimerName}
          isApproved={isApproved}
        />
      )}
    </MaterialCertTableWrapper>
  );
};

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default withRouter(connect(mapStateToProps, null)(ReviewTable));
