import React from "react";
import Select from "react-select";

import DropdownIndicator from "./component/indicator";

import { palette } from "../../theme";
import buttonIcon from "../../statics/img/icon-download-white.svg";
import previewIcon from "../../statics/img/icon-preview.svg";

import { DropdownLabel } from "./styled";

// Dropdown with an icon
export const DownloadDropdown = ({ options, isPreview, labelImg, theme }) => {
  const style = {
    control: (styles) => ({
      ...styles,
      width: "236px", // min width where everything is readable
      height: "40px",
      border: theme === "primary" ? `1px solid ${palette.gunmetal}` : `none`,
      fontSize: "16px",
      fontWeight: "bold",
      boxShadow: "none",
      backgroundColor:
        theme === "primary" ? `${palette.white}` : `${palette.gunmetal}`,
    }),
    menu: (styles) => ({
      ...styles,
      width: "236px",
      marginTop: "0px",
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: "none",
    }),
    option: (styles) => ({
      ...styles,
      fontSize: "16px",
      padding: "10px 16px",
      fontWeight: "normal",
      backgroundColor: "none",
      "&:hover": { backgroundColor: palette.warmLightGrey, cursor: "pointer" },
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      paddingRight: "16px",
    }),
  };

  const img = labelImg || (isPreview ? previewIcon : buttonIcon);
  const labelText = isPreview ? "Preview" : "Download";

  return (
    <Select
      components={{ DropdownIndicator }}
      styles={style}
      isSearchable={false}
      value={{
        label: (
          <DropdownLabel buttonTheme={theme}>
            <img className="label-img" src={img} alt={`${labelText} Button`} />
            <div className="label-text">{`${labelText} PDF`}</div>
          </DropdownLabel>
        ),
      }}
      onChange={(e) => e.value()}
      options={options}
    />
  );
};
