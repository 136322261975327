import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Input from "../form";

const CountryConfig = (props) => {
  const {
    countriesList,
    accountConfig,
    value,
    className,
    width,
    hasInitialValue,
    label,
  } = props;
  const [defaultCountry, setDefaultCountry] = useState("");

  useEffect(() => {
    let accCountry =
      countriesList && (value || hasInitialValue)
        ? countriesList.find((o) => o.value === value)?.label || ""
        : accountConfig?.getIn(["country", "value"]);

    setDefaultCountry(accCountry);
  }, [countriesList, accountConfig, value, hasInitialValue]);

  return (
    <Input
      {...props}
      label={label ? "" : "Country"} // to prevent double labels when a label prop has been passed
      name="country"
      field="lock-field"
      className={className}
      width={width}
      value={defaultCountry}
    />
  );
};

const mapStateToProps = (state) => ({
  countriesList: state.getIn(["config", "countriesList"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default connect(mapStateToProps, null)(CountryConfig);
