import React from "react";
import { HistoryIcon } from "../styled";
import ToolTip from "common/toolTip";

const HistoryButton = ({ hoverMsg, action }) => (
  <>
    <ToolTip title={hoverMsg} placement={"bottom"}>
      <HistoryIcon onClick={action} />
    </ToolTip>
  </>
);

export default HistoryButton;
