import React, { Component } from "react";
import { connect } from "react-redux";
import Link from "../../../../common/linkWithQuery";
import { Table } from "shineout";

import { BgWrapper, HeaderTitle } from "../../../../common/shared_style";
import {
  Subtitle,
  InfoItem,
  PlanInfo,
  BillInfo,
  PaymentInfo,
  PaymentDetail,
  SearchInput,
  ErrorMeg,
} from "./style";

import Menu from "../../../../common/breadcrumbs";
import {
  BlueWhiteBtn as Update,
  BlueOutlinedBtn as View,
} from "../../../../common/button";
import PageBarPart from "../../../../common/pagination";
import Input from "../../../../common/form";
import Loading from "../../../../common/loading";

import { actionCreators } from "../../store";

const ErrorIndicator = ({ info }) => {
  const content = {
    past_due: {
      title: "Past Due",
      meg: (
        <>
          The last payment has failed to go through. Please go to “Update Plan &
          Payment” to check if your payment details are correct.
          <br />
          There will be another payment attempt…
        </>
      ),
      errorType: "warning",
    },
    error: {
      title: "Payments Failed",
      meg: "There may be an issue with your payment details.. Please go to “Update Plan & Payment” to check if your payment details are correct.",
      errorType: "error",
    },
  };
  if (
    info.get("status") === "active" ||
    info.get("status") === "not_registed"
  ) {
    return null;
  } else {
    let status = info.get("status") === "past_due" ? "past_due" : "error";
    return (
      <ErrorMeg errorType={content[status]["errorType"]}>
        <div className="warningIcon" />
        <div>
          <div className="warningTitle">{content[status]["title"]}</div>
          <div>{content[status]["meg"]} </div>
        </div>
      </ErrorMeg>
    );
  }
};

class PlanPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      limit: 10,
      searchInput: "",
      filteredData: props.billHistory,
      yearSel: "",
      yearOpt: [],
    };
  }

  handlePageChange = (page) => {
    //pagination function to change page
    this.setState({
      currentPage: page,
    });
  };
  handleSetDataNum = (data) => {
    //pagination function to set how many rows displaying per page
    return this.setState(
      {
        limit: data,
      },
      () => this.handlePageChange(1),
    );
  };

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () =>
      this.handleFilter(this.state.list, this.props.data),
    );
  };
  handleFilter = () => {
    let { searchInput, yearSel } = this.state;
    let filteredData = this.props.billHistory.filter((value) => {
      return (
        value.get("number").toLowerCase().includes(searchInput.toLowerCase()) &&
        value.get("create_date").toLowerCase().includes(yearSel)
      );
    });
    return this.setState({ filteredData });
  };
  render() {
    const { currentPage, limit, filteredData, searchInput, yearOpt, yearSel } =
      this.state;
    const { billHistory, planDetail, loadingPlanInfo, accountConfig } =
      this.props;

    const currency = accountConfig?.getIn(["currency", "title"]);

    var link = [{ link: "/manage-account/view", text: "Manage Account" }];
    const columns = [
      {
        title: "Invoice Number",
        render: "number",
        width: 338,
      },
      {
        title: "Pay Period",
        render: "create_date",
        width: 312,
      },
      {
        title: "Amount",
        render: (d) => `${currency}${d.amount}`,
        width: 287,
      },
      {
        title: "PDF",
        render: (d) => (
          <a href={d.pdf}>
            <div className="pdf" />
          </a>
        ),
        width: 126,
      },
    ];
    let infoToShow = {
      period: "",
      date: "",
      plan: "",
      detail: "",
    };
    if (
      planDetail.get("current_plan") &&
      planDetail.getIn(["current_plan", "price_interval"]) !== ""
    ) {
      infoToShow = {
        period:
          planDetail.getIn(["current_plan", "price_interval"]).toLowerCase() +
          "ly",
        date: planDetail.get("current_period_end"),
        plan:
          "The " +
          planDetail.getIn(["current_plan", "product_name"]) +
          "  Toolbox",
        detail:
          planDetail.getIn(["current_plan", "price_currency"]) +
          ` ${currency}` +
          planDetail.getIn(["current_plan", "price_decimal"]),
      };
    }

    return (
      <BgWrapper>
        <Menu link={link} content="Plan & Payment" />
        <HeaderTitle>Plan & Payment</HeaderTitle>
        {loadingPlanInfo ? (
          <Loading />
        ) : (
          <PlanInfo>
            <BillInfo>
              <Subtitle>Billing Overview</Subtitle>
              <div>
                <InfoItem className="sub">
                  <div className="title">Billing Period</div>
                  <div className="content">{infoToShow.period}</div>
                </InfoItem>
                <InfoItem className="sub">
                  <div className="title">Next Renewal</div>
                  <div className="content">{infoToShow.date}</div>
                </InfoItem>
              </div>
              <InfoItem className="middle">
                <div className="title main">Current Plan</div>
                <div className="contentWrapper">
                  <div className="label">
                    <div>
                      <b>{infoToShow.plan}</b>
                    </div>
                    <div> {infoToShow.detail}</div>
                  </div>
                  <div>
                    <Update
                      title="Update Plan & Payment"
                      padding="16px 24px"
                      height="48px"
                      onClick={() =>
                        this.props.getCustomerPortal(
                          planDetail.get("session_id"),
                        )
                      }
                    />

                    <Link
                      to={{
                        pathname: `/manage-account/plan-price`,
                        state: { noPermission: false },
                      }}
                    >
                      <View
                        title="View Pricing"
                        padding="16px 64px"
                        height="48px"
                        margin="0 30px 0 0"
                      />
                    </Link>
                  </div>
                </div>
                <ErrorIndicator info={planDetail} />
              </InfoItem>
            </BillInfo>
            {billHistory.size !== 0 && (
              <PaymentInfo>
                <PaymentDetail>
                  <Subtitle> Billing History</Subtitle>
                  <div>
                    <SearchInput>
                      <input
                        name="searchInput"
                        value={searchInput}
                        onChange={this.handleChange}
                        placeholder={"search"}
                      />
                      <div className="searchIcon" />
                    </SearchInput>
                    <Input
                      field="dropdown"
                      width="113px"
                      placeholder=""
                      options={yearOpt}
                      value={yearSel}
                      onChange={(e) => {
                        this.setState(
                          { yearSel: e.value },
                          this.handleFilter(),
                        );
                      }}
                    />
                  </div>
                </PaymentDetail>
                <Table
                  keygen="id"
                  columns={columns}
                  data={filteredData.toJS()}
                />
                <PageBarPart
                  total={filteredData.size}
                  limit={limit}
                  pageCount={5}
                  currentPage={currentPage}
                  handlePageChange={this.handlePageChange}
                  handleSetData={this.handleSetDataNum}
                  field="big"
                />
              </PaymentInfo>
            )}
          </PlanInfo>
        )}
      </BgWrapper>
    );
  }

  componentDidMount() {
    if (this.props.match.params.status) {
      let param = this.props.match.params.status;
      if (param === "canceled") {
        this.props.showNoticeBoard("canceled", "");
      } else if (param === "accept") {
        let id = new URLSearchParams(this.props.location.search).get(
          "sessionId",
        );
        this.props.saveCheckoutSession(id);
        this.props.showNoticeBoard("accept", id);
      }
    }
    this.props.getPaymentInfo();
  }
  componentWillUnmount() {
    this.props.reset();
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.billHistory !== nextProps.billHistory) {
      let yearList = [];
      nextProps.billHistory.forEach((item) => {
        let year = new Date(item.get("create_date")).getFullYear();
        if (yearList.indexOf(year) === -1) {
          yearList.push(year);
        }
      });
      let yearSel = Math.max.apply(null, yearList);
      let yearOpt = [];
      yearList.map((item, index) => {
        return yearOpt.push({
          label: item,
          value: item,
        });
      });
      this.setState({
        filteredData: nextProps.billHistory,
        yearOpt: yearOpt,
        yearSel: yearSel,
      });
    }
    return true;
  }
}

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
  billHistory: state.getIn(["manageAccount", "billHistory"]),
  planDetail: state.getIn(["manageAccount", "planDetail"]),
  loadingPlanInfo: state.getIn(["manageAccount", "loadingPlanInfo"]),
});

const mapDispatchToProps = (dispatch) => ({
  getPaymentInfo() {
    dispatch(actionCreators.getPlanOverview());
    dispatch(actionCreators.getBillHistory());
  },
  getCustomerPortal(sessionId) {
    dispatch(actionCreators.getCustomerPortal(sessionId));
  },
  showNoticeBoard(status, sessionId) {
    dispatch(actionCreators.showNoticeBoard(status, sessionId));
  },
  reset() {
    dispatch(actionCreators.reset());
  },
  saveCheckoutSession(id) {
    dispatch(actionCreators.saveCheckoutSession(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanPayment);
