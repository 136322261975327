import * as constants from "./constants";
import API from "../../../server";

export const resetPageStatus = () => ({
  type: constants.RESET_PAGE_STATUS,
});

const setAllProjectList = (projects) => ({
  //return all project list
  type: constants.SET_ALL_PROJECT_LIST,
  payload: projects,
});

const setData = (name, value) => ({
  type: constants.SET_DATA,
  name,
  value,
});

export const getAllProjectList = (type) => {
  return (dispatch, getState) => {
    let accountList = getState().getIn(["headers", "accountFilterList"]).toJS();
    let is_gst_include = getState().getIn(["headers", "is_gst_include"]);
    dispatch(setData("loading", true));
    if (accountList.length !== 0) {
      let dataSet = { list: [], search_option: {} };
      accountList.map((item) => dataSet.list.push(item.value));
      dataSet.search_option.is_gst_include = is_gst_include;
      API.read_all_claims_received_multi(dataSet)
        .then((res) => res.data)
        .then((res) => res.data.certlist)
        .then((res) => {
          dispatch(setAllProjectList(res));
          dispatch(chooseProjectType(type));
        });
    } else {
      let params = `is_gst_include=${is_gst_include}`;
      API.read_all_claims_received(params)
        .then((res) => res.data)
        .then((res) => res.data.certlist)
        .then((res) => {
          dispatch(setAllProjectList(res));
          dispatch(chooseProjectType(type));
        });
    }
  };
};

export const chooseProjectType = (value) => ({
  type: constants.SET_PROJECT_TYPE,
  payload: value,
});
export const setDate = (date) => ({
  type: constants.SET_DATE,
  payload: date,
});
const certSummary = (result) => ({
  type: constants.SET_CERT_SUMMARY,
  payload: result,
});

export const getSummary = (date) => {
  return (dispatch, getState) => {
    let accountList = getState().getIn(["headers", "accountFilterList"]).toJS();
    let is_gst_include = getState().getIn(["headers", "is_gst_include"]);

    if (accountList.length !== 0) {
      let dataSet = { list: [], search_option: {} };
      accountList.map((item) => dataSet.list.push(item.value));
      dataSet.search_option.is_gst_include = is_gst_include;
      dataSet.cert_date = date;
      dataSet.is_all = false;
      API.read_cert_summary_multi(dataSet)
        .then((res) => res.data)
        .then((res) => res.data.data)
        .then((res) => dispatch(certSummary(res)));
    } else {
      let params = `is_gst_include=${is_gst_include}`;
      API.read_cert_summary(
        {
          cert_date: date,
          is_all: false,
        },
        params,
      )
        .then((res) => res.data)
        .then((res) => res.data.data)
        .then((res) => dispatch(certSummary(res)));
    }
  };
};
