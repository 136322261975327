import React from "react";
import { TitleWrap, LineWrap } from "../styled";
import { CustomizeSkeleton } from "./base";

const TextLine = ({ theme }) => (
  <TitleWrap>
    <CustomizeSkeleton containerClassName="title-skeleton" theme={theme} />
  </TitleWrap>
);

const Line = ({ theme, width }) => (
  <LineWrap width={width}>
    <CustomizeSkeleton containerClassName="title-skeleton" theme={theme} />
  </LineWrap>
);

export default {
  TextLine,
  Line,
};
