import * as constants from "./constants";
import API from "../../../server";
export const setInfo = (
  project,
  cert,
  retention,
  taskList,
  systemNotice,
  claimsReceived,
  claim,
  groupTable,
) => ({
  type: constants.SET_INITIAL_DASHBOARD,
  project,
  cert,
  retention,
  taskList,
  systemNotice,
  claimsReceived,
  claim,
  groupTable,
});

export const getAllDashboard = () => {
  return (dispatch, getState) => {
    let is_gst_include = getState().getIn(["headers", "is_gst_include"]),
      account_id = getState().getIn([
        "headers",
        "profile",
        "payclaim_account_id",
      ]),
      claimsReceivedType = getState().getIn([
        "dashboards",
        "claimsReceivedType",
      ]),
      period = getState().getIn(["dashboards", "aggPeriod"]);

    let promiseArray = [],
      params = new URLSearchParams();
    params.append("is_gst_include", is_gst_include);
    params.append("account_id", account_id);
    promiseArray = [
      API.read_projects_summary(params),
      API.read_upcoming_cert_summary(params),
      API.read_retention_summary(params),
      API.read_task_list(params),
      API.read_system_notice(48),
      API.read_claims_summary({ is_gst_include, month: claimsReceivedType }),
      API.read_active_projects(params),
    ];

    let tableSearchParams = new URLSearchParams(params);
    tableSearchParams.append("period", period);
    promiseArray = [
      ...promiseArray,
      API.read_certifier_group_table(tableSearchParams),
    ];
    dispatch(setData("loading", true));

    Promise.all(promiseArray)
      .then((resArr) => resArr.map((res) => res.data))
      .then((data) => {
        dispatch(
          setInfo(
            data[0].data.contractlist,
            data[1].data.result,
            data[2].data.result,
            data[3].data.data,
            data[4].data.events,
            data[5].data.result,
            data[6].data.result,
            data[7].data.data,
          ),
        );
      });
  };
};

export const resetStatus = () => ({
  type: constants.RESET_STATUS,
});

export const chooseProjectType = (name, value) => ({
  type: constants.SET_PROJECT_TYPE_OPTION,
  name,
  value,
});

const chooseClaimsReceivedType = (name, value, claimsReceivedSummary) => ({
  type: constants.SET_CLAIMS_RECEIVED_TYPE_OPTION,
  name,
  value,
  claimsReceived: claimsReceivedSummary,
});

export const chooseMonth = (name, value) => {
  return (dispatch, getState) => {
    let accountList = getState().getIn(["headers", "accountFilterList"]).toJS();
    let is_gst_include = getState().getIn(["headers", "is_gst_include"]);
    if (accountList.length !== 0) {
      let dataSet = { list: [], search_option: {} };
      accountList.map((item) => dataSet.list.push(item.value));
      dataSet.search_option.is_gst_include = is_gst_include;
      dataSet.month = value;
      API.read_claims_received_summary_multi(dataSet)
        .then((res) => res.data)
        .then((result) => result.data.result)
        .then((result) =>
          dispatch(chooseClaimsReceivedType(name, value, result)),
        );
    } else {
      API.read_claims_summary({ is_gst_include, month: value })
        .then((res) => res.data)
        .then((result) => result.data.result)
        .then((result) =>
          dispatch(chooseClaimsReceivedType(name, value, result)),
        );
    }
  };
};

export const setData = (name, value) => ({
  type: constants.SET_DATA,
  name,
  value,
});

export const readClaimCertList = (data) => {
  return (dispatch, getState) => {
    let accountList = getState().getIn(["headers", "accountFilterList"]).toJS();
    let is_gst_include = getState().getIn(["headers", "is_gst_include"]);
    dispatch(setData("loadingList", true));
    if (accountList.length !== 0) {
      let dataSet = { list: [], search_option: {} };
      accountList.map((item) => dataSet.list.push(item.value));
      dataSet.search_option = { ...data };
      dataSet.search_option.is_gst_include = is_gst_include;

      API.read_cert_claim_list_multi(dataSet)
        .then((res) => res.data)
        .then((result) => result.data.data)
        .then((result) => {
          dispatch(setData("claimCertList", result || []));
          dispatch(setData("loadingList", false));
        });
    } else {
      data.is_gst_include = is_gst_include;
      API.read_cert_claim_list(data)
        .then((res) => res.data)
        .then((result) => result.data.data)
        .then((result) => {
          dispatch(setData("claimCertList", result || []));
          dispatch(setData("loadingList", false));
        });
    }
  };
};

export const readConfig = () => {
  return (dispatch) => {
    API.read_cc_config()
      .then((res) => res.data)
      .then((result) => result.data.config)
      .then((result) => dispatch(setData("config", result)));
  };
};

export const readGroupTable = (period) => {
  return (dispatch, getState) => {
    let is_gst_include = getState().getIn(["headers", "is_gst_include"]),
      account_id = getState().getIn([
        "headers",
        "profile",
        "payclaim_account_id",
      ]);

    let params = new URLSearchParams();
    params.append("is_gst_include", is_gst_include);
    params.append("period", period);
    params.append("account_id", account_id);
    dispatch(setData("aggPeriod", period));

    API.read_certifier_group_table(params)
      .then((res) => res.data)
      .then((res) => {
        dispatch({
          type: constants.SET_GROUP_TABLE,
          payload: res.data.data,
        });
      });
  };
};

export const pinGroup = (groupId, value, period) => {
  return (dispatch) => {
    API.update_project_group_pin(groupId, { is_pinned: value })
      .then((res) => res.data)
      .then((res) => {
        dispatch(readGroupTable(period));
      });
  };
};

export const setGroupExpandKeys = (keys) => ({
  type: constants.SET_AGG_TABLE_EXPAND_KEY,
  name: "expandKeys",
  value: keys,
});
