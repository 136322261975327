import React, { Component } from "react";
import { connect } from "react-redux";
import { actionCreators } from "../dashboard/store";
import Table from "./components/table";
import SearchBar from "./components/searchBar";
import { actionCreators as actionCreatorHeader } from "../../common/header/store";
import { BgWrapper, HeaderTitle } from "../../common/shared_style";
import { Wrapper } from "./styled";
// import Tour from "../../common/tour"; -- pl-568
import FilterBar from "../../common/select_multi_accounts";
import { format } from "date-fns";
import SwitchGst from "../../common/switch/switch_include_gst";

function setOptions(data) {
  let optList = {},
    config = data.toJS();
  if (data.get("all")) {
    let keys = Object.keys(config);
    keys.forEach(function (item) {
      let a = [];
      Object.keys(config[item]).map((key) =>
        a.push({
          label: config[item][key],
          value: key,
        }),
      );
      optList[item + "_options"] = a;
    });

    let type_status_option = optList["cert_options"].concat(
      optList["claim_options"],
    );
    optList["all_options"] = [];
    for (var item1 of type_status_option) {
      let flag = true;
      for (var item2 of optList["all_options"]) {
        if (item1.value === item2.value) {
          flag = false;
        }
      }
      if (flag) {
        optList["all_options"].push(item1);
      }
    }
  }
  return optList;
}

const defaultState = {
  contract_status: "all",
  type: "all",
  type_status: "all",
  date: "createddate",
  start_date: "",
  end_date: "",
};
class ClaimCert extends Component {
  constructor() {
    super();
    this.state = {
      option: {
        contract_status: "all",
        type: "all",
        type_status: "all",
        date: "createddate",
        start_date: "",
        end_date: "",
      },
    };
    this.selectOption = this.selectOption.bind(this);
    this.clear = this.clear.bind(this);
    this.search = this.search.bind(this);
  }

  selectOption = (name, value) => {
    let data = value;
    if (name === "start_date" || name === "end_date") {
      if (!value) {
        data = "";
      } else if (value.toString() === "Invalid Date") {
        return;
      } else {
        data = format(new Date(value), "yyyy-MM-dd");
      }
    }
    const obj = Object.assign({}, this.state.option, {
      [name]: data,
    });
    return this.setState({
      option: obj,
    });
  };

  clear = () => {
    this.props.getAllInfo(defaultState);
    return this.setState({
      option: defaultState,
    });
  };

  search = () => {
    this.props.getAllInfo(this.state.option);
  };

  render() {
    const { config, getAllInfo /*, firstLogin, showSetupList -- PL-568*/ } =
      this.props;
    const { option } = this.state;
    let optList = setOptions(config);
    return (
      <>
        {/* {firstLogin && <Tour field="CCS" showSetupList={showSetupList}/>} -- pl-568*/}
        <BgWrapper className="big" style={{ position: "relative" }}>
          <FilterBar applyFilter={() => this.props.getAllInfo(option)} />
          <HeaderTitle>
            Claims & Certs
            <SwitchGst apply={() => this.props.getAllInfo(option)} />
          </HeaderTitle>
          <Wrapper>
            <SearchBar
              optList={optList}
              getAllInfo={getAllInfo}
              initialValue={this.props.location.state}
              option={option}
              clear={this.clear}
              search={this.search}
              selectOption={this.selectOption}
            />
            <hr />
            <Table {...this.props} />
          </Wrapper>
        </BgWrapper>
      </>
    );
  }
  componentDidMount() {
    if (this.props.location.state) {
      this.props.getAllInfo(this.props.location.state);
      this.setState({ option: this.props.location.state });
    } else {
      this.props.getAllInfo("all");
    }
    this.props.getDropdownList();
  }
}

const mapStateToProps = (state) => ({
  firstLogin: state.getIn(["headers", "firstLogin"]),
  list: state.getIn(["dashboards", "claimCertList"]),
  config: state.getIn(["dashboards", "config"]),
  loading: state.getIn(["dashboards", "loadingList"]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAllInfo(data) {
      if (data === "all") {
        data = defaultState;
      }
      dispatch(actionCreators.readClaimCertList(data));
    },
    getDropdownList() {
      dispatch(actionCreators.readConfig());
    },
    showSetupList(triggerClose) {
      dispatch(actionCreatorHeader.getSetupProcess(triggerClose));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ClaimCert);
