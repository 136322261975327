import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  ProjectNavList,
  AttachmentList,
  ReviewWrapper,
  AttachmentWrapper,
  NoFileWrapper,
  FileWrapper,
  FileDetail,
} from "./styled";
import { FormGroup } from "../form/styled";

import Input from "../form";
import { IconDropdown } from "../dropdown/icon_dropdown";
import {
  GreenWhiteBtn as SaveBtn,
  BlueOutlinedBtn as CancelBtn,
} from "../button";
import { bytesToSize } from "../../utils/helper";
import UploadingFile from "./components/attachments";

const FileItem = ({ item, isEditable, readOnly, shareFile, downloadFile }) => (
  <li>
    <FileDetail className="attachment">
      <div className="left">
        <div className="fileImg attachment" />
        <div className="fileName adjust_size">{item.get("file_name")}</div>
        <div className="size">({bytesToSize(item.get("file_size"))})</div>
      </div>
      <div className="right">
        {/* if file belongs to owner, allow to modify share or not*/}
        {isEditable && (
          <div className="share_file_wrapper">
            <input
              checked={item.get("is_shared")}
              type="checkbox"
              className="checkBox"
              onChange={(e) => shareFile(item.get("file_id"), e)}
              id={item.get("file_id")}
              disabled={readOnly}
            />
            <label htmlFor={item.get("file_id")} disabled={readOnly}>
              Share externally
            </label>
          </div>
        )}
        {/* if file is a pdf, use the icon dropdown */}
        {item.get("file_name").includes("pdf") ? (
          <div className="dropdown-spacer">
            <IconDropdown
              options={[
                {
                  label: "View file in new tab",
                  value: () =>
                    downloadFile(
                      item.get("file_id"),
                      item.get("file_name"),
                      item.get("token"),
                      item.get("account_id"),
                      false,
                    ),
                },
                {
                  label: "Save file to your device",
                  value: () =>
                    downloadFile(
                      item.get("file_id"),
                      item.get("file_name"),
                      item.get("token"),
                      item.get("account_id"),
                      true,
                    ),
                },
              ]}
            />
          </div>
        ) : (
          <button
            className="delFileImg downloadFileImg"
            value={item.get("file_name")}
            onClick={() =>
              downloadFile(
                item.get("file_id"),
                item.get("file_name"),
                item.get("token"),
                item.get("account_id"),
                true,
              )
            }
          />
        )}
      </div>
    </FileDetail>
  </li>
);

const SharedFileSection = ({ sharedFiles, ...otherProps }) => (
  <div className="uploadFileWrapper">
    <FormGroup>
      <Input field="child" label="Files shared with you">
        <FileWrapper>
          {sharedFiles && sharedFiles.size !== 0 ? (
            sharedFiles.map((item) => (
              <FileItem
                key={item.get("file_id")}
                item={item}
                isEditable={false}
                {...otherProps}
              />
            ))
          ) : (
            <NoFileWrapper>No files uploaded</NoFileWrapper>
          )}
        </FileWrapper>
      </Input>
    </FormGroup>
  </div>
);

class index extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
  }

  deleteFile = (fileId) => {
    this.props.deleteFile(fileId);
  };

  cancel = () => {
    this.props.readInfo();
    this.setState({ editing: false });
  };

  shareFile = (fileId, event) => {
    let isAdd = event.target.checked;
    this.props.changeSharedFiles(fileId, isAdd);
  };

  render() {
    const { editing } = this.state;
    const {
      title,
      data,
      sharedFiles,
      hasSharedFiles,

      displayOnly,
      readOnly,
      otherPartyName,
      contractInfo,
      accountConfig,
      noMargin,

      downloadFile,
      uploadFileApi,
    } = this.props;

    if (displayOnly) {
      return (
        <ReviewWrapper className={noMargin ? "" : "hasMargin"}>
          <ProjectNavList>Documents from {otherPartyName}</ProjectNavList>
          <AttachmentList>
            <AttachmentWrapper>
              <SharedFileSection
                sharedFiles={sharedFiles}
                readOnly={readOnly}
                downloadFile={downloadFile}
                shareFile={this.shareFile}
              />
            </AttachmentWrapper>
          </AttachmentList>
        </ReviewWrapper>
      );
    } else {
      return (
        <ReviewWrapper>
          <ProjectNavList>
            Additional Documents{" "}
            {!readOnly && (
              <div
                className={!editing ? "imgEdit" : "imgEdit inactive"}
                onClick={() => this.setState({ editing: true })}
              />
            )}
          </ProjectNavList>
          <ProjectNavList className="comment">
            Attach any documents here that relate to your{" "}
            {title.includes("Claim")
              ? accountConfig?.getIn(["claim", "noun"])
              : title}{" "}
            to ensure all information is kept in one place. These can be visible
            both internally or externally.
          </ProjectNavList>

          {contractInfo?.get("is_bcti") && (
            <div className="sub-wrapper flex-row">
              Certifier will provide {accountConfig?.getIn(["bcti", "title"])}{" "}
              with the {accountConfig?.getIn(["cert", "noun"])}. You do not need
              to include an invoice with your{" "}
              {accountConfig?.getIn(["claim", "noun"])?.toLowerCase()}.
            </div>
          )}

          <AttachmentList>
            {editing && (
              <AttachmentWrapper>
                <div className="title">Upload documents to attach</div>
                <div className="uploadFileWrapper">
                  <UploadingFile
                    uploadFileApi={uploadFileApi}
                    uploadedFiles={data}
                    // uploadFileToDB={(files) => this.uploadFiles(files)} //upload file
                    deleteFileFromDB={(fileId) => this.deleteFile(fileId)}
                    // setFileList={(files) => this.setFileList(files)}
                  />
                </div>
                <div className="button_wrapper">
                  <SaveBtn
                    title="Done"
                    margin={"0 0 0 30px"}
                    onClick={() => this.cancel()}
                  />
                  <CancelBtn title="Cancel" onClick={() => this.cancel()} />
                </div>
              </AttachmentWrapper>
            )}

            {!editing && (
              <AttachmentWrapper>
                <div className="uploadFileWrapper">
                  <FormGroup>
                    <Input field="child" label="Attachments">
                      <FileWrapper>
                        {data && data.size !== 0 ? (
                          data.map((item) => (
                            <FileItem
                              key={item.get("file_id")}
                              item={item}
                              isEditable={true}
                              readOnly={readOnly}
                              downloadFile={downloadFile}
                              shareFile={this.shareFile}
                            />
                          ))
                        ) : (
                          <NoFileWrapper>No files uploaded</NoFileWrapper>
                        )}
                      </FileWrapper>
                    </Input>
                  </FormGroup>
                </div>
              </AttachmentWrapper>
            )}

            {!editing && hasSharedFiles && (
              <AttachmentWrapper className="hasMargin">
                <SharedFileSection
                  sharedFiles={sharedFiles}
                  readOnly={readOnly}
                  downloadFile={downloadFile}
                  shareFile={this.shareFile}
                />
              </AttachmentWrapper>
            )}
          </AttachmentList>
        </ReviewWrapper>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default connect(mapStateToProps, null)(index);
