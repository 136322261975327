export function getCertDueType(certDueDay, unclaimedDueDay) {
  if (unclaimedDueDay >= 30) {
    return "unclaimed_days";
  }
  if (certDueDay < 0) {
    return "over_due";
  }
  if (certDueDay === 0 || certDueDay > 7 || certDueDay === "") {
    return "";
  }
  if (certDueDay <= 3) {
    return "due_in_3_days";
  } else {
    return "due_in_7_days";
  }
}
