import {
  ADMIN_ROLE,
  NONE_ROLE,
  OWNER_ROLE,
  VIEW_ROLE,
  CREATE,
  EDIT,
  ACCESS,
  CLAIM_CERT,
  CONTACT,
  ACCOUNT,
  PROJECT,
  CONTRACT,
} from "./constants";
import API from "../server";

let _account = null;

const PERMISSIONS = {
  [ACCOUNT]: {
    [ACCESS]: "isAdminOrOwner",
    [EDIT]: "isOwner",
  },
  [CONTACT]: {
    [CREATE]: "isAdminOrOwner",
    [EDIT]: "isAdminOrOwner",
  },
  [PROJECT]: {
    [CREATE]: "isAdminOrOwner",
    [EDIT]: ({ accId, proId }) => {
      return API.read_account_project_role(accId, proId)
        .then((res) => res.data.data)
        .then((res) => {
          return [ADMIN_ROLE, OWNER_ROLE].includes(res.role);
        });
    },
  },
  [CONTRACT]: {
    [CREATE]: ({ accId, proId }) => {
      return API.read_account_project_role(accId, proId)
        .then((res) => res.data.data)
        .then((res) => {
          return [ADMIN_ROLE, OWNER_ROLE].includes(res.role);
        });
    },
    [EDIT]: ({ accId, proId, contractId }) => {
      return API.read_account_contract_role(accId, proId, contractId)
        .then((res) => res.data.data)
        .then((res) => {
          return [ADMIN_ROLE, OWNER_ROLE].includes(res.role);
        });
    },
    [CLAIM_CERT]: ({ accId, proId, contractId }) => {
      return API.read_account_contract_role(accId, proId, contractId)
        .then((res) => res.data.data)
        .then((res) => {
          return [ADMIN_ROLE, OWNER_ROLE].includes(res.role);
        });
    },
  },
};

export default {
  ..._account,
  //account level
  setAccount(account) {
    _account = account;
    Object.assign(this, _account);
  },

  isAdmin() {
    return this?.role_key === ADMIN_ROLE;
  },

  isOwner() {
    return this?.role_key === OWNER_ROLE;
  },

  isViewer() {
    return this?.role_key === VIEW_ROLE;
  },

  isNone() {
    return this?.role_key === NONE_ROLE;
  },

  isAdminOrOwner() {
    return this.isAdmin() || this.isOwner();
  },

  isFullPermission() {
    return true;
  },

  checkPermission(page, action, proId, contractId) {
    const permissions = PERMISSIONS[page];
    const accId = this.account_id;
    if (!permissions || !permissions[action]) {
      return false;
    }
    const check = permissions[action];

    if (typeof check === "function") {
      return check({ accId, proId, contractId });
    }
    return this[check]();
  },
};
