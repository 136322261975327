import { httpRequest } from "../base/httpRequest";

const ACCOUNT_URL = `/api/account/accId`;

const read_account_user = (type, status, accountId) =>
  httpRequest(
    "get",
    accountId
      ? `/api/account/${accountId}/users?type=${type}&status=${status}`
      : `${ACCOUNT_URL}/users?type=${type}&status=${status}`,
  );
const read_account_detail = () => httpRequest("get", `${ACCOUNT_URL}`);
const read_account_config = () => httpRequest("get", `${ACCOUNT_URL}/config`);
const update_account_detail = (data) =>
  httpRequest("patch", `${ACCOUNT_URL}`, data);
const update_user_role = (data) =>
  httpRequest("put", `${ACCOUNT_URL}/update_account_user`, data);
const report_error = (data) =>
  httpRequest("post", `${ACCOUNT_URL}/report_error`, data);

export default {
  read_account_user,
  read_account_detail,
  read_account_config,
  update_account_detail,
  update_user_role,
  report_error,
};
