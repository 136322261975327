import React from "react";
import { Field, reduxForm } from "redux-form/immutable";

import { DetailBox, FormWrapper } from "../../../users/add/style";

import PhoneInput from "../../../../../../common/form/telephone";
import RenderField from "../../../../component/inputField";
import NavBottom from "../../../../component/navBottom";
import validate from "./validate";

const Form = (props) => {
  const { handleSubmit, reset } = props;
  return (
    <form onSubmit={handleSubmit}>
      <FormWrapper>
        <DetailBox>
          <div className="content profileWrapper">
            <Field
              name="name"
              type="text"
              component={RenderField}
              label="Full Name"
            />
            <Field
              name="role"
              type="text"
              component={RenderField}
              label="Role"
              isReadOnly
            />
            <div>
              <div className="label">Phone</div>
              <Field
                name="phone"
                type="text"
                component={PhoneInput}
                label="Phone"
              />
            </div>
            <Field
              name="email"
              type="email"
              component={RenderField}
              label="Email Address"
              isReadOnly
            />
          </div>
        </DetailBox>
        <NavBottom reset={reset} link="/manage-account/profile" />
      </FormWrapper>
    </form>
  );
};

export default reduxForm({
  form: "ProfileForm", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(Form);
