import styled from "styled-components";
import * as theme from "../../theme";
import rightIcon from "../../statics/img/icon-chevron-right-medium-grey.svg";
import yesIcon from "../../statics/img/icon-check-circle-green.svg";
import personIcon from "../../statics/img/icon-group-person.svg";
import requiredIcon from "../../statics/img/icon-error-yellow.svg";
import avatar from "../../statics/img/avatar.svg";
import deleteIcon from "../../statics/img/icon-delete-medium-grey.svg";
import uploadCloudIcon from "../../statics/img/icon-upload-cloud-light-grey.svg";
import fileItemIcon from "../../statics/img/icon-tick-green.svg";
import attachIcon from "../../statics/img/icon-file-pin-light-grey.svg";
import acceptInviteIcon from "../../statics/img/accept-invite.svg";
import editPencilIcon from "../../statics/img/icon-edit-medium-grey.svg";
import warningIcon from "../../statics/img/icon-information-red.svg";
import payLabLogo from "../../statics/img/logo-pay-lab.svg";
import MagnifyingIcon from "../../statics/img/icon-search-dark-grey.svg";
import helpIcon from "../../statics/img/icon-information-black.svg";
import { SettingSection, ProjectDetailWrapper } from "../project/styled";

export const SettingWrapper = styled(SettingSection)`
  border: ${(props) =>
    props.border && props.border !== "" ? props.border : "inherit"};
  .setting-info {
    &.grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 4px;
      margin-top: 16px;
    }
    &.retention-info {
      display: flex;
      justify-content: space-between;
    }
  }
  .margin-left {
    margin-left: 10px;
  }
  .sub-header {
    font-size: 20px;
    font-weight: bold;
    &.margin-top {
      margin-top: 40px;
    }
  }
  .no-margin {
    margin: 0;
  }
  .edit-button {
    width: 92px;
    min-width: auto;
  }
  .margin-top {
    margin-top: 16px;
  }
  .view-button {
    width: 138px;
    min-width: auto;
    margin: 0 0 32px 0;
  }
  border: none;
`;

export const ContractDetailWrapper = styled(ProjectDetailWrapper)`
  margin-top: ${(props) => (props.HasMarginTop ? "-220px" : 0)};
  &.required {
    border-left: 3px solid ${theme.palette.tangerineOrange};
  }
  &.expand-table {
    width: 1360px;
  }
  &#nav-tax {
    z-index: 100;
  }
`;

export const CompleteWrap = styled.div`
  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
  }
  .sub-title{
    margin: 24px 0 26px;
    line-height: 20px;
  }
  width: 100%;
  display: grid;
  grid-template-columns: 160px 1fr;
  grid-column-gap: 80px;


  .circle-wrap {
    width: 150px;
    height: 150px;
    background-color: #fef2ce;
    border-radius: 50%;
    border: 1px solid ${theme.palette.paleYellow};
  }
  .circle-wrap .circle .mask,
  .circle-wrap .circle .fill {
    width: 150px;
    height: 150px;
    position: absolute;
    border-radius: 50%;
  }

  .circle-wrap .circle .mask {
    clip: rect(0px, 150px, 150px, 75px);
  }
  .mask .fill {
    clip: rect(0px, 75px, 150px, 0px);
    background-color: ${theme.palette.tangerineOrange};
  }

  .mask.full,
  .circle .fill {
    transform: ${(props) => `rotate(${props.completed}deg)`};
  }

  .circle-wrap .inside-circle {
    width: 122px;
    height: 122px;
    border-radius: 50%;
    background-color: ${theme.palette.white}
    text-align: center;
    position: absolute;
    font-weight: bold;
    margin-top: 14px;
    margin-left: 14px;

    font-size: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .sub-label{
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: ${theme.palette.veryMediumGrey};
      margin-top: 4px;
    }
  }


  li{
    list-style: none;
    margin: 20px 0;
    display: flex;
    align-items: center;

    &.uncompleted{
      font-weight: bold;
    }
    &.completed{
      color: ${theme.palette.veryMediumGrey};
      text-decoration: line-through;
    }
    .right-icon{
      width: 24px;
      height: 24px;
      background: url(${rightIcon}) no-repeat;
      float: right;
    }
    .check-icon{
      width: 24px;
      height: 24px;
      background: url(${yesIcon}) no-repeat;
      margin-right: 14px;
    }
    a{
      text-decoration: none;
      display: inline-block;
      color: ${theme.palette.gunmetal};
      line-height: 24px;
    }
  }
  button{
    width: 204px;
    float: left;
    margin-top: 6px;
  }
`;

export const InvitationWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 56px;
  padding: 40px;

  width: 880px;
  height: 269px;

  background: ${(props) =>
    props.isClaim
      ? theme.palette.lightPastelGreen
      : theme.palette.lightPastelBlue};
  border-radius: 4px;
  justify-content: space-between;

  &.full-info {
    background: ${theme.palette.white} !important;
    border: 1px solid ${theme.palette.lightWolfGrey};
    height: auto;
    padding: 24px;
  }
  .person-icon {
    width: 24px;
    height: 24px;
    background: url(${personIcon}) no-repeat;
    margin-right: 12px;
    float: left;
  }
  .notice-type {
    padding: 7px 12px;
    background: ${theme.palette.veryWarmLightGrey};
    border-radius: 16px;
    font-weight: bold;
    font-size: 12px;
    margin-left: 8px;
    float: left;
  }

  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    float: left;
  }
  h2 {
    font-size: 20px;
    line-height: 24px;
  }
  h3 {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: ${theme.palette.veryMediumGrey};
    margin: 0;
  }

  .pending-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    margin: 24px 0;

    height: 160px;

    border: 1px solid ${theme.palette.lightWolfGrey};
    border-radius: 4px;
    .pending-notice {
      padding: 4px 12px;
      width: 129px;
      height: 23px;

      background: ${theme.palette.veryWarmLightGrey};
      border-radius: 16px;
      font-size: 12px;
      font-weight: bold;
    }
    .has-margin {
      margin: 20px 0 16px 0;
    }
    &.approved {
      width: 400px;
      align-items: flex-start;
      height: auto;
      float: left;
      .person-avatar {
        width: 42px;
        height: 42px;
        mask: url(${avatar});
        background-color: ${theme.palette.gunmetal};
      }
    }
  }
  .flex-wrap {
    display: flex;
    justify-content: space-between;
  }
  .all-link {
    float: right;
    margin-right: 10px;
    text-decoration: underline;
    cursor: pointer;
  }
  .append-info {
    float: left;
    margin: 10px 0;
  }
`;
export const InvitationTable = styled.div`
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    font-weight: bold;
    &.company {
      width: 250px;
    }
    &.type {
      width: 218px;
    }
    &.phone {
      width: 145px;
    }
    &.status {
      width: 220px;
    }
  }
  table,
  tr,
  th,
  td {
    border-bottom: 1px solid ${theme.palette.lightWolfGrey};
    padding: 10px;
    text-align: left;
  }
  .img_company {
    height: 40px;
    width: 40px;
    background: url(${avatar}) no-repeat;
  }
  .img_accept {
    height: 24px;
    width: 24px;
    background: url(${acceptInviteIcon}) no-repeat;
  }
  .actions {
    display: flex;
    justify-content: space-between;
  }
  .img_actions {
    height: 24px;
    width: 24px;
    background: url(${deleteIcon}) no-repeat;
    cursor: pointer;
    &:hover {
      background-color: ${theme.palette.veryWarmLightGrey};
      border-radius: 50%;
    }
    &:active {
      background-color: ${theme.palette.wolfGrey};
      border-radius: 50%;
    }
    &.img_edit {
      background: url(${editPencilIcon}) no-repeat;
    }
  }
  tr:hover {
    background-color: ${theme.palette.lightGrey};
  }
`;

export const RequiredWrap = styled.div`
  .required-icon {
    width: 16px;
    height: 16px;
    background: url(${requiredIcon}) no-repeat;
    margin-right: 8px;
    float: right;
  }

  label {
    font-size: 14px;
    color: ${theme.palette.veryMediumGrey};
    float: right;
    line-height: 16px;
    font-weight: normal;
  }
`;

export const NotesFieldStyle = styled.div`
  width: 880px;

  h3 {
    line-height: 20px;
    margin: 0 0 8px 0;
  }

  .notes-input {
    width: 100%;
    height: 120px;
  }

  .description {
    font-size: 12px;
    line-height: 15px;
    margin: 8px 0 0 0;
  }
`;

export const NotesStyle = styled.div`
  margin: 40px 0 0 0;
  line-height: 20px;

  h3 {
    margin: 0 0 10px 0;
  }

  .internal {
    margin: 0 0 34px 0;
  }
`;

export const NotesModalContent = styled.div`
  height: 372px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FilesFrame = styled.div`
  margin: 32px 0;
  width: 880px;

  .dropzone {
    .Highlight {
      background-color: ${theme.palette.veryMediumGrey};
    }
  }
  .dragFrame {
    border-radius: 4px;
    border: 2px dashed rgb(198, 204, 208);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 30px;
    padding: 25px 0;
  }
  .uploadText {
    display: flex;
    justify-content: center;
  }
  .uploadCloudImg {
    height: 30px;
    width: 30px;
    background: url(${uploadCloudIcon}) no-repeat;
    background-size: contain;
    display: block;
    margin-right: 16px;
  }

  .drag {
    font-weight: ${theme.font.mainFontWeight};
    color: ${theme.palette.wolfGrey};
  }
  .upload {
    margin: 0 8px;
    text-align: center;
    color: ${theme.palette.wolfGrey};
  }
  .browser {
    font-weight: ${theme.font.mainFontWeight};
    color: ${theme.palette.gunmetal};
  }
  .upload-files {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: auto;
  }
  .append-label {
    margin: 16px 0 32px 0;
    color: ${theme.palette.veryMediumGrey};
    font-size: 14px;
    line-height: 17px;
  }

  .file-list {
    height: 44px;
    line-height: 24px;
    padding: 6px 16px;
    display: flex;
    align-items: center;
    list-style: none;

    &.files {
      height: auto;
      border-bottom: 1px solid ${theme.palette.lightWolfGrey};
      height: 57px;
      border: 1px solid ${theme.palette.lightWolfGrey};
      border-radius: 4px;
      margin-bottom: 12px;
      padding: 12px 16px;

      &.upload {
        margin: 0 0 12px 0;
      }
    }
    .uploadIcon {
      height: 24px;
      width: 24px;
      background: url(${fileItemIcon}) no-repeat;
      background-size: contain;
      display: block;
      float: left;
      margin-right: 10px;
    }
    .attachIcon {
      height: 24px;
      width: 24px;
      background: url(${attachIcon}) no-repeat;
      background-size: contain;
      display: block;
      float: left;
      margin-right: 10px;
    }
    .name {
      font-weight: ${theme.font.mainFontWeight};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${theme.palette.gunmetal};
      max-width: 400px;
      font-weight: bold;
    }
    .size {
      float: left;
      margin-left: 8px;
      font-size: 14px;
      color: ${theme.palette.veryMediumGrey};
    }
    .action {
      height: 24px;
      width: 24px;
      background: url(${deleteIcon}) no-repeat;
      background-size: contain;
      display: block;
      float: right;
      border: none;
      cursor: pointer;
    }
    .status {
      float: right;
      margin-right: 12px;
      color: ${theme.palette.veryMediumGrey};
    }
    .select-wrap {
      margin: 0 16px 0 auto;
    }
  }
`;

export const FileListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 448px;
  overflow: auto;

  .file-wrap {
    display: flex;
    flex-direction: column;
    padding: 10px 16px;

    width: 424px;
    height: 59px;

    background: #ffffff;
    border: 1px solid ${theme.palette.lightWolfGrey};
    border-radius: 4px;
  }
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
  }
  .name {
    font-weight: bold;
    line-height: 20px;

    max-width: 340px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
      cursor: pointer;
    }
  }
  .type {
    &.size {
      margin-left: 8px;
    }
    font-size: 14px;
    color: ${theme.palette.veryMediumGrey};
  }
`;

export const PrefilledTag = styled.div`
  height: 23px;
  padding: 5px 14px 3px 14px;
  margin: 0 0 0 12px;
  border-radius: 16px;

  font-size: 12px;
  text-align: center;
  background-color: ${theme.palette.lightPastelBlue};
  color: ${theme.palette.tealBlue};
`;

export const NoticeWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  height: 56px;
  width: 100%;

  background: ${theme.palette.lightPastelPink};
  border-radius: 4px;
  line-height: 24px;

  margin: 56px 0 -40px 0;

  .warning-icon {
    width: 24px;
    height: 24px;
    background: url(${warningIcon}) no-repeat;
    float: left;
    margin-right: 8px;
  }
  .all-link {
    float: right;
    margin-right: 10px;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const InvitationDetailWrap = styled.div`
  margin-top: 32px;
  width: 880px;
  display: flex;
  justify-content: space-between;
  .detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    .has-margin {
      margin-right: 32px;
    }
  }

  .phone-wrap {
    input {
      margin-bottom: 0;
      width: 361px;
      &:disabled {
        color: ${theme.palette.veryMediumGrey};
      }
    }
  }

  .gst-field {
    margin-bottom: 16px;
    .sub-text {
      margin: 4px 0 0 0;
      font-size: 12px;
    }
    input {
      margin: 0;
    }
  }
  .has-big-margin {
    margin-top: 140px;
  }
`;

export const HelpIconButton = styled.button.attrs({
  title: "You can check the NZBN here",
})`
  width: 16px;
  height: 16px;
  border: none;
  background: url(${helpIcon}) no-repeat;
  background-size: contain;
  margin-left: 4px;
  cursor: pointer;
  transform: 180deg;
`;

export const CompanyImage = styled.div`
  width: 100%;
  height: 50px;
  background: ${(props) =>
    props.url !== ""
      ? `url(${props.url}) no-repeat`
      : `url(${payLabLogo}) no-repeat`};
  background-size: contain;
`;

export const ClaimHistoryWrap = styled.div`
  height: 100%;
  margin: 40px 0 0 0;

  .buttons {
    height: fit-content;
    display: flex;
    .payments-btn {
      margin: 0 0 0 16px;
    }
  }

  .pagination {
    display: flex;
    height: 44px;
  }

  button {
    min-width: 0;
  }
`;

export const SwitchWrap = styled.div`
  display: flex;
  padding: 10px 10px;
  margin-right: 16px;
  width: 216px;
  height: 40px;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.lightWolfGrey};
  border-radius: 4px;
`;

export const SettingContent = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  line-height: 20px;

  .input-margin {
    margin: 16px 0 8px 0;
  }
  .has_margin {
    margin-bottom: 24px;
  }

  input {
    width: 424px;
    outline: none;
    border-radius: 4px;
    color: ${theme.palette.gunmetal};
  }
  h2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: ${theme.font.mainFontWeight};
    &.hasMargin {
      margin-bottom: 8px;
    }
    &.small-size {
      font-size: 18px;
      line-height: 20px;
      font-weight: ${theme.font.mainFontWeight};
      margin-top: 16px;
    }
  }
  .small-label {
    font-size: 12px;
    line-height: 15px;
  }
  .form-container {
    margin-top: 40px;
    width: 100%;
  }
  .top-container {
    width: 100%;
    min-height: 24px;
  }
`;

export const SearchIcon = styled.div`
  -webkit-appearance: none;
  width: ${(props) => (props.width ? props.width : "24px")};
  height: ${(props) => (props.height ? props.height : "24px")};
  mask: url(${MagnifyingIcon}) no-repeat;
  background-size: contain;
  background-color: ${theme.palette.gunmetal};
  margin-left: 14px;
`;

export const Error = styled.div`
  font-size: 12px;
  color: ${theme.palette.watermelonRed};
  display: flex;
  align-items: center;
  margin-top: -10px;
  margin-right: 360px;
  padding-bottom: 10px;
  .error_img {
    background: url(${warningIcon});
    background-size: contain;
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`;

export const ErrorWrapper = styled.div`
  width: 100%;
  & > div {
    margin-top: 0;
  }
  .error_item {
    padding-top: 7px;
  }
`;

export const TaxSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: auto;
  margin: 30px 0px;
  gap: 16px;
  .medium-width {
    width: 70%;
  }
  .mb-0 {
    margin-bottom: 0;
  }

  .description {
    font-size: 12px;
    margin-top: 10px;
  }

  .description-link {
    text-decoration: none;
    color: ${theme.palette.gunmetal};
    font-weight: bold;
  }

  label {
    font-size: 16px;
    font-weight: bold;
  }
`;
