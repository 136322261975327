import React, { useEffect } from "react";

import { SectionIcon } from "../../project/styled";
import {
  SettingWrapper,
  ContractDetailWrapper,
  RequiredWrap,
  SwitchWrap,
} from "../styled";

import {
  BlueWhiteBtn as Button,
  BlueOutlinedBtn as ButtonSecondary,
} from "../../../common/button";
import { AntSwitch } from "../../../common/switch";
import UploadLineItem from "../../../common/lineitem/csvUploadDialog";
import LineItemsTable from "../../../common/lineitem/baseTableReview";
import LineItemClaimTable from "../../../common/table/claim_table";

import Link from "../../../common/linkWithQuery";
import { LinkedInfoSettings } from "../common/integration";

const Table = (props) => {
  if (props.tableType === "claim") {
    return <LineItemClaimTable {...props}>{props.children}</LineItemClaimTable>;
  } else {
    return <LineItemsTable {...props}>{props.children}</LineItemsTable>;
  }
};

const RenderTable = (props) => {
  const {
    table,
    itemsType,
    sectionTitle,
    proId,
    contractId,
    expand,
    switchOnExpand,
    readOnly,
    isLinked,
    hasExpand,
    type,
    tableContent,
    hasSort,
    isProvisional,
    downloadFile,
    ...otherProps
  } = props;

  let tableType = expand ? "claim" : "";

  return (
    <Table
      tableContent={tableContent ? tableContent : table}
      tableCaption={
        sectionTitle && (
          <div>
            <SectionIcon img={itemsType} />
            <span>{sectionTitle}</span>
          </div>
        )
      }
      tableType={tableType}
      sectionTitle={sectionTitle}
      proId={proId}
      contractId={contractId}
      hasSort={hasSort === undefined ? true : hasSort}
      isTableSubtitleBold={true}
      downloadFile={downloadFile}
      isProvisional={isProvisional}
      {...otherProps}
    >
      <>
        {hasExpand && (
          <SwitchWrap>
            <AntSwitch
              checked={expand}
              onChange={(event) => switchOnExpand(event)}
              id="expand"
            />
            <label htmlFor="expand" style={{ marginLeft: 16 }}>
              Show values to date
            </label>
          </SwitchWrap>
        )}
        {!readOnly && !isLinked && (
          <Link to={`/contract/${proId}/${type}/${contractId}/${itemsType}`}>
            <ButtonSecondary title="Edit" className="edit-button" />
          </Link>
        )}
        {isLinked && <LinkedInfoSettings from="Contract" />}
      </>
    </Table>
  );
};

export default function LineItems(props) {
  const {
    contractInfo,
    itemsType,
    readContract,
    hasExpand,
    downloadFile,
    outstandingClaims,
    isAdmin,
    readOnly,
    accountConfig,
    setExpanded,
    tableContent,
    tableTitle,
    componentTotal,
    hasSort,
    isProvisional,
    isExpanded,
    isLinked,
  } = props;
  const { proId, contractId, type } = props.match.params;

  const [expand, setExpand] = React.useState(false);

  useEffect(() => {
    if (isExpanded !== undefined) {
      setExpand(isExpanded);
      setExpanded(isExpanded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  const switchOnExpand = (event) => {
    setExpand(event.target.checked);
    setExpanded(event.target.checked);
  };

  const ifRequired =
      itemsType === "baseContract" && !contractInfo.get("baseitems"),
    sectionTitle =
      tableTitle !== undefined
        ? tableTitle
        : itemsType === "baseContract"
          ? "Base Contract"
          : "Variations",
    table =
      itemsType === "baseContract"
        ? contractInfo.get("baseitems")
        : contractInfo.get("variation_items"),
    total =
      componentTotal !== undefined
        ? componentTotal
        : itemsType === "baseContract"
          ? contractInfo.get("total_baseitems")
          : contractInfo.get("total_variations");

  if (props?.noOuterWrapper) {
    return (
      <RenderTable
        table={table}
        itemsType={itemsType}
        sectionTitle={sectionTitle}
        total={total}
        contractInfo={contractInfo}
        downloadFile={downloadFile}
        tableContent={tableContent}
        proId={proId}
        type={type}
        contractId={contractId}
        outstandingClaims={outstandingClaims}
        accountConfig={accountConfig}
        expand={expand}
        switchOnExpand={switchOnExpand}
        marginLeft={16}
        {...props}
        readOnly={readOnly}
        hasExpand={hasExpand}
        hasSort={hasSort}
        isProvisional={isProvisional}
      />
    );
  }

  if (readOnly && table) {
    return (
      <ContractDetailWrapper
        className={"setting-page " + (expand ? "expand-table" : "")}
      >
        <SettingWrapper>
          <Table
            tableContent={table}
            tableCaption={
              <div>
                <SectionIcon img={itemsType} />
                <span>{sectionTitle}</span>
              </div>
            }
            tableType={expand ? "claim" : ""}
            sectionTitle={sectionTitle}
            total={total}
            contractInfo={contractInfo}
            downloadFile={downloadFile}
            proId={proId}
            contractId={contractId}
            outstandingClaims={outstandingClaims}
            accountConfig={accountConfig}
            hasSort={hasSort === undefined ? true : hasSort}
            expandTreeKeys={props.expandTreeKeys}
            setExpandKeys={props.setExpandKeys}
          >
            <>
              {hasExpand && (
                <SwitchWrap>
                  <AntSwitch
                    checked={expand}
                    onChange={(event) => switchOnExpand(event)}
                    id="expand"
                  />
                  <label htmlFor="expand" style={{ marginLeft: 16 }}>
                    Show values to date
                  </label>
                </SwitchWrap>
              )}
            </>
          </Table>
        </SettingWrapper>
      </ContractDetailWrapper>
    );
  }

  return (
    <ContractDetailWrapper
      className={
        "setting-page " +
        (ifRequired ? "required" : "") +
        (expand ? "expand-table" : "")
      }
      id={
        isProvisional
          ? ""
          : itemsType === "baseContract"
            ? "nav-baseContracts"
            : "nav-variation"
      }
    >
      <SettingWrapper border="none">
        {!table && (
          <>
            <div className="section-title">
              <div>
                <SectionIcon img={itemsType} />
                <span>{sectionTitle}</span>
              </div>
              {ifRequired && !readOnly && (
                <RequiredWrap>
                  <label>Required</label>
                  <div className="required-icon" />
                </RequiredWrap>
              )}
            </div>
            <div className="center">
              Start building up the items of the {sectionTitle}. You can invite
              consultants to complete this together.
            </div>
            {!readOnly && !isLinked && (
              <div>
                <Link
                  to={`/contract/${proId}/${type}/${contractId}/${itemsType}`}
                >
                  <Button
                    title={"Setup " + sectionTitle}
                    margin={"0 0 0 16px"}
                  />
                </Link>
                <UploadLineItem
                  type={itemsType}
                  proId={proId}
                  contractId={contractId}
                  readOnly={readOnly}
                  resetInfo={() =>
                    readContract(proId, contractId, isAdmin, type)
                  }
                />
              </div>
            )}
          </>
        )}
        {table && (
          <RenderTable
            table={table}
            itemsType={itemsType}
            sectionTitle={sectionTitle}
            total={total}
            contractInfo={contractInfo}
            type={type}
            downloadFile={downloadFile}
            proId={proId}
            contractId={contractId}
            outstandingClaims={outstandingClaims}
            accountConfig={accountConfig}
            expand={expand}
            switchOnExpand={switchOnExpand}
            marginLeft={16}
            {...props}
            readOnly={readOnly}
            hasExpand={hasExpand}
          />
        )}
      </SettingWrapper>
    </ContractDetailWrapper>
  );
}
