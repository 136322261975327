import React from "react";
import { connect } from "react-redux";
import ConfirmDialog from "./component/confirmDialog";
import DeleteDialog from "./component/deleteDialog";
import LeaveContractDialog from "./component/leaveContractDialog";
import ConfirmLeaveDialog from "./component/confirmLeaveDialog";
import TrialDialog from "./component/freeTrailDialog";

import { actionCreators } from ".";
const MODAL_TYPES = {
  alert: ConfirmDialog,
  confirm: ConfirmDialog,
  delete: DeleteDialog,
  leaveContract: LeaveContractDialog,
  confirmLeave: ConfirmLeaveDialog,
  trialNotice: TrialDialog,
  // prompt: modalTypes.promptModal
};

class ModalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: props.modalProps.open,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modalProps.open !== this.props.modalProps.open) {
      this.setState({
        modalIsOpen: nextProps.modalProps.open,
      });
    }
  }

  closeModal(status) {
    this.props.hideModal(status);
  }

  render() {
    const { deleteItem } = this.props;
    if (!this.props.modalType) {
      return null;
    }
    const SpecifiedModal = MODAL_TYPES[this.props.modalType];
    return (
      <div>
        <SpecifiedModal
          closeModal={this.closeModal}
          deleteItem={deleteItem}
          type={this.props.modalType}
          {...this.props.modalProps}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  modalType: state.getIn(["modal", "modalType"]),
  modalProps: state.getIn(["modal", "modalProps"]),
});
const mapDispatchToProps = (dispatch) => {
  return {
    hideModal(status) {
      dispatch(actionCreators.hideModal(status));
    },
    deleteItem() {
      dispatch(actionCreators.deleteItem());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
