import styled from "styled-components";
import * as theme from "../../theme";
import searchIcon from "../../statics/img/icon-search-dark-grey.svg";
import whiteIcon from "../../statics/img/icon-dropdown-white.svg";
import whiteSetIcon from "../../statics/img/icon-dropdown-up-white.svg";
import listIcon from "../../statics/img/icon-list-layout.svg";
import tableIcon from "../../statics/img/icon-table-layout.svg";
import noImage from "../../statics/img/icon-no-image.svg";
import actionIcon from "../../statics/img/icon-more-horizontal.svg";

// new
export const EmptyStateWrapper = styled.div`
  height: 280px;
  width: 100%;
  background: ${theme.palette.white};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .top-label {
    font-size: 24px;
    font-weight: ${theme.font.mainFontWeight};
    line-height: 29px;
  }
  .sub-label {
    margin: 16px 0 32px 0;
    color: ${theme.palette.veryMediumGrey};
    line-height: 20px;
  }
`;

export const EmptyState = styled.div`
  margin: 80px auto;
  text-align: center;
  color: ${theme.palette.veryMediumGrey};

  .top-label {
    font-size: 24px;
    font-weight: ${theme.font.mainFontWeight};
    line-height: 29px;
    margin-bottom: 16px;
  }
`;

export const ProjectsWrapper = styled.div`
  width: 100%;
  height: auto;
`;
export const SummaryContainer = styled.div`
  display: grid;
  margin-left: 24px;
  float: left;
`;

export const MoreOptions = styled.div`
  width: 52px;
  height: 44px;
  border: 1px solid ${theme.palette.wolfGrey};
  border-radius: 4px;
  box-sizing: border-box;
  background: url(${actionIcon}) no-repeat;
  background-position: center;
  cursor: pointer;
  grid-template-columns: 70px auto;
  background-color: ${theme.palette.white};
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
  justify-content: space-between;
`;

export const SortContainer = styled.div`
  height: 44px;
  width: 222px;
  border: 1px solid ${theme.palette.wolfGrey};
  border-radius: 4px;
  margin-left: 24px;
  float: left;
  box-sizing: border-box;
  background-color: ${theme.palette.white};
  display: grid;
  grid-template-columns: 70px auto;
  align-items: center;
  .sort-label {
    margin-left: 16px;
    font-weight: ${theme.font.mainFontWeight};
  }
`;

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  width: 97px;
  height: 44px;

  border: 1px solid ${theme.palette.lightWolfGrey};
  border-radius: 4px;
  background-color: ${theme.palette.white};
  cursor: pointer;
  .option-wrap {
    transition: all 0.1s ease-in-out;
    width: 50%;
    height: 100%;
    padding: 12px 15px;
    &.list {
      border-radius: 4px 0px 0px 4px;
      background-color: ${(props) =>
        props.layout === "list" ? theme.palette.gunmetal : theme.palette.white};
    }
    &.table {
      border-radius: 0px 4px 4px 0;
      background-color: ${(props) =>
        props.layout === "table"
          ? theme.palette.gunmetal
          : theme.palette.white};
    }
  }
  .icon-list {
    width: 18px;
    height: 18px;
    mask: url(${listIcon}) no-repeat center;
    background-color: ${(props) =>
      props.layout === "list" ? theme.palette.white : theme.palette.gunmetal};
  }
  .icon-table {
    width: 18px;
    height: 18px;
    background-color: ${(props) =>
      props.layout === "table" ? theme.palette.white : theme.palette.gunmetal};
    mask: url(${tableIcon}) no-repeat center;
  }
`;

export const ProjectItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
  border-radius: 4px;
  background: ${theme.palette.white};
  margin-bottom: 16px;
  position: relative;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
  .cover-image {
    height: 72px;
    width: 100%;
    background: ${(props) =>
      props.cover ? `url(${props.cover}) no-repeat` : theme.palette.lightGrey};
    background-size: cover;
    background-position: center;
  }
  .avatar-image {
    position: absolute;
    width: 128px;
    height: 128px;
    top: 24px;
    left: 32px;
    background: ${(props) =>
      props.avatar
        ? `url(${props.avatar}) no-repeat`
        : `url(${noImage}) no-repeat`};
    border: 3px solid #ffffff;
    border-radius: 6px;
    background-size: cover;
    background-color: ${theme.palette.veryColdGrey};
  }
  .complete-num {
    position: absolute;
    top: 96px;
    right: 24px;
    height: 40px;
    padding: 10px;
    min-width: 52px;
    background: ${theme.palette.veryColdGrey};
    border-radius: 4px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .project-info {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 24px 192px;
    line-height: 20px;
    font-weight: bold;
    width: 100%;
  }
  .project-title-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
  }
  .title {
    font-size: 24px;

    margin-bottom: 14px;
  }
  .project-data {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 58px;
    grid-row-gap: 6px;
    margin-top: 24px;
  }
  label {
    font-weight: ${theme.font.normalFontWeight};
    color: ${theme.palette.veryMediumGrey};
  }
`;

export const SearchContainer = styled.div`
  height: 44px;
  width: ${(props) => (props.isContractList ? "420px" : "320px")};
  background-color: ${theme.palette.white};
  box-sizing: border-box;
  float: left;
  border-radius: 4px;
  padding: 10px 10px 10px 16px;
  border: 1px solid ${theme.palette.lightWolfGrey};
  display: grid;
  grid-template-columns: 24px auto;
  grid-column-gap: 10px;

  input {
    ::placeholder {
      color: ${theme.palette.wolfGrey};
    }
    outline: none;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-family: ${theme.font.mainFont};
    font-weight: ${theme.font.normalFontWeight};
    height: 100%;
    color: ${theme.palette.gunmetal};
  }
  .img-search {
    height: 24px;
    width: 24px;
    background: url(${searchIcon}) no-repeat scroll;
    background-size: 24px;
    cursor: pointer;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 40px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  background-color: ${theme.palette.white};
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: end;
  caption {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    display: table-caption;
    margin-bottom: 24px;
    text-align: left;
    height: 29px;
  }
  table {
    height: 100%;
    width: 100%;
    border-spacing: 1px 0;
    border-collapse: separate;
    table-layout: fixed;
    font-size: 16px;
    font-weight: ${theme.font.normalFontWeight};
    color: ${theme.palette.gunmetal};
    tbody {
      tr:nth-child(even) {
        background-color: ${theme.palette.greyWhite};
      }
      tr:nth-child(odd) {
        background-color: ${theme.palette.white};
      }
      tr {
        &:hover {
          cursor: pointer;
          background-color: ${theme.palette.veryColdGrey};
        }
      }
    }
  }

  thead {
    background-color: ${theme.palette.gunmetal};
    height: 44px;
    box-sizing: border-box;
  }
  th {
    text-align: left;
    padding: 0 15px;
    color: ${theme.palette.white};
    font-weight: ${theme.font.mainFontWeight};
    line-height: 24px;
    position: relative;
    box-sizing: border-box;
    height: 44px;
    &.name {
      width: 316px;
    }
    &.number {
      width: 110px;
    }
    &.value {
      width: 160px;
    }
  }
  td {
    padding: 4px 15px;
    box-sizing: border-box;
    height: 44px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: right;
    &.align-left {
      text-align: left;
    }
    &.action {
      text-align: center;
      padding: 0;
    }
    &.empty {
      background-color: ${theme.palette.white} !important;
    }
    .secondary-label {
      font-size: 12px;
      color: ${theme.palette.veryMediumGrey};
      margin-top: 4px;
    }
    .project-title-container {
      display: flex;
      flex-direction: row;
      gap: 8px;
      width: 100%;
    }

    .project-title {
      padding-top: 4px;
    }
  }
  .table-container_total {
    background-color: ${theme.palette.tealBlue};
    color: ${theme.palette.white};
    width: 282px;
    height: 89px;
    padding: 16px;
    font-weight: ${theme.font.mainFontWeight};
    text-align: left;
    font-weight: 700;
    margin-top: 32px;
    .number {
      text-align: right;
      margin-top: 8px;
      font-size: 24px;
    }
  }

  .img-sort {
    height: 24px;
    width: 24px;
    mask: url(${whiteIcon}) no-repeat center;
    background-color: ${theme.palette.veryMediumGrey};
    background-size: contain;
    cursor: pointer;
    box-sizing: border-box;
    position: absolute;
    right: 10px;
    bottom: 5px;
    &.up {
      mask: url(${whiteSetIcon}) no-repeat center;
      background-size: contain;
      bottom: 15px;
    }
    &.active {
      background-color: ${theme.palette.white};
    }
  }
`;

export const Caption = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 20px;
  margin-top: 56px;
`;

export const SubsetBadge = styled.div.attrs((props) => ({
  className: props.className || "",
}))`
  box-sizing: border-box;
  height: 23px;
  width: max-content;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: normal;
  line-height: 15px;
  color: ${theme.palette.white};
  border-radius: 12px;
  background-color: ${theme.palette.midGrey};
  align-items: center;
`;

export const ActionsWrap = styled.div`
  position: absolute;
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 15px;
  height: auto;
  top: 270px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  flex: none;
  z-index: 9;
  background-color: white;
  .action {
    cursor: pointer;
  }
`;
