import styled from "styled-components";
import * as theme from "../../theme";
import editIcon from "../../statics/img/icon-edit-white.svg";
import noImgIcon from "../../statics/img/icon-no-image.svg";
import closeIcon from "../../statics/img/icon-clear-medium-grey.svg";
import errorIcon from "../../statics/img/icon-information-red.svg";
import vaultIcon from "../../statics/img/vault-brand-logo.svg";
import settingIcon from "../../statics/img/icon-setting.svg";
import cheerIcon from "../../statics/img/icon-celebration.svg";
import dateIcon from "../../statics/img/icon-calendar-dark-grey.svg";
import memberIcon from "../../statics/img/icon-member.svg";
import workflowIcon from "../../statics/img/icon-tree.svg";
import timezoneIcon from "../../statics/img/icon-time-zone.svg";
import certIconGreen from "../../statics/img/icon-workflow-cert-green.svg";
import avatarSmallIcon from "../../statics/img/icon-avatar.svg";
import retentionIcon from "../../statics/img/icon-retention.svg";
import deleteIcon from "../../statics/img/icon-delete-medium-grey.svg";
import noticeIcon from "../../statics/img/icon-notice-green.svg";
import whiteIcon from "../../statics/img/icon-dropdown-white.svg";
import whiteSetIcon from "../../statics/img/icon-dropdown-up-white.svg";
import upIcon from "../../statics/img/icon-dropdown-list-up-light-grey.svg";
import avatarIcon from "../../statics/img/avatar.svg";
import radioOnIcon from "../../statics/img/icon-radio-on.svg";
import radioOffIcon from "../../statics/img/icon-radio-off.svg";
import baseIcon from "../../statics/img/icon-base-item.svg";
import variationIcon from "../../statics/img/icon-variation-item.svg";

import notesIcon from "../../statics/img/notes-icon.svg";
import taxIcon from "../../statics/img/tax-icon.svg";
import docIcon from "../../statics/img/icon-document.svg";
import tickedIcon from "../../statics/img/icon-check-box-check-dark-grey.svg";
import untickedIcon from "../../statics/img/icon-check-box-un-check-dark-grey.svg";
import actionIcon from "../../statics/img/icon-more-horizontal.svg";
import materialIcon from "../../statics/img/icon-materials.svg";
import linkIcon from "../../statics/img/icon-link.svg";

import { IconButton } from "../../common/button";

export const Container = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  .page-breadcrumbs {
    width: 960px;
    margin: 24px auto;
    align-items: center;
    display: grid;
    grid-template-columns: auto 200px;
    .page-breadcrumbs-label {
      margin: 0 !important;
    }
  }
  hr {
    height: 1px;
    width: 100%;
    margin: 40px;
    border: none;
    background: ${theme.palette.lightWolfGrey};
  }

  .move-to-top {
    margin-top: -220px;
  }

  .skeleton-components {
    width: 960px;
    margin: 0 auto;
    display: grid;
    grid-auto-rows: 400px;
    grid-row-gap: 16px;
  }

  .margin-bottom {
    margin-bottom: 56px;
    &.small {
      margin-bottom: 24px;
    }
  }
`;
export const ProjectBackground = styled.div`
  width: 100%;
  height: 300px;
  position: relative;
  background: ${(props) => `url(${props.src})`};
  background-color: ${theme.palette.wolfGrey};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 1;
  &.setting-page {
    height: 169px;
  }
`;
export const ProjectAvatar = styled.div`
  position: absolute;
  width: 128px;
  height: 128px;
  top: 72px;
  left: 32px;

  background: ${(props) => `url(${props.projectImage})`}
    ${theme.palette.veryColdGrey};
  border: 3px solid ${theme.palette.white};
  border-radius: 6px;
  background-size: cover;
`;
export const EditImg = styled.div`
  width: 40px;
  height: 40px;
  background: ${theme.palette.gunmetal};
  border-radius: 32px;
  position: absolute;
  top: 19%;
  left: 48.6%;
  background-image: url(${editIcon});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &.bottom-position {
    bottom: 8px;
    right: 8px;
    top: auto;
    left: auto;
  }
`;
export const CloseIcon = styled(IconButton)`
  background: url(${closeIcon}) no-repeat;
`;
export const ProjectDetailWrapper = styled.div`
  width: 960px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 40px;

  margin: -148px auto 56px;
  box-shadow: 0px 2px 6px rgb(0 0 0/25%);
  border-radius: 4px;
  background-color: ${theme.palette.white};
  box-sizing: border-box;

  position: relative;
  z-index: 5;

  &.setting-page {
    margin: 16px auto;
  }

  .avatar {
    width: 128px;
    height: 128px;
    flex: none;
    position: relative;
    border-radius: 6px;
    background: ${(props) =>
      props.projectImage ? `url(${props.projectImage})` : `url(${noImgIcon})`};
    background-repeat: no-repeat;
    background-position: center;
    background-color: ${theme.palette.veryColdGrey};
    margin-bottom: 56px;
    background-size: contain;
  }
  h3 {
    font-size: 18px;
    line-height: 22px;
    font-weight: ${theme.font.mainFontWeight};
    margin-bottom: 24px;
    &.no-margin {
      margin: 0;
    }
  }
  .input-field_name {
    min-width: 320px;
    width: fit-content;
    min-height: 60px;
    height: auto;
    border: none;
    border-bottom: 1px solid ${theme.palette.lightWolfGrey};
    outline: none;
    font-weight: ${theme.font.mainFontWeight};
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 24px;
    &.error {
      border-bottom: 1px solid ${theme.palette.watermelonRed};
    }
  }
  .des-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: ${(props) => (props.linked ? "0px" : "16px")};
    margin: 24px auto 56px;

    width: 100%;
    height: 44px;

    border: 1px solid ${theme.palette.lightWolfGrey};
    border-radius: 4px;

    &.no-margin {
      margin: 0 auto;
    }

    div {
      width: 100%;
    }
  }
  .input-field_description {
    border: none;
    outline: none;
    height: fit-content;
    width: 800px;
  }
  .input-field {
    outline: none;
    border-radius: 4px;
    width: 832px;
    margin-bottom: 24px;
    &.small {
      width: 256px;
    }
    &.middle {
      width: 400px;
      &.number {
        outline: none;
        ::placeholder {
          color: ${theme.palette.wolfGrey};
        }
      }
    }
  }

  .details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`;
export const LinkLabel = styled.a`
  line-height: 20px;
  text-decoration-line: underline;
  font-weight: ${theme.font.mainFontWeight};
  color: ${theme.palette.turquoiseBlue};
  cursor: pointer;
  margin: 24px auto 56px;

  &.no-margin {
    margin: 0 auto;
  }
`;
export const AdditionalWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 24px auto 56px;
  padding: 24px;
  width: 100%;
  height: auto;
  border: 1px solid ${theme.palette.lightWolfGrey};
  border-radius: 4px;
  &.no-margin {
    margin: 0;
  }
  &.top-margin {
    margin: 56px 0 0 0;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  textarea {
    padding: 10px 16px;
  }
  .append-meg {
    margin: 8px 0 24px 0;
    font-size: 12px;
  }

  &.duplicate-project {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 26px;
  }

  .with-spacing {
    margin-top: 20px;
  }
  .margin-bottom {
    margin-bottom: 24px;
  }
`;
export const Error = styled.div`
  font-size: 12px;
  color: ${theme.palette.watermelonRed};
  display: flex;
  align-items: center;
  margin: -18px 0 24px 0;
  .error_img {
    background: url(${errorIcon});
    background-size: contain;
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`;

export const CropImageContainer = styled.div`
  .class-cropper-modal {
    position: fixed;
    background-color: rgba(#000000, 0.3);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    .modal-panel {
      width: 880px;
      height: auto;
      background: ${theme.palette.white};
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);

      .button-row {
        margin-top: 40px;
        height: 50px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
      }

      .cropper-container-container {
        flex: 1;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        height: 100%;
        margin-left: 20px;

        .cropper-container {
          flex: 0 0 600px;
          margin-right: 20px;

          .cropper {
            width: 100%;
            height: 100%;
          }
        }

        .preview-container {
          flex: 1;
          display: flex;
          align-items: flex-end;

          .cropper-preview {
            width: 180px;
            height: 180px;
            overflow: hidden;
            border: 1px solid ${theme.palette.gunmetal};
          }
        }
      }
    }
  }
`;

export const ProjectContentWrapper = styled.div`
  .project-image {
    position: absolute;
    width: 128px;
    height: 128px;
    top: -40px;

    background: ${(props) => `url(${props.projectImage})`}
      ${theme.palette.veryColdGrey};
    border: 3px solid ${theme.palette.white};
    border-radius: 6px;
    background-size: cover;
  }
  .content {
    margin-left: 208px;
    width: 672px;
  }
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title-label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    gap: 8px;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
  }

  .number {
    color: ${theme.palette.veryMediumGrey};
    margin-bottom: 16px;
  }
  .des {
    margin-bottom: 16px;
  }
  .vault-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 16px;

    width: 160px;
    height: 40px;

    background: ${theme.palette.veryWarmLightGrey};
    border-radius: 50px;

    color: ${theme.palette.vaultGreen};
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 32px;
    .logo {
      background: url(${vaultIcon}) no-repeat;
      height: 24px;
      width: 24px;
    }
  }
  .button-wrap {
    height: 40px;
    &.has-margin {
      margin-top: 38px;
      display: flex;
      justify-content: space-between;

      .delete-button {
        height: 100%;
        width: max-content;
      }
      .left-buttons {
        display: flex;
        .archive-button {
          height: 100%;
          width: max-content;
        }
      }
    }
  }
  .setIcon {
    width: 52px;
    height: 40px;

    border: 1px solid ${theme.palette.gunmetal};
    border-radius: 4px;
    background: url(${settingIcon}) no-repeat;
    float: left;
    margin: 0 12px 0 0;
  }

  .moreOption {
    width: 52px;
    height: 40px;

    border: 1px solid ${theme.palette.gunmetal};
    border-radius: 4px;
    background: url(${actionIcon}) no-repeat;
    background-position: center;
    cursor: pointer;
    float: left;
  }
`;

export const WelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;

  width: 880px;
  height: 141px;
  margin-top: 10px;
  background: ${theme.palette.paleYellow};
  border-radius: 4px;
  position: relative;

  .arrow {
    &:before {
      position: absolute;
      content: "";
      display: block;
      width: 0;
      height: 0;
      left: 43.5%;
      margin-top: -60px;
      border: 18px solid transparent;
      border-bottom: 18px solid ${theme.palette.paleYellow};
      pointer-events: none;
    }
  }
  .title {
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    float: left;
  }
  .cheer-img {
    background: url(${cheerIcon}) no-repeat;
    height: 24px;
    width: 24px;
    margin-left: 12px;
    float: left;
  }
  label {
    width: 85%;
    float: left;
    line-height: 20px;
  }
  button {
    margin: 0;
    height: 40px;
    padding: 10px 28px;
  }
`;

export const ProjectListWrapper = styled.div`
  width: 960px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  margin: 24px auto 40px;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
  border-radius: 4px;
  background-color: ${theme.palette.white};
  box-sizing: border-box;
  .frame-title {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .project-list-frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 16px;

    width: 880px;
    height: 124px;

    border: 2px dashed ${theme.palette.lightWolfGrey};
    border-radius: 4px;
    label {
      font-weight: bold;
    }
    .placeholder {
      color: ${theme.palette.veryMediumGrey};
    }
    .hasAlign {
      align-self: end;
    }
    button {
      &.has-margin {
        margin-top: -34px;
      }
    }
  }
`;
export const ContractWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: ${(props) =>
    props.padding && props.padding !== "" ? props.padding : "24px"};
  width: ${(props) =>
    props.width && props.width !== "" ? props.width : "880px"};

  height: auto;

  border: 1px solid ${theme.palette.lightWolfGrey};
  border-radius: 4px;

  line-height: 20px;
  font-weight: bold;
  margin-bottom: 16px;

  .header-bar {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;

    .name {
      font-size: 24px;
      line-height: 29px;
      display: flex;
    }
    .contract-name {
      max-width: calc(100% - 225px);
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
    }
    .complete-info {
      height: 40px;
      padding: 10px;
      min-width: 52px;
      background: ${theme.palette.veryColdGrey};
      border-radius: 4px;
      margin-left: auto;
    }
    .no-background {
      background: none;
    }
    .capitalize {
      text-transform: capitalize;
    }
    .draft {
      color: ${theme.palette.veryMediumGrey};
    }
    .completed {
      color: ${theme.palette.brightGreen};
    }
    .archived {
      color: ${theme.palette.gunmetal};
    }
    .subset-badge {
      margin-left: 8px;
    }
  }
  .info-bar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 58px;
    grid-row-gap: 6px;
    margin-top: 24px;
    width: 100%;
    label {
      font-weight: 400;
      color: ${theme.palette.veryMediumGrey};
    }
    .num {
      font-size: 20px;
      .draft {
        color: ${theme.palette.lightGrey};
        display: inline;
        margin-left: 3px;
        font-weight: lighter;
      }
    }
  }
  .view-button {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .other-party-name {
    color: ${theme.palette.veryMediumGrey};
    font-weight: 400;
  }
`;

export const ContractSummary = styled(ContractWrap)`
  &.has-margin {
    margin: 32px 0 -24px 0;
  }
  .info-bar {
    grid-template-columns: 150px 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    &.contract-page {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  .complete-bar {
    width: 832px;
    height: 16px;
    margin-top: 24px;
    border-radius: 16px;
    background: ${theme.palette.greyWhite};
    position: relative;
    .cert {
      background: ${theme.palette.brightGreen};
      height: 100%;
      border-radius: 16px;
      position: absolute;
    }
    .claim {
      background: ${theme.palette.tealBlue};
      height: 100%;
      border-radius: 16px;
      position: absolute;
    }
  }
`;

export const NoticeWrap = styled.div`
  margin-left: 16px;
  padding: 4px 14px;
  width: auto;
  height: 23px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  font-size: 12px;

  background-color: ${(props) => {
    let color = theme.palette.lightPastelGreen;
    switch (props.type) {
      case "overdue":
        color = theme.palette.lightPastelPink;
        break;
      case "due":
        color = theme.palette.paleYellow;
        break;
      default:
        break;
    }
    return `${color}`;
  }};

  color: ${(props) => {
    let color = theme.palette.brightGreen;
    switch (props.type) {
      case "overdue":
        color = theme.palette.watermelonRed;
        break;
      case "due":
        color = theme.palette.tangerineOrange;
        break;
      default:
        break;
    }
    return `${color}`;
  }};

  .notice-icon {
    width: 14px;
    height: 14px;
    background: url(${noticeIcon}) no-repeat center;
    margin-right: 8px;
  }
`;

export const PageTitle = styled.div`
  margin: 40px auto;
  width: 960px;
  h2 {
    font-weight: bold;
    font-size: 34px;
    margin-bottom: 8px;
  }
`;

export const ProjectInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 270px 1fr;
  grid-template-rows: 1fr;
  grid-gap: 32px 24px;
  width: 100%;
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  label {
    color: ${theme.palette.veryMediumGrey};
    line-height: 20px;
  }
  button {
    width: 92px;
    place-self: end;
  }
`;
export const SettingSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .section-title {
    font-size: 24px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    button {
      min-width: 92px;
    }
    span {
      text-transform: capitalize;
    }
    .flex {
      display: flex;
    }
  }
  .notice-message {
    float: left;
    color: ${theme.palette.veryMediumGrey};
    line-height: 40px;
  }
  .center {
    margin: 40px 0;
    &.sub {
      margin-top: 0;
      font-weight: ${theme.font.mainFontWeight};
    }
    &.has-margin {
      margin-top: 32px;
      font-weight: ${theme.font.normalFontWeight};
    }
    li {
      list-style: none;
      margin: 8px 0;
    }
  }
  &.retention {
    li {
      list-style: none;
      margin: 4px 0;
      line-height: 20px;

      &:first-child {
        margin-top: 0px;
      }
    }
  }
`;
export const SectionIcon = styled.div`
  height: 24px;
  width: 24px;
  background: ${(props) => {
      let url = "";
      switch (props.img) {
        case "date":
          url = dateIcon;
          break;
        case "materials":
          url = materialIcon;
          break;
        case "member":
          url = memberIcon;
          break;
        case "workflow":
          url = workflowIcon;
          break;
        case "timezone":
          url = timezoneIcon;
          break;
        case "retention":
          url = retentionIcon;
          break;
        case "setting":
          url = settingIcon;
          break;
        case "baseContract":
          url = baseIcon;
          break;
        case "variations":
          url = variationIcon;
          break;
        case "notes":
          url = notesIcon;
          break;
        case "tax":
          url = taxIcon;
          break;
        case "document":
          url = docIcon;
          break;
        default:
          url = "";
      }
      return `url(${url})`;
    }}
    no-repeat;
  float: left;
  margin-right: 14px;
  background-size: auto;
  background-position: center;
`;

export const ModalContent = styled.div`
  max-width: 960px;
  height: auto;
  padding: 0 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  &.align-row {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .full-width {
    width: 100%;
  }
  .mini-width {
    width: 196px;
  }
  .small-width {
    width: 272px;
  }
  .medium-width: {
    width: 424px;
  }

  .input-margin {
    margin: 32px 0 8px 0;
  }
  .has_margin {
    margin-bottom: 24px;
  }
  .move-top {
    margin-top: 12px;
  }

  .middle {
    width: 400px;
  }
  .small {
    width: 256px;
  }
  &.retention-report {
    input {
      width: 100%;
    }
  }
  input {
    width: 424px;
    outline: none;
    border-radius: 4px;
    color: ${theme.palette.gunmetal};
  }
  textarea {
    outline: none;
    border-radius: 4px;
    width: 424px;
    padding: 16px;
  }
  h3 {
    font-size: 18px;
    line-height: 22px;
    font-weight: ${theme.font.mainFontWeight};
    margin-bottom: 24px;
  }
  h1 {
    font-size: 24px;
    line-height: 30px;
    font-weight: ${theme.font.mainFontWeight};
  }
  h2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: ${theme.font.mainFontWeight};
    &.hasMargin {
      margin-bottom: 8px;
    }
  }
  .small-label {
    font-size: 12px;
    line-height: 15px;
  }
  .form-container {
    margin-top: 32px;
  }
  .input-percent-small-width {
    width: 68px;
    border-radius: 4px;
    padding: 10px 8px;
    text-align: right;
    margin-right: 8px;
    &.dollar {
      border-radius: 4px;
      width: 176px;
      text-align: left;
      margin-left: 24px;
      &.big_size {
        width: 280px;
      }
    }
  }
  .input-small-width {
    width: 68px;
    margin-right: 8px;
    text-align: center;
  }
  .input-label {
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
  .flex-row {
    display: flex;
    align-items: center;
    width: 832px;
    margin-bottom: 12px;
    flex-wrap: wrap;
    &.full-size {
      width: 100%;
    }
    .content-label {
      width: 132px;
      margin-left: 24px;
      &.small_space {
        width: 20px;
      }
      &.has_big_space {
        margin-left: 100px;
      }
    }
  }
  .notice-message {
    margin-top: 15px;
    color: ${theme.palette.veryMediumGrey};
  }
  .error-info {
    color: ${theme.palette.watermelonRed};
    font-size: 14px;
    margin-top: -8px;
  }
`;

export const WorkflowWrapper = styled.div`
  height: auto;
  margin: 40px auto;
  line-height: 20px;
  width: 587px;
  &.no-margin {
    margin: 0 150px;
  }
  .header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    font-weight: bold;
    color: ${theme.palette.veryMediumGrey};
  }
  .secondary-label {
    color: ${theme.palette.veryMediumGrey};
  }

  .step {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    .circle {
      background: ${theme.palette.brightGreen};
      border-radius: 100%;
      width: 24px;
      height: 24px;
      display: inline-block;
    }
    .line {
      top: 23px;
      left: 11px;
      height: calc(100% + 20px);
      position: absolute;
      border-left: 2px solid ${theme.palette.brightGreen};
    }
  }
  .v-stepper {
    position: relative;
  }

  .step:last-child .circle {
    visibility: visible;
    height: 24px;
    width: 24px;
    background: url(${certIconGreen}) no-repeat;
    background-size: contain;
  }

  .step:last-child .line {
    display: none;
  }
  .step {
    .content {
      width: inherit;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 32px;
    }
  }
  .step .step-name {
    margin-left: 50px;
    font-weight: bold;
  }
  .step .approver {
    li {
      list-style: none;
      margin-bottom: 18px;
      display: flex;
      align-items: center;
      :last-child {
        margin-bottom: 0;
      }
    }
    .person-avatar {
      width: 20px;
      height: 20px;
      background: url(${avatarSmallIcon}) no-repeat center;
      float: left;
      margin-right: 18px;
    }
  }
`;

export const HelperContainer = styled.div`
  padding: 12px 0px 12px 16px;
  gap: 8px;

  width: 881px;
  height: 44px;

  background: linear-gradient(
      0deg,
      ${theme.palette.lightPastelBlue},
      ${theme.palette.lightPastelBlue}
    ),
    ${theme.palette.greyWhite};
  border-radius: 4px;
  line-height: 20px;
  color: ${theme.palette.tealBlue};
  margin: 32px 0 0 0;
`;

export const DeleteButton = styled(IconButton)`
  background: url(${deleteIcon}) no-repeat center;
  margin-left: 29px;
`;

export const TableContainer = styled.div`
  width: 100%;
  height: auto;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${theme.palette.white};
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: end;

  table {
    height: 100%;
    width: 100%;
    border-spacing: 1px 0;
    border-collapse: separate;
    table-layout: fixed;
    font-size: 16px;
    font-weight: ${theme.font.normalFontWeight};
    color: ${theme.palette.gunmetal};
    tbody {
      tr:nth-child(even) {
        background-color: ${theme.palette.greyWhite};
      }
      tr:nth-child(odd) {
        background-color: ${theme.palette.white};
      }
      tr {
        &:hover {
          cursor: pointer;
          background-color: ${theme.palette.veryColdGrey};
        }
      }
    }
    .margin-left {
      margin-left: 8px;
    }
  }

  thead {
    background-color: ${theme.palette.gunmetal};
    height: 44px;
    box-sizing: border-box;
  }
  th {
    text-align: left;
    padding: 0 15px;
    color: ${theme.palette.white};
    font-weight: ${theme.font.mainFontWeight};
    line-height: 14px;
    position: relative;
    box-sizing: border-box;
    height: 44px;
    font-size: 12px;
    &.name {
      width: 292px;
    }
    &.number {
      width: 104px;
    }
    &.value {
      width: 160px;
    }
  }
  td {
    padding: 8px 16px;
    box-sizing: border-box;
    min-height: 44px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: right;
    &.align-left {
      text-align: left;
      &.flex {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .contract-name {
          max-width: 100%;
          margin-right: 8px;
          line-height: 20px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
    &.action {
      text-align: center;
      padding: 0;
    }
    &.empty {
      background-color: ${theme.palette.white} !important;
    }
    .secondary-label {
      font-size: 12px;
      color: ${theme.palette.veryMediumGrey};
      margin-top: 4px;
      flex-basis: 100%;
    }
  }
  .img-sort {
    height: 24px;
    width: 24px;
    mask: url(${whiteIcon}) no-repeat center;
    background-color: ${theme.palette.veryMediumGrey};
    background-size: contain;
    cursor: pointer;
    box-sizing: border-box;
    position: absolute;
    right: 10px;
    bottom: 5px;
    &.up {
      mask: url(${whiteSetIcon}) no-repeat center;
      background-size: contain;
      bottom: 15px;
    }
    &.active {
      background-color: ${theme.palette.white};
    }
  }
`;

export const ContractListWrapper = styled.div`
  margin-top: 40px;
`;

export const MemberWrapper = styled.div`
  width: 100%;
  margin: 32px 0;
  .list-wrap {
    display: grid;
    grid-template-columns: 52px 362px 362px 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 32px;
    margin-bottom: 16px;
    input {
      width: 364px;
      text-transform: capitalize;
      color: ${theme.palette.gunmetal};
    }
    &.header {
      font-weight: bold;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  input[type="radio"] {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    background: url(${radioOffIcon}) no-repeat;
    background-size: contain;
    margin: 10px 12px;
    cursor: pointer;
    :checked {
      background: url(${radioOnIcon}) no-repeat;
      background-size: contain;
    }
  }
  .member-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 16px;
    margin-bottom: 8px;
    width: 100%;
    height: 64px;

    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.lightWolfGrey};
    border-radius: 4px;
    .person-avatar {
      height: 40px;
      width: 40px;
      mask: url(${avatarIcon});
      background-color: ${theme.palette.gunmetal};
    }
    .person-info {
      height: 100%;
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        float: left;
        line-height: 20px;
        font-weight: bold;
      }
      .main-role {
        padding: 4px 12px;
        background: ${theme.palette.veryWarmLightGrey};
        border-radius: 16px;
        font-weight: bold;
        font-size: 12px;
        margin-left: 8px;
        float: left;
      }
      .email {
        font-size: 14px;
        color: ${theme.palette.veryMediumGrey};
      }
    }

    .role {
      margin-left: auto;
      font-size: 14px;
      text-transform: capitalize;
    }
  }

  .show_hide_controller {
    display: flex;
    cursor: pointer;
    margin: 42px 0;
    .label {
      color: ${theme.palette.veryMediumGrey};
      line-height: 24px;
      font-weight: bold;
    }
    .img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background: url(${upIcon}) no-repeat;
      object-fit: contain;
      &.down {
        transform: rotate(180deg);
      }
    }
  }
`;

export const DelButton = styled(IconButton)`
  background: url(${deleteIcon}) no-repeat;
  &.has-margin {
    margin-left: -16px;
    margin-top: 10px;
  }
`;

export const MoveProject = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  button {
    float: none;
  }

  .has_margin {
    &.grid {
      display: grid;
      grid-template-columns: 256px 160px;
      grid-column-gap: 24px;
    }
  }
`;

export const ProjectStatusTag = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: max-content;
  padding: 10px 12px;
  font-size: 16px;
  border-radius: 4px;
  font-weight: bold;
  background-color: ${theme.palette.veryColdGrey};

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const ContractStatusTag = styled.div`
  padding: 4px 14px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  height: 23px;
  display: flex;
  align-items: center;
  width: fit-content;

  &.red {
    background-color: ${theme.palette.lightPastelPink};
    color: ${theme.palette.watermelonRed};
  }
  &.yellow {
    background-color: ${theme.palette.paleYellow};
    color: ${theme.palette.tangerineOrange};
  }
  &.green {
    background-color: ${theme.palette.lightPastelGreen};
    color: ${theme.palette.brightGreen};
  }

  .new-icon {
    background-image: url(${noticeIcon});
    background-size: cover;
    margin-right: 9px;
    width: 16px;
    height: 16px;
  }
`;

export const SettingsWrapper = styled.div`
  width: 100%;

  .item-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
    height: 24px;
  }
  .input {
    margin-top: 32px;
  }
`;

export const CheckBoxStyle = styled.div`
  display: flex;

  &.margin-bottom {
    margin-bottom: 58px;
  }

  input {
    margin: auto;
    margin-right: 15px;
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    background: url(${untickedIcon}) no-repeat;
    background-size: contain;
    cursor: pointer;
    :checked {
      background: url(${tickedIcon}) no-repeat;
    }
  }

  label {
    line-height: 20px;
    cursor: pointer;
  }
`;

export const AddressSection = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: 24px;
  h3 {
    margin: 0;
  }

  .searchAddressClass {
    margin: 0;
  }

  .input-field {
    margin: 0;
  }
  .error {
    margin: 10px 0 0 0;
  }
`;

export const ActionsWrap = styled.div`
  position: absolute;
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 15px;
  height: auto;
  top: 160px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  flex: none;
  z-index: 9;
  background-color: white;
  .action {
    cursor: pointer;
  }
`;

export const AccountWrap = styled.div`
  display: flex;
  padding: 24px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  border: ${(props) =>
    props.hasError
      ? `1px solid ${theme.palette.watermelonRed}`
      : `1px solid ${theme.palette.lightWolfGrey}`};
  width: 100%;
  margin: 16px 0;

  .account-info {
    outline: none;
    border-radius: 4px;
    width: 95%;
    border: none;
    color: ${theme.palette.gunmetal};
    padding: 0;
    resize: none;
  }
  .delete-icon {
    width: 24px;
    height: 24px;
    mask: url(${deleteIcon}) no-repeat;
    background-color: ${theme.palette.gunmetal};
    cursor: pointer;
  }
`;

export const LinkedBanner = styled.div`
  width: 100%;
  margin-top: 40px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: ${theme.palette.veryWarmLightGrey};
  display: grid;
  grid-template-columns: 24px auto 100px;
  grid-column-gap: 8px;

  &.contract {
    margin-top: 0px;
    &.settings {
      margin-top: 56px;
    }
  }

  div {
    align-self: center;
  }
  .webpage-link {
    a,
    a:hover,
    a:visited,
    a:active {
      color: inherit;
    }
    text-decoration: underline;
  }
`;

export const IconLink = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${linkIcon});
`;
