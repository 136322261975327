import React from "react";
import { connect } from "react-redux";
import ClaimCertSummaryComponent from "pages/makeclaim/shared/newSummaryComponent";
import { findKeyByValue } from "../../../../utils/helper";

const SummaryPart = (props) => {
  const { data, accountConfig } = props;
  const dataJS = data?.toJS();
  const cisJson = accountConfig?.getIn(["cis", "json", "status"])?.toJS();
  const cisStatus = findKeyByValue(cisJson, dataJS?.cis_status);
  return (
    <ClaimCertSummaryComponent
      accountConfig={accountConfig}
      readOnly={true}
      summaryType={"claim"}
      gst={dataJS?.gst}
      retentionExists={dataJS?.has_retention}
      currentToDateData={dataJS?.claim_to_date_summary}
      previousData={dataJS?.previous}
      thisCalculatedData={dataJS?.this}
      calculatedCisStatus={cisStatus}
      {...props}
    />
  );
};

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
  cisStatus: state.getIn(["makeaclaim", "cisStatus"]),
  domestic_reverse_charge: state.getIn([
    "makeaclaim",
    "domestic_reverse_charge",
  ]),
});

export default connect(mapStateToProps, null)(SummaryPart);
