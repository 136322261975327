import styled from "styled-components";
import * as theme from "../../theme";

//bottom btn style
export const ProjectBottomWrapper = styled.div`
  width: 100%;
  height: 40px;
  margin: 56px 0 62px 0;

  &.review,
  .right {
    display: flex;
  }
  &.review {
    justify-content: space-between;
    float: left;
    margin-bottom: 90px; // Gives more space for the pdf dropdown
  }
`;

export const ProjectInfoWrapper = styled.div`
  width: 1254px;
  height: auto;
  margin: 0 0 56px 0;
  display: flex;
  flex-direction: column;
  .bottomWrapper {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
    color: ${theme.palette.gunmetal};
    background-color: ${theme.palette.white};
  }
  .line {
    height: 1px;
    background-color: ${theme.palette.lightWolfGrey};
  }
`;

export const disabledInputStyle = {
  marginRight: 8,
  border: "none",
  backgroundColor: "#F2F2F2",
};

export const OutLayerWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 40px;
  margin-bottom: 56px;
  box-sizing: border-box;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  display: table;
  color: ${theme.palette.gunmetal};
  &.tableWidth {
    padding: 40px 18px;
  }
  // Style for lineitem_options based on lump
  .grey-dash {
    display: inline-block;
    width: 10px;
    height: 1px;
    background-color: grey;
  }
`;
