import React from "react";
import { useDispatch } from "react-redux";

import { uploadFileWithProgress } from "../store/actionCreators";
import Dropzone from "../../upload_file_display/dropzone";

const Index = ({ api }) => {
  const dispatch = useDispatch();

  const handleDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      dispatch(uploadFileWithProgress(api, file));
    });
  };
  return (
    <div className="dropzone">
      <Dropzone onFilesAdded={handleDrop} />
    </div>
  );
};

export default Index;
