import React, { Fragment } from "react";
import { ItemWrapper, Icon, Content, Buttons, Delete } from "../styled";
import {
  BlueWhiteBtn as AcceptBtn,
  BlueOutlinedBtn as ActionBtn,
} from "../../../common/button";
import due7Icon from "../../../statics/img/notice-blue.svg";
import due3Icon from "../../../statics/img/notice-yellow.svg";
import overdueIcon from "../../../statics/img/icon-information-red.svg";
import unclaimIcon from "../../../statics/img/icon-information-black.svg";
import invitationIcon from "../../../statics/img/invitation-yellow.svg";
import systemIcon from "../../../statics/img/system-notice-yellow.svg";
import { format } from "date-fns";
import Link from "../../../common/linkWithQuery";

function checkIcon(type, field) {
  let iconName = systemIcon;
  switch (field) {
    case "Due_3":
      iconName = due3Icon;
      break;
    case "Due_7":
      iconName = due7Icon;
      break;
    case "Overdue_0":
      iconName = overdueIcon;
      break;
    case "unclaim":
      iconName = unclaimIcon;
      break;
    default:
      if (type === "invitation") {
        iconName = invitationIcon;
        break;
      } else {
        iconName = systemIcon;
        break;
      }
  }
  return iconName;
}
function checkDueType(data, field) {
  let type = "",
    day_num = 0;
  if (field === "payclaim_due" || field === "paycert_due") {
    let days = data.split(";")[0];
    day_num = parseInt(days.split("d")[0], 10);
    if (day_num <= 3) {
      type = "Due_3";
    } else if (day_num > 3) {
      type = "Due_7";
    }
  } else if (field === "payclaim_overdue" || field === "paycert_overdue") {
    type = "Overdue_0";
  }
  return { type, day_num };
}
function formatTime(time) {
  var now = Date.now(),
    stime = new Date(now).getTime(),
    date = "";
  var etime = new Date(time).getTime();
  var usedTime = stime - etime;
  var days = Math.floor(usedTime / (24 * 3600 * 1000));
  if (days >= 1) {
    date = format(new Date(time), "MMM d, yyyy, h:mm aaaa");
  } else {
    var hours = Math.floor(usedTime / (3600 * 1000));
    if (hours === 0) {
      date = Math.floor(usedTime / (60 * 1000)) + " minutes ago";
    } else {
      if (hours === 1) {
        date = hours + " hour ago";
      } else {
        date = hours + " hours ago";
      }
    }
  }

  return date;
}
const Button = (props) => {
  const {
    type,
    contractId,
    claimProId,
    certProId,
    deleteNotice,
    noticeId,
    accountConfig,
  } = props;
  switch (type) {
    case "paycert_due":
    case "paycert_overdue":
      return (
        <Fragment>
          <Delete onClick={() => deleteNotice(noticeId)} />
          <Link to={`/make-cert/cert/${certProId}/${contractId}`}>
            <ActionBtn
              title={accountConfig?.getIn(["cert", "noun"])}
              padding="10px 31px"
              margin="0 24px 0 0"
            />
          </Link>
        </Fragment>
      );
    case "payclaim_due":
    case "payclaim_overdue":
      return (
        <Fragment>
          <Delete onClick={() => deleteNotice(noticeId)} />
          <Link to={`/make-claim/claim/${claimProId}/${contractId}`}>
            <ActionBtn
              title={accountConfig?.getIn(["claim", "noun"])}
              padding="10px 26px"
              margin="0 24px 0 0"
            />
          </Link>
        </Fragment>
      );
    case "invitation":
      return (
        <Fragment>
          <Delete onClick={() => deleteNotice(noticeId)} />
          {/* <Link to={"/invitation/cert/" + projectId}> */}
          <AcceptBtn title="View" padding="10px 31px" />
          {/* </Link> */}
        </Fragment>
      );
    default:
      return <Delete onClick={() => deleteNotice(noticeId)} />;
  }
};

const Item = (props) => {
  const certWord = props.accountConfig?.getIn(["cert", "noun"]);
  const claimTerm = props.accountConfig.get("claim");
  let title = "",
    label = "",
    id = props.content.get("contract_uuid"),
    claimProId = props.content.get("claim_project_id")
      ? props.content.get("claim_project_id")
      : "",
    certProId = props.content.get("cert_project_id")
      ? props.content.get("cert_project_id")
      : "",
    noticeId = props.content.get("id"),
    type = props.content.get("type"),
    content = props.content.get("content"),
    obj = checkDueType(content, type),
    field = obj.type,
    num = obj.day_num,
    day_label = "",
    date = "";
  date = formatTime(props.content.get("createdate"));
  if (num === 1) {
    day_label = num + " day";
  } else {
    day_label = num + " days";
  }
  switch (type) {
    case "payclaim_overdue":
      title = `${claimTerm?.get("noun")} ${field.split("_")[0]}`;
      label = (
        <div>
          You have an <b> Overdue </b> {claimTerm?.get("noun")} for project
          <b> {content.split(";")[1]}</b>
        </div>
      );
      break;
    case "payclaim_due":
      if (num === 0) {
        label = (
          <div>
            <b>Today</b> is the <b>last day</b> to{" "}
            {claimTerm?.get("noun") === "Claim"
              ? "claim project"
              : claimTerm?.get("verb")?.toLowerCase()}{" "}
            <b>{content.split(";")[1]}</b>
          </div>
        );
      } else {
        label = (
          <div>
            You have <b>{day_label}</b> to{" "}
            {claimTerm?.get("noun") === "Claim"
              ? "claim project"
              : claimTerm?.get("verb")?.toLowerCase()}{" "}
            <b>{content.split(";")[1]}</b>
          </div>
        );
      }
      title = `${claimTerm?.get("noun")} ${field.split("_")[0]}`;
      break;
    case "paycert_due":
      if (num === 0) {
        label = (
          <div>
            <b>Today</b> is the <b>last day</b> to certify project{" "}
            <b>{content.split(";")[1]}</b>
          </div>
        );
      } else {
        label = (
          <div>
            You have <b>{day_label} </b> to certify project{" "}
            <b>{content.split(";")[1]}</b>
          </div>
        );
      }
      title = certWord + " " + field.split("_")[0];
      break;
    case "paycert_overdue":
      title = certWord + " " + field.split("_")[0];
      label = (
        <div>
          You have an <b> Overdue </b> certify for project
          <b> {content.split(";")[1]}</b>
        </div>
      );
      break;
    case "invitation":
      title = "Project Invitation";
      label = (
        <div>
          <b>{content.split(";")[0]} </b> has invited you to project
          <b> {content.split(";")[1]}</b>
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <ItemWrapper>
      <Icon icon={checkIcon(type, field)} />
      <Content>
        <div className="label">{title}</div>
        <div className="notice">{label}</div>
        <div className="date">{date}</div>
      </Content>
      <Buttons>
        <Button
          type={type}
          contractId={id}
          claimProId={claimProId}
          certProId={certProId}
          deleteNotice={props.deleteNotice}
          noticeId={noticeId}
          accountConfig={props.accountConfig}
        />
      </Buttons>
    </ItemWrapper>
  );
};

export default function ListItem(props) {
  return <Item {...props} />;
}
