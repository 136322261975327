import styled from "styled-components";
import * as theme from "../../theme";
import requiredIcon from "../../statics/img/icon-error-yellow.svg";
import listIcon from "../../statics/img/icon-list-expand.svg";

export const NavigatorSection = styled.div`
  display: flex;
  position: fixed;
  z-index: 100;
  left: calc((100% - 960px) / 2 - 218px);
  transition: left 0.2s ease-out;
  top: ${(props) => props.offsetY + "px"};

  @media (max-width: 1396px) {
    left: 24px;
  }
  .container {
    display: flex;
    height: 40px;
    width: 52px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    position: relative;
    background: url(${listIcon}) no-repeat;
    background-color: ${theme.palette.white};
    background-size: 24px;
    background-position: center;
    cursor: pointer;
  }
`;

export const Wrap = styled.div`
  &.append {
    top: 48px;
    left: 0;
    position: absolute;
  }
  display: flex;
  width: 164px;
  flex-direction: column;
  align-items: center;
  border-radius: 2px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);

  .navigator-item {
    display: flex;
    height: 40px;
    align-items: center;
    padding: 12px;
    align-self: stretch;
    font-size: 14px;
    border-left: 1px solid ${theme.palette.wolfGrey};
    &.section-title {
      color: ${theme.palette.veryMediumGrey};
      font-size: 12px;
      font-weight: 700;
      border: none;
      &:hover {
        color: ${theme.palette.veryMediumGrey};
        cursor: initial;
      }
    }
    &.selected {
      background: ${theme.palette.veryWarmLightGrey};
      font-weight: 700;
      border-left: 1px solid ${theme.palette.tangerineOrange};
    }
    &:hover {
      color: ${theme.palette.tangerineOrange};
      cursor: pointer;
    }
    .navigator-item_label {
      display: flex;
      align-items: center;
      .required-icon {
        width: 16px;
        height: 16px;
        background: url(${requiredIcon}) no-repeat;
        margin-left: 4px;
      }
    }
  }
`;
