import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CertTableWrapper } from "./review_cert_style";
import TableComponent from "./basic_table";
import CommentList from "./comment_table";

import { convertToPercent } from "../../utils/numeralHelper";
import { checkIsParent } from "../../utils/helper";
import {
  currencySubunitToUnit,
  convertToCurrencyUnit,
} from "../../utils/currencyHelper";
import profile from "../../utils/profile";
import {
  CONTRACT,
  CONTRACT_TYPE,
  EDIT,
  RATE,
  VIEW,
} from "../../utils/constants";
import { headerFormatter } from "../../utils/tableFormatHelper";
import { countComments } from "./table_utils";
import {
  ProvisionalDescription,
  getExpandProvisionalParents,
} from "../lineitem/sharedComponent";

class Table extends Component {
  constructor() {
    super();
    this.state = {
      current: 1,
      expands: [],
      allowEditContract: true,
      allowViewContract: false,
    };
  }
  handlePageChange = (current) => {
    this.setState({ current });
  };

  isTreeExpanded = (id) => {
    return this.state.expands.includes(id);
  };

  expandProvisionalParent = (line) => {
    let expanded = getExpandProvisionalParents(line, this.state.expands);
    this.setState({ expands: expanded });
  };

  render() {
    const { expands, allowEditContract, allowViewContract } = this.state;
    const {
      projectData,
      tableContent,
      tableField, //base_contract / variation / contra same with the api parameter name
      certTotal,
      thisCertTotal,
      updateComment,
      isNewCert,
      table_title,
      accountConfig,
      isApproved,
      commentViewOnly,
      contractType,
    } = this.props;

    const showClaimerName =
      contractType === CONTRACT_TYPE.cert ||
      contractType === CONTRACT_TYPE.selfCert;
    const showCurrency = !accountConfig?.get("enable_header_currency");

    const Reason = "Reason";

    const columns_dev = [
      {
        title: "Ref",
        className: "align_left lineitem-ref",
        render: "label",
        width: 52,
      },
      {
        title: "Description",
        className: "align_left",
        treeColumnsName: "childitems",
        width: 211,
        render: (d) => (
          <div className="description">
            <ProvisionalDescription
              data={d}
              isUpdateExpanded={() => {
                return false;
              }}
              isTreeExpanded={this.isTreeExpanded}
              expandProvisionalParent={this.expandProvisionalParent}
            />
          </div>
        ),
      },
      {
        title: headerFormatter(
          "Total",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        width: 110,
        render: (d) => currencySubunitToUnit(d.total, showCurrency),
      },
      {
        title: headerFormatter(
          `${accountConfig?.getIn(["claim", "value"])} Total`,
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        width: 110,
        render: (d) => currencySubunitToUnit(d.claim_value, showCurrency),
      },
      {
        title: "Certified Qty | %",
        className: "align_center",
        width: 127,
        render: (d) => {
          return (
            <div className="percent_qty">
              <div>
                {d.lineitem_options === RATE &&
                d.cert_qty !== 0 &&
                !checkIsParent(d) ? (
                  d.cert_qty
                ) : (
                  <div className="grey-dash"></div>
                )}
              </div>
              <div>{convertToPercent(d.cert_percent / 100)}&nbsp; </div>
            </div>
          );
        },
      },
      {
        title: headerFormatter(
          "Certified Total",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        width: 110,
        render: (d) => currencySubunitToUnit(d.cert_value, showCurrency),
      },
      {
        title: headerFormatter(
          "Variance",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        width: 110,
        render: (d) =>
          currencySubunitToUnit(d.claim_value - d.cert_value, showCurrency),
      },

      {
        title: headerFormatter(
          `This ${accountConfig?.getIn(["cert", "noun"])}`,
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        width: 110,
        render: (d) =>
          currencySubunitToUnit(
            d.cert_value - d.cert_to_date_value,
            showCurrency,
          ),
      },
      {
        group: Reason,
        className: "hidden th_align_left reason",
        width: 190,
        render: (d) => (
          <div className="inline-wrapper">
            <div className="reason-content">{d.cert_reason}</div>
          </div>
        ),
      },
      {
        group: Reason,
        className: "hidden th_align_left reviewCertComment",
        width: 44,
        render: (d) => (
          <div className="commentDiv">
            {isApproved
              ? countComments(d, d?.id) > 0 && (
                  <CommentList
                    data={d}
                    expands={expands}
                    onTreeExpand={(key) => {
                      return this.setState({
                        expands: [...this.state.expands, key],
                      });
                    }}
                    updateComment={updateComment}
                    type={"cert"}
                    viewOnly={true}
                    otherParty={
                      showClaimerName
                        ? projectData.get("payee_entity_name")
                        : projectData.get("payer_entity_name")
                    }
                  />
                )
              : !isNewCert &&
                (allowEditContract || allowViewContract) && (
                  <CommentList
                    data={d}
                    expands={expands}
                    onTreeExpand={(key) => {
                      return this.setState({
                        expands: [...this.state.expands, key],
                      });
                    }}
                    updateComment={updateComment}
                    type={"cert"}
                    viewOnly={commentViewOnly}
                    otherParty={
                      showClaimerName
                        ? projectData.get("payee_entity_name")
                        : projectData.get("payer_entity_name")
                    }
                  />
                )}
          </div>
        ),
      },
    ];

    const totalList = {
      //currencySubunitToUnit   item/100 format
      //convertToCurrencyUnit   item.format
      total: currencySubunitToUnit(
        projectData.get(`total_${tableField}`),
        showCurrency,
      ),
      claimed_total: currencySubunitToUnit(
        projectData.get(`total_claim_${tableField}`),
        showCurrency,
      ),
      certified_total: convertToCurrencyUnit(certTotal, showCurrency),
      variance_total: convertToCurrencyUnit(
        projectData.get(`total_claim_${tableField}`) / 100 - certTotal,
        showCurrency,
      ),
      this_cert_total: convertToCurrencyUnit(thisCertTotal, showCurrency),
    };

    return (
      <CertTableWrapper>
        <TableComponent
          onTreeExpand={(keys) => this.setState({ expands: keys })}
          treeExpandKeys={expands}
          tableContent={tableContent.toJS()}
          tableColumns={columns_dev}
          tableCaption={table_title}
          hasSort
          externalSortOptions={{
            claim_value: `${accountConfig?.getIn(["claim", "value"])} Value`,
            cert_percent: `${accountConfig?.getIn(["cert", "value"])} %`,
            cert_value: `${accountConfig?.getIn(["cert", "value"])} Value`,
          }}
          hasEnhancedTableFooter
        >
          <table>
            <tfoot>
              <tr>
                <>
                  <td className="white before" />
                  <td className="title">Sub Total</td>
                  <td className="value">{totalList.total}</td>
                  <td className="value">{totalList.claimed_total}</td>
                  <td className="value big">{totalList.certified_total}</td>
                  <td className="value"> {totalList.variance_total}</td>
                  <td className="light_blue"> {totalList.this_cert_total}</td>
                  <td className="white after" />
                </>
              </tr>
            </tfoot>
          </table>
        </TableComponent>
      </CertTableWrapper>
    );
  }
  componentDidMount() {
    const { proId, contractId } = this.props.match.params;
    profile.checkPermission(CONTRACT, EDIT, proId, contractId).then((res) => {
      this.setState({ allowEditContract: res });
    });
    profile.checkPermission(CONTRACT, VIEW, proId, contractId).then((res) => {
      this.setState({ allowViewContract: res });
    });
  }
}

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default withRouter(connect(mapStateToProps, null)(Table));
