import React, { PureComponent } from "react";
import { connect } from "react-redux";
import debounce from "lodash/debounce";

import { BgWrapper, HeaderTitle } from "../../../common/shared_style";
import { ProjectInfoWrapper } from "../styled";

import ApprovalChart from "../../../common/approval_flow";
import ApproveActions from "../../../common/approval_flow/approve_action";

import LeaveComment from "../../../common/approval_flow/components/approval_comment";
import Attachment from "../../../common/claim_cert_attachment";
import Menu from "../../../common/breadcrumbs";
import Loading from "../../../common/loading";
import BctiModal from "../../../common/bcti";

import { OverviewPageUrlContractType } from "../../contract/common";
import ProjectInfo from "./components/projectInfo";
import Table from "./components/paycertReviewTable";
import Summary from "./components/summary";
import Notes from "./components/notes";
import Contact from "./components/emailInfo";
import NavBarBottom from "./components/navBarBottom";
import { getMenuLink } from "../components/pageMenu";
import profile from "../../../utils/profile";
import {
  CONTRACT_TYPE,
  CONTRACT,
  EDIT,
  STATUS_DRAFT,
} from "../../../utils/constants";

import history from "../../../utils/history";

import { actionCreators } from "../store";
import { actionCreators as actionCreatorsContract } from "../../contract/store";
import { checkIfHavePermission as makeACertCheckIfHavePermission } from "../../contract/view/components/claimHistoryTable";

import API from "../../../server";

function checkIfHavePermission(list, user) {
  if (list.size === 0) {
    return false;
  }
  const { current_step_index, steps } = list.toJS();

  if (current_step_index === steps?.length - 1) {
    return false;
  }
  const currentStep = steps?.find((step) => step.step === current_step_index);

  if (!currentStep) {
    return true;
  }

  const approvals = currentStep.approvals || [];

  const userApproval = approvals.find(
    (approval) =>
      approval.user_email === user.get("email") && !approval.approved,
  );

  return !!userApproval;
}
class index extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      allowEditApproval: false,
      bctiInfo: {},
      open: false,
      currentUserApproved: true,
    };
  }

  performIssuePaycert = () => {
    const {
      emailToList,
      emailSubject,
      isSendEmail,
      attachedEmailFiles,
      issuePaycert,
      certId,
      accountInfo,
    } = this.props;

    const { proId, contractId, claimId } = this.props.match.params;

    const isLinked =
      accountInfo?.get("integrations")?.size > 0 ? "true" : "false";

    issuePaycert(
      proId,
      contractId,
      claimId,
      certId,
      isSendEmail,
      emailToList,
      emailSubject,
      attachedEmailFiles,
      isLinked,
    );
  };

  saveIssuePaycert = () => {
    const { proId, contractId } = this.props.match.params;

    const { certInfo } = this.props;

    if (certInfo?.get("is_bcti")) {
      const view =
        certInfo?.get("contract_type") === CONTRACT_TYPE.selfCert
          ? CONTRACT_TYPE.selfCert
          : CONTRACT_TYPE.cert;

      API.check_bcti(proId, contractId, view)
        .then((res) => res.data.data)
        .then((res) => {
          if (
            res.bcti["has_gst"] &&
            res.bcti["has_name"] &&
            res.bcti["has_address"]
          ) {
            this.performIssuePaycert();
          } else {
            this.setState({ open: true, bctiInfo: res.bcti });
          }
        });
    } else {
      this.performIssuePaycert();
    }
  };

  performBctiSubmit = (data) => {
    const { proId, contractId, contractType } = this.props.match.params;
    const { certInfo, accountId } = this.props;

    const isAccountAdmin = profile.isAdminOrOwner();

    if (isAccountAdmin) {
      if (
        contractType === CONTRACT_TYPE.cert ||
        contractType === CONTRACT_TYPE.selfClaim
      ) {
        // When Claim is not Self-Cert/Self-Claim
        this.props.updateBctiInfo(proId, contractId, data);
        const doPerformIssuePaycert = debounce(
          () => this.performIssuePaycert(),
          1200,
        );
        doPerformIssuePaycert();
      } else if (contractType === CONTRACT_TYPE.selfCert) {
        // Update the Payer's details
        const payerCompany = certInfo?.get("payer_company");

        data.entity_id =
          payerCompany?.get("entity_id") || certInfo?.get("payer_entity_id");
        data.entity_name =
          payerCompany?.get("entity_name") ||
          certInfo?.get("payer_entity_name");

        // If BCTI Modal is not returning the data, then use the saved one from the payer details
        data.street_address =
          data?.street_address ||
          payerCompany?.get("street_address") ||
          certInfo?.get("payer_entity_streetaddress");
        data.postal_code =
          data?.postcode ||
          payerCompany?.get("postal_code") ||
          certInfo?.get("payer_entity_postal_code");
        data.suburb =
          data?.suburb ||
          payerCompany?.get("suburb") ||
          certInfo?.get("payer_entity_suburb");
        data.city =
          data?.city ||
          payerCompany?.get("city") ||
          certInfo?.get("payer_entity_city");
        data.state =
          data?.state ||
          payerCompany?.get("state") ||
          certInfo?.get("payer_entity_state");
        data.country =
          data?.country ||
          payerCompany?.get("country") ||
          certInfo?.get("payer_entity_country");
        data.gst_no =
          data?.gst_number ||
          payerCompany?.get("gst_no") ||
          certInfo?.get("payer_gst_number");
        API.upsert_account_company(data)
          .then((res) => res.data)
          .then((res) => res.data.id)
          .then((res) => {
            this.performIssuePaycert();
          });
      }
    } else {
      history.push(
        `/contract/${proId}/${OverviewPageUrlContractType(
          contractType,
        )}/${contractId}?account_id=${accountId}`,
      );
    }

    this.setState({ open: false });
  };

  updateComment = (action, content, comment_id, lineId) => {
    this.props.updateComment(
      this.props.match.params.contractType,
      this.props.match.params.contractId,
      this.props.match.params.claimId,
      this.props.certId,
      action,
      content,
      comment_id,
      lineId,
      this.props.match.params.proId,
    );
  };

  fetchCertWorkflow = async (
    proId,
    contractId,
    claimId,
    certId,
    userProfile,
  ) => {
    const res = await API.read_cert_workflow(
      proId,
      contractId,
      claimId,
      certId,
    );
    const data = res.data.data.data;

    if (data && Object.keys(data).length > 0) {
      const approverPermission = makeACertCheckIfHavePermission(
        data,
        userProfile,
      );
      return approverPermission;
    }
  };

  render() {
    const {
      accountId,
      certInfo,
      claimDetail,
      loading,
      readOnly,

      baseContractTable,
      baseCertTotal,
      baseThisCertTotal,

      variationsTable,
      variationCertTotal,
      variationThisCertTotal,

      sendingEmail,
      approvingCert,

      approvalList,
      approval_comment,
      cert_attachments,
      shared_cert_attachments,
      isAuditAttached,
      exceedAttachLimit,

      approvePaycert,
      leaveComment,
      closeDialog,
      setData,
      downloadPdf,

      deleteFile,
      downloadFile,
      changeSharedFiles,
      setPdfAudit,
      projectInfo,
      certId,
      userProfile,

      materialsTable,
      materialsTotal,
      materialsTotalExcludeRetention,
      readClaim,
      revertByStep,
    } = this.props;

    const { proId, contractId, claimId, contractType } =
      this.props.match.params;

    const { allowEditApproval, currentUserApproved } = this.state;
    const isAccountAdmin = profile.isAdminOrOwner();
    const payerCompany = certInfo?.get("payer_company");
    const hasMaterialData = materialsTable.size !== 0;
    const payerEntityName =
      payerCompany?.get("entity_name") || certInfo?.get("payer_entity_name");
    const editableWorkflow =
      allowEditApproval &&
      (approvalList.get("current_step_index") <
        approvalList?.get("steps")?.size - 1 ||
        approvalList?.get("steps")?.size === 2);
    const showEmailService = approvalList?.get("current_step_index")
      ? approvalList.get("current_step_index") >=
        approvalList?.get("steps")?.size - 1
      : true;
    return (
      <BgWrapper style={{ width: "1295px" }}>
        <Menu
          link={getMenuLink(
            projectInfo,
            contractId,
            certInfo.get("contract_name"),
            contractType,
            claimId,
          )}
          content="Review and Submit Certificate"
        />
        <HeaderTitle className="review">
          Review and Submit Certificate
          <div className="subtitle">
            for {certInfo.get("payee_entity_name")}
          </div>
        </HeaderTitle>
        {!loading ? (
          <ProjectInfoWrapper>
            <ProjectInfo claimDetail={claimDetail} certInfo={certInfo} />
            <Table
              table_title="Base Contract"
              projectData={certInfo}
              tableContent={baseContractTable}
              certTotal={baseCertTotal}
              thisCertTotal={baseThisCertTotal}
              updateComment={this.updateComment}
              isNewCert={false}
              isApproved={false}
              contractType={contractType}
            />
            <Table
              table_title="Variations"
              projectData={certInfo}
              tableContent={variationsTable}
              certTotal={variationCertTotal}
              thisCertTotal={variationThisCertTotal}
              updateComment={this.updateComment}
              isNewCert={false}
              isApproved={false}
              contractType={contractType}
            />
            {hasMaterialData && (
              <Table
                table_title="Materials On/Off Site"
                tableContent={materialsTable}
                projectData={certInfo}
                contractType={contractType}
                updateComment={this.updateComment}
                isMaterial
                isNewCert={false}
                isApproved={false}
              />
            )}
            <div className="bottomWrapper">
              <Summary
                data={certInfo}
                materialsTable={materialsTable}
                materialsTotal={materialsTotal}
                materialsTotalExcludeRetention={materialsTotalExcludeRetention}
                claimDetail={claimDetail}
              />
              <div className="line" />
              <Notes claimInfo={claimDetail} />
              <div className="line" />

              {/* hide attachment section for claim */}
              {contractType !== CONTRACT_TYPE.claim && (
                <>
                  <Attachment
                    title={"Certificate"}
                    data={cert_attachments}
                    readOnly={readOnly}
                    hasSharedFiles
                    changeSharedFiles={(fileId, isAdd) =>
                      changeSharedFiles(
                        proId,
                        contractType,
                        contractId,
                        claimId,
                        certId,
                        fileId,
                        isAdd,
                      )
                    }
                    sharedFiles={shared_cert_attachments}
                    uploadFileApi={{
                      name: "upload_file_contract_cert",
                      config: [contractId, certId],
                    }}
                    readInfo={() =>
                      readClaim(proId, contractId, claimId, contractType)
                    }
                    deleteFile={(fileId) =>
                      deleteFile(
                        contractType,
                        contractId,
                        claimId,
                        certId,
                        fileId,
                      )
                    }
                    downloadFile={downloadFile}
                  />
                  {/* display workflow for self-claim/cert contract */}
                  {(contractType === CONTRACT_TYPE.cert ||
                    contractType === CONTRACT_TYPE.selfClaim) && (
                    <ApprovalChart
                      workflowURL={`/project/${proId}/contract/${contractId}/${contractType}/claim/${claimId}/workflow/${certId}`}
                      data={approvalList}
                      isAuditAttached={isAuditAttached}
                      timezone={certInfo?.get("contract_timezone")}
                      isComplete={
                        (certInfo.get("cert_status") || STATUS_DRAFT) !==
                        STATUS_DRAFT
                      }
                      editable={editableWorkflow}
                      setPdfAudit={(e) =>
                        setPdfAudit(
                          proId,
                          contractId,
                          claimId,
                          certId,
                          e.target.checked,
                        )
                      }
                    />
                  )}
                </>
              )}

              {showEmailService && (
                <>
                  <div className="line" />
                  <Contact {...this.props} />
                </>
              )}
            </div>
            {checkIfHavePermission(approvalList, userProfile) && !readOnly && (
              <ApproveActions
                approvers={approvalList}
                goToRevert={(comment, stepId) =>
                  revertByStep(
                    proId,
                    contractId,
                    claimId,
                    certId,
                    stepId,
                    comment,
                  )
                }
                approving={approvingCert}
                handleApprove={() => setData("leaveComment", true)}
                workflowType={"certificate"}
              />
            )}
            <NavBarBottom
              readOnly={readOnly}
              proId={proId}
              contractId={contractId}
              claimId={claimId}
              certId={certId}
              accountId={accountId}
              profile={userProfile}
              approvalList={approvalList}
              sendingEmail={sendingEmail}
              approvingCert={approvingCert}
              contractInfo={certInfo}
              exceedAttachLimit={exceedAttachLimit}
              handleIssuePaycert={() => this.saveIssuePaycert()}
              options={[
                {
                  label: "View file in new tab",
                  value: () =>
                    downloadPdf(proId, contractId, claimId, certId, false),
                },
                {
                  label: "Save file to your device",
                  value: () =>
                    downloadPdf(proId, contractId, claimId, certId, true),
                },
              ]}
              handleApprovePaycert={() => setData("leaveComment", true)}
              contractType={contractType}
              currentUserApproved={currentUserApproved}
            />
            {leaveComment && (
              <LeaveComment
                open={leaveComment}
                content={approval_comment}
                closeDialog={closeDialog}
                handleInput={(e) => setData("approval_comment", e.target.value)}
                goToApprove={() =>
                  approvePaycert(proId, contractId, claimId, certId)
                }
                type="cert"
              />
            )}
          </ProjectInfoWrapper>
        ) : (
          <Loading />
        )}
        <BctiModal
          isOpen={this.state.open}
          submit={(data) => {
            this.performBctiSubmit(data);
          }}
          close={() => {
            this.setState({ open: false });
          }}
          bctiInfo={this.state.bctiInfo}
          isAccountAdmin={isAccountAdmin}
          page="issueCert"
          contactCompany={
            contractType === CONTRACT_TYPE.selfCert
              ? payerEntityName
              : undefined
          }
        />
      </BgWrapper>
    );
  }
  componentDidMount() {
    const projectId = this.props.match.params.proId;
    const contractId = this.props.match.params.contractId;
    const claimId = this.props.match.params.claimId;

    if (projectId && contractId) {
      profile
        .checkPermission(CONTRACT, EDIT, projectId, contractId)
        .then((res) => {
          this.setState({ allowEditApproval: res });
        });

      const { certId, userProfile } = this.props;
      this.fetchCertWorkflow(
        projectId,
        contractId,
        claimId,
        certId,
        userProfile,
      ).then((approverPermission) => {
        this.setState({ currentUserApproved: approverPermission });
      });
    }
  }
  componentDidUpdate(prevProps) {
    const { proId, contractId, claimId } = this.props.match.params;
    const { certId, userProfile, approvingClaim } = this.props;

    if (approvingClaim !== prevProps.approvingClaim && !approvingClaim) {
      this.fetchCertWorkflow(
        proId,
        contractId,
        claimId,
        certId,
        userProfile,
      ).then((approverPermission) => {
        this.setState({ currentUserApproved: approverPermission });
      });
    }
  }
}

const mapStateToProps = (state) => ({
  userProfile: state.getIn(["headers", "profile"]),
  leaveComment: state.getIn(["makeacert", "leaveComment"]),
  approval_comment: state.getIn(["makeacert", "approval_comment"]),
  approvingClaim: state.getIn(["makeacert", "approvingCert"]),
  accountInfo: state.getIn(["manageAccount", "accountInfo"]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    issuePaycert(
      proId,
      contractId,
      claimId,
      certId,
      isSendEmail,
      emailToList,
      subject,
      attachedEmailFiles,
      isLinked,
    ) {
      dispatch(
        actionCreators.issueCert(
          proId,
          contractId,
          claimId,
          certId,
          isSendEmail,
          emailToList,
          subject,
          attachedEmailFiles,
          isLinked,
        ),
      );
    },
    closeDialog() {
      dispatch(actionCreators.closeDialog());
    },
    setData(name, value) {
      dispatch(actionCreators.setValue(name, value));
    },
    approvePaycert(proId, contractId, claimId, certId) {
      dispatch(
        actionCreators.approveStepCert(proId, contractId, claimId, certId),
      );
    },

    updateBctiInfo(proId, contractId, value) {
      dispatch(actionCreatorsContract.updateBctiInfo(proId, contractId, value));
    },
    revertByStep(proId, contractId, claimId, certId, stepId, comment) {
      dispatch(
        actionCreators.revertByStep(
          proId,
          contractId,
          claimId,
          certId,
          stepId,
          comment,
        ),
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(index);
