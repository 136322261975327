import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ContractInfoWrapper, StatusTag } from "../styled";
import {
  ContractSummary,
  IconLink,
  LinkedBanner,
} from "../../../project/styled";
import {
  GreenWhiteBtn as GreenBtn,
  BlueWhiteBtn as BlueBtn,
  LightBlueWhiteBtn as LightBlueBtn,
  RedOutlinedBtn as RedBtn,
} from "../../../../common/button";

import Link from "../../../../common/linkWithQuery";
import { parsePercent } from "../../../../utils/numeralHelper";
import { currencySubunitToUnit } from "../../../../utils/currencyHelper";
import { CompleteTooltip } from "../../../project/components/contract";
import InvitationBar from "../../components/invitationBar";
import Confirm from "../../../../common/modal/component/finaliseConfirm";
import Map from "../../../../common/mapbox/map";
import {
  CONTRACT_TYPE,
  STATUS_ACTIVE,
  STATUS_ARCHIVE,
  STATUS_COMPLETED,
  STATUS_CURRENT,
} from "../../../../utils/constants";
import HistoryButton from "common/audit_logs/component/historyButton";
const DetailItem = ({ item, label }) => {
  if (item) {
    return (
      <div>
        <label>
          <b>{label}</b>
        </label>
        <label>
          {item} {label?.includes("GST") ? "%" : ""}
        </label>
      </div>
    );
  } else {
    return null;
  }
};

const Index = (props) => {
  const {
    contractInfo,
    accountId,
    changeContractStatus,
    leaveContract,
    readOnly,
    accountConfig,
    projectInfo,
  } = props;
  const { proId, type, contractId } = props.match.params;
  const history = useHistory();
  const hasRetention = contractInfo.get("retention") ? true : false;
  const [showModal, setShowModal] = useState(false);
  const fullContractType = contractInfo.get("contract_type");
  // claim route
  const claimPath = `/project/${proId}/contract/${contractId}/${contractInfo.get(
    "contract_type",
  )}/claim?account_id=${accountId}`;

  const isLinked =
    contractInfo.get("integration") && projectInfo.get("integration")
      ? true
      : false;

  const contractDetails = {
    contract_number: "Contract Number",
    payee_ref: "Payee Reference",
    po_number: "PO Number",
    gst: accountConfig?.getIn(["gst_rate", "title"]),
  };

  const getTag = (status) => {
    if (status === "current") {
      return "Active";
    } else if (status === "completed") {
      return "Finalised";
    } else if (status === "archived") {
      return "Archived";
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0); //Scroll to Top
  }, []);

  return (
    <>
      <ContractInfoWrapper>
        <div className="sub-wrapper">
          <div className="title">
            {contractInfo.get("name")}
            <StatusTag className={contractInfo.get("status")}>
              {getTag(contractInfo.get("status"))}
            </StatusTag>
            <div style={{ margin: "0 0 0 auto" }}>
              <Link to={`/contract/${proId}/${type}/${contractId}/history`}>
                <HistoryButton hoverMsg={"Contract History"} />
              </Link>
            </div>
          </div>
          {contractInfo.get("trade") && (
            <div className="trade">{contractInfo.get("trade")}</div>
          )}
          {contractInfo.get("description") && (
            <div>{contractInfo.get("description")}</div>
          )}
          <div className="info-list">
            {Object.keys(contractDetails).map((ele) => (
              <DetailItem
                item={contractInfo.get(ele)}
                label={contractDetails[ele]}
                key={ele}
              />
            ))}
          </div>
        </div>
        <Map
          placeName={
            !contractInfo.get("street_address")
              ? ""
              : `${contractInfo.get("street_address")},${contractInfo.get(
                  "suburb",
                )},${contractInfo.get("city")},${contractInfo.get(
                  "postal_code",
                )},${contractInfo.get("state")},${contractInfo.get("country")}`
          }
        />

        <ContractSummary className="has-margin">
          <div className="header-bar">
            <div className="name">Contract Summary</div>
            <div className="complete-info">
              {parsePercent(
                contractInfo.get("cert_to_date_value"),
                contractInfo.get("total_contract_value"),
              )}
            </div>
          </div>
          <div className="info-bar contract-page">
            <div className="num">
              {currencySubunitToUnit(contractInfo.get("total_contract_value"))}
            </div>
            <div className="num">
              {currencySubunitToUnit(contractInfo.get("claimed_to_date_value"))}
            </div>
            <div className="num">
              {currencySubunitToUnit(contractInfo.get("cert_to_date_value"))}
            </div>
            <div className="num">
              {currencySubunitToUnit(
                contractInfo.get("total_contract_value") -
                  contractInfo.get("cert_to_date_value"),
              )}
            </div>
            <label>Total contract value</label>
            <label>{accountConfig?.getIn(["claim", "value"])} to date</label>
            <label>{accountConfig?.getIn(["cert", "value"])} to date</label>
            <label>Remaining</label>
          </div>
          <div className="complete-bar">
            <CompleteTooltip
              label="Claim to Date"
              num={contractInfo.get("claimed_to_date_value")}
              placement="bottom-end"
            >
              <div
                className="claim"
                style={{
                  width:
                    contractInfo.get("claimed_to_date_value") /
                      contractInfo.get("total_contract_value") >
                    1
                      ? "100%"
                      : parsePercent(
                          contractInfo.get("claimed_to_date_value"),
                          contractInfo.get("total_contract_value"),
                        ),
                }}
              />
            </CompleteTooltip>
            <CompleteTooltip
              label={`${accountConfig?.getIn(["cert", "value"])} to Date`}
              num={contractInfo.get("cert_to_date_value")}
            >
              <div
                className="cert"
                style={{
                  width:
                    contractInfo.get("cert_to_date_value") /
                      contractInfo.get("total_contract_value") >
                    1
                      ? "100%"
                      : parsePercent(
                          contractInfo.get("cert_to_date_value"),
                          contractInfo.get("total_contract_value"),
                        ),
                }}
              />
            </CompleteTooltip>
          </div>
        </ContractSummary>

        <div className="sub-wrapper">
          <InvitationBar {...props} readOnly={readOnly} />
        </div>
        {
          <div className="sub-wrapper">
            {contractInfo?.get("is_bcti") &&
              accountConfig?.getIn(["buyer_created_tax_invoices", "status"]) ===
                STATUS_ACTIVE && (
                <div className="bcti">
                  {`${accountConfig?.getIn([
                    "buyer_created_tax_invoices",
                    "title",
                  ])} are active for this contract.`}
                </div>
              )}

            {props.isLinked && (
              <LinkedBanner className="contract">
                <IconLink />
                <div>
                  This contract is linked from another service so some details
                  are provided and cannot be edited.
                </div>
                {/* Hidden for now as webpage does not exist yet
                <div className="webpage-link">
                  <a href="">Find out more</a>
                </div>
              */}
              </LinkedBanner>
            )}
          </div>
        }

        {!readOnly && (
          <div className="sub-wrapper">
            {/* Hide button for the 'cert' side for now (only show on 'claim' or 'self-cert' types)*/}
            {contractInfo.get("contract_type") !== CONTRACT_TYPE.cert &&
              contractInfo.get("status") === STATUS_CURRENT && (
                <LightBlueBtn
                  className="claim-button"
                  title={
                    accountConfig?.getIn(["claim", "noun"]) === "Claim"
                      ? "Make a claim"
                      : accountConfig?.getIn(["claim", "verb"])
                  }
                  onClick={() => history.push(claimPath)}
                />
              )}
            {contractInfo.get("status") === STATUS_CURRENT && (
              <GreenBtn
                title="Finalise Contract"
                className="finalise-button"
                onClick={() => setShowModal(true)}
              />
            )}
            <Link to={`/contract/${proId}/${type}/${contractId}/settings`}>
              <div className="setting-icon" />
            </Link>
            {contractInfo.get("status") === STATUS_COMPLETED && (
              <div className="right">
                <LightBlueBtn
                  title="Reopen Contract"
                  onClick={() =>
                    changeContractStatus(proId, contractId, "reopen")
                  }
                />
                <BlueBtn
                  title="Archive Contract"
                  onClick={() =>
                    changeContractStatus(proId, contractId, "archive")
                  }
                />
              </div>
            )}
            {contractInfo.get("status") === STATUS_ARCHIVE && (
              <BlueBtn
                title="Reopen Contract"
                onClick={() =>
                  changeContractStatus(proId, contractId, "reopen")
                }
              />
            )}
            {contractInfo.get("status") === STATUS_CURRENT &&
              ["claim", "cert"].indexOf(fullContractType) >= 0 &&
              !isLinked && (
                <RedBtn
                  title={
                    ["claim", "cert"].indexOf(fullContractType) >= 0
                      ? "leave"
                      : "delete"
                  }
                  className="leave-button"
                  onClick={() =>
                    leaveContract(proId, contractId, fullContractType)
                  }
                />
              )}
          </div>
        )}
      </ContractInfoWrapper>

      {/* finalise confirmation modal */}
      <Confirm
        visible={showModal}
        hasRetention={hasRetention}
        finalise={() => {
          changeContractStatus(proId, contractId, "finalise");
          setShowModal(false);
        }}
        close={() => setShowModal(false)}
        iconType={hasRetention ? "warning" : "none"}
        textStyle={
          hasRetention
            ? {
                padding: "10px 30px",
                lineHeight: "40px",
                width: "555px",
                height: "195px",
              }
            : {
                padding: "10px 30px",
                lineHeight: "40px",
                width: "555px",
                height: "155px",
              }
        }
        content={
          <>
            {hasRetention && (
              <>
                {accountConfig?.getIn(["claim", "noun"]) === "Claim" ? (
                  <>
                    This contract still has{" "}
                    {accountConfig?.getIn(["claim", "novalue"])?.toLowerCase()}{" "}
                    Retentions.
                    <br />
                  </>
                ) : (
                  <>
                    This contract still has retentions to be{" "}
                    {accountConfig?.getIn(["claim", "value"])?.toLowerCase()}.
                    <br />
                  </>
                )}
              </>
            )}
            You are about to finalise this contract.
            <br />
            No more {accountConfig?.getIn(["claim", "noun"])}s or Retention{" "}
            {accountConfig?.getIn(["claim", "noun"])}s can be made against it.
            <br />
            Are you sure you want to continue?
          </>
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
  projectInfo: state.getIn(["contract", "projectInfo"]),
});

export default connect(mapStateToProps, null)(Index);
