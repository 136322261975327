import React, { Component, createRef } from "react";
import { connect } from "react-redux";

import { BgWrapper, HeaderTitle } from "../../../common/shared_style";

import Prompt from "../../../common/modal/component/prompt";
import AccountForm from "./components/form";
import Menu from "../../../common/breadcrumbs";

import { actionCreators } from "../store";
import { actionCreators as configActionCreators } from "../../../common/config/store";

class Index extends Component {
  render() {
    const { formValue, saved } = this.props;
    const formRef = createRef();

    var link = [{ link: "/manage-account/view", text: "Manage Account" }];
    return (
      <BgWrapper className="account">
        <Prompt when={this.props.isFormDirty && !saved} />
        <Menu link={link} content="Edit Account Details" />
        <HeaderTitle>Edit Account Details</HeaderTitle>
        <AccountForm
          onSubmit={(values) => this.props.updateAccountInfo(values)}
          initialValues={formValue}
          ref={formRef}
          {...this.props}
        />
      </BgWrapper>
    );
  }
  componentDidMount() {
    this.props.getOptions();
    this.props.getCountries();
    this.props.getConfig();
  }
}

const mapStateToProps = (state) => ({
  formValue: state.getIn(["manageAccount", "formValue"]),
  options: state.getIn(["headers", "config", "payclaim"]),
  saved: state.getIn(["manageAccount", "saved"]),
  isFormDirty: state.getIn(["manageAccount", "isFormDirty"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});

const mapDispatchToProps = (dispatch) => ({
  getOptions() {
    dispatch(actionCreators.getAccountInfo());
  },
  getConfig() {
    dispatch(configActionCreators.getAccountConfig());
  },
  updateAccountInfo(value) {
    dispatch(actionCreators.updateAccountInfo(value));
  },
  reset() {
    dispatch(actionCreators.reset());
  },
  getCountries() {
    dispatch(configActionCreators.readCountries());
  },
  setData(stateName, value) {
    dispatch(actionCreators.setData(stateName, value));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Index);
