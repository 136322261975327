import React, { Component } from "react";

import {
  TableStyle,
  VarDetailSection,
  FileWrapper,
  FileDetail,
  ImgClose,
} from "./styled";
import { FormGroup } from "../form/styled";

import TableComponent from "./basic_table";
import Input from "../form";
import { IconDropdown } from "../dropdown/icon_dropdown";
import { currencySubunitToUnit } from "../../utils/currencyHelper";
import { bytesToSize } from "../../utils/helper";
import { formatInTimeZone } from "../../utils/dateTimeHelper";
import {
  dropDecimalColumns,
  headerFormatter,
} from "../../utils/tableFormatHelper";
import { LUMP } from "../../utils/constants";
import Tooltip from "../toolTip";
import { roundNearest } from "../../utils/numeralHelper";
import { ExcludeRetentionBox } from "../lineitem/sharedComponent";
import History from "./log_history";

export const MaterialDetails = ({
  data,
  downloadFile,
  proId,
  contractId,
  readOnlyExpandKeys,
  handleClose,
  timeZone,
}) => {
  return (
    <VarDetailSection>
      {handleClose ? <ImgClose onClick={handleClose} /> : null}
      <FormGroup>
        <History
          data={data}
          proId={proId}
          contractId={contractId}
          timeZone={timeZone}
        />
        <div className="form_body">
          <Input field="child" label="Material Attachments">
            <FileWrapper
              className={
                readOnlyExpandKeys ? "viewDetail expanded" : "viewDetail"
              }
            >
              {data.uploaded_files !== null ? (
                data.uploaded_files.map((item) => {
                  return (
                    <li key={item.id}>
                      <FileDetail>
                        <div className="fileImg attachment" />
                        <div className="fileName">{item.file_name}</div>
                        <div className="size">
                          ({bytesToSize(item.file_size)})
                        </div>
                        {item.file_name.includes("pdf") ? (
                          <div className="delFileImg noBackground">
                            <IconDropdown
                              options={[
                                {
                                  label: "View file in new tab",
                                  value: () =>
                                    downloadFile(
                                      proId,
                                      contractId,
                                      item.id,
                                      item.file_name,
                                      true,
                                    ),
                                },
                                {
                                  label: "Save file to your device",
                                  value: () =>
                                    downloadFile(
                                      proId,
                                      contractId,
                                      item.id,
                                      item.file_name,
                                      false,
                                    ),
                                },
                              ]}
                            />
                          </div>
                        ) : (
                          <div
                            className="delFileImg downloadFileImg"
                            value={item.file_name}
                            onClick={() =>
                              downloadFile(
                                proId,
                                contractId,
                                item.id,
                                item.file_name,
                              )
                            }
                          />
                        )}
                      </FileDetail>
                    </li>
                  );
                })
              ) : (
                <FileDetail>
                  <div className="fileName no-file">No files</div>
                </FileDetail>
              )}
            </FileWrapper>
            <ExcludeRetentionBox
              className={"has-margin"}
              disabled={true}
              checked={data.exclude_retention}
            />
          </Input>
          <Input
            disabled
            label="Detailed Description"
            name="detailed_description"
            value={data.detailed_description || "No Description"}
            width={readOnlyExpandKeys ? "300px" : "600px"}
            field="textarea"
          />
        </div>
      </FormGroup>
    </VarDetailSection>
  );
};

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandKeys: [],
      expandTreeKeys: [],
    };
  }

  checkGreyDash = (line) => {
    return line.lineitem_options === LUMP || line.childitems?.length > 0;
  };

  isTreeExpanded = (id) => {
    return this.state.expandTreeKeys.includes(id);
  };

  isItemFileExpanded = (id) => {
    return this.state.expandKeys.includes(id);
  };

  expandProvisionalParent = (line) => {
    let expanded = getExpandProvisionalParents(line, this.state.expandTreeKeys);
    this.setState({ expandTreeKeys: expanded });
  };

  render() {
    const {
      tableContent,
      tableCaption,
      downloadFile,

      sectionTitle,
      contractInfo,
      proId,
      contractId,
      outstandingClaims,
      accountConfig,
      tableSubtitle,
      hasTableSubtitle,
      isTableSubtitleBold,
      hasSort,
      ...otherProps
    } = this.props;
    const { expandTreeKeys } = this.state;
    const other = <div className="otherEmptyTr" />;

    let claim_to_date = formatInTimeZone(
      contractInfo?.get("up_to_date"),
      "d MMM yyyy",
      contractInfo?.get("time_zone"),
    );
    const showCurrency = !accountConfig.get("enable_header_currency");

    const date = (
      <div className="updateS">
        Up to <span className="dateInline">{claim_to_date || ""}</span>
      </div>
    );
    let dropDecimal = dropDecimalColumns(tableContent.toJS(), [
      "quantity",
      "rate",
      "total",
      "claimed_up_to_date_total",
      "approved_up_to_date_total",
    ]);

    let claimTotal = 0,
      approvedTotal = 0;
    //get material list
    let materialList = contractInfo?.toJS().materials || [];
    if (tableSubtitle.includes("On Site")) {
      materialList.forEach((value) => {
        if (value.on_site) {
          claimTotal += value.claimed_up_to_date_total;
          approvedTotal += value.approved_up_to_date_total;
        }
      });
    } else {
      materialList.forEach((value) => {
        if (!value.on_site) {
          claimTotal += value.claimed_up_to_date_total;
          approvedTotal += value.approved_up_to_date_total;
        }
      });
    }

    const overrideSortOptions = {
      created_date: "Date Created",
      label: "Reference",
      description: "Description",
      claimed_up_to_date_total: `${accountConfig?.getIn([
        "claim",
        "value",
      ])} Value`,
      approved_up_to_date_total: "Approved Value",
    };

    const columns_dev = [
      {
        className: "item-not-show",
        render: "label",
        group: [other, "Ref"],
        width: 67,
      },
      {
        type: "row-expand",
        group: [other, "Ref"],
        className: "file-not-show",
        width: 0,
        onClick: (d, isExpand) => {
          if (isExpand) {
            this.setState({
              expandKeys: [d.id],
            });
          } else {
            this.setState({
              expandKeys: [],
            });
          }
        },
        render: (d) => {
          return () => {
            return (
              <div className="inputWrapper">
                <MaterialDetails
                  data={d}
                  downloadFile={downloadFile}
                  proId={proId}
                  contractId={contractId}
                  timeZone={contractInfo?.get("time_zone")}
                />
              </div>
            );
          };
        },
      },
      {
        title: "Description",
        className: "align_left",
        group: other,
        render: (d) => {
          return <div id={`material_name_${d.id}`}>{d.description}</div>;
        },
        width: 666,
        treeColumnsName: "childitems",
        treeIndent: 10,
      },
      {
        title: headerFormatter(
          `${accountConfig?.getIn(["claim", "value"])} to date`,
          accountConfig?.getIn(["currency", "title"]),
          accountConfig?.get("enable_header_currency"),
        ),
        className: "blue_box",
        render: (d) =>
          currencySubunitToUnit(
            dropDecimal["claimed_up_to_date_total"]
              ? roundNearest(d.claimed_up_to_date_total)
              : d.claimed_up_to_date_total,
            showCurrency,
            dropDecimal["claimed_up_to_date_total"],
          ),
        group: date,
        width: 263,
      },
      {
        title: () => {
          const actualTitle = headerFormatter(
            "Approved to date",
            accountConfig?.getIn(["currency", "title"]),
            accountConfig?.get("enable_header_currency"),
          );
          if (outstandingClaims === 0) {
            return <div>{actualTitle}</div>;
          } else {
            let tooltip =
              outstandingClaims === 1
                ? `There is an outstanding claim`
                : `There are ${outstandingClaims} outstanding claims`;
            return (
              <div>
                <Tooltip title={tooltip}>
                  <div className="hoverIcon" />
                </Tooltip>
                {actualTitle}
              </div>
            );
          }
        },
        className: "blue_box",
        render: (d) =>
          currencySubunitToUnit(
            dropDecimal["approved_up_to_date_total"]
              ? roundNearest(d.approved_up_to_date_total)
              : d.approved_up_to_date_total,
            showCurrency,
            dropDecimal["approved_up_to_date_total"],
          ),
        group: date,
        width: 260,
      },
    ];
    const { expandKeys } = this.state;
    return (
      <>
        <TableStyle>
          <TableComponent
            tableContent={tableContent.toJS()}
            tableColumns={columns_dev}
            tableCaption={tableCaption}
            sectionTitle={sectionTitle}
            hasSort={hasSort === undefined ? true : hasSort}
            overwriteDefaultSortOptions={overrideSortOptions}
            hasEnhancedTableFooter
            hasEnhancedTableToolbar
            rowClassName={(record, index) => {
              if (expandKeys[0] === record.id) {
                return "highlight";
              }
            }}
            expandKeys={expandKeys}
            treeExpandKeys={expandTreeKeys}
            onTreeExpand={(keys) => {
              return this.setState({ expandTreeKeys: keys });
            }}
            tableSubtitle={tableSubtitle}
            hasTableSubtitle={hasTableSubtitle}
            isTableSubtitleBold={isTableSubtitleBold}
            {...otherProps}
          >
            {this.props.children}
            <table>
              <tfoot>
                <tr>
                  <td className="blank-extended" />
                  <td className="title">{"Total"}</td>
                  <td className="claimedTotal">
                    {currencySubunitToUnit(claimTotal, showCurrency)}
                  </td>
                  <td className="claimedTotal">
                    {currencySubunitToUnit(approvedTotal, showCurrency)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </TableComponent>
        </TableStyle>
      </>
    );
  }
}
export default index;
