import { fromJS } from "immutable";
import * as constants from "./constants";

const defaultState = fromJS({
  modalType: null,
  modalProps: { open: false },
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SHOW_MODAL:
      return state
        .set("modalType", action.modalType)
        .set("modalProps", action.modalProps);
    case constants.SET_MODAL_INFO:
      return state.mergeIn(["modalProps"], action.value);
    case constants.HIDE_MODAL:
      return defaultState;
    default:
      return state;
  }
};
