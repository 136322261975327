import React, { useState } from "react";
import PropTypes from "prop-types";

import { UploadCSV, FileFrame } from "./styled";

import { BlueOutlinedBtn as Button } from "../button";
import { bytesToSize } from "../../utils/helper";
import Dropzone from "../upload_file_display/dropzone";

export const FileUpload = (props) => {
  return (
    <FileFrame>
      {props.fileList.length > 0 ? (
        <div className="filelist csv">
          <div>
            <div className="uploadIcon" />
            <div className="name">{props.fileList[0].name}</div>
            <div className="size">({bytesToSize(props.fileList[0].size)})</div>
            <div className="action" onClick={props.clearUpload} />
            <div className="status">Complete</div>
          </div>
        </div>
      ) : (
        <div className="dropzone">
          <Dropzone
            uploadSection="csv"
            onFilesAdded={props.uploadFile}
            alertBox={props.alertBox}
          />
        </div>
      )}
    </FileFrame>
  );
};

const typeMap = {
  variations: {
    title: "Variations",
    sheet: "Variations",
    column: [
      "Ref",
      "Description",
      "Quantity",
      "Unit",
      "Rate/Total",
      "Submit Date",
      "Approved Date",
      "Variation Ref",
      "Detailed Description",
      "Lump Sum (Y/N)",
      "Provisional (Y/N)",
      "Parent Ref",
      "Exclude from Retention (Y/N)",
    ],
  },
  baseContract: {
    title: "Base Contract",
    sheet: "BaseContract",
    column: [
      "Ref",
      "Description",
      "Quantity",
      "Unit",
      "Rate/Total",
      "Lump Sum (Y/N)",
      "Provisional (Y/N)",
      "Parent Ref",
      "Exclude from Retention (Y/N)",
    ],
  },
  materials: {
    title: "Materials On/Off Site",
    sheet: "Materials",
    column: [
      "Ref",
      "Description",
      "On site (Y/N)",
      "Detailed Description",
      "Exclude from Retention (Y/N)",
    ],
    uniqueNotes: {
      3: (
        <>
          3. Please make sure that columns: &nbsp;
          <span className="browser">Ref</span>, &nbsp;
          <span className="browser">Description</span>
          &nbsp;have non empty values.
        </>
      ),
      4: `4. Make 'On site (Y/N)' column with 'Y' if item is On Site and 'N' 
        if item is Off Site. 
        When left blank item will be uploaded as off site.`,
      additionalNote: <></>,
    },
  },
};

const additionalNote = (
  <div className="note">
    <b>Note:</b> Example of establishing a group using the import tool.
    <div className="content">
      <table>
        <tr>
          <td className="ref">Ref</td>
          <td>Description</td>
          <td>Parent Ref</td>
        </tr>
        {[
          { ref: "A", des: "Item 1", parent: "" },
          { ref: "B", des: "Item 2", parent: "A" },
          { ref: "C", des: "Item 3", parent: "B" },
          { ref: "D", des: "Item 4", parent: "" },
        ].map((item) => (
          <tr key={item.ref}>
            <td className="ref">{item.ref}</td>
            <td>{item.des}</td>
            <td>{item.parent}</td>
          </tr>
        ))}
      </table>
      <div className="template-img" />
    </div>
  </div>
);
const CsvContent = (props) => {
  const [showInstruction, setShowInstruction] = useState(false);

  const modalType = typeMap[props.type] || typeMap["base"];
  return (
    <UploadCSV>
      <div className="instruction">
        <div>
          <div>
            Quickly fill in {modalType.title} items by uploading an EXCEL.
          </div>
          <div
            className="actions"
            onClick={() => setShowInstruction(!showInstruction)}
          >
            {showInstruction ? "Hide Instructions" : "See Instructions"}
          </div>
        </div>
        <form
          action={`${process.env.PUBLIC_URL}/template/upload_lineitem_template.xlsx`}
        >
          <Button title="download template" />
        </form>
      </div>
      {showInstruction && (
        <div className="description">
          <div className="item">
            The format of your Excel file is important. <br />
            1. Please name the Sheet Name in your file to '
            <span className="browser">{modalType.sheet}</span>
            '. <br />
            2. Please make sure you have the following columns in this order:
            <div className="item table">
              <table>
                <thead>
                  <tr>
                    {modalType.column.map((col) => (
                      <th key={col}>{col}</th>
                    ))}
                  </tr>
                </thead>
              </table>
            </div>
            {modalType?.uniqueNotes?.[3] ? (
              modalType.uniqueNotes[3]
            ) : (
              <>
                3. Please make sure that columns: &nbsp;
                <span className="browser">Ref</span>, &nbsp;
                <span className="browser">Description</span>, &nbsp;
                <span className="browser">Quantity</span>, and &nbsp;
                <span className="browser">Rate</span>
                &nbsp;have non empty values.
              </>
            )}
            <br />
            {modalType?.uniqueNotes?.[4] ? (
              modalType.uniqueNotes[4]
            ) : (
              <>
                4. To group items together, use the "Parent Ref" cells to
                specify the "Ref" of the parent item.
              </>
            )}
            <br />
            5. You can use the provided template for reference.
          </div>

          <div className="item add-on">
            Once uploaded, your browser will automatically download a pdf.
            Please check the downloaded pdf is correctly formatted before
            selecting the Import button.
          </div>

          {modalType?.uniqueNotes?.additionalNote || additionalNote}
        </div>
      )}
      <FileUpload {...props} />
    </UploadCSV>
  );
};

CsvContent.propTypes = {
  type: PropTypes.oneOf(["variations", "baseContract", "materials"]),
};

export default CsvContent;
