import React, { PureComponent } from "react";
import { ReviewApprovedWrapper } from "../../styled";
import ClaimTable from "../../../../common/table/approved_claim_table";
import { ApprovedClaimMaterials } from "../../../../common/table/approved_claim_materials_table";
class Table extends PureComponent {
  render() {
    const { bottom, isMaterial } = this.props;

    return (
      <ReviewApprovedWrapper style={{ marginBottom: bottom }}>
        {isMaterial ? (
          <ApprovedClaimMaterials {...this.props} />
        ) : (
          <ClaimTable {...this.props} />
        )}
      </ReviewApprovedWrapper>
    );
  }
}

export default Table;
