import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, WorkflowContainer } from "./styled";

import Menu from "../../common/breadcrumbs";
import Link from "../../common/linkWithQuery";
import {
  GreenWhiteBtn as Button,
  BlueOutlinedBtn as ButtonBlue,
  AddBtn as ButtonAdd,
} from "../../common/button";
import Step from "./components/step";

import { actionCreators } from "./store";
import Loading from "../../common/loading";

export class index extends Component {
  render() {
    const {
      projectName,
      stepInfo,
      addOneStep,
      upsertWorkflow,
      contractName,
      basicInfo,
      loading,
      updateContract,
      accountConfig,
    } = this.props;
    const claimWord = accountConfig?.getIn(["claim", "noun"]);
    const workflowType =
      this.props.match.params.flowType === "claim" ||
      this.props.match.params.type === "claim"
        ? claimWord
        : "Certificate";
    const guideMeg = {
      [claimWord]: `Invite members and manage the approval of ${claimWord?.toLowerCase()}s. A basic workflow has no approval steps between creating and issuing 
      ${claimWord?.toLowerCase()}s. You can set up a custom approval workflow.`,
      Certificate: `Invite members and manage the approval of certificates. A basic workflow has no approval steps between creating and issuing certificates. You can set up a custom approval workflow.`,
    };
    const projectId =
      this.props.match.params.projectId || this.props.match.params.proId;
    const contractId = this.props.match.params.contractId;

    if (loading) {
      return <Loading />;
    }

    let link = [
      { link: "/projects", text: "Projects" },
      { link: `/project/view/${projectId}`, text: projectName },
    ];
    let previousURL = "";
    if (contractId) {
      previousURL =
        basicInfo.get("status") === "draft"
          ? `/contract/${projectId}/${this.props.match.params.type}/${contractId}`
          : `/contract/${projectId}/${this.props.match.params.type}/${contractId}/settings`;
      link.push({ link: previousURL, text: contractName });
    } else {
      previousURL = `/project/view/${projectId}/settings`;
      link.push({ link: previousURL, text: "Project Settings" });
    }
    const hasWorkflow = stepInfo && stepInfo.size !== 0;

    //sort workflow by step id
    const sortedWorkflow = hasWorkflow
      ? stepInfo.sortBy((val) => val.get("step"))
      : null;
    // #TODO remove the max approval step after BE udpated

    const emailListName = `issue_${
      this.props.match.params.flowType || this.props.match.params.type
    }_emails`;
    // for linked contract/project, hide editing the certificate workflow
    const isLinked =
      basicInfo?.get("integration") && workflowType === "Certificate"
        ? true
        : false;
    return (
      <Container>
        <div className="page-breadcrumbs">
          <Menu
            link={link}
            content={`${workflowType} workflow`}
            className="page-breadcrumbs-label"
          />
          <Link to={previousURL}>
            <ButtonBlue
              title={
                contractId
                  ? `Back To Contract Settings`
                  : `Back To Project Settings`
              }
              textWrap="nowrap"
            />
          </Link>
        </div>
        <h1>{workflowType} workflow</h1>
        <p>{guideMeg[workflowType]}</p>

        <WorkflowContainer>
          <Step stepName={"Create " + workflowType} />

          {hasWorkflow &&
            sortedWorkflow.map((item) => (
              <Step
                key={item.get("id")}
                stepName={item.get("name")}
                isEditable
                step={item}
                {...this.props}
                isLinked={isLinked}
              />
            ))}
          {stepInfo.size <= 4 ? (
            <div className="step action">
              <div className="v-stepper">
                <div className="circle" />
                <div className="line" />
              </div>
              <div className="content">
                <ButtonAdd
                  title="Approval Step"
                  className="button-add"
                  onClick={() => addOneStep()}
                  disabled={isLinked}
                />
              </div>
            </div>
          ) : (
            <></>
          )}

          <Step
            stepName={"Issue " + workflowType}
            allowAddEmail={true}
            emailList={basicInfo?.get(emailListName)}
            updateEmail={(data) =>
              updateContract(projectId, contractId, { [emailListName]: data })
            }
          />
        </WorkflowContainer>

        <div className="page-foot">
          <Link to={previousURL}>
            <ButtonBlue title="cancel" />
          </Link>
          <Button
            title="save"
            onClick={() =>
              upsertWorkflow(
                projectId,
                contractId,
                this.props.match.params.flowType ||
                  this.props.match.params.type,
              )
            }
          />
        </div>
      </Container>
    );
  }

  componentDidMount() {
    const proId =
      this.props.match.params.projectId || this.props.match.params.proId;
    const contractId = this.props.match.params.contractId;
    const type =
      this.props.match.params.flowType || this.props.match.params.type;

    if (contractId) {
      this.props.readUsers(proId, contractId);
    } else {
      this.props.readUsers(proId, "");
    }
    this.props.readWorkflow(proId, contractId, type);
  }
  componentWillUnmount() {
    this.props.resetInfo();
  }
}

const mapStateToProps = (state) => ({
  stepInfo: state.getIn(["workflow", "stepInfo"]),
  userList: state.getIn(["workflow", "userList"]),
  projectName: state.getIn(["workflow", "projectName"]),
  contractName: state.getIn(["workflow", "contractName"]),
  basicInfo: state.getIn(["workflow", "basicInfo"]),
  loading: state.getIn(["workflow", "loading"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    readUsers(proId, contractId) {
      dispatch(actionCreators.readUsers(proId, contractId));
    },
    resetInfo() {
      dispatch(actionCreators.resetInfo());
    },
    readWorkflow(proId, contractId, type) {
      dispatch(actionCreators.readWorkflow(proId, contractId, type));
    },
    upsertWorkflow(proId, contractId, type) {
      dispatch(actionCreators.upsertWorkflow(proId, contractId, type));
    },
    addOneStep() {
      dispatch(actionCreators.addOneStep());
    },
    deleteStep(stepIndex) {
      dispatch(actionCreators.deleteOneStep(stepIndex));
    },
    changeStepName(stepId, name) {
      dispatch(actionCreators.changeStepName(stepId, name));
    },
    addStepApprover(stepId) {
      dispatch(actionCreators.addStepApprover(stepId));
    },
    deleteStepApprover(stepId, approverId) {
      dispatch(actionCreators.deleteStepApprover(stepId, approverId));
    },
    handleApprover(stepId, approverId, field, value) {
      dispatch(
        actionCreators.changeStepApprover(stepId, approverId, field, value),
      );
    },
    updateContract(proId, contractId, emailList) {
      dispatch(actionCreators.updateContract(proId, contractId, emailList));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
