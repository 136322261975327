import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { IconLink, TableContainer } from "../styled";

import { currencySubunitToUnit } from "../../../utils/currencyHelper";
import PageBarPart from "../../../common/pagination";
import { getContractTag } from "./dueWarningBadge";
import { LinkTooltip } from "./integration";

function compareByOption(field) {
  //sort function based on the table columns
  let sortLabel = field.split("$")[0];
  let reverse = field.split("$")[1] === "up" ? 1 : -1;
  if (sortLabel !== "name" && sortLabel !== "status") {
    return function (a, b) {
      var result = a > b ? 1 : b > a ? -1 : 0;
      return result * reverse;
    };
  } else
    return function (a, b) {
      var result =
        a.toString().toLowerCase().trim() > b.toString().toLowerCase().trim()
          ? 1
          : b.toString().toLowerCase().trim() >
              a.toString().toLowerCase().trim()
            ? -1
            : 0;
      return result * reverse;
    };
}

const TableHeaderItem = (props) => {
  const { className, label, sortLabel } = props.column;
  const { handleSort, activeSortField } = props;
  return (
    <th className={className}>
      {label}
      {sortLabel && (
        <>
          <span
            className={
              "img-sort up " +
              (sortLabel + "$up" === activeSortField ? "active" : "")
            }
            onClick={() => handleSort(sortLabel + "$up")}
          />
          <span
            className={
              "img-sort " +
              (sortLabel + "$down" === activeSortField ? "active" : "")
            }
            onClick={() => handleSort(sortLabel + "$down")}
          />
        </>
      )}
    </th>
  );
};

function TableLineitem({ item, history, viewPath, accountConfig }) {
  return (
    <tr>
      <td className="align-left flex" onClick={() => history.push(viewPath)}>
        <div className="contract-name">{item.get("name")}</div>
        {getContractTag(item, accountConfig) && (
          <div>{getContractTag(item, accountConfig)}</div>
        )}
        {item.get("integration") && (
          <LinkTooltip from="Contract">
            <IconLink
              className={
                getContractTag(item, accountConfig) ? "margin-left" : ""
              }
            />
          </LinkTooltip>
        )}
        <div className="secondary-label">
          {item?.get("payee_company")?.get("entity_name")}
        </div>
        <div className="secondary-label">{item.get("contract_number")}</div>
      </td>
      <td className="align-left" style={{ textTransform: "capitalize" }}>
        {item.get("status")}
      </td>
      <td>
        {currencySubunitToUnit(
          item.get("claimed_to_date_value"),
          true,
          false,
          true,
        )}
      </td>
      <td>
        {currencySubunitToUnit(
          item.get("cert_to_date_value"),
          true,
          false,
          true,
        )}
      </td>
      <td>
        {currencySubunitToUnit(
          item.get("total_contract_value"),
          true,
          false,
          true,
        )}
      </td>
    </tr>
  );
}

export function Table(props) {
  const { tableData, accountConfig } = props;
  const [data, setData] = useState([]);
  const [rows, setRows] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("total_contract_value$up");
  const history = useHistory();

  const handleSetRows = (data) => {
    setCurrentPage(1);
    setRows(data);
  };
  const handleSort = (field) => {
    setSortField(field);
  };

  const tableHeader = [
    { label: "Contract Name", sortLabel: "name", className: "name" },
    { label: "Status", sortLabel: "status", className: "number" },
    {
      label: `${accountConfig?.getIn(["claim", "value"])} to Date`,
      sortLabel: "claimed_to_date_value",
      className: "value",
    },
    {
      label: `${accountConfig?.getIn(["cert", "value"])} to Date`,
      sortLabel: "cert_to_date_value",
      className: "value",
    },
    {
      label: "Contract Value",
      sortLabel: "total_contract_value",
      className: "value",
    },
  ];

  useEffect(() => {
    let sortedTable = tableData.sortBy(
      (val) => val.get(sortField.split("$")[0]),
      compareByOption(sortField),
    );
    setData(sortedTable);
  }, [tableData, sortField]);

  return (
    <TableContainer className="project-view">
      <table>
        <thead>
          <tr>
            {tableHeader.map((column) => (
              <TableHeaderItem
                key={column.label}
                handleSort={handleSort}
                activeSortField={sortField}
                column={column}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            const proId = item.get("cert_contract_group");
            let viewPath = `/contract/${proId}/cert/${item.get("id")}`;
            let page = currentPage - 1;
            if (index >= page * rows && index < (page + 1) * rows) {
              return (
                <TableLineitem
                  item={item}
                  history={history}
                  viewPath={viewPath}
                  key={item.get("id")}
                  accountConfig={accountConfig}
                />
              );
            } else return null;
          })}
        </tbody>
      </table>

      <PageBarPart
        total={tableData.size}
        limit={rows}
        pageCount={5}
        currentPage={currentPage}
        handlePageChange={setCurrentPage}
        handleSetData={handleSetRows}
        field="big"
      />
    </TableContainer>
  );
}

export default function TableView(props) {
  const { data, ...otherProps } = props;
  return (
    <>
      <Table tableData={data} {...otherProps} />
    </>
  );
}
