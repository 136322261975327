import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { ProjectDetailWrapper, SettingSection, SectionIcon } from "../styled";
import {
  BlueOutlinedBtn as Button,
  BlueWhiteBtn as ButtonDark,
} from "../../../common/button";
import Input from "../../../common/form";
import Modal from "./modal";
import { getTimezone } from "../../../common/mapbox/map";
import { timezoneLabel } from "../../../utils/dateTimeHelper";
import { actionCreators as actionCreatorsManageAccount } from "../../manageaccount/store";
import { getAddressString } from "../store/actionCreators";

function Timezone(props) {
  const {
    timezone,
    updateInfo,
    level,
    readOnly,
    projectInfo,
    contractInfo,
    config,
    getTimezoneOptions,
    timezoneOptions,
    projectsPreviousAddress,
    contractsPreviousAddress,
    accountConfig,
  } = props;
  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [info, setInfo] = useState({ label: timezone, value: timezone });
  const hasMapService = localStorage.getItem("MAPBOX_TOKEN") ? true : false;
  const [isAddressInitialized, setAddressInitialized] = useState(false);
  const [previousAddress, setPreviousAddress] = useState("");
  const mainAddressInfo = contractInfo || projectInfo;
  const mainPreviousAddress =
    contractsPreviousAddress || projectsPreviousAddress;

  useEffect(() => {
    const addressString = getAddressString(mainPreviousAddress?.toJS());
    setPreviousAddress(addressString);
  }, [mainPreviousAddress]);

  useEffect(() => {
    let isMounted = true;

    const fetchTimezone = async () => {
      if (address && hasMapService && config.mapbox_token) {
        await getTimezone(
          address,
          config.mapbox_token,
          (fetchedTimezone) => {
            if (isMounted && fetchedTimezone !== timezone) {
              updateInfo(fetchedTimezone);
            }
          },
          () => {
            let tempAddress = `${mainAddressInfo.get(
              "state",
            )},${mainAddressInfo.get("country")}`;
            getTimezone(tempAddress, config.mapbox_token, (fetchedTimezone) => {
              if (isMounted && fetchedTimezone !== timezone) {
                updateInfo(fetchedTimezone);
              }
            });
          },
        );
      }
    };

    const fullAddress = getAddressString(mainAddressInfo?.toJS());

    if (!isAddressInitialized) {
      setAddress(fullAddress);
      setAddressInitialized(true);
    } else if (
      (!timezone && fullAddress && mainAddressInfo?.get("street_address")) ||
      (fullAddress !== previousAddress &&
        mainPreviousAddress?.get("street_address"))
    ) {
      fetchTimezone();
    }

    return () => {
      isMounted = false;
    };
  }, [
    address,
    mainAddressInfo,
    isAddressInitialized,
    hasMapService,
    config,
    updateInfo,
    timezone,
    timezoneOptions,
    previousAddress,
    mainPreviousAddress,
  ]);

  useEffect(() => {
    getTimezoneOptions(mainAddressInfo?.get("country"));
  }, [mainAddressInfo, getTimezoneOptions]);

  useEffect(() => {
    setInfo({ label: timezone, value: timezone });
  }, [open, timezone]);

  if (readOnly && !timezone) {
    return null;
  }

  if (readOnly && timezone) {
    return (
      <ProjectDetailWrapper className="setting-page" id="nav-timezone">
        <SettingSection>
          <div className="section-title">
            <div>
              <SectionIcon img={"timezone"} />
              <span>Time Zone</span>
            </div>
          </div>
          <div className={"center sub has-margin"}>{timezone}</div>
        </SettingSection>
      </ProjectDetailWrapper>
    );
  }

  return (
    <ProjectDetailWrapper className="setting-page" id="nav-timezone">
      <SettingSection>
        <div className="section-title">
          <div className="flex">
            <SectionIcon img={"timezone"} />
            <span>Time Zone</span>
          </div>
          {timezone && <Button title="edit" onClick={() => setOpen(true)} />}
        </div>
        <div className="center">
          {timezone && (
            <>
              We’ve populated the time zone for this {level} based on the{" "}
              {level} address.
            </>
          )}{" "}
          This will affect how times are displayed for{" "}
          {accountConfig?.getIn(["claim", "noun"])} and Certificate dates.
        </div>
        <div className={"center sub "}>{timezoneLabel(timezone)}</div>
        <div>
          {!timezone && (
            <>
              <ButtonDark
                title="Set up Time Zone"
                onClick={() => setOpen(true)}
              />
            </>
          )}
        </div>
      </SettingSection>
      <Modal
        title="Time Zone"
        open={open}
        close={() => setOpen(false)}
        submit={() => {
          updateInfo(info?.value);
          setOpen(false);
        }}
      >
        <>
          <div>
            This determines the format of time and dates (and public holidays)
            for project members.
          </div>
          <Input
            label="Time Zone"
            field="search"
            wrapperClassName="input-margin"
            onChange={(e) => setInfo(e)}
            value={info?.value}
            options={timezoneOptions?.toJS()}
            placeholder="Search for a timezone"
            usePortal={true}
          />
          <div className="small-label">
            This will affect how times are displayed for{" "}
            {accountConfig?.getIn(["claim", "noun"])} and Certificate dates
          </div>
        </>
      </Modal>
    </ProjectDetailWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    config: state.getIn(["headers", "config"]),
    timezoneOptions: state.getIn(["manageAccount", "timezoneOptions"]),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTimezoneOptions(country) {
    dispatch(actionCreatorsManageAccount.getTimezoneOptions(country));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Timezone);
