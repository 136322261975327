import React, { useEffect, useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { Table } from "shineout";

import { MaterialClaimTableWrapper } from "./review_claim_style";
import { handleTableSorter } from "../../utils/helper";
import { Sort } from "./basic_table";
import { TableContainer } from "./basic_table_style";
import {
  dropDecimalColumns,
  headerFormatter,
} from "../../utils/tableFormatHelper";
import { currencySubunitToUnit } from "../../utils/currencyHelper";
import { toKebabCase } from "../../utils/stringHelper";
import { countComments } from "./table_utils";
import CommentList from "./comment_table";

const ReviewClaimMaterialTable = (props) => {
  const [claimTotal, setClaimTotal] = useState(0);
  const {
    title,
    data,
    updateComment,
    accountConfig,
    isIssued,
    allowAddComment,
    allowEditContract,
  } = props;
  const showCurrency = !accountConfig?.get("enable_header_currency");
  let dropDecimal = dropDecimalColumns(data, ["claim_value"]);

  const ClaimedTotal = (
    <span className="claimed-total">
      {headerFormatter(
        `${accountConfig?.getIn(["claim", "value"])} Total`,
        accountConfig?.getIn(["currency", "code"]),
        accountConfig?.get("enable_header_currency"),
      )}
    </span>
  );

  const columns = [
    {
      title: "Ref",
      className: "align_left lineitem-ref",
      width: 81,
      render: "label",
    },
    {
      title: "Description",
      className: "align_left",
      width: 792,
      render: "description",
    },
    {
      className: "hidden th_align_left claimTotal",
      width: 347,
      group: ClaimedTotal,
      render: (d) => (
        <div className="inlineDiv">
          <div>
            {currencySubunitToUnit(
              dropDecimal["claim_value"]
                ? roundNearest(d.claim_value)
                : d.claim_value,
              showCurrency,
              dropDecimal["claim_value"],
            )}
          </div>
        </div>
      ),
    },
    {
      className: "hidden th_align_left reviewClaimComment",
      width: 40,
      group: ClaimedTotal,
      render: (d) => (
        <div className="commentDiv">
          {!isIssued
            ? allowAddComment && (
                <CommentList
                  data={d}
                  expands={[]}
                  updateComment={updateComment}
                  type={"claim"}
                  viewOnly={!allowEditContract}
                />
              )
            : countComments(d, d?.id) > 0 && (
                <CommentList
                  data={d}
                  expands={[]}
                  updateComment={updateComment}
                  type={"claim"}
                  viewOnly={true}
                />
              )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    let total = 0;
    data.forEach((item) => {
      total += item.claim_value;
    });
    setClaimTotal(total);
  }, [data]);

  return (
    <div className="materials">
      <span className="sub-title">{title}</span>
      <TableContainer className={"table " + toKebabCase(title)}>
        <Table data={data} columns={columns}>
          <table>
            <tfoot className="materials-footer">
              <tr>
                <td className="empty" />
                <td className="title">Sub Total</td>
                <td className="claimed">
                  {" "}
                  {currencySubunitToUnit(claimTotal, showCurrency)}
                </td>
              </tr>
            </tfoot>
          </table>
        </Table>
      </TableContainer>
    </div>
  );
};

const ReviewClaimMaterials = (props) => {
  const {
    tableContent,
    accountConfig,
    isIssued,
    allowAddComment,
    updateComment,
    allowEditContract,
  } = props;
  const [onSite, setOnSite] = useState([]);
  const [offSite, setOffSite] = useState([]);
  const [sortOpen, setSortOpen] = useState(false);
  const [sortOption, setSortOption] = useState({
    field: "created_date",
    sep: "asc",
  });
  const sortingOptions = {
    options: {
      created_date: "Date Created",
      label: "Reference",
      description: "Description",
      claim_value: `${accountConfig?.getIn(["claim", "noun"])} Value`,
    },
    sep: {
      asc: "Ascending",
      desc: "Descending",
    },
  };

  // separate data into onsite or offsite
  useEffect(() => {
    let table = tableContent.toJS();
    table = table.sort(handleTableSorter(sortOption.field, sortOption.sep));

    let onSite = [],
      offSite = [];
    table.forEach((line) => {
      if (line.on_site) {
        onSite.push(line);
      } else {
        offSite.push(line);
      }
    });
    setOnSite(onSite);
    setOffSite(offSite);
  }, [tableContent, sortOption]);

  const setSort = (option) => {
    if (sortingOptions.options[option]) {
      setSortOption({ ...sortOption, field: option });
    } else if (sortingOptions.sep[option]) {
      setSortOption({ ...sortOption, sep: option });
    }
    setOnSite(handleSort(onSite, sortOption));
    setOffSite(handleSort(offSite, sortOption));
  };

  const handleSort = (list, sortOptions) => {
    let newList = [...list];
    newList.sort(handleTableSorter(sortOptions.field, sortOptions.sep));
    return newList;
  };

  return (
    <MaterialClaimTableWrapper>
      <div className="title-field">
        <span>Materials On/Off Site</span>
        <ClickAwayListener onClickAway={() => setSortOpen(false)}>
          <div>
            <Sort
              open={sortOpen}
              openDropdown={() => setSortOpen(true)}
              setSort={(e) => setSort(e.target.value)}
              sortField={sortOption.field}
              sortSep={sortOption.sep}
              sortOptions={sortingOptions}
            />
          </div>
        </ClickAwayListener>
      </div>

      {onSite.length > 0 && (
        <ReviewClaimMaterialTable
          title="On Site"
          data={onSite}
          accountConfig={accountConfig}
          isIssued={isIssued}
          allowAddComment={allowAddComment}
          updateComment={updateComment}
          allowEditContract={allowEditContract}
        />
      )}
      {offSite.length > 0 && (
        <ReviewClaimMaterialTable
          title="Off Site"
          data={offSite}
          accountConfig={accountConfig}
          isIssued={isIssued}
          allowAddComment={allowAddComment}
          updateComment={updateComment}
          allowEditContract={allowEditContract}
        />
      )}
    </MaterialClaimTableWrapper>
  );
};

export default ReviewClaimMaterials;
