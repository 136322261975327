import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { isDirty } from "redux-form/immutable";

import { BgWrapper, HeaderTitle } from "../../../../common/shared_style";

import Form from "./form";
import Menu from "../../../../common/breadcrumbs";
import Prompt from "../../../../common/modal/component/prompt";

import { actionCreators } from "../../store";

class Contacts extends PureComponent {
  render() {
    var link = [{ link: "/contacts", text: "Contacts" }];
    return (
      <BgWrapper>
        <Prompt when={this.props.isFormDirty && !this.props.saved} />
        <Menu link={link} content="Edit Contact" />
        <HeaderTitle> Edit Contact</HeaderTitle>
        <Form
          onSubmit={(values) => {
            this.props.update(values, this.props.match.params.id);
          }}
          initialValues={this.props.formValue}
          accountConfig={this.props.accountConfig}
        />
      </BgWrapper>
    );
  }

  componentDidMount() {
    this.props.getInitialInfo(this.props.match.params.id);
  }
  componentWillUnmount() {
    this.props.resetInfo();
  }
}
const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
  isFormDirty: isDirty("ContactForm")(state),
  saved: state.getIn(["contacts", "saved"]),
  formValue: state.getIn(["contacts", "formValue"]),
});
const mapDispatch = (dispatch) => {
  return {
    update(values, id) {
      let entityId = id.split("&&")[0];
      let contactId =
        id.split("&&")[1] === "undefined" ? "" : id.split("&&")[1];
      dispatch(actionCreators.updateContactInfo(values, entityId, contactId));
    },
    getInitialInfo(id) {
      let entityId = id.split("&&")[0];
      let contactId =
        id.split("&&")[1] === "undefined" ? "" : id.split("&&")[1];
      dispatch(actionCreators.getDetailedContact(entityId, contactId));
    },
    resetInfo() {
      dispatch(actionCreators.resetStage());
    },
  };
};
export default connect(mapStateToProps, mapDispatch)(Contacts);
