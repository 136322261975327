import axios from "axios";
import { publicIp } from "./apiIp";
import { httpCode, processMessage } from "./helper.js";
import { actionCreators } from "../../common/modal";
import store from "../../store";
import history from "../../utils/history";

const instance = axios.create({
  //axios instance
  timeout: 600000, // delay time 10 mins
  baseURL: publicIp,
});

// post header
instance.defaults.headers.post["Content-Type"] = "application/json";

/** Add request interceptor **/
instance.interceptors.request.use(
  (config) => {
    config.headers["token"] = sessionStorage.getItem("token") || "";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

/** Add response interceptor  **/
instance.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      store.dispatch(
        actionCreators.showModal("alert", {
          open: true,
          title: "Error!",
          message: "Time out, please refresh again!",
        }),
      );
      return Promise.reject(response.data.message);
    }
  },
  async (error) => {
    if (error.response) {
      // According to the http status code of the request
      // failure to give the user the corresponding prompt
      if (error.response.status === 403) {
        history.push(`/access-denied`);
        return;
      }
      let errorMsg = "";
      if (error.response.status === 401) {
        const currentLocation =
          window.location.pathname + window.location.search;
        window.location = "/login?next=" + encodeURIComponent(currentLocation);
        return;
      }
      if (error.response.status in httpCode) {
        errorMsg = httpCode[error.response.status];
      } else if (
        error.response.request.responseType === "blob" &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        const msg = JSON.parse(await error.response.data.text());
        errorMsg = msg.data.errors;
      } else {
        errorMsg = error.response.data.data.errors;
      }
      store.dispatch(
        actionCreators.showModal("alert", {
          open: true,
          title: "Error!",
          message: processMessage(errorMsg),
        }),
      );

      return Promise.reject(error);
    } else {
      store.dispatch(
        actionCreators.showModal("alert", {
          open: true,
          title: "Error!",
          message: "Time out, please refresh again!",
        }),
      );
      return Promise.reject(new Error("Time out, please refresh again!"));
    }
  },
);

export const httpRequest = (method, url, data, config = {}, headers = {}) => {
  const searhParams = new URLSearchParams(window.location.search);
  const ACC_ID =
    searhParams?.get("account_id") ||
    store.getState().getIn(["headers", "profile", "payclaim_account_id"]);

  if (url.indexOf("accId") !== -1) {
    url = url.replace("accId", ACC_ID);
  }

  return instance({
    method,
    url,
    data,
    ...config,
    headers,
  });
};
