import React from "react";
import Link from "../../../common/linkWithQuery";

import { BottomWrapper } from "./style";
import {
  BlueOutlinedBtn as Cancel,
  GreenWhiteBtn as Save,
} from "../../../common/button";

export default function NavBottom(props) {
  return (
    <BottomWrapper>
      <Link to={props.link}>
        <Cancel title="CANCEL" margin="0 30px 0 0" padding="10px 34px" />
      </Link>
      <Save
        title="SAVE"
        padding="10px 41px"
        type="submit"
        disabled={props.submitting || props.hasError}
        submitting={props.submitting}
      />
    </BottomWrapper>
  );
}
