import React, { Component } from "react";
import Select, { components } from "react-select";
import DropdownIndicator from "common/dropdown/component/indicator";
import * as mainTheme from "../../../theme";
import { sortByOption } from "../../../utils/helper";
import { overrideColor, valueFromId } from "common/dropdown/component/helper";
const selectInputStyle = {
  control: (styles) => ({
    ...styles,
    width: "100%",
    height: "44px",
    borderRadius: "4px",
    borderColor: mainTheme.palette.wolfGrey,
    fontSize: "16px",
    boxShadow: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    width: "98%",
    minHeight: 44,
    height: "auto",
    fontSize: "16px",
    margin: "1%",
    padding: "1%",
    color: mainTheme.palette.gunmetal,
    "&:hover": {
      backgroundColor: mainTheme.palette.warmLightGrey,
      cursor: "pointer",
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: "14px",
  }),
  menu: (styles) => ({
    ...styles,
    width: "100%",
    color: mainTheme.palette.gunmetal,
    fontFamily: mainTheme.font.mainFont,
  }),
  singleValue: (styles) => ({
    ...styles,
    lineHeight: "16px",
    color: mainTheme.palette.gunmetal,
    fontFamily: mainTheme.font.mainFont,
  }),
};
const style = {
  label: {
    color: mainTheme.palette.wolfGrey,
    fontWeight: mainTheme.font.normalFontWeight,
    marginTop: "4px",
    marginLeft: "4px",
  },
  name: {
    color: mainTheme.palette.gunmetal,
    fontWeight: mainTheme.font.normalFontWeight,
  },
};
export default class SelectFrame extends Component {
  render() {
    const MyOptionCom = (props) => {
      const { data } = props;
      const name = data.label.split(" (")[0];
      const label = data.label.split(" (")[1]
        ? "(" + data.label.split(" (")[1]
        : "";
      return (
        <components.Option {...props}>
          <div>
            <div style={style.name}> {name} </div>
            <div style={style.label}> {label} </div>
          </div>
        </components.Option>
      );
    };
    const { options, value, onChange, field, placeholder } = this.props;
    return (
      <Select
        components={
          field === "account"
            ? { DropdownIndicator }
            : { DropdownIndicator, Option: MyOptionCom }
        }
        options={options.sort(sortByOption("label"))}
        value={valueFromId(options, value) || ""}
        onChange={onChange}
        isSearchable
        placeholder={placeholder}
        styles={selectInputStyle}
        closeMenuOnSelect={true}
        isClearable={true}
        theme={(theme) => overrideColor(theme)}
      />
    );
  }
}
