import React from "react";
import PropTypes from "prop-types";

import { RequiredBannerWrap } from "./styled";

const Index = (props) => {
  const { fieldsAndRefs, hasNextPage, noticeTitle, marginTop } = props;
  const errorNotice = noticeTitle
    ? noticeTitle
    : `To proceed${
        hasNextPage ? " to the next page" : ""
      }, correct the following errors:`;

  const scrollToComponent = (targetRef) => {
    if (targetRef && targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };
  return (
    <>
      {fieldsAndRefs.some((field) => field.missing) && (
        <RequiredBannerWrap marginTop={marginTop}>
          <div className="message">
            <div className="warning-icon" />
            {errorNotice}
          </div>

          <div className="list">
            <ul>
              {fieldsAndRefs.map(
                ({ field, name, ref, missing }) =>
                  missing && (
                    <li
                      key={field}
                      className="error_item"
                      onClick={() => scrollToComponent(ref)}
                    >
                      {name}
                    </li>
                  ),
              )}
            </ul>
          </div>
        </RequiredBannerWrap>
      )}
    </>
  );
};

Index.propTypes = {
  fieldsAndRefs: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      ref: PropTypes.shape({
        current: PropTypes.any,
      }).isRequired,
      missing: PropTypes.any,
    }),
  ).isRequired,
};

export default Index;
