import React, { Component } from "react";
import { format, formatDistance } from "date-fns";
import { Notice } from "../styled";

export default class noticeBar extends Component {
  render() {
    const { closeNotice, type, systemEvents } = this.props;
    let meg = { period: "", time: "", message: "" };
    if (systemEvents && systemEvents.size !== 0) {
      // set default to get the first event info
      meg.time = format(
        new Date(systemEvents.getIn([0, "start"])),
        "do LLLL, yyyy",
      );
      meg.period = formatDistance(
        new Date(systemEvents.getIn([0, "start"])),
        new Date(systemEvents.getIn([0, "end"])),
      );
      meg.message = systemEvents.getIn([0, "message"]);
    }
    const noticeLabel = {
      maintenance_notice:
        meg.message !== ""
          ? meg.message
          : `PayLab will be undergoing maintenance and will be down for ${meg.period} on the ${meg.time}.`,
      no_project_notice:
        " You are currently not assigned to any projects… when you are, their data will be shown here.",
    };
    if (type === "help_desk_mode") {
      return (
        <Notice>
          <div className="container help_desk">
            <div className="img_icon help_desk" />
            <div className="label">Help Desk Mode Active</div>
          </div>
        </Notice>
      );
    } else {
      return (
        <Notice>
          <div className="container">
            <div className="img_icon" />
            <div className="label">{noticeLabel[type]}</div>
            <div className="close_icon" onClick={() => closeNotice()} />
          </div>
        </Notice>
      );
    }
  }
}
