import React from "react";
import { BottomContainer, HubSpotContainer, MailIcon } from "./dialogStyle";
import {
  BlueWhiteBtn as SaveButton,
  BlueOutlinedBtn as BlueButton,
} from "../../button";
import Modal from "./modalBase";
import HubSpotForm from "react-hubspot-form";
import LoadingBar from "../../loading";
import API from "../../../server";

class CustomizedDialogs extends React.Component {
  constructor() {
    super();
    this.state = {
      hsSubmit: null,
      status: "loading",
    };
    //doing this so the hubspot react library not rely on jquery
    //to hide submit button after form submitted
    this.hubSpotEventHandler = this.hubSpotEventHandler.bind(this);
  }

  componentDidMount() {
    window.addEventListener("message", this.hubSpotEventHandler);
    API.read_config()
      .then((res) => res.data)
      .then((res) => res.data.config)
      .then((res) => {
        sessionStorage.setItem(
          "hubspot_request_support_portal_id",
          res.hubspot_request_support_portal_id,
        );
        sessionStorage.setItem(
          "hubspot_request_support_form_id",
          res.hubspot_request_support_form_id,
        );
      });
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.hubSpotEventHandler);
  }

  hubSpotEventHandler(event) {
    if (
      event.data.type === "hsFormCallback" &&
      event.data.eventName === "onFormSubmitted"
    ) {
      // get callback after huspot submitted form
      // in this way, we do not rely on jquery library
      if (
        event.data.id ===
        sessionStorage.getItem("hubspot_request_support_form_id")
      ) {
        this.setState({ status: "submitted" });
      }
    }
  }

  render() {
    const portalId = sessionStorage.getItem(
      "hubspot_request_support_portal_id",
    );
    const formId = sessionStorage.getItem("hubspot_request_support_form_id");
    return (
      <Modal
        handleClose={() => {
          this.props.closeDialog();
        }}
        open={this.props.open}
        title={
          <div>
            <MailIcon />
            Request Support
          </div>
        }
      >
        <HubSpotContainer>
          <HubSpotForm
            ref={(input) => (this.hubspotForm = input)}
            portalId={portalId}
            formId={formId}
            region="na1"
            onSubmit={(e) => {
              this.setState({ status: "submitting" }); // disable the submit button
            }}
            loading={
              <div>
                <LoadingBar />
              </div>
            }
            onReady={() => {
              let button =
                this.hubspotForm.el.querySelectorAll(".c-help-hs__submit");
              if (button.length > 0)
                this.setState({ hsSubmit: button[0], status: "ready" });
            }}
            submitButtonClass="c-help-hs__submit"
          />
        </HubSpotContainer>
        <BottomContainer>
          <BlueButton
            title="View Support Hub"
            onClick={(e) => {
              e.preventDefault();
              window.open("https://support.asbuiltvault.com/support", "_blank");
            }}
          />
          {this.state.status !== "submitted" && (
            <div>
              <SaveButton
                title="SEND"
                disabled={this.state.status !== "ready"}
                onClick={() => {
                  if (this.state.hsSubmit !== null) {
                    this.state.hsSubmit.click();
                  }
                }}
                padding="10px 30px"
                type="submit"
              />
              <BlueButton
                title="CANCEL"
                onClick={() => this.props.closeDialog()}
                margin="0 10px 0 0"
              />
            </div>
          )}
        </BottomContainer>
      </Modal>
    );
  }
}

export default CustomizedDialogs;
