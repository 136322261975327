import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { ProjectNavList, WorkflowWrapper, ReviewWrapper } from "./styled";

import history from "../../utils/history";
import { ApprovalSteps, AuditCheckbox } from "./components/approval_steps";
import { ApprovalHistory } from "./components/approval_history";

function ApprovalWorkflow(props) {
  const {
    data,
    editable,
    isAuditAttached,
    setPdfAudit,
    isComplete,
    timezone,
    workflowURL,
    accountConfig,
  } = props;

  const arrowRef = useRef(null);
  const [openStep, setOpenStep] = useState(false);
  const [step, setStep] = useState(null);
  const [dataStep, setDataStep] = useState([]);

  const checkIfPast = (item, data) => {
    const currentStepId = data?.get("current_step_index") || 0;
    return currentStepId > item.get("step");
  };

  const setInitialSteps = (data) => {
    let dataStep = [];

    if (data?.get("steps")) {
      data.size !== 0 &&
        data.get("steps").forEach((approvalStep) => {
          let step = {
            id: approvalStep.get("step") + 1,
            label: approvalStep.get("name"),
            completed: checkIfPast(approvalStep, data), // current step is bigger then step
            active:
              approvalStep.get("step") === data.get("current_step_index") ||
              isComplete,
            approvers: [],
            history: [],
          };

          approvalStep.get("approvals") &&
            approvalStep.get("approvals").forEach((approver) => {
              step.approvers.push({
                name: approver.get("user_name"),
                approved: approver.get("approved"),
                required: approver.get("required"),
                date: approver.get("approved") || "",
                comment: approver.get("comment"),
              });
            });
          step.approvers.sort((a, b) =>
            b.required === a.required ? 0 : b.required ? 1 : -1,
          );

          approvalStep.get("history") &&
            approvalStep.get("history").forEach((history) => {
              step.history.push({
                name: history.get("user_name"),
                action: history.get("action"),
                message: history.get("message"),
                date: history.get("created") || "",
                comment: history.get("comment"),
                emails: history.get("send_emails") || [],
              });
            });
          step.history.sort((a, b) => a.date.localeCompare(b.date));
          dataStep.push(step);
        });

      dataStep.sort((a, b) => a.id - b.id);
    }
    setDataStep(dataStep);
  };

  useEffect(() => {
    setInitialSteps(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const positionArrow = (target) => {
    let stepWrap = document.getElementById("stepWrap");
    if (!stepWrap || !arrowRef.current || !target) {
      return;
    }
    const containerRect = target.getBoundingClientRect();
    const arrowWidth = arrowRef.current.offsetWidth;
    const centerPosition =
      containerRect.x +
      containerRect.width / 2 -
      stepWrap.getBoundingClientRect().x -
      arrowWidth / 2;
    arrowRef.current.style.left = `${centerPosition}px`;
  };

  return (
    <>
      <div className="line" />
      <ReviewWrapper>
        <ProjectNavList>
          Approval Workflow
          {editable && (
            <div
              className={"imgEdit"}
              onClick={() => {
                setOpenStep(false);
                history.push(workflowURL);
              }}
            />
          )}
        </ProjectNavList>
        <WorkflowWrapper id="stepWrap">
          <ApprovalSteps
            data={dataStep}
            selectedStep={step}
            onClick={(id, e) => {
              setStep(id);
              setOpenStep(true);
              positionArrow(e.target);
            }}
            accountConfig={accountConfig}
          />
        </WorkflowWrapper>
        <ApprovalHistory
          openStep={openStep}
          arrowRef={arrowRef}
          dataStep={dataStep}
          step={step}
          timezone={timezone}
          close={() => {
            setOpenStep(false);
            setStep(null);
          }}
        />

        <AuditCheckbox
          checked={isAuditAttached}
          setPdfAudit={(e) => setPdfAudit(e)}
        />
      </ReviewWrapper>
    </>
  );
}

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default connect(mapStateToProps, null)(ApprovalWorkflow);
