import React, { Fragment } from "react";
import {
  ProjectCardWrap,
  ComponentCardWrap,
  DetailsCardWrap,
  ContractDetailsWrap,
  ProjectDetailsCardWrap,
} from "../styled";
import { CustomizeSkeleton } from "./base";

const ProjectCard = ({ count }) => {
  const line = (
    <div className="line-wrap">
      <CustomizeSkeleton containerClassName="line-skeleton" />
      <CustomizeSkeleton containerClassName="line-skeleton-1" />
    </div>
  );
  return Array.from({ length: count }, (_, i) => (
    <ProjectCardWrap key={i}>
      <CustomizeSkeleton containerClassName="image-skeleton" />
      <CustomizeSkeleton containerClassName="name-skeleton" />
      <CustomizeSkeleton containerClassName="count-skeleton" />
      <div className="lines">
        {Array.from({ length: 4 }, (_, i) => (
          <Fragment key={i}>{line}</Fragment>
        ))}
      </div>
    </ProjectCardWrap>
  ));
};

const ComponentCard = ({ count }) => {
  return Array.from({ length: count }, (_, i) => (
    <ComponentCardWrap key={i}>
      <div className="title-section">
        <CustomizeSkeleton containerClassName="title" />
        <CustomizeSkeleton containerClassName="button" />
      </div>
      <div className="content-section">
        <CustomizeSkeleton containerClassName="thick-line" />
        <CustomizeSkeleton containerClassName="line" />
        <CustomizeSkeleton containerClassName="line" />
        <CustomizeSkeleton containerClassName="line short" />
      </div>
    </ComponentCardWrap>
  ));
};

const DetailsCard = () => {
  return (
    <DetailsCardWrap>
      <ContractDetailsWrap>
        <div className="title-section">
          <CustomizeSkeleton containerClassName="title" />
          <CustomizeSkeleton containerClassName="line" />
        </div>

        <div className="detail-section">
          <div className="summary">
            <div className="title-bar">
              <CustomizeSkeleton containerClassName="title" />
              <CustomizeSkeleton containerClassName="percentage" />
            </div>
            <div className="values">
              <CustomizeSkeleton containerClassName="line thick" />
              <CustomizeSkeleton containerClassName="line thick" />
              <CustomizeSkeleton containerClassName="line thick" />
              <CustomizeSkeleton containerClassName="line thick" />
            </div>
            <CustomizeSkeleton containerClassName="line" />
          </div>

          <div className="other-party">
            <CustomizeSkeleton containerClassName="title" />
            <div className="split-section">
              <div className="section">
                <CustomizeSkeleton circle width={48} height={48} />
                <div className="lines">
                  <CustomizeSkeleton containerClassName="line thick" />
                  <CustomizeSkeleton containerClassName="line thin" />
                  <CustomizeSkeleton containerClassName="line thin short" />
                </div>
              </div>

              <div className="section">
                <CustomizeSkeleton containerClassName="line very-thick" />
                <div className="lines">
                  <CustomizeSkeleton containerClassName="line thick" />
                  <CustomizeSkeleton containerClassName="line thin" />
                  <CustomizeSkeleton containerClassName="line thin short" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContractDetailsWrap>
    </DetailsCardWrap>
  );
};

const ProjectDetailsCard = () => {
  return (
    <DetailsCardWrap inputHeight="700px">
      <ProjectDetailsCardWrap>
        <CustomizeSkeleton containerClassName="image-skeleton" />
        <CustomizeSkeleton containerClassName="name-skeleton" />
        <CustomizeSkeleton containerClassName="count-skeleton" />
        <div className="button-wrap">
          <div className="left-buttons">
            <CustomizeSkeleton containerClassName="archive-button" />
            <CustomizeSkeleton containerClassName="setIcon" />
          </div>
          <CustomizeSkeleton containerClassName="delete-button" />
        </div>
        <CustomizeSkeleton containerClassName="map-wrap" />
      </ProjectDetailsCardWrap>
    </DetailsCardWrap>
  );
};

export default { ProjectCard, ComponentCard, DetailsCard, ProjectDetailsCard };
