import styled from "styled-components";
import * as theme from "../../theme";

export const FilterContainer = styled.div`
  position: absolute;
  z-index: 99;
  top: -58px;
  right: 20px;

  .filter_button {
    position: absolute;
    right: 44px;
    height: 34px;
    width: max-content;
    background-color: ${theme.palette.tangerineOrange};
    border-radius: 4px;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
    font-size: 12px;
    font-weight: bold;
    color: white;
    z-index: -1;
    cursor: pointer;
    padding: 7px 10px;
    line-height: 20px;
    &.hide {
      top: 80px;
    }
  }
  .filter_bar {
    min-height: 277px;
    height: auto;
    width: 460px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
    position: absolute;
    opacity: 0;
    &.show {
      right: 0px;
      top: 0px;
      transition: opacity 0.3s ease-in 0s;
      opacity: 1;
    }
    &.hide {
      top: -410px;
      right: inherit;
      transition: max-height 0.3s ease-in;
    }
    .filter_bar_top {
      min-height: 192px;
      height: auto;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: left;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: ${theme.palette.gunmetal};
        &.small {
          font-size: 14px;
        }
      }
      .sub_title {
        margin: 10px 0;
        font-size: 14px;
        color: ${theme.palette.veryMediumGrey};
      }
    }
    .filter_bar_action {
      padding: 20px 30px;
      height: 84px;
      position: relative;
    }
  }
`;

export const Icon = styled.div`
  width: 20px;
  height: 20px;
  margin: 0 0 0 2px;
  object-fit: contain;
  background: url(${(props) => props.name}) no-repeat;
  cursor: pointer;
  float: right;
`;
