import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  HIDE_EMPTY,
  HIDE_ACTIVE,
  SHOW_ARCHIVED,
  CLAIM_ONLY,
  CREATE,
  PROJECT,
} from "../../utils/constants";

import { BgWrapper, HeaderTitle } from "../../common/shared_style";
import { EmptyStateWrapper } from "./styled";

import Link from "../../common/linkWithQuery";
import { BlueWhiteBtn as Button } from "../../common/button";
// import Tour from "../../common/tour"; -- pl-568
import history from "../../utils/history";
import profile from "../../utils/profile";

import ProjectList from "./components/listView";
import ProjectTable from "./components/tableView";
import ActionBar from "./components/actionBar";

import { actionCreators } from "./store";
import { actionCreators as actionCreatorHeader } from "../../common/header/store";
import { actionCreators as actionCreatorsAccount } from "../manageaccount/store";

const filterOptions = {
  "Project Status": [
    { label: "Hide Empty", value: HIDE_EMPTY },
    { label: "Hide Active", value: HIDE_ACTIVE },
    { label: "Show Archived", value: SHOW_ARCHIVED },
  ],
};

export function openProject(accountId, project, orgType, isNewWin) {
  let claimContractId = project?.getIn(["claim_contracts", "contract_id"]),
    certContractNum =
      project?.getIn(["cert_contracts", "contract_number"]) || 0,
    path = "";
  //when account org is claim only, there is a claim contract and no cert contract in the project
  //will redirect to contract page instead of project page
  if (claimContractId && orgType === CLAIM_ONLY && certContractNum === 0) {
    path = `/contract/${project.get(
      "id",
    )}/claim/${claimContractId}?account_id=${accountId}`;
  } else {
    path = `/project/view/${project.get("id")}?account_id=${accountId}`;
  }
  return isNewWin ? window.open(path, "_blank") : history.push(path);
}

class Projects extends PureComponent {
  render() {
    const {
      loading,
      allProjects,
      filteredProjects,
      layout /*, firstLogin, showSetupList, -- PL-568*/,
    } = this.props;
    const allowCreateProject = profile.checkPermission(PROJECT, CREATE);
    return (
      <>
        {/* !todo new onboarding guide info */}
        {/* {firstLogin && <Tour field="PROJECTS" showSetupList={showSetupList} />} -- pl-568 */}

        <BgWrapper>
          <HeaderTitle className="align-left">Projects</HeaderTitle>

          {!loading && allProjects.size === 0 && (
            <EmptyStateWrapper>
              <div className="top-label">No Projects Yet</div>
              {allowCreateProject && (
                <>
                  <div className="sub-label">
                    Get started by creating your first project
                  </div>
                  <Link to="/project">
                    <Button title="Create New Project" />
                  </Link>
                </>
              )}
            </EmptyStateWrapper>
          )}

          {(loading || allProjects.size !== 0) && (
            <>
              {allowCreateProject && (
                <Link to="/project">
                  <Button title="Create New Project" margin="0 0 0 20px" />
                </Link>
              )}
              <ActionBar
                {...this.props}
                filterOptions={filterOptions}
                from={"projects"}
                searchPlaceHolder={"Search…"}
              />
              {layout === "table" ? (
                <ProjectTable data={filteredProjects} {...this.props} />
              ) : (
                <ProjectList data={filteredProjects} {...this.props} />
              )}
            </>
          )}
        </BgWrapper>
      </>
    );
  }
  componentDidMount() {
    this.props.readProjects();
  }
  componentWillUnmount() {
    this.props.reset();
  }
}

const mapStateToProps = (state) => ({
  accountId: state.getIn(["headers", "profile", "payclaim_account_id"]),
  loading: state.getIn(["projects", "loading"]),
  layout: state.getIn(["projects", "layout"]),
  sortListField: state.getIn(["projects", "sortListField"]),
  search: state.getIn(["projects", "search"]),
  allProjects: state.getIn(["projects", "allProjects"]),
  filteredProjects: state.getIn(["projects", "filteredProjects"]),
  firstLogin: state.getIn(["headers", "firstLogin"]),
  orgType: state.getIn(["manageAccount", "orgType"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});

const mapDispatch = (dispatch) => {
  return {
    showSetupList(triggerClose) {
      dispatch(actionCreatorHeader.getSetupProcess(triggerClose));
    },
    readProjects() {
      dispatch(actionCreators.readProjects());
      dispatch(actionCreatorsAccount.getAccountInfo());
    },
    handleChangeLayout(value) {
      dispatch(actionCreators.setData("layout", value));
    },
    handleSortList(value) {
      dispatch(actionCreators.setData("sortListField", value));
    },
    handleSearch(value) {
      dispatch(actionCreators.handleSearch(value));
    },
    handleFilter(filterOption) {
      dispatch(actionCreators.handleFilters(filterOption));
    },
    reset() {
      dispatch(actionCreators.reset());
    },
    downloadProjectsSummary() {
      dispatch(actionCreators.downloadProjectsSummary());
    },
  };
};

export default connect(mapStateToProps, mapDispatch)(Projects);
