import React, { Fragment } from "react";
import Link from "../../../common/linkWithQuery";

import {
  ListItem,
  TableHeader,
  ListItemWrapper,
  ImgEdit,
  ImgDelete,
} from "../style";

import profile from "../../../utils/profile";
import { CONTACT, EDIT } from "../../../utils/constants";
import { capitalizeAddress } from "../../../utils/helper";
import BootstrapTooltip from "../../../common/toolTip";

function compareByOption(name) {
  let reverse = 1;
  if (name === "registed_time") {
    reverse = -1;
  }
  if (name === "label") {
    return function (a, b) {
      var result =
        a[name].toString().toLowerCase().trim() >
        b[name].toString().toLowerCase().trim()
          ? 1
          : b[name].toString().toLowerCase().trim() >
              a[name].toString().toLowerCase().trim()
            ? -1
            : 0;
      return result;
    };
  } else {
    return function (a, b) {
      if (a && b) {
        var result =
          a.toString().toLowerCase().trim() > b.toString().toLowerCase().trim()
            ? 1
            : b.toString().toLowerCase().trim() >
                a.toString().toLowerCase().trim()
              ? -1
              : 0;
        return result * reverse;
      } else {
        return false;
      }
    };
  }
}
function checkPhoneNumber(num) {
  if (num?.length <= 3 || num === "+64" || !num) {
    return "No phone details";
  } else {
    return num;
  }
}
const ContactList = ({ item, handleDelete, allowEditContact }) => {
  const canDelete = item.get("active_contracts") < 1;
  return (
    <ListItem className={allowEditContact ? "contact" : "view-only"}>
      <div className="company">
        <BootstrapTooltip title={item.get("entity_name")} placement="top">
          <div className="nowrap_text">{item.get("entity_name")}</div>
        </BootstrapTooltip>
        <BootstrapTooltip title={item.get("gst_no")} placement="top">
          <div className="company_id nowrap_text light_grey_text">
            {/* company_id is replaced with gst_no. */}
            {item.get("gst_no") ? "#" + item.get("gst_no") : ""}
          </div>
        </BootstrapTooltip>
      </div>

      <div className="person_name">
        {item
          ?.get("contact_name")
          ?.split(" ")
          .map((ele, index) => (
            <div key={index + ele}>{ele}</div>
          ))}{" "}
      </div>

      <div>
        <div>{checkPhoneNumber(item?.get("contact_tel"))}</div>
        <BootstrapTooltip title={item?.get("contact_email")} placement="top">
          <div className="light_grey_text nowrap_text">
            {item.get("contact_email")}
          </div>
        </BootstrapTooltip>
      </div>

      <div>
        {capitalizeAddress(
          item.get("street_address"),
          item.get("suburb"),
          item.get("city"),
          item.get("postal_code"),
          item.get("state"),
          item.get("country"),
        )}
      </div>
      {allowEditContact && (
        <React.Fragment>
          <div className="align_center">{item.get("active_contracts")}</div>
          <div className="action">
            <Link
              to={`/contacts/edit/${item.get("entity_id")}&&${item.get(
                "contact_id",
              )}`}
              style={{ textDecoration: "none" }}
            >
              <ImgEdit />
            </Link>

            {canDelete ? (
              <ImgDelete
                id={item.get("contact_id")}
                onClick={() => handleDelete(item)}
              />
            ) : (
              <BootstrapTooltip
                title="Can’t delete contact participating in a contract"
                placement="bottom"
              >
                <ImgDelete id={item.get("contact_id")} className="disable" />
              </BootstrapTooltip>
            )}
          </div>
        </React.Fragment>
      )}
    </ListItem>
  );
};

export default function contactList(props) {
  const {
    list,
    selectedSortOption,
    limit,
    currentPage,
    handleDeleteContact,
    accountRole,
    handleActivate,
    handleResendEmail,
    sendingEmail,
  } = props;
  let sortedList = list.sortBy((val, index, obj) => {
    let sortOption = selectedSortOption.split(".");
    if (sortOption.length === 2) {
      // Use get() instead of getIn() for single-level access.
      return val.get(sortOption[1]);
    }
    return val.get(selectedSortOption);
  }, compareByOption(selectedSortOption));

  let allowEditContact = profile.checkPermission(CONTACT, EDIT);

  return (
    <div>
      <TableHeader className={allowEditContact ? "contact" : "view-only"}>
        <Fragment>
          <div className="firstItem">Company</div>
          <div>Name</div>
          <div>Basic Info</div>
          <div>Company Address</div>
          {allowEditContact && <div>Active Projects</div>}
        </Fragment>
      </TableHeader>
      <ListItemWrapper>
        {sortedList.map((item, index) => {
          let page = currentPage - 1;
          if (index >= page * limit && index < (page + 1) * limit) {
            return (
              <ContactList
                key={item?.get("contact_id") || item?.get("id")}
                handleDelete={handleDeleteContact}
                item={item}
                accountRole={accountRole}
                handleActivate={handleActivate}
                handleResendEmail={handleResendEmail}
                sendingEmail={sendingEmail}
                allowEditContact={allowEditContact}
              />
            );
          } else return "";
        })}
      </ListItemWrapper>
    </div>
  );
}
