import React from "react";
import Tooltip from "./index";
import { TimezoneTip } from "./style";

const Timezone = ({ zoneAbbr, zoneName }) => {
  return (
    <Tooltip
      title={zoneName}
      color={"#595C5C"}
      fontColor={"#FFFFFF"}
      size="12px"
      placement="bottom"
    >
      <TimezoneTip>{zoneAbbr}</TimezoneTip>
    </Tooltip>
  );
};

export default Timezone;
