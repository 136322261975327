import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Flags from "country-flag-icons/react/3x2";
import { isImmutable } from "immutable";

import {
  PageLayout,
  Header,
  Body,
  ContentContainer,
  Content,
  ALinkStyle,
  LinkStyle,
  Error,
} from "./style";

import Input from "../../common/form";
import { PhoneComponent } from "../../common/form/telephone";
import { actionCreators } from "../../common/modal";
import { actionCreators as configActionCreators } from "../../common/config/store";
import PreSignUp from "./preSignUp";
import PostSignUp from "./postSignUp";
import { YellowBtn } from "../../common/button";
import { DEFAULT_COUNTRY } from "../../utils/constants";
import API from "../../server";

const FieldInput = (props) => {
  return (
    <div className="input-field">
      <Input {...props} className="input" />
      {props.error && (
        <Error>
          <div className="error_img" />
          {props.error}
        </Error>
      )}
    </div>
  );
};

const PhoneNumberInput = (props) => {
  return (
    <div className="input-field">
      <Input label="Phone Number" field="child">
        <PhoneComponent
          addPhoneNumber={props.value}
          defaultValue={props.defaultValue}
          handleOnChange={props.handleChange}
        />
      </Input>
      {props.error && (
        <Error>
          <div className="error_img" /> {props.error}
        </Error>
      )}
    </div>
  );
};

class index extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        company: "",
        phone_number: "",
        country: null,
      },
      signupStep: 1,
      isValidating: false,
      errors: {},
      submitting: false,
      countriesKeys: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.nextStep = this.nextStep.bind(this);
  }

  nextStep(e) {
    e.preventDefault();
    this.setState({ signupStep: 2 });
  }

  onValidate(values) {
    const errors = {};
    if (values.first_name === "") {
      errors.first_name = "Required";
    }
    if (values.last_name === "") {
      errors.last_name = "Required";
    }
    if (values.phone_number === "" || !values.phone_number) {
      errors.phone_number = "Required";
    } else {
      if (
        values.phone_number.startsWith("+61") ||
        values.phone_number.startsWith("+64")
      ) {
        var ausMobile =
          //eslint-disable-next-line
          /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
        let nzMobile =
          //eslint-disable-next-line
          /^(((\+?64\s*[-\.]?[3-9]|\(?0[3-9]\)?)\s*[-\.]?\d{3}\s*[-\.]?\d{4})|((\+?64\s*[-\.\(]?2\d{1}[-\.\)]?|\(?02\d{1}\)?)\s*[-\.]?\d{3}\s*[-\.]?\d{3,5})|((\+?64\s*[-\.]?[-\.\(]?800[-\.\)]?|[-\.\(]?0800[-\.\)]?)\s*[-\.]?\d{3}\s*[-\.]?(\d{2}|\d{5})))$/;
        if (
          !nzMobile.test(values.phone_number) &&
          !ausMobile.test(values.phone_number)
        ) {
          errors.phone_number = "Invalid phone number";
        }
      }
    }
    if (values.country === "") {
      errors.country = "Required";
    }
    if (values.company === "") {
      errors.company = "Required";
    }
    if (values.email === "") {
      errors.email = "Required";
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    return this.setState({ errors });
  }

  async validate() {
    var valid = true;
    await this.onValidate(this.state.form);
    valid &= Object.keys(this.state.errors).length === 0;
    return valid;
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setState({ isValidating: true });

    if (!(await this.validate())) return;

    return this.setState({ submitting: true }, () => {
      const nextLocation = new URLSearchParams(window.location.search).get(
        "next",
      );
      API.sign_up(JSON.stringify(this.state.form), { next: nextLocation })
        .then((res) => res.data)
        .then(
          (res) => {
            this.setState({ submitting: false, signupStep: 3 });
          },
          (error) => {
            this.setState({ submitting: false });
          },
        );
    });
  }

  handleChange(e, value) {
    let newForm = {};
    if (!value && e.target) {
      newForm = Object.assign({}, this.state.form, {
        [e.target.name]: e.target.value,
      });
    } else {
      newForm = Object.assign({}, this.state.form, {
        [e]: value,
      });
    }
    this.state.isValidating && this.onValidate(newForm);

    return this.setState({ form: newForm });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props?.ipAddressDetails &&
      this.props?.ipAddressDetails?.country !==
        prevProps.ipAddressDetails?.country
    ) {
      if (this.state.form.country === null) {
        this.setState({
          form: {
            ...this.state.form,
            country: this.props?.ipAddressDetails?.country || DEFAULT_COUNTRY,
          },
        });
      }
    }

    if (
      !isImmutable(this.props?.countriesList) &&
      isImmutable(prevProps?.countriesList)
    ) {
      let countries = {};
      this.props?.countriesList.map((country) => {
        countries[country.value] = country.label;
        return country;
      });
      this.setState({ countriesKeys: Object.keys(countries) });
    }
  }

  componentDidMount() {
    this.props.getCountries();
    this.props.getIPAddressDetails();
  }

  componentWillUnmount() {
    return this.setState({
      form: {
        first_name: "",
        last_name: "",
        email: "",
        company: "",
        phone_number: "",
        country: null,
      },
      isValidating: false,
      countriesList: [],
      errors: {},
      submitting: false,
    });
  }

  render() {
    let { form, errors, submitting } = this.state;
    const nextLocation = new URLSearchParams(window.location.search).get(
      "next",
    );
    const nextQueryParam = nextLocation ? `?next=${nextLocation}` : "";
    const Flag = Flags[form?.country || DEFAULT_COUNTRY];
    const selectedCountry = this.props.countriesList.find(
      (ele) => ele.value === form?.country,
    );

    return (
      <PageLayout>
        <Header>
          <div className="logo" />
        </Header>
        <Body>
          <ContentContainer>
            <Content className={`step${this.state.signupStep}`}>
              {this.state.signupStep === 1 && (
                <PreSignUp
                  countriesList={this.props.countriesList}
                  countriesKeys={this.state.countriesKeys}
                  form={form}
                  nextStep={this.nextStep}
                  handleChange={this.handleChange}
                />
              )}
              {this.state.signupStep === 3 && <PostSignUp />}

              {this.state.signupStep === 2 && (
                <>
                  <div className="title">Create A New Account</div>
                  <form>
                    <div className="input-name-wrapper margin-reset">
                      <label className="text-header">Account Country</label>
                    </div>
                    <div>
                      <div className="input-name-wrapper margin-reset flag-contianer">
                        <div>
                          <Flag className="flag" />
                          <span>{selectedCountry?.label}</span>
                        </div>
                        <div>
                          <a
                            className="change"
                            onClick={() => {
                              this.setState({ signupStep: 1 });
                            }}
                          >
                            Change
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="input-name-wrapper">
                      <FieldInput
                        label="First Name"
                        name="first_name"
                        value={form.first_name}
                        onChange={this.handleChange}
                        error={errors.first_name}
                      />
                      <FieldInput
                        label="Last Name"
                        name="last_name"
                        onChange={this.handleChange}
                        value={form.last_name}
                        error={errors.last_name}
                      />
                    </div>

                    <FieldInput
                      label="Email Address"
                      name="email"
                      onChange={this.handleChange}
                      value={form.email}
                      error={errors.email}
                    />
                    <div className="input-name-wrapper">
                      <FieldInput
                        label="Company Name"
                        name="company"
                        onChange={this.handleChange}
                        value={form.company}
                        error={errors.company}
                      />
                      <PhoneNumberInput
                        value={form.phone_number}
                        handleChange={(phone) =>
                          this.handleChange("phone_number", phone)
                        }
                        error={errors.phone_number}
                        defaultValue={form.country}
                      />
                    </div>
                  </form>
                  <div className="notice-label-wrapper">
                    <YellowBtn
                      title="Create Account"
                      submitting={submitting}
                      onClick={this.handleSubmit}
                      disabled={Object.keys(errors).length !== 0 || submitting}
                      className="full-width"
                    />
                    <div className="hasMargin">
                      By signing up you agree to our{" "}
                      <ALinkStyle
                        href="https://paylab.asbuiltvault.com/terms-of-service"
                        target="_blank"
                      >
                        Terms
                      </ALinkStyle>{" "}
                      and{" "}
                      <ALinkStyle
                        href="https://www.asbuiltdigital.com/privacy-policy"
                        target="_blank"
                      >
                        Privacy Policy
                      </ALinkStyle>
                      .
                    </div>
                    <div>
                      Already have an account?{" "}
                      <LinkStyle to={"/login" + nextQueryParam}>
                        Log In here
                      </LinkStyle>
                    </div>
                  </div>
                </>
              )}
            </Content>
          </ContentContainer>
        </Body>
        <div className="pattern" />
      </PageLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  countriesList: state.getIn(["config", "countriesList"]),
  ipAddressDetails: state.getIn(["config", "ipAddressDetails"]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    displaySuccessMessage(res) {
      dispatch(
        actionCreators.showModal("confirm", {
          open: true,
          title: "Success!",
          message: res.data.info,
          url: "/login",
        }),
      );
    },
    getCountries() {
      dispatch(configActionCreators.readCountries());
    },
    getIPAddressDetails() {
      dispatch(configActionCreators.readPublicIPAddressDetails());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(index);
