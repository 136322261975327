import styled from "styled-components";
import * as theme from "../../theme";
export const Loading = styled.div`
  width: max-content;
  height: 120px;
  border-radius: 4px;
  background-color: ${theme.palette.wolfGrey};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 35vh;
  .message {
    font-size: 18px;
    font-weight: ${theme.font.mainFontWeight};
    color: ${theme.palette.white};
  }
`;
