import React from "react";
import { CustomizeSkeleton } from "./base";

export const TableSkeleton = ({ rowNum = 10, columnNum }) => {
  const TableRow = () => (
    <tr>
      {Array.from({ length: columnNum }, (_, i) => (
        <td key={i}>
          <CustomizeSkeleton height="50%" />
        </td>
      ))}
    </tr>
  );

  return Array.from({ length: rowNum }, (_, i) => <TableRow key={i} />);
};

export const skeletonData = [{ id: 1 }];
export const skeletonCol = [
  {
    title: "Ref",
    width: "8%",
    render: () => (
      <table>
        <tbody>
          <TableSkeleton columnNum={1} />
        </tbody>
      </table>
    ),
  },
  {
    title: "Description",
    width: "9%",
    render: () => (
      <table>
        <tbody>
          <TableSkeleton columnNum={1} />
        </tbody>
      </table>
    ),
  },
  {
    title: "Qty",
    width: "14%",
    render: () => (
      <table>
        <tbody>
          <TableSkeleton columnNum={1} />
        </tbody>
      </table>
    ),
  },
  {
    title: "Unit",
    width: "9%",
    render: () => (
      <table>
        <tbody>
          <TableSkeleton columnNum={1} />
        </tbody>
      </table>
    ),
  },
  {
    title: "Rate",
    width: "15%",
    render: () => (
      <table>
        <tbody>
          <TableSkeleton columnNum={1} />
        </tbody>
      </table>
    ),
  },
  {
    title: "Total",
    width: "15%",
    render: () => (
      <table>
        <tbody>
          <TableSkeleton columnNum={1} />
        </tbody>
      </table>
    ),
  },
  {
    title: "Action",
    width: "8%",
    render: () => (
      <table>
        <tbody>
          <TableSkeleton columnNum={1} />
        </tbody>
      </table>
    ),
  },
];

export default { TableSkeleton };
