import styled from "styled-components";
import * as theme from "../../../../theme";
import downloadIcon from "../../../../statics/img/download-file-medium-grey.svg";
import searchIcon from "../../../../statics/img/icon-search-dark-grey.svg";
import warningYellowIcon from "../../../../statics/img/icon-information-yellow.svg";
import warningRedIcon from "../../../../statics/img/icon-information-red.svg";

export const PlanInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const BillInfo = styled.div`
  height: 100%;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  padding: 40px;
  box-sizing: border-box;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
export const Subtitle = styled.div`
  font-size: 24px;
  font-weight: ${theme.font.mainFontWeight};
  margin-bottom: 40px;
`;

export const InfoItem = styled.div`
  height: auto;
  &.sub {
    width: 168px;
    font-size: 16px;
    float: left;
  }
  &.middle {
    margin: 40px 0 0;
  }
  .title {
    font-weight: ${theme.font.mainFontWeight};
    margin-bottom: 6px;
    &.main {
      font-size: 20px;
    }
  }
  .content {
    font-weight: ${theme.font.normalFontWeight};
  }
  .contentWrapper {
    height: 98px;
    width: 100%;
    background-color: ${theme.palette.greyWhite};
    border-radius: 4px;
    padding: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .label {
      height: 48px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
`;

export const PaymentInfo = styled.div`
  height: fit-content;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  padding: 40px;
  box-sizing: border-box;
  font-size: 16px;
  table {
    width: 100%;
    border-spacing: 0px;
  }
  thead th {
    border: none !important;
    height: 40px;
    text-align: left;
  }
  tr td {
    height: 40px;
    border-top: 1px solid ${theme.palette.lightWolfGrey};
    text-align: left;
    .pdf {
      width: 24px;
      height: 24px;
      background: url(${downloadIcon}) no-repeat;
      cursor: pointer;
    }
  }
  .so-table-normal:nth-child(2n) {
    background-color: white;
    &:hover {
      background-color: ${theme.palette.lightGrey};
    }
  }
`;
export const PaymentDetail = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const SearchInput = styled.div`
  height: 44px;
  width: 232px;
  background-color: ${theme.palette.white};
  box-sizing: border-box;
  float: right;
  border-radius: 4px;
  padding: 10px 10px 10px 16px;
  border: 1px solid ${theme.palette.wolfGrey};
  margin-left: 20px;
  position: relative;
  input {
    ::placeholder {
      color: ${theme.palette.wolfGrey};
    }
    outline: none;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-family: ${theme.font.mainFont};
    font-weight: ${theme.font.normalFontWeight};
    float: left;
    height: 100%;
    width: 180px;
    color: ${theme.palette.gunmetal};
  }
  .searchIcon {
    height: 24px;
    width: 24px;
    background: url(${searchIcon}) no-repeat scroll;
    background-size: 24px;
    position: absolute;
    right: 14px;
    top: 10px;
  }
`;
export const ErrorMeg = styled.div`
  width: 100%;
  border-radius: 4px;
  padding: 16px;
  background-color: ${(props) =>
    props.errorType === "error"
      ? theme.palette.lightPastelPink
      : theme.palette.paleYellow};
  display: flex;
  line-height: 18px;
  margin-top: 8px;
  .warningIcon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    background-image: url(${(props) =>
      props.errorType === "error" ? warningRedIcon : warningYellowIcon});
    background-size: contain;
    transform: rotate(
      ${(props) => (props.errorType === "error" ? 0 : `180deg`)}
    );
  }
  .warningTitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
  }
`;
