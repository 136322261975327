import React, { Component } from "react";
import { connect } from "react-redux";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { BarChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  DataZoomComponent,
  LegendComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

import { convertToCurrencyUnit } from "../../../utils/currencyHelper";
import * as mainTheme from "../../../theme";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  DataZoomComponent,
  LegendComponent,
]);
class Chart extends Component {
  getOption = (chartData, shorten, hideCurrency, currencyCode) => {
    let [xAxisData, claimBar, certBar] = chartData;
    let claimBarName = "",
      certBarName = "";
    if (xAxisData) {
      xAxisData = xAxisData.slice(1);
    }
    if (claimBar) {
      claimBarName = claimBar[0].replace(
        "Claimed",
        this.props.accountConfig?.getIn(["claim", "value"]),
      );
      claimBar = claimBar.slice(1);
    }
    if (certBar) {
      certBarName = certBar[0];
      certBar = certBar.slice(1);
    }

    let option = {
      legend: { show: false },
      tooltip: {
        backgroundColor: mainTheme.palette.gunmetal,
        formatter: function (params) {
          var relVal = `<div style="font-weight:"bold"">${params.seriesName}</div>`;
          relVal +=
            `<span style="display:inline-block;margin-right:5px;width:10px;height:10px;background-color:${params.color};"></span>` +
            `${params.name} : ${convertToCurrencyUnit(params.value)}</br>`;
          return relVal;
        },
        textStyle: {
          color: mainTheme.palette.white,
        },
      },
      xAxis: [
        {
          type: "category",
          name: new Date().getFullYear(),
          data: xAxisData,
          gridIndex: 0,
          nameLocation: "middle",
          nameGap: 40,
          nameTextStyle: {
            color: mainTheme.palette.veryMediumGrey,
            fontSize: 16,
          },
          axisLine: { lineStyle: { color: mainTheme.palette.lightWolfGrey } },
          axisLabel: {
            fontWeight: "bold",
            color: mainTheme.palette.gunmetal,
            fontFamily: mainTheme.font.mainFont,
            fontSize: 16,
            lineHeight: 24,
          },
        },
      ],
      yAxis: {
        gridIndex: 0,
        type: "value",
        axisLine: {
          show: true,
          lineStyle: { color: mainTheme.palette.lightWolfGrey },
        },
        axisLabel: {
          color: mainTheme.palette.veryMediumGrey,
          fontFamily: mainTheme.font.mainFont,
          fontSize: 14,
          formatter: function (value) {
            return convertToCurrencyUnit(value, false, true, shorten);
          },
        },
      },
      grid: {
        show: false,
        left: "15%",
        top: "10%",
        bottom: "25%",
        right: "5%",
      },
      series: [
        {
          type: "bar",
          seriesLayoutBy: "row",
          color: mainTheme.palette.tangerineOrange,
          data: claimBar,
          name: claimBarName,
        },
        {
          type: "bar",
          seriesLayoutBy: "row",
          color: mainTheme.palette.lightWolfGrey,
          data: certBar,
          name: certBarName,
        },
      ],
    };

    if (hideCurrency) {
      option["yAxis"]["name"] = `(In ${currencyCode})`;
      option["yAxis"]["nameLocation"] = "middle";
      option["yAxis"]["nameGap"] = 40;
      option["yAxis"]["nameTextStyle"] = {
        color: mainTheme.palette.veryMediumGrey,
        fontSize: 16,
      };
    }
    return option;
  };

  processArray = (array) => {
    return array.map((element, index) =>
      index === 0 ? element : element / 100,
    );
  };

  render() {
    const { chartData, shorten, enableHeaderCurrency, currencyCode } =
      this.props;

    let processedChartData = [
      chartData[0],
      this.processArray(chartData[1]),
      this.processArray(chartData[2]),
    ];
    return (
      <ReactEChartsCore
        echarts={echarts}
        option={this.getOption(
          processedChartData,
          shorten,
          enableHeaderCurrency,
          currencyCode,
        )}
        notMerge={true}
        lazyUpdate={true}
        style={{
          height: "100%",
          width: "100%",
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  enableHeaderCurrency: state.getIn([
    "config",
    "accountConfig",
    "enable_header_currency",
  ]),
  currencyCode: state.getIn(["config", "accountConfig", "currency", "code"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default connect(mapStateToProps, null)(Chart);
