import React, { PureComponent } from "react";
import { connect } from "react-redux";
import debounce from "lodash/debounce";

import { ProjectInfoWrapper, ProjectWrapper } from "../styled";
import { BgWrapper, HeaderTitle } from "../../../common/shared_style";

import BctiModal from "../../../common/bcti";
import Menu from "../../../common/breadcrumbs";
import ApprovalChart from "../../../common/approval_flow";
import ApproveActions from "../../../common/approval_flow/approve_action";
import LeaveComment from "../../../common/approval_flow/components/approval_comment";
import Attachment from "../../../common/claim_cert_attachment";
import Loading from "../../../common/loading";

import { OverviewPageUrlContractType } from "../../contract/common";
import Notes from "../shared/note";
import ProjectInfo from "./components/projectInfo";
import Table from "./components/claimReviewTable";
import SummaryPart from "./components/summaryData";
import Contact from "./components/contactInfo";
import NavBarBottom from "./components/navBarBottom";
import { getMenuLink } from "../components/pageMenu";
import profile from "../../../utils/profile";
import {
  CONTRACT_TYPE,
  CONTRACT,
  EDIT,
  STATUS_DRAFT,
  STATUS_ISSUE,
  STATUS_APPROVED,
} from "../../../utils/constants";

import history from "../../../utils/history";
import API from "../../../server";

import { actionCreators } from "../store";
import { actionCreators as actionCreatorsContract } from "../../contract/store";
import { checkIfHavePermission as makeAClaimCheckIfHavePermission } from "../../contract/view/components/claimHistoryTable";

// draft/approve claim info page
// only viewed by claim/self-claim/self-cert contract

function checkIfHavePermission(
  list,
  user,
  contractType,
  claimStatus,
  readOnly,
) {
  if (claimStatus !== STATUS_DRAFT || readOnly) {
    return false;
  }
  //no workflow situation
  if (contractType === CONTRACT_TYPE.selfClaim || list.size === 0) {
    return true;
  }

  const { current_step_index, steps } = list.toJS();
  //only create/issue two step, allow to approve
  if (steps.length === 2) {
    return true;
  }
  //last step, not allow to approve
  if (current_step_index === steps?.length - 1) {
    return false;
  }
  const currentStep = steps?.find((step) => step.step === current_step_index);

  if (!currentStep) {
    return true;
  }

  //only approvers in current step workflow, allow approve
  const approvals = currentStep.approvals || [];

  const userApproval = approvals.find(
    (approval) =>
      approval.user_email === user.get("email") && !approval.approved,
  );
  return !!userApproval;
}
class ProjectMakeClaim extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      allowEditApproval: false,
      bctiInfo: {},
      open: false,
      currentUserApproved: true,
    };
  }

  performIssuePayclaim = () => {
    const {
      emailToList,
      emailSubject,
      attachedEmailFiles,
      isSendEmail,
      issuePayclaim,
      accountInfo,
    } = this.props;

    const { proId, contractId, claimId, contractType } =
      this.props.match.params;

    const isLinked =
      accountInfo?.get("integrations")?.size > 0 ? "true" : "false";

    issuePayclaim(
      proId,
      contractId,
      claimId,
      isSendEmail,
      emailToList,
      emailSubject,
      attachedEmailFiles,
      contractType,
      isLinked,
    );
  };

  saveIssuePayclaim = () => {
    const { proId, contractId } = this.props.match.params;
    const { contractInfo } = this.props;

    if (contractInfo.get("is_bcti")) {
      const view =
        contractInfo?.get("contract_type") === CONTRACT_TYPE.selfClaim
          ? CONTRACT_TYPE.selfClaim
          : CONTRACT_TYPE.claim;

      API.check_bcti(proId, contractId, view)
        .then((res) => res.data.data)
        .then((res) => {
          if (
            res.bcti["has_gst"] &&
            res.bcti["has_name"] &&
            res.bcti["has_address"]
          ) {
            this.performIssuePayclaim();
          } else {
            this.setState({ open: true, bctiInfo: res.bcti });
          }
        });
    } else {
      this.performIssuePayclaim();
    }
  };

  performBctiSubmit = (data) => {
    const { proId, contractId, contractType } = this.props.match.params;
    const { contractInfo, accountId } = this.props;

    const isAccountAdmin = profile.isAdminOrOwner();

    if (isAccountAdmin) {
      if (
        contractType === CONTRACT_TYPE.claim ||
        contractType === CONTRACT_TYPE.selfCert
      ) {
        // Update Details at the Account Level
        this.props.updateBctiInfo(proId, contractId, data);
        const doPerformIssuePayclaim = debounce(
          () => this.performIssuePayclaim(),
          1200,
        );
        doPerformIssuePayclaim();
      } else if (contractType === CONTRACT_TYPE.selfClaim) {
        const payeeCompany = contractInfo?.get("payee_company");
        data.entity_id =
          payeeCompany?.get("entity_id") ||
          contractInfo?.get("payee_entity_id");
        data.entity_name =
          payeeCompany?.get("entity_name") ||
          contractInfo?.get("payee_entity_name");
        data.street_address =
          data?.street_address ||
          payeeCompany?.get("street_address") ||
          contractInfo?.get("payee_entity_streetaddress");
        data.postal_code =
          data?.postcode ||
          payeeCompany?.get("postal_code") ||
          contractInfo?.get("payee_entity_postal_code");
        data.suburb =
          data?.suburb ||
          payeeCompany?.get("suburb") ||
          contractInfo?.get("payee_entity_suburb");
        data.city =
          data?.city ||
          payeeCompany?.get("city") ||
          contractInfo?.get("payee_entity_city");
        data.state =
          data?.state ||
          payeeCompany?.get("state") ||
          contractInfo?.get("payee_entity_state");
        data.country =
          data?.country ||
          payeeCompany?.get("country") ||
          contractInfo?.get("payee_entity_country");
        data.gst_no =
          data?.gst_number ||
          payeeCompany?.get("gst_no") ||
          contractInfo?.get("payee_gst_number");

        API.upsert_account_company(data)
          .then((res) => res.data)
          .then((res) => res.data.id)
          .then((res) => {
            this.performIssuePayclaim();
          });
      }
    } else {
      history.push(
        `/contract/${proId}/${OverviewPageUrlContractType(
          contractType,
        )}/${contractId}?account_id=${accountId}`,
      );
    }

    this.setState({ open: false });
  };

  updateComment = (action, content, comment_id, lineId) => {
    this.props.updateComment(
      this.props.match.params.proId,
      this.props.match.params.contractId,
      this.props.match.params.claimId,
      action,
      content,
      comment_id,
      lineId,
    );
  };

  fetchClaimWorkflow = async (proId, contractId, claimId, userProfile) => {
    const res = await API.read_claim_workflow(proId, contractId, claimId);
    const data = res.data.data.data;

    if (data && Object.keys(data).length > 0) {
      const approverPermission = makeAClaimCheckIfHavePermission(
        data,
        userProfile,
      );
      return approverPermission;
    }
  };

  handleMakeAClaimBreadCrumbsClick = async () => {
    const { proId, contractId, contractType, claimId } =
      this.props.match.params;
    const { accountId, userProfile } = this.props;

    if (
      accountId &&
      claimId &&
      contractType === CONTRACT_TYPE.claim &&
      userProfile
    ) {
      const makeClaimPath = `/project/${proId}/contract/${contractId}/${contractType}/claim/${claimId}?account_id=${accountId}`,
        reviewClaimPath = `/project/${proId}/contract/${contractId}/${contractType}/claim/${claimId}/review?account_id=${accountId}`;

      const approverPermission = await this.fetchClaimWorkflow(
        proId,
        contractId,
        claimId,
        userProfile,
      );
      history.push(approverPermission ? makeClaimPath : reviewClaimPath);
    }
  };

  render() {
    const {
      accountId,
      contractInfo,
      projectInfo,
      loading,
      claimDetail,
      claim_status,
      claim_attachments,
      readOnly,
      accountConfig,

      baseContractTable,
      baseTotalValue,
      baseClaimTotal,

      variationsTable,
      variationsTotal,
      variationClaimTotal,

      materialsTable,

      approvalList,
      userProfile,
      approval_comment,
      netCertTotal,

      emailToList,
      isSendEmail,
      autoSendEmails,
      sendingEmail,
      approvingClaim,
      exceedAttachLimit,

      approvePayclaim,
      cancelClaim,
      downloadPdf,
      handleRevertDraft,
      leaveComment,
      closeDialog,
      setData,
      approveClaimByStep,

      deleteFile,
      downloadFile,
      changeSharedFiles,
      isAuditAttached,
      setPdfAudit,
      timeZone,
      requestContractInfo,
      revertByStep,
    } = this.props;

    const { proId, contractId, claimId, contractType } =
      this.props.match.params;

    const { allowEditApproval, currentUserApproved } = this.state;
    const isAccountAdmin = profile.isAdminOrOwner();
    const payeeCompany = contractInfo?.get("payee_company");
    const payeeEntityName =
      payeeCompany?.get("entity_name") ||
      contractInfo?.get("payee_entity_name");
    const claimTerm = accountConfig?.get("claim");

    const options = [
      {
        label: "View file in new tab",
        value: () => downloadPdf(proId, contractId, claimId, false),
      },
      {
        label: "Save file to your device",
        value: () => downloadPdf(proId, contractId, claimId, true),
      },
    ];

    const editableWorkflow =
      allowEditApproval &&
      claim_status === STATUS_DRAFT &&
      approvalList.get("current_step_index") < approvalList?.get("steps")?.size;
    return (
      <BgWrapper className="medium">
        <Menu
          link={getMenuLink(
            projectInfo,
            contractId,
            contractInfo.get("contract_name"),
            contractType,
            claimId,
            claimTerm?.get("verb"),
            this.handleMakeAClaimBreadCrumbsClick,
          )}
          content={`Review and Submit ${claimTerm?.get("noun")}`}
          claimTitle={claimTerm?.get("verb")}
        />
        <HeaderTitle>Review and Submit {claimTerm?.get("noun")}</HeaderTitle>
        <ProjectWrapper>
          {!loading ? (
            <>
              <ProjectInfoWrapper>
                <ProjectInfo
                  claimDetail={claimDetail}
                  claimInfo={contractInfo}
                />
                <Table
                  title={"Base Contract"}
                  tableContent={baseContractTable}
                  totalTable={baseTotalValue}
                  calculateClaimTotal={baseClaimTotal}
                  updateComment={this.updateComment}
                  allowAddComment={true}
                  accountConfig={accountConfig}
                  isIssued={false}
                />
                <Table
                  title={"Variations"}
                  tableContent={variationsTable}
                  totalTable={variationsTotal}
                  calculateClaimTotal={variationClaimTotal}
                  updateComment={this.updateComment}
                  allowAddComment={true}
                  accountConfig={accountConfig}
                  isIssued={false}
                />
                {materialsTable.size > 0 && (
                  <Table
                    title="Materials On/Off Site"
                    tableContent={materialsTable}
                    updateComment={this.updateComment}
                    allowAddComment={true}
                    accountConfig={accountConfig}
                    allowEditContract={allowEditApproval}
                    isIssued={false}
                    isMaterial
                  />
                )}
                <div className="bottomWrapper">
                  <SummaryPart
                    data={contractInfo}
                    type="review"
                    netCertTotal={netCertTotal}
                    claimDetail={claimDetail}
                  />
                  <div className="line" />
                  <Notes claimInfo={claimDetail} isReview />
                  <div className="line" />
                  <Attachment
                    title={accountConfig?.getIn(["claim", "noun"])}
                    data={claim_attachments}
                    readOnly={readOnly}
                    contractInfo={contractInfo}
                    uploadFileApi={{
                      name: "upload_file_claim",
                      config: [contractId, claimId],
                    }}
                    readInfo={() =>
                      requestContractInfo(proId, contractId, claimId)
                    }
                    deleteFile={(fileId) =>
                      deleteFile(proId, contractId, claimId, fileId)
                    }
                    downloadFile={downloadFile}
                    changeSharedFiles={(fileId, isAdd) =>
                      changeSharedFiles(
                        proId,
                        contractId,
                        claimId,
                        fileId,
                        isAdd,
                      )
                    }
                  />
                  {/* display workflow for claim/self-cert contract*/}
                  {(contractType === CONTRACT_TYPE.claim ||
                    contractType === CONTRACT_TYPE.selfCert) && (
                    <>
                      <ApprovalChart
                        workflowURL={`/project/${proId}/contract/${contractId}/${contractType}/claim/${claimId}/workflow`}
                        data={approvalList}
                        editable={editableWorkflow}
                        isComplete={claim_status === STATUS_ISSUE}
                        timezone={timeZone}
                        setPdfAudit={(e) =>
                          setPdfAudit(
                            proId,
                            contractId,
                            claimId,
                            e.target.checked,
                          )
                        }
                        isAuditAttached={isAuditAttached}
                        editAudit={
                          allowEditApproval && claim_status === STATUS_APPROVED
                        }
                      />
                    </>
                  )}
                  {claim_status !== STATUS_DRAFT && !readOnly && (
                    <>
                      <div className="line" />
                      <Contact {...this.props} claimStatus={claim_status} />
                    </>
                  )}
                </div>
              </ProjectInfoWrapper>
              {checkIfHavePermission(
                approvalList,
                userProfile,
                contractType,
                claim_status,
                readOnly,
              ) && (
                <ApproveActions
                  workflowType={"claim"}
                  approvers={approvalList}
                  goToRevert={(comment, stepId) =>
                    revertByStep(proId, contractId, claimId, stepId, comment)
                  }
                  approving={approvingClaim}
                  handleApprove={() =>
                    approvePayclaim(proId, contractId, claimId)
                  }
                />
              )}
              <NavBarBottom
                readOnly={readOnly}
                proId={proId}
                contractId={contractId}
                claimId={claimId}
                accountId={accountId}
                contractType={contractType}
                type={claim_status}
                isSendEmail={isSendEmail}
                profile={userProfile}
                approvalList={approvalList}
                emailToList={emailToList}
                autoSendEmails={autoSendEmails}
                sendingEmail={sendingEmail}
                exceedAttachLimit={exceedAttachLimit}
                approvingClaim={approvingClaim}
                handleIssuePayclaim={() => this.saveIssuePayclaim()}
                handleApprovePayclaim={() =>
                  approvePayclaim(proId, contractId, claimId)
                }
                options={options}
                handleCancel={() =>
                  cancelClaim(proId, contractId, claimId, contractType)
                }
                handleRevertDraft={() =>
                  handleRevertDraft(proId, contractId, claimId, contractType)
                }
                accountConfig={accountConfig}
                currentUserApproved={currentUserApproved}
              />
              {leaveComment && (
                <LeaveComment
                  open={leaveComment}
                  content={approval_comment}
                  closeDialog={closeDialog}
                  handleInput={(e) =>
                    setData("approval_comment", e.target.value)
                  }
                  goToApprove={() =>
                    approveClaimByStep(proId, contractId, claimId)
                  }
                  type="claim"
                />
              )}
            </>
          ) : (
            <Loading />
          )}
        </ProjectWrapper>
        <BctiModal
          isOpen={this.state.open}
          submit={(data) => {
            this.performBctiSubmit(data);
          }}
          close={() => {
            this.setState({ open: false });
          }}
          bctiInfo={this.state.bctiInfo}
          isAccountAdmin={isAccountAdmin}
          page="issueClaim"
          contactCompany={
            contractType === CONTRACT_TYPE.selfClaim
              ? payeeEntityName
              : undefined
          }
        />
      </BgWrapper>
    );
  }

  componentDidMount() {
    const projectId = this.props.match.params.proId;
    const contractId = this.props.match.params.contractId;
    const claimId = this.props.match.params.claimId;

    if (projectId && contractId) {
      profile
        .checkPermission(CONTRACT, EDIT, projectId, contractId)
        .then((res) => {
          this.setState({ allowEditApproval: res });
        });

      const { userProfile } = this.props;
      this.fetchClaimWorkflow(projectId, contractId, claimId, userProfile).then(
        (approverPermission) => {
          this.setState({ currentUserApproved: approverPermission });
        },
      );
    }
  }
  componentDidUpdate(prevProps) {
    const { proId, contractId, claimId } = this.props.match.params;
    const { userProfile, approvingClaim } = this.props;
    if (approvingClaim !== prevProps.approvingClaim && !approvingClaim) {
      this.fetchClaimWorkflow(proId, contractId, claimId, userProfile).then(
        (approverPermission) => {
          this.setState({ currentUserApproved: approverPermission });
        },
      );
    }
  }
}

const mapStateToProps = (state) => ({
  getInfoReady: state.getIn(["makeaclaim", "getInfoReady"]),
  claim_status: state.getIn(["makeaclaim", "claim_status"]),
  userProfile: state.getIn(["headers", "profile"]),
  leaveComment: state.getIn(["makeaclaim", "leaveComment"]),
  approval_comment: state.getIn(["makeaclaim", "approval_comment"]),
  netCertTotal: state.getIn(["makeaclaim", "certRetention", "preNetTotal"]),
  approvingClaim: state.getIn(["makeaclaim", "approvingClaim"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
  accountInfo: state.getIn(["manageAccount", "accountInfo"]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    approvePayclaim(proId, contractId, claimId) {
      dispatch(actionCreators.approveClaim(proId, contractId, claimId));
    },
    issuePayclaim(
      proId,
      contractId,
      claimId,
      isSendEmail,
      emailToList,
      emailSubject,
      attachedEmailFiles,
      contractType,
      isLinked,
    ) {
      dispatch(
        actionCreators.issueClaim(
          proId,
          contractId,
          claimId,
          isSendEmail,
          emailToList,
          emailSubject,
          attachedEmailFiles,
          isLinked,
        ),
      );
    },
    cancelClaim(proId, contractId, claimId, contractType) {
      if (claimId) {
        dispatch(
          actionCreators.deleteClaim(proId, contractId, claimId, contractType),
        );
      }
    },
    closeDialog() {
      dispatch(actionCreators.closeDialog());
    },
    setData(name, value) {
      dispatch(actionCreators.setValue(name, value));
    },
    approveClaimByStep(proId, contractId, claimId) {
      dispatch(actionCreators.approveClaimByStep(proId, contractId, claimId));
    },
    updateBctiInfo(proId, contractId, value) {
      dispatch(actionCreatorsContract.updateBctiInfo(proId, contractId, value));
    },
    revertByStep(proId, contractId, claimId, stepId, comment) {
      dispatch(
        actionCreators.revertByStep(
          proId,
          contractId,
          claimId,
          stepId,
          comment,
        ),
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectMakeClaim);
