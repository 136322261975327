import React from "react";
import { ReviewWrapper } from "../../../makeclaim/styled";
import CertTable from "../../../../common/table/review_cert_table";
import CertMaterialTable from "../../../../common/table/review_cert_table_material";
import { getTableField } from "../../components/paycertTable";

const Index = (props) => (
  <ReviewWrapper className="wide_container">
    {props.isMaterial ? (
      <CertMaterialTable {...props} />
    ) : (
      <CertTable {...props} tableField={getTableField(props.table_title)} />
    )}
  </ReviewWrapper>
);

export default Index;
