import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Container, SectionWrapper } from "../basecontract/styled";

import { BlueOutlinedBtn as ButtonBlue } from "../../common/button";
import Table from "../../common/lineitem/variationTable";
import UploadDialog from "../../common/lineitem/csvUploadDialog";
import Menu from "../../common/breadcrumbs";
import Link from "../../common/linkWithQuery";

import { actionCreators } from "./store";
import { actionCreators as actionCreatorsContract } from "../contract/store";
import { actionCreators as actionCreatorsFile } from "../../common/upload_file_progress/store";

class Variations extends PureComponent {
  render() {
    const {
      handleEditItem,
      variationsTable,
      setEditFiles,
      handleDeleteItem,
      files,
      addNewRow,
      fileDelete,
      uploadFiles,
      contractInfo,
      projectInfo,
      readContract,
    } = this.props;
    const { proId, type, contractId } = this.props.match.params;
    const previousURL = `/contract/${proId}/${type}/${contractId}`;
    const link = [
      { link: "/projects", text: "Projects" },
      { link: `/project/view/${proId}`, text: projectInfo.get("name") },
      { link: previousURL, text: contractInfo.get("name") },
    ];
    const headers = "Variations";

    return (
      <Container>
        <div className="page-breadcrumbs">
          <Menu
            link={link}
            content={headers}
            className="page-breadcrumbs-label"
          />
          <Link to={previousURL}>
            <ButtonBlue title="Back To Contract" />
          </Link>
        </div>
        <h1>{headers}</h1>
        <p>Set up {headers} Items.</p>

        <SectionWrapper>
          <Table
            tableContent={variationsTable.toJS()}
            tableSubtitle={"Add a variation."}
            tableCaption={headers}
            deleteItem={(id) => handleDeleteItem(proId, contractId, id)}
            addRow={(lineType, newRow) =>
              addNewRow(
                proId,
                contractId,
                newRow,
                files,
                "",
                variationsTable,
                lineType,
              )
            }
            addChildRow={(parentId, lineType, newRow) =>
              addNewRow(
                proId,
                contractId,
                newRow,
                files,
                parentId,
                variationsTable,
                lineType,
              )
            }
            handleSaveEdit={(list, id) =>
              handleEditItem(proId, contractId, id, list)
            }
            setEditFiles={(file) => setEditFiles(file)}
            handleUploadFile={(id, file) =>
              uploadFiles(proId, contractId, id, file)
            }
            handleFileDelete={(lineid, fileid) =>
              fileDelete(proId, contractId, lineid, fileid)
            }
            proId={proId}
            contractId={contractId}
            {...this.props}
          >
            <UploadDialog
              type="variations"
              proId={proId}
              contractId={contractId}
              resetInfo={() => readContract(proId, contractId)}
            />
          </Table>
        </SectionWrapper>
      </Container>
    );
  }
  componentDidMount() {
    const { proId, contractId } = this.props.match.params;
    this.props.readContract(proId, contractId);
  }
  componentWillUnmount() {
    this.props.resetStatus();
  }
}

const mapStateToProps = (state) => ({
  variationsTable: state.getIn(["variation", "variationstable"]),
  variationsTotal: state.getIn(["variation", "variationsTotal"]),
  files: state.getIn(["variation", "uploadFiles"]),
  editFileList: state.getIn(["variation", "editFileList"]),
  loading: state.getIn(["variation", "loading"]),
  projectInfo: state.getIn(["contract", "projectInfo"]),
  contractInfo: state.getIn(["variation", "contractInfo"]),
  currency: state.getIn(["config", "accountConfig", "currency", "title"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
  headersConfig: state.getIn(["headers", "config"]),
});
const mapDispatchToProps = (dispatch) => {
  return {
    resetStatus() {
      dispatch(actionCreators.reset());
      dispatch(actionCreatorsFile.clearFiles());
    },
    readContract(proId, contractId) {
      dispatch(actionCreatorsContract.readProject(proId));
      dispatch(actionCreators.getInitialVariationsItems(proId, contractId));
    },
    handleDeleteItem(proId, contractId, itemId) {
      dispatch(actionCreators.deleteLineItem(proId, contractId, itemId));
    },
    handleEditItem(proId, contractId, id, value) {
      dispatch(actionCreators.updateLineItem(proId, contractId, id, value));
    },
    uploadFiles(proId, contractId, id, files) {
      dispatch(actionCreators.uploadFileToDB(proId, contractId, id, files));
    },
    fileDelete(proId, contractId, lineId, fileId) {
      dispatch(actionCreators.deleteFile(proId, contractId, lineId, fileId));
    },
    addNewRow(proId, contractId, newRow, files, parentId, table, lineType) {
      if (parentId === "") {
        dispatch(
          actionCreators.createNewRow(
            proId,
            contractId,
            newRow,
            files,
            table,
            lineType,
          ),
        );
      } else {
        dispatch(
          actionCreators.createNewChildRow(
            proId,
            contractId,
            newRow,
            files,
            parentId,
            table,
            lineType,
          ),
        );
      }
    },
    handleFilesChange(files) {
      dispatch(actionCreators.changeFilesList(files));
    },
    handleFileCancel(name) {
      dispatch(actionCreators.cancelOneFile(name));
    },
    setEditFiles(fileList) {
      dispatch(actionCreators.initialFileList(fileList));
    },
    downloadFile(proId, contractId, fileId, fileName, isView) {
      dispatch(
        actionCreatorsContract.downloadItemFile(
          proId,
          contractId,
          fileId,
          fileName,
          isView,
        ),
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Variations);
