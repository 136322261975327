import * as constants from "./constants";
import { format } from "date-fns";
import { actionCreators as actionCreatorsModal } from "../../modal";
import API from "../../../server";

const setDueProjects = (result) => ({
  type: constants.SET_DUE_PROJECT,
  result,
});
export const setData = (name, value) => ({
  type: constants.SET_DATA,
  name,
  value,
});
export const setImuData = (name, value) => ({
  type: constants.SET_IMU_DATA,
  name,
  value,
});

export const getNoticeInfo = () => {
  return (dispatch) => {
    dispatch(getDueProjects());
    dispatch(getNoticeNum());
    dispatch(getSetupProcess(false));
    dispatch(getDueContracts());
  };
};

const getDueContracts = () => {
  return (dispatch) => {
    API.read_all_claims_received()
      .then((res) => res.data)
      .then((res) => res.data.certlist)
      .then((res) => {
        if (!res) return;
        let hasDueContract = res.filter(
          (item) =>
            (item.cert_due_num !== 0 && item.cert_due_num < 8) ||
            item.unclaimed_days >= 30,
        );
        dispatch(setImuData("due_contracts", hasDueContract.length));
      });
  };
};

const getDueProjects = () => {
  let date = format(new Date(), "yyyy-MM-dd");
  return (dispatch) => {
    API.read_claim_summary({
      claim_date: date,
      is_all: true,
    })
      .then((res) => res.data)
      .then((res) => res.data.claimsummary)
      .then((res) => dispatch(setDueProjects(res)));
  };
};

export const getSetupProcess = (triggerClose) => {
  return (dispatch) => {
    API.read_setup_process()
      .then((res) => res.data)
      .then((res) => res.data.result)
      .then((res) =>
        dispatch({
          type: constants.SET_UP_PROCESS,
          payload: res,
          triggerClose,
        }),
      );
  };
};

export const getNoticeNum = () => {
  return (dispatch) => {
    API.read_notice_num()
      .then((res) => res.data)
      .then((res) => res.data.notenums)
      .then((res) => dispatch(setData("noticeNum", res)));
  };
};

export const login = (data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(loginSuccess(data));
    } else {
      API.login_info()
        .then((res) => res.data)
        .then((res) => {
          dispatch(loginSuccess(res.data.profile));
        });
    }
  };
};

export const loginSuccess = (result) => {
  return {
    type: constants.LOGIN_SUCCESS,
    profile: result,
  };
};

export const loginFailed = () => ({
  type: constants.LOGIN_FAILED,
});

export const dropdownOpen = () => ({
  type: constants.DROPDOWN_OPEN,
});

// * notification
//delete notice
export const deleteNotice = (noticeId) => {
  return (dispatch) => {
    dispatch(
      actionCreatorsModal.showModal("delete", {
        open: true,
        title: "Notification",
        message: "notification",
        api: {
          name: "delete_one_notice",
          config: [noticeId],
        },
        action: [readAllNotice(), getNoticeNum()],
      }),
    );
  };
};

export const readAllNotice = () => {
  return (dispatch) => {
    API.read_all_notice()
      .then((res) => res.data)
      .then((res) => res.data.notifications)
      .then((res) => dispatch(setAllNotice(res)));
  };
};

export const setAllNotice = (data) => ({
  type: constants.SET_NOTICE_DATA,
  payload: data,
});

export const setHelpDeskMode = (name, value) => {
  return (dispatch) => {
    API.update_account_detail({ is_help_mode: value })
      .then((res) => res.data)
      .then((res) => {
        dispatch(setData(name, value));
      });
  };
};
