import React, { Component } from "react";
import { connect } from "react-redux";
import { TableStyle } from "./styled";
import TableComponent from "../table/basic_table";
import { MaterialDetails } from "../table/material_table";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readOnlyExpandKeys: [],
      expandTreeKeys: [],
    };
  }

  render() {
    const { readOnlyExpandKeys, expandTreeKeys } = this.state;
    const {
      tableContent,
      tableCaption,
      noAdditionToolbar,
      downloadFile,
      proId,
      contractId,
      hasSort,
      tableSubtitle,
      hasTableSubtitle,
      isTableSubtitleBold,
      contractInfo,
      ...otherProps
    } = this.props;

    const columns = [
      {
        title: "Ref",
        className: "item-not-show lineitem-ref",
        render: "label",
        width: "7%",
      },
      {
        type: "row-expand",
        className: "file-not-show",
        width: 0,
        onClick: (d, isExpand) => {
          if (isExpand) {
            this.setState({
              readOnlyExpandKeys: [d.id],
            });
          } else {
            this.setState({
              readOnlyExpandKeys: [],
            });
          }
        },
        render: (d) => {
          return () => {
            return (
              <div className="inputWrapper">
                <MaterialDetails
                  data={d}
                  downloadFile={downloadFile}
                  proId={proId}
                  contractId={contractId}
                  readOnlyExpandKeys={readOnlyExpandKeys}
                  timeZone={contractInfo?.get("time_zone")}
                />
              </div>
            );
          };
        },
      },
      {
        title: "Description",
        className: "align_left",
        treeColumnsName: "childitems",
        treeIndent: 10,
        width: "90%",
        render: (d) => <div id={`material_name_${d.id}`}>{d.description}</div>,
      },
    ];

    return (
      <TableStyle>
        <TableComponent
          tableContent={tableContent.toJS()}
          tableCaption={tableCaption}
          tableColumns={columns}
          hasSort={hasSort === undefined ? true : hasSort}
          hasEnhancedTableToolbar={!noAdditionToolbar}
          hasEnhancedTableFooter
          rowClassName={(record, index) => {
            if (readOnlyExpandKeys[0] === record.id) {
              return "highlight";
            }
          }}
          expandKeys={readOnlyExpandKeys}
          treeExpandKeys={expandTreeKeys}
          onTreeExpand={(keys) => {
            return this.setState({ expandTreeKeys: keys });
          }}
          tableSubtitle={tableSubtitle}
          hasTableSubtitle={hasTableSubtitle}
          isTableSubtitleBold={isTableSubtitleBold}
          {...otherProps}
        >
          {this.props.children}
        </TableComponent>
      </TableStyle>
    );
  }
}

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default connect(mapStateToProps, null)(Table);
