import React, { useEffect, useState } from "react";
import moment from "moment";
import { fromJS } from "immutable";

import { RetentionWrap } from "./styled";
import { SettingWrapper } from "../../styled";

import RetentionReport from "./retentionReport";
import { BlueOutlinedBtn as Button } from "../../../../common/button";
import { currencySubunitToUnit } from "../../../../utils/currencyHelper";
import ExcludeRetentionTable from "./excludeRetentionTable";
import { ScaleItems } from "../../../project/components/retention/settings";
import { roundNumber } from "utils/numeralHelper";

const NetRetentionInfo = ({
  contractInfo,
  retentionReport,
  retention,
  downloadRetentionPdf,
  accountConfig,
  setExpandKeys,
  config,
}) => {
  const [data, setData] = useState({
    netRetention: 0,
    pcdNetValue: 0,
    dlpNetValue: 0,
    pcdDate: "",
    dlpDate: "",
    pcdPercent: "",
    dlpPercent: "",
    dlpDays: 0,
    dlpType: "",
  });
  const [retentionAccounts, setRetentionAccounts] = useState([]);
  const [openReport, setOpenReport] = useState(false);

  useEffect(() => {
    let data = {},
      latestCert = retentionReport?.get(0);
    if (latestCert) {
      data.netRetention = latestCert?.get("cert_retention") || 0;

      if (contractInfo?.get("retention")) {
        data.pcdNetValue =
          (contractInfo?.getIn(["retention", "pcd_percent"]) / 100) *
            latestCert?.get("cert_less_retention") -
            latestCert?.get("cert_pcrr") || 0;
        data.dlpNetValue =
          (contractInfo?.getIn(["retention", "dlppercent"]) / 100) *
            latestCert?.get("cert_less_retention") -
            latestCert?.get("cert_dlrr") || 0;
      }
    }

    let retention = contractInfo?.get("retention");
    data = {
      ...data,
      pcdDate: contractInfo?.get("pcd_date")
        ? moment(contractInfo?.get("pcd_date")).format("DD-MM-YYYY")
        : "",
      dlpDate: contractInfo?.get("dlp_date")
        ? moment(contractInfo?.get("dlp_date")).format("DD-MM-YYYY")
        : "",
      pcdPercent:
        typeof retention?.get("pcd_percent") === "number"
          ? roundNumber(retention?.get("pcd_percent"))
          : "",
      dlpPercent:
        typeof retention?.get("dlppercent") === "number"
          ? roundNumber(retention?.get("dlppercent"))
          : "",
      dlpDays: retention?.get("dlpdays") || "",
    };
    if (config.size !== 0 && retention?.get("release_dlp_type")) {
      let dlpConfig = config.payclaim.payclaim_retention_dlp_option || {};
      data.dlpType = dlpConfig[retention?.get("release_dlp_type")];
    }
    setData(data);
  }, [contractInfo, retentionReport, config]);

  useEffect(() => {
    let retentionAccount = [];
    if (retention) {
      retentionAccount = retention?.get("retention_account")?.toJS()
        ? retention
            ?.get("retention_account")
            .toJS()
            .map((msg, index) => ({ id: index, msg }))
        : [];
    }
    setRetentionAccounts(retentionAccount);
  }, [retention]);
  return (
    <>
      <SettingWrapper border="none" id="nav-retention">
        <Button
          title="View Report"
          className="view-button"
          onClick={() => setOpenReport(true)}
        />
        <RetentionWrap>
          <h2>Net Retention To Date</h2>
          <h2> {currencySubunitToUnit(data.netRetention)}</h2>
        </RetentionWrap>

        <RetentionWrap className="schedule-wrap">
          <div>Retention Schedule</div>
          <div>
            <ScaleItems data={contractInfo?.get("retention")} />
          </div>
        </RetentionWrap>

        <div className="setting-info retention-info">
          <RetentionWrap className="content">
            <h2>Practical Completion</h2>
            <div className="date">
              {data.pcdPercent !== "" && (
                <>
                  <span>{data?.pcdPercent}% released </span>
                  {data?.pcdDate && <span>on {data?.pcdDate}</span>}
                </>
              )}
            </div>
            <div className="grid-wrap">
              <div className="num">
                {currencySubunitToUnit(data.pcdNetValue)}
              </div>
              <div className="num">{data.pcdDate}</div>
              <label>Net Retention</label>
              <label>{data.pcdDate ? "Practical Completion Date" : ""}</label>
              <div />
            </div>
          </RetentionWrap>

          <RetentionWrap className="content">
            <h2>Defects Liability Period</h2>
            <div className="date">
              {data.dlpPercent !== "" && (
                <>
                  <span>{data?.dlpPercent}% released </span>
                  {data.dlpType ? (
                    <>
                      {data.dlpDays && <span>{data.dlpDays}</span>}{" "}
                      <span>{data.dlpType}</span>
                    </>
                  ) : null}
                </>
              )}
            </div>

            <div className="grid-wrap">
              <div className="num">
                {currencySubunitToUnit(data.dlpNetValue)}
              </div>
              <div className="num">{data.dlpDate}</div>
              <label>Net Retention</label>
              <label>{data.dlpDate ? "Defects Liability Period" : ""}</label>
              <div />
            </div>
          </RetentionWrap>
        </div>
        <ExcludeRetentionTable
          contractInfo={contractInfo}
          accountConfig={accountConfig}
          setExpandKeys={setExpandKeys}
        />
      </SettingWrapper>
      {/*contract retention report modal */}
      <RetentionReport
        open={openReport}
        handleOpen={setOpenReport}
        retentionRecord={retentionReport || fromJS([])}
        retentionAccounts={retentionAccounts}
        timezone={contractInfo.get("time_zone")}
        downloadRetentionPdf={downloadRetentionPdf}
      />
    </>
  );
};

export default NetRetentionInfo;
