import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, connect } from "react-redux";

import { fromJS } from "immutable";

import {
  Container,
  ProjectDetailWrapper,
  LinkLabel,
  AdditionalWrapper,
  CloseIcon,
} from "../../project/styled";
import API from "../../../server";

import { GreenWhiteBtn as Button } from "../../../common/button";
import Input from "../../../common/form";
import ErrorMsg from "../../../common/missingRequiredBanner";
import Address from "./address";
import { createNewUUID, setData } from "../store/actionCreators";
import { COUNTRY_AU, CONTRACT_TYPE } from "../../../utils/constants";
import { ToggleButtons, LinkSelection } from "../common/integration";

const ContractDetail = (props) => {
  const {
    defaultInfo,
    config,
    accountConfig,
    hasIntegration,
    proId,
    accountInfo,
    projectInfo,
  } = props;
  const dispatch = useDispatch();
  const { payclaim } = config;
  const [contract, setContract] = useState({
    name: "",
    description: "",
    gst: null,
    trade: "",
    contract_number: "",
    payee_ref: "",
    po_number: "",
    attach_method: "",
    street_address: "",
    country: "",
    state: "",
    postal_code: "",
    city: "",
    suburb: "",
  });
  const [contractAddress, setContractAddress] = useState({});
  const [openDes, setOpenDes] = useState(false);
  const [openAddDes, setOpenAddDes] = useState(false);
  const [tradeOptions, setTradeOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [isValidating, setIsValidating] = useState(false);
  const [newContractID, setNewContractID] = useState("");
  const [linkContractType, setLinkContractType] = useState("new");
  const [linked, setLinked] = useState("");
  const [contracts, setContracts] = useState([]);
  const [loadIntegration, setLoadIntegration] = useState(false);
  const [isCreated, setIsCreated] = useState(false);

  const errorRef = useRef();
  const nameRef = useRef();
  const streetAddressRef = useRef();
  const stateRef = useRef();

  const currentContractType = props.match.params.type;

  useEffect(() => {
    const fetchNewUUID = async () => {
      const result = await createNewUUID();
      setNewContractID(result);
    };

    fetchNewUUID();
  }, []);

  useEffect(() => {
    if (hasIntegration && linkContractType === "link") {
      setLoadIntegration(true);
      API.read_ipm_contracts(proId)
        .then((res) => res.data.data)
        .then((res) => {
          setContracts(res.data);
          setLoadIntegration(false);
        });
    }
  }, [hasIntegration, linkContractType]); // eslint-disable-line

  useEffect(() => {
    let options = [];
    payclaim &&
      payclaim.trade_options.map((ele) =>
        options.push({
          label: ele,
          value: ele,
        }),
      );
    setTradeOptions(options);
    if (defaultInfo.size !== 0) {
      let data = defaultInfo.toJS();
      setContract({
        ...contract,
        ...data,
        country: defaultInfo.country || accountInfo?.get("country"),
      });
      if (data.description) {
        setOpenDes(true);
      }
    }
    // eslint-disable-next-line
  }, [defaultInfo, payclaim, accountInfo]);

  useEffect(() => {
    if (Object.keys(contractAddress).length !== 0) {
      setContract({ ...contract, ...contractAddress });
      onValidate({ ...contract, ...contractAddress });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractAddress]);

  const onValidate = (values) => {
    const errors = {};
    // if linked, project address is inherited (which should have an address)
    let stringArr = !linked
      ? ["name", "street_address", "country"]
      : ["name", "country"];
    if (contract.country === COUNTRY_AU) {
      stringArr.push("state");
    }
    stringArr.forEach((element) => {
      if (!values[element]) {
        errors[element] = "Required";
      }
    });
    setErrors(errors);
    return errors;
  };

  const validate = async () => {
    var valid = true;
    let errors = await onValidate(contract);
    valid &= Object.keys(errors).length === 0;
    if (!valid) {
      errorRef.current &&
        errorRef.current.scrollIntoView({ behavior: "smooth" });
    }
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsValidating(true);
    if (!(await validate())) return;
    contract["id"] = linked ? linked : newContractID;
    if (hasIntegration) {
      let integration = hasIntegration;
      let type =
        integration.size > 0 ? integration?.get(0)?.get("type") : "ipm";
      contract["integration"] = linked ? type : null;

      // inherit project address
      if (linked !== "") {
        contract.street_address = projectInfo.get("street_address");
        contract.suburb = projectInfo.get("suburb");
        contract.city = projectInfo.get("city");
        contract.postal_code = projectInfo.get("postcode");
        contract.state = projectInfo.get("state");
      }
    }

    setIsCreated(true);

    return props.createNewContract(contract);
  };

  const handleChange = (e, field) => {
    let newContract = {};
    if (field === "gst" || field === "trade") {
      if (e === "Other") {
        newContract = Object.assign({}, contract, {
          tradeText: "",
          [field]: e,
        });
      } else {
        newContract = Object.assign({}, contract, { [field]: e });
      }
    } else if (field === "state") {
      newContract = Object.assign({}, contract, {
        [field]: e.value,
      });
    } else {
      newContract = Object.assign({}, contract, {
        [e.target.name]: e.target.value,
      });
    }
    isValidating && onValidate(newContract);
    return setContract(newContract);
  };

  const cancelAddDes = (descriptionType) => {
    if (descriptionType === "additional") {
      setOpenAddDes(false);
      !linked &&
        setContract({
          ...contract,
          gst: defaultInfo.get("gst"),
          contract_number: "",
          trade: "",
          payee_ref: "",
          po_number: "",
        });
      linked &&
        setContract({
          ...contract,
          gst: defaultInfo.get("gst"),
          contract_number: defaultInfo.get("contract_number") || "",
          trade: defaultInfo.get("trade") || "",
          payee_ref: defaultInfo.get("payee_ref") || "",
          po_number: defaultInfo.get("po_number") || "",
        });
    } else {
      setOpenDes(false);
      setContract({ ...contract, description: "" });
    }
  };

  const setTargetAddress = (address, insertDefaultCountry) => {
    address.postal_code = address.postcode;
    if (!insertDefaultCountry) {
      return setContractAddress(address);
    }
  };

  const switchLinkContractType = (type) => {
    setLinkContractType(type);
    setLinked("");
    setErrors({});
    if (type === "new") {
      return props.readDefault(proId);
    }
  };

  const createInputField = (label, name, css, options = {}) => (
    <Input
      label={label}
      name={name}
      className={`input-field${css}`}
      onChange={handleChange}
      error={errors?.[name]}
      value={contract[name] || ""}
      {...options}
      field={linked && defaultInfo?.get(name) ? "link-field" : options.field}
    />
  );

  const requiredFieldsAndRefs = [
    {
      field: "name",
      name: "Contract Name",
      ref: nameRef,
      missing: errors.name,
    },
    {
      field: "street_address",
      name: accountConfig.getIn(["address", "street"]),
      ref: streetAddressRef,
      missing: errors.street_address,
    },
    {
      field: "state",
      name: accountConfig.getIn(["address", "state"]),
      ref: stateRef,
      missing: errors.state,
    },
  ];

  return (
    <Fragment>
      <Container>
        <ProjectDetailWrapper
          ref={errorRef}
          style={{ margin: "56px auto" }}
          linked={linked !== ""}
        >
          <ErrorMsg fieldsAndRefs={requiredFieldsAndRefs} hasNextPage />

          {hasIntegration && currentContractType !== CONTRACT_TYPE.claim && (
            <ToggleButtons setValue={(type) => switchLinkContractType(type)} />
          )}

          {linkContractType === "link" && (
            <LinkSelection
              from="contract"
              linked={linked}
              list={fromJS(contracts || [])}
              setLinkInfo={(id, details) => {
                setLinked(id);
                dispatch(setData("defaultInfo", details));
              }}
              loading={loadIntegration}
              linkedName={defaultInfo?.get("name")}
            />
          )}

          {(linkContractType !== "link" || linked) && (
            <>
              <h3>Contract Name</h3>

              {createInputField(null, "name", "_name", {
                placeholder: "Untitled Contract",
                required: true,
                ref: nameRef,
                noRequiredStar: true,
                justifyContent: "center",
              })}

              {openDes ? (
                <div className="des-wrapper">
                  {createInputField(null, "description", "_description", {
                    placeholder: "Add description for the contract",
                  })}
                  {!(linked && defaultInfo.get("description")) && (
                    <CloseIcon
                      onClick={cancelAddDes}
                      title="remove description"
                    />
                  )}
                </div>
              ) : (
                <LinkLabel onClick={() => setOpenDes(true)}>
                  Add Description (Optional)
                </LinkLabel>
              )}
              <Address
                handleChange={handleChange}
                address={contract}
                contractInfo={defaultInfo}
                projectInfo={projectInfo}
                errors={errors}
                setTargetAddress={setTargetAddress}
                accountConfig={accountConfig}
                refs={{ streetAddressRef, stateRef }}
                linked={linked !== ""}
              />
              {openAddDes ? (
                <AdditionalWrapper>
                  <div className="flex-row">
                    <h3>Additional Details</h3>
                    <CloseIcon
                      onClick={() => cancelAddDes("additional")}
                      title="remove additional description"
                    />
                  </div>
                  <div className="flex-row">
                    <div>
                      {createInputField("Trade/Discipline", "trade", "", {
                        placeholder: "Select Trade/Discipline",
                        value: contract.trade,
                        options: tradeOptions,
                        width: 400,
                        field: "dropdown",
                        onChange: (e) => handleChange(e.value, "trade"),
                      })}
                      <div className="append-meg">
                        If base contract items are different, this will be
                        updated to Mixed Discipline.
                      </div>
                    </div>
                    {contract.trade === "Other" &&
                      createInputField(null, "tradeText", " middle", {
                        placeholder: "Type Trade/Discipline",
                      })}
                  </div>
                  <div className="flex-row">
                    {createInputField(
                      "Contract Number",
                      "contract_number",
                      " middle",
                      {
                        placeholder: "Enter number",
                      },
                    )}
                    {createInputField(
                      accountConfig?.getIn(["gst_rate", "title"]),
                      "gst",
                      " middle number",
                      {
                        placeholder:
                          accountConfig?.getIn(["gst_rate", "value"]) + "%",
                        field: "number",
                        defaultValue: accountConfig?.getIn([
                          "gst_rate",
                          "value",
                        ]),
                        decimalScale: 0,
                        onValueChange: (e) => handleChange(e.floatValue, "gst"),
                        isAllowed: (values) => {
                          const { formattedValue, floatValue } = values;
                          return formattedValue === "" || floatValue <= 100;
                        },
                      },
                    )}
                  </div>
                  <div className="flex-row">
                    {createInputField(
                      "Payee Reference",
                      "payee_ref",
                      " middle",
                      {
                        placeholder: "Internal Reference",
                      },
                    )}
                    {createInputField(
                      "Payer Reference (PO Number/Contract Reference)",
                      "payer_ref",
                      " middle",
                      {
                        placeholder: "Enter number",
                      },
                    )}
                  </div>
                </AdditionalWrapper>
              ) : (
                <LinkLabel onClick={() => setOpenAddDes(true)}>
                  Add Additional Details (Optional)
                </LinkLabel>
              )}

              <Button
                title={linked ? "Link Contract" : "Create New Contract"}
                type="submit"
                disabled={isCreated}
                onClick={handleSubmit}
              />
            </>
          )}
        </ProjectDetailWrapper>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
  hasIntegration: state.getIn(["manageAccount", "integration"]),
  accountInfo: state.getIn(["manageAccount", "accountInfo"]),
});

export default connect(mapStateToProps, null)(ContractDetail);
