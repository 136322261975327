import { httpRequest } from "../base/httpRequest";

const PROJECT_URL = `/api/group/account/accId`;
const ACCOUNT_PROJECT_URL = `/api/group/account`; // When passing AccountUUID manually

// * new project

// new project, with duplicate or clean settings
const create_one_project_group = (data, duplicate) => {
  if (duplicate) {
    return httpRequest(
      "POST",
      `${PROJECT_URL}/contract_group/${duplicate}/duplicate`,
      data,
    );
  } else {
    return httpRequest("post", `${PROJECT_URL}/contract_group`, data);
  }
};

const read_one_project_group = (proId) =>
  httpRequest("get", `${PROJECT_URL}/contract_group/${proId}`); //new project

const upload_one_project_image = (proId, type, data) =>
  httpRequest(
    "post",
    `${PROJECT_URL}/contract_group/${proId}/image?type=${type}`,
    data,
    {},
    { "Content-Type": "multipart/form-data" },
  );

const upsert_project = (proId, data) =>
  httpRequest("patch", `${PROJECT_URL}/contract_group/${proId}`, data);

const read_projects = (accountId) =>
  httpRequest(
    "get",
    accountId
      ? `/api/group/account/${accountId}/contract_group`
      : `${PROJECT_URL}/contract_group`,
  );

const create_project_retention = (proId, data) =>
  httpRequest("post", `${PROJECT_URL}/contract_group/${proId}/retention`, data);

const update_project_retention = (proId, retentionId, data) =>
  httpRequest(
    "put",
    `${PROJECT_URL}/contract_group/${proId}/retention/${retentionId}`,
    data,
  );

const remove_project_retention = (proId, retentionId) =>
  httpRequest(
    "delete",
    `${PROJECT_URL}/contract_group/${proId}/retention/${retentionId}`,
  );

const read_project_member = (proId) =>
  httpRequest("get", `${PROJECT_URL}/contract_group/${proId}/members`); //group_members

const update_project_member = (proId, data) =>
  httpRequest("post", `${PROJECT_URL}/contract_group/${proId}/members`, data);

const read_project_workflow = (proId, type) =>
  httpRequest(
    "get",
    `${PROJECT_URL}/contract_group/${proId}/${type}/workflows`,
  );

const update_project_workflow = (proId, type, data) =>
  httpRequest(
    "POST",
    `${PROJECT_URL}/contract_group/${proId}/${type}/workflows`,
    data,
  );

const calculate_due_dates = (proId, data, periodEnd = "") =>
  httpRequest(
    "POST",
    `${PROJECT_URL}/contract_group/${proId}/calculate_dates?periodEnding=${periodEnd}`,
    data,
  );

// state changes
const archive_project = (proId) =>
  httpRequest("PUT", `${PROJECT_URL}/contract_group/${proId}/archive`);
const delete_project = (proId) =>
  httpRequest("PUT", `${PROJECT_URL}/contract_group/${proId}/delete`);
const reopen_project = (proId) =>
  httpRequest("PUT", `${PROJECT_URL}/contract_group/${proId}/reopen`);

const read_project_role = (proId) =>
  httpRequest("GET", `${PROJECT_URL}/contract_group/${proId}/role`);

const read_account_project_role = (accId, proId) =>
  httpRequest(
    "GET",
    `${ACCOUNT_PROJECT_URL}/${accId}/contract_group/${proId}/role`,
  ); // When passing AccountUUID manually

const update_project_group_pin = (proId, data) =>
  httpRequest("put", `${PROJECT_URL}/contract_group/${proId}/pin`, data);

//download project summary csv report
const download_project_csv_summary = (proId, config) =>
  httpRequest(
    "GET",
    `${PROJECT_URL}/contract_group/${proId}/report`,
    null,
    config,
  );

//download projects summary csv report
const download_projects_csv_summary = (config) =>
  httpRequest("GET", `${PROJECT_URL}/contract_group/report`, null, config);

export default {
  download_projects_csv_summary,
  download_project_csv_summary,
  create_one_project_group,
  read_one_project_group,
  upload_one_project_image,
  upsert_project,
  read_projects,
  create_project_retention,
  update_project_retention,
  remove_project_retention,
  read_project_member,
  update_project_member,
  read_project_workflow,
  update_project_workflow,
  calculate_due_dates,
  archive_project,
  delete_project,
  reopen_project,
  read_project_role,
  read_account_project_role,
  update_project_group_pin,
};
