import React, { useState } from "react";
import debounce from "lodash/debounce";
import {
  MoreOptions,
  SummaryContainer,
  ProjectsWrapper,
  SearchContainer,
  SortContainer,
  LayoutWrapper,
  ActionContainer,
  ActionsWrap,
} from "../styled";
import Dropdown from "common/dropdown/dropdown_with_label";
import Filter from "../../../common/filter";
import { Img } from "../../../common/button/style";
import downloadIcon from "../../../statics/img/download-arrow.svg";
import { ClickAwayListener } from "@material-ui/core";

function ActionBar(props) {
  const { accountConfig } = props;
  const [edit, setEdit] = useState(false);
  const sortOption = props.sortOption || [
    { label: "Project Name", value: "name" },
    { label: "Project Total", value: "total_contract_value" },
    { label: "Contract Number", value: "contract_number" },
    {
      label: `${accountConfig?.getIn(["claim", "value"])} to Date`,
      value: "claimed_to_date_value",
    },
    {
      label: `${accountConfig?.getIn(["cert", "value"])} to Date`,
      value: "cert_to_date_value",
    },
    { label: "Date Created", value: "created_date" },
  ];
  const valueFromId = (opts, id) => opts.find((o) => o.value === id);
  const {
    layout,
    handleChangeLayout,
    sortListField,
    handleSortList,
    handleSearch,
    downloadProjectsSummary,
    from,
    searchPlaceHolder,
  } = props;
  const debounceChange = debounce((value) => handleSearch(value), 400);
  return (
    <ProjectsWrapper>
      <ActionContainer>
        <div>
          <SearchContainer isContractList={from === "project"}>
            <div className="img-search" />
            <input
              onChange={(e) => debounceChange(e.target.value)}
              placeholder={searchPlaceHolder}
            />
          </SearchContainer>
          {layout !== "table" && (
            <SortContainer>
              <div className="sort-label">Sort by:</div>
              <Dropdown
                value={valueFromId(sortOption, sortListField)}
                onChange={(e) => handleSortList(e.value)}
                options={sortOption}
              />
            </SortContainer>
          )}
          <Filter {...props} />
          {from === "projects" ? (
            <ClickAwayListener
              onClickAway={() => {
                setEdit(false);
              }}
            >
              <SummaryContainer>
                <MoreOptions
                  onClick={() => {
                    setEdit(!edit);
                  }}
                />
                <ActionsWrap show={edit}>
                  <div
                    className="action"
                    onClick={() => downloadProjectsSummary()}
                  >
                    <Img
                      name={downloadIcon}
                      margin="0 10px 0 0"
                      float="left"
                      height="17px"
                      width="14px"
                    />
                    Export {accountConfig?.getIn(["claim", "noun"])}s Summary
                  </div>
                </ActionsWrap>
              </SummaryContainer>
            </ClickAwayListener>
          ) : (
            <></>
          )}
        </div>
        <LayoutWrapper layout={layout}>
          <div
            className="option-wrap list"
            onClick={() => handleChangeLayout("list")}
          >
            <div className="icon-list" />
          </div>
          <div
            className="option-wrap table"
            onClick={() => handleChangeLayout("table")}
          >
            <div className="icon-table" />
          </div>
        </LayoutWrapper>
      </ActionContainer>
    </ProjectsWrapper>
  );
}

export default ActionBar;
