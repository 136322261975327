import React from "react";
import { ReviewWrapper } from "../../styled";
import ClaimTable from "../../../../common/table/review_claim_table";
import ReviewClaimMaterials from "../../../../common/table/review_claim_material_table";

export default function claimReviewTable(props) {
  const { isMaterial } = props;

  return (
    <>
      <ReviewWrapper className="wide_container">
        {isMaterial ? (
          <>
            <ReviewClaimMaterials {...props} />
          </>
        ) : (
          <ClaimTable {...props} />
        )}
      </ReviewWrapper>
    </>
  );
}
