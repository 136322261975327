import React, { Component } from "react";
import Select from "react-select";
import DropdownIndicator from "common/dropdown/component/indicator";
import * as mainTheme from "../../../theme";
import { Label } from "./style";
import { overrideColor, valueFromId } from "common/dropdown/component/helper";

export default class RenderSelectInput extends Component {
  onChange(event) {
    if (this.props.input.onChange && event != null) {
      this.props.input.onChange(event.value);
    } else {
      this.props.input.onChange(null);
    }
  }

  render() {
    const {
      input,
      options,
      name,
      id,
      width,
      placeholder,
      label,
      meta: { touched, error },
      missingRequired,
      ...custom
    } = this.props;
    const selectInputStyle = {
      control: (styles) => ({
        ...styles,
        width: width,
        height: "44px",
        borderColor:
          (touched && error) || missingRequired
            ? mainTheme.palette.watermelonRed
            : mainTheme.palette.wolfGrey,
        borderRadius: 4,
        fontSize: "16px",
        paddingLeft: "10px",
      }),
      option: (provided, state) => ({
        ...provided,
        width: width,
        height: "44px",
        fontSize: "16px",
        paddingLeft: "10px",
        fontWeight: 400,
        color: mainTheme.palette.gunmetal,
        "&:hover": {
          backgroundColor: mainTheme.palette.warmLightGrey,
          cursor: "pointer",
        },
      }),
      indicatorSeparator: (styles) => ({
        ...styles,
        display: "none",
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        padding: "14px",
      }),
      menu: (styles) => ({
        ...styles,
        width: width,
        color: mainTheme.palette.gunmetal,
        fontFamily: mainTheme.font.mainFont,
      }),
      singleValue: (styles) => ({
        ...styles,
        color: mainTheme.palette.gunmetal,
        fontWeight: 400,
        fontFamily: mainTheme.font.mainFont,
        lineHeight: "initial",
      }),
      placeholder: (styles) => ({
        ...styles,
        color: mainTheme.palette.wolfGrey,
      }),
    };
    return (
      <div>
        <Label>{label}</Label>
        <Select
          {...input}
          {...custom}
          id={id}
          name={name}
          options={options}
          value={valueFromId(options, this.props.input.value) || ""}
          onBlur={() => this.props.input.onBlur(this.props.input.value)}
          onChange={this.onChange.bind(this)}
          components={{ DropdownIndicator }}
          styles={selectInputStyle}
          placeholder={placeholder}
          autocomplete="off"
          isSearchable={false}
          theme={(theme) => overrideColor(theme)}
        />
      </div>
    );
  }
}
