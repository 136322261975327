import React, { Component } from "react";

import { TableFooter, TableStyle } from "./styled";

import InputTable from "./baseContractInputTable";
import { roundNearest } from "../../utils/numeralHelper";
import { currencySubunitToUnit } from "../../utils/currencyHelper";
import { dropDecimalColumns, dropDecimal } from "../../utils/tableFormatHelper";
import { checkIsParent } from "../../utils/helper";
import TableComponent from "../table/basic_table";
import { skeletonCol, skeletonData } from "../skeleton/component/table";
import { BaseDetails } from "../table/shared_component";
import Skeleton from "../skeleton";
import { AddBtn as AddButton } from "../button";
import { LUMP, STATUS_DRAFT } from "../../utils/constants";
import {
  getExpandProvisionalParents,
  getAllChildKeys,
  getLastChildParentKeys,
  ProvisionalDescription,
} from "./sharedComponent";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandKeys: [], //control edit or add lineitem key
      expandTreeKeys: [], //control tree expand keys
      expandEdit: false, //add or edit action
      expandChildKeys: [], //the expand item's all children key
      parentId: "", // the root expand lineitem
      lastChildKeys: [], //th elast tree keys of the expand item
      expandAttach: false,
      addingItem: false,
      previousLineType: "rate",
    };
  }
  handleCloseEdit = () => {
    return this.setState({
      expandKeys: [],
      parentId: "",
      expandChildKeys: [],
      lastChildKeys: [],
    });
  };
  handleCleanItem = (id) => {
    this.props.deleteItem(id);
    return this.setState({
      expandKeys: [],
      parentId: "",
      expandChildKeys: [],
      lastChildKeys: [],
    });
  };

  handleSaveEdit = (id, lineType, editInfo) => {
    let edited = {};
    // only copy objects with keys specified in edit fields or else saving will cause server errors
    const editFields = [
      "label",
      "description",
      "unit",
      "quantity",
      "rate",
      "total",
      "detailed_description",
      "is_provisional",
      "exclude_retention",
    ];
    for (let [key, value] of Object.entries(editInfo)) {
      if (editFields.includes(key)) {
        edited[key] = value;
      }
    }
    edited.lineitem_options = lineType;

    this.props.handleSaveEdit(edited, id);
    return this.setState({
      expandKeys: [],
      parentId: "",
      expandChildKeys: [],
      lastChildKeys: [],
    });
  };

  setInitialValue = (table) => {
    this.props.setEditFiles(table.uploaded_files);
  };

  checkGreyDash = (line) => {
    return line.lineitem_options === LUMP || line.childitems?.length > 0;
  };

  isTreeExpanded = (id) => {
    return this.state.expandTreeKeys.includes(id);
  };

  // check if line item is being edited
  isUpdateExpanded = (id) => {
    return this.state.expandKeys.includes(id);
  };

  expandProvisionalParent = (line) => {
    let expanded = getExpandProvisionalParents(line, this.state.expandTreeKeys);
    this.setState({ expandTreeKeys: expanded });
  };

  render() {
    const {
      tableContent,
      UIpage,
      handleFileCancel,
      handleFilesChange,
      files,
      editFileList,
      handleUploadFile,
      handleFileDelete,
      setFileList,
      tableCaption,
      tableSubtitle,
      loading,
      proId,
      contractId,
      downloadFile,
      baseContractTotal,
      addChildRow,
      addRow,
      accountConfig,
      setEditFiles,
      contractInfo,
      headersConfig,
    } = this.props;

    const maxDepth = headersConfig.payclaim?.max_lineitem_child_level || 0;
    let columnDecimal = dropDecimalColumns(tableContent, ["rate", "total"]);
    const {
      expandKeys,
      expandTreeKeys,
      parentId,
      expandChildKeys,
      lastChildKeys,
      expandEdit,
      addingItem,
      previousLineType,
    } = this.state;
    const Actions = <span className="actionHeader">Action</span>;
    const columns = [
      {
        title: "Ref",
        className: "align_left lineitem-ref",
        render: "label",
        width: "8%",
      },
      {
        title: "Description",
        className: "align_left",
        treeColumnsName: "childitems",
        treeIndent: 10,
        width: "31%",
        render: (d) => (
          <div>
            <ProvisionalDescription
              data={d}
              isUpdateExpanded={this.isUpdateExpanded}
              isTreeExpanded={this.isTreeExpanded}
              expandProvisionalParent={this.expandProvisionalParent}
            />
          </div>
        ),
      },
      {
        title: "Qty",
        width: "14%",
        // convert number from cent to dollar
        // if the number is bigger than 100000000, we will round number, drop decimal to display.
        // Display gray dash for qty and rate if the lineitem is a parent, a lumpsum type or the value is zero
        render: (d) => {
          let qty =
            this.checkGreyDash(d) || d.quantity === 0 ? (
              <div className="grey-dash" />
            ) : (
              currencySubunitToUnit(
                dropDecimal(d.quantity) ? roundNearest(d.quantity) : d.quantity,
                false,
              )
            );
          return qty;
        },
      },
      {
        title: "Unit",
        width: "9%",
        render: (d) => {
          // Display gray dash if the lineitem is a parent or lumpsum type
          return this.checkGreyDash(d) || !d.unit ? (
            <div className="grey-dash" />
          ) : (
            d.unit
          );
        },
      },
      {
        title:
          "Rate" +
          (accountConfig?.get("enable_header_currency")
            ? ` (${accountConfig?.getIn(["currency", "code"])})`
            : ""),
        width: "15%",
        render: (d) => {
          let rate =
            this.checkGreyDash(d) || d.rate === 0 ? (
              <div className="grey-dash" />
            ) : (
              currencySubunitToUnit(
                columnDecimal["rate"] ? roundNearest(d.rate) : d.rate,
                !accountConfig?.get("enable_header_currency"),
                false,
                false,
              )
            );
          return rate;
        },
      },
      {
        title:
          "Total" +
          (accountConfig?.get("enable_header_currency")
            ? ` (${accountConfig?.getIn(["currency", "code"])})`
            : ""),
        width: "15%",
        render: (d) =>
          currencySubunitToUnit(
            columnDecimal["total"] ? roundNearest(d.total) : d.total,
            !accountConfig?.get("enable_header_currency"),
            false,
            false,
          ),
      },
      {
        type: "expand",
        className: "edit hidden",
        width: "4%",
        onClick: (d, isExpand) => {
          this.setState({
            parentId: d.id,
            expandKeys: [d.id],
            expandEdit: true,
            expandChildKeys: [],
            lastChildKeys: [],
            expandAttach: false,
          });
          this.setInitialValue(d);
          this.setState({ addingItem: false });
        },
        render: (d) => {
          if (this.state.expandAttach) {
            return () => {
              return (
                <BaseDetails
                  data={d}
                  downloadFile={downloadFile}
                  proId={proId}
                  contractId={contractId}
                  readOnlyExpandKeys={expandKeys}
                  handleClose={() => this.handleCloseEdit()}
                  timeZone={contractInfo?.get("time_zone")}
                />
              );
            };
          }
          if (this.state.expandEdit) {
            return () => (
              //edit item
              <div className="inputWrapper">
                <InputTable
                  id={d.id}
                  title={"Edit Item : " + d.description}
                  handleClose={() => this.handleCloseEdit()}
                  handleSaveItem={(lineType, editInfo) =>
                    this.handleSaveEdit(d.id, lineType, editInfo)
                  }
                  handleCleanItem={() => this.handleCleanItem(d.id)}
                  tableList={d}
                  files={editFileList} //file list
                  handleFilesChange={(files, uploadingFileField) =>
                    handleUploadFile(d.id, files, uploadingFileField)
                  } //upload file
                  handleFileCancel={(fileId) => handleFileDelete(d.id, fileId)}
                  status={UIpage}
                  action="save"
                  setFileList={(files) => setFileList(files)}
                  //prevent item from editing quantity/rate if item is parent or certified
                  isBlocked={
                    (d.approved_up_to_date_total !== 0 || checkIsParent(d)) &&
                    !d.is_provisional
                  }
                  canDelete={d.approved_up_to_date_total === 0}
                  accountConfig={accountConfig}
                  isDraft={contractInfo.get("status") === STATUS_DRAFT}
                  allowExcludeRetention={
                    !checkIsParent(d) && contractInfo.get("retention")
                  }
                />
              </div>
            );
          } else {
            return () => (
              <div className="inputWrapper">
                <InputTable
                  id={parentId ? parentId : d.id}
                  title="Add Child Item"
                  handleClose={() => this.handleCloseEdit()}
                  handleSaveItem={(lineType, newRow) => {
                    this.handleCloseEdit();
                    this.setState({ previousLineType: lineType });
                    addChildRow(parentId ? parentId : d.id, lineType, newRow);
                  }}
                  files={files} // file list
                  handleFileCancel={handleFileCancel}
                  handleFilesChange={handleFilesChange}
                  status={UIpage}
                  action="add"
                  previousLineType={previousLineType}
                  accountConfig={accountConfig}
                  setEditFiles={setEditFiles}
                  isDraft={contractInfo.get("status") === STATUS_DRAFT}
                  allowExcludeRetention={
                    !checkIsParent(d) && contractInfo.get("retention")
                  }
                />
              </div>
            );
          }
        },
        group: Actions,
      },
      {
        type: "expand",
        width: "4%",
        className: "add hidden",
        onClick: (d, isExpand) => {
          if (checkIsParent(d)) {
            let childKeys = [],
              lastChildKeys = [];
            childKeys = getAllChildKeys(d.childitems, childKeys);
            childKeys.push(d.id);
            lastChildKeys = getLastChildParentKeys(d, []);
            this.setState({
              expandChildKeys: childKeys,
              expandTreeKeys: expandTreeKeys.concat(childKeys),
              parentId: d.id,
              expandKeys: [childKeys[childKeys.length - 2]],
              expandEdit: false,
              lastChildKeys: lastChildKeys,
              expandAttach: false,
            });
          } else {
            this.setState({
              expandChildKeys: [],
              parentId: d.id,
              expandKeys: [d.id],
              expandEdit: false,
              lastChildKeys: [],
              expandAttach: false,
            });
          }
          this.setState({ addingItem: false });
        },
        render: (d) => {
          if (
            (d.approved_up_to_date_total !== 0 && !checkIsParent(d)) ||
            d.depth >= maxDepth
          ) {
            return undefined;
          }
          return () => <div />;
        },
        group: Actions,
      },
      {
        type: "expand",
        className: "file file-not-show",
        group: Actions,
        width: "2%",
        onClick: (d, isExpand) => {
          if (d.uploaded_files) {
            this.setState({
              expandChildKeys: [],
              parentId: d.id,
              expandKeys: [d.id],
              expandEdit: false,
              lastChildKeys: [],
              expandAttach: true,
            });
          } else {
            this.setState({
              expandChildKeys: [],
              parentId: "",
              expandKeys: [],
              expandEdit: false,
              lastChildKeys: [],
              expandAttach: false,
            });
          }
        },
        render: (d) => {
          if (!d.uploaded_files) {
            return undefined;
          }
          return () => <div />;
        },
      },
    ];

    return (
      <TableStyle>
        <TableComponent
          tableContent={loading ? skeletonData : tableContent}
          tableCaption={tableCaption}
          tableColumns={loading ? skeletonCol : columns}
          hasSort
          expandKeys={expandKeys}
          rowClassName={(record, index) => {
            if (record.id === parentId) {
              return "highlight";
            }
            if (expandChildKeys.indexOf(record.id) !== -1) {
              return "border-highlight";
            }
          }}
          onTreeExpand={(keys) => {
            if (!expandEdit) {
              if (lastChildKeys.length !== 0) {
                let res = lastChildKeys.filter((x) => !keys.includes(x));
                if (res.length !== 0) {
                  this.setState({
                    expandKeys: [res[0]],
                  });
                } else {
                  this.setState({
                    expandKeys: [expandChildKeys[expandChildKeys.length - 2]],
                  });
                }
              }
            }
            return this.setState({ expandTreeKeys: keys });
          }}
          treeExpandKeys={expandTreeKeys}
          hasEnhancedTableToolbar
          hasTableSubtitle={tableSubtitle ? true : false}
          tableSubtitle={tableSubtitle}
        >
          {this.props.children}
        </TableComponent>

        {addingItem && (
          <InputTable
            type="parentItem"
            title="Add Item"
            handleClose={() => this.setState({ addingItem: false })}
            handleSaveItem={(lineType, newRow) => {
              addRow(lineType, newRow);
              this.setState({ previousLineType: lineType, addingItem: false });
            }}
            previousLineType={previousLineType}
            action="add"
            handleFilesChange={handleFilesChange}
            files={files}
            handleFileCancel={handleFileCancel}
            accountConfig={accountConfig}
            setEditFiles={setEditFiles}
            isDraft={contractInfo.get("status") === STATUS_DRAFT}
            allowExcludeRetention={contractInfo.get("retention")}
          />
        )}
        <TableFooter>
          {loading ? (
            <Skeleton.TextLine />
          ) : (
            <AddButton
              onClick={() => this.setState({ addingItem: true })}
              disabled={addingItem}
              title="ADD ITEM"
              float="none"
            />
          )}
          <div className="totalDiv">
            <div className="blueBox">
              <div className="titleLeft">Total Base Contract</div>
              <div className="titleRight">
                {loading ? (
                  <Skeleton.TextLine theme={"secondary"} />
                ) : (
                  currencySubunitToUnit(
                    dropDecimal(baseContractTotal)
                      ? roundNearest(baseContractTotal)
                      : baseContractTotal,
                    true,
                    dropDecimal(baseContractTotal),
                  )
                )}
              </div>
            </div>
            {dropDecimal(baseContractTotal) && (
              <div className="notice">
                Note: Values have been rounded for display purposes. All
                calculations use unrounded values.
              </div>
            )}
          </div>
        </TableFooter>
      </TableStyle>
    );
  }

  componentDidUpdate(_, prevState) {
    if (
      this.state.addingItem !== prevState.addingItem &&
      this.state.addingItem
    ) {
      this.handleCloseEdit();
    }
  }
}
export default Table;
