import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Menu from "@material-ui/core/Menu";
import Badge from "@material-ui/core/Badge";

import { FilterContainer, FilterIcon, FormStyle } from "./styled";

function checkFilters(filters) {
  //filters data look like {object:{object:value},...}
  for (const key in filters) {
    for (const childKey in filters[key]) {
      if (filters[key][childKey]) {
        return true;
      }
    }
  }
  return false;
}

export default function Filter(props) {
  const [newState, setNewState] = useState({});
  const [refreshKey, setRefreshKey] = useState(1);
  const { filterOptions, handleFilter, allProjects, hasReset } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  let numFilterTypes = Object.keys(newState).length;

  const processFilterOptions = (objectList) => {
    let objectOptions = {};
    if (objectList) {
      for (const key in objectList) {
        let options = {};
        for (var index in objectList[key]) {
          let hasDefault = Object.prototype.hasOwnProperty.call(
            objectList[key][index],
            "default",
          );
          if (hasDefault) {
            options[objectList[key][index]?.value] =
              objectList[key][index].default;
          } else {
            options[objectList[key][index]?.value] = false;
          }
        }
        objectOptions[key] = options;
      }
    }
    return objectOptions;
  };
  const objectList = processFilterOptions(filterOptions);

  useEffect(() => {
    setNewState(objectList);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleFilter({ ...objectList });
    // eslint-disable-next-line
  }, [allProjects]);

  const handleChange = (name) => (event) => {
    for (const key in newState) {
      if (typeof newState[key][name] !== "undefined") {
        newState[key][name] = event.target.checked;
        break;
      }
    }
    setNewState(newState);
    setRefreshKey(refreshKey + 1);
  };

  const clear = () => {
    for (const key in newState) {
      for (const childKey in newState[key]) {
        newState[key][childKey] = false;
      }
    }
    setNewState(newState);
    setRefreshKey(refreshKey + 1);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleFilter({ ...newState });
  };

  const getOptionLabel = (stateLabel, option) => {
    let label = option.replaceAll("_", " ");
    for (let index = 0; index < filterOptions[stateLabel].length; index++) {
      if (
        typeof filterOptions[stateLabel][index] === "object" &&
        filterOptions[stateLabel][index]?.value === option
      ) {
        label = filterOptions[stateLabel][index]?.label;
        break;
      }
    }
    return label;
  };

  const getOptionValue = (option) => {
    if (typeof option === "object") {
      return option?.value;
    }
    return option;
  };

  return (
    <FilterContainer>
      <Badge
        color="secondary"
        overlap="rectangular"
        variant="dot"
        invisible={!checkFilters(newState)}
      >
        <FilterIcon onClick={handleClick} />
      </Badge>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <FormStyle key={refreshKey}>
          {Object.keys(newState).map((stateLabel, i) => (
            <div
              className={
                "filter-container" + (i < numFilterTypes - 1 ? " border" : "")
              }
              key={stateLabel}
            >
              <div className="title">{stateLabel}</div>
              <FormGroup>
                {Object.keys(newState[stateLabel]).map((option) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newState[stateLabel][option]}
                        onChange={handleChange(getOptionValue(option))}
                        color="default"
                      />
                    }
                    label={getOptionLabel(stateLabel, option)}
                    key={option}
                  />
                ))}
              </FormGroup>
            </div>
          ))}
          {hasReset && (
            <div className="reset" onClick={clear}>
              Reset
            </div>
          )}
        </FormStyle>
      </Menu>
    </FilterContainer>
  );
}
