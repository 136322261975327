import React, { Fragment, Component } from "react";
import {
  SortInput,
  TopicItem,
  SearchInput,
  BottomPagination,
  EmptyState,
} from "../style";
import PageBarPart from "../../../common/pagination";
import Search from "./select";
import InputSelect from "common/dropdown/dropdown_with_label";
import ContactList from "./contactList";

class List extends Component {
  constructor() {
    super();
    this.handleSetDataNum = this.handleSetDataNum.bind(this);
    this.state = {
      currentPage: 1,
      limit: 50,
    };
  }
  handlePageChange = (page) => {
    this.setState({
      currentPage: page,
    });
  };
  selectContact = (name, e) => {
    this.handlePageChange(1);
    this.props.selectContact(name, e);
  };
  handleSetDataNum(data) {
    this.handlePageChange(1);
    return this.setState({
      limit: data,
    });
  }

  render() {
    const {
      list,
      handleDeleteContact,
      allContact,
      selectedContactId,
      selectedSortOption,
      handleSelectedOption,
      role,
    } = this.props;
    const { currentPage, limit } = this.state;
    const total = list.size;
    let options = [];
    allContact.map((item) => {
      let a = {
        value: item.get("contact_id"),
        label: item.get("contact_name") + " (" + item.get("entity_name") + ")",
      };
      if (a.value) {
        options = options.concat(a);
        options = options.filter((a) => a.value !== item.get("entity_id"));
      }
      let gstNum = item.get("gst_no") ? ` (#${item.get("gst_no")})` : "";
      let b = {
        value: item.get("entity_id"),
        label: item.get("entity_name") + gstNum,
      };
      options = options.concat(b);
      return options;
    });
    const valueFromId = (opts, id) => opts.find((o) => o.value === id);
    const sortOptions = [
      {
        label: "Company Name",
        value: "company.entity_name",
      },
      {
        label: "Person Name",
        value: "contact_name",
      },
      {
        label: "Recently Added",
        value: "registed_time",
      },
    ];
    return (
      <Fragment>
        <TopicItem>
          <SearchInput>
            <Search
              placeholder="Search for a contact by name or company"
              options={options}
              value={selectedContactId}
              onChange={(e) => this.selectContact("selectedContactId", e)}
            />
          </SearchInput>
          <SortInput>
            <div className="sortTxt">Sort by:</div>
            <div className="inputSelect">
              <InputSelect
                value={valueFromId(sortOptions, selectedSortOption)}
                name="selectedSortOption"
                onChange={handleSelectedOption}
                options={sortOptions}
              />
            </div>
          </SortInput>
        </TopicItem>
        {list.size === 0 ? (
          <EmptyState>No contacts found</EmptyState>
        ) : (
          <>
            <ContactList
              handleDeleteContact={(contact) =>
                handleDeleteContact(
                  contact.get("contact_id"),
                  contact.get("entity_id"),
                )
              }
              list={list}
              limit={limit}
              currentPage={currentPage}
              selectedSortOption={selectedSortOption}
              role={role}
            />
            <BottomPagination>
              <PageBarPart
                total={total}
                limit={limit}
                pageCount={4}
                currentPage={currentPage}
                handlePageChange={this.handlePageChange}
                handleSetData={this.handleSetDataNum}
                field="account"
              />
            </BottomPagination>
          </>
        )}
      </Fragment>
    );
  }
}

export default List;
