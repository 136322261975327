import React, { useEffect, useState, Fragment } from "react";
import { fromJS } from "immutable";
import debounce from "lodash/debounce";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import {
  SearchContainer,
  DropdownWrapper,
  BottomPagination,
  TopicItem,
  EmptyState,
  TableHeader,
  ListItemWrapper,
} from "../style";
import PageBarPart from "../../../../../common/pagination";
import Filter from "../../../../../common/filter";
import UserItem from "./userItem";
import {
  ADMIN_ROLE,
  VIEW_ROLE,
  OWNER_ROLE,
  NONE_ROLE,
} from "../../../../../utils/constants";

function compareByOption(field, sep) {
  let reverse = sep === "asc" ? 1 : -1;
  if (["projectNum", "contractNum"].includes(field)) {
    return function (a, b) {
      var result = a - b > 0 ? 1 : b - a > 0 ? -1 : 0;
      return result * reverse;
    };
  }
  return function (a, b) {
    if (a && b) {
      var result =
        a.toString().toLowerCase().trim() > b.toString().toLowerCase().trim()
          ? 1
          : b.toString().toLowerCase().trim() >
              a.toString().toLowerCase().trim()
            ? -1
            : 0;
      return result * reverse;
    } else {
      return false;
    }
  };
}

const DefaultSortOption = {
  options: {
    name: "Name",
    added_date: "Date Added",
    projectNum: "Number of Explicit Projects",
    contractNum: "Number of Explicit Contracts",
  },
  sep: {
    asc: "Ascending",
    desc: "Descending",
  },
};

const FilterOption = {
  "User Permission": [
    { label: "Hide Viewers", value: VIEW_ROLE },
    { label: "Hide Admins", value: ADMIN_ROLE },
    { label: "Hide Owner", value: OWNER_ROLE },
    { label: "Hide None", value: NONE_ROLE },
  ],
  "User Status": [
    { label: "Hide Active", value: "active" },
    { label: "Show Inactive", value: "inactive" },
  ],
};

const Sort = ({
  open,
  openDropdown,
  setSortField,
  setSortSep,
  sortField,
  sortSep,
  sortOptions,
}) => {
  return (
    <DropdownWrapper active={open}>
      <div className="dropdown-title" onClick={openDropdown}>
        <label>Sort By:</label>
        <div>{sortOptions["options"][sortField]}</div>
        <div className="icon" />
      </div>

      {open && (
        <div className="dropdown-list">
          <ul>
            {Object.keys(sortOptions["options"]).map((item) => (
              <li key={item}>
                <input
                  type="radio"
                  id={item}
                  name="sortField"
                  value={item}
                  checked={sortField === item}
                  onChange={setSortField}
                />
                <label htmlFor={item}>{sortOptions["options"][item]}</label>
              </li>
            ))}
          </ul>
          <hr />
          <ul>
            {Object.keys(sortOptions["sep"]).map((item) => (
              <li key={item}>
                <input
                  type="radio"
                  id={item}
                  name="sortSep"
                  value={item}
                  checked={sortSep === item}
                  onChange={setSortSep}
                />
                <label htmlFor={item}>{sortOptions["sep"][item]}</label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </DropdownWrapper>
  );
};

export default function User(props) {
  const { allUsers } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [openSortList, setOpenSortList] = useState(false);
  const [sortField, setSortField] = useState("name");
  const [sortSep, setSortSep] = useState("asc");
  const [users, setUsers] = useState(fromJS([]));
  const [searchInput, setSearchInput] = useState("");
  const [filter, setFilter] = useState({
    hideRole: [],
    hideStatus: ["inactive"],
  });

  const debounceChange = debounce((value) => setSearchInput(value), 400);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSetDataNum = (data) => {
    setCurrentPage(1);
    setLimit(data);
  };

  const handleFilter = (filters) => {
    let hideRole = [],
      hideStatus = ["inactive"];
    for (const [filter, value] of Object.entries(filters["User Permission"])) {
      if (value) {
        hideRole.push(filter);
      }
    }
    for (const [filter, value] of Object.entries(filters["User Status"])) {
      if (filter === "active" && value) hideStatus.push(filter);
      if (filter === "inactive" && value) {
        let index = hideStatus.indexOf("inactive");
        if (index !== -1) {
          hideStatus.splice(index, 1);
        }
      }
    }
    setFilter({
      hideRole,
      hideStatus,
    });
  };

  const sortUser = (data) => {
    let sortedUser = data.sortBy(
      (val) => {
        return val.get(sortField);
      },
      compareByOption(sortField, sortSep),
    );
    return sortedUser;
  };

  const searchUser = (data, searchString) => {
    let searched = data.filter((user) =>
      user.get("name").toLowerCase().includes(searchString.toLowerCase()),
    );
    return searched;
  };

  const filterUser = (data, filter) => {
    let filterData = data.filter((user) => {
      return (
        !filter.hideRole.includes(user.get("role_id")) &&
        !filter.hideStatus.includes(user.get("status"))
      );
    });
    return filterData;
  };

  useEffect(() => {
    if (allUsers.size > 0) {
      let users = allUsers;
      if (searchInput) {
        users = searchUser(allUsers, searchInput);
      }
      users = filterUser(users, filter);
      users = sortUser(users);
      setUsers(users);
    }
    // eslint-disable-next-line
  }, [allUsers, sortField, sortSep, searchInput, filter]);

  return (
    <Fragment>
      <TopicItem>
        <SearchContainer>
          <div className="img-search" />
          <input
            onChange={(e) => debounceChange(e.target.value)}
            placeholder={"Search for a user by name..."}
          />
        </SearchContainer>
        <div>
          <ClickAwayListener onClickAway={() => setOpenSortList(false)}>
            <div style={{ float: "left" }}>
              <Sort
                open={openSortList}
                openDropdown={() => setOpenSortList(!openSortList)}
                setSortField={(e) => setSortField(e.target.value)}
                setSortSep={(e) => setSortSep(e.target.value)}
                sortField={sortField}
                sortSep={sortSep}
                sortOptions={DefaultSortOption}
              />
            </div>
          </ClickAwayListener>

          <Filter
            filterOptions={FilterOption}
            handleFilter={(value) => {
              handleFilter(value);
            }}
          />
        </div>
      </TopicItem>
      {users.size === 0 ? (
        <EmptyState>No users found</EmptyState>
      ) : (
        <>
          <div>
            <TableHeader>
              <>
                <div className="firstItem">Name</div>
                <div>Account Access</div>
                <div>Explicit Access</div>
                <div>Contact</div>
                <div className="align_middle">Status</div>
              </>
            </TableHeader>
            <ListItemWrapper>
              {users.map((item, index) => {
                let page = currentPage - 1;
                if (index >= page * limit && index < (page + 1) * limit) {
                  return (
                    <UserItem key={item.get("id")} item={item} {...props} />
                  );
                } else return "";
              })}
            </ListItemWrapper>
          </div>
          <BottomPagination>
            <PageBarPart
              total={users.size}
              limit={limit}
              pageCount={4}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              handleSetData={handleSetDataNum}
              field="account"
            />
          </BottomPagination>
        </>
      )}
    </Fragment>
  );
}
