import React, { useEffect, useState } from "react";
import { UploadProgressBar } from "../styled";
import { bytesToSize } from "../../../utils/helper";

export const UploadingBar = (props) => {
  const { file, width, retryFile, removeFile } = props;
  const [uploadStatus, setUploadStatus] = useState("");
  const { file_id, uploading, success, progress, file_name, file_size } = file;

  const statusText = {
    error: "Upload Failed",
    success: "Complete",
    inProgress: `Uploading ... ${progress}%`,
  };

  useEffect(() => {
    if (!uploading && success) {
      setUploadStatus("success");
    } else if (uploading) {
      setUploadStatus("inProgress");
    } else {
      setUploadStatus("error");
    }
  }, [progress, uploading, success]);

  return (
    <UploadProgressBar width={width} uploadStatus={uploadStatus}>
      <div className="info">
        {uploadStatus === "error" && <div className="error-icon" />}
        {uploadStatus === "success" && <div className="success-icon" />}
        {uploadStatus === "inProgress" && <div className="spin-icon" />}
        <div className="title">
          <div className="file-name">{file_name}</div>
          <span className="file-size">({bytesToSize(file_size)})</span>
        </div>

        {uploadStatus === "success" && (
          <div className="message">
            <span>{statusText[uploadStatus]}</span>
            <div className="remove-icon" onClick={() => removeFile(file_id)} />
          </div>
        )}

        {uploadStatus === "inProgress" && (
          <div className="message">
            <span className="grey">{statusText[uploadStatus]}</span>
          </div>
        )}

        {uploadStatus === "error" && (
          <div className="message error">
            {statusText[uploadStatus]}
            <div className="retry-icon" onClick={() => retryFile(file)} />
            <div className="remove-icon" onClick={() => removeFile(file_id)} />
          </div>
        )}
      </div>
      {uploadStatus === "inProgress" && (
        <div className="progress-bar">
          <div className="progress" style={{ width: `${progress}%` }} />
        </div>
      )}
    </UploadProgressBar>
  );
};

export default UploadingBar;
