import React from "react";
import { WarningWrap } from "./styled";

export const StateWarningModal = ({ country, state }) => {
  let type = "";
  const excludedStates = ["New South Wales", "Victoria"];
  if (state && !excludedStates.includes(state) && country === "AU") {
    type = "warning";
  }
  return (
    <>
      {type && (
        <WarningWrap type={type}>
          <div className="icon" />
          <div>
            "Paylab currently only supports territory regulations in NSW and
            VIC"
          </div>
        </WarningWrap>
      )}
    </>
  );
};
