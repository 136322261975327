import { httpRequest } from "../base/httpRequest";
import { httpRequestExt } from "../base/httpRequestExt";

// * login
const login_info = () => httpRequest("get", `/api/profile`); //config

// * config
const read_config = () => httpRequest("get", `/api/config`); //config
const read_cc_config = () => httpRequest("get", `/api/config/certs/claims`); //config
const read_system_notice = (data) =>
  httpRequest("get", `/api/maintenance?period=${data}`);
const read_timezones = () => httpRequest("get", "/api/config/timezones");
const read_countries = () => httpRequest("get", `/api/config/countries`);
const read_public_ip_details = () =>
  httpRequestExt("get", `https://api.country.is/`);
const get_uuid = () => httpRequest("get", `/api/config/randomuuid`); // new uuid
const read_integration = () => httpRequest("get", "/api/integration/type"); //data

export default {
  login_info,
  read_config,
  read_cc_config,
  read_system_notice,
  read_timezones,
  read_countries,
  get_uuid,
  read_public_ip_details,
  read_integration,
};
