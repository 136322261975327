import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  Container,
  ProjectDetailWrapper,
  ProjectInfoWrapper,
} from "../../project/styled";
import { capitalizeAddress } from "../../../utils/helper";

import { BlueOutlinedBtn as Button } from "../../../common/button";
import Modal from "../../project/components/modal";
import Input from "../../../common/form";
import Invitation from "./invitationBar";
import Address from "./address";

const DetailItem = ({ item }) => {
  if (item.defaultValue && !item.isNotShow) {
    return (
      <>
        <label>
          <b>{item.label}</b>
        </label>
        <label>{item.defaultValue}</label>
      </>
    );
  } else {
    return null;
  }
};

const ContractDetails = (props) => {
  const { contractInfo, config, updateInfo, readOnly, accountConfig } = props;
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({});
  const [tradeOptions, setTradeOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [isValidating, setIsValidating] = useState(false);
  const [contractAddress, setContractAddress] = useState({});
  const isLinked = contractInfo?.get("integration") === "ipm";

  const ContractInfoDisplay = [
    {
      label: "Contract Name",
      defaultValue: contractInfo.get("name"),
      name: "name",
      className: "full-width",
      isNotShow: true,
      ...(isLinked && { field: "link-field", value: contractInfo.get("name") }),
    },
    {
      label: "Description",
      defaultValue: contractInfo.get("description"),
      name: "description",
      className: "full-width",
      ...(isLinked && {
        field: "link-field",
        value: contractInfo.get("description"),
      }),
    },
    {
      label: "Contract Address",
      defaultValue: capitalizeAddress(
        contractInfo.get("street_address"),
        contractInfo.get("suburb"),
        contractInfo.get("city"),
        contractInfo.get("postal_code"),
        contractInfo.get("state"),
        contractInfo.get("country"),
      ),
      name: "address",
      className: "full-width",
    },
    {
      label: "Trade/Discipline",
      defaultValue: contractInfo.get("trade"),
      value: info.trade,
      name: "trade",
      className: "medium-width",
      field: "dropdown",
      options: tradeOptions,
      placeholder: "Select Trade/Discipline",
      width: info.trade !== "Other" ? "880px" : "424px",
      onChange: (e) => handleChange(e.value, "trade"),
    },
    {
      defaultValue: info.tradeText,
      name: "tradeText",
      className: "medium-width move-top",
      placeholder: "Type Trade/Discipline",
      isNotShow: true,
      hideText: info.trade !== "Other",
    },
    {
      label: "Contract Number",
      defaultValue: contractInfo.get("contract_number"),
      name: "contract_number",
      placeholder: "Enter number",
      className: "full-width",
    },
    {
      label: "Payee Reference",
      defaultValue: contractInfo.get("payee_ref"),
      name: "payee_ref",
      placeholder: "Enter number",
      className: "medium-width",
    },
    {
      label: "Payer Reference (PO Number/Contract Reference)",
      defaultValue: contractInfo.get("payer_ref"),
      name: "payer_ref",
      placeholder: "Enter number",
      className: "medium-width",
    },
  ];
  const handleChange = (e, field) => {
    let name = field || e.target.name,
      value = field ? e : e.target.value;
    if (field === "trade" && e === "Other") {
      setInfo({ ...info, tradeText: "", [name]: value });
    } else if (field === "state") {
      setInfo({ ...info, [name]: e.value });
    } else {
      setInfo({ ...info, [name]: value });
    }
  };
  useEffect(() => {
    let options = [],
      tradeOption = "";
    if (config.payclaim) {
      config.payclaim.trade_options.map((ele) =>
        options.push({
          label: ele,
          value: ele,
        }),
      );
      if (!config.payclaim.trade_options.includes(contractInfo.get("trade"))) {
        tradeOption = "Other";
      }
    }
    setTradeOptions(options);
    setInfo({
      contract_number: contractInfo.get("contract_number"),
      description: contractInfo.get("description"),
      po_number: contractInfo.get("po_number"),
      payee_ref: contractInfo.get("payee_ref"),
      name: contractInfo.get("name"),
      tradeText: contractInfo.get("trade"),
      trade: tradeOption || contractInfo.get("trade"),
      street_address: contractInfo.get("street_address"),
      suburb: contractInfo.get("suburb"),
      city: contractInfo.get("city"),
      postal_code: contractInfo.get("postal_code"),
      state: contractInfo.get("state"),
      country: contractInfo.get("country"),
    });
  }, [config, contractInfo, open]);

  useEffect(() => {
    Object.keys(contractAddress).length !== 0 &&
      setInfo({ ...info, ...contractAddress });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractAddress]);

  const onValidate = (values) => {
    const errors = {};
    const stringArr = ["name", "street_address", "country"];
    stringArr.forEach((element) => {
      if (!values[element]) {
        errors[element] = "Required";
      }
    });
    setErrors(errors);
    return errors;
  };

  const validate = async () => {
    var valid = true;
    let errors = await onValidate(info);
    valid &= Object.keys(errors).length === 0;
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsValidating(true);
    if (!(await validate())) return;
    updateInfo(info);
    setOpen(false);
  };

  const setTargetAddress = (address, insertDefaultCountry) => {
    address.postal_code = address.postcode;
    if (!insertDefaultCountry) {
      setContractAddress(address);
      isValidating && onValidate({ ...info, ...address });
    }
  };

  return (
    <Container className={props.className} id="nav-invitation">
      <ProjectDetailWrapper style={{ margin: "56px auto" }}>
        <ProjectInfoWrapper>
          <div className="title">{contractInfo.get("name")}</div>
          {/* if it's read only, replace the button with an empty div to maintain element positions in the display */}
          {!readOnly ? (
            <Button title="Edit" onClick={() => setOpen(true)} />
          ) : (
            <div />
          )}
          {ContractInfoDisplay.map((ele) => (
            <DetailItem item={ele} key={ele.name} />
          ))}
        </ProjectInfoWrapper>
        <Invitation {...props} readOnly={readOnly} />
      </ProjectDetailWrapper>

      <Modal
        title="Contract Details"
        open={open}
        close={() => setOpen(false)}
        submit={(e) => handleSubmit(e)}
        disabled={info.name === ""}
        className={"align-row"}
      >
        <>
          {ContractInfoDisplay.map((ele) => {
            if (!ele.hideText) {
              return (
                <div key={ele.name} className={"has_margin " + ele.className}>
                  {ele.name !== "address" && (
                    <Input onChange={handleChange} {...ele} />
                  )}
                  {ele.name === "address" && (
                    <Address
                      address={info}
                      setTargetAddress={setTargetAddress}
                      errors={errors}
                      handleChange={handleChange}
                      className="no-margin"
                      accountConfig={accountConfig}
                      linked={isLinked}
                      isDraft
                    />
                  )}
                </div>
              );
            } else {
              return null;
            }
          })}
        </>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default connect(mapStateToProps, null)(ContractDetails);
