import React, { useState, Fragment, useRef } from "react";
import { connect } from "react-redux";
import {
  reduxForm,
  formValueSelector,
  getFormValues,
  touch,
  stopSubmit,
  SubmissionError,
  clearSubmitErrors,
} from "redux-form/immutable";
import {
  WrapperPayerDetail,
  SearchPart,
  ProjectCheckBoxWrapper,
  WrapperDetail,
} from "./style";
import NavBottom from "./navBottom";
import SelectFrame from "common/dropdown/select";
import { BlueOutlinedBtn as Import } from "../../../../common/button";
import ErrorMsg from "../../../../common/missingRequiredBanner";
import InputSection from "./inputSection";
import { validate } from "../shared";
import { sortByLabel as compare } from "../../../../utils/helper";

let NewContactForm = (props) => {
  const {
    handleSubmit,
    reset,
    entityList,
    selectedEntityId,
    selectEntity,
    companyInfo,
    useExistingEntity,
    changeCheckbox,
    setValue,
    dispatch,
    formValues,
    accountConfig,
  } = props;

  const [errors, setErrors] = useState({});
  const errorsRef = useRef(null);

  const formRef = useRef(null);

  const requiredFieldsAndRefs = [
    {
      field: "entity_name",
      name: "Company Name",
      ref: formRef,
      missing: errors?.entity_name,
    },
    {
      field: "street_address",
      name: accountConfig.getIn(["address", "street"]),
      ref: formRef,
      missing: errors?.street_address,
    },
    {
      field: "state",
      name: accountConfig.getIn(["address", "state"]),
      ref: formRef,
      missing: errors?.state,
    },
    {
      field: "payerName",
      name: "Full Name",
      ref: formRef,
      missing: errors?.payerName,
    },
  ];

  const onSubmit = (e) => {
    dispatch(clearSubmitErrors("NewContactForm"));

    const errors = validate(formValues, props);

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      // If there are no errors, submit the form
      dispatch(reset("NewContactForm"));

      return e;
    }
    e.preventDefault();

    dispatch(touch("NewContactForm", ...Object.keys(errors)));
    dispatch(stopSubmit("NewContactForm", errors));

    errorsRef.current.scrollIntoView({ behavior: "smooth" });

    // Returning a rejected Promise to prevent form submission
    throw new SubmissionError(errors);
  };

  const options = entityList.map((item) => ({
    value: item.get("entity_id"),
    label: item.get("entity_name"),
  }));

  const valueFromId = (opts, id) => opts.find((o) => o.value === id);
  return (
    <Fragment>
      <form onSubmit={(e) => handleSubmit(onSubmit(e))} id="NewContactForm">
        <WrapperDetail>
          <div>
            <span ref={errorsRef}></span>
            <ErrorMsg
              marginTop="20px"
              fieldsAndRefs={requiredFieldsAndRefs}
              noticeTitle="To proceed, please fill in the following information"
            />
          </div>
          <ProjectCheckBoxWrapper>
            <div>
              <input
                name="useExistingEntity"
                value={useExistingEntity}
                type="checkbox"
                className="checkBoxS"
                onChange={changeCheckbox}
                id="useExistingEntity"
              />
              <label className="message" htmlFor={"useExistingEntity"}>
                Use existing entity info
              </label>
            </div>
            <Import
              type="button"
              disabled={useExistingEntity && selectedEntityId === ""}
              onClick={() => setValue("is_dialog_open", true)}
              title={
                useExistingEntity
                  ? "Import Contacts"
                  : "Import Details & Contacts"
              }
              float="right"
            />
          </ProjectCheckBoxWrapper>
          <SearchPart style={{ display: useExistingEntity ? "block" : "none" }}>
            <SelectFrame
              name="selectedEntityId"
              value={valueFromId(options, selectedEntityId)}
              onChange={(e) => selectEntity("selectedEntityId", e)}
              options={options.sort(compare)}
              placeholder="Search for a company"
            />
          </SearchPart>
          <WrapperPayerDetail>
            <InputSection
              info={companyInfo}
              status={useExistingEntity}
              type="create"
              formName={"NewContactForm"}
              errors={errors}
              formRef={formRef}
            />
          </WrapperPayerDetail>
        </WrapperDetail>
        <NavBottom reset={reset} />
      </form>
    </Fragment>
  );
};

NewContactForm = reduxForm({
  form: "NewContactForm",
  validate,
  enableReinitialize: true,
})(NewContactForm);

// add company and contact name form values to state
const selector = formValueSelector("NewContactForm");
NewContactForm = connect((state) => {
  const companyName = selector(state, "entity_name");
  const contactName = selector(state, "payerName");
  const companyCountry = selector(state, "country");
  const companyState = selector(state, "state");
  return {
    companyName,
    contactName,
    companyState,
    companyCountry,
    formValues: getFormValues("NewContactForm")(state),
  };
})(NewContactForm);

export default NewContactForm;
