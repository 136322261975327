import React, { Component } from "react";
import { connect } from "react-redux";
import { isDirty } from "redux-form/immutable";

import { BgWrapper, HeaderTitle } from "../../../../common/shared_style";

import Menu from "../../../../common/breadcrumbs";
import Prompt from "../../../../common/modal/component/prompt";
import Form from "./form";
import UploadDialog from "../../components/csvUpload";

import { actionCreators as modalAction } from "../../../../common/modal";
import { actionCreators } from "../../store";

class Contacts extends Component {
  createContact = (value) => {
    this.props.createNewEntityContact(
      value,
      this.props.useExistingEntity,
      this.props.selectedEntityId,
    );
  };
  render() {
    var link = [{ link: "/contacts", text: "Contacts" }];
    const {
      useExistingEntity,
      selectedEntityId,
      csvFile,
      is_dialog_open,
      formValue,

      setValue,
      uploadFile,
      handleClose,
      showAlert,
      importContactCSV,
    } = this.props;
    return (
      <BgWrapper>
        <Prompt when={this.props.isFormDirty && !this.props.saved} />
        <UploadDialog
          open={is_dialog_open}
          handleOpen={() => setValue("is_dialog_open", true)}
          handleClose={handleClose}
          uploadFile={uploadFile}
          filelist={csvFile}
          clearUpload={() => setValue("csvFile", [])}
          showAlert={(message) => showAlert(message)}
          importCSV={() => importContactCSV(selectedEntityId, csvFile)}
          type={!useExistingEntity ? "company" : "payer"}
        />
        <Menu link={link} content="Create Contact" />
        <HeaderTitle>Create Contact</HeaderTitle>
        <Form
          onSubmit={this.createContact}
          initialValues={formValue}
          {...this.props}
        />
      </BgWrapper>
    );
  }
  componentDidMount() {
    this.props.requestEntityOption();
  }
  componentWillUnmount() {
    this.props.resetInfo();
  }
}
const mapStateToProps = (state) => ({
  entityList: state.getIn(["contacts", "entityList"]),
  selectedEntityId: state.getIn(["contacts", "selectedEntityId"]),
  companyInfo: state.getIn(["contacts", "companyInfo"]),
  useExistingEntity: state.getIn(["contacts", "useExistingEntity"]),
  isFormDirty: isDirty("NewContactForm")(state),
  saved: state.getIn(["contacts", "saved"]),
  is_dialog_open: state.getIn(["contacts", "is_dialog_open"]),
  csvFile: state.getIn(["contacts", "csvFile"]),
  formValue: state.getIn(["contacts", "formValue"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});
const mapDispatch = (dispatch) => {
  return {
    createNewEntityContact(value, useExistingEntity, selectedEntityId) {
      if (useExistingEntity && selectedEntityId === "") {
        dispatch(
          modalAction.showModal("alert", {
            open: true,
            title: "Error!",
            message: "Please select an entity!",
          }),
        );
      } else {
        dispatch(
          actionCreators.createNewContact(
            value,
            useExistingEntity,
            selectedEntityId,
          ),
        );
      }
    },
    requestEntityOption() {
      dispatch(actionCreators.getAllEntityOption());
    },
    selectEntity(name, e) {
      dispatch(actionCreators.setSelectedInfo(name, e.value));
      dispatch(actionCreators.getSpecificEntity(e.value));
    },
    changeCheckbox(e) {
      dispatch(actionCreators.setSelectedInfo(e.target.name, e.target.checked));
    },
    resetInfo() {
      dispatch(actionCreators.resetStage());
    },
    importContactCSV(entityId, file) {
      dispatch(actionCreators.importContactCSV(entityId, file));
    },
    uploadFile(file) {
      dispatch(actionCreators.setData("csvFile", file));
    },
    setValue(name, value) {
      dispatch(actionCreators.setData(name, value));
    },
    handleClose() {
      dispatch(actionCreators.closeUploadDialog());
    },
    showAlert(meg) {
      dispatch(
        modalAction.showModal("alert", {
          open: true,
          title: "Error!",
          message: meg,
        }),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatch)(Contacts);
