import React, { Fragment, useState, useEffect } from "react";
import { SearchContainer, BottomWrap } from "./styled";
import Search from "../dropdown/select";
import API from "../../server";
import { BlueWhiteBtn as Add, BlueOutlinedBtn as Cancel } from "../button";
import * as theme from "../../theme";
import {
  sortByLabel as compare,
  validateEmailAddress,
} from "../../utils/helper";
import Input from "../form";

const Index = (props) => {
  const {
    handleClose,
    entityList,
    field,
    addContact,
    addNewContact,
    emailToList,
  } = props;
  const [selectedEntityId, setSelectedEntityId] = useState("");
  const [selectedContactId, setSelectedContactId] = useState("");
  const [contactList, setContactList] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionsContact, setOptionsContact] = useState([]);
  const [input, setInput] = useState({
    newContact: "",
    newContactEmail: "",
  });
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    let entityOptions = [];
    let contactOptions = [];

    entityList?.map((item) => {
      let a = {
        value: item.get("entity_id"),
        label: item.get("entity_name"),
      };
      entityOptions.push(a);
      return null;
    });
    contactList?.map((item) => {
      let a = {
        value: item.contact_id,
        label: item.contact_name,
        id: item.entity_id,
      };
      contactOptions.push(a);
      return null;
    });

    setOptions(entityOptions);
    setOptionsContact(contactOptions);
  }, [entityList, contactList]);

  const valueFromId = (opts, id) => opts.find((o) => o.value === id);

  const selectEntity = (id) => {
    API.read_company_contacts(id)
      .then((res) => res.data.data.data)
      .then((res) => {
        setSelectedEntityId(id);
        setContactList(res || []);
      });
  };

  const handleAddContact = () => {
    if (
      validateEmailAddress(input.newContactEmail) &&
      !isEmailInList(input.newContactEmail)
    ) {
      addNewContact(input.newContact, input.newContactEmail, field);
    } else {
      if (!input.newContactEmail) {
        alert("Please input the email address!");
      }
      validateEmailInput();
    }
  };

  const isEmailInList = (email) => {
    let emailList = [];
    emailToList.map((item) => {
      emailList.push(item.get("contact_email")?.toLowerCase());
      return null;
    });
    return email ? emailList.includes(email.toLowerCase()) : false;
  };

  const validateEmailInput = () => {
    setErrMsg("");
    if (input.newContactEmail) {
      if (!validateEmailAddress(input.newContactEmail)) {
        setErrMsg("Invalid email address");
      }
      if (isEmailInList(input.newContactEmail)) {
        setErrMsg("Email already added");
      }
    }
  };

  return (
    <Fragment>
      <SearchContainer>
        <div className="option">Select</div>
        <div className="child_container">
          <div className="name">
            Company Name{" "}
            <span style={{ color: theme.palette.watermelonRed }}>*</span>
          </div>
          <div className="right">
            <Search
              options={options.sort(compare)}
              value={valueFromId(options, selectedEntityId)}
              onChange={(e) => selectEntity(e.value)}
              placeholder="Search for a company"
            />
          </div>
        </div>
        <div className="child_container">
          <div className="name">
            Contact Name{" "}
            <span style={{ color: theme.palette.watermelonRed }}>*</span>
          </div>
          <div className="right">
            <Search
              options={optionsContact.sort(compare)}
              value={valueFromId(optionsContact, selectedContactId)}
              onChange={(e) => setSelectedContactId(e.value)}
              placeholder="Search for a contact person"
            />
          </div>
        </div>
        <div>
          <Add
            title="ADD"
            onClick={() =>
              addContact(selectedEntityId, selectedContactId, field)
            }
            padding="10px 38px"
          />
        </div>
      </SearchContainer>
      <SearchContainer className="Or">Or</SearchContainer>
      <SearchContainer className="Input">
        <div className="option">Input</div>
        <div className="child_container">
          <div className="name">Contact Name</div>
          <div className="right">
            <Input
              className="inputStyle"
              name="newContact"
              value={input.newContact}
              onChange={(e) =>
                setInput({ ...input, [e.target.name]: e.target.value })
              }
            />
          </div>
        </div>
        <div className="child_container">
          <div className="name">
            Contact Email{" "}
            <span style={{ color: theme.palette.watermelonRed }}>*</span>
          </div>
          <div className="right">
            <Input
              className="inputStyle"
              name="newContactEmail"
              value={input.newContactEmail}
              onChange={(e) => {
                setInput({ ...input, [e.target.name]: e.target.value?.trim() });
                if (errMsg) {
                  setErrMsg("");
                }
              }}
              onBlur={() => validateEmailInput()}
              type="email"
              error={errMsg}
              required
            />
          </div>
        </div>
        <div>
          <Add
            title="ADD"
            onClick={() => handleAddContact()}
            padding="10px 38px"
          />
        </div>
      </SearchContainer>
      <BottomWrap>
        <Cancel onClick={handleClose} title="CANCEL" />
        <Add onClick={handleClose} title="DONE" margin="0 30px 0 0" />
      </BottomWrap>
    </Fragment>
  );
};

export default Index;
