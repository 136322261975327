import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NumericFormat } from "react-number-format";
import {
  HeaderRow,
  HeaderCell,
  HeaderRenderIcon,
  HeaderContent,
  DataRow,
  ChildDataRow,
  Label,
  RowLabel,
  SubLabel,
  DataCell,
  RowItem,
  Disclaimer,
} from "./styled";
import { SUMMARY_TYPE } from "utils/constants";
import BootstrapTooltip from "../../../common/toolTip";
const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export const NumberInput = connect(
  mapStateToProps,
  null,
)((props) => {
  const {
    accountConfig,
    value,
    currency,
    showWarning,
    onValueChange,
    displayInBrackets,
    id,
  } = props;
  const [displayVal, setDisplayVal] = useState(value ?? 0.0);
  const [disabled, setDisabled] = useState(true);
  const isLTR = accountConfig?.getIn(["direction", "value"]) === "LTR";
  let prefix = isLTR ? currency : "";
  let suffix = !isLTR ? currency : "";

  if (displayInBrackets) {
    prefix = `${prefix}(`;
    suffix = `)${suffix}`;
  }
  useEffect(() => {
    if (disabled && displayVal !== value) {
      onValueChange(displayVal);
    }
  }, [disabled]); // eslint-disable-line

  const handleValueChange = (e) => {
    const newValue = e.floatValue ?? 0.0;
    if (!disabled) {
      setDisplayVal(newValue);
    }
  };

  return (
    <>
      <NumericFormat
        id={id}
        thousandSeparator={","}
        decimalSeparator={"."}
        prefix={prefix}
        suffix={suffix}
        placeholder={currency}
        decimalScale={2}
        fixedDecimalScale={true}
        className={`inputFrame ${showWarning ? " required" : ""}`}
        allowLeadingZeros={false}
        value={displayVal}
        onBlur={() => setDisabled(true)}
        onFocus={() => setDisabled(false)}
        allowNegative={!displayInBrackets}
        onValueChange={handleValueChange}
      />
    </>
  );
});

const renderCellContent = (key) => {
  if (key) {
    return typeof key === "function" ? key() : key;
  } else {
    return "";
  }
};

const ClaimCertSummaryTable = ({
  headers,
  data,
  summaryType,
  claimReleaseRetention,
  handleClaimReleaseRetentionCheckbox,
  readOnly,
  disclaimer,
}) => {
  const [expandedRows, setExpandedRows] = useState({});
  const handleRowClick = (rowIndex) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex],
    }));
  };

  const SubChildsRows = ({
    subChilds,
    description,
    headers,
    rowIndex,
    summaryType,
    readOnly,
    renderCellContent,
    height,
  }) => {
    const filteredSubChilds = subChilds?.filter((subChild) =>
      Object.prototype.hasOwnProperty.call(subChild, "isConditional")
        ? subChild.isConditional
        : Object.prototype.hasOwnProperty.call(subChild, "isShow")
          ? subChild.isShow
          : true,
    );
    return (
      <>
        {filteredSubChilds &&
          filteredSubChilds.map((singleSubChild, subChildIndex) => (
            <ChildDataRow
              key={`${rowIndex}-${subChildIndex}`}
              className={`child ${singleSubChild.description ? "description" : "subChildRow"}`}
            >
              <RowItem
                height={height}
                className={`${summaryType} ${readOnly ? "readonly" : ""} ${singleSubChild.description ? "subChilddescription" : "subChildItem"}`}
              >
                <div
                  className={`label ${singleSubChild.description ? "subChilddescriptionLabel" : "subChildItemLabel"}`}
                >
                  {singleSubChild.label}
                </div>
              </RowItem>
              {headers?.map((header, colIndex) => {
                const columnData = singleSubChild.columns.find(
                  (col) => col[header.dataKey],
                );
                return (
                  <DataCell
                    key={colIndex}
                    width={header.style?.width}
                    fontColor={
                      columnData?.style
                        ? columnData.style?.fontColor
                        : header.style?.fontColor
                    }
                    height={columnData?.height}
                    className="subChildCell"
                    backgroundColor={
                      columnData?.style
                        ? columnData.style?.backgroundColor
                        : header.style?.backgroundColor
                    }
                    id={`${header?.dataKeyID}${singleSubChild?.labelID}`}
                  >
                    {columnData
                      ? renderCellContent(columnData[header?.dataKey])
                      : ""}
                  </DataCell>
                );
              })}
            </ChildDataRow>
          ))}
      </>
    );
  };

  // Filter out the rows that are not required to be shown
  const filteredData = data?.filter((row) =>
    Object.prototype.hasOwnProperty.call(row, "isConditional")
      ? row.isConditional
      : Object.prototype.hasOwnProperty.call(row, "isShow")
        ? row.isShow
        : true,
  );

  const nestedRowsToShow = (row) =>
    readOnly || !claimReleaseRetention ? row.combinedChildren : row.children;

  return (
    <>
      <HeaderRow>
        <HeaderCell
          className={`first ${summaryType} ${readOnly ? "readonly" : ""}`}
        />
        {headers?.map((header, index) => {
          if (header?.component && !readOnly) {
            if (summaryType === SUMMARY_TYPE.claim) {
              return (
                <HeaderCell
                  key={index}
                  backgroundColor={header.style?.backgroundColor}
                  width={header.style?.width}
                  alignItems={header.style?.alignItems}
                >
                  <HeaderContent>{header.component}</HeaderContent>
                </HeaderCell>
              );
            }
            if (summaryType === SUMMARY_TYPE.cert) {
              return (
                <HeaderCell
                  key={index}
                  backgroundColor={header.style?.backgroundColor}
                  width={header.style?.width}
                  alignItems={header.style?.alignItems}
                >
                  {header.renderIcon && (
                    <HeaderRenderIcon>{header.renderIcon}</HeaderRenderIcon>
                  )}
                  <HeaderContent>{header.component}</HeaderContent>
                </HeaderCell>
              );
            }
          }

          if (summaryType === SUMMARY_TYPE.claim && header?.renderIcon) {
            return (
              <HeaderCell
                key={index}
                width={header.style?.width}
                backgroundColor={header.style?.backgroundColor}
                alignItems={header.style?.alignItems}
              >
                <HeaderContent key={index}>{header.renderIcon}</HeaderContent>
              </HeaderCell>
            );
          }

          return (
            <HeaderCell
              key={index}
              backgroundColor={header.style?.backgroundColor}
              width={header.style?.width}
              alignItems={header.style?.alignItems}
            >
              {header.renderIcon && (
                <HeaderRenderIcon>{header.renderIcon}</HeaderRenderIcon>
              )}
              <HeaderContent>
                <Label fontSize={header.style?.fontSize}>{header.label}</Label>
                {header.subLabel && <SubLabel>{header.subLabel}</SubLabel>}
              </HeaderContent>
            </HeaderCell>
          );
        })}
      </HeaderRow>
      {filteredData?.map((row, rowIndex) => {
        const { label = "", labelStyle = [], rowStyle = {} } = row;

        let labelCustomStyle;
        let labelJSX = <>{label}</>;

        labelStyle.forEach((styleOption) => {
          if (styleOption?.startsWith("bold")) {
            if (styleOption === "bold") {
              labelJSX = <b>{labelJSX}</b>;
              return;
            }

            const [frontLabelIndex, endLabelIndex] = styleOption
              .slice(styleOption.indexOf("[") + 1, styleOption.indexOf("]"))
              .split(":")
              .map((num) => parseInt(num));

            if (isNaN(frontLabelIndex) || isNaN(endLabelIndex)) {
              return;
            }

            labelJSX = (
              <>
                {label.slice(0, frontLabelIndex)}
                <b>{label.slice(frontLabelIndex, endLabelIndex)}</b>
                {label.slice(endLabelIndex)}
              </>
            );
          }

          if (styleOption?.startsWith("fontSize")) {
            const [, fontSizeString] = styleOption.split(":");

            if (fontSizeString?.length === 0) {
              return;
            }

            if (!labelCustomStyle) {
              labelCustomStyle = {
                fontSize: fontSizeString,
              };
            } else {
              labelCustomStyle.fontSize = fontSizeString;
            }
          }
        });

        return (
          <React.Fragment key={rowIndex}>
            {row?.isCustomColumns && (
              <DataRow borderTop={row?.borderTop}>
                <RowItem
                  className={`first ${summaryType} ${readOnly ? "readonly" : ""}`}
                >
                  <div className="label">{row.label}</div>
                </RowItem>
                {row?.columns?.map((col, colIndex) => {
                  return (
                    <DataCell
                      width={col?.width || "inherit"}
                      height={col?.height}
                      key={`custom-${colIndex}`}
                      id={`cell_${row?.labelID}`}
                    >
                      {col?.renderRow()}
                    </DataCell>
                  );
                })}
              </DataRow>
            )}
            {/* BELOW ROWS FOR CLAIM and CERT: THAT DONOT HAVE CLAIM RELEASE RETENTION */}
            {!row?.isCustomColumns && !row?.isConditional && (
              <React.Fragment>
                <DataRow
                  key={rowIndex}
                  borderTop={rowStyle.borderTop}
                  textWrap={rowStyle.textWrap}
                >
                  <RowItem
                    className={`first ${summaryType} ${readOnly ? "readonly" : ""}`}
                  >
                    <RowLabel fontSize={labelCustomStyle?.fontSize}>
                      {labelJSX}
                    </RowLabel>
                  </RowItem>
                  {headers?.map((header, colIndex) => {
                    const columnData = row.columns.find(
                      (col) => col[header.dataKey],
                    );
                    return (
                      <DataCell
                        key={colIndex}
                        width={header.style?.width}
                        fontColor={
                          columnData?.style
                            ? columnData.style?.fontColor
                            : header.style?.fontColor
                        }
                        backgroundColor={
                          columnData?.style
                            ? columnData.style?.backgroundColor
                            : header.style?.backgroundColor
                        }
                        fontSize={columnData?.style?.fontSize}
                        id={`${header?.dataKeyID}${row?.labelID}`}
                      >
                        {columnData
                          ? renderCellContent(columnData[header?.dataKey])
                          : ""}
                      </DataCell>
                    );
                  })}
                </DataRow>
                <SubChildsRows
                  subChilds={row.subChilds}
                  headers={headers}
                  rowIndex={rowIndex}
                  summaryType={summaryType}
                  readOnly={readOnly}
                  renderCellContent={renderCellContent}
                  height={row.height}
                />
              </React.Fragment>
            )}
            {/* BELOW ROWS FOR ONLY CLAIM: CLAIM RELEASE RETENTION */}
            {!row?.isCustomColumns && row?.isConditional && (
              <>
                <DataRow
                  key={rowIndex}
                  borderTop={rowStyle.borderTop}
                  textWrap={rowStyle.textWrap}
                  className={row.expand ? "expandedRow" : ""}
                  clickable={!!row.handler}
                  onClick={() => row.handler && handleRowClick(rowIndex)}
                >
                  <RowItem
                    className={`${summaryType}  ${readOnly ? " readonly" : "conditional"}`}
                    width={row?.width}
                  >
                    {!readOnly && row.showCheckbox && (
                      <input
                        checked={claimReleaseRetention}
                        type="checkbox"
                        className="checkBox"
                        onChange={handleClaimReleaseRetentionCheckbox}
                      />
                    )}
                    <div className="label-container">
                      {row.prefix && (
                        <span
                          className={`${expandedRows[rowIndex] ? "expanded" : "collapsed"} ${readOnly ? "readonly" : ""}`}
                        ></span>
                      )}
                      <RowLabel fontSize={labelCustomStyle?.fontSize}>
                        {labelJSX}
                      </RowLabel>
                      {row.tooltiptext && (
                        <BootstrapTooltip
                          title={row.tooltiptext}
                          placement="bottom"
                          width="450px"
                          height="50px"
                          themecolor="slateGrey"
                          size="12px"
                          fontWeight="normal"
                        >
                          <div className={`icon ${row.icon}`} />
                        </BootstrapTooltip>
                      )}
                    </div>
                  </RowItem>
                  {headers?.map((header, colIndex) => {
                    const columnData = row.columns.find(
                      (col) => col[header.dataKey],
                    );
                    return (
                      <DataCell
                        key={colIndex}
                        width={header.style?.width}
                        fontColor={
                          columnData?.style
                            ? columnData.style?.fontColor
                            : header.style?.fontColor
                        }
                        backgroundColor={
                          columnData?.style
                            ? columnData.style?.backgroundColor
                            : header.style?.backgroundColor
                        }
                        fontSize={columnData?.style?.fontSize}
                        id={`${header?.dataKeyID}${row?.labelID}`}
                      >
                        {columnData
                          ? renderCellContent(columnData[header?.dataKey])
                          : ""}
                      </DataCell>
                    );
                  })}
                </DataRow>
                {nestedRowsToShow(row)?.map((childRow, childIndex) => (
                  <ChildDataRow key={childIndex}>
                    <RowItem
                      className={`conditional child${readOnly ? " readonly" : ""}`}
                    >
                      <RowLabel>{childRow.label}</RowLabel>
                    </RowItem>
                    {headers?.map((header, colIndex) => {
                      const columnData = childRow.columns.find(
                        (col) => col[header.dataKey],
                      );
                      return (
                        <DataCell
                          key={colIndex}
                          width={header.style?.width}
                          fontColor={
                            columnData?.style
                              ? columnData.style?.fontColor
                              : header.style?.fontColor
                          }
                          backgroundColor={
                            columnData?.style
                              ? columnData.style?.backgroundColor
                              : header.style?.backgroundColor
                          }
                          fontSize={columnData?.style?.fontSize}
                          id={`${header?.dataKeyID}${childRow?.labelID || row?.labelID}`}
                        >
                          {columnData
                            ? renderCellContent(columnData[header?.dataKey])
                            : ""}
                        </DataCell>
                      );
                    })}
                  </ChildDataRow>
                ))}
                {expandedRows[rowIndex] && (
                  <SubChildsRows
                    subChilds={row.subChilds}
                    description={row.description}
                    headers={headers}
                    rowIndex={rowIndex}
                    summaryType={summaryType}
                    readOnly={readOnly}
                    renderCellContent={renderCellContent}
                  />
                )}
              </>
            )}
          </React.Fragment>
        );
      })}
      {disclaimer && <Disclaimer>{disclaimer}</Disclaimer>}
    </>
  );
};

export default ClaimCertSummaryTable;
