import React from "react";
import history from "../../../utils/history";
import {
  BlueWhiteBtn as Confirm,
  BlueOutlinedBtn as Cancel,
} from "../../button";
import { TrialIcon, TrialWrapper } from "./dialogStyle";
import Modal from "./modalBase";

const TrialDialog = (props) => {
  const redirect = () => {
    props.closeModal();
    localStorage.setItem("close_trial_notice", JSON.stringify(true));
    history.push({
      pathname: `/manage-account/plan-price`,
      search: `?account_id=${props.accountId}`,
      state: { noPermission: false },
    });
  };
  return (
    <Modal
      {...props}
      handleClose={() => {
        props.closeModal();
        localStorage.setItem("close_trial_notice", JSON.stringify(true));
      }}
      width="561px"
      divider={false}
    >
      {props.days > 0 ? (
        <TrialWrapper>
          <TrialIcon />
          <div className="title">
            Your free trial ends in {props.days} days.
          </div>
          <div className="content">
            To keep using PayLab, please enter your payment details now.
            <br />
            <br />
            <div className="link">How PayLab charging works.</div>
          </div>
        </TrialWrapper>
      ) : (
        <TrialWrapper>
          <TrialIcon className="over" />
          <div className="title">Your free trial is over.</div>
          <div className="content">
            You can use PayLab on one project for free. If you would like to use
            it for more, please add your payment details now.
            <br />
            <br />
            <div className="link">How PayLab charging works.</div>
          </div>
        </TrialWrapper>
      )}
      <div>
        <Confirm
          onClick={() => redirect()}
          title="Enter Payment Details"
          padding="10px 30px"
        />
        <Cancel
          onClick={() => {
            props.closeModal();
            localStorage.setItem("close_trial_notice", JSON.stringify(true));
          }}
          margin="0 20px 40px 0"
          title="I’ll Do It Later"
          padding="10px 58px"
        />
      </div>
    </Modal>
  );
};
export default TrialDialog;
