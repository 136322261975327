import styled from "styled-components";

export const Container = styled.div`
  height: auto;
  width: 1183px;
  margin: 0 auto;
  .page-breadcrumbs {
    width: 100%;
    margin: 24px auto;
    align-items: center;
    display: grid;
    grid-template-columns: auto 140px 200px;
    .page-breadcrumbs-label {
      margin: 0 !important;
    }
  }
  h1 {
    font-weight: bold;
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 8px;
  }
  p {
    line-height: 20px;
  }
  .content-container {
    margin: 40px 0;
  }
`;
