import React, { useEffect, useState } from "react";
import {
  ModalContent,
  TableWrap,
  BottomPagination,
  ModalTitle,
} from "../style";
import history from "../../../../../utils/history";
import Modal from "../../../../../common/modal/component/modalBase";
import PageBarPart from "../../../../../common/pagination";

export default function UserAccessScope(props) {
  const { data, isOpen, close } = props;
  const [open, setOpen] = useState(false);
  const [scope, setScope] = useState("projects");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(50);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const total = !data?.get(scope) ? 0 : data?.get(scope).size;

  return (
    <Modal
      divider={false}
      handleClose={close}
      open={open}
      title={
        <ModalTitle>
          <div>Explicit Access</div>
          <div className="tabWrap">
            <div
              className={
                "tab " + (scope === "projects" ? "selected" : "unselected")
              }
              onClick={() => setScope("projects")}
            >
              Projects
            </div>
            <div
              className={
                "tab " + (scope === "contracts" ? "selected" : "unselected")
              }
              onClick={() => setScope("contracts")}
            >
              Contracts
            </div>
          </div>
        </ModalTitle>
      }
      width={960}
    >
      <ModalContent>
        <div>
          These are {scope} that this user has been given explicit access to.
        </div>
        <TableWrap>
          <table>
            <thead>
              <tr>
                <th className="project">{scope}</th>
                <th className="access">Access</th>
              </tr>
            </thead>
            <tbody>
              {!data?.get(scope) ? (
                <tr>
                  <td colSpan={2} className="alignCenter">
                    No Data
                  </td>
                </tr>
              ) : (
                data?.get(scope).map((item, index) => {
                  let page = currentPage - 1;
                  if (index >= page * limit && index < (page + 1) * limit) {
                    if (scope === "contracts") {
                      return (
                        <tr
                          key={item.get("contract_uuid")}
                          onClick={() =>
                            history.push(
                              `/contract/${item.get("project_uuid")}/${item.get(
                                "contract_type",
                              )}/${item.get("contract_uuid")}`,
                            )
                          }
                        >
                          <td>
                            {item.get("contract_name")} (for{" "}
                            {item.get("project_name")})
                          </td>
                          <td className="roleDes">{item.get("role")}</td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr
                          key={item.get("project_uuid")}
                          onClick={() =>
                            history.push(
                              `/project/view/${item.get("project_uuid")}`,
                            )
                          }
                        >
                          <td>{item.get("project_name")}</td>
                          <td className="roleDes">{item.get("role")}</td>
                        </tr>
                      );
                    }
                  } else {
                    return null;
                  }
                })
              )}
            </tbody>
          </table>
        </TableWrap>
      </ModalContent>
      <BottomPagination>
        <PageBarPart
          total={total}
          limit={limit}
          field="big"
          currentPage={currentPage}
          handlePageChange={(page) => setCurrentPage(page)}
          handleSetData={(data) => {
            setCurrentPage(1);
            setLimit(data);
          }}
        />
      </BottomPagination>
    </Modal>
  );
}
