import * as constants from "./constants";
import history from "../../utils/history";
import API from "../../server";

export const deleteItem = () => {
  return (dispatch, getState) => {
    let api = getState().getIn(["modal", "modalProps", "api"]),
      action = getState().getIn(["modal", "modalProps", "action"]);
    let httpRequest = API[api.name](...api.config);
    httpRequest
      .then((res) => res.data)
      .then((res) => {
        dispatch(hideModal());
        if (action !== undefined) {
          if (Array.isArray(action)) {
            for (var index in action) {
              dispatch(action[index]);
            }
          } else {
            dispatch(action);
          }
        }
      });
  };
};

export const hideModal = (status) => {
  return (dispatch, getState) => {
    let url = getState().getIn(["modal", "modalProps", "url"]),
      type = getState().getIn(["modal", "modalType"]),
      action = getState().getIn(["modal", "modalProps", "action"]);
    dispatch({
      type: constants.HIDE_MODAL,
      status,
    });
    if (status !== "cancel") {
      url && history.push(url);
    }
    type === "confirm" && action && dispatch(action);
  };
};

export const showModal = (modalType, modalProps) => ({
  type: constants.SHOW_MODAL,
  modalType,
  modalProps,
});

export const setModalInfo = (value) => ({
  type: constants.SET_MODAL_INFO,
  value,
});
