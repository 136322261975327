import React, { Component } from "react";
import Modal from "../../modal/component/modalBase";
import Input from "../../form";
import { DialogContent } from "../styled";
import { GreenWhiteBtn as SaveBtn } from "../../button";

export default class approval_comment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      approverApproved: false,
    };
  }

  performApprovalOnClick = (goToApprove) => {
    this.setState({ approverApproved: true });
    goToApprove();
  };

  render() {
    const { open, content, closeDialog, handleInput, goToApprove, type } =
      this.props;
    const { approverApproved } = this.state;
    return (
      <Modal
        open={open}
        handleClose={closeDialog}
        title={"Leave a comment?"}
        width="590px"
      >
        <DialogContent>
          <div>
            You can leave a comment for this approval. This will appear in your
            approval history for this {type}.
          </div>
          <div className="sub-label">
            <Input
              label="Leave a comment (optional)"
              name="content"
              field="textarea"
              value={content}
              onChange={handleInput}
              placeholder=""
              style={{ padding: "20px", width: "647px", height: "88px" }}
            />
          </div>
        </DialogContent>
        <SaveBtn
          title="Continue"
          type="submit"
          onClick={
            approverApproved
              ? null
              : () => this.performApprovalOnClick(goToApprove)
          }
          disabled={approverApproved}
        />
      </Modal>
    );
  }
}
