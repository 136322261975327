import React from "react";
import history from "../../../../utils/history";

import { ProjectBottomWrapper } from "../../styled";
import {
  BlueOutlinedBtn as BlueButton,
  IssueBtn as Issue,
  BackBtn as Back,
} from "../../../../common/button";
import { DownloadDropdown } from "common/dropdown/dropdown_with_icon";
import { CertContract } from "../../components/pageMenu";

function checkIfApprove(list, sendingEmail, exceedAttachLimit) {
  let current_step =
    list.size !== 0
      ? list.get("current_step_index") === list.get("steps")?.size - 1
      : true;
  if (current_step) {
    return !sendingEmail && !exceedAttachLimit;
  }
  return false;
}

const Index = (props) => {
  const {
    handleIssuePaycert,
    isApprovedCert,
    isSendEmail,
    handleResendEmail,
    approvalList,
    accountId,
    sendingEmail,
    options,
    contractType,
    readOnly,
    contractInfo,
    exceedAttachLimit,
    currentUserApproved,
  } = props;

  const redirect = (location) => {
    const { proId, contractId, contractType, claimId, accountId } = props;
    let contractPageType = CertContract.includes(contractType)
      ? "cert"
      : "claim";
    let link = "";
    if (location === "overviewCert") {
      link = `/contract/${proId}/${contractPageType}/${contractId}`;
    }
    if (location === "draftCert") {
      link = `/project/${proId}/contract/${contractId}/${contractType}/claim/${claimId}/cert`;
    }
    history.push({ pathname: link, search: `?account_id=${accountId}` });
  };

  return (
    <ProjectBottomWrapper className="review">
      <div className="left">
        <BlueButton
          title="EXIT"
          float="left"
          onClick={() => redirect("overviewCert", accountId)}
        />
        {!isApprovedCert && (
          <Back
            label="BACK"
            margin="0 0 0 30px"
            float="left"
            onClick={() => redirect("draftCert", accountId)}
            title="Back to add paycert page"
            disabled={!currentUserApproved}
          />
        )}
      </div>

      <div className="right">
        {isApprovedCert && contractType !== "claim" && !readOnly && (
          <Issue
            title="Resend Email"
            onClick={handleResendEmail}
            disabled={!isSendEmail || sendingEmail || exceedAttachLimit}
            margin="0 30px"
            color="blue"
            submitting={sendingEmail}
          />
        )}
        <DownloadDropdown options={options} isPreview={!isApprovedCert} />
        {!isApprovedCert && !readOnly && (
          <Issue
            disabled={
              !checkIfApprove(approvalList, sendingEmail, exceedAttachLimit)
            }
            onClick={handleIssuePaycert}
            submitting={sendingEmail}
            title={
              contractInfo && contractInfo?.get("is_bcti")
                ? "Issue Certificate + Invoice"
                : "Issue Certificate"
            }
            margin="0 0 0 30px"
          />
        )}
      </div>
    </ProjectBottomWrapper>
  );
};

export default Index;
