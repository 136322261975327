export const CertContract = ["cert", "self-claim"];

export function getMenuLink(
  projectInfo,
  contractId,
  contractName,
  contractType,
  claimId,
  certId,
  certText,
) {
  let contractPageType = CertContract.includes(contractType) ? "cert" : "claim";
  let proId = projectInfo.get("id");

  let menuLinks = [
    { link: "/projects", text: "Projects" }, // projects page
    { link: `/project/view/${proId}`, text: projectInfo.get("name") }, // project overview page
    {
      link: `/contract/${proId}/${contractPageType}/${contractId}`,
      text: contractName,
    }, // contract overview page
  ];
  if (certId) {
    menuLinks.push({
      link: `/project/${proId}/contract/${contractId}/${contractType}/claim/${claimId}/cert`,
      text: `Add ${certText}`,
    }); // draft cert page
  }

  return menuLinks;
}
