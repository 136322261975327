import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Select from "./select_frame";

import {
  ProjectItems,
  ProjectWrapper,
  ProjectInfoWrapper,
  InfoProject,
  ProjectParamTitle,
  ProjectValue,
  Date,
} from "../styled";

import { actionCreators as actionCreatorsProjects } from "../../projects/store";
import { currencySubunitToUnit } from "../../../utils/currencyHelper";

class TableContainer extends PureComponent {
  render() {
    const valueFromId = (opts, id) => opts.find((o) => o.value === id);
    const {
      projectList,
      projectType,
      selProjectType,
      allProjects,
      accountConfig,
    } = this.props;

    const hasClaimedContracts = () =>
      allProjects.some(
        (item) =>
          parseFloat(item.getIn(["claim_contracts", "contract_number"])) > 0,
      );

    const options = [
      { label: "Current Contracts", value: "current" },
      { label: "Draft Contracts", value: "draft" },
      { label: "Completed Contracts", value: "completed" },
      { label: "Archived Contracts", value: "archived" },
    ];
    return (
      <>
        {hasClaimedContracts() && (
          <ProjectItems className={projectType}>
            <ProjectWrapper>
              <Select
                value={valueFromId(options, projectType)}
                onChange={selProjectType}
                name="projectType"
                className="selectFrame"
                defaultValue="current"
                options={options}
              />
            </ProjectWrapper>
            <ProjectInfoWrapper>
              <InfoProject className="project">
                <ProjectParamTitle>Projects:</ProjectParamTitle>
                <ProjectValue>{projectList.get("contract_nums")}</ProjectValue>
                <Date />
              </InfoProject>
              <InfoProject className="contract">
                <ProjectParamTitle>Contract Value:</ProjectParamTitle>
                <ProjectValue>
                  {currencySubunitToUnit(
                    projectList.get("contract_invitation_value"),
                    true,
                    false,
                    true,
                  )}
                </ProjectValue>
                <Date />
              </InfoProject>
              <InfoProject className="other">
                <ProjectParamTitle>
                  {accountConfig?.getIn(["claim", "value"])} Total:
                </ProjectParamTitle>
                <ProjectValue>
                  {currencySubunitToUnit(
                    projectList.get("contract_claimed_total"),
                    true,
                    false,
                    true,
                  )}
                </ProjectValue>
                <Date> (to date) </Date>
              </InfoProject>
              <InfoProject className="other">
                <ProjectParamTitle>Approved Total:</ProjectParamTitle>
                <ProjectValue>
                  {currencySubunitToUnit(
                    projectList.get("contract_approved_total"),
                    true,
                    false,
                    true,
                  )}
                </ProjectValue>
                <Date> (to date) </Date>
              </InfoProject>
              <InfoProject className="other">
                <ProjectParamTitle>Paid Total:</ProjectParamTitle>
                <ProjectValue>
                  {currencySubunitToUnit(
                    projectList.get("contract_paid_total"),
                    true,
                    false,
                    true,
                  )}
                </ProjectValue>
                <Date> (to date) </Date>
              </InfoProject>
              <InfoProject className="other">
                <ProjectParamTitle>Remained Total:</ProjectParamTitle>
                <ProjectValue>
                  {currencySubunitToUnit(
                    projectList.get("contract_remained_total"),
                    true,
                    false,
                    true,
                  )}
                </ProjectValue>
                <Date> (to date) </Date>
              </InfoProject>
            </ProjectInfoWrapper>
          </ProjectItems>
        )}
      </>
    );
  }
  componentDidMount() {
    this.props.readProjects();
  }
}

const mapStateToProps = (state) => ({
  allProjects: state.getIn(["projects", "allProjects"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});

const mapDispatchToProps = (dispatch) => ({
  readProjects() {
    dispatch(actionCreatorsProjects.readProjects());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TableContainer);
