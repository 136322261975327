/* NOTE:
   If file is a pdf and the browser is firefox...
   downloadFile() and viewFile() will both do the same thing
   firefox will always follow its settings
*/

// given a response from the server containing data and possibly headers open
// this in the browser with the correct content-type and filename etc
export function downloadFile(res, fileName) {
  // if no filename is given then pull it from the content-disposition
  // headers otherwise use the given fileName
  if (!fileName) {
    fileName = res.headers["content-disposition"]
      .split(";")[1]
      .split("=")[1]
      .replaceAll('"', "");
  }

  const url = window.URL.createObjectURL(res.data);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);

  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

//  Open a pdf view tab for browsers (except for a firefox set to save pdfs)
export function viewPDF(pdf) {
  const url = window.URL.createObjectURL(pdf.data);
  const link = document.createElement("a");

  document.body.appendChild(link);

  link.href = url;
  link.setAttribute("target", "_blank"); // pdf view tab
  link.click();
  link.parentNode.removeChild(link);
}
