import React, { useState, Fragment, useRef } from "react";
import {
  reduxForm,
  formValueSelector,
  getFormValues,
  touch,
  stopSubmit,
  SubmissionError,
  clearSubmitErrors,
} from "redux-form/immutable";
import { connect } from "react-redux";

import { WrapperDetail, WrapperPayerDetail } from "../createContact/style";

import NavBottom from "../createContact/navBottom";
import InputSection from "../createContact/inputSection";
import ErrorMsg from "../../../../common/missingRequiredBanner";
import { validate } from "../shared";

let ContactForm = (props) => {
  const { handleSubmit, reset, dispatch, formValues, accountConfig } = props;

  const [errors, setErrors] = useState({});
  const errorsRef = useRef(null);
  const formRef = useRef(null);

  const requiredFieldsAndRefs = [
    {
      field: "entity_name",
      name: "Company Name",
      ref: formRef,
      missing: errors?.entity_name,
    },
    {
      field: "payerName",
      name: "Full Name",
      ref: formRef,
      missing: errors?.payerName,
    },
  ];

  if (accountConfig.getIn(["address", "street"])) {
    requiredFieldsAndRefs.push({
      field: "street_address",
      name: accountConfig.getIn(["address", "street"]),
      ref: formRef,
      missing: errors?.street_address,
    });

    requiredFieldsAndRefs.push({
      field: "state",
      name: accountConfig.getIn(["address", "state"]),
      ref: formRef,
      missing: errors?.state,
    });
  }

  const onSubmit = (e) => {
    dispatch(clearSubmitErrors("ContactForm"));
    const errors = validate(formValues, props);

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      // If there are no errors, submit the form
      dispatch(reset("ContactForm"));

      return e;
    }
    e.preventDefault();

    dispatch(touch("ContactForm", ...Object.keys(errors)));
    dispatch(stopSubmit("ContactForm", errors));

    errorsRef.current.scrollIntoView({ behavior: "smooth" });

    // Returning a rejected Promise to prevent form submission
    throw new SubmissionError(errors);
  };

  return (
    <Fragment>
      <form onSubmit={(e) => handleSubmit(onSubmit(e))} id="ContactForm">
        <WrapperDetail>
          <div>
            <span ref={errorsRef}></span>
            <ErrorMsg
              marginTop="20px"
              fieldsAndRefs={requiredFieldsAndRefs}
              noticeTitle="To proceed, please fill in the following information"
            />
          </div>
          <WrapperPayerDetail className="edit-contact">
            <InputSection
              formName={"ContactForm"}
              errors={errors}
              formRef={formRef}
            />
          </WrapperPayerDetail>
        </WrapperDetail>
        <NavBottom reset={reset} />
      </form>
    </Fragment>
  );
};

ContactForm = reduxForm({
  form: "ContactForm", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(ContactForm);

// add company and contact name form values to state
const selector = formValueSelector("ContactForm");
ContactForm = connect((state) => {
  const companyName = selector(state, "entity_name");
  const contactName = selector(state, "payerName");
  const companyCountry = selector(state, "country");
  const companyState = selector(state, "state");
  return {
    companyName,
    contactName,
    companyState,
    companyCountry,
    formValues: getFormValues("ContactForm")(state),
  };
})(ContactForm);

export default ContactForm;
