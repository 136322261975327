import { fromJS } from "immutable";
import * as constants from "./constants";

const defaultState = fromJS({
  accountInfo: {},
  loadingAccountInfo: true,
  loadingPlanInfo: true,
  allAccount: [],
  profile: {},
  saved: false,
  planInfo: [],
  planDetail: [],
  billHistory: [],
  emailSummary: {},
  saving: false,
  sendingEmail: { id: "", sending: false },
  formValue: {},
  orgType: "claiming",
  timezoneOptions: [],
  config: {},
  isFormDirty: false,
  integration: null,
  // used to disable owner role changes when there's already a pending request
  requestingOwner: false,
});

const setData = (state, action) => {
  if (action.name === "accountInfo") {
    return state.merge({
      loadingAccountInfo: false,
      accountInfo: fromJS(action.data),
    });
  } else {
    return state.set(action.name, fromJS(action.data));
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET:
      return defaultState;
    case constants.SET_DATA:
      return setData(state, action);
    default:
      return state;
  }
};
