import React, { useEffect } from "react";
import { UploadFile } from "./styled";
import UploadItem from "./uploadItem";
import { toArray } from "lodash";
import { connect } from "react-redux";

const UploadingFiles = (props) => {
  const { fileProgress, uploadFile, uploadingFileField, width } = props;
  const uploadFileAmount = fileProgress.size;

  useEffect(() => {
    const fileToUpload = fileProgress.filter(
      (file) => file.get("progress") === 0,
    );
    uploadFile(fileToUpload, uploadingFileField);
    return () => {};
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFileAmount]);

  return uploadFileAmount > 0 ? (
    <UploadFile>
      {toArray(fileProgress).map((file) => {
        let item = file[1];
        return (
          <UploadItem
            key={item.get("id")}
            file={item.get("file")}
            progress={item.get("progress")}
            width={width}
          />
        );
      })}
    </UploadFile>
  ) : null;
};
const mapStateToProps = (state) => ({
  fileProgress: state.getIn(["files", "fileProgress"]),
  uploadingFileField: state.getIn(["files", "uploadingFileField"]),
});

export default connect(mapStateToProps)(UploadingFiles);
