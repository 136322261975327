import { createGlobalStyle } from "styled-components";
import * as theme from "./theme";
import "./common/shared_style/shineout.css";
export const GlobalStyle = createGlobalStyle`

body {
	margin: 0 auto;
	 width: 100%;
	padding: 0;
	background-color: ${theme.palette.veryColdGrey};
	text-decoration: none;
	font-family: ${theme.font.mainFont};
	color:${theme.palette.gunmetal};
  }
  hr {
    border: 0;
    border-top: 1px solid ${theme.palette.wolfGrey};
    margin: 0;
    height: 1px;
  }
html, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
 caption, 
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	}

`;
