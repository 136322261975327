import * as mainTheme from "../../../theme";

export const valueFromId = (opts, id) => {
  return opts ? opts.find((o) => o.value === id) : "";
};

export const overrideColor = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: mainTheme.palette.warmLightGrey,
    primary: mainTheme.palette.veryWarmLightGrey,
    neutral50: mainTheme.palette.wolfGrey,
  },
});
