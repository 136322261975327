import { CLAIM_AND_CERT } from "./constants";

export function getOrgType(config, orgRole) {
  let orgType = "";
  if (config?.account_types) {
    orgType = Object.keys(config.account_types).find((ele) =>
      Object.keys(config.account_types[ele]).includes(orgRole),
    );
  }
  return orgType || CLAIM_AND_CERT;
}
