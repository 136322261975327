import { httpRequest } from "../base/httpRequest";

const PAYMENT_URL = `/api/payment/account/accId`;

const read_plan_payment_config = (params) =>
  httpRequest("get", `${PAYMENT_URL}/payment`, null, { params }); //config pricing_plans

const read_account_payment = () =>
  httpRequest("get", `${PAYMENT_URL}/overview`); //data

const read_account_bill_history = () =>
  httpRequest("get", `${PAYMENT_URL}/billing-history`);

const create_checkout_session = (data) =>
  httpRequest("post", `${PAYMENT_URL}/create-checkout-session`, data);

const checkout_payment_session = (sessionId) =>
  httpRequest("get", `${PAYMENT_URL}/checkout-session?sessionId=${sessionId}`);

const checkout_customer_portal = (sessionId) =>
  httpRequest("get", `${PAYMENT_URL}/customer-portal?sessionId=${sessionId}`);

export default {
  read_plan_payment_config,
  read_account_payment,
  read_account_bill_history,
  create_checkout_session,
  checkout_payment_session,
  checkout_customer_portal,
};
