import React from "react";
import { ErrorStyle, Label, Input, HelpIconButton } from "./style";

function openNZBNweb() {
  window.open("https://companies-register.companiesoffice.govt.nz/");
}

export default function RenderField({
  input,
  label,
  type,
  width,
  placeholder,
  isReadOnly,
  help,
  meta: { touched, error, warning },
}) {
  return (
    <div>
      {label !== "" ? (
        <Label>
          {label}{" "}
          {help && <HelpIconButton onClick={openNZBNweb} type="button" />}
        </Label>
      ) : (
        <div />
      )}
      <div>
        <Input
          {...input}
          readOnly={isReadOnly}
          type={type}
          placeholder={placeholder}
          width={width}
        />
        {touched && error && (
          <ErrorStyle>
            <div className="error_img" />
            {error}
          </ErrorStyle>
        )}
      </div>
    </div>
  );
}
