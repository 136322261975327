import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { ModalContent } from "./styled";

import {
  BlueWhiteBtn as Button,
  BlueOutlinedBtn as CancelButton,
} from "../button";
import Modal from "../modal/component/modalBase";
import BctiWarningModal from "./warning";
import BctiInfoModal from "./edit";

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export const BasicModal = connect(
  mapStateToProps,
  null,
)((props) => {
  const { cancelText, saveText, disabled, open, close, submit, accountConfig } =
    props;
  return (
    <Modal
      open={open}
      title={`Missing Details for ${accountConfig?.getIn(["bcti", "title"])}`}
      divider={false}
      width={"720px"}
      handleClose={close}
    >
      <ModalContent className={props.className}>{props.children}</ModalContent>
      <div>
        {saveText && (
          <Button title={saveText} onClick={submit} disabled={disabled} />
        )}
        {cancelText && (
          <CancelButton
            title={cancelText}
            margin={"0 16px 0 0"}
            onClick={close}
          />
        )}
      </div>
    </Modal>
  );
});

const BCTI = (props) => {
  const { isOpen, isAccountAdmin } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      {isAccountAdmin && <BctiInfoModal open={open} {...props} />}
      {!isAccountAdmin && <BctiWarningModal open={open} {...props} />}
    </>
  );
};

export default BCTI;
