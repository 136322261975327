import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";

import { BgWrapper, HeaderTitle } from "../../common/shared_style";
import { TopContainer, Header } from "./styled";

import Link from "../../common/linkWithQuery";
import { BlueWhiteBtn as Button } from "../../common/button";
import Loading from "../../common/loading";
import FilterBar from "../../common/select_multi_accounts";
// import Tour from "../../common/tour"; -- pl-568

import TableContainer from "./components/projectTable";
import InfoSection from "./components/infoData";
import ProjectGroup from "./components/projectGroupTable";
import TaskSummary from "./components/taskSummary";
import NoticeBar from "./components/noticeBar";

import profile from "../../utils/profile";
import { CREATE, PROJECT } from "../../utils/constants";

import { actionCreators } from "../dashboard/store";
import { actionCreators as actionCreatorsModal } from "../../common/modal";
import { actionCreators as actionCreatorHeader } from "../../common/header/store";
import { actionCreators as actionCreatorsContract } from "../contract/store";
class DashBoard extends PureComponent {
  applyFilter = () => {
    this.props.getDashboardInfo(this.props.free_trial, this.props.accountId);
  };

  render() {
    const {
      loading,
      closeNotice,
      systemNotice,
      showNotice,
      projectGroupTable,
      hasContracts,
      symbol,
      /* -- pl-568
      firstLogin,
      showSetupList,
      -- pl-815
      hasProject, 
      */
    } = this.props;
    return (
      <Fragment>
        {showNotice &&
          localStorage.getItem("close_system_notice") !== "true" && (
            <NoticeBar
              closeNotice={() => closeNotice("close_system_notice")}
              type="maintenance_notice"
              systemEvents={systemNotice}
            />
          )}
        {/* pl-815
        {!hasProject &&
          localStorage.getItem("close_project_notice") !== "true" && (
            <NoticeBar
              closeNotice={() => closeNotice("close_project_notice")}
              type="no_project_notice"
            />
          )} */}
        <BgWrapper style={{ position: "relative" }}>
          <FilterBar applyFilter={this.applyFilter} />
          {/* {firstLogin && <Tour field="DASHBOARD" showSetupList={showSetupList}/>} -- pl-568 */}
          <Header>
            <HeaderTitle style={{ float: "left" }}>Dashboard</HeaderTitle>
            {profile.checkPermission(PROJECT, CREATE) && (
              <Link to="/project">
                <Button title="Create New Project" margin="0 0 0 20px" />
              </Link>
            )}
          </Header>
          {!loading ? (
            <Fragment>
              <TopContainer>
                <TaskSummary {...this.props} />
                {hasContracts && (
                  <ProjectGroup
                    groupTable={projectGroupTable}
                    {...this.props}
                  />
                )}
                <InfoSection {...this.props} currency={symbol?.get("title")} />
              </TopContainer>
              <TableContainer className="dashboard" {...this.props} />
            </Fragment>
          ) : (
            <Loading message=" Loading Dashboard ..." />
          )}
        </BgWrapper>
      </Fragment>
    );
  }
  componentDidMount() {
    this.props.getDashboardInfo(this.props.free_trial, this.props.accountId);
  }
  componentWillUnmount() {
    this.props.resetStatus();
  }
}

const mapStateToProps = (state) => ({
  accountId: state.getIn(["headers", "profile", "payclaim_account_id"]),
  firstLogin: state.getIn(["headers", "firstLogin"]),
  free_trial: state.getIn(["headers", "free_trial"]),
  loading: state.getIn(["dashboards", "loading"]),
  claimsReceivedSummary: state.getIn(["dashboards", "claimsReceivedSummary"]),
  retentionSummary: state.getIn(["dashboards", "retentionSummary"]),
  claimSummary: state.getIn(["dashboards", "claimSummary"]),
  certSummary: state.getIn(["dashboards", "certSummary"]),
  projectType: state.getIn(["dashboards", "projectType"]),
  claimsReceivedType: state.getIn(["dashboards", "claimsReceivedType"]),
  projectList: state.getIn(["dashboards", "projectList"]),
  hasProject: state.getIn(["dashboards", "hasProject"]),
  taskList: state.getIn(["dashboards", "taskList"]),
  systemNotice: state.getIn(["dashboards", "systemNotice"]),
  showNotice: state.getIn(["dashboards", "showNotice"]),
  projectGroupTable: state.getIn(["dashboards", "projectGroupTable"]),
  projectGroupValue: state.getIn(["dashboards", "projectGroupValue"]),
  singleProjects: state.getIn(["dashboards", "singleProjects"]),
  hasContracts: state.getIn(["dashboards", "hasContracts"]),
  expandKeys: state.getIn(["dashboards", "expandKeys"]),
  aggPeriod: state.getIn(["dashboards", "aggPeriod"]),
  symbol: state.getIn(["config", "accountConfig", "currency"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
  userProfile: state.getIn(["headers", "profile"]),
});

const mapDispatch = (dispatch) => {
  return {
    getDashboardInfo(trialInfo, accountId) {
      let closeTrialNotice = localStorage.getItem("close_trial_notice");
      dispatch(actionCreators.getAllDashboard());
      trialInfo.get("status") &&
        closeTrialNotice !== "true" &&
        dispatch(
          actionCreatorsModal.showModal("trialNotice", {
            open: true,
            days: trialInfo.get("days"),
            accountId: accountId,
          }),
        );
    },
    resetStatus() {
      dispatch(actionCreators.resetStatus());
    },
    selProjectType(e) {
      dispatch(actionCreators.chooseProjectType("projectType", e.value));
    },
    selClaimsMonth(e) {
      dispatch(actionCreators.chooseMonth("claimsReceivedType", e.value));
    },
    closeNotice(field) {
      localStorage.setItem(field, JSON.stringify(true));
      if (field.includes("project")) {
        dispatch(actionCreators.setData("hasProject", true));
      } else {
        dispatch(actionCreators.setData("showNotice", false));
      }
    },
    showSetupList(triggerClose) {
      dispatch(actionCreatorHeader.getSetupProcess(triggerClose));
    },
    pinGroup(groupId, value, period) {
      dispatch(actionCreators.pinGroup(groupId, value, period));
    },
    changeGroupPeriod(value) {
      dispatch(actionCreators.readGroupTable(value));
    },
    setExpandKeys(keys) {
      dispatch(actionCreators.setGroupExpandKeys(keys));
    },
    handlePendingCert(proId, contractId, claimId, certId, contractType, isNew) {
      dispatch(
        actionCreatorsContract.handlePendingCert(
          proId,
          contractId,
          claimId,
          certId,
          isNew,
          contractType,
        ),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatch)(DashBoard);
