import styled from "styled-components";
import * as theme from "../../theme";
import pattern from "../../statics/img/slate-pattern.svg";
import MicrosoftIcon from "../../statics/img/microsoft-azure.svg";
import PayLabIcon from "../../statics/img/icon-pay-lab-arrow.svg";
import slateIcon from "../../statics/img/slate.svg";
import errorIcon from "../../statics/img/icon-information-red.svg";
import { Link } from "react-router-dom";

export const PageLayout = styled.div`
  display: grid;
  grid-template-rows: 124px auto;
  grid-row-gap: 56px;

  .pattern {
    width: 776px;
    height: 380px;
    background: url(${pattern});
    background-repeat: no-repeat;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  .slogan {
    position: fixed;
    top: 168px;
    right: 12px;
    .slate {
      background: url(${slateIcon});
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
    .construction {
      margin-top: 8px;
      writing-mode: vertical-lr;
      transform: rotate(180deg);
      font-size: 14px;
      font-weight: bold;
      line-height: 40px;
    }
  }
  .sign-up {
    width: 100%;
    height: 48px;
    position: relative;
    .icon {
      background: url(${PayLabIcon});
      width: 40px;
      height: 40px;
      background-size: contain;
      position: absolute;
      right: 16px;
      top: 4px;
    }
  }
  .full-width {
    width: 100%;
    height: 48px;
    position: relative;
  }
`;

export const Header = styled.div`
  background-color: ${theme.palette.tangerineOrange};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  .logo {
    height: 100px;
    width: 150px;
    margin-left: 140px;
  }
`;

export const Body = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 56px;
`;
export const Main = styled.div`
  height: 100%;
  min-width: 300px;
  max-width: 434px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0px 148px;
  .header {
    margin: 0;
    height: fit-content;
    color: ${theme.palette.tangerineOrange};
    letter-spacing: 0.2px;
    font-weight: bold;
    line-height: 1.05;
    font-size: 80px;
  }
  .sub-header {
    margin: 72px 0 24px 0;
    font-size: 14px;
  }
  hr {
    height: 1px;
    background-color: ${theme.palette.lightWolfGrey};
    margin: 34px 0;
  }
  .error-msg {
    margin-top: 34px;
    width: 100%;
    background-color: ${theme.palette.lightPastelPink};
    border-radius: 4px;
    padding: 16px;
    display: flex;
    .error-img {
      background: url(${errorIcon}) no-repeat;
      background-size: contain;
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }
    .error-title {
      margin-bottom: 4px;
      font-weight: bold;
      font-size: 20px;
    }
  }
  .get-start {
    width: 100%;
    height: 48px;
    margin-top: 24px;
    background-color: transparent;
    &:hover {
      background-color: rgba(66, 85, 99, 0.05);
    }
  }
`;

export const ContentContainer = styled.div`
  .step1 {
    height: 550px;
  }
  .step2 {
    height: 700px;
  }
  .step3 {
    height: 350px;
  }
`;

export const Content = styled.div`
  width: 700px;
  background-color: ${theme.palette.white};
  padding: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  justify-content: space-between;

  .hasMargin {
    margin: 26px 0 20px 0;
  }

  p.center {
    text-align: center;
    margin: 20px 0;
  }

  .countries button {
    background: ${theme.palette.white} !important;
  }

  .text-header {
    font-weight: bold;
    line-height: 25px;
  }

  .flag-contianer {
    height: 40px;
    border: solid 1px ${theme.palette.wolfGrey};
    padding: 10px 15px;

    .flag {
      width: 26px;
    }

    .change {
      color: ${theme.palette.darkBlue};
      cursor: pointer;
      text-decoration: underline;
    }
    span {
      padding-left: 15px;
      font-size: 18px;
    }
  }

  .bullets {
    font-weight: bold;
    margin-left: 25px;
    font-size: 15px;
    line-height: 18px;
  }

  form {
    width: 500px;
  }
  .title {
    margin: 0;
    height: fit-content;
    font-size: 38px;
    color: ${theme.palette.tangerineOrange};
    letter-spacing: 0.2px;
    font-weight: bold;
    &.top-header {
      line-height: 1.05;
      font-size: 80px;
    }
  }

  .input-name-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: -20px;
    .input,
    .input-field {
      width: 240px !important;
    }
  }

  .margin-reset {
    margin-bottom: 0px;
  }

  .input-field {
    margin: 20px 0;
    .input {
      width: 500px;
    }
  }

  .notice-label-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    .hasMargin {
      margin: 26px 0 20px 0;
    }
  }
  .powered-by {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.5px;
    margin-bottom: 12px;
    padding: 0px 49px;
  }
  .microsoft-image {
    background: url(${MicrosoftIcon});
    height: 24px;
    width: 170px;
  }
`;

export const ALinkStyle = styled.a`
  color: ${theme.palette.turquoiseBlue};
  cursor: pointer;
`;

export const LinkStyle = styled(Link)`
  color: ${theme.palette.turquoiseBlue};
  cursor: pointer;
`;

export const Error = styled.div`
  font-size: 12px;
  color: ${theme.palette.watermelonRed};
  display: flex;
  align-items: center;
  margin: 6px 0;
  .error_img {
    background: url(${errorIcon});
    background-size: contain;
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`;
