import * as theme from "../../theme";
import styled from "styled-components";
import delFileIcon from "../../statics/img/icon-clear-medium-grey.svg";
import inactiveEditIcon from "../../statics/img/icon-edit-inactive.svg";
import editIcon from "../../statics/img/icon-edit-medium-grey.svg";
import checkIcon from "../../statics/img/icon-check-box-check-dark-grey.svg";
import uncheckIcon from "../../statics/img/icon-check-box-un-check-dark-grey.svg";
import userIcon from "../../statics/img/icon-approval-workflow-user-dark-blue.svg";

import completeStep from "../../statics/img/icon-check-circle-green.svg";
import completeIssueStep from "../../statics/img/icon-workflow-cert-green.svg";
import notStart from "../../statics/img/icon-pending.svg";
import notIssue from "../../statics/img/icon-workflow-cert-grey.svg";
import approverIcon from "../../statics/img/icon-avatar.svg";
import infoIcon from "../../statics/img/icon-information-blue.svg";

export const WorkflowWrapper = styled.div`
  height: 82px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

export const CheckboxWrapper = styled.div`
  .checkBox {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    mask: url(${uncheckIcon}) no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-right: 12px;
    background-color: ${theme.palette.gunmetal};
    :checked {
      mask: url(${checkIcon}) no-repeat;
    }
    :disabled {
      background-color: ${theme.palette.veryMediumGrey};
      cursor: not-allowed;
    }
  }
  margin-top: 40px;
  display: flex;
  align-items: center;
  font-weight: bold;
`;

export const ApprovalList = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content {
    margin: 10px 0 8px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .date {
    color: ${theme.palette.veryMediumGrey};
  }
  .comment {
    margin: 0 0 10px 0;
  }
  .email {
    display: flex;
    padding: 8px 24px;
    align-items: flex-start;
    border-radius: 24px;
    background: ${theme.palette.veryColdGrey};
    margin-right: 12px;
    width: fit-content;
    margin-bottom: 6px;
  }
`;

export const ApprovalContainer = styled.div`
  transition:
    opacity 0.3s ease 0s,
    transform 0.2s ease 0s;
  visibility: hidden;
  opacity: 0;
  height: 0;
  border-radius: 4px;
  &.show {
    visibility: visible;
    opacity: 1;
    height: auto;
    margin-top: 40px;
  }
  .no_data-label {
    color: ${theme.palette.veryMediumGrey};
    margin: 20px 0;
  }
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  position: relative;
  .arrow {
    position: absolute;
    content: "";
    border: 18px solid transparent;
    border-bottom: 24px solid ${theme.palette.white};
    pointer-events: none;
    top: -40px;
    filter: drop-shadow(0px -2px 2px rgba(0, 0, 0, 0.09));
  }
  h1 {
    font-size: 24px;
    font-weight: bold;
  }
  h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 24px;
    &.has-margin {
      margin-top: 24px;
    }
  }
  .container {
    padding: 24px;
  }
  .avatar {
    width: 24px;
    height: 24px;
    background: url(${approverIcon}) no-repeat;
    background-size: contain;
    margin-right: 16px;
  }
  .history {
    display: flex;
    padding: 14px 0;
    border-bottom: 1px solid ${theme.palette.lightWolfGrey};

    &:last-child {
      border: none;
    }
  }
`;

export const ApproverWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;

  .name {
    font-weight: bold;
  }
  .status {
    color: ${theme.palette.veryMediumGrey};
    margin-right: 16px;
  }
  .checked {
    width: 24px;
    height: 24px;
    background: url(${completeStep}) no-repeat;
    background-size: contain;
  }
`;

export const CloseIcon = styled.div`
  height: 24px;
  width: 24px;
  background: url(${delFileIcon}) no-repeat;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  right: 40px;
  top: 24px;
`;

export const ReviewWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 40px;
  box-sizing: border-box;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  &.approver {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0px;
  }
  &.button-wrap {
    margin: 16px 0 56px 0;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
    &.claim {
      margin-top: -40px;
    }
    &.cert {
      margin-bottom: 0px;
    }
  }
`;

export const ProjectNavList = styled.div`
  width: 100%;
  line-height: 24px;
  font-size: 24px;
  font-weight: ${theme.font.mainFontWeight};
  margin-bottom: 20px;
  &.sub_title {
    font-size: 20px;
    line-height: 20px;
  }
  &.no_margin {
    margin: 0;
  }
  .imgEdit {
    height: 24px;
    width: 24px;
    background: url(${editIcon});
    background-size: contain;
    cursor: pointer;
    float: right;
    &.inactive {
      background: url(${inactiveEditIcon});
    }
  }
`;

export const ApproveStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
`;

export const BadgeWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .step_item {
    width: ${(props) => (props.isSelected ? "60px" : "40px")};
    height: ${(props) => (props.isSelected ? "60px" : "40px")};
    background-size: contain;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    z-index: 100;
    &.completed {
      background: url(${completeStep}) no-repeat;
      background-size: contain;
    }
    &.completeIssue {
      background: url(${completeIssueStep}) no-repeat;
      background-size: contain;
    }
    &.notStart {
      background: url(${notStart}) no-repeat;
      background-size: contain;
    }
    &.notIssue {
      background: url(${notIssue}) no-repeat;
      background-size: contain;
    }
  }
  .label {
    font-size: 16px;
    color: ${theme.palette.gunmetal};
    margin-top: 6px;
    font-weight: ${theme.font.mainFontWeight};
    &.grey {
      color: ${theme.palette.wolfGrey};
    }
  }
`;

export const StepRow = styled.hr`
  height: 4px;
  background-color: ${theme.palette.brightGreen};
  flex: 1 1 auto;
  position: absolute;
  top: calc(50% - 10px);
  right: calc(50% + 20px);
  left: calc(-50% + 20px);
  border: none;
  &.grey-dot {
    background-color: ${theme.palette.white};
    border-top: 4px dashed #c6ccd0;
  }
`;

export const DialogContent = styled.div`
  padding: 30px 40px;
  .sub-comment {
    color: ${theme.palette.veryMediumGrey};
    margin-top: 40px;
  }
  .sub-label {
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
    .personIcon {
      background: url(${userIcon}) no-repeat;
      background-size: contain;
      display: block;
      height: 24px;
      width: 24px;
    }
    .sub-title {
      margin-left: 10px;
      font-size: 20px;
      font-weight: ${theme.font.mainFontWeight};
    }
  }
  .comment-field {
    padding: 20px;
    width: 720px;
    height: 100px;
    &.has-error {
      border-color: ${theme.palette.watermelonRed};
    }
  }
  .sub-header {
    font-size: 20px;
    font-weight: ${theme.font.mainFontWeight};
    margin-bottom: 24px;
  }
  .warning-wrap {
    display: flex;
    padding: 16px;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: ${theme.palette.lightPastelBlue};
    margin: 24px 0;
    .info-icon {
      width: 24px;
      height: 24px;
      background: url(${infoIcon}) no-repeat;
      margin-right: 16px;
    }
  }
  .avatar {
    width: 24px;
    height: 24px;
    background: url(${approverIcon}) no-repeat;
    background-size: contain;
    margin-right: 16px;
  }
`;
