import { fromJS } from "immutable";
import * as constants from "./constants";
import { getCertDueType } from "../../../utils/checkDueType";

const defaultState = fromJS({
  projectType: "current",
  table: [],
  loading: true,
  currentTable: [],
  completedTable: [],
  archivedTable: [],
  selDate: new Date(),
  certSummary: [],
});
const setTableContent = (state, action) => {
  let type = action.payload;
  switch (type) {
    case "current":
      return state.merge({
        table: state.get("currentTable"),
        projectType: type,
      });
    case "completed":
      return state.merge({
        table: state.get("completedTable"),
        projectType: type,
      });
    case "archived":
      return state.merge({
        table: state.get("archivedTable"),
        projectType: type,
      });
    default:
      return;
  }
};

export const setProjectTable = (state, action) => {
  let allTable = action.payload;
  var current_table = [],
    completed_table = [],
    archived_table = [];
  if (allTable !== null) {
    allTable.map((item) => {
      let type = item.contract_status;
      item.over_due_type = getCertDueType(
        item.cert_due_num,
        item.unclaimed_days,
      );
      switch (type) {
        case "current":
          return current_table.push(item);
        case "completed":
          return completed_table.push(item);
        case "archived":
          return archived_table.push(item);
        default:
          return null;
      }
    });
  }
  return state.merge(
    fromJS({
      loading: false,
      currentTable: current_table,
      completedTable: completed_table,
      archivedTable: archived_table,
    }),
  );
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_PAGE_STATUS:
      return state.set("loading", true);
    case constants.SET_ALL_PROJECT_LIST:
      return setProjectTable(state, action);
    case constants.SET_PROJECT_TYPE:
      return setTableContent(state, action);
    case constants.SET_DATE:
      return state.set("selDate", action.payload);
    case constants.SET_CERT_SUMMARY:
      return state.set("certSummary", fromJS(action.payload));
    case constants.SET_DATA:
      return state.set(action.name, action.value);
    default:
      return state;
  }
};
