import React, { Component } from "react";
import { connect } from "react-redux";
import Link from "../../../../common/linkWithQuery";

import { BgWrapper, HeaderTitle } from "../../../../common/shared_style";
import { DetailBox, FormWrapper } from "../users/add/style";
import { BottomWrapper } from "../../component/style";
import {
  BlueOutlinedBtn as Cancel,
  GreenWhiteBtn as Save,
} from "../../../../common/button";
import Menu from "../../../../common/breadcrumbs";
import Input from "../../../../common/form";
import { getWeekList, getHoursList } from "../../../../utils/dateTimeHelper";

import { actionCreators } from "../../store";
class index extends Component {
  render() {
    const { emailSummary, changeEmailPeriod, updateInfo } = this.props;

    let day_time = emailSummary?.get("day_time");
    let week_time = emailSummary?.get("week_time");

    if (day_time === 0 || day_time === 12) {
      day_time += 12;
    }

    if (week_time === 0 || week_time === 12) {
      week_time += 12;
    }

    return (
      <BgWrapper>
        <Menu
          link={[{ link: "/manage-account", text: "Manage Account" }]}
          content="Email Summary Options"
        />
        <HeaderTitle>Email Summary Options</HeaderTitle>
        <FormWrapper>
          <DetailBox>
            <div className="content">
              <div className="title">Daily Emails</div>
              <Input
                name="day_time"
                field="dropdown"
                label="Time of the Day"
                noRadius
                value={day_time}
                options={getHoursList()}
                onChange={(e) =>
                  changeEmailPeriod(emailSummary, "day_time", e.value)
                }
              />
              <div className="title hasMargin">Weekly Emails</div>
              <Input
                name="week"
                field="dropdown"
                label="Day of the Week"
                noRadius
                value={emailSummary.get("week")}
                options={getWeekList()}
                onChange={(e) =>
                  changeEmailPeriod(emailSummary, "week", e.value)
                }
              />
              <Input
                name="week_time"
                field="dropdown"
                label="Time of the Day"
                noRadius
                value={week_time}
                options={getHoursList()}
                onChange={(e) =>
                  changeEmailPeriod(emailSummary, "week_time", e.value)
                }
              />
            </div>
          </DetailBox>
          <BottomWrapper>
            <Link to={"/manage-account"}>
              <Cancel title="CANCEL" margin="0 30px 0 0" padding="10px 34px" />
            </Link>
            <Save
              title="SAVE"
              padding="10px 41px"
              onClick={() => updateInfo(emailSummary)}
            />
          </BottomWrapper>
        </FormWrapper>
      </BgWrapper>
    );
  }
  componentDidMount() {
    this.props.getAccountInfo();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.accountTimezone !== prevProps.accountTimezone &&
      this.props.accountTimezone
    ) {
      this.props.getInitialInfo();
    }
  }
  componentWillUnmount() {
    this.props.reset();
  }
}

const mapStateToProps = (state) => ({
  emailSummary: state.getIn(["manageAccount", "emailSummary"]),
  timezone: state.getIn([
    "config",
    "accountConfig",
    "default_timezone",
    "value",
  ]),
  accountTimezone: state.getIn(["manageAccount", "accountInfo", "time_zone"]),
});

const mapDispatchToProps = (dispatch) => ({
  getAccountInfo() {
    dispatch(actionCreators.getAccountInfo());
  },
  getInitialInfo() {
    dispatch(actionCreators.getEmailSummary());
  },
  updateInfo(data) {
    dispatch(actionCreators.updateEmailSummary(data));
  },
  reset() {
    dispatch(actionCreators.reset());
  },
  changeEmailPeriod(data, name, value) {
    dispatch(actionCreators.changeEmailPeriod(data, name, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
