import React, { useState, useEffect, Fragment } from "react";
import { fromJS } from "immutable";

import {
  ContractDetailWrapper,
  SettingWrapper,
  RequiredWrap,
  PrefilledTag,
} from "../styled";
import { SectionIcon } from "pages/project/styled";
import {
  BlueWhiteBtn as Button,
  BlueOutlinedBtn as EditBtn,
} from "common/button";

import { calculateMonthPosition } from "utils/dateScheduleHelper";
import DateScheduleBar from "pages/project/components/dateSchedule/dateScheduleBar";
import PaymentDatesModal from "pages/project/components/dateSchedule/paymentDatesModal";
import { DEFAULT_DUE_DATE_TERMS } from "utils/constants";

export default function DateSchedule(props) {
  const {
    readOnly,
    calculateExample,
    dueDates,
    contractInfo,
    submit,
    hideTag,
    prefilledSeen,
    accountConfig,
    options,
  } = props;
  const [required, setRequired] = useState(true);
  const [monthPosition, setMonthPosition] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState({});

  useEffect(() => {
    const terms = contractInfo?.get("payment_terms");
    const isEmpty =
      !terms?.get("claim_due_term") ||
      !terms?.get("cert_due_term") ||
      !terms?.get("payment_due_term");

    setRequired(isEmpty);

    if (!isEmpty) {
      let initialTerms = {
        claim: {
          days: terms.get("claim_due_days"),
          terms: terms.get("claim_due_term"),
        },
        cert: {
          days: terms.get("cert_due_days"),
          terms: terms.get("cert_due_term"),
        },
        pay: {
          days: terms.get("payment_due_days"),
          terms: terms.get("payment_due_term"),
        },
      };

      setMonthPosition(
        calculateMonthPosition(
          initialTerms["claim"],
          initialTerms["cert"],
          initialTerms["pay"],
        ),
      );
      setPaymentTerms(terms);
    } else {
      // payment dates modal requires the terms in this form
      const defaultDueDates = accountConfig?.get("default_due_dates");
      const defaultTerms = fromJS({
        claim_due_days:
          defaultDueDates?.getIn(["claim_due_days", "days"]) ??
          DEFAULT_DUE_DATE_TERMS.claim.days,
        claim_due_term:
          defaultDueDates?.getIn(["claim_due_days", "terms"]) ??
          DEFAULT_DUE_DATE_TERMS.claim.terms,
        cert_due_days:
          defaultDueDates?.getIn(["cert_due_days", "days"]) ??
          DEFAULT_DUE_DATE_TERMS.cert.days,
        cert_due_term:
          defaultDueDates?.getIn(["cert_due_days", "terms"]) ??
          DEFAULT_DUE_DATE_TERMS.cert.terms,
        payment_due_days:
          defaultDueDates?.getIn(["payment_due_days", "days"]) ??
          DEFAULT_DUE_DATE_TERMS.pay.days,
        payment_due_term:
          defaultDueDates?.getIn(["payment_due_days", "terms"]) ??
          DEFAULT_DUE_DATE_TERMS.pay.terms,
      });
      setPaymentTerms(defaultTerms);
    }
  }, [contractInfo, accountConfig]);

  const handleSubmit = (terms) => {
    setShowModal(false);
    setRequired(false);
    setMonthPosition(
      calculateMonthPosition(terms.claim, terms.cert, terms.pay),
    );
    submit(terms);
    hideTag();
  };

  if (readOnly && required) {
    return null;
  }

  if (readOnly && !required) {
    return (
      <ContractDetailWrapper className={"setting-page"} id="nav-dateSchedule">
        <SettingWrapper>
          <div className="section-title">
            <div className="flex">
              <SectionIcon img="date" />
              <span>Date Schedule</span>
            </div>
          </div>
          <div className={`center no-margin`}>
            <DateScheduleBar monthPosition={monthPosition} options={options} />
          </div>
        </SettingWrapper>
      </ContractDetailWrapper>
    );
  }

  return (
    <ContractDetailWrapper
      className={`setting-page ` + (required ? `required` : ``)}
      id="nav-dateSchedule"
    >
      <SettingWrapper border="none">
        <div className="section-title">
          <div className="flex">
            <SectionIcon img="date" />
            <span>Date Schedule</span>
            {prefilledSeen &&
              !prefilledSeen.get("dateSchedule") &&
              !required && <PrefilledTag>Prefilled From Project</PrefilledTag>}
          </div>
          {required ? (
            <RequiredWrap>
              <label>Required</label>
              <div className="required-icon" />
            </RequiredWrap>
          ) : (
            !required && (
              <EditBtn title="Edit" onClick={() => setShowModal(true)} />
            )
          )}
        </div>
        <div className={`center ` + (required ? `` : `no-margin`)}>
          {required ? (
            <Fragment>
              Set up date for {accountConfig?.getIn(["claim", "noun"])}s,
              Certificates and Payments. You can use the same dates as set up in
              the project if needed.
            </Fragment>
          ) : (
            <DateScheduleBar monthPosition={monthPosition} options={options} />
          )}
        </div>
        {required && (
          <div>
            <Button title="Set Up Dates" onClick={() => setShowModal(true)} />
          </div>
        )}

        <PaymentDatesModal
          title="Payment Due Dates"
          open={showModal}
          initial={paymentTerms}
          options={options}
          close={() => setShowModal(false)}
          calculateMonthPosition={calculateMonthPosition}
          handleSubmit={handleSubmit}
          calculateExample={calculateExample}
          dueDates={dueDates}
          noInitial={required} // comes from checking if there are initial payment terms coming from the server (true if there are none)
          accountConfig={accountConfig}
        />
      </SettingWrapper>
    </ContractDetailWrapper>
  );
}
