import React, { PureComponent } from "react";
import { ClaimTableWrapper, NoticeIconTransparent, NetClaim } from "./styled";
import { withRouter } from "react-router-dom";

import BootstrapTooltip from "../toolTip";
import Tooltip from "../toolTip/toolTipTable";
import CommentList from "./comment_table";
import { NumericInput, NumericInputGroup } from "./numeric_input_comp";
import TableComponent from "./basic_table";

import { removeTrailingZeros, checkIsParent } from "../../utils/helper";
import { roundNearest } from "../../utils/numeralHelper";
import {
  currencySubunitToUnit,
  convertToCurrencyUnit,
} from "../../utils/currencyHelper";
import {
  calculateNetClaim,
  dropDecimalColumns,
  headerFormatter,
} from "../../utils/tableFormatHelper";
import profile from "../../utils/profile";
import { CONTRACT, EDIT, LUMP, VIEW } from "../../utils/constants";

import API from "../../server";
import {
  ProvisionalDescription,
  getExpandProvisionalParents,
} from "../lineitem/sharedComponent";

class Table extends PureComponent {
  constructor() {
    super();
    this.state = {
      table: [],
      expands: [],
      allowEditContract: true,
      allowViewContract: false,
    };
  }

  getClaimHistory = (contractId, itemId) => {
    //read last three months claim history for lineitem
    API.read_three_month_claim_history(contractId, itemId)
      .then((res) => res.data)
      .then((res) => res.data.data.records)
      .then((res) => {
        return !res
          ? this.setState({
              table: [],
            })
          : this.setState({
              table: res,
            });
      });
  };

  inputValue = (itemId, list, field) => {
    let percent = 0;
    let value = 0;
    let claim_qty = 0;
    var obj = list.find(function (obj) {
      return obj.get("lineitem_id") === itemId;
    });

    if (obj) {
      percent = obj.get("percent");
      value = obj.get("value");
      claim_qty = obj.get("claim_qty");
      if (claim_qty === 0) {
        claim_qty = value / obj?.get("item_rate");
      }
    }
    if (field === "percent") {
      return removeTrailingZeros(percent);
    }
    if (field === "qty") {
      return claim_qty;
    } else {
      return (value / 100).toFixed(2);
    }
  };

  saveLineItemType = (itemId, type) => {
    const { proId, contractId } = this.props.match.params;
    API.update_one_lineitem(proId, contractId, itemId, { claim_option: type });
  };

  isTreeExpanded = (id) => {
    return this.state.expands.includes(id);
  };

  expandProvisionalParent = (line) => {
    let expanded = getExpandProvisionalParents(line, this.state.expands);
    this.setState({ expands: [...expanded] });
  };

  render() {
    const { expands, allowEditContract, allowViewContract } = this.state;
    const {
      tableContent,
      totalTable,
      inputClaim,
      claimList,
      calculateClaimTotal,
      contractId,
      calNetClaim,
      updateComment,
      title,
      claimId,
      accountConfig,
    } = this.props;
    const claimWord = accountConfig?.getIn(["claim", "noun"]);
    let dropDecimal = dropDecimalColumns(tableContent.toJS(), [
      "quantity",
      "rate",
      "total",
      "claimed_up_to_date_total",
    ]);
    const showCurrency = !accountConfig?.get("enable_header_currency");
    const columns_dev = [
      {
        title: "Ref",
        className: "align_left lineitem-ref",
        width: 81,
        render: "label",
      },
      {
        title: "Description",
        className: "align_left",
        treeColumnsName: "childitems",
        treeIndent: 10,
        width: 312,
        render: (d) => (
          <div>
            <ProvisionalDescription
              data={d}
              isUpdateExpanded={() => {
                return false;
              }}
              isTreeExpanded={this.isTreeExpanded}
              expandProvisionalParent={this.expandProvisionalParent}
            />
          </div>
        ),
      },
      {
        title: "Qty",
        width: 154,
        render: (d) => {
          //quick fix for when read lineitem from contract api, quantity return in cent
          //read from claim api, quantity return in dollar
          if (d.lineitem_options === LUMP || d.childitems?.length > 0) {
            return <div className="grey-dash"></div>;
          } else {
            if (!claimId) {
              return currencySubunitToUnit(
                dropDecimal["quantity"] ? roundNearest(d.quantity) : d.quantity,
                false,
              );
            } else {
              return convertToCurrencyUnit(
                dropDecimal["quantity"] ? roundNearest(d.quantity) : d.quantity,
                false,
              );
            }
          }
        },
      },
      {
        title: "Unit",
        width: 86,
        render: (d) => {
          if (d.lineitem_options === LUMP || d.childitems?.length > 0) {
            return <div className="grey-dash"></div>;
          } else {
            return d.unit;
          }
        },
      },
      {
        title: headerFormatter(
          "Rate",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        width: 136,
        render: (d) => {
          if (d.lineitem_options === LUMP || d.childitems?.length > 0) {
            return <div className="grey-dash"></div>;
          } else {
            return currencySubunitToUnit(
              dropDecimal["rate"] ? roundNearest(d.rate) : d.rate,
              showCurrency,
            );
          }
        },
      },
      {
        title: headerFormatter(
          "Total",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        width: 154,
        render: (d) =>
          currencySubunitToUnit(
            dropDecimal["total"] ? roundNearest(d.total) : d.total,
            showCurrency,
          ),
      },
      {
        title: headerFormatter(
          "Value",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        className: "th_align_left group_input group_header",
        width: 290,
        render: (d) => {
          return (
            <div style={{ display: "flex" }} className="claimValue">
              {d.lineitem_options === LUMP || checkIsParent(d) ? (
                <NumericInput
                  isParent={checkIsParent(d)}
                  lineItem={d}
                  onChange={(id, field, value, onLoad, fromPrctQty) =>
                    inputClaim(id, field, value, onLoad, fromPrctQty)
                  }
                  displayValue={(id, field) =>
                    this.inputValue(id, claimList, field)
                  }
                />
              ) : (
                <NumericInputGroup
                  isParent={checkIsParent(d)}
                  lineItem={d}
                  defaultOption={d?.claim_option}
                  onChange={(id, field, value, onLoad, fromPrctQty) =>
                    inputClaim(id, field, value, onLoad, fromPrctQty)
                  }
                  displayValue={(id, field) =>
                    this.inputValue(id, claimList, field)
                  }
                  saveLineItemType={(id, type) =>
                    this.saveLineItemType(id, type)
                  }
                />
              )}
              <Tooltip
                status="lineitem"
                getTable={() => this.getClaimHistory(contractId, d.id)}
                title={this.state.table}
                id={d.id}
                isParent={checkIsParent(d)}
                accountConfig={accountConfig}
              />
            </div>
          );
        },
      },
      {
        title: (
          <NetClaim>
            <span
              className={
                accountConfig?.get("enable_header_currency") ? "center" : ""
              }
            >
              {headerFormatter(
                "Net",
                accountConfig?.getIn(["currency", "code"]),
                accountConfig?.get("enable_header_currency"),
              )}
            </span>
            <BootstrapTooltip
              title={`This ${claimWord?.toLowerCase()} less previous ${claimWord?.toLowerCase()}.`}
              placement="bottom"
            >
              <NoticeIconTransparent />
            </BootstrapTooltip>
          </NetClaim>
        ),
        className: "net-claim",
        width: 154,
        render: (d) => {
          return (
            <div className="inlineDiv">
              <div>
                {calculateNetClaim(
                  d.id,
                  claimList.toJS(),
                  d.claimed_up_to_date_total,
                  dropDecimal["claimed_up_to_date_total"],
                  !accountConfig?.get("enable_header_currency"),
                )}
              </div>
              {claimId && (allowEditContract || allowViewContract) && (
                <CommentList
                  data={d}
                  expands={expands}
                  onTreeExpand={(key) => {
                    return this.setState({
                      expands: [...this.state.expands, key],
                    });
                  }}
                  updateComment={updateComment}
                  type={"claim"}
                  viewOnly={allowViewContract}
                />
              )}
            </div>
          );
        },
      },
    ];

    return (
      <ClaimTableWrapper>
        <TableComponent
          onTreeExpand={(keys) => this.setState({ expands: keys })}
          treeExpandKeys={expands}
          tableContent={tableContent.toJS()}
          tableColumns={columns_dev}
          tableCaption={title}
          hasSort
          hasEnhancedTableHeader
          hasEnhancedTableFooter
        >
          <table className="header_table">
            <tbody>
              <tr>
                <td style={{ width: 921 }} />
                <td className="label-claim">{claimWord?.toUpperCase()}</td>
                <td style={{ width: 158 }} />
              </tr>
            </tbody>
          </table>

          <table>
            <tfoot>
              <tr>
                <td className="empty" />
                <td className="title">Sub Total</td>
                <td className="rate">
                  {convertToCurrencyUnit(totalTable, showCurrency)}
                </td>
                <td className="claim">
                  {convertToCurrencyUnit(calculateClaimTotal, showCurrency)}
                </td>
                <td className="net">
                  {" "}
                  {convertToCurrencyUnit(calNetClaim, showCurrency)}
                </td>
              </tr>
            </tfoot>
          </table>
        </TableComponent>
      </ClaimTableWrapper>
    );
  }
  componentDidMount() {
    const { proId, contractId } = this.props.match.params;
    profile.checkPermission(CONTRACT, EDIT, proId, contractId).then((res) => {
      this.setState({ allowEditContract: res });
    });
    profile.checkPermission(CONTRACT, VIEW, proId, contractId).then((res) => {
      this.setState({ allowViewContract: res });
    });
  }
}

export default withRouter(Table);
