import styled from "styled-components";
import * as theme from "../../theme";
import editIcon from "../../statics/img/icon-edit-medium-grey.svg";
import certIconGreen from "../../statics/img/icon-workflow-cert-green.svg";
import avatarIcon from "../../statics/img/icon-avatar.svg";
import saveIcon from "../../statics/img/icon-tick-white.svg";
import closeIcon from "../../statics/img/icon-clear-medium-grey.svg";
import delIcon from "../../statics/img/icon-delete-medium-grey.svg";
import addIcon from "../../statics/img/icon-add-white.svg";
import lockIcon from "../../statics/img/icon-lock.svg";
import emailIcon from "../../statics/img/icon-email.svg";

import { IconButton } from "../../common/button";

export const Container = styled.div`
  width: 960px;
  height: auto;
  margin: 0 auto;
  .page-breadcrumbs {
    width: 960px;
    margin: 24px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .page-breadcrumbs-label {
      margin: 0 !important;
    }
    button {
      min-width: 225px;
    }
  }
  h1 {
    font-weight: bold;
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 8px;
  }
  p {
    line-height: 20px;
  }
  .page-foot {
    display: flex;
    justify-content: space-between;
    margin: 56px 0;
  }
  .lock-input {
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 8px;
      font-weight: bold;
    }
    .form_input {
      background-color: ${theme.palette.greyWhite};
      border: 1px solid ${theme.palette.wolfGrey};
      border-radius: 4px;
      display: flex;
      align-items: center;
      padding: 10px 12px;
      box-sizing: border-box;
      width: 364px;
      input {
        font-size: 16px;
        font-weight: ${theme.font.normalFontWeight};
        font-family: ${theme.font.mainFont};
        border-radius: 4px;
        border: none;
        outline: none;
        &:disabled {
          color: ${theme.palette.veryMediumGrey};
          background-color: ${theme.palette.greyWhite};
        }
      }
    }
    .lock {
      height: 24px;
      width: 24px;
      background: url(${lockIcon}) no-repeat center;
    }
  }
`;

export const WorkflowContainer = styled.div`
  .button-add {
    float: left;
    margin-left: 50px;
    width: 196px;
  }
  .step {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    .circle {
      background: ${theme.palette.brightGreen};
      border-radius: 100%;
      width: 40px;
      height: 40px;
      display: inline-block;
      position: absolute;
      top: 40%;
    }
    .line {
      left: 20px;
      height: calc(100% + 40px);
      position: absolute;
      border-left: 2px solid ${theme.palette.brightGreen};
      z-index: -1;
    }
    &.action .circle {
      background-color: transparent;
    }
  }
  .v-stepper {
    position: relative;
  }

  .step:last-child .circle {
    visibility: visible;
    height: 40px;
    width: 40px;
    background: url(${certIconGreen}) no-repeat;
    background-size: contain;
  }

  .step:last-child .line {
    height: calc(50% + 40px);
    bottom: 50%;
  }
  .step:first-child .line {
    height: calc(50% + 40px);
    top: 50%;
  }
  .step {
    .content {
      width: inherit;
      margin-left: 40px;
    }
  }
  .step .step-name {
    margin-left: 50px;
  }
  .step .approver {
    li {
      list-style: none;
      margin-bottom: 18px;
      display: flex;
      align-items: center;
      :last-child {
        margin-bottom: 0;
      }
    }
    .person-avatar {
      width: 20px;
      height: 20px;
      background: url(${avatarIcon}) no-repeat center;
      float: left;
      margin-right: 18px;
    }
  }

  .button {
    height: 40px;
    width: 40px;
    border-radius: 4px;
    margin: 0 4px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    &.save {
      background-color: ${theme.palette.brightGreen};
      background-image: url(${saveIcon});
    }
    &.cancel {
      background-color: ${theme.palette.veryWarmLightGrey};
      background-image: url(${closeIcon});
      border: 1px solid ${theme.palette.wolfGrey};
    }
  }
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 864px;
  height: auto;
  background: ${theme.palette.white};
  border-radius: 4px;

  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25));

  h1 {
    font-size: 24px;
    font-weight: ${theme.font.mainFontWeight};
    margin-bottom: 0;
    font-size: 24px;
    font-weight: bold;
  }
  .header-bar {
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .edit {
      width: 24px;
      height: 24px;
      background: url(${editIcon}) no-repeat center;
      margin-left: 12px;
      cursor: pointer;
      &.disabled {
        cursor: not-allowed;
        opacity: 50%;
      }
    }
    input {
      outline: none;
      border-radius: 4px;
      font-weight: bold;
    }
  }
  .button-wrap {
    background-color: ${theme.palette.white};
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  .content-wrapper {
    padding: 0 40px 40px 40px;
    input {
      width: 364px;
    }
  }
`;

export const DelButton = styled(IconButton)`
  background: url(${delIcon}) no-repeat;
  &.has-margin {
    margin-top: 22px;
  }
`;

export const EmailBlueButton = styled.div`
  border: 0;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-family: ${theme.font.mainFont};
  font-weight: ${theme.font.mainFontWeight};
  letter-spacing: 0.2px;
  height: 40px;
  padding: 10px 24px;
  color: ${theme.palette.gunmetal};
  background-color: ${theme.palette.white};
  border: 1px solid ${theme.palette.gunmetal};
  margin-left: auto;
  display: flex;
  align-items: center;
  .email-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background: url(${emailIcon}) no-repeat;
  }
  .number {
    display: flex;
    width: fit-content;
    min-width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background: ${theme.palette.gunmetal};
    color: ${theme.palette.white};
    font-size: 12px;
    margin-left: 10px;
  }
  &:hover {
    background-color: rgba(66, 85, 99, 0.05);
  }
  &:active {
    background-color: rgba(6, 85, 99, 0.2);
  }
  &:disabled {
    border: 1px solid ${theme.palette.wolfGrey};
    color: ${theme.palette.wolfGrey};
    background-color: ${theme.palette.white};
  }
`;

export const ApproverWrapper = styled.div`
  li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    label {
      font-weight: bold;
      line-height: 20px;
    }
  }
  .add-approver-btn {
    .add-icon {
      margin-right: 16px;
      width: 24px;
      height: 24px;
      mask: url(${addIcon}) no-repeat center;
      background-color: ${theme.palette.gunmetal};
      float: left;
      &.disabled {
        opacity: 50%;
      }
    }
    margin-top: 20px;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
    line-height: 24px;
    padding: 8px 24px;
    color: ${theme.palette.gunmetal};
    background-color: ${theme.palette.white};
    border: 1px solid ${theme.palette.gunmetal};
    font-weight: bold;
    &:hover {
      background-color: rgba(66, 85, 99, 0.05);
    }
    &:active {
      background-color: rgba(6, 85, 99, 0.2);
    }
    &:disabled {
      cursor: not-allowed;
      pointer-events: auto;
      border: 1px solid ${theme.palette.wolfGrey};
      color: ${theme.palette.wolfGrey};
      background-color: ${theme.palette.white};
    }
  }
`;

export const ModalContent = styled.div`
  width: 640px;
  height: auto;
  padding: 0 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  h3 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 24px;
  }
  li {
    list-style: none;
    margin: 6px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .email-list {
      display: flex;
      align-items: center;

      height: 44px;
      padding: 10px 16px;
      align-items: flex-start;
      border-radius: 4px;
      border: 1px solid ${theme.palette.lightWolfGrey};
      &.has-error {
        border: 1px solid ${theme.palette.watermelonRed};
      }
      .email-icon {
        width: 24px;
        height: 24px;
        margin-right: 16px;
        background: url(${emailIcon}) no-repeat;
      }
      input {
        width: 440px;
        border: none;
        outline: none;
        height: 24px;
        padding: 0;
      }
    }
  }
  .delete-icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    background: url(${delIcon}) no-repeat;
    margin-left: 24px;
    cursor: pointer;
  }
  .error-msg {
    margin: 4px 0 6px 0;
    font-size: 12px;
    color: ${theme.palette.watermelonRed};
  }
`;
