import API from "../../../server";
import * as constants from "./constants";
import history from "../../../utils/history";
import { actionCreators as actionCreatorsModal } from "../../../common/modal";
import {
  ADMIN_ROLE,
  CONTRACT_ADMIN,
  OWNER_ROLE,
  PROJECT_ADMIN,
} from "../../../utils/constants";

const setData = (name, value, immutable = true) => ({
  type: constants.SET_DATA,
  name,
  value,
  immutable,
});

export const readUsers = (proId, contractId) => {
  return (dispatch) => {
    let userList = [];
    let adminsFilter = [ADMIN_ROLE, OWNER_ROLE, PROJECT_ADMIN];
    // get users from either read_contract_member or read_project_member depending on where
    // this is being called (project or contract level)
    if (contractId) {
      adminsFilter.push(CONTRACT_ADMIN);
      API.read_contract_member(proId, contractId)
        .then((res) => res.data.data.members)
        .then((res) => {
          res.map((user) => {
            return userList.push({
              label: user.name,
              value: user.id,
              role: user.role_id,
            });
          });
          userList = userList.filter((u) => {
            return adminsFilter.includes(u.role);
          });
          dispatch(setData("userList", userList, false));
        });
    } else {
      API.read_project_member(proId)
        .then((res) => res.data.data.members)
        .then((res) => {
          res.map((user) => {
            return userList.push({
              label: user.name,
              value: user.id,
              role: user.role_id,
            });
          });
          userList = userList.filter((u) => {
            return adminsFilter.includes(u.role);
          });
          dispatch(setData("userList", userList, false));
        });
    }
  };
};

export const readWorkflow = (proId, contractId, type) => {
  return (dispatch) => {
    dispatch(setData("loading", true));
    if (contractId) {
      //Reading contract and its workflow
      API.read_contract(proId, contractId)
        .then((res) => res.data.data)
        .then((res) => {
          dispatch(setData("contractName", res.data.name));
          dispatch(setData("basicInfo", res.data));
          dispatch(setWorkflow(res, type));
        });

      API.read_one_project_group(proId)
        .then((res) => res.data.data)
        .then((res) => {
          dispatch(setData("projectName", res.data.name));
        });
    } else {
      // reading project workflow
      API.read_one_project_group(proId)
        .then((res) => res.data.data)
        .then((res) => {
          dispatch(setData("projectName", res.data.name));
          dispatch(setWorkflow(res, type));
          dispatch(setData("basicInfo", res.data));
        });
    }
  };
};

const setWorkflow = (res, type) => {
  return (dispatch) => {
    let workflows =
      type === "claim"
        ? res.data.claim_workflow_steps
        : res.data.cert_workflow_steps;
    // mapping the user id to ids for workflow steps.
    let data = workflows.map((step) => {
      if (step.approvers.length !== 0) {
        step.approvers.map((ele) => {
          ele.id = ele.user.id;
          return ele;
        });
      }
      return step;
    });
    dispatch(setData("stepInfo", data));
    dispatch(setData("loading", false));
  };
};

export const upsertWorkflow = (proId, contractId, type) => {
  return (dispatch, getState) => {
    let data = getState().getIn(["workflow", "stepInfo"]).toJS(),
      basicInfo = getState().getIn(["workflow", "basicInfo"]);
    let missingApprovers = data.some(
      (ele) =>
        ele.approvers.length === 0 ||
        !ele.approvers.some(
          (person) => person.required && typeof person.id === "string",
        ),
    );
    if (missingApprovers) {
      return dispatch(
        actionCreatorsModal.showModal("alert", {
          open: true,
          title: "Error!",
          message:
            "There must be at least 1 required approver for each approval workflow step",
        }),
      );
    }

    let dataSend = { steps: [] };
    data.map((ele) => {
      dataSend.steps.push({
        step: ele.step,
        name: ele.name,
        approvers: ele.approvers
          .filter((item) => {
            return isNaN(item.id); //ignore empty approver lines
          })
          .map((person) => ({
            required: person.required,
            user_id: person.id,
            step: ele.step,
          })),
      });
      return ele;
    });

    if (contractId) {
      API.update_contract_workflow(proId, contractId, type, dataSend).then(
        (res) => {
          if (basicInfo.get("status") === "draft") {
            history.push(`/contract/${proId}/${type}/${contractId}`);
          } else {
            history.push(`/contract/${proId}/${type}/${contractId}/settings`);
          }
        },
      );
    } else {
      API.update_project_workflow(proId, type, dataSend).then((res) => {
        history.push(`/project/view/${proId}/settings`);
      });
    }
  };
};

export const addOneStep = () => {
  return (dispatch, getState) => {
    let info = getState().getIn(["workflow", "stepInfo"]).toJS();
    let step = {
      approvers: [],
      id: Date.now(),
      name: "Approval Step " + (info.length + 1),
      step: info.length + 1,
    };
    dispatch(setData("stepInfo", info.concat(step)));
  };
};

export const deleteOneStep = (stepIndex) => {
  return (dispatch, getState) => {
    let info = getState().getIn(["workflow", "stepInfo"]).toJS();
    let data = info.filter((item) => item.step !== stepIndex);
    data.map((item) => {
      if (item.step > stepIndex) {
        item.step--;
      }
      return item;
    });
    dispatch(setData("stepInfo", data));
  };
};

export const changeStepName = (stepId, name) => {
  return (dispatch, getState) => {
    let info = getState().getIn(["workflow", "stepInfo"]).toJS();
    info.map((item) => {
      if (item.id === stepId) {
        item.name = name;
      }
      return item;
    });
    dispatch(setData("stepInfo", info));
  };
};

export const addStepApprover = (stepId) => {
  return (dispatch, getState) => {
    let info = getState().getIn(["workflow", "stepInfo"]).toJS();
    info.map((item) => {
      if (item.id === stepId) {
        item.approvers = item.approvers.concat({
          required: true,
          id: Date.now(),
        });
      }
      return item;
    });
    dispatch(setData("stepInfo", info));
  };
};

export const deleteStepApprover = (stepId, approverId) => {
  return (dispatch, getState) => {
    let info = getState().getIn(["workflow", "stepInfo"]).toJS();
    info.map((item) => {
      if (item.id === stepId) {
        item.approvers = item.approvers.filter(
          (person) => person.id !== approverId,
        );
      }
      return item;
    });
    dispatch(setData("stepInfo", info));
  };
};

export const changeStepApprover = (stepId, approverId, field, value) => {
  return (dispatch, getState) => {
    let info = getState().getIn(["workflow", "stepInfo"]).toJS();
    info.map((item) => {
      if (item.id === stepId) {
        item.approvers.map((person) => {
          if (person.id === approverId) {
            person[field] = value;
          }
          return person;
        });
      }
      return item;
    });
    dispatch(setData("stepInfo", info));
  };
};

export const resetInfo = () => ({
  type: constants.RESET,
});

export const updateContract = (proId, contractId, data) => {
  return (dispatch) => {
    if (contractId) {
      API.upsert_contract(proId, contractId, data).then(() => {
        API.read_contract(proId, contractId)
          .then((res) => res.data.data)
          .then((res) => {
            dispatch(setData("basicInfo", res.data));
          });
      });
    } else {
      API.upsert_project(proId, data).then(() => {
        API.read_one_project_group(proId)
          .then((res) => res.data.data)
          .then((res) => {
            dispatch(setData("basicInfo", res.data));
          });
      });
    }
  };
};
//claim cert workflow
export const readClaimCertWorkflow = (proId, contractId, claimId, certId) => {
  return (dispatch) => {
    dispatch(setData("loading", true));
    //Reading contract and its workflow
    API.read_contract(proId, contractId)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("contractName", res.data.name));
        dispatch(setData("basicInfo", res.data));
      });
    if (certId) {
      API.read_cert_workflow(proId, contractId, claimId, certId)
        .then((res) => res.data)
        .then((ele) => {
          dispatch(setPartWorkflow(ele.data.data.steps));
        });
    } else {
      API.read_claim_workflow(proId, contractId, claimId)
        .then((res) => res.data)
        .then((ele) => {
          dispatch(setPartWorkflow(ele.data.data.steps));
        });
    }

    API.read_one_project_group(proId)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("projectName", res.data.name));
      });
  };
};

export const setPartWorkflow = (workflows) => {
  return (dispatch) => {
    workflows.shift(); // Remove the first element
    workflows.pop(); // Remove the last element
    // mapping the user id to ids for workflow steps.
    let data = workflows.map((step) => {
      step.approvers = step.approvals;
      delete step.approvals;
      if (step.approvers.length !== 0) {
        step.approvers.map((ele) => {
          ele.id = ele.user_id;
          return ele;
        });
      }
      return step;
    });
    dispatch(setData("stepInfo", data));
    dispatch(setData("loading", false));
  };
};

export const upsertClaimCertWorkflow = (
  successURL,
  proId,
  contractId,
  claimId,
  certId,
) => {
  return (dispatch, getState) => {
    let data = getState().getIn(["workflow", "stepInfo"]).toJS();
    let missingApprovers = data.some(
      (ele) =>
        ele.approvers.length === 0 ||
        !ele.approvers.some(
          (person) => person.required && typeof person.id === "string",
        ),
    );
    if (missingApprovers) {
      return dispatch(
        actionCreatorsModal.showModal("alert", {
          open: true,
          title: "Error!",
          message:
            "There must be at least 1 required approver for each approval workflow step",
        }),
      );
    }

    let steps = [];
    data.map((ele) => {
      steps.push({
        step: ele.step,
        name: ele.name,
        approvals: ele.approvers
          .filter((item) => {
            return isNaN(item.id); //ignore empty approver lines
          })
          .map((person) => ({
            required: person.required,
            user_id: person.id,
            step: ele.step,
          })),
      });
      return ele;
    });

    let dataSend = { steps: steps };
    if (certId) {
      return API.update_cert_workflow(
        proId,
        contractId,
        claimId,
        certId,
        dataSend,
      ).then(() => {
        history.push(successURL);
      });
    }
    return API.update_claim_workflow(proId, contractId, claimId, dataSend).then(
      () => {
        history.push(successURL);
      },
    );
  };
};
