import React from "react";
import { NumericFormat } from "react-number-format";
import Dropdown from "../dropdown/dropdown";
import Date from "../datePicker";
import Toggle from "./toggle";
import { InputGroup, InputRender, Textarea, Error, LinkInput } from "./styled";
import { PhoneComponent } from "./telephone";
import LockInput from "./lock_input";
import CountryConfig from "../config/countryConfigInput";
import StyledCheckbox from "./checkbox";

class InputComponent extends React.Component {
  renderControl(otherProps) {
    switch (this.props.field) {
      case "textarea":
        return (
          <div>
            <Textarea {...otherProps} />
            {this.props.error && (
              <Error>
                <div className="error_img" />
                {this.props.error}
              </Error>
            )}
          </div>
        );
      case "number":
        return (
          <NumericFormat
            {...this.props}
            className={`form_input ${this.props.className}`}
            thousandSeparator={","}
            decimalSeparator={"."}
            placeholder={
              this.props?.placeholder ||
              this.props?.prefix ||
              this.props?.suffix
            }
            allowLeadingZeros={false}
          />
        );
      case "checkbox":
        return <StyledCheckbox {...this.props} />;
      case "child":
        return this.props.children;
      case "dropdown":
        if (this.props?.required) {
          return (
            <div>
              <Dropdown
                {...this.props}
                field="select"
                className={this.props?.error ? "error" : ""}
                marginBottom={
                  this.props.error ? false : otherProps?.marginBottom
                }
              />
              {this.props.error && (
                <Error marginBottom={otherProps?.marginBottom}>
                  <div className="error_img" />
                  {this.props.error}
                </Error>
              )}
              {this.props.suffix && this.props.suffix}
            </div>
          );
        }
        return <Dropdown {...this.props} field="select" />;
      case "date":
        return <Date {...this.props} />;
      case "date-required":
        return (
          <div>
            <Date {...this.props} />
            {this.props.error && (
              <Error>
                <div className="error_img" />
                {this.props.error}
              </Error>
            )}
          </div>
        );
      case "search":
        if (this.props?.required) {
          return (
            <div ref={this.props?.inputref}>
              <Dropdown {...this.props} field="search" />
              {this.props.error && (
                <Error marginBottom={otherProps?.marginBottom}>
                  <div className="error_img" />
                  {this.props.error}
                </Error>
              )}
            </div>
          );
        }
        return (
          <Dropdown ref={this.props?.inputref} {...this.props} field="search" />
        );
      case "toggle":
        return <Toggle {...this.props} />;
      case "telephone":
        return <PhoneComponent {...this.props} />;
      case "lock-field":
        return <LockInput {...this.props} />;
      case "lock-country":
        return <CountryConfig {...this.props} />;
      case "link-field":
        return (
          <LinkInput {...otherProps} className={`${this.props.className}`}>
            <div>{otherProps.value || otherProps.defaultValue}</div>
            <div className="link-icon" />
          </LinkInput>
        );
      default:
        if (this.props?.required) {
          return (
            <>
              <InputRender
                {...otherProps}
                ref={this.props?.inputref}
                className={`${this.props.className} ${
                  this.props?.error ? "error" : ""
                }`}
                marginBottom={
                  this.props.error ? false : otherProps?.marginBottom
                }
              />
              {this.props.error && (
                <Error
                  marginBottom={otherProps?.marginBottom}
                  justifyContent={otherProps?.justifyContent}
                >
                  <div className="error_img" />
                  {this.props.error}
                </Error>
              )}
            </>
          );
        }
        return <InputRender ref={this.props?.inputref} {...otherProps} />;
    }
  }
  render() {
    const { wrapperClassName, label, required, noRequiredStar, ...otherProps } =
      this.props;
    return (
      <InputGroup
        className={`${wrapperClassName || ""}`}
        width={this.props?.width}
      >
        {this.props.field !== "checkbox" && label && (
          <label className="form_label">
            {label}
            {required && !noRequiredStar && <span className="required">*</span>}
          </label>
        )}
        {this.renderControl(otherProps)}
      </InputGroup>
    );
  }
}

const Input = React.forwardRef((props, ref) => {
  return <InputComponent {...props} inputref={ref} />;
});

export default Input;
