import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { CloseIcon, SearchIcon } from "./styled";
import SearchContact from "./email_select_dialog_content";
import * as themeStyle from "../../theme";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 24,
    color: themeStyle.palette.gunmetal,
    fontFamily: themeStyle.font.mainFont,
    fontWeight: themeStyle.font.mainFontWeight,
    padding: "30px 0 30px 40px",
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.title} {...other}>
      {children}
      {onClose ? <CloseIcon onClick={onClose} /> : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <SearchIcon className="right" onClick={handleClickOpen} />
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.title}
        </DialogTitle>
        <DialogContent>
          <SearchContact handleClose={handleClose} {...props} />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
