import React from "react";
import { Link } from "react-router-dom";
import { PageLayout, Header, Body, Content, Image } from "./style";
import barrier from "../../statics/img/images-barrier-1.svg";
import barrier2 from "../../statics/img/images-barrier-2.svg";
import cone from "../../statics/img/images-traffic-cone.svg";
import { DownloadBtn as BackButton } from "../../common/button";
import { LogoWhite } from "../../common/file/getFilePath";

const errorPageInfo = {
  _403: {
    title: "No Access",
    message: (
      <span>
        Unfortunately, you do not have access to this page. <br />
        Please contact your account administrator to get access.
      </span>
    ),
    image: barrier2,
  },
  _401: {
    title: "No Access",
    message: (
      <span>
        Unfortunately, you do not have access to this page. <br />
        Please contact your account administrator to get access.
      </span>
    ),
    image: barrier2,
  },
  _401NoAcc: {
    title: "No Accounts",
    message: (
      <span>
        Unfortunately, you have not been added to any accounts. <br />
        Please contact your account administrator to get access. <br /> <br />{" "}
        You can try logging in with a different account.
      </span>
    ),
    image: barrier2,
    hasUniqLink: true,
    uniqLink: (
      <BackButton
        onClick={() => {
          window.location = "/logout";
        }}
        title="Login Again"
      />
    ),
  },
  _403LinkExpired: {
    title: "Link Expired",
    message: (
      <span>
        Unfortunately, the link you opened has expired. <br />
        Please contact your account administrator to get a new link.
      </span>
    ),
    image: barrier,
  },
  _404: {
    title: "Page Not Found",
    message: "Unfortunately, we couldn't find the page you're looking for.",
    image: cone,
  },
  _errorBoundary: {
    title: "Something Wrong",
    message: (
      <>
        Uh Oh! Something has gone wrong.
        <br />
        <br />
        You can refresh the page or report this error if the issue persists.
      </>
    ),
    image: cone,
    hasUniqLink: true,
  },
};

const ErrorPage = ({ errorType, children }) => {
  return (
    <PageLayout>
      <Header>
        <LogoWhite className="logo" />
      </Header>
      <Body>
        <Content>
          <h1>{errorPageInfo[errorType]["title"]}</h1>
          <p>{errorPageInfo[errorType]["message"]}</p>
          <Image src={errorPageInfo[errorType]["image"]} />
          {errorPageInfo[errorType]["hasUniqLink"] && (
            <>{errorPageInfo[errorType]["uniqLink"]}</>
          )}
          {!errorPageInfo[errorType]["hasUniqLink"] && (
            <Link to="/">
              <BackButton title="Back to Home" />
            </Link>
          )}
          {children}
        </Content>
      </Body>
      <div className="pattern" />
    </PageLayout>
  );
};

export const Error401NoAcc = () => <ErrorPage errorType="_401NoAcc" />;
export const Error403 = () => <ErrorPage errorType="_403LinkExpired" />;
export const Error403AccessDenied = () => <ErrorPage errorType="_403" />;
export const Error401 = () => <ErrorPage errorType="_401" />;
export const Error404 = () => <ErrorPage errorType="_404" />;
export const ErrorBoundaryPage = (props) => (
  <ErrorPage errorType={"_errorBoundary"}>{props.children}</ErrorPage>
);
