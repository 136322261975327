import { fromJS } from "immutable";
import * as constants from "./constants";

const defaultState = fromJS({
  projectGroup: [],
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SET_ARRAY_DATA:
      return state.set(action.name, fromJS(action.value || []));
    default:
      return state;
  }
};
