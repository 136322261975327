import React, { Component } from "react";
import { connect } from "react-redux";
import { NavList } from "./component/navList";
import { UserInfoPart } from "./component/userInfoPart";
import { NavBarRectangle } from "./style";
import { BgWrapper } from "../shared_style";
import { actionCreators } from "./store";
import NoticeBar from "../../pages/dashboard/components/noticeBar";
import profile from "../../utils/profile";
import { ACCESS, ACCOUNT } from "../../utils/constants";

class Header extends Component {
  constructor(props) {
    super(props);
    this.handleClickAway = this.handleClickAway.bind(this);
    this.changeAccount = this.changeAccount.bind(this);
  }
  handleClickAway() {
    this.props.handleUserDropdown(true);
  }
  changeAccount(accountId) {
    window.location = `/dashboard?account_id=${accountId}`;
    this.props.changeAccount(accountId);
  }
  render() {
    const {
      handleUserDropdown,
      due_projects,
      due_contracts,
      profileInfo,
      setupExpand,
      handleNotice,
      is_help_desk_mode,
    } = this.props;
    const accountIndex = localStorage.getItem("paylab_account_id");
    let canAccessAccount = profile.checkPermission(ACCOUNT, ACCESS);
    let navList = [
      {
        code: "dashboard",
        text: "Dashboard",
        num: 0,
        link: "/dashboard",
        permission: true,
      },
      {
        code: "projects",
        text: "Projects",
        num: due_projects,
        link: "/projects",
        permission: true,
      },
      {
        code: "contacts",
        text: "Contacts",
        num: 0,
        link: "/contacts",
        permission: true,
      },
      {
        code: "manage-account",
        text: "Manage Account",
        num: 0,
        link: "/manage-account/view",
        permission: canAccessAccount,
      },
    ];
    return (
      <>
        <NavBarRectangle className="dashboard_step1">
          <BgWrapper className="header">
            <NavList
              navList={navList}
              due_projects={due_projects}
              due_contracts={due_contracts}
            />
            <UserInfoPart
              {...this.props}
              accountIndex={accountIndex}
              profile={profileInfo}
              handleUserDropdown={() => handleUserDropdown()}
              handleClickAway={this.handleClickAway}
              changeAccount={this.changeAccount}
              canAccessAccount={canAccessAccount}
              handleNotice={(status) =>
                handleNotice("setupExpand", setupExpand, status)
              }
            />
          </BgWrapper>
        </NavBarRectangle>
        {is_help_desk_mode && <NoticeBar type="help_desk_mode" />}
      </>
    );
  }
  componentDidMount() {
    this.props.getNoticeInfo();
  }
}

const mapStateToProps = (state) => {
  return {
    profileInfo: state.getIn(["headers", "profile"]),
    accountId: state.getIn(["headers", "profile", "payclaim_account_id"]),
    openUserList: state.getIn(["headers", "openUserList"]),
    accountList: state.getIn(["headers", "accountList"]),
    due_projects: state.getIn(["headers", "due_projects"]),
    due_contracts: state.getIn(["headers", "due_contracts"]),
    noticeNum: state.getIn(["headers", "noticeNum"]),
    setupExpand: state.getIn(["headers", "setupExpand"]),
    setupProcess: state.getIn(["headers", "setupProcess"]),
    free_trial: state.getIn(["headers", "free_trial"]),
    openSupportDialog: state.getIn(["headers", "openSupportDialog"]),
    is_help_desk_mode: state.getIn(["headers", "is_help_desk_mode"]),
    accountConfig: state.getIn(["config", "accountConfig"]),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    handleUserDropdown(status) {
      status
        ? dispatch(actionCreators.setData("openUserList", false))
        : dispatch(actionCreators.dropdownOpen());
    },
    changeValue(name, value) {
      dispatch(actionCreators.setData(name, value));
    },
    changeAccount(accountId) {
      localStorage.setItem("close_checklist", JSON.stringify(false));
      localStorage.setItem("paylab_account_id", accountId);
    },
    getNoticeInfo() {
      dispatch(actionCreators.getNoticeInfo());
    },
    handleNotice(name, initialStatus, status) {
      if (status !== "") {
        dispatch(actionCreators.setData(name, status));
      } else {
        dispatch(actionCreators.setData(name, !initialStatus));
      }
    },
    setHelpDeskMode(event) {
      dispatch(
        actionCreators.setHelpDeskMode(event.target.name, event.target.checked),
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
