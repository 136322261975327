import React, { Fragment, useEffect } from "react";
import { ReasonIcon, ReasonContainer } from "./dialogStyle";
import {
  GreenWhiteBtn as SaveButton,
  BlueOutlinedBtn as ClearButton,
} from "../../button";
import Modal from "./modalBase";
import Input from "../../form";

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const [showTextarea, setShowTextarea] = React.useState(false);
  const [otherReason, setOtherReason] = React.useState("");

  useEffect(() => {
    if (props.initialValue.reason !== "") {
      if (props.reasonOption.toJS().includes(props.initialValue.reason)) {
        setReason(props.initialValue.reason);
        setShowTextarea(false);
      } else {
        setReason("create_own_reason");
        setShowTextarea(true);
        setOtherReason(props.initialValue.reason);
      }
    }
  }, [open, props.initialValue.reason, props.reasonOption]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setReason("");
    setOtherReason("");
    setShowTextarea(false);
  };

  const handleChangeReason = (value) => {
    setReason(value);
    setOtherReason("");
    if (value === "create_own_reason") {
      setShowTextarea(true);
    } else {
      setShowTextarea(false);
    }
  };
  const handleClear = () => {
    setOtherReason("");
  };

  const updateReason = (e) => {
    reason === "create_own_reason"
      ? props.inputReason(otherReason, e)
      : props.inputReason(reason || "", e);
    handleClose();
  };
  let reasonOption = props.reasonOption
    .toJS()
    .map((item) => ({ value: item, label: item }));
  reasonOption.push({
    value: "create_own_reason",
    label: "Other reason (enter details below)",
  });

  return (
    <Fragment>
      {props.initialValue.reason === "" ? (
        <div className="reason_button_wrap">
          <ReasonIcon
            className={props.initialValue.isRequiredReason ? "warning" : ""}
            onClick={handleOpen}
          />
          {props.initialValue.isRequiredReason && (
            <span className="warning_label">Required</span>
          )}
        </div>
      ) : (
        <div onClick={handleOpen} className="reason_label">
          {props.initialValue.reason}
        </div>
      )}

      <Modal handleClose={handleClose} open={open} title={props.title}>
        <ReasonContainer>
          <Input
            field="dropdown"
            options={reasonOption}
            width={678}
            noRadius
            label="Reason"
            placeholder="Select Reason ..."
            value={reason}
            usePortal={true}
            onChange={(e) => {
              !e ? handleChangeReason(null) : handleChangeReason(e.value);
            }}
            isClearable
          />
          {showTextarea && (
            <>
              <textarea
                className="reason"
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
                autoFocus
              />
              {otherReason === "" && (
                <div className="warning_text">
                  <div className="warning" />
                  Required
                </div>
              )}
            </>
          )}
        </ReasonContainer>
        <Fragment>
          {showTextarea && (
            <ClearButton
              title="CLEAR"
              onClick={handleClear}
              padding="10px 42px"
              margin="0 30px 0 0"
            />
          )}
          <SaveButton
            title="SAVE"
            onClick={updateReason}
            padding="10px 48px"
            disabled={props.initialValue.isRequiredReason && reason === ""}
          />
        </Fragment>
      </Modal>
    </Fragment>
  );
}
