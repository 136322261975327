import React, {
  Fragment,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";

import { DetailBox, CompanyDetails } from "../style";
import { BottomWrapper } from "../../component/style";
import {
  BlueOutlinedBtn as Cancel,
  GreenWhiteBtn as Save,
} from "../../../../common/button";
import AddressSearch from "../../../../common/mapbox/addressSearch";
import Link from "../../../../common/linkWithQuery";
import Input from "../../../../common/form/index";
import MapboxDisplay from "../../../../common/mapbox/mapboxDisplay";
import { COUNTRY_AU, STATUS_ACTIVE } from "../../../../utils/constants";
import { StateWarningModal } from "../../../../common/config/stateConfigInput";
import RequiredBanner from "../../../../common/missingRequiredBanner";

const NavBottom = (props) => (
  <BottomWrapper>
    <Link to={props.link}>
      <Cancel title="CANCEL" margin="0 30px 0 0" padding="10px 34px" />
    </Link>
    <Save
      title="SAVE"
      padding="10px 41px"
      type="submit"
      onClick={props.submit}
    />
  </BottomWrapper>
);

const Form = forwardRef((props, ref) => {
  const { initialValues, onSubmit, accountConfig, isFormDirty, setData } =
    props;
  const [accountInfo, setAccountInfo] = useState({});
  const [addressInfo, setAddressInfo] = useState({});
  const [searchResult, setSearchResult] = useState({});
  const [update, setUpdate] = useState({});
  const [errors, setErrors] = useState({
    name: "",
    street_address: "",
    state: "",
  }); // for required fields
  const [hasSaved, setHasSaved] = useState(false); // for checking if save button has been pressed
  // for separating account and address info
  const addressFields = [
    "address",
    "city",
    "country",
    "postal_code",
    "state",
    "street_address",
    "suburb",
  ];

  // refs for scrolling
  const companyNameRef = useRef(null);
  const addressRef = useRef(null);
  const stateRef = useRef(null);

  const fieldsAndRefs = [
    {
      field: "name",
      name: "Company Name",
      ref: companyNameRef,
      missing: hasSaved ? errors.name : "",
    },
    {
      field: "street_address",
      name: accountConfig.getIn(["address", "street"]),
      ref: addressRef,
      missing: hasSaved ? errors.street_address : "",
    },
    {
      field: "state",
      name: accountConfig.getIn(["address", "state"]),
      ref: stateRef,
      missing: hasSaved ? errors.state : "",
    },
  ];

  useEffect(() => {
    // set account and address info
    let accountValues = {},
      addressValues = {};
    initialValues?.map((v, k) => {
      if (addressFields.includes(k)) {
        addressValues[k] = v;
      } else {
        accountValues[k] = v;
      }
      return null;
    });
    setAccountInfo(accountValues);
    setAddressInfo(addressValues);
  }, [initialValues]); // eslint-disable-line

  // for mapbox search autofill
  useEffect(() => {
    let autoAddress = searchResult;
    let addressUpdate = {};
    let hasUpdate = false;

    for (var key in autoAddress) {
      // prevent update changes when it's getting called with no address values
      if (autoAddress[key] && key !== "country") {
        hasUpdate = true;
      }
    }

    if (hasUpdate) {
      // handle street-address errors
      let error = autoAddress?.street_address ? "" : "Required";
      addressUpdate = autoAddress;
      addressUpdate.postal_code = autoAddress.postcode;

      // handle state errors for AU
      let state_error = "";
      if (autoAddress.country === "AU") {
        state_error = autoAddress?.state ? "" : "Required";
      }
      setErrors({ ...errors, street_address: error, state: state_error });
      setUpdate({ ...update, ...addressUpdate });
      setAddressInfo({ ...addressInfo, ...addressUpdate });
    }
  }, [searchResult]); // eslint-disable-line

  useEffect(() => {
    if (!isFormDirty && Object.keys(update).length) {
      setData("isFormDirty", true);
    }
  }, [update, isFormDirty]); // eslint-disable-line

  useEffect(() => {
    setData("saved", false);
    setData("isFormDirty", false);
  }, []); // eslint-disable-line

  const handleSubmit = () => {
    let err = {
      name: requiredCheck("name"),
      street_address: requiredCheck("street_address"),
      state: requiredCheck("state"),
    };

    if (!err.name && !err.street_address && !err.state) {
      onSubmit(update);
    } else {
      setHasSaved(true);
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    setErrors(err);
  };

  const handleInfoChange = (field, value) => {
    // handle required field error
    if (field === "name" && value) {
      setErrors({ ...errors, name: "" });
    }
    setUpdate({ ...update, [field]: value });
    setAccountInfo({ ...accountInfo, [field]: value });
  };

  // separate handler for address change so that
  // timezone will not be updated if there are non address field changes
  const handleAddressChange = (field, value) => {
    let addressUpdate = { ...addressInfo, [field]: value };
    // handle required field error
    if (field === "street_address" && value) {
      setErrors({ ...errors, street_address: "" });
    }
    // handle required state error
    if (field === "state" && addressUpdate.country === "AU" && value) {
      setErrors({ ...errors, state: "" });
    }
    setAddressInfo(addressUpdate);
    setUpdate({ ...update, ...addressUpdate });
  };

  // on blur add to the errors list
  const requiredCheck = (field) => {
    let error = "";

    if (field === "name") {
      error = accountInfo[field] ? "" : "Required";
    }
    if (field === "street_address") {
      error = addressInfo[field] ? "" : "Required";
    }
    if (field === "state" && addressInfo.country === COUNTRY_AU) {
      error = addressInfo[field] ? "" : "Required";
    }
    return error;
  };

  return (
    <Fragment>
      <DetailBox ref={ref}>
        <RequiredBanner fieldsAndRefs={fieldsAndRefs} />
        <CompanyDetails className="grid">
          <Input
            label="Company Name"
            ref={companyNameRef}
            required={true}
            value={accountInfo.name || ""}
            onChange={(e) => handleInfoChange("name", e.target.value)}
            error={errors.name}
            onBlur={() => setErrors({ ...errors, name: requiredCheck("name") })}
          />
          <Input
            label="Phone"
            field="telephone"
            addPhoneNumber={accountInfo.phone || ""}
            handleOnChange={(num) => handleInfoChange("phone", num)}
          />
          <Input
            label="Account Country"
            name="country"
            field="lock-country"
            className="country-field"
            value={props?.initialValues?.get("country")}
            width="100%"
            hasFlag
          />
          <div>
            <Input
              label={accountConfig?.getIn(["gst_number", "title"])}
              value={accountInfo.gst_number || ""}
              onChange={(e) => handleInfoChange("gst_number", e.target.value)}
            />
            {accountConfig?.getIn(["buyer_created_tax_invoices", "status"]) ===
              STATUS_ACTIVE && (
              <div className="sub-text">
                Used for{" "}
                {accountConfig?.getIn(["buyer_created_tax_invoices", "title"])}
              </div>
            )}
          </div>
          <Input
            label="Website"
            value={accountInfo.website || ""}
            onChange={(e) => handleInfoChange("website", e.target.value)}
          />
        </CompanyDetails>
        {/* address area */}
        <CompanyDetails className="address">
          <h3>Company Address</h3>
          <MapboxDisplay setAddress={(data) => setSearchResult(data)}>
            <AddressSearch
              className="search"
              hasRestrictCountry
              setAddress={(data) => setSearchResult(data)}
            />
          </MapboxDisplay>
          <Input
            ref={addressRef}
            required={true}
            label={accountConfig.getIn(["address", "street"])}
            value={addressInfo.street_address || ""}
            placeholder={accountConfig.getIn(["address", "street"])}
            onChange={(e) =>
              handleAddressChange("street_address", e.target.value)
            }
            width="100%"
            error={errors.street_address}
            onBlur={() =>
              setErrors({
                ...errors,
                street_address: requiredCheck("street_address"),
              })
            }
          />
          <div className="flex">
            <Input
              label={accountConfig.getIn(["address", "suburb"])}
              value={addressInfo.suburb || ""}
              placeholder={accountConfig.getIn(["address", "suburb"])}
              onChange={(e) => handleAddressChange("suburb", e.target.value)}
              width="404px"
            />
            <Input
              label={accountConfig.getIn(["address", "city"])}
              value={addressInfo.city || ""}
              placeholder={accountConfig.getIn(["address", "city"])}
              onChange={(e) => handleAddressChange("city", e.target.value)}
              width="404px"
            />
          </div>
          <div className="flex">
            <Input
              label={accountConfig.getIn(["address", "postcode"])}
              value={addressInfo.postal_code || ""}
              placeholder={accountConfig.getIn(["address", "postcode"])}
              onChange={(e) =>
                handleAddressChange("postal_code", e.target.value)
              }
              width="261px"
            />
            {addressInfo.country !== "AU" ? (
              <Input
                label={accountConfig.getIn(["address", "state"])}
                name="state"
                value={addressInfo.state || ""}
                placeholder={accountConfig.getIn(["address", "state"])}
                onChange={(e) => handleAddressChange("state", e.target.value)}
                width="261px"
              />
            ) : (
              <div
                ref={stateRef}
                onBlur={() =>
                  setErrors({ ...errors, state: requiredCheck("state") })
                }
              >
                <Input
                  label={accountConfig.getIn(["address", "state"])}
                  name="state"
                  className="mini-width"
                  value={addressInfo.state || ""}
                  onChange={(e) => handleAddressChange("state", e.value)}
                  options={accountConfig.get("state")?.toJS() || []}
                  field={"dropdown"}
                  placeholder={`Select ${accountConfig.getIn(["address", "state"])}`}
                  width="261px"
                  missingRequired={errors.state}
                  required={true}
                  error={errors.state}
                />
              </div>
            )}
            <Input
              label={accountConfig.getIn(["address", "country"])}
              name="country"
              width="261px"
              field="lock-country"
            />
          </div>
          <StateWarningModal
            country={addressInfo.country}
            state={addressInfo.state}
          />
        </CompanyDetails>
      </DetailBox>
      <NavBottom link="/manage-account/view" submit={handleSubmit} />
    </Fragment>
  );
});

export default Form;
