import React, { useState, useEffect } from "react";
import { Wrap, NavigatorSection } from "./styled";
import { COUNTRY_GB } from "../../utils/constants";
export const navTemplate = {
  "nav-projectInfo": { label: "Project Details" },
  "nav-invitation": {
    label: "Contract Details",
    required: true,
    completed: true,
  },
  "nav-projectMember": { label: "Project Members" },
  "nav-contractMember": { label: "Contract Members" },
  "nav-dateSchedule": {
    label: "Date Schedule",
    required: true,
    completed: true,
  },
  "nav-claimWorkflow": { label: "Claim Workflow" },
  "nav-certWorkflow": { label: "Certificate Workflow" },
  "nav-timezone": { label: "Time Zone" },
  "nav-baseContracts": {
    label: "Base Contract",
    required: true,
    completed: true,
  },
  "nav-variation": { label: "Variations" },
  "nav-provisional": { label: "Provisional Sums" },
  "nav-materials": { label: "Materials On/Off Site" },
  "nav-retention": { label: "Retentions" },
  "nav-note": { label: "Notes" },
  "nav-tax": { label: "Tax", required: false, completed: false },
  "nav-document": { label: "Contract Documents" },
  "nav-setting": { label: "Settings" },
  "nav-history": { label: "Claims History" },
  "nav-contractInfo": { label: "Contract Details" },
};

export default function Navigator({ data, anchor, navExpanded, country }) {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [showNavigator, setShowNavigator] = useState(false);
  const [selected, setSelected] = useState("");
  const [isScrollEventEnabled, setIsScrollEventEnabled] = useState(true);
  const [yPos, setYPos] = useState(0);

  const navigatorContent = data;

  useEffect(() => {
    if (country === COUNTRY_GB) {
      navTemplate["nav-tax"].required = true;
    } else {
      navTemplate["nav-tax"].required = false;
    }
  }, [country]);

  useEffect(() => {
    if (isScrollEventEnabled) {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrollEventEnabled]);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, []);

  useEffect(() => {
    setNavigationYPosition();
  }, [document]); // eslint-disable-line

  const setNavigationYPosition = () => {
    const element = document.getElementById(anchor.element);
    if (element) {
      const a = element.getBoundingClientRect();
      if (anchor.direction === "top") {
        if (a.top + a.height >= 16) {
          setYPos(a.top + a.height + 16);
        } else {
          setYPos(16);
        }
      } else if (anchor.direction === "right") {
        if (a.top >= 16) {
          setYPos(a.top);
        } else {
          setYPos(16);
        }
      }
    }
  };

  useEffect(() => {
    const adjustNavigatorPosition = () => {
      const navigatorElement = document.getElementById("navigator");
      if (!navigatorElement) return;

      const defaultLeftPositionValue = (screenSize - 960) / 2 - 218;
      let adjustedLeftPositionValue = defaultLeftPositionValue;

      if (navExpanded && navExpanded.length > 0) {
        if (yPos < 300 && navExpanded.includes(selected)) {
          adjustedLeftPositionValue = (screenSize - 960) / 2 - (218 + 182);
          // Ensure it doesn't overflow the left boundary and stays 10px away from the left edge.
          if (adjustedLeftPositionValue < 10) {
            adjustedLeftPositionValue = 5;
          }
        }
      }

      const adjustedLeftPosition = `calc(${adjustedLeftPositionValue}px)`;
      navigatorElement.style.left = adjustedLeftPosition;
    };

    adjustNavigatorPosition();
  }, [yPos, selected, navExpanded, screenSize]);

  const handleScroll = () => {
    const divElements = document.querySelectorAll('div[id^="nav-"]');
    const topElements = Array.from(divElements)
      .map((item) => {
        const element = document.getElementById(item.id);
        if (element) {
          const { top, height } = element.getBoundingClientRect();
          return {
            id: item.id,
            distance: Math.abs(top),
            height: height,
            top: top,
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    let closestElement = null;

    if (topElements.length > 0) {
      closestElement = topElements.reduce((prev, curr) => {
        if (
          curr.distance < prev.distance &&
          curr.distance <= curr.height &&
          curr.top <= 17
        ) {
          return curr;
        }
        return prev;
      });
    }

    setSelected(closestElement ? closestElement.id : null);
    setNavigationYPosition();
  };

  // Function to scroll to the element and padding to top 16px
  const scrollToElement = (elementId) => {
    const a = document?.getElementById(anchor.element).getBoundingClientRect();
    const element = document.getElementById(elementId);
    const offset = 16;
    if (element) {
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;
      setIsScrollEventEnabled(false);
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
      setTimeout(() => {
        setIsScrollEventEnabled(true);
      }, 200);

      if (offsetPosition >= a.top + a.height) {
        setYPos(offset);
      }
    }
  };

  const clickNavigator = (event) => {
    if (event.target.id !== "navigator-icon") {
      return;
    } else {
      setShowNavigator(!showNavigator);
    }
  };

  const Navigator = ({ className }) => (
    <Wrap className={className}>
      <div className="navigator-item section-title">SECTION NAVIGATOR</div>
      {navigatorContent.map((item) => (
        <div
          key={item.id}
          className={
            selected === item.id ? "navigator-item selected" : "navigator-item"
          }
          onClick={() => {
            setSelected(item.id);
            scrollToElement(item.id);
          }}
          data-target={item.id}
        >
          <div className="navigator-item_label">
            {item.label}
            {item.required && !item.completed && (
              <div className="required-icon" />
            )}
          </div>
        </div>
      ))}
    </Wrap>
  );

  return (
    <NavigatorSection id="navigator" offsetY={yPos}>
      {screenSize <= 1396 || (navExpanded && navExpanded.length) > 0 ? (
        // When not enough space to show the whole navigator or if navExpanded has something in the array, show icon instead.
        <div
          className="container"
          id="navigator-icon"
          onClick={(e) => clickNavigator(e)}
        >
          {showNavigator && <Navigator className="append" />}
        </div>
      ) : (
        <Navigator />
      )}
    </NavigatorSection>
  );
}
