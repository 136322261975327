import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Menu from "@material-ui/core/Menu";
import {
  FormStyle,
  FilterContainer,
  FilterIcon,
} from "../../../common/filter/styled";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
import * as themeStyle from "../../../theme";
const labelStyle = makeStyles((theme) => ({
  label: {
    fontSize: "14px",
    fontFamily: themeStyle.font.mainFont,
  },
}));

export default function FormControlLabelPosition(props) {
  const classes = labelStyle();
  const [state, setState] = React.useState({
    overdue: false,
    due_in_3: false,
    due_in_7: false,
  });
  const { overdue, due_in_3, due_in_7 } = state;
  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };
  const clear = () => {
    setState({ ...state, overdue: false, due_in_3: false, due_in_7: false });
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    props.handleFilter({ ...state });
    props.setFilter({ ...state });
  };

  return (
    <FilterContainer>
      <Badge
        color="secondary"
        overlap="rectangular"
        badgeContent=" "
        variant="dot"
        invisible={!(overdue || due_in_3 || due_in_7)}
        className="claims-received_step4"
      >
        <FilterIcon
          onClick={handleClick}
          aria-controls="simple-menu"
          aria-haspopup="true"
        />
      </Badge>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <FormStyle>
          <div className="title">Filter options</div>
          <FormGroup aria-label="position">
            <FormControlLabel
              control={
                <Checkbox
                  checked={overdue}
                  onChange={handleChange("overdue")}
                  value="overdue"
                  color="default"
                  size="small"
                />
              }
              label="Overdue"
              classes={classes}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={due_in_3}
                  onChange={handleChange("due_in_3")}
                  value="due_in_3"
                  color="default"
                  size="small"
                />
              }
              label="Due in 3 days"
              classes={classes}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={due_in_7}
                  onChange={handleChange("due_in_7")}
                  value="due_in_7"
                  color="default"
                  size="small"
                />
              }
              label="Due in 7 days"
              classes={classes}
            />
          </FormGroup>
          <div onClick={() => clear()} className="filter">
            Clear Filter
          </div>
        </FormStyle>
      </Menu>
    </FilterContainer>
  );
}
