import React, { useState } from "react";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";

import { RetentionOverloadWrapper } from "./styled";
import { CheckboxWrapper, OptionBoxWrap } from "../../makeclaim/styled";
import { calculateSubunitToUnit } from "utils/currencyHelper";
import {
  RETENTION_VALUE_NO_RETENTION,
  RETENTION_VALUE_ACCRUED_RETENTION,
} from "utils/constants";

const OptionBox = ({ checked, id, label, onChange, content }) => {
  return (
    <OptionBoxWrap key={id} selected={checked}>
      <CheckboxWrapper>
        <Checkbox
          checked={checked}
          onChange={onChange}
          id={id}
          color="default"
          size="small"
        />
        <label htmlFor={id}>{label}</label>
      </CheckboxWrapper>
      <div className="content">{content}</div>
    </OptionBoxWrap>
  );
};

const RetentionExceedPopup = (props) => {
  const {
    retentionOption,
    certRetentionValue,
    changeRetentionCalculations,
    accountConfig,
    pcdMax,
    dlpMax,
    changeReleaseValue,
  } = props;

  const [noMoreRetention, selNoMoreRetention] = useState(
    retentionOption === RETENTION_VALUE_NO_RETENTION,
  );
  const [releaseRetention, selReleaseRetention] = useState(
    retentionOption === RETENTION_VALUE_ACCRUED_RETENTION,
  );

  /**
   * Handles the change event for the retention release options.
   * Updates the selected retention option and performs necessary calculations.
   * If not released and checked, the max PCD and DLP values are used from certRetentionValue.
   * If released and checked, the PCD and DLP values are set from the API calculation.
   *
   * @param {Object} event - The event object triggered by the checkbox change.
   */
  const changeReleaseOption = (event) => {
    const targetId = event.target.id;
    const targetValue = event.target.checked;
    // Determine if the "no retention release" option is selected
    const isNoRelease = targetId === "noRelease";
    // Set the retention option based on the selected checkbox
    const retentionOption = isNoRelease ? "no_retention" : "accrued_retention";

    if (isNoRelease) {
      if (targetValue) {
        selReleaseRetention(false);
        changeRetentionCalculations(
          certRetentionValue.get("maxPcd"),
          certRetentionValue.get("maxDlp"),
        );
      }
      selNoMoreRetention(targetValue);
    } else {
      if (targetValue) {
        selNoMoreRetention(false);
        changeRetentionCalculations(
          calculateSubunitToUnit(pcdMax),
          calculateSubunitToUnit(dlpMax),
        );
      }
      selReleaseRetention(targetValue);
    }
    if (targetValue) {
      changeReleaseValue(
        "pcdReleaseValue",
        isNoRelease ? certRetentionValue.get("maxPcd") : 0,
      );
      changeReleaseValue(
        "dlpReleaseValue",
        isNoRelease ? certRetentionValue.get("maxDlp") : 0,
      );
    }

    changeReleaseValue("retentionOption", targetValue ? retentionOption : null);
  };

  return (
    <RetentionOverloadWrapper>
      <div className="addition-bar">
        <div className="arrow" />
        <div className="container">
          <div className="info-icon" />
          <div className="message">
            <div>
              The other party has{" "}
              {accountConfig?.getIn(["claim", "value"]).toLowerCase()} more than
              the accrued retention amount. This happens when the other party
              claims <b>progress</b> <i>and</i> <b>retention release</b> in the
              same claim, but the progress is not fully{" "}
              {accountConfig?.getIn(["cert", "value"]).toLowerCase()} (there is
              a variance).
            </div>
            <br />
            <div>Select an option, or enter a new value manually.</div>
          </div>
        </div>
        <OptionBox
          label="Do not release any retention"
          content={`The ${accountConfig?.getIn(["cert", "value"]).toLowerCase()} retention release amounts will be set to the previously released amounts. No retention
        will be released in this ${accountConfig?.getIn(["cert", "noun"]).toLowerCase()}.`}
          id="noRelease"
          checked={noMoreRetention}
          onChange={changeReleaseOption}
        />
        <OptionBox
          label="Release the total amount accrued"
          content={`The ${accountConfig?.getIn(["cert", "value"]).toLowerCase()} retention release amounts will be adjusted to release exactly the amount that has accrued.`}
          id="release"
          checked={releaseRetention}
          onChange={changeReleaseOption}
        />
      </div>
    </RetentionOverloadWrapper>
  );
};
// export default RetentionExceedPopup;
const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default connect(mapStateToProps, null)(RetentionExceedPopup);
