import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { ScheduleBars, ProgressBarStyle } from "./styled";
import { LAST_DAY_FOLLOWING_MONTH } from "utils/constants";

const ProgressBar = ({ iconOverlay }) => (
  <ProgressBarStyle>
    <div className="icons">{iconOverlay}</div>
  </ProgressBarStyle>
);

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

function DateScheduleBar({ monthPosition, accountConfig, options }) {
  const [isClaimCertClose, setIsClaimCertClose] = useState(false);
  const [isCertPayClose, setIsCertPayClose] = useState(false);
  const [isClaimPayClose, setIsClaimPayClose] = useState(false);
  useEffect(() => {
    setIsClaimCertClose(closeEachOther("claim", "cert"));
    setIsCertPayClose(closeEachOther("cert", "pay"));
    setIsClaimPayClose(closeEachOther("claim", "pay"));
    //eslint-disable-next-line
  }, [monthPosition]);

  const closeEachOther = (type1, type2) => {
    if (monthPosition[type1]?.month === monthPosition[type2]?.month) {
      // same month check
      let gap =
        monthPosition[type2]?.dayOffset - monthPosition[type1]?.dayOffset;
      return gap < 17 ? true : false;
    } else {
      let gap =
        31 - monthPosition[type1]?.dayOffset + monthPosition[type2]?.dayOffset;
      return gap < 11 ? true : false;
    }
  };

  const makeDescription = (dueField, dueDetails) => {
    let claimNoun = accountConfig?.getIn(["claim", "noun"]),
      certNoun = accountConfig?.getIn(["cert", "noun"]),
      termOptions = "";

    if (dueField === "pay") {
      termOptions = options?.payment_options?.[dueDetails?.terms] || "";
    } else {
      termOptions =
        options?.[`pay_${dueField}_options`]?.[dueDetails?.terms] || "";
    }
    termOptions =
      termOptions?.replace(/claim/gi, claimNoun)?.toLowerCase() || "";

    const headings = {
      claim: `${claimNoun} Due`,
      cert: `${certNoun} Due`,
      pay: "Payment Due",
    };

    return [
      <div className={`description-heading ${dueField}`} key={dueField}>
        {headings?.[dueField]}
      </div>,
      <>
        {dueDetails?.terms === LAST_DAY_FOLLOWING_MONTH ? (
          <>
            <span className="bold">Last</span> calendar day of following month
          </>
        ) : (
          <>
            <span className="bold">{dueDetails.day}</span> {termOptions}
          </>
        )}
      </>,
    ];
  };

  const makeIcon = (
    type,
    css = "",
    options = {
      closeEdge: false,
      sameDateAlign: false,
      alignLeftNum: 0,
      offsetNum: 0,
    },
  ) => {
    const { closeEdge, sameDateAlign, alignLeftNum, offsetNum } = options;
    let description = makeDescription(
      type,
      monthPosition[type],
      monthPosition["claim"],
    );
    let offset = (monthPosition[type].dayOffset / 31) * 100;
    let ifAlignEnd = monthPosition[type].dayOffset >= 26 && closeEdge;
    let alignLeft = ifAlignEnd
      ? 83
      : sameDateAlign
        ? offset + 3 + offsetNum
        : offset;
    alignLeft = Math.min(alignLeft, 100);
    return (
      <div
        key={type}
        className="icon-holder"
        style={{ left: `${alignLeftNum || alignLeft}%` }}
      >
        <div className={`icon ${type}`} />
        <div className={`description ${css}`}>
          {description[0]}
          {description[1]}
        </div>
      </div>
    );
  };

  const populateProgressBar = (month) => {
    let icons = [],
      currIconType = [];
    for (const k in monthPosition) {
      if (monthPosition[k].month === month) {
        currIconType.push(k);
      }
    }
    switch (currIconType.length) {
      case 0:
        return null;
      case 1:
        return handleOneIcon(currIconType[0], icons);
      case 2:
        return handleTwoIcons(currIconType, icons);
      case 3:
        return handleThreeIcons(currIconType, icons);
      default:
        return icons;
    }
  };

  const handleTwoIcons = (types, icons) => {
    //handle two icons in same month
    const [type1, type2] = types;

    let css = "";
    if (type1 === "cert" && isClaimCertClose) {
      css = "plus";
    }
    if (closeEachOther(type1, type2)) {
      icons.push(
        makeIcon(type1, type1 === "cert" ? "up" : css, { closeEdge: true }),
      );
      icons.push(
        makeIcon(type2, type2 === "cert" ? "up" : css, {
          sameDateAlign:
            monthPosition[type2].dayOffset - monthPosition[type1].dayOffset <=
            2,
          offsetNum: 3,
        }),
      );
    } else {
      icons.push(makeIcon(type1));
      icons.push(makeIcon(type2));
    }
    return icons;
  };

  const handleThreeIcons = (types) => {
    //handle three icons in same month
    const [claim, cert, pay] = types;
    const claimOffset = monthPosition[claim].dayOffset,
      certOffset = monthPosition[cert].dayOffset,
      payOffset = monthPosition[pay].dayOffset;

    if (claimOffset >= 20) {
      //claim align end of the bar
      return [
        makeIcon(claim, "end", { alignLeftNum: 64 }),
        makeIcon(cert, "up", { alignLeftNum: 83 }),
        makeIcon(pay, "", { alignLeftNum: 100 }),
      ];
    }
    if (claimOffset === 0 && payOffset <= 5) {
      // both of three align start of the bar
      return [
        makeIcon(claim, "left", { alignLeftNum: 0 }),
        makeIcon(cert, "up", { alignLeftNum: 10 }),
        makeIcon(pay, "right end", { alignLeftNum: 20 }),
      ];
    }
    if (isClaimPayClose) {
      //three icons close each other
      return [
        makeIcon(claim, "left"),
        makeIcon(cert, "up", {
          sameDateAlign: certOffset - claimOffset <= 2,
          offsetNum: 0,
          closeEdge: true,
        }),
        makeIcon(pay, payOffset - claimOffset <= 12 ? "right" : "", {
          sameDateAlign: payOffset - certOffset <= 2,
          offsetNum: 6,
        }),
      ];
    }
    const claimIcon = isClaimCertClose
      ? [makeIcon(claim, "", { closeEdge: true })]
      : [makeIcon(claim)];

    const certIcon = isClaimCertClose
      ? [
          makeIcon(cert, "up", {
            sameDateAlign: certOffset - claimOffset <= 2,
            offsetNum: 3,
          }),
        ]
      : isCertPayClose
        ? [makeIcon(cert, "up", { closeEdge: true })]
        : [makeIcon(cert)];

    const payIcon = isCertPayClose
      ? [
          makeIcon(pay, "", {
            sameDateAlign: payOffset - certOffset <= 2,
            offsetNum: 3,
          }),
        ]
      : [makeIcon(pay)];

    return [...claimIcon, ...certIcon, ...payIcon];
  };

  const handleOneIcon = (type) => {
    //handle only one icon in same month
    let icon = null;
    if (type === "cert" && (isClaimCertClose || isCertPayClose)) {
      icon = makeIcon(type, "up");
    } else if (type === "pay" && isCertPayClose) {
      icon = makeIcon(type, "plus");
    } else {
      icon = makeIcon(type);
    }

    return icon;
  };

  return (
    <ScheduleBars>
      {[0, 1, 2].map((bar) => (
        <div className="bar-container" key={bar}>
          <ProgressBar iconOverlay={populateProgressBar(bar)} />
        </div>
      ))}
    </ScheduleBars>
  );
}

export default connect(mapStateToProps, null)(DateScheduleBar);
