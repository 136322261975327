import React, { Component, useMemo } from "react";
import { clarity } from "react-microsoft-clarity";
import { useDispatch, connect } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import { actionCreators as homeActionCreators } from "./common/header/store";
import { actionCreators as actionCreatorsAccount } from "./pages/manageaccount/store";
import { actionCreators as actionCreatorsConfig } from "./common/config/store";
import API from "./server";
import { RedirectWithQuery } from "./common/linkWithQuery";
import Header from "./common/header";
import routes from "./routes";
import { Error404 } from "./pages/errorPage";
import profile from "./utils/profile";

function checkAuth(route, props) {
  if (route.auth) {
    if (route.auth(profile, props) instanceof Promise) {
      route.auth(profile, props).then((res) => {
        if (!res) {
          window.location = "/access-denied";
        }
      });
    }
    if (!route.auth(profile, props)) {
      window.location = "/access-denied";
    }
  }
}

function RenderRoute({ route, props, accountId }) {
  const dispatch = useDispatch(),
    preAccId = accountId;
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  let accId = searchParams.get("account_id");
  let component = null;
  if (accId && accId !== preAccId && accId !== "undefined" && accId !== "") {
    localStorage.setItem("paylab_account_id", accId);
    dispatch(homeActionCreators.login()).then((res) => {
      component = <route.component {...props} {...route.props} />;
    });
  } else {
    component = <route.component {...props} {...route.props} />;
  }
  checkAuth(route, props);
  return component;
}

class index extends Component {
  componentDidMount() {
    API.login_info()
      .then((res) => res.data)
      .then((res) => {
        this.props.login(res.data.profile);
      })
      .then(() =>
        API.read_config()
          .then((res) => res.data)
          .then((res) => {
            this.props.setConfig(res.data.config);
            if (!res.data.config.mapbox_token) {
              localStorage.removeItem("MAPBOX_TOKEN");
            } else {
              localStorage.setItem(
                "MAPBOX_TOKEN",
                res.data.config.mapbox_token,
              );
            }
            // Initialise Microsoft Clarity
            if (res?.data?.config?.microsoft_clarity_token) {
              clarity.init(res.data.config.microsoft_clarity_token);
            }
            let measurementId = res.data.config.ga_tracking_id;
            if (measurementId) {
              ReactGA.initialize(measurementId);
            }
          }),
      );
  }
  render() {
    const { accountId } = this.props;
    return (
      <>
        {this.props.isLoggedIn && (
          <Switch>
            <Route
              path="/"
              exact
              render={() => <RedirectWithQuery to="/dashboard" />}
            />
            {routes.map((route) => {
              return (
                <Route
                  exact
                  key={route.path}
                  path={route.path}
                  render={(props) =>
                    route.navText ? (
                      <React.Fragment>
                        <Header />
                        <RenderRoute
                          route={route}
                          props={props}
                          accountId={accountId}
                        />
                      </React.Fragment>
                    ) : (
                      <RenderRoute route={route} />
                    )
                  }
                />
              );
            })}
            <Route component={Error404} />
          </Switch>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.getIn(["headers", "isLoggedIn"]),
    accountId: state.getIn(["headers", "profile", "payclaim_account_id"]),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login(data) {
      dispatch(homeActionCreators.login(data));
    },
    setConfig(config) {
      dispatch(homeActionCreators.setData("config", config));
      dispatch(actionCreatorsAccount.getAccountInfo());
      dispatch(actionCreatorsConfig.getAccountConfig());
      dispatch(actionCreatorsConfig.readCountries());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(index);
