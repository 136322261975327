import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { components } from "react-select";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faCaretDown);

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon="caret-down" />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
