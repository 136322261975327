import React, { PureComponent } from "react";
import {
  InfoSection,
  SummaryContainer,
  ChartSection,
  TotalSection,
} from "../styled";
import { format } from "date-fns";
import Select from "./select_frame";
import ChartGraph from "./claimsReceivedChart";
import { currencySubunitToUnit } from "../../../utils/currencyHelper";

export default class TableContainer extends PureComponent {
  setChartData = (sum, claimsReceivedType) => {
    var dataArr = [];
    var data = new Date();
    data.setMonth(data.getMonth() + 1, 1);
    for (var i = 0; i < claimsReceivedType; i++) {
      data.setMonth(data.getMonth() - 1);
      var m = data.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      dataArr.push(data.getFullYear() + "-" + m);
    }
    dataArr.reverse();
    if (sum !== undefined) {
      let initialData = sum.get("months").toJS();
      let dataSet = [
        ["Month"],
        [sum?.get("field_one_title")],
        [sum?.get("field_two_title")],
      ];
      for (var n = 0; n < dataArr.length; n++) {
        let key = dataArr[n];
        dataSet[0][n + 1] = format(new Date(dataArr[n]), "MMM");
        dataSet[1][n + 1] = initialData[key]?.field_one;
        dataSet[2][n + 1] = initialData[key]?.field_two;
      }
      return dataSet;
    } else return [["month", dataArr[0], dataArr[1], dataArr[2]]];
  };

  render() {
    const {
      claimsReceivedSummary,
      claimsReceivedType,
      selClaimsMonth,
      currency,
      accountConfig,
    } = this.props;
    const options = [
      { label: "Last 6 Months", value: 6 },
      {
        label: "Last 3 Months",
        value: 3,
      },
    ];
    const valueFromId = (opts, id) => opts.find((o) => o.value === id);
    let dataSet = this.setChartData(claimsReceivedSummary, claimsReceivedType);
    const claimedOutTitle = claimsReceivedSummary
      .get("field_one_title")
      .replace("Claimed", accountConfig?.getIn(["claim", "value"]));
    return (
      <SummaryContainer width="49%" className="dashboard_step3">
        <div className="sumType">
          <span>{accountConfig?.getIn(["claim", "noun"]) + "s"} Summary</span>
          <Select
            value={valueFromId(options, claimsReceivedType)}
            onChange={selClaimsMonth}
            name="claimsReceivedType"
            className="selectMonthFrame"
            defaultValue="month_6"
            options={options}
            width="150px"
            height="24px"
            padding="0 10px"
            paddingLeft="0"
          />
        </div>

        <InfoSection>
          <TotalSection>
            <div className="group-data">
              <div className="label-box out" />
              <div className="label">{claimedOutTitle}</div>
              <div className="value">
                {currencySubunitToUnit(
                  claimsReceivedSummary.get("field_one"),
                  true,
                  false,
                  true,
                )}
              </div>
            </div>
            <hr />
            <div className="group-data">
              <div className="label-box" />
              <div className="label">
                {claimsReceivedSummary.get("field_two_title")}
              </div>
              <div className="value">
                {currencySubunitToUnit(
                  claimsReceivedSummary.get("field_two"),
                  true,
                  false,
                  true,
                )}
              </div>
            </div>
            <hr />
            <div className="group-data">
              <div className="label">Difference</div>
              <div className="value">
                {currencySubunitToUnit(
                  claimsReceivedSummary.get("difference"),
                  true,
                  false,
                  true,
                )}
              </div>
            </div>
          </TotalSection>
          <ChartSection className="chart">
            <ChartGraph
              chartData={dataSet}
              claimsReceivedSummary={claimsReceivedSummary}
              currency={currency}
              shorten={true}
            />
          </ChartSection>
        </InfoSection>
      </SummaryContainer>
    );
  }
}
