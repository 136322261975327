import { formatPhoneNumberIntl } from "react-phone-number-input";
import * as constants from "./constants";
import { actionCreators } from "../../../common/modal";
import history from "../../../utils/history";
import API from "../../../server";

export const resetStage = () => ({
  type: constants.RESET_STAGE,
});
export const setData = (name, data) => ({
  type: constants.SET_DATA,
  name,
  data,
});
export function getAllEntityOption() {
  //get request about all entity info from this account
  return (dispatch) => {
    API.read_account_companies()
      .then((res) => res.data)
      .then((result) => result.data.data)
      .then((result) =>
        dispatch({
          type: constants.SET_ALL_ENTITY_OPTION,
          payload: result,
        }),
      );
  };
}
export const setSelectedInfo = (name, id) => ({
  //record the selected contact id
  type: constants.SELECTED_INFO,
  name: name,
  id: id,
});

export const getSpecificEntity = (entityId) => {
  return (dispatch) => {
    API.read_account_company(entityId)
      .then((res) => res.data)
      .then((result) => result.data.data)
      .then((result) =>
        dispatch({
          type: constants.SET_DETAIL_CONTACT_INFO,
          info: result,
          field: "entity",
        }),
      );
  };
};

export const createNewContact = (form, useExistingEntity, selectedEntityId) => {
  return (dispatch) => {
    let data = form.toJS();
    useExistingEntity
      ? dispatch(addContact(form, selectedEntityId))
      : API.upsert_account_company(data)
          .then((res) => res.data)
          .then((result) => result.data.id)
          .then((result) => dispatch(addContact(form, result)));
  };
};

const addContact = (form, entityId) => {
  return (dispatch, getState) => {
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    API.upsert_company_contact(entityId, {
      name: form.get("payerName"),
      phone: form.get("phone") && formatPhoneNumberIntl(form.get("phone")),
      email: form.get("email"),
    })
      .then((res) => res.data)
      .then(() => {
        dispatch(setData("saved", true));
        dispatch(
          actionCreators.showModal("confirm", {
            open: true,
            title: "Success!",
            message: "You have created a new contact successfully！",
            url: `/contacts?account_id=${accountId}`,
          }),
        );
      });
  };
};

const updateContact = (form, contactId, entityId) => {
  //update contact based on the contactId
  return (dispatch, getState) => {
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    API.upsert_company_contact(entityId, {
      name: form.get("payerName"),
      phone: form.get("phone") && formatPhoneNumberIntl(form.get("phone")),
      email: form.get("email"),
      id: contactId,
    })
      .then((res) => res.data)
      .then(() => {
        dispatch(setData("saved", true));
        dispatch(
          actionCreators.showModal("confirm", {
            open: true,
            title: "Success!",
            message: "You have updated the contact successfully！",
            url: `/contacts?account_id=${accountId}`,
          }),
        );
      });
  };
};
export const updateContactInfo = (form, entityId, contactId) => {
  //Based on the change and entityId to update entity info
  let data = form.toJS();
  data.entity_id = entityId;
  return (dispatch) => {
    API.upsert_account_company(data)
      .then((res) => res.data)
      .then(() => dispatch(updateContact(form, contactId, entityId)));
  };
};

const setContactInfo = (result, type) => ({
  type: constants.SET_CONTACT_INFO,
  field: type,
  contactList: result,
});
export const getDetailedContact = (entityId, contactId) => {
  //Read specific entity info based on the entityId
  return (dispatch) => {
    API.read_account_company(entityId)
      .then((res) => res.data)
      .then((result) => result.data.data)
      .then((result) => {
        dispatch(setContactInfo(result, "entity"));
        if (contactId) {
          dispatch(readSpecificContact(entityId, contactId, result));
        } else {
          dispatch(setInitialFormValues(result, {}));
        }
      });
  };
};
const readSpecificContact = (entityId, contactId, entityInfo) => {
  //Read specific contact info based on the contactId and entityId
  return (dispatch) => {
    API.read_company_contact(entityId, contactId)
      .then((res) => res.data)
      .then((result) => result.data.data)
      .then((result) => {
        dispatch(setContactInfo(result, "contact"));
        dispatch(setInitialFormValues(entityInfo, result));
      });
  };
};

export const getContactInfo = () => {
  return (dispatch) => {
    dispatch(setData("loading", true));
    API.read_account_contacts()
      .then((res) => res.data.data.data)
      .then((res) => {
        // process data for contactList
        let contactList = [];
        for (const i in res) {
          if (res[i].contacts.length > 0) {
            // add as contacts
            res[i].contacts.map((c) => {
              let data = copyObject(res[i], ["contacts"]);
              let contactData = copyObject(c, ["company"]);

              data = { ...data, ...contactData };
              contactList.push(data);
              return null;
            });
          } else {
            // add as company
            let data = copyObject(res[i], ["contacts"]);
            data.active_contracts = 0;
            contactList.push(data);
          }
        }

        dispatch({
          type: constants.CHANGE_LIST_DATA,
          contactList: contactList || [],
        });
        dispatch(setData("loading", false));
      });
  };
};

// copy some object properties
const copyObject = (data, exclude = []) => {
  let copy = {};
  for (const [key, value] of Object.entries(data)) {
    if (exclude.some((i) => i !== key)) {
      copy[key] = value;
    }
  }
  return copy;
};

export const deleteContact = (contactId, entityId) => {
  return (dispatch) => {
    const api = contactId
      ? { name: "delete_company_contact", config: [contactId, entityId] }
      : { name: "delete_account_company", config: [entityId] };
    dispatch(
      actionCreators.showModal("delete", {
        open: true,
        title: "Contact",
        message: "contact",
        api: api,
        action: getContactInfo(),
      }),
    );
  };
};
export const closeUploadDialog = () => {
  return (dispatch) => {
    dispatch(setData("csvFile", []));
    dispatch(setData("is_dialog_open", false));
  };
};
export const importContactCSV = (entityId, file) => {
  return (dispatch, getState) => {
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    let dataSend = new FormData();
    dataSend.append(
      entityId !== "" ? "payers_template" : "contacts_template",
      file.toJS()[0],
    );

    API.upload_csv_contact(entityId, dataSend, {
      mimeType: "multipart/form-data",
    })
      .then((res) => res.data)
      .then((res) => {
        dispatch(
          actionCreators.showModal("confirm", {
            open: true,
            title: "Success!",
            message: "You have imported the file successfully！",
          }),
        );
        dispatch(closeUploadDialog());
        history.push(`/contacts?account_id=${accountId}`);
      });
  };
};

export const setTargetAddress = (data, formName) => {
  return (dispatch, getState) => {
    let formValue = getState().getIn(["form", formName, "values"]);
    let form = {};
    if (formValue) {
      form = { ...formValue.toJS(), ...data, postal_code: data.postcode };
    } else {
      form = { ...data, postal_code: data.postcode };
    }
    dispatch(setData("formValue", form));
  };
};

const setInitialFormValues = (entityInfo, contactInfo) => {
  return (dispatch) => {
    let initialSet = entityInfo;
    initialSet["payerName"] = contactInfo.contact_name;
    initialSet["phone"] = contactInfo.contact_tel;
    initialSet["email"] = contactInfo.contact_email;
    dispatch(setData("formValue", initialSet));
  };
};
