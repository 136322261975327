export const REQUEST_CONTRACT_INFO = "/MAKE_CLAIM/REQUEST_CONTRACT_INFO";
export const SET_INPUT_VALUE = "/MAKE_CLAIM/SET_INPUT_VALUE";
export const SET_NAME_VALUE = "/MAKE_CLAIM/SET_NAME_VALUE";
export const SET_CLAIM_DATES = "/MAKE_CLAIM/SET_CLAIM_DATES";
export const RESET_STAGE = "/MAKE_CLAIM/RESET_STAGE";
export const REQUEST_CLAIM_INFO = "/MAKE_CLAIM/REQUEST_CLAIM_INFO";
export const REMOVE_EMAIL = "/MAKE_CLAIM/REMOVE_EMAIL";
export const ADD_EMAIL = "/MAKE_CLAIM/ADD_EMAIL";
export const SET_EMAIL_SUBJECT = "/MAKE_CLAIM/SET_EMAIL_SUBJECT";
export const SET_CLAIM_INPUT_METHOD = "/MAKE_CLAIM/SET_CLAIM_INPUT_METHOD";
export const SET_TABLE_DATA = "/MAKE_CLAIM/SET_TABLE_DATA";
export const UPDATE_CLAIM_DATES = "/MAKE_CLAIM/UPDATE_CLAIM_DATES";
export const SET_DATA = "/MAKE_CLAIM/SET_DATA";
export const SET_CONTRACT_DETAIL = "/MAKE_CLAIM/SET_CONTRACT_DETAIL";
export const SET_LINE_COMMENTS = "/MAKE_CLAIM/SET_LINE_COMMENTS";
export const SET_MATERIAL_INPUT = "/MAKE_CLAIM/SET_MATERIAL_INPUT";
export const DELETE_FILE = "/MAKE_CLAIM/DELETE_FILE";
export const SET_PREVIOUS_CLAIM_CERT = "/MAKE_CLAIM/SET_PREVIOUS_CLAIM_CERT";
export const SET_PREVIOUS_OPTION = "/MAKE_CLAIM/SET_PREVIOUS_OPTION";
