import React from "react";
import { RedBtn as Confirm, BlueOutlinedBtn as Cancel } from "../../button";
import Modal from "./modalBase";

const megStyle = {
  padding: "33px 30px",
  lineHeight: "30px",
};

const DeleteDialog = (props) => {
  const {
    closeModal,
    open,
    title,
    message,
    deleteItem,
    uniqueMessage,
    uniqueAction,
    uniqueTitle,
  } = props;
  return (
    <Modal
      width="590px"
      title={uniqueTitle || `Delete ${title}?`}
      handleClose={() => closeModal("cancel")}
      open={open}
    >
      <div style={megStyle}>
        {uniqueMessage ||
          `Are you sure you want to delete this ${message}? This action cannot be undone.`}
      </div>
      <div>
        <Confirm
          onClick={() => deleteItem()}
          title={uniqueAction || "Delete"}
          padding="10px 34px"
        />
        <Cancel
          onClick={() => closeModal("cancel")}
          title="Cancel"
          margin="0 20px 0 0"
          padding="10px 34px"
        />
      </div>
    </Modal>
  );
};
export default DeleteDialog;
