import React, { Component, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Container } from "./styled";
import {
  BlueOutlinedBtn as ButtonBlue,
  DownloadDark as DownloadBtn,
} from "common/button";

import Menu from "common/breadcrumbs";
import Link from "common/linkWithQuery";
import Loading from "common/loading";
import profile from "utils/profile";
import { CONTRACT, EDIT } from "utils/constants";

import { actionCreators } from "../store";
import { actionCreators as actionCreatorsAccount } from "pages/manageaccount/store";

const LazyHistory = lazy(() => import("common/audit_logs/index.js"));

class ContractHistory extends Component {
  state = {
    allowEditContract: true,
  };
  componentDidMount() {
    const { proId, contractId } = this.props.match.params;
    this.props.readProject(proId);
    this.props.readContract(proId, contractId);
    profile.checkPermission(CONTRACT, EDIT, proId, contractId).then((res) => {
      this.setState({ allowEditContract: res });
    });
  }

  render() {
    const { proId, contractId, type } = this.props.match.params;
    const { projectInfo, contractInfo, accountConfig } = this.props;
    const previousURL = `/contract/${proId}/${type}/${contractId}`;
    const link = [
      { link: "/projects", text: "Projects" },
      { link: `/project/view/${proId}`, text: projectInfo.get("name") },
      { link: previousURL, text: contractInfo.get("name") },
    ];
    const headers = "Contract History";

    return (
      <Container>
        <div className="page-breadcrumbs">
          <Menu
            link={link}
            content={headers}
            className="page-breadcrumbs-label"
          />
          <DownloadBtn
            title="Export"
            onClick={() =>
              this.props.downloadHistory(
                proId,
                contractId,
                contractInfo.get("name"),
              )
            }
          />
          <Link to={previousURL}>
            <ButtonBlue title="Back To Contract" />
          </Link>
        </div>
        <h1>{headers}</h1>
        <p>
          Changes that have been made to the contract are shown here, including
          changes made by the other party. For the history of claims and
          certificates, refer to the{" "}
          <Link
            to={{
              pathname: previousURL,
              state: { id: "nav-history", section: "history" },
            }}
          >
            <b>{accountConfig?.getIn(["claim", "noun"])}s History</b>
          </Link>{" "}
          section of the Contract page.
        </p>
        <div className="content-container">
          <Suspense fallback={<Loading message={"Loading Page..."} />}>
            <LazyHistory
              fetchDataApi={"read_contract_history"}
              contractType={type}
              timeZone={contractInfo?.get("time_zone")}
              proId={proId}
              contractId={contractId}
              hasAccessToSettings={this.state.allowEditContract}
            />
          </Suspense>
        </div>
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  projectInfo: state.getIn(["contract", "projectInfo"]),
  contractInfo: state.getIn(["contract", "contractInfo"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});

const mapDispatchToProps = (dispatch) => ({
  readProject(proId) {
    dispatch(actionCreators.readProject(proId));
  },
  readContract(proId, contractId) {
    dispatch(actionCreators.readContract(proId, contractId));
    dispatch(actionCreatorsAccount.getAccountInfo());
  },
  downloadHistory(proId, contractId, name) {
    dispatch(actionCreators.downloadContractHistory(proId, contractId, name));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractHistory);
