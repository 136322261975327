import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import * as colorTheme from "../../../theme";

const skeletonTheme = {
  primary: {
    baseColor: colorTheme.palette.warmLightGrey,
    highlightColor: colorTheme.palette.highlightGrey,
  },
  secondary: {
    baseColor: colorTheme.palette.lighterBlue,
    highlightColor: colorTheme.palette.mediumBlue,
  },
};
export const CustomizeSkeleton = ({ theme = "primary", ...otherProps }) => (
  <Skeleton
    height="100%"
    baseColor={skeletonTheme[theme]["baseColor"]}
    highlightColor={skeletonTheme[theme]["highlightColor"]}
    width="100%"
    {...otherProps}
  />
);
