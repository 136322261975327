import { List } from "immutable";

export const handleSortIntegrationContractList = (data, sortKey, from) => {
  let dataList;
  if (Array.isArray(data)) {
    dataList = List(data);
  } else {
    dataList = data;
  }

  const sortedList = dataList.sortBy((val) => {
    let sortValue = val.get(sortKey);

    if (from === "project" && sortKey === "name") {
      const name = val.get("name");
      const number = val.get("project_number");

      if (number && name.includes(number)) {
        const numberEndIndex = name.indexOf(number) + number.length;
        sortValue = name.substring(numberEndIndex).trim();
      }
    } else if (from === "contract" && sortKey === "name") {
      sortValue = val.get("description");
    } else if (from === "contract" && sortKey === "vendor_name") {
      const name = val.get("name");
      const number = val.get("contract_number");
      const description = val.get("description");

      let parts = name.split(" - ");
      let remainingPart = parts.find(
        (part) => part !== number && part !== description,
      );
      sortValue = remainingPart || name;
    }

    return String(sortValue).toLowerCase();
  });

  return sortedList;
};
