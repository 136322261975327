import React, { useEffect, useState } from "react";
import {
  StepContainer,
  ApproverWrapper,
  DelButton,
  EmailBlueButton,
} from "../styled";

import Input from "../../../common/form";
import { RedOutlinedBtn as Button } from "../../../common/button";
import EmailModal from "./emailList";

const permissionOption = [
  { label: "Required", value: true },
  { label: "Optional", value: false },
];

export default function Step(props) {
  const {
    stepName,
    isEditable,
    userList,
    changeStepName,
    step,
    addStepApprover,
    deleteStep,
    deleteStepApprover,
    handleApprover,
    allowAddEmail,
    emailList,
    updateEmail,
    isLinked,
  } = props;
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(stepName);
  const [options, setOptions] = useState([]);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const claimCetEmails = emailList?.toJS() || [];

  const saveEmailList = (list) => {
    let emails = list.map((item) => item.value);
    updateEmail(emails);
  };
  useEffect(() => {
    userList && userList.sort((a, b) => a.label.localeCompare(b.label));

    if (!step || !step.get("approvers")) {
      setOptions(userList);
    } else {
      let approvers = [];
      step.get("approvers").map((person) => {
        approvers.push(person.get("id"));
        return person;
      });
      let newList = userList.filter((ele) => !approvers.includes(ele.value));
      setOptions(newList);
    }
  }, [step, userList]);

  const ApproverSection = ({ stepName }) => {
    return (
      <ApproverWrapper>
        {!isEditable && (
          <li key={stepName}>
            <div className="lock-input">
              <label>Who can do this?</label>
              <div className="form_input">
                <input value={"Admins"} disabled />
                <div className="lock" />
              </div>
            </div>
          </li>
        )}

        {isEditable &&
          step.get("approvers") &&
          step.get("approvers").map((person, index) => (
            <li key={person.get("id")}>
              <Input
                field="search"
                label={index === 0 ? "Who can do this?" : ""}
                onChange={(e) =>
                  handleApprover(
                    step.get("id"),
                    person.get("id"),
                    "id",
                    e.value,
                  )
                }
                value={person.get("id")}
                options={options}
                extraOption={userList}
                width="364px"
                placeholder="Search for a person"
                usePortal={true}
                noOptionsMessage="No results found. You must add users into your account and project before you can add them here."
                disabled={isLinked}
              />
              <Input
                field="dropdown"
                label={index === 0 ? "Required?" : ""}
                onChange={(e) =>
                  handleApprover(
                    step.get("id"),
                    person.get("id"),
                    "required",
                    e.value,
                  )
                }
                value={person.get("required")}
                width="364px"
                options={permissionOption}
                disabled={isLinked}
              />
              <DelButton
                disabled={isLinked}
                className={index === 0 ? "has-margin" : ""}
                onClick={() =>
                  deleteStepApprover(step.get("id"), person.get("id"))
                }
              />
            </li>
          ))}

        {isEditable && options.length !== 0 && (
          <button
            className="add-approver-btn"
            onClick={() => addStepApprover(step.get("id"))}
            disabled={isLinked}
          >
            <div className={"add-icon " + (isLinked ? "disabled" : "")} /> Add
            Approver
          </button>
        )}
      </ApproverWrapper>
    );
  };

  return (
    <div className="step">
      <div className="v-stepper">
        <div className="circle" />
        <div className="line" />
      </div>
      <div className="content">
        <div className="step-name">
          <StepContainer>
            <div className="header-bar">
              {!editing ? (
                <h1>{stepName}</h1>
              ) : (
                <>
                  <Input
                    defaultValue={stepName}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <div
                    className="button save"
                    onClick={() => {
                      changeStepName(step.get("id"), name);
                      setEditing(false);
                    }}
                  />
                  <div
                    className="button cancel"
                    onClick={() => {
                      setEditing(false);
                      setName("");
                    }}
                  />
                </>
              )}
              {isEditable && !editing && (
                <div
                  className={`edit ${isLinked ? "disabled" : ""}`}
                  onClick={() => !isLinked && setEditing(true)}
                />
              )}
              {isEditable && (
                <Button
                  title="Remove"
                  className="button-wrap"
                  onClick={() => deleteStep(step.get("step"))}
                  disabled={isLinked}
                />
              )}
              {allowAddEmail && (
                <EmailBlueButton
                  className="button-wrap"
                  onClick={() => setOpenEmailModal(true)}
                >
                  <div className="email-icon" />
                  Email List
                  <div className="number">{claimCetEmails.length}</div>
                </EmailBlueButton>
              )}
            </div>
            <div className="content-wrapper">
              <ApproverSection isEditable />
            </div>
          </StepContainer>
        </div>
      </div>
      {openEmailModal && (
        <EmailModal
          open={openEmailModal}
          title={`${stepName} (Email List)`}
          close={() => setOpenEmailModal(false)}
          submit={(list) => saveEmailList(list)}
          emailList={claimCetEmails}
        />
      )}
    </div>
  );
}
