import numeral from "numeral";

export const roundNearest = (num, cents = false) => {
  if (cents) {
    return num ? Math.round(num / 10) * 10 : 0;
  } else {
    return num ? Math.round(num) : 0;
  }
};

export const roundNumber = (num) =>
  Math.round((num + Number.EPSILON) * 100) / 100;

export const parsePercent = (value, total) => {
  let number = 0;
  if (total && value) {
    number = value / total;
  }
  return Number.parseFloat(number * 100).toFixed(2) + "%";
};

export const convertToPercent = (num) => {
  let number = num || 0;
  return numeral(number).format("0.00%");
};

export const checkExceedMaximum = (value, maximum) => {
  const parsedValue = value ? parseFloat(value, 10) : 0;
  return parsedValue > maximum;
};
