import * as theme from "../../theme";
import styled from "styled-components";

export const SwitchContainer = styled.div`
  height: 40px;
  width: 198px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  float: right;
  .label {
    font-size: 14px;
    font-weight: bold;
    &.unchecked {
      color: ${theme.palette.wolfGrey};
    }
  }
`;
