import React, { Component } from "react";
import { connect } from "react-redux";

import { Container } from "../../project/styled";

import Menu from "../../../common/breadcrumbs";
import { BlueOutlinedBtn as Button } from "../../../common/button";
import Link from "../../../common/linkWithQuery";

import CreateDetail from "../components/newContractDetails";

import { actionCreators } from "../store";

// create contract details page
class index extends Component {
  render() {
    const { projectInfo, createNewContract, ...otherProps } = this.props;
    const { proId, type } = this.props.match.params;

    const link = [
      { link: "/projects", text: "Projects" },
      {
        link: `/project/view/${projectInfo.get("id")}`,
        text: projectInfo.get("name"),
      },
    ];

    return (
      <Container>
        <div className="page-breadcrumbs">
          <Menu
            link={link}
            content={"Create New Contract"}
            className="page-breadcrumbs-label"
          />
        </div>
        <div className="page-breadcrumbs">
          <h1>Create New Contract</h1>
          <Link to={`/project/view/${projectInfo.get("id")}`}>
            <Button title="Back To Project" />
          </Link>
        </div>
        <CreateDetail
          proId={proId}
          createNewContract={(data) => createNewContract(proId, type, data)}
          projectInfo={projectInfo}
          {...otherProps}
        />
      </Container>
    );
  }
  componentDidMount() {
    const proId = this.props.match.params.proId;
    this.props.readDefault(proId);
  }
}

const mapStateToProps = (state) => ({
  config: state.getIn(["headers", "config"]),
  projectInfo: state.getIn(["contract", "projectInfo"]),
  defaultInfo: state.getIn(["contract", "defaultInfo"]),
  contractInfo: state.getIn(["contract", "contractInfo"]),
});

const mapDispatchToProps = (dispatch) => ({
  readDefault(proId) {
    dispatch(actionCreators.readDefault(proId));
    dispatch(actionCreators.readProject(proId));
  },
  createNewContract(proId, type, data) {
    dispatch(actionCreators.createNewContract(proId, type, data));
  },
  readIntegrationContract(proId, contractId) {
    dispatch(actionCreators.readIntegrationContract(proId, contractId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
