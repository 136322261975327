export const countComments = (data, parentID) => {
  let commentCount = 0;

  // Check if the current node's parent_id matches the provided parentID
  if ((data.parent_id === parentID || data.id === parentID) && data.comments) {
    commentCount += data.comments.length;
  }

  // Check if the current node has childitems
  if (data.childitems && data.childitems.length > 0) {
    // Recursively count comments in childitems
    for (const child of data.childitems) {
      commentCount += countComments(child, child.id);
    }
  }

  return commentCount;
};
