import styled from "styled-components";
import * as theme from "../../../theme";
import errorIcon from "../../../statics/img/icon-information-red.svg";

export const Label = styled.label`
  font-size: 16px;
  font-weight: ${theme.font.mainFontWeight};
  margin-bottom: 8px;
  float: left;
  width: 100%;
  display: flex;
  .required {
    color: ${theme.palette.watermelonRed};
  }
`;

export const Error = styled.div`
  font-size: 12px;
  color: ${theme.palette.watermelonRed};
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-bottom: ${(props) => props.marginBottom || "inherit"};
  .error_img {
    background: url(${errorIcon});
    background-size: contain;
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`;
