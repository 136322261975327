import { fromJS } from "immutable";
import { constants } from ".";

const defaultState = fromJS({
  projectInfo: [],
  loading: true,
  welcomeInfoSeen: null,
  allUsers: [],
  members: [],
  dueDates: {},
  integratedProjects: [],
  integrateProjectInfo: [],
  loadIntegration: false,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SET_DATA:
      return state.set(action.payload.name, fromJS(action.payload.value));
    case constants.RESET:
      return defaultState;
    default:
      return state;
  }
};
