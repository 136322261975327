import React, { Component } from "react";
import Select from "react-select";

import DropdownIndicator from "./component/indicator";
import { overrideColor } from "./component/helper";
import * as mainTheme from "../../theme";

export default class SelectFrame extends Component {
  render() {
    const { options, value, onChange, width, isNotSearchable } = this.props;

    const selectInputStyle = {
      control: (styles) => ({
        ...styles,
        width: width || "446px",
        height: "44px",
        borderRadius: "0",
        borderColor: mainTheme.palette.wolfGrey,
        fontSize: "16px",
      }),
      option: (provided, state) => ({
        ...provided,
        width: width || "446px",
        height: "44px",
        fontSize: "16px",
        color: mainTheme.palette.gunmetal,
        "&:hover": {
          backgroundColor: mainTheme.palette.warmLightGrey,
          cursor: "pointer",
        },
      }),
      indicatorSeparator: (styles) => ({
        ...styles,
        display: "none",
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        padding: "14px",
      }),
      menu: (styles) => ({
        ...styles,
        width: width || "446px",
        color: mainTheme.palette.gunmetal,
        fontFamily: mainTheme.font.mainFont,
      }),
      singleValue: (styles) => ({
        ...styles,
        color: mainTheme.palette.gunmetal,
        fontFamily: mainTheme.font.mainFont,
        lineHeight: "16px",
      }),
      placeholder: (styles) => ({
        ...styles,
        color: mainTheme.palette.wolfGrey,
      }),
    };

    return (
      <Select
        {...this.props}
        components={{ DropdownIndicator }}
        options={options}
        value={value}
        onChange={onChange}
        isSearchable={!isNotSearchable}
        styles={selectInputStyle}
        closeMenuOnSelect={true}
        menuPosition={"fixed"}
        theme={(theme) => overrideColor(theme)}
      />
    );
  }
}
