import styled from "styled-components";
import * as theme from "../../../../theme";
import claimingIcon from "../../../../statics/img/image-construction.svg";
import certIcon from "../../../../statics/img/image-coffee-mug.svg";
import bothRoleIcon from "../../../../statics/img/image-pencil-holder.svg";
import selectedIcon from "../../../../statics/img/icon-check-circle-green.svg";

export const FormWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 40px 0 56px 0;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  padding: 40px;
  box-sizing: border-box;
`;

export const RoleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${theme.palette.veryMediumGrey};
  line-height: 20px;
  margin-top: 48px;
  &:first-child {
    margin: 0;
  }
  .role-header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin: 0 0 24px 0;

    .role-info {
      .title {
        font-weight: bold;
        font-size: 28px;
        line-height: 34px;
        color: ${theme.palette.gunmetal};
        margin-bottom: 8px;
      }
      margin-left: 24px;
    }
    .role-image {
      width: 80px;
      height: 80px;
      background: ${(props) => {
          let url = "";
          switch (props.img) {
            case "claim":
              url = claimingIcon;
              break;
            case "cert":
              url = certIcon;
              break;
            case "both":
              url = bothRoleIcon;
              break;
            default:
              url = "";
          }
          return `url(${url})`;
        }}
        no-repeat;
    }
  }
  .role-selection {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    border: 1px solid ${theme.palette.lightWolfGrey};
    border-radius: 4px;
    margin: 8px;
    cursor: pointer;

    .title {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: ${theme.palette.gunmetal};
      margin-bottom: 4px;
    }
    &.selected {
      border: 2px solid ${theme.palette.tangerineOrange};
    }
    .selIcon {
      width: 24px;
      height: 24px;
      background: url(${selectedIcon}) no-repeat;
      float: right;
    }
  }
`;
